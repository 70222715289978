import React, { useEffect } from 'react'

import makeStyles from '@mui/styles/makeStyles';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useFormikContext } from 'formik';
export const NativeSelectFormik = props => {
    //const classes = useStyles();
    
    const { values,
        errors,
        handleChange,
        handleBlur,
        setFieldValue
    } = useFormikContext();
    const onChange = (e) => {
        if (props.number) {
            const newNum = Number(e.target.value);
            if (!isNaN(newNum)) {
                setFieldValue(props.name, newNum);
            }
        } else {
            handleChange(e);
        }
    };
    const handleOnBlur = (e) => {
        console.log("++++++++++++++++++++++++++++++++++++++++++", e);
    };
    useEffect(() => {
    }, [props.errors]);
    return(
        <FormControl style={props.style} error={errors[props.name]} variant="outlined">
            <InputLabel htmlFor="name-native-error" shrink={true}>{props.label}</InputLabel>
            <Select
                native
                name={props.name}
                value={values[props.name]}
                onChange={onChange}
                onBlur={handleOnBlur}
                label={props.label}
            >
                {props.options}
            </Select>
            <FormHelperText>{errors[props.name]}</FormHelperText>
        </FormControl>
    );
}