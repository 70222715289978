import React, { Component, useEffect, useContext, useState } from 'react';
import { FormulasEditView } from '../Formula/FormulasEditView';
import { GridDateFilter, DateFilterLogic, UpdateFilterList } from '../MetaData/GridDateFilter';
import AddCustomToolbar from "../AddCustomToolbar";
import Grid from '@mui/material/Grid';

import { Formik, Form, Field } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

//MUI
import MUIDataTable from "mui-datatables";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { TextField, CheckboxWithLabel, Select } from 'formik-mui'
import { DataNumber, DataForm, DataDate } from '../FormManager';
import { MenuItem } from '@mui/material';
import { error } from 'logrocket';
import { FormControl } from '@mui/material';

import { selectBaselinesByPlantId } from '../../selectors/BaselineSelectors';

import { AddBaseline, DeleteBaseline, GetBaselines, GetBaselinesByPlantID, UpdateBaseline } from '../../actions/BaselineActions';
import { ConfirmAction } from '../../actions/ConfirmActionActions';
import CustomSelectToolbar from '../CustomSelectToolbar';
import { selectContImproveByPlantId, selectContImproveByVisitID } from '../../selectors/ContinuouseImprovement';
import { AlertAction } from '../../actions/AlertAction';
import { useNavigate } from 'react-router-dom';
const BaselineTab = props => {
    useEffect(() => {
        if (props.plantID) {
            dispatch(GetBaselinesByPlantID(props.plantID))
        }
    }, [props.plantID]);

    const dispatch = useDispatch();
    const [selectedRows, setSelectedRows] = useState([]);
    const [modalOpen, setModal] = useState(false);
    const [listModalOpen, setlistModalOpen] = useState(false);
    const [asyncClickOpen, setClickOpen] = useState(false);
    const [baseLineData, setBaseLineData] = useState(null);
    const [AddUpdateType, setAddUpdateType] = useState(null);
    const [ContImpList, setContImpList] = useState([]);
    const [BaselineStartDate, setBaselineStartDate] = useState('');
    const [BaselineEndDate, setBaselineEndDate] = useState('');
    const [selectedBLineData, setselectedBLineData] = useState('');

    const DateView = props => {
        if (props.value === "0001-01-01T00:00:00") {
            return null;
        }
        const d = new Date(props.value);
        return <div>{d.toLocaleDateString()}</div>;
    };

    const baselines = useSelector(s => selectBaselinesByPlantId(s, props.plantID));
    const contImps = useSelector(s => selectContImproveByPlantId(s, props.plantID));

    // dispatch(GetBaselines());

   // console.log("baselines_________________333_____________________", baselines);
    let baseLineList = baselines
    baseLineList.forEach(element => {
        let count = 0
        contImps.forEach(a => {
            if (a.baselineID === element.id && !element.deleted) {
                count += 1
            }
        })
        element.ciImpCount = count
    });
    // 'ciImpCount'
    // console.log("baseLineList_________________33xxxxxxxxxxxxxxx3_____________________", baseLineList);
    const ClickOpen = () => {
        // console.log('selected rowsssss', selectedRows);
        // console.log('selected rowsssss', baseLineList[selectedRows[0]]);
        setAddUpdateType('Update')
        setBaseLineData(baseLineList[selectedRows[0]])
        setModal(true);
    };
    const columns = [
        {
            name: "createdDate", label: "Date",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <DateView value={value} />
                    );
                },
                filter: true,
                filterType: 'custom',
                customFilterListOptions: {
                    update: UpdateFilterList
                },
                filterOptions: {
                    logic: DateFilterLogic,
                    display: (filterList, onChange, index, column) => <GridDateFilter filterList={filterList} onChange={onChange} index={index} column={column} />
                }
            }
        },
        { name: "baselineName", label: "Baseline Name" },
        { name: "ciImpCount", label: "Continuous Improvement" }];

    const rowSelect = (rowsSelectedData, allRows, rowsSelected) => {
        setSelectedRows(rowsSelected);
    };

    const ClickAdd = (e) => {
        setAddUpdateType('Add')
        const data = {
            baselineStartDate: '',
            baselineEndDate: '',
            baselineName: '', noOfMonth: null, daysPerWeek: null, shiftPerDay: null, hoursPerShift: null, baseGrade: null,
            avgSpeed: null, costRate: null, avgWidth: null, preprint: 0, consumptionBaseline: null, actualPrcWaste: null, plantID: props.plantID, createdDate: null
        }
        data.id = uuidv4();
        setBaseLineData(data)
        setModal(true);
    };
    const handleSubmit = (e) => {
        let data = e
        // console.log('dataaaaaaaaaaaaaaaaaaaaaaaaaaaa', data);
        // console.log('BaselineStartDate', BaselineStartDate);
        // console.log('BaselineEndDate', BaselineEndDate);
        if (data.preprint === "") {
            data.preprint = 0
        }
        setModal(false);
        if (AddUpdateType === 'Add') {
            data.createdDate = new Date()
            dispatch(AddBaseline(data));
        } else {
            dispatch(UpdateBaseline(data));
        }
    }
    const handleDate = (value, forV) => {
        if ('StartDate' === forV) {
            let data = baseLineData
            data.baselineStartDate = value
            setBaseLineData(data)
            // setBaselineStartDate(value)
        }
        if ('EndDate' === forV) {
            let data = baseLineData
            data.baselineEndDate = value
            setBaseLineData(data)
            //    setBaselineEndDate (value)
        }
    }
    function formatDate(date) {
        const month = new Date(date).getMonth() + 1; // Month is zero-based, so we add 1
        const day = new Date(date).getDate();
        const year = new Date(date).getFullYear() % 100; // Get last two digits of the year

        // Construct the formatted date string
        const formattedDate = `${month}/${day}/${year}`;

        return formattedDate;
    }

    const ClickDelete = () => {
        let temp = true
        let finds = contImps.filter(CI => CI.baselineID == baseLineList[selectedRows[0]].id)
        // console.log('finds', finds);

        if (finds.length > 0) {

            let li = ''
            finds.forEach(element => {
                li += '\n - ' + element.baselineName + ' Created Date - ' + formatDate(element.createdDate)
            });

            dispatch(AlertAction(`This baseline is linked with ${finds.length} Continuous Improvement   ${li}   `, AlertAction(), 'ok',
                (confirmed) => { }));
        } else {
            dispatch(ConfirmAction("Do you want to delete this BaseLine?", DeleteBaseline(baseLineList[selectedRows[0]]), null));
            setSelectedRows([]);
        }



    };
    const view = () => {
        setselectedBLineData(baseLineList[selectedRows[0]])
        let finds = contImps.filter(CI => CI.baselineID == baseLineList[selectedRows[0]].id)
        setContImpList(finds)
        // console.log('baselineID', baselineID);
        setlistModalOpen(true)
    };
    const options = {
        filterType: 'checkbox', selectableRows: 'single', selectableRowsOnClick: true, print: false,
        download: false,
        fixedHeader: true,
        responsive: 'standard',
        rowsPerPage: baseLineList.length,
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
            return <CustomSelectToolbar selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows} clickEdit={ClickOpen} clickDelete={ClickDelete} view={view} />;
        },
        customToolbar: () => {
            return (
                <AddCustomToolbar onClick={ClickAdd} />
            );
        },
        onRowSelectionChange: rowSelect,
        rowsSelected: selectedRows,
        sortOrder: { name: 'createdDate', direction: 'desc' },
        setRowProps: () => ({
            onDoubleClick: (row) => {
                if (selectedRows.length == 0) {
                    const targetID = row.currentTarget.id;
                    const dataRow = parseInt(targetID.substring(targetID.lastIndexOf('-') + 1));
                    if (!isNaN(dataRow)) {
                        setSelectedRows([dataRow]);
                        setClickOpen(true);//dislike this but requred
                        //;
                    }
                } else {
                    ClickOpen();
                }
            }
        })
    };


    return (<>
        <MUIDataTable
            title={"Baselines"}
            data={baseLineList}
            columns={columns}
            options={options}
        />
        <BaselineFormDialog AddUpdateType={AddUpdateType} modalOpen={modalOpen} closeModal={() => setModal(false)}
            onSubmit={handleSubmit} StartErr={''}
            EndtErr={''} handleDate={handleDate} initialValues={baseLineData} />
        <SpecificCIList selectedBLineData={selectedBLineData} ContImpList={ContImpList} modalOpen={listModalOpen} closeModal={() => setlistModalOpen(false)}
        />
    </>
    );
}
export default BaselineTab;


export const BaselineFormDialog = props => {
    // console.log('propspropsprops', props);
    // new Date('2024-07-17').toISOString()
    const [StartErr, setStartErr] = useState('');
    const [EndtErr, setEndtErr] = useState('');
    const [baselineStartDate, setBaselineStartDate] = useState('');
    const [baselineEndDate, setBaselineEndDate] = useState('');
    const [checkSDate, setcheckSDate] = useState(true);
    const [checkEDate, setcheckEDate] = useState(true);
    const [BaselineDateError, setBaselineDateError] = useState('');
    useEffect(() => {
        if (props.initialValues !== null) {
            let str1 = props.initialValues?.baselineStartDate ? props.initialValues?.baselineStartDate : ''
            if (str1 !== '') {
                setBaselineStartDate(new Date(new Date(str1)).toISOString())
                setcheckSDate(false)
            }
            let str2 = props.initialValues?.baselineEndDate ? props.initialValues?.baselineEndDate : ''
            if (str2 !== '') {
                setBaselineEndDate(new Date(new Date(str2)).toISOString())
                setcheckEDate(false)
            }
            
            
        }
        setStartErr(props.StartErr)
        setEndtErr(props.EndtErr)
    }, [props.initialValues]);

    const handelStartDate = (StartDate) => {
        setBaselineStartDate(StartDate)
        props.handleDate(StartDate, 'StartDate')
        setStartErr(StartDate === '' ? 'Required' : '')
        setcheckSDate(StartDate === '' ? true : false)
        // console.log('StartDate', StartDate);
        // console.log('baselineEndDate', baselineEndDate);
        if (StartDate !== '' && baselineEndDate !== '') {
            const startDate = new Date(StartDate);
            const endDate = new Date(baselineEndDate);
            if (startDate > endDate) {
                setBaselineDateError('Start date should not be after the End date')
            } else {
                setBaselineDateError('')
            }
        }
    }
    const handelEndDate = (EndDate) => {
        setBaselineEndDate(EndDate)
        props.handleDate(EndDate, 'EndDate')
        setEndtErr(EndDate === '' ? 'Required' : '')
        setcheckEDate(EndDate === '' ? true : false)
        // console.log('EndDate', EndDate);
        // console.log('baselineStartDate', baselineStartDate);
        if (baselineStartDate !== '' && EndDate !== '') {
            const startDate = new Date(baselineStartDate);
            const endDate = new Date(EndDate);
            // console.log('startDate <= endDate', startDate <= endDate);
            if (startDate > endDate) {
                setBaselineDateError('Start date should not be after the End date')
            } else {
                setBaselineDateError('')
            }
        }
    }
    const callSubmint = (e) => {
        let data = e
        let isError = false
        if (data.baselineStartDate === '') {
            isError = true
            setStartErr('Required')
        }
        if (data.baselineEndDate === '') {
            isError = true
            setEndtErr('Required')
        }
        if (!isError) {
            props.onSubmit(data)
        }
    }
    const isEmptyObject = obj => Object.entries(obj).length === 0 && obj.constructor === Object;
    const getObjectKeyCount = obj => Object.keys(obj).length;
    const validate = (values) => {
        // console.log('values', values);
        // console.log('checkSDate', checkSDate);
        // console.log('values.baselineEndDate', values.baselineEndDate);
        // console.log('BaselineEndDate', BaselineEndDate);
        const errors = {};

        // if (checkSDate) {
        //     if (baselineStartDate === '') {
        //         errors.baselineStartDate = "Required"
        //         if (values.baselineStartDate === '') { setStartErr('Required'); }
        //     }
        // }
        // if (checkEDate) {
        //     if (baselineEndDate === '') {
        //         errors.baselineEndDate = "Required";

        //         if (values.baselineEndDate === '') { setEndtErr('Required'); }
        //     }
        // }

      
        if (!values.baselineName) { errors.baselineName = "Must have value"; }
        // if (values.noOfMonth <= 0) { errors.noOfMonth = "Select Month"; }
        if (values.avgSpeed <= 0) { errors.avgSpeed = "Required"; }
        if (values.daysPerWeek <= 0) { errors.daysPerWeek = "Select day/week"; }
        if (values.shiftPerDay <= 0) { errors.shiftPerDay = "Select shift/day"; }
        if (values.hoursPerShift <= 0) { errors.hoursPerShift = "Select hours/shift"; }
        if (values.avgWidth <= 0) { errors.avgWidth = "Required"; }
        if (values.actualPrcWaste <= 0) { errors.actualPrcWaste = "Required"; }
        if (values.preprint < 0) { errors.preprint = "Required"; }
        if (values.preprint === null) { errors.preprint = "Required"; }
        if (values.baseGrade <= 0) { errors.baseGrade = "Select base grade"; }
        if (values.consumptionBaseline <= 0) { errors.consumptionBaseline = "Required"; }
        if (values.costRate <= 0) { errors.costRate = "Select base grade"; }
        if (isEmptyObject(errors) || getObjectKeyCount(errors) === 10) {
            if (checkSDate) {
                if (baselineStartDate === '') {
                    errors.baselineStartDate = "Required"
                    if (values.baselineStartDate === '') { setStartErr('Required'); }
                }
            }
            if (checkEDate) {
                if (baselineEndDate === '') {
                    errors.baselineEndDate = "Required";
    
                    if (values.baselineEndDate === '') { setEndtErr('Required'); }
                }
            }
        }
        return errors;
    }
    
    return (
        <Dialog fullWidth maxWidth={"md"} open={props.modalOpen} >
            {
                props.AddUpdateType === 'Add' ? <DialogTitle >Add Baseline</DialogTitle > : <DialogTitle >Update Baseline</DialogTitle >
            }
            <DataForm data={props.initialValues}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sx={{ marginLeft: '22px' }}>
                        <div style={{ display: 'flex' }}>
                            <div style={{ marginRight: '20px' }}>
                                <DataDate dateHandler={handelStartDate} noBlurAction={true} name={'baselineStartDate'} label={'Start Date'} TextFieldProps={{ size: 'small' }} />
                                {
                                    StartErr !== "" ? <Typography style={{ fontSize: '0.75rem', paddingLeft: '10px' }} color="error">{StartErr}</Typography> : <></>
                                }
                            </div>
                            <div>
                                <DataDate dateHandler={handelEndDate} noBlurAction={true} name={'baselineEndDate'} label={'End Date'} TextFieldProps={{ size: 'small' }} />
                                {
                                    EndtErr !== "" ? <Typography style={{ fontSize: '0.75rem', paddingLeft: '10px' }} color="error">{EndtErr}</Typography> : <></>
                                }
                            </div>
                        </div>
                        {
                        BaselineDateError !== "" ?
                                <Typography style={{ fontSize: '0.75rem', paddingLeft: '10px' }} color="error">{BaselineDateError}</Typography>
                                : <></>
                        }
                    </Grid>
                </Grid>
            </DataForm>


            <Formik initialValues={props.initialValues} onSubmit={callSubmint} validate={validate}>
                <Form>
                    <DialogContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sx={{ marginRight: '20px' }} >
                                <div>
                                    <Field fullWidth
                                        component={TextField}
                                        name="baselineName"
                                        type="text"
                                        label="Baseline Name"
                                    />
                                </div>
                            </Grid>
                            {/* <Grid item xs={12} >
                                <div style={{ display: 'flex' }}>

                                    <Field style={{ minWidth: 250 }} label="Month" name="noOfMonth" component={Select}
                                    >
                                        <MenuItem value={1}>1 month</MenuItem>
                                        <MenuItem value={3}>3 month</MenuItem>
                                        <MenuItem value={6}>6 month</MenuItem>
                                        <MenuItem value={12}>12 month</MenuItem>
                                    </Field>
                                </div>
                            </Grid> */}
                            <Grid item xs={6} md={4} >
                                <div>
                                    {/* <BaseDayPeWeekFormik name="dayPerWeek" label="Day/Week" /> */}
                                    <Field style={{ minWidth: 250 }} label="Day/Week" name="daysPerWeek" component={Select}
                                    >
                                        <MenuItem value={4}>4</MenuItem>
                                        <MenuItem value={5}>5</MenuItem>
                                        <MenuItem value={6}>6</MenuItem>
                                        <MenuItem value={7}>7</MenuItem>
                                    </Field>
                                </div>
                            </Grid>
                            <Grid item xs={6} md={4} >
                                <div>
                                    {/* <BaseShiftPeDayFormik name="shiftPerDay" label="Shift/Day" /> */}
                                    <Field style={{ minWidth: 250 }} label="Shift/Day" name="shiftPerDay" component={Select}
                                    >
                                        <MenuItem value={1}>1</MenuItem>
                                        <MenuItem value={2}>2</MenuItem>
                                        <MenuItem value={3}>3</MenuItem>
                                    </Field>
                                </div>
                            </Grid>
                            <Grid item xs={6} md={4} >
                                <div>
                                    {/* <HourPerShiftFormik name="hourPerShift" label="Hours/Shift" /> */}
                                    <Field style={{ minWidth: 250 }} label="Hours/Shift" name="hoursPerShift" component={Select}
                                    >
                                        <MenuItem value={8}>8</MenuItem>
                                        <MenuItem value={10}>10</MenuItem>
                                        <MenuItem value={12}>12</MenuItem>
                                    </Field>
                                </div>
                            </Grid>
                            <Grid item xs={6} md={4} >
                                <Field id="avgSpeed" style={{ minWidth: 250 }} label="Avg Speed LFH" pattern="[0-9]*" onWheel={(e) => e.target.blur()}

                                    onKeyDown={(e) => {
                                        const keysAllowed = ['.']
                                        const keyPressed = e.key
                                        if (keysAllowed.includes(keyPressed)) {
                                            e.preventDefault()
                                        }
                                        if (e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 189) {
                                            e.preventDefault();
                                        }
                                    }}
                                    component={TextField} name="avgSpeed" type="number" />
                            </Grid>
                            
                            
                            
                            <Grid item xs={6} md={4} >
                                <Field style={{ minWidth: 250 }} label="Avg Width" component={TextField} name="avgWidth" id="avgWidth" pattern="[0-9]*" onWheel={(e) => e.target.blur()} onKeyDown={(e) => {
                                    const keysAllowed = ['.']
                                    const keyPressed = e.key
                                    if (keysAllowed.includes(keyPressed)) {
                                        e.preventDefault()
                                    }
                                    if (e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 189) {
                                        e.preventDefault();
                                    }
                                }} type="number" />
                            </Grid>
                            <Grid item xs={6} md={4} >
                                <Field style={{ minWidth: 250 }} label="Actual %Waste" component={TextField} name="actualPrcWaste" type="number" id="actualPrcWaste" pattern="[0-9]*" onWheel={(e) => e.target.blur()} onKeyDown={(e) => {
                                    if (e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 189) {
                                        e.preventDefault();
                                    }
                                }} />
                            </Grid>
                            <Grid item xs={6} md={4} >
                                <Field style={{ minWidth: 250 }} label="Preprint %" component={TextField} name="preprint" type="number" id="preprint" pattern="[0-9]*" onWheel={(e) => e.target.blur()} onKeyDown={(e) => {
                                    const keysAllowed = ['.']
                                    const keyPressed = e.key
                                    if (keysAllowed.includes(keyPressed)) {
                                        e.preventDefault()
                                    }
                                    if (e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 189) {
                                        e.preventDefault();
                                    }
                                }} />
                            </Grid>
                            <Grid item xs={6} md={4} >
                                <div>
                                    {/* <BaseGradeFormik name="baseGrade" label="Base Grade" /> */}
                                    <Field style={{ minWidth: 250 }} label="Base Grade" name="baseGrade" component={Select} >
                                        <MenuItem value={90}>90</MenuItem>
                                        <MenuItem value={95}>95</MenuItem>
                                        <MenuItem value={100}>100</MenuItem>
                                        <MenuItem value={105}>105</MenuItem>
                                        <MenuItem value={110}>110</MenuItem>
                                        <MenuItem value={115}>115</MenuItem>
                                        <MenuItem value={120}>120</MenuItem>
                                        <MenuItem value={125}>125</MenuItem>
                                        <MenuItem value={130}>130</MenuItem>
                                        <MenuItem value={135}>135</MenuItem>
                                        <MenuItem value={140}>140</MenuItem>
                                        <MenuItem value={145}>145</MenuItem>
                                        <MenuItem value={150}>150</MenuItem>
                                        <MenuItem value={160}>160</MenuItem>
                                    </Field>
                                </div>
                            </Grid>
                            <Grid item xs={6} md={4} >

                                <div>
                                    {/* <CostRateFormik name="costRate" label="Cost Rate" /> */}
                                    <Field style={{ minWidth: 250 }} label="Cost Rate" name="costRate" component={Select} >
                                        <MenuItem value={1}>1</MenuItem>
                                        <MenuItem value={1.1}>1.1</MenuItem>
                                        <MenuItem value={1.2}>1.2</MenuItem>
                                        <MenuItem value={1.3}>1.3</MenuItem>
                                        <MenuItem value={1.4}>1.4</MenuItem>
                                        <MenuItem value={1.5}>1.5</MenuItem>
                                        <MenuItem value={1.6}>1.6</MenuItem>
                                        <MenuItem value={1.7}>1.7</MenuItem>
                                    </Field>
                                </div>
                            </Grid>
                            <Grid item xs={6} md={4} >
                                <Field style={{ minWidth: 250 }} label="Consumption Baseline" component={TextField} name="consumptionBaseline" type="number" id="consumptionBaseline" pattern="[0-9]*" onWheel={(e) => e.target.blur()} onKeyDown={(e) => {
                                    if (e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 189) {
                                        e.preventDefault();
                                    }
                                }} />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        {
                            props.AddUpdateType === 'Add' ? <Button disabled={BaselineDateError !== ''} type="submit">Add</Button> : <Button disabled={BaselineDateError !== ''} type="submit">Update</Button>
                        }

                        <Button onClick={props.closeModal}>Cancel</Button>
                    </DialogActions>
                </Form>
            </Formik>
        </Dialog >
    );
};
export const SpecificCIList = props => {
    const navigate = useNavigate();
    const [selectedRows, setSelectedRows] = useState([]);
    console.log('propspropspropspropsprops', props);
    const ContImprove = props.ContImpList
    const selectedBLineData = props.selectedBLineData

    const formatnum = (num) => {
        let value = num.toFixed(2)
        return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
    function formatDate(date) {
        const month = new Date(date).getMonth() + 1; // Month is zero-based, so we add 1
        const day = new Date(date).getDate();
        const year = new Date(date).getFullYear() % 100; // Get last two digits of the year
        const formattedDate = `${month}/${day}/${year}`;

        return formattedDate;
    }
    
    ContImprove.forEach(element => {
        let totalSAVE = 0

        if (element.newPerWaste > 0 && element.wasteTotalCount > 0) {
            totalSAVE += element.totalWasteSavings
        }
        if (element.proNewLinealPerHr > 0 && element.proTotalCount > 0) {
            totalSAVE += element.totalProductionSaving
        }
        if ((element.consLbPerMsf > 0 && element.consCostLb > 0)) {
            totalSAVE += element.totalConsumptionSavings
        }
        if (element.hoursOfTraining > 0) {
            totalSAVE += element.totalTrainingCostSaved
        }
        if (element.hoursOfDownTime > 0) {
            totalSAVE += element.totalDownTimeSaved
        }
    // let value = element.totalWasteSavings + element.totalProductionSaving + element.totalConsumptionSavings + element.totalTrainingCostSaved + element.totalDownTimeSaved
    element.totalSaving = formatnum(totalSAVE)
    element.contImpName = selectedBLineData.baselineName + ` ${selectedBLineData.baselineStartDate !== '' ? ': (From ' + selectedBLineData.baselineStartDate + ' to ' + selectedBLineData.baselineEndDate + ')' : ''}`
    });
    const columns = [
        {
            name: "createdDate", label: "Date",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <DateView value={value} />
                    );
                },
                filter: true,
                filterType: 'custom',
                customFilterListOptions: {
                    update: UpdateFilterList
                },
                filterOptions: {
                    logic: DateFilterLogic,
                    display: (filterList, onChange, index, column) => <GridDateFilter filterList={filterList} onChange={onChange} index={index} column={column} />
                }
            }
        },
        { name: "contImpName", label: "Baseline Name" },
        { name: "totalSaving", label: "Total Saving" },

    ];
    const rowSelect = (rowsSelectedData, allRows, rowsSelected) => {
        setSelectedRows(rowsSelected);
    };
    const options = {
        filterType: 'checkbox', selectableRows: 'single', selectableRowsOnClick: true, print: false,
        download: false,
        fixedHeader: true,
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
            return <CustomSelectToolbar view={view} />;
        },
        onRowSelectionChange: rowSelect,
        rowsSelected: selectedRows,
        sortOrder: { name: 'createdDate', direction: 'desc' },
        setRowProps: () => ({
            onDoubleClick: (row) => {
                if (selectedRows.length == 0) {
                    const targetID = row.currentTarget.id;
                    const dataRow = parseInt(targetID.substring(targetID.lastIndexOf('-') + 1));
                    if (!isNaN(dataRow)) {
                        setSelectedRows([dataRow]);
                    }
                }
            }
        })
    };
    const view = () => {
        navigate('/ContinuousImprovementInfo/' + ContImprove[selectedRows[0]].id);
    };
    const DateView = props => {
        if (props.value === "0001-01-01T00:00:00") {
            return null;
        }
        const d = new Date(props.value);
        return <div>{d.toLocaleDateString()}</div>;
    };
    return (
        <Dialog fullWidth maxWidth={"md"} open={props.modalOpen} >
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} sx={{ marginRight: '20px' }} >
                        <MUIDataTable
                            title={"Continuous Improvements"}
                            data={ContImprove}
                            columns={columns}
                            options={options}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.closeModal}>Close</Button>
            </DialogActions>
        </Dialog >
    );
};