import React, { useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { OpenDrawer, CloseDrawer } from '../../actions/MenuActions'
import MenuList from './MenuList';
const MenuDrawer = props => {
    const dispatch = useDispatch();
    const drawerOpen = useSelector(s => s.Menu.drawerOpen, shallowEqual);
    return <SwipeableDrawer
        anchor={'left'}
        open={drawerOpen}
        onClose={()=>dispatch(CloseDrawer())}
        onOpen={()=>dispatch(OpenDrawer())}
    >
        <MenuList />
    </SwipeableDrawer>
}
export default MenuDrawer