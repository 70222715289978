import Constants from '../../constants';
//Modules
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
//MUI
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MUIDataTable from "mui-datatables";
//Compontents
import CustomSelectToolbar from '../CustomSelectToolbar';
//Actions
import { ConfirmAction } from '../../actions/ConfirmActionActions';
import { DeleteIngredientVerificationByID } from '../../actions/IngredientVerificationActions';
import { DeleteHeatVesselByID } from '../../actions/HeatVesselActions';
import { DeleteGlueRollByID } from '../../actions/GlueRollActions';
import { DeleteGlueLineByID } from '../../actions/GlueLineActions';
import { DeleteChecklistByID } from '../../actions/ChecklistActions';
//Misc
import { useVisitSurveys } from './VisitSurveys';
import { DeleteThermalByID } from '../../actions/ThermalImageActions';
const constants = Constants;
const DateView = props => {
    if (props.value === "0001-01-01T00:00:00") {
        return null;
    }
    const d = new Date(props.value);
    return <div>{d.toLocaleDateString()}</div>;
};
export const SurveysGrid = props => {
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectedRows, setSelectedRows] = useState([]);
    const surveys = useVisitSurveys(props.visitID);
    //useEffect(() => {
    //    surveys = [];
        
    //}, [ivs]);
    const ClickOpen = () => {
        if (selectedRows.length > 0) {
            navigate(surveys[selectedRows[0]].url);
        } else {
            alert("Select Survey From List");
        }
    };
    const ClickDelete = () => {
        if (selectedRows.length > 0) {
            const survey = surveys[selectedRows[0]];
            switch (survey.surveyType) {
                case constants.Surveys.IngredientVerification:
                    dispatch(ConfirmAction("Do you want to delete this Ingredient Verification?", DeleteIngredientVerificationByID(survey.id),null));
                    break;
                case constants.Surveys.HeatVessel:
                    dispatch(ConfirmAction("Do you want to delete this HeatVessel?", DeleteHeatVesselByID(survey.id), null));
                    break;
                case constants.Surveys.GlueRoll_Measurements:
                case constants.Surveys.GlueRoll_Pictures:
                    dispatch(ConfirmAction("Do you want to delete this GlueRoll?", DeleteGlueRollByID(survey.id), null));
                    break;
                case constants.Surveys.GlueLine:
                    dispatch(ConfirmAction("Do you want to delete this GlueLine?", DeleteGlueLineByID(survey.id), null));
                    break;
                case constants.Surveys.Checklist:
                    dispatch(ConfirmAction("Do you want to delete this Checklist?", DeleteChecklistByID(survey.id), null));
                    break;
                case constants.Surveys.Thermal:
                    dispatch(ConfirmAction("Do you want to delete this Thermal?",DeleteThermalByID(survey.id), null));
                    break;
                default:
                    alert("Delete Action not Implemented!");
                    break; 
            }
            setSelectedRows([]);
        } else {
            alert("Select Survey From List");
        }
    }
    const columns = [
        { name: "surveyType", label: "Type" }, 
        { name:"name",label:"Name" },
        {
            name: "createdDate", label: "Date", options: {
                display: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <DateView value={value} />
                    );
                }
            }
        }];
    const rowSelect = (rowsSelectedData, allRows, rowsSelected) => {
        setSelectedRows(rowsSelected);
    };
    const options = {
        filterType: 'checkbox', selectableRows: 'single', selectableRowsOnClick: true, print: false,
        download: false,
        fixedHeader: true,
        responsive: 'standard',
        
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
            return <CustomSelectToolbar selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows}
                clickEdit={ClickOpen} clickDelete={ClickDelete} />;
        },
        onRowSelectionChange: rowSelect,
        rowsSelected: selectedRows
    };
    return (     
            <Card>
                <CardContent>
                    <MUIDataTable
                        title={"Surveys"}
                        data={surveys}
                        columns={columns}
                        options={options}
                    />
                </CardContent>
            </Card>
            );
};
