import React, { useState } from 'react'
import { AddPersonnel, UpdatePersonnel, DeletePerson } from '../../actions/PersonnelActions';
import { useSelector, useDispatch } from 'react-redux'
import AddCustomToolbar from "../AddCustomToolbar";
import CustomSelectToolbar from '../CustomSelectToolbar';
import MUIDataTable from "mui-datatables";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Checkbox from '@mui/material/Checkbox';
import { Formik, Form, Field } from 'formik';
import { TextField, CheckboxWithLabel } from 'formik-mui'
export const PersonnelGridView = props => {
    //const classes = useStyles();
    const dispatch = useDispatch();
    const personnel = useSelector(state => state.Personnel.filter(p => p.plantID === props.plantID && !p.deleted));
    const [modalOpen, setModal] = useState(false);
    const initPerson = { name: '', title: '', email: '', primary: false };
    const [selectedRows, setSelectedRows] = useState([]);
    const [addPerson, setAddPerson] = useState(false);
    const columns = [{ name: "name", label: "Name" }, { name: "title", label: "Title" }, { name: "email", label: "Email" }, {name:"phoneNumber",label:"Phone Number"}
        , {
            name: "mobilePhone", label: "Mobile"
        }];
    const ClickOpen = () => {
        setAddPerson(false);
        setModal(true);
    };
    const ClickDelete = () => {
        dispatch(DeletePerson(personnel[selectedRows[0]]));
        setSelectedRows([]);
    };
    const ClickAdd = (e) => {
        console.log("personnel add clicked");
        setModal(true);
        setAddPerson(true);
    };
    const rowSelect = (rowsSelectedData, allRows, rowsSelected) => {
        setSelectedRows(rowsSelected);
    };
    const options = {
        filterType: 'checkbox', selectableRows: 'none', selectableRowsOnClick: false, print: false,
        download: false,
        fixedHeader: true,
        responsive: 'standard',
        customToolbar: () => {
            return null;
        },
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
            return <CustomSelectToolbar selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows} clickEdit={ClickOpen} clickDelete={ClickDelete } />;
        },
        onRowSelectionChange: rowSelect,
        rowsSelected: selectedRows
    };
    const handleSubmit = (e) => {
        console.log("Handle Submit", e);
        setModal(false);
        if (addPerson) {
            e.plantID = props.plantID;
            dispatch(AddPersonnel(e));
        } else { 
            //Update
            dispatch(UpdatePersonnel(e, personnel[selectedRows[0]]));
        }
    }
    const getInitValues = () => {
        if (modalOpen) {
            if (addPerson) {
                return initPerson;
            } else {
                return personnel[selectedRows[0]];
            }
        } else
            return null;
    }
    return (
        <Card>
            <CardContent>
                <MUIDataTable
                    title={"Personnel"}
                    data={personnel}
                    columns={columns}
                    options={options}
                />
                <PersonnelFormDialog modalOpen={modalOpen} closeModal={() => setModal(false)} onSubmit={handleSubmit} initialValues={getInitValues()} />
            </CardContent>
        </Card>
    );
}
export const PersonnelFormDialog = props => {

    return (
        <Dialog open={props.modalOpen} onClose={props.closeModal} >
            <Formik initialValues={props.initialValues} onSubmit={props.onSubmit}>
                <Form>
                    <DialogTitle >Add Personnel</DialogTitle >
                    <DialogContent>
                        <div>
                            <Field
                                component={TextField}
                                name="name"
                                type="name"
                                label="Name"
                            />
                        </div>
                        <div>
                            <Field
                                component={TextField}
                                name="title"
                                type="title"
                                label="Title"
                            />
                        </div>
                        <div>
                            <Field
                                component={TextField}
                                name="email"
                                type="email"
                                label="Email"
                            />
                        </div>
                        <div>
                            <Field
                                component={TextField}
                                name="phoneNumber"
                                type="tel"
                                label="Phone Number"
                            />
                        </div>
                        <div>
                            <Field
                                component={CheckboxWithLabel}
                                name="primary"
                                type='checkbox'
                                Label={{ label: 'Primary' }}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit">Save</Button>
                        <Button onClick={props.closeModal}>Cancel</Button>
                    </DialogActions>
                </Form>
            </Formik>
        </Dialog >
        );
}