import Constants from '../../constants';
//Modules
import React, { useEffect, useContext, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

//MUI
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import PrintIcon from '@mui/icons-material/Print';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import makeStyles from '@mui/styles/makeStyles';
import EditIcon from '@mui/icons-material/Edit';
//Components
import { PlantLink } from '../MetaData/Links';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { usePlantIDFromVisitID } from '../MetaData/PlantOptions';
import { DataForm, DataTextBox, DataDate, DataSelect, DataNumber, DataFormContext } from '../FormManager';
import { ConfirmAction } from '../../actions/ConfirmActionActions';
import { NativeSelectFormik } from '../FormikUI/NativeSelectFormik';
import { FormControl, IconButton, InputLabel } from '@mui/material';

import WasteBaselineDetail from './WasteBaselineDetail';
import ProductionBaselineDetail from './ProductionBaselineDetail';
import ConsumptionDetail from './ConsumptionDetail';
import Training from './Training';
import DownTime from './Donwntime';
import { ForkLeft, Height, SavedSearch } from '@mui/icons-material';
import { selectBaselinesByPlantId } from '../../selectors/BaselineSelectors';
import CiSignatureDialog from './CIsignatureDialog';
import { AddContImprove, DeleteContImprove, UpdateContImprove } from '../../actions/ContinuousImprovementAction';
import { Formik, Form, Field } from 'formik';
import { usePlantDataFromVisitID } from '../../misc/UseFuncs';
const ContinuousImprovementView = props => {
    const params = useParams();
    const glID = params.VisitID;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { visit, userObj } = useSelector(state => {
        return {
            visit: state.Visits.find(gr => gr.id == glID),
            userObj: state.Auth.userData
        };
    }, shallowEqual);
    //Get Latest Data and Check if still valid.

    const plant = usePlantDataFromVisitID(params.VisitID);
    const plantID = usePlantIDFromVisitID(visit ? params.VisitID : null);
    const user = userObj ? userObj.fullName : ''
    const tID = params.VisitID;
    const UpdateId = params.ContImpID;
    const [value, setValue] = React.useState('');
    const [inprovementId, setinprovementId] = React.useState(0);
    const [selectedBLine, setSelectedBaseline] = React.useState(null);
    const [isAddWaste, setisAddWaste] = React.useState(false);
    const [isAddProduction, setisAddProduction] = React.useState(false);
    const [isAddCnsumption, setisAddCnsumption] = React.useState(false);
    const [isAddTraining, setisAddTraining] = React.useState(false);
    const [isAddDownTime, setisAddDownTime] = React.useState(false);
    const [isDisabledCnsumption, setisDisabledCnsumption] = React.useState(false);
    const [isValidFOrSave, setisValidFOrSave] = React.useState(true);
    const [readyToSign, setreadyToSign] = React.useState(false);
    const [readyToPrint, setreadyToPrint] = React.useState(false);
    const [errorSection, seterrorSection] = React.useState('');
    const [sigOpen, setSigOpen] = useState(false);
    const [sigGmOpen, setGMSigOpen] = useState(false);
    const [enteredPeriodValue, setenteredPeriodValue] = useState(0);
    const [SummaryText, setSummaryText] = useState('');
    const [SummaryErr, setSummaryErr] = useState('');
    const [edittextid, setedittextid] = useState(null);
    const [addMore, setaddMore] = useState(true);
    const [SumTextArr, setSumTextArr] = useState([]);
    const [isloading, setisloading] = useState(false);
    const [isloadingSecond, setloadingSecond] = useState(false);
    const [TotalDisplaySaving, setTotalDisplaySaving] = useState(0);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showChangeClose, setShowChangeClose] = useState(false);
    const [confirmedValue, setConfirmedValue] = useState('');
    const [isUpdateAny, setisUpdateAny] = useState(false);

    const Satedata = useSelector(s => {
        return s
    });
    const baselines = useSelector(s => selectBaselinesByPlantId(s, plantID));
    const sortBaselineByCreatedDate = (data) => {
        return data.sort((a, b) => {
            const dateA = new Date(a.createdDate);
            const dateB = new Date(b.createdDate);
            return dateB - dateA;
        });
    };
    let baseLineList = sortBaselineByCreatedDate(baselines);

    const DetailObj = useSelector(state => {
        if (Array.isArray(state.ContImprove)) { //Fix for stored state mismatch
            return state.ContImprove.find(i => i.id === params.ContImpID)
        } else {
            return null;
        }
    }, shallowEqual);
    const handleDel = () => {
        const visitID = params.VisitID;
        dispatch(ConfirmAction("Do you want to delete this Continuous Improvement?", DeleteContImprove(DetailObj), null,
            (confirmed) => {
                if (confirmed) {
                    navigate("/VisitOverview/" + visitID, { replace: true });
                }
            }));
    }

    const handleClose = () => {
        if (isUpdateAny) {
            setShowChangeClose(true);
        } else {
            navigate(-1);
        }
    }
    const getLocalISOTime = () => {
        const now = new Date();
        const offset = -now.getTimezoneOffset();
        const offsetHours = offset / 60;
        const offsetMinutes = Math.abs(offset % 60);
        const offsetSign = offset >= 0 ? '+' : '-';

        const pad = (num) => {
            const norm = Math.abs(Math.floor(num));
            return (norm < 10 ? '0' : '') + norm;
        };

        return `${now.getFullYear()}-${pad(now.getMonth() + 1)}-${pad(now.getDate())}T${pad(now.getHours())}:${pad(now.getMinutes())}:${pad(now.getSeconds())}${offsetSign}${pad(offsetHours)}:${pad(offsetMinutes)}`;
    };
    const [ReqObj, setReqObj] = useState({
        id: null,
        visitID: params.VisitID,
        plantID: plantID,
        baselineID: null,
        selectedDate: new Date().toISOString(),
        selectedRadioValue: null,
        newPerWaste: 0,
        wasteTotalCount: 0,
        proNewLinealPerHr: 0,
        proTotalCount: 0,
        consLbPerMsf: 0,
        consCostLb: 0,
        consTotalCount: 0,
        hoursOfTraining: 0,
        hoursOfDownTime: 0,
        totalWasteSavings: 0,
        totalProductionSaving: 0,
        totalConsumptionSavings: 0,
        totalTrainingCostSaved: 0,
        totalDownTimeSaved: 0,
        createdDate: new Date().toISOString(),
        signatureCapture: '',
        signatureCaptureBy: user,
        baselineName: '',
        startDate: '',
        enddate: '',
        selectedStartDate: '',
        periodValues: 0,
        projectSummary: '',
        isSignedByGM: false,
        gmSignatureData: '',
        gmSignatureCaptureTime: getLocalISOTime(),
        userSignatureCaptureTime: '',
        userSignatureAddedName: '',
        gmsignatureCaptureBy: user,
        fromDetailUserUploadedByName: '',
        fromDetailUserUploadedFileData: '',
        fromDetailUserUploadedFileName: '',
        fromDetailUserUploadedTime: getLocalISOTime(),
        gmRepDisplayUserName: '',
    })

    const [ErrObj, setErrObj] = useState({
        newPerWasteErr: 'Required',
        wasteTotalCountErr: 'Required',
        proNewLinealPerHrErr: 'Required',
        proTotalCountErr: 'Required',
        consLbPerMsfErr: 'Required',
        consCostLbErr: 'Required',
        consTotalCountErr: 'Required',
        hoursOfTrainingErr: 'Required',
        hoursOfDownTimeErr: 'Required',
    })
    useEffect(() => {
        if (DetailObj) {
            console.log('------------------------------------------------------------------------------', DetailObj);
            const bData = ReqObj
            bData.id = DetailObj.id
            bData.visitID = DetailObj.visitID
            bData.plantID = DetailObj.plantID
            bData.baselineID = DetailObj.baselineID
            bData.selectedDate = DetailObj.selectedDate
            bData.selectedRadioValue = DetailObj.selectedRadioValue
            bData.newPerWaste = DetailObj.newPerWaste
            bData.wasteTotalCount = DetailObj.wasteTotalCount
            bData.proNewLinealPerHr = DetailObj.proNewLinealPerHr
            bData.proTotalCount = DetailObj.proTotalCount
            bData.consLbPerMsf = DetailObj.consLbPerMsf
            bData.consCostLb = DetailObj.consCostLb
            bData.consTotalCount = DetailObj.consTotalCount
            bData.hoursOfTraining = DetailObj.hoursOfTraining
            bData.hoursOfDownTime = DetailObj.hoursOfDownTime
            bData.totalWasteSavings = DetailObj.totalWasteSavings
            bData.totalProductionSaving = DetailObj.totalProductionSaving
            bData.totalConsumptionSavings = DetailObj.totalConsumptionSavings
            bData.totalTrainingCostSaved = DetailObj.totalTrainingCostSaved
            bData.totalDownTimeSaved = DetailObj.totalDownTimeSaved
            bData.createdDate = DetailObj.createdDate
            bData.signatureCapture = DetailObj.signatureCapture
            bData.signatureCaptureBy = DetailObj.signatureCaptureBy
            bData.gmsignatureCaptureBy = DetailObj.gmsignatureCaptureBy
            bData.baselineName = DetailObj.baselineName
            bData.startDate = DetailObj.startDate
            bData.enddate = DetailObj.enddate
            bData.selectedStartDate = DetailObj.selectedStartDate
            bData.periodValues = DetailObj.periodValues > 0 ? DetailObj.periodValues : 0
            bData.projectSummary = DetailObj.projectSummary !== "" ? DetailObj.projectSummary : ''
            bData.isSignedByGM = DetailObj.isSignedByGM
            bData.gmSignatureData = DetailObj.gmSignatureData
            bData.gmSignatureCaptureTime = DetailObj.gmSignatureCaptureTime
            bData.userSignatureCaptureTime = DetailObj.userSignatureCaptureTime
            bData.fromDetailUserUploadedByName = DetailObj.fromDetailUserUploadedByName
            bData.fromDetailUserUploadedFileData = DetailObj.fromDetailUserUploadedFileData
            bData.fromDetailUserUploadedFileName = DetailObj.fromDetailUserUploadedFileName
            bData.fromDetailUserUploadedTime = DetailObj.fromDetailUserUploadedTime
            bData.gmRepDisplayUserName = DetailObj.gmRepDisplayUserName
            setenteredPeriodValue(DetailObj.periodValues)
            setReqObj({ ...bData })
            if (DetailObj.projectSummary !== "" && DetailObj.projectSummary !== null) {
                setSumTextArr(JSON.parse(DetailObj.projectSummary))
                setaddMore(false)
            }
            setTimeout(() => {
                const inputField = document.getElementById('gmRepDisplayUserName');
                if (inputField) {
                    inputField.value = DetailObj.gmRepDisplayUserName;
                }
            }, 1000);
        }
    }, [DetailObj]);

    useEffect(() => {
        if (DetailObj) {
            let erObj = ErrObj
            const data = baseLineList.find((line) => line.id == DetailObj.baselineID)
            setSelectedBaseline(data)
            if (DetailObj.newPerWaste > 0 && DetailObj.wasteTotalCount > 0) {
                erObj.newPerWasteErr = ''
                erObj.wasteTotalCountErr = ''
                setisAddWaste(true)
            }
            if (DetailObj.proNewLinealPerHr > 0 && DetailObj.proTotalCount > 0) {
                erObj.proNewLinealPerHrErr = ''
                erObj.proTotalCountErr = ''
                setisAddProduction(true)
            }
            if ((DetailObj.consLbPerMsf > 0 && DetailObj.consCostLb > 0 && DetailObj.consTotalCount > 0)) {
                erObj.consLbPerMsfErr = ''
                erObj.consCostLbErr = ''
                erObj.consTotalCountErr = ''
                setisAddCnsumption(true)
            }
            if (DetailObj.hoursOfTraining > 0) {
                erObj.hoursOfTrainingErr = ''
                setisAddTraining(true)
            }
            if (DetailObj.hoursOfDownTime > 0) {
                erObj.hoursOfDownTimeErr = ''
                setisAddDownTime(true)
            }
            setreadyToSign(true)
            setreadyToPrint(true)
            setErrObj({ ...erObj })
        }
    }, [DetailObj]);

    useEffect(() => {
        console.log('ReqObj.totalWasteSavings -----------------------------------', ReqObj.totalWasteSavings);
        
        console.log('ReqObj.totalProductionSaving -----------------------------------', ReqObj.totalProductionSaving);
        
        console.log('ReqObj.totalConsumptionSavings -----------------------------------', ReqObj.totalConsumptionSavings);
        
        console.log('ReqObj.totalTrainingCostSaved -----------------------------------', ReqObj.totalTrainingCostSaved);
        
        console.log('ReqObj.totalDownTimeSaved -----------------------------------', ReqObj.totalDownTimeSaved);
        
        
        const req = ReqObj
        let totalSAVE = 0

        if (ReqObj.newPerWaste > 0 && ReqObj.wasteTotalCount > 0) {
            totalSAVE += req.totalWasteSavings
        }
        if (ReqObj.proNewLinealPerHr > 0 && ReqObj.proTotalCount > 0) {
            totalSAVE += req.totalProductionSaving
        }
        if ((ReqObj.consLbPerMsf > 0 && ReqObj.consCostLb > 0)) {
            totalSAVE += req.totalConsumptionSavings
        }
        if (ReqObj.hoursOfTraining > 0) {
            totalSAVE += req.totalTrainingCostSaved
        }
        if (ReqObj.hoursOfDownTime > 0) {
            totalSAVE += req.totalDownTimeSaved
        }
  
        setTotalDisplaySaving(totalSAVE)
        if ((totalSAVE > 100000)) {
            req.isSignedByGM = true
            setReqObj({ ...req })
        } else {
            req.isSignedByGM = false
            setReqObj({ ...req })
        }
    }, [ReqObj.totalWasteSavings, ReqObj.totalProductionSaving, ReqObj.totalConsumptionSavings, ReqObj.totalTrainingCostSaved, ReqObj.totalDownTimeSaved]);
    function isInteger(num) {
        return num % 1 === 0;
    }
    const handleLineChange = selectedBaseline => {
        const data = baseLineList.find((line) => line.id == selectedBaseline)
        // const bData = ReqObj
        // bData.baselineID = data.id
        // bData.baselineName = data.baselineName
        // setReqObj({ ...bData })
        setSelectedBaseline(data)
        updateRequest(data.id, 'baselineID')
        updateRequest(data.baselineName + ` ${data.baselineStartDate !== '' ? ': (From ' + formatDate(data.baselineStartDate) + ' to ' + formatDate(data.baselineEndDate) + ')' : ''}`, 'baselineName')
    }
    const handleCloseConfirmation = (confirmed) => {
        if (confirmed) {
            navigate(-1);
        }
        setShowChangeClose(false);
    };
    const handleConfirmation = (confirmed) => {
        if (confirmed) {
            setTotalDisplaySaving(0)
            setenteredPeriodValue(0)
            updateRequest(0, 'periodValues')
            if ('day' === confirmedValue) {
                updateRequest(ReqObj.selectedStartDate, 'startDate')
                updateRequest(confirmedValue, 'selectedRadioValue')
                setisDisabledCnsumption(false)
            }
            if ('week' === confirmedValue) {
                updateRequest(ReqObj.selectedStartDate, 'startDate')
                updateRequest(confirmedValue, 'selectedRadioValue')
                setisDisabledCnsumption(false)
            }
            if ('month' === confirmedValue) {
                updateRequest(confirmedValue, 'selectedRadioValue')
                setisDisabledCnsumption(false)
            }
            if (ReqObj.periodValues > 0) {
                // updateRequest(new Date(setDate()).toISOString(), 'enddate')
            }
        }
        setShowConfirmation(false);
    };
    const handleRadioChange = e => {

        const newValue = e.target.value;
        // console.log('check 1---------', newValue);
        // console.log('check 2---------', ReqObj.selectedRadioValue);
        // console.log('check enteredPeriodValue---------', enteredPeriodValue);
        if (newValue !== 'day' && ReqObj.selectedRadioValue === 'day' && enteredPeriodValue > 0 && !isInteger(enteredPeriodValue)) {
            // if (!isInteger(enteredPeriodValue)) {
            // Show confirmation dialog if changing to 'Week'
            setShowConfirmation(true);
            setConfirmedValue(newValue); // Store the confirmed value 
            // }
        } else {
            // updateRequest(newValue, 'selectedRadioValue')
            const bData = ReqObj
            let temp = moment(ReqObj.startDate).format('MM/DD/YY')
            let dValue = temp + ' 10:00 AM'
            let date = new Date(dValue)
            if (e.target.value === 'day') {
                let temp = moment(ReqObj.selectedStartDate).format('MM/DD/YY')
                let dValue = temp + ' 10:00 AM'
                updateRequest(new Date(dValue), 'startDate')
                updateRequest('day', 'selectedRadioValue')
                setisDisabledCnsumption(false)
            }
            if (e.target.value === 'week') {
                let temp = moment(ReqObj.selectedStartDate).format('MM/DD/YY')
                let dValue = temp + ' 10:00 AM'
                updateRequest(new Date(dValue), 'startDate')
                updateRequest('week', 'selectedRadioValue')
                setisDisabledCnsumption(false)
            }
            if (e.target.value === 'month') {
                updateRequest('month', 'selectedRadioValue')
                setisDisabledCnsumption(false)
                let newdate = date.setDate(1);
                updateRequest(new Date(newdate).toISOString(), 'startDate')
                let temp = moment(ReqObj.startDate).format('MM/DD/YY')
                let dValue = temp + ' 10:00 AM'
                let data = new Date(dValue)
                let setnew = new Date(data.setMonth(data.getMonth() + (parseInt(enteredPeriodValue))));
                let previousDay = new Date(setnew);
                let currentDayOfMonth = previousDay.getDate();
                previousDay.setDate(currentDayOfMonth - 1);

                date = previousDay
                updateRequest(new Date(date).toISOString(), 'enddate')
            } else if (ReqObj.periodValues > 0) {
                updateRequest(new Date(setDValue()).toISOString(), 'enddate')
            }
        }



    }
    const setDValue = () => {
        if (parseInt(ReqObj.periodValues) > 0) {
            let temp = moment(ReqObj.startDate).format('MM/DD/YY')
            let dValue = temp + ' 10:00 AM'
            let date = new Date(dValue)
            if (ReqObj.selectedRadioValue === 'day') {
                if (enteredPeriodValue >= 1) {
                    if (!isInteger(enteredPeriodValue)) {
                        date.setDate(date.getDate() + (parseInt(enteredPeriodValue)))
                    } else {
                        date.setDate(date.getDate() + (parseInt(enteredPeriodValue) - 1))
                    }
                } else {
                    date.setDate(date.getDate())
                }
            }
            if (ReqObj.selectedRadioValue === 'week') {
                date.setDate(date.getDate() + parseInt(ReqObj.periodValues * 7))
            }
            if (ReqObj.selectedRadioValue === 'month') {
                let newdate = date.setDate(1);
                updateRequest(new Date(newdate).toISOString(), 'startDate')
                let temp = moment(ReqObj.startDate).format('MM/DD/YY')
                let dValue = temp + ' 10:00 AM'
                let data = new Date(dValue)
                let setnew = new Date(data.setMonth(data.getMonth() + (parseInt(enteredPeriodValue))));
                let previousDay = new Date(setnew);
                let currentDayOfMonth = previousDay.getDate();
                previousDay.setDate(currentDayOfMonth - 1);

                date = previousDay
            }
            updateRequest(new Date(date).toISOString(), 'enddate')
            return date
        } else {
            return ''
        }

    }
    const handlePrint = () => {
        navigate('/ContinuousImprovementPrint/' + params.ContImpID);
    }

    const wasteHandle = (data, a) => {
        setisValidFOrSave(true)
        const bData = ReqObj
        bData.newPerWaste = data.newWaste
        bData.wasteTotalCount = data.totalCount
        bData.totalWasteSavings = data.totalWasteSavings
        let erObj = ErrObj
        if (a === 'newWaste') {
            erObj.newPerWasteErr = ''
        }
        if (a === 'totalCount') {
            erObj.wasteTotalCountErr = ''
        }
        setErrObj({ ...erObj })
        setReqObj({ ...bData })
    }
    const ProductionHandle = (data, a) => {
        setisValidFOrSave(true)
        const bData = ReqObj
        bData.proNewLinealPerHr = data.proNewLinealPerHr
        bData.proTotalCount = data.proTotalCount
        bData.totalProductionSaving = data.totalProductionSaving
        let erObj = ErrObj
        if (a === 'proNewLinealPerHr') {
            erObj.proNewLinealPerHrErr = ''
        }
        if (a === 'proTotalCount') {
            erObj.proTotalCountErr = ''
        }
        setErrObj({ ...erObj })
        setReqObj({ ...bData })
    }
    const ConsumptionHandle = (data, a) => {
        setisValidFOrSave(true)
        const bData = ReqObj
        bData.consLbPerMsf = data.consLbPerMsf
        bData.consCostLb = data.consCostLb
        bData.consTotalCount = data.totalCount
        bData.totalConsumptionSavings = data.totalConsumptionSavings
        let erObj = ErrObj
        if (a === 'consLbPerMsf') {
            erObj.consLbPerMsfErr = ''
        }
        if (a === 'consCostLb') {
            erObj.consCostLbErr = ''
        }
        if (a === 'consTotalCount') {
            erObj.consTotalCountErr = ''
        }
        setErrObj({ ...erObj })

        setReqObj({ ...bData })
    }
    const TrainingHandle = (data) => {
        setisValidFOrSave(true)
        const bData = ReqObj
        bData.hoursOfTraining = data.hoursOfTraining
        bData.totalTrainingCostSaved = data.totalTrainingCostSaved
        let erObj = ErrObj
        erObj.hoursOfTrainingErr = ''
        setErrObj({ ...erObj })
        setReqObj({ ...bData })
    }
    const DonetimeHandle = (data) => {
        setisValidFOrSave(true)
        const bData = ReqObj
        bData.hoursOfDownTime = data.hoursOfDownTime
        bData.totalDownTimeSaved = data.totalDownTimeSaved
        let erObj = ErrObj
        erObj.hoursOfDownTimeErr = ''
        setErrObj({ ...erObj })
        setReqObj({ ...bData })
    }

    const updateErr = (value, key) => {
        let erObj = ErrObj
        erObj[key] = value
        setErrObj({ ...erObj })
    }

    const handelisSignedByGM = (event) => {
        const bData = ReqObj
        bData.isSignedByGM = event.target.checked
        setReqObj({ ...bData })
    };
    const handleWChecked = (event) => {        
        if (!event.target.checked) {
            updateRequest(0, 'newPerWaste')
            updateRequest(0, 'wasteTotalCount')
            updateRequest(0, 'totalWasteSavings')
        } else {
            updateRequest(enteredPeriodValue, 'wasteTotalCount')
            updateErr('Required', 'newPerWasteErr')
        }
        setisAddWaste(event.target.checked)
    };
    const handlePChecked = (event) => {
        if (!event.target.checked) {
            updateRequest(0, 'proNewLinealPerHr')
            updateRequest(0, 'proTotalCount')
            updateRequest(0, 'totalProductionSaving')
        } else {
            updateRequest(enteredPeriodValue, 'proTotalCount')
            updateErr('Required', 'proNewLinealPerHrErr')
        }
        setisAddProduction(event.target.checked)
    };
    const handleCChecked = (event) => {
        if (!event.target.checked) {
            updateRequest(0, 'consLbPerMsf')
            updateRequest(0, 'consCostLb')
            updateRequest(0, 'consTotalCount')
            updateRequest(0, 'totalConsumptionSavings')
        } else {
            updateRequest(enteredPeriodValue, 'consTotalCount')
            updateErr('Required', 'consLbPerMsfErr')
            updateErr('Required', 'consCostLbErr')
        }
        setisAddCnsumption(event.target.checked)
    };
    const handleTChecked = (event) => {
        if (!event.target.checked) {
            updateRequest(0, 'hoursOfTraining')
            updateRequest(0, 'totalTrainingCostSaved')
        }
        setisAddTraining(event.target.checked)
    };
    const handleDTChecked = (event) => {
        if (!event.target.checked) {
            updateRequest(0, 'hoursOfDownTime')
            updateRequest(0, 'totalDownTimeSaved')
        }
        setisAddDownTime(event.target.checked)
    };

    const updateRequest = (value, key) => {
        setisUpdateAny(true)
        let req = ReqObj
        req[key] = value
        if (key !== 'gmRepDisplayUserName') {
            req.signatureCapture = ''
            req.userSignatureCaptureTime = ''
            req.gmSignatureData = ''
            req.gmSignatureCaptureTime = ''
        }
        setReqObj({ ...req })
        handleSave('set', true)
        seterrorSection('')
    }
    const handelSelectedDate = (value) => {
         let temp = moment(value).format('MM/DD/YY')
        let dValue = temp + ' 10:00 AM'
        updateRequest(new Date(dValue).toISOString(), 'selectedDate')
    }
    const dateHandler = (value) => {
        // updateRequest(new Date(value).toISOString(), 'startDate')
        let temp = moment(value).format('MM/DD/YY')
        let dValue = temp + ' 10:00 AM'
        console.log('value========================++++++++++++++++++++', new Date(dValue));
        updateRequest(new Date(dValue), 'startDate')
        updateRequest(value, 'selectedStartDate')
        if (ReqObj.periodValues > 0) {
            let date = setDValue()
        }
    }
    const handelPereiodValue = (value) => {
        setenteredPeriodValue(Number(value))
    }
    const handleGoButtonClick = (e) => {
        let temp = moment(ReqObj.startDate).format('MM/DD/YY')
        let dValue = temp + ' 10:00 AM'
        let date = new Date(dValue)

        if (ReqObj.selectedRadioValue === 'day') {
            if (enteredPeriodValue >= 1) {
                if (!isInteger(enteredPeriodValue)) {
                    date.setDate(date.getDate() + (parseInt(enteredPeriodValue)))
                } else {
                    date.setDate(date.getDate() + (parseInt(enteredPeriodValue) - 1))
                }
            } else {
                date.setDate(date.getDate())
            }
        }
        if (ReqObj.selectedRadioValue === 'week') {
            date.setDate(date.getDate() + parseInt(enteredPeriodValue * 7))
        }
        if (ReqObj.selectedRadioValue === 'month') {
            let newdate = date.setDate(1);
            updateRequest(new Date(newdate).toISOString(), 'startDate')
            let temp = moment(ReqObj.startDate).format('MM/DD/YY')
            let dValue = temp + ' 10:00 AM'
            let data = new Date(dValue)
            let setnew = new Date(data.setMonth(data.getMonth() + (parseInt(enteredPeriodValue))));
            let previousDay = new Date(setnew);
            let currentDayOfMonth = previousDay.getDate();
            previousDay.setDate(currentDayOfMonth - 1);
            date = previousDay
        }
        updateRequest(new Date(date).toISOString(), 'enddate')
        updateRequest(enteredPeriodValue, 'periodValues')
        updateRequest(enteredPeriodValue, 'wasteTotalCount')
        updateRequest(enteredPeriodValue, 'proTotalCount')
        updateRequest(enteredPeriodValue, 'consTotalCount')
    }
    function formatDate(date) {
        const month = new Date(date).getMonth() + 1; // Month is zero-based, so we add 1
        const day = new Date(date).getDate();
        const year = new Date(date).getFullYear() % 100; // Get last two digits of the year

        // Construct the formatted date string
        const formattedDate = `${month}/${day}/${year}`;

        return formattedDate;
    }
    function FromformatDate(value) {
        let temp = moment(value).format('MM/DD/YY')
        let dValue = temp + ' 10:00 AM'
        let date = new Date(dValue)
        const month = new Date(date).getMonth() + 1; // Month is zero-based, so we add 1
        const day = new Date(date).getDate();
        const year = new Date(date).getFullYear() % 100; // Get last two digits of the year

        // Construct the formatted date string
        const formattedDate = `${month}/${day}/${year}`;

        return formattedDate;
    }
    const formatnum = (num) => {
        let value = parseFloat(num)
        let formatted = Math.round(value);
        return parseFloat(formatted).toLocaleString();
    }
    const Tformatnum = (number) => {
        if (isNaN(number) || number === null) {
            return "Invalid Number";
        }
        if (Number.isInteger(number)) {
            return number.toString();
        } else {
            let formattedNumber = Number(number).toFixed(2);
            formattedNumber = formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return formattedNumber;
        }
    }



    const handelSignature = (bas64Str) => {
        // updateRequest(bas64Str, 'signatureCapture') 
        let req = ReqObj
        // req.userSignatureCaptureTime = new Date().toLocaleString()
        req.userSignatureCaptureTime = getLocalISOTime()
        req.signatureCapture = bas64Str
        req.signatureCaptureBy = user
        setloadingSecond(false)
        setReqObj({ ...req })
    }
    const handelGMSignature = (bas64Str) => {
        let req = ReqObj
        // req.gmSignatureCaptureTime = new Date().toLocaleString()
        req.gmSignatureCaptureTime = getLocalISOTime()
        req.gmSignatureData = bas64Str
        req.gmsignatureCaptureBy = user
        setloadingSecond(false)
        setReqObj({ ...req })
    }

    const deleteSummurryText = (value, index) => {
        let aaa = JSON.parse(JSON.stringify(SumTextArr))
        aaa.splice(index, 1)
        setSumTextArr([...aaa], 'projectSummary')
        updateRequest(JSON.stringify([...aaa]), 'projectSummary')
        setaddMore(true)
    }
    const editSummurryText = (value, index) => {
        setaddMore(true)
        setedittextid(index)
        setSummaryText(value)

        setTimeout(() => {
            const inputField = document.getElementById('summaryInputId');
            if (inputField) {
                inputField.value = value;
                inputField.focus()
            }
        }, 500);

    }
    const addMoreHandle = (e) => {
        setaddMore(true)
        setTimeout(() => {
            const inputField = document.getElementById('summaryInputId');
            if (inputField) {
                inputField.value = value;
                inputField.focus()
            }
        }, 500);
    }

    const addProjectSummary = (e) => {
        setSummaryErr('')
        const value = e.target.value;
        setSummaryText(value)
    };

    const addProjectSummaryOnTab = () => {
        if (SummaryText.trim() === '') {
            setSummaryErr('Required')
        }
        if (SummaryText.trim() !== '' && edittextid === null) {
            let aaa = JSON.parse(JSON.stringify(SumTextArr))
            aaa.push(SummaryText)
            // setSumTextArr([...aaa])
            setSumTextArr([...aaa], 'projectSummary')
            updateRequest(JSON.stringify([...aaa]), 'projectSummary')
            setSummaryText('')

            const inputField = document.getElementById('summaryInputId');
            if (inputField) {
                inputField.value = ''; // Setting the value to empty
                inputField.focus()
            }
            setedittextid(null)
            setaddMore(false)
        }

        if (SummaryText.trim() !== '' && edittextid !== null) {
            let aaa = JSON.parse(JSON.stringify(SumTextArr))
            aaa[edittextid] = SummaryText
            // setSumTextArr([...aaa])
            setSumTextArr([...aaa], 'projectSummary')
            updateRequest(JSON.stringify([...aaa]), 'projectSummary')
            setSummaryText('')

            const inputField = document.getElementById('summaryInputId');
            if (inputField) {
                inputField.value = ''; // Setting the value to empty
                inputField.focus()
            }
            setedittextid(null)
            setaddMore(false)
        }

    };

    const handleSave = (isReadyToSave, isCheckSign) => {
        // console.log('ReqObj----------------save-----------------------', ReqObj);
        let updated = ReqObj
        let isValid = true
        let isready = true
        let errorSection = ''
        if (updated.userSignatureCaptureTime === "") {
            updated.userSignatureCaptureTime = getLocalISOTime()
        }
        if (updated.gmSignatureCaptureTime === "") {
            updated.gmSignatureCaptureTime = getLocalISOTime()
        }
        if (isAddWaste || isAddProduction || isAddCnsumption || isAddTraining || isAddDownTime) {
            console.log('check 1');
            if (((ReqObj.periodValues !== enteredPeriodValue || ReqObj.periodValues <= 0) && (isAddWaste || isAddProduction || isAddCnsumption))) {
                if (errorSection === '') {
                    errorSection = 'periodValues'
                }
                isValid = false
                isready = false
                console.log('check 1.2');
            }
            if (isAddWaste && (ReqObj.newPerWaste <= 0 || ReqObj.wasteTotalCount <= 0)) {
                if (errorSection === '') {
                    errorSection = 'waste'
                }
                isValid = false
                isready = false
                console.log('check 2');
            }
            if (isAddProduction && (ReqObj.proNewLinealPerHr <= 0 || ReqObj.proTotalCount <= 0)) {
                if (errorSection === '') {
                    errorSection = 'production'
                }
                isValid = false
                isready = false
                console.log('check 3');
            }
            if (isAddCnsumption && (ReqObj.consLbPerMsf <= 0 || ReqObj.consCostLb <= 0 || ReqObj.consTotalCount <= 0)) {
                if (errorSection === '') {
                    errorSection = 'consumption'
                }
                isValid = false
                isready = false
                console.log('check 4');
            }
            if (isAddTraining && ReqObj.hoursOfTraining <= 0) {
                if (errorSection === '') {
                    errorSection = 'training'
                }
                isValid = false
                isready = false
                console.log('check 5');
            }
            if (isAddDownTime && ReqObj.hoursOfDownTime <= 0) {
                if (errorSection === '') {
                    errorSection = 'downTime'
                }
                isValid = false
                isready = false
                console.log('check 6');
            }
            if (isCheckSign) {
                if (ReqObj.signatureCapture === '') {
                    if (errorSection === '') {
                        errorSection = 'signature'
                    }
                    isValid = false
                    console.log('check 7');
                }
                if (ReqObj.isSignedByGM && (ReqObj.gmSignatureData === '' || ReqObj.gmRepDisplayUserName.trim() === '')) {
                    if (errorSection === '') {
                        errorSection = 'signature'
                    }
                    isValid = false
                    console.log('check 8');
                }
            } else {
                updated.signatureCapture = ''
                updated.gmSignatureData = ''
                updated.isSignedByGM = true
            }
            seterrorSection(errorSection)
            setreadyToSign(isready)
            setisValidFOrSave(isValid)
            setreadyToPrint(isValid)
            if (isValid && isReadyToSave === 'save') {
                if (isCheckSign) {
                    setisloading(true)
                } else {
                    setloadingSecond(true)
                }
                // console.log('call save Apiiiiiiiiiiiiiiiiiiiiiiiiiii', ReqObj);
                let data = dispatch(AddContImprove(updated));
                // console.log('call data datadatadatadatadatadatadatadatadatadata', data);
                setTimeout(() => {
                    // navigate(-1);
                    navigate('/ContinuousImprovementInfo/' + data.payload.id);
                }, 1000);
            } else if (isValid && isReadyToSave === 'update') {
                if (isCheckSign) {
                    setisloading(true)
                } else {
                    setloadingSecond(true)
                }
                // console.log('call Update Apiiiiiiiiiiiiiiiiiiiiiiiiiii', ReqObj);
                dispatch(UpdateContImprove(updated));
                setTimeout(() => {
                    navigate('/ContinuousImprovementInfo/' + params.ContImpID);
                }, 1000);
            } else {
                // console.log('errrr isReadyToSave', isReadyToSave);
                // console.log('errrr errorSection', errorSection);
            }
        } else {
            setisValidFOrSave(false)
        }
        if (errorSection !== '' && (isReadyToSave === 'save' || isReadyToSave === 'update')) {
            const element = document.getElementById(`${errorSection}`); // Get the element by its ID
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' }); // Scroll to the element
            }
        }
    }
    if (!plantID) {
        return <div>Loading Cointinuous Improvement Data from Database.</div>;
    }
    return (
        <DataForm actionType={Constants.Actions.ContImprove} data={ReqObj}>
            <Grid container sx={{ marginBottom: 12 }}>
                <Grid item xs={12}>

                    <Grid container spacing={3}>
                        <Grid item xs={4} sm={4} align="left">
                            {/* {readyToPrint ?
                                <Button
                                    variant="contained"
                                    startIcon={<PrintIcon />}
                                    onClick={handlePrint}
                                >
                                    Print
                                </Button> : <></>
                            } */}
                        </Grid>
                        <Grid item xs={4} sm={4} >
                            {ReqObj.id !== null ?
                                <Button variant="contained" color="secondary" startIcon={<DeleteIcon />} onClick={handleDel} >Delete </Button> : <></>
                            }
                        </Grid>
                        {/* <Grid item xs={4} sm={4} align="right">
                            {ReqObj.id !== null ? <Button style={{ marginRight: '4px' }} variant="contained" onClick={() => handleSave('update')} > {isloading ? <CircularProgress style={{marginRight: '12px'}} size={'small'} /> : <span>Update</span> } </Button> :
                                <Button style={{ marginRight: '4px' }} variant="contained" onClick={() => handleSave('save')} >{isloading ? <CircularProgress style={{marginRight: '12px'}} size={'small'} /> : <span>Save</span> } </Button>
                            }
                            <Button variant="contained" startIcon={<CloseIcon />} onClick={handleClose}>
                                Close
                            </Button>
                        </Grid> */}
                        <Grid item xs={4} sm={4} align="right" style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                            {ReqObj.id !== null ?
                                <> {isloadingSecond ? <
                                    div style={{ marginRight: '12px', height: '36px' }}><CircularProgress /></div> :
                                    <>
                                        {ReqObj.signatureCapture === "" && ReqObj.gmSignatureData === "" ?
                                            <Button style={{ marginRight: '4px' }} variant="contained" onClick={() => handleSave('update', false)} >Update Without Signature </Button> : <></>

                                        }
                                    </>
                                } </> :
                                <> {
                                    !isloadingSecond && (ReqObj.signatureCapture === "" && ReqObj.gmSignatureData === "") ? <Button style={{ marginRight: '4px' }} variant="contained" onClick={() => handleSave('save', false)} >Save Without Signature </Button> : isloadingSecond ? <div style={{ marginRight: '12px', height: '36px' }}><CircularProgress /></div> : <></> } </>
                            }
                            {ReqObj.id !== null ? <> {isloading ? <div style={{ marginRight: '12px', height: '36px' }}><CircularProgress /></div> : <Button style={{ marginRight: '4px' }} variant="contained" onClick={() => handleSave('update', true)} >Update </Button>} </> :
                                <> {!isloading ? <Button style={{ marginRight: '4px' }} variant="contained" onClick={() => handleSave('save', true)} >Save </Button> : isloading ? <div style={{ marginRight: '12px', height: '36px' }}><CircularProgress /></div> : <></>} </>
                            }

                            <Button variant="contained" startIcon={<CloseIcon />} onClick={handleClose}>
                                Close
                            </Button>
                        </Grid>
                        <Grid item md={4}>Base line visit Survey</Grid>
                        <Grid item md={4}><PlantLink plantID={plantID} /></Grid>
                        <Grid item md={4} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <DataDate dateHandler={handelSelectedDate} noBlurAction={true} name={'selectedDate'} label={'Date'} TextFieldProps={{ size: 'small' }} />
                            {/* <div>
                                <Button onClick={() => setSigOpen(true)}>Signature</Button>
                                {ReqObj.signatureCapture === '' ?
                                    <Typography style={{ marginLeft: '20px', fontSize: '0.75rem' }} color="error">Required</Typography> : <></>
                                }
                            </div> */}
                        </Grid>

                    </Grid>

                </Grid>
                <Grid item lg={12}>
                    <div>
                        {/* <BaselineSelect baseLineList={handleLineChange} TextFieldProps={{ size: 'small' }}  label="Baseline" name="selectedBaseline" /> */}
                        <BaselineSelect TextFieldProps={{ size: 'small' }}
                            name="selectedBLine"
                            options={baseLineList}
                            label="Baseline"
                            baseLinehandel={handleLineChange}
                            selectedBL={ReqObj.baselineID}
                            TransitionComponent={null}
                        />
                        {
                            ReqObj.baselineID === null ? <Typography style={{ marginLeft: '20px', fontSize: '0.75rem' }} color="error">Please select baseline</Typography> : <></>
                        }
                    </div>
                </Grid>

                {
                    selectedBLine !== null ? <>
                        <Grid item xs={12}>
                            <div style={{ padding: '16px', paddingBottom: '0px' }}>
                                <FormControlLabel checked={isAddWaste} style={{ marginRight: '25px' }} onChange={handleWChecked} control={<Checkbox />} label="Waste" />
                                <FormControlLabel checked={isAddProduction} style={{ marginRight: '25px' }} onChange={handlePChecked} control={<Checkbox />} label="Production" />
                                <FormControlLabel checked={isAddCnsumption} style={{ marginRight: '25px' }} onChange={handleCChecked} control={<Checkbox />} label="Consumption" />
                                <FormControlLabel checked={isAddTraining} style={{ marginRight: '25px' }} onChange={handleTChecked} control={<Checkbox />} label="Training" />
                                <FormControlLabel checked={isAddDownTime} style={{ marginRight: '25px' }} onChange={handleDTChecked} control={<Checkbox />} label="Downtime" />
                                {!isAddWaste && !isAddProduction && !isAddCnsumption && !isAddTraining && !isAddDownTime ?
                                    <Typography sx={{}} color="error">Please select atleast one checkbox</Typography> : <></>
                                }
                            </div>
                        </Grid>
                        {
                            isAddWaste || isAddProduction || isAddCnsumption || isAddTraining || isAddDownTime ? <>
                                <Grid item md={3} lg={2}>
                                    <div style={{ padding: '16px', paddingBottom: '0px' }}>
                                        <DataDate dateHandler={dateHandler} noBlurAction={true} value={ReqObj.selectedStartDate} name="selectedStartDate" label={'Start Date'} TextFieldProps={{ size: 'small' }} />
                                        {ReqObj.selectedStartDate === '' ?
                                            <Typography style={{ marginLeft: '40px', fontSize: '0.75rem' }} color="error">Please select start date</Typography> : <></>
                                        }
                                    </div>
                                </Grid>
                                {ReqObj.selectedStartDate !== '' ? <>
                                    <Grid item md={8} lg={3}>
                                        <div style={{ padding: '16px' }}>
                                            <RadioGroup
                                                value={ReqObj.selectedRadioValue}
                                                onChange={handleRadioChange}
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                            >
                                                <FormControlLabel value="day" control={<Radio />} label="Day" />
                                                <FormControlLabel value="week" control={<Radio />} label="Week" />
                                                <FormControlLabel value="month" control={<Radio />} label="Month" />
                                            </RadioGroup>
                                            {ReqObj.selectedRadioValue === null ?
                                                <Typography sx={{}} color="error">Please select day / week / month</Typography> : <></>
                                            }
                                        </div>

                                    </Grid>
                                    {ReqObj.selectedRadioValue != null ?
                                        <Grid item xs={12}>
                                            <Formik initialValues={ReqObj}>
                                                <Form>
                                                    <div style={{ display: 'flex', flex: 'flex-wrap', alignItems: 'center' }}>
                                                        <div style={{ paddingLeft: '16px', paddingTop: '12px', minWidth: 120, maxWidth: 190, marginRight: '7rem' }}>
                                                            <div id='periodValues' style={{ display: 'flex' }}>
                                                                <div style={{ minWidth: 170 }}>
                                                                    <DataNumber isNotAllowDecimal={ReqObj.selectedRadioValue !== 'day' ? true : false} noBlurAction={true} handelChange={handelPereiodValue} name={'periodValues'} label={'Enter ' + ReqObj.selectedRadioValue.charAt(0).toUpperCase() + ReqObj.selectedRadioValue.slice(1) + 's'}
                                                                        inputProps={{
                                                                            min: 0,
                                                                            max: 100,
                                                                            style: { width: '136px' }
                                                                        }}
                                                                    />
                                                                </div>
                                                                <Button style={{ maxWidth: '23px', maxHeight: '30px', minWidth: '46px', minHeight: '41px', marginTop: '4px' }} variant="contained" disabled={enteredPeriodValue > 0 ? false : true} onClick={handleGoButtonClick}>Go</Button>
                                                            </div>
                                                            {
                                                                enteredPeriodValue <= 0 ? <Typography align='right' style={{ fontSize: '0.75rem' }} color="error">Required</Typography> : <></>
                                                            }
                                                            {
                                                                enteredPeriodValue > 0 && enteredPeriodValue !== ReqObj.periodValues ? <Typography align='right' style={{ fontSize: '0.75rem' }} color="error">Please click on Go</Typography> : <></>
                                                            }

                                                        </div>
                                                        <div style={{ paddingLeft: '16px' }}>
                                                            {
                                                                ReqObj.periodValues > 0 ? <div align='left' style={{ paddingLeft: 5 }}>From: {FromformatDate(ReqObj.startDate)} to  {formatDate(ReqObj.enddate)} {ReqObj.periodValues >= 1 ? <> ({ReqObj.periodValues} {' ' + ReqObj.selectedRadioValue.charAt(0).toUpperCase() + ReqObj.selectedRadioValue.slice(1) + `${ReqObj.periodValues > 1 ? 's' : ''}`})
                                                                </> : <></>}
                                                                    {
                                                                        ReqObj.selectedRadioValue === 'day' && ReqObj.periodValues < 1 ? <p align='left' style={{ paddingLeft: 0, margin: 0 }}>
                                                                            <span>Shift/Day : {selectedBLine.shiftPerDay}</span> <br />
                                                                            <span style={{ paddingLeft: 0 }}>Hours/Shift : {selectedBLine.hoursPerShift}</span>
                                                                        </p> : <></>
                                                                    }
                                                                </div> : <></>
                                                            }
                                                        </div>
                                                    </div>
                                                    {
                                                        SumTextArr.length > 0 ?
                                                            <Typography style={{ paddingLeft: '20px', marginTop: '10px' }} variant='h6'>Project Summary</Typography>
                                                            : <></>
                                                    }
                                                    <ul style={{ marginLeft: '10px', margin: '0px' }}>

                                                        {
                                                            SumTextArr !== null ?
                                                                SumTextArr.map((e, i) => (
                                                                    <li key={i}>{e}
                                                                        <IconButton
                                                                            onClick={() => editSummurryText(e, i)}
                                                                            size="small">
                                                                            <EditIcon className='ciIcons' />
                                                                        </IconButton>
                                                                        {!addMore ?
                                                                            <IconButton
                                                                                onClick={() => deleteSummurryText(e, i)}
                                                                                size="small">
                                                                                <DeleteIcon className='ciIcons' />
                                                                            </IconButton> : <></>
                                                                        }
                                                                    </li>
                                                                ))
                                                                : <></>
                                                        }
                                                    </ul>
                                                    {
                                                        SumTextArr.length > 0 && !addMore ? <Typography onClick={addMoreHandle} style={{ paddingLeft: '20px', width: '200px', cursor: 'pointer' }} color={'primary'}>+ Add More</Typography>
                                                            : <></>
                                                    }
                                                    {addMore ? <>
                                                        <div style={{ marginTop: '20px', marginLeft: '15px', display: 'flex', alignItems: 'center' }}>
                                                            <Field style={{ width: '400px' }}
                                                                id="summaryInputId"
                                                                component={TextField}
                                                                type="text"
                                                                label="Project Summary"
                                                                onChange={addProjectSummary}
                                                            />
                                                            <Button onClick={addProjectSummaryOnTab}> Save</Button>
                                                        </div>
                                                        {SummaryErr !== '' ?
                                                            <Typography style={{ marginLeft: '20px', fontSize: '0.75rem' }} color="error">Required</Typography> : <></>
                                                        }
                                                    </> : <></>
                                                    }
                                                </Form>
                                            </Formik>
                                        </Grid> : <></>
                                    }
                                </>
                                    : <></>
                                }
                            </> : <></>
                        }
                        {
                            ReqObj.selectedRadioValue != null && ReqObj.periodValues > 0 && enteredPeriodValue === ReqObj.periodValues ? <>

                                <Grid item xs={12}>
                                    {isAddWaste ? <div id="waste" >
                                        <WasteBaselineDetail updateRequest={updateRequest} ErrObj={ErrObj} wasteHandle={wasteHandle} selectedBaseline={selectedBLine} selectedDays={ReqObj.selectedRadioValue} DetailObj={ReqObj} />
                                    </div> : <></>

                                    }
                                    {isAddProduction ?
                                        <div id="production">
                                            <ProductionBaselineDetail updateRequest={updateRequest} ErrObj={ErrObj} ProductionHandle={ProductionHandle} selectedBaseline={selectedBLine} selectedDays={ReqObj.selectedRadioValue} DetailObj={ReqObj} /> </div>
                                        : <></>
                                    }
                                    {isAddCnsumption ?
                                        <div id="consumption">
                                            <ConsumptionDetail updateRequest={updateRequest} ErrObj={ErrObj} ConsumptionHandle={ConsumptionHandle} selectedBaseline={selectedBLine} DetailObj={ReqObj} selectedDays={ReqObj.selectedRadioValue} /></div>
                                        : <></>
                                    }

                                    {
                                        isAddTraining ?
                                            <Grid item xs={12}>
                                                <div id="training">

                                                    <Training updateRequest={updateRequest} ErrObj={ErrObj} TrainingHandle={TrainingHandle} selectedBaseline={selectedBLine} DetailObj={ReqObj} />
                                                </div>
                                            </Grid> : <></>
                                    }

                                    {
                                        isAddDownTime ?
                                            <Grid item xs={12}>
                                                <div id="downTime">
                                                    <DownTime updateRequest={updateRequest} ErrObj={ErrObj} DonetimeHandle={DonetimeHandle} selectedBaseline={selectedBLine} DetailObj={ReqObj} />
                                                </div>
                                            </Grid> : <></>
                                    }
                                </Grid>
                            </> : <></>
                        }



                        <>

                            {/* {TotalDisplaySaving > 0 ? */}
                            {ReqObj.periodValues > 0 && enteredPeriodValue > 0 ?
                                <Grid container xl={12} style={{ padding: '24px' }}>
                                    <Grid spacing={1} container item xs={12} sx={{ border: 1, borderColor: 'lightgray', p: 2 }} >
                                        <Typography sx={{ pl: 1 }}><span style={{ fontWeight: 'bold' }} >Total Savings: ${Tformatnum(TotalDisplaySaving)}</span>     </Typography>
                                    </Grid>
                                </Grid> : <></>
                            }
                            {
                                readyToSign ?
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div style={{ padding: '16px', paddingBottom: '0px' }} id="signature">
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Button onClick={() => setSigOpen(true)}>Signature</Button>
                                                {ReqObj.signatureCapture !== '' ?
                                                    <Typography sx={{}}>Captured On: {moment(ReqObj.userSignatureCaptureTime).format('MM/DD/YY hh:mm a')}</Typography> : <></>
                                                }
                                            </div>

                                            {ReqObj.signatureCapture === '' ?
                                                <Typography style={{ marginLeft: '20px', fontSize: '0.75rem' }} color="error">Required</Typography> : <></>
                                            }
                                            <div>
                                                <Typography sx={{ pl: 1 }}>CCI representative ({user})</Typography>
                                            </div>
                                        </div>
                                        <div style={{ padding: '16px', paddingBottom: '0px' }} id="signature">
                                            {/* { !ReqObj.isSignedByGM ? <div>
                                                <FormControlLabel sx={{ pl: 1 }} checked={ReqObj.isSignedByGM} onChange={handelisSignedByGM} style={{ marginRight: '25px' }} control={<Checkbox />} label="Signed off by GM" />
                                            </div> : <></> } */}
                                            <div>
                                                <Formik>
                                                    <Form>
                                                        <Field style={{ width: '400px' }}
                                                            id="gmRepDisplayUserName"
                                                            onChange={(e => {
                                                                updateRequest(e.target.value, 'gmRepDisplayUserName')
                                                            })}
                                                            component={TextField}
                                                            type="text"
                                                            label={ReqObj.isSignedByGM ? 'GM Name' : 'Representative Name'}
                                                        />
                                                        {ReqObj.gmRepDisplayUserName === '' ?
                                                            <Typography align='right' style={{ marginRight: '5px', fontSize: '0.75rem' }} color="error">Required</Typography> : <></>
                                                        }
                                                    </Form>
                                                </Formik>
                                            </div>
                                            {ReqObj.isSignedByGM ?
                                                <Typography sx={{ pl: 1 }}> ({plant.name}) <span style={{ fontWeight: 'bold' }} >GM Signature / Date</span>     </Typography>
                                                : <Typography sx={{ pl: 1 }}> ({plant.name}) <span style={{ fontWeight: 'bold' }}>Representative Signature / Date</span></Typography>
                                            }
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Button onClick={() => setGMSigOpen(true)}>Signature</Button>
                                                {ReqObj.gmSignatureData !== '' ?
                                                    <Typography sx={{}}>Captured On: {moment(ReqObj.gmSignatureCaptureTime).format('MM/DD/YY hh:mm a')}</Typography> : <></>
                                                }
                                            </div>
                                            {ReqObj.gmSignatureData === '' ?
                                                <Typography style={{ marginLeft: '20px', fontSize: '0.75rem' }} color="error">Required</Typography> : <></>
                                            }
                                        </div>
                                    </div> : <></>
                            }


                        </>



                    </> : null
                }

                <CiSignatureDialog signatureCaptureDate={ReqObj.userSignatureCaptureTime} signatureCaptureBy={ReqObj.signatureCaptureBy} open={sigOpen} handelSignature={handelSignature} onClose={() => setSigOpen(false)} signatureImage={ReqObj.signatureCapture} />
                <CiSignatureDialog signatureCaptureDate={ReqObj.gmSignatureCaptureTime} signatureCaptureBy={ReqObj.gmsignatureCaptureBy} open={sigGmOpen} handelSignature={handelGMSignature} onClose={() => setGMSigOpen(false)} signatureImage={ReqObj.gmSignatureData} />
            </Grid>

            <Dialog open={showConfirmation} onClose={() => handleConfirmation(false)}>
                <DialogTitle>Changing the date range will reset all values. <br />
                    Do you want to reset values and continue?</DialogTitle>
                <DialogActions>
                    <Button onClick={() => handleConfirmation(true)} color="primary" autoFocus>
                        Yes
                    </Button>
                    <Button onClick={() => handleConfirmation(false)} color="primary">
                        No
                    </Button>

                </DialogActions>
            </Dialog>
            <Dialog open={showChangeClose} onClose={() => handleCloseConfirmation(false)}>
                <DialogTitle>Unsaved Changes</DialogTitle>
                <DialogActions>
                    <Button onClick={() => handleCloseConfirmation(true)} color="primary" autoFocus>
                        Discard and Close
                    </Button>
                    <Button onClick={() => handleCloseConfirmation(false)} color="primary">
                        Cancel and Stay
                    </Button>

                </DialogActions>
            </Dialog>
        </DataForm>
    );
};
export default ContinuousImprovementView;


const BaselineSelect = (props) => {
    const { name, options, baseLinehandel, selectedBL } = props;
    const [selectedBaseline, setSelectedBaseline] = React.useState(selectedBL);

    useEffect(() => {
        setSelectedBaseline(selectedBL)
    }, [selectedBL]);

    const handleChange = (event) => {
        setSelectedBaseline(event.target.value);
        baseLinehandel(event.target.value);
    };
    const formatDate = (dateString) => {
        if (dateString !== '') {
            const date = new Date(dateString);
            const options = { month: 'numeric', day: 'numeric', year: 'numeric' };
            return date.toLocaleDateString('en-US', options);
        } else {
            return ''
        }

    }

    return (
        <div style={{ marginLeft: '12px' }}>
            <FormControl variant="outlined" sx={{ m: 1, minWidth: 400, maxWidth: 400 }}>
                <InputLabel className='MuiInputLabel-animated' id="demo-simple-select-helper-label">Select Baseline</InputLabel>
                <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={selectedBaseline}
                    name={name}
                    label="Select Baseline"
                    onChange={handleChange}
                >
                    {
                        options.length > 0 ?
                            options.map((option, i) => (
                                <MenuItem sx={{}} key={i} value={option.id}>
                                    {option.baselineName}  {option.baselineStartDate !== '' ? ': (From ' + formatDate(option.baselineStartDate) + ' to ' + formatDate(option.baselineEndDate) + ')' : ''}
                                </MenuItem>
                            )) : <MenuItem key={'addnew'} value={null} disabled>
                                Record not found
                            </MenuItem>
                    }
                </Select>
            </FormControl>
        </div>
    );
};
