import Constants from '../../constants';
//Modules
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { createSelector } from 'reselect';
import { Formik, Form, Field, useFormikContext } from 'formik';
import { useNavigate } from 'react-router-dom';
//MUI
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DeleteIcon from '@mui/icons-material/Delete';
import HideImageIcon from '@mui/icons-material/HideImage';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ImageIcon from '@mui/icons-material/Image';
import Checkbox from '@mui/material/Checkbox';


import { TextField, CheckboxWithLabel } from 'formik-mui';
//Compontents
import KeyItemsHistory from './KeyItemsHistory';
import KeyItem_ActionItems_SelectGrid from '../ActionItem/KeyItem_ActionItems_SelectGrid';
import { NativeSelectFormik } from '../FormikUI/NativeSelectFormik';
import {ImageAttachmentDialog} from '../ImageAttachment/ImageAttachmentButton';
//Actions
import {  DeleteKeyItem } from '../../actions/KeyItemActions';
import { ConfirmAction } from '../../actions/ConfirmActionActions';
import { Grid } from '@mui/material';
//Misc

//Selectors
const constants=Constants;
const actionLinksSel = state => state.ActionItemLinks.filter(ail=>!ail.deleted);
const GetActionItemIDByKeyItemID = createSelector(actionLinksSel, (state, keyItemID) => keyItemID,
    (links, kiID) => {
        return links.find(l => l.keyItemID == kiID)?.actionItemID
    }
);
const KeyItemEditDialog = props => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const actionItemID = useSelector(state => GetActionItemIDByKeyItemID(state, props.keyItem?.id));
    const [historyOpen, openHistory] = useState(false);
    const [actionOpen, openAction] = useState(false);
    const [keepOpen,setKeepOpen]=useState(false);
    const [isPriority,setisPriority]=useState(false);
    useEffect(()=>{
        if (props.keyItem) {
            if (props.keyItem.isPriority === 'Yes') {
                setisPriority(true)
            } else {
                setisPriority(false)
            }
                
        }
    },[props.keyItem]);
    const handleDel = () => {
        dispatch(ConfirmAction("Do you want to delete this Key Item?", DeleteKeyItem(props.keyItem), null,
            (confirmed) => {
                if (confirmed) {
                    props.modalClose();
                }
            }));

    }
    if (!props.keyItem || !props.modalOpen) {
        return null;
    }
    const typeOptions = [
        <option key={'measuretype_Operational'} value={'Operational'}>Operational</option>,
        <option key={'measuretype_Mechanical'} value={'Mechanical'}>Mechanical</option>,
        <option key={'measuretype_Safety'} value={'Safety'}>Safety</option>,
    ];
    const handleActionItemClick=()=> {
        if (!actionItemID) {
            openAction(true);
        } else {
            navigate('/ActionItem/' + actionItemID);
        }
    };
    const handleSubmit = (formVal, {setSubmitting  })=>{
        let data = formVal
        data.isPriority = isPriority ? 'Yes' : '-'

        if(keepOpen){
            props.onSave(data);
            setSubmitting(false);
        } else {
            props.onSubmit(data);
        }
    };
    const onChangePriority = (e) => {
        props.onChangePriority(e.target.checked); 
        setisPriority(e.target.checked)
    };



    let content = <Formik initialValues={props.keyItem} onSubmit={handleSubmit}>
        <Form>
            <DialogTitle >Edit KeyItem Notes</DialogTitle >
            <DialogContent >
                <Grid container spacing={1}>
                    <Grid item xs={8}>
                    <Field
                        fullWidth
                        component={TextField}
                        name="entityType"
                        type="text"
                        label="Entity"
                        InputProps={{
                            inputProps: { readOnly: true },

                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <NativeSelectFormik name={'type'} label={'Type'} options={ typeOptions } />
                </Grid>
                <Grid item xs={12}>
                    <Field
                        fullWidth
                        component={TextField}
                        name="reference"
                        type="text"
                        label="Reference"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Field
                        fullWidth
                        component={TextField}
                        name="notes"
                        type="text"
                        label="Notes"
                        InputProps={{
                            multiline: true,
                            inputProps: { autoFocus: true },
                            rows: 3,

                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                       
                    <FormControlLabel  style={{ marginRight: '25px' }} control={<Checkbox onChange={onChangePriority} checked={isPriority} />} label="Priority Item" />
                </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Box
                  m={1}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="space-between"
                   style={{width:'100%'}}>
                    <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<DeleteIcon />}
                        onClick={handleDel}
                    >Delete
                    </Button>
                    <KeyItemImageButton setKeepOpen={() => setKeepOpen(true)} />
                    <Button onClick={handleActionItemClick}>Action Item</Button>
                    
                    
                    <Button onClick={() => openHistory(true)}>History</Button>
                    <SubmitButton setKeepOpen={()=>setKeepOpen(false)} />
                    <Button onClick={props.modalClose}>Cancel</Button>
                </Box>
            </DialogActions>
        </Form>
    </Formik>;
    if (historyOpen) {  
        content = <>
            <DialogTitle >History {props.keyItem.entityType + ' ' +props.keyItem.reference}</DialogTitle >
            <DialogContent >
                <KeyItemsHistory keyItem={props.keyItem}  />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => openHistory(false) }>Close</Button>
            </DialogActions>
        </>
    }
    if (actionOpen) {
        content = <>
            <DialogTitle >Action Items {props.keyItem.entityType + ' ' + props.keyItem.reference}</DialogTitle >
            <DialogContent >
                <KeyItem_ActionItems_SelectGrid keyItem={props.keyItem} />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => openAction(false)}>Close</Button>
            </DialogActions>
        </>
    }

    return (
        <Dialog open={props.modalOpen} onClose={props.modalClose} fullWidth>
            { content }
        </Dialog>
        );
}
export default KeyItemEditDialog;

const KeyItemImageButton =props=>{
    const { values, submitForm } = useFormikContext();
    const handleImageChange = (newValue) => {
        if (newValue != values.imageID) {
            values.imageID=newValue;
            props.setKeepOpen();
            submitForm();
        }
    };
    const handleClick=()=>{
     
        setOpen(true);
    };
    const [isOpen, setOpen] = useState(false);
    let imageID = values.imageID;
    if (typeof (imageID) === 'undefined')
        imageID = constants.EmptyGuid;
    let icon = <ImageIcon />;
    if (imageID == constants.EmptyGuid) {
        icon = <HideImageIcon />
    }
    return <>
        <Tooltip title={"Image Attachment"}>
            <IconButton onClick={handleClick } tabIndex="-1" size="large">
                { icon }
            </IconButton>
        </Tooltip>
        <ImageAttachmentDialog modalOpen={isOpen} modalClose={() => setOpen(false)}
            onChange={handleImageChange} attachmentID={imageID} header={''}  />
        </>;
    
};
const SubmitButton = props => {
    const { values, submitForm } = useFormikContext();
    const handleClick = () => {
        props.setKeepOpen();
        submitForm();
    };
    return <Button onClick={handleClick} >Save</Button>

};

