import Constants from '../constants';
import produce from "immer";
import { UpdateArrayRange } from '../misc/ArrayFuncs';
const initDataState = {
    Vessels: []
    ,Regions:[]
    , AutoCompleteData: {}
    , ActiveVisitID:null
};
const MetaData = (state = initDataState, action) => {
    switch (action.type) {       
        case Constants.Actions.ReceiveVessels:
            return {
                ...state,
                Vessels: UpdateArrayRange(action.data, state.Vessels)
            };
        case Constants.Actions.AddVessel:
            const newVessels = [...state.Vessels, action.payload];
            return { ...state, Vessels: newVessels };
        case Constants.Actions.AddVessel_Commit:
            return state;
        case Constants.Actions.AddVessel_Rollback:
            const vInd = state.Vessels.findIndex(person => person.id === action.meta.newPerson.id);
            if (vInd !== -1) {
                return produce(state, draft => { draft.Vessels.splice(vInd, 1) });
            } else {
                return state;
            }
        case Constants.Actions.ReceiveAutoComplete:
            const updACD = {};
            updACD[action.name] = action.data;
            const newACD = { ...state.AutoCompleteData, ...updACD };
            return { ...state, AutoCompleteData: newACD };
        case Constants.Actions.ReceiveRegions:
            return { ...state, Regions: action.data };
        case Constants.Actions.MetaData_ClearData:
            return initDataState;
        default:
            return state;
    }
}
export default MetaData;