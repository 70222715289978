import { Stimulsoft } from 'stimulsoft-reports-js/Scripts/stimulsoft.viewer';
import 'stimulsoft-reports-js/Css/stimulsoft.viewer.office2013.whiteblue.css';
import { createSelector } from 'reselect';
Stimulsoft.Base.StiLicense.key = "6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHmcw9yfeYg0alektZLd6SKIcqi70mOXDgIUAKXJoiSQyCxP42" + 
"yh2Mi5cm23Ep0NWjiSfZpV7B/Gp3u+3hQfGmO8sz89J8CEb5wBF0ELK+KWeXV7PUlm0oCGhdzmFIj/+l+0nuGcdirB" + 
"/ndm8ustyhufkpuAFgwFCBYBdnUXG4UBhLckFwF0cFRmzZ6wYUG5z1dEBMBgvUsKQRfkyLvaOhbw4I2+z0nzqY3oGw" + 
"GelxWF9oKxSqcMFGF2I7C5FkUkrPIuZcj4auiUw0VIU/vc1siaqsrDzUgPUN0t9t4q8iO9GNvv687+YhAMqCQqmI7A" + 
"+0msgL8A+rhRBiIPx3dUqyeMv7aDxwED5OW0fx04HRKsjGKPoIvU+cK8cYLx0SvOg5x6VZUwvRef3gWNMRoxkGDXw8" + 
"Y6ZThKQz+nzInrsKDJczl8crdcvDKQy9Aq1ZwaxTDnitJ/zvpTH5AoT/EqkHdx5Kv/W5XBb/a8uxAuFYRud7SZqYDl" + 
"9Bmkz7/bHk/2kNplrDX7khXClWmlmKt37kVW+lEImpZcHwDPxnZ9aIblEQ==";
export const LoadReport = (reportData,reportName,dataSets) => {
	const report = Stimulsoft.Report.StiReport.createNewReport();
	report.load(reportData.reportData);
	if (reportName) {
		report.reportName = reportName;
	}
	report.dictionary.databases.clear();
	reportData.dataSourcesCSV
		.split(',')
		.forEach(ds => {
			try {
				const dataSet = new Stimulsoft.System.Data.DataSet(ds);
				dataSet.readJson(dataSets[ds]);
				report.regData(ds, ds, dataSet);
			} catch (dsError) {
				throw new Error("Error Loading DataSet " + ds);
			}
		});
	return report
}
export const ReportsSel = state=>state.Reports;
export const TypeNameSel = (_,Type,Name)=>{return {Type:Type,Name:Name}};
export const ReportsByTypeName = createSelector(ReportsSel, TypeNameSel,
    (Reports, typeName) => {
        return Reports.find(r => r.reportType == typeName.Type && r.reportName==typeName.Name)
    }
);
const table = [];
for (let i = 65; i < 91; i++) table.push(String.fromCharCode(i));
for (let i = 97; i < 123; i++) table.push(String.fromCharCode(i));
for (let i = 0; i < 10; i++) table.push(i.toString(10));
table.push("+");
table.push("/");

const rev_table = table.reduce((obj, c, i) => {
	obj[c] = i;
	return obj;
}, {});
export function encode(bytes) {
	if (bytes instanceof Array) {
		bytes = Uint8Array.from(bytes);
	} else if (!(bytes instanceof Uint8Array || bytes instanceof Uint8ClampedArray)) {
		bytes = new TextEncoder().encode(bytes.toString());
	}

	let base64 = "";
	const len = bytes.byteLength;
	for (let i = 0; i < len; i += 3) {
		if (len === i + 1) {  // last 1 byte
			const a = (bytes[i] & 0xfc) >> 2;
			const b = ((bytes[i] & 0x03) << 4);
			base64 += table[a];
			base64 += table[b];
			base64 += "==";
		} else if (len === i + 2) { // last 2 bytes
			const a = (bytes[i] & 0xfc) >> 2;
			const b = ((bytes[i] & 0x03) << 4) | ((bytes[i + 1] & 0xf0) >> 4);
			const c = ((bytes[i + 1] & 0x0f) << 2);
			base64 += table[a];
			base64 += table[b];
			base64 += table[c];
			base64 += "=";
		} else {
			const a = (bytes[i] & 0xfc) >> 2;
			const b = ((bytes[i] & 0x03) << 4) | ((bytes[i + 1] & 0xf0) >> 4);
			const c = ((bytes[i + 1] & 0x0f) << 2) | ((bytes[i + 2] & 0xc0) >> 6);
			const d = bytes[i + 2] & 0x3f;
			base64 += table[a];
			base64 += table[b];
			base64 += table[c];
			base64 += table[d];
		}
	}
	return base64;
};
function decode(base64str) {
	const len = base64str.length;
	//if (len % 4 !== 0) {
	//  base64str = base64str.padEnd(len + 4 - (len % 4), "=")
	//}

	const bytes = [];
	for (let i = 0; i < len; i += 4) {
		const a = rev_table[base64str[i]];
		const b = rev_table[base64str[i + 1]];
		const c = rev_table[base64str[i + 2]];
		const d = rev_table[base64str[i + 3]];
		bytes.push((a << 2 & 0xfc) | (b >> 4 & 0x03));
		bytes.push((b << 4 & 0xf0) | (c >> 2 & 0x0f));
		bytes.push((c << 6 & 0xc0) | d & 0x3f);
	}
	return bytes;
}
function decodeAsArrayBuffer(base64str) {
	return Uint8Array.from(decode(base64str));
}
function decodeAsString(base64str) {
	return new TextDecoder().decode(decodeAsArrayBuffer(base64str));
}