import Constants from '../constants';
import produce from "immer";
import { UpdateArray, ChangeOrder, UpdateArrayRange } from '../misc/ArrayFuncs';
const initDataState = [];
//Not Normalized
const Notifications = (state = initDataState, action) => {
    switch (action.type) {
        case Constants.Actions.ENQUEUE_SNACKBAR:
            return  [
                    ...state,
                    {
                        key: action.key,
                        ...action.notification,
                    }
                ]
            ;

        case Constants.Actions.CLOSE_SNACKBAR:
            return  state.map(notification => (
                    (action.dismissAll || notification.key === action.key)
                        ? { ...notification, dismissed: true }
                        : { ...notification }
                ));

        case Constants.Actions.REMOVE_SNACKBAR:
            return state.filter(notification => notification.key !== action.key);
        case Constants.Actions.CLEAR_DATA_SNACKBAR:
            return initDataState;
        default:
            return state;
    };
}
export default Notifications;