import Constants from '../../constants';
//Libraries
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import produce from "immer";
//MUI 
import makeStyles from '@mui/styles/makeStyles';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
//Compontents
import { KeyItemCheckBox } from '../KeyItems/KeyItemCheckBox';
import ImageAttachmentButton from '../ImageAttachment/ImageAttachmentButton';
//Actions
import { UpdateChecklistItem } from '../../actions/ChecklistActions';
const constants = Constants;
const useStyles = makeStyles((theme) => ({
    itemText: {
        fontWeight: 'normal',
        fontSize: '1em',
        color: 'black',
    }
}));
const ChecklistItemsList = props => {
    const itemIDs = useSelector(state => state.ChecklistItem
        .filter(i => i.checklistID === props.checklist.id && i.groupID == props.group.id && !i.deleted)
        .sort((a, b) => a.order - b.order)
        .map(i=>i.id)
        , shallowEqual);
    const itemMap = (id) => {
        return <ItemEdit itemID={id} checklist={ props.checklist} />
    }
    const itemsJSX = itemIDs.map(itemMap);
    return <>{itemsJSX}</>;
};

export const ItemEdit = props => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const item = useSelector(state => state.ChecklistItem.find(i => i.id == props.itemID), shallowEqual);
    const [noteVal, setNoteVal] = useState(item.notes);
    const [prevNotes, setPrevNotes] = useState(item.notes);

    const handleChecked = (event) => {
        dispatch(UpdateChecklistItem(item,
            produce(item, d => { d.isChecked = event.target.checked; })
        ));
    };
    const handleTextChange = (event) => {
            dispatch(UpdateChecklistItem(item,
                produce(item, d => { d.notes = noteVal })
            ));
    };
    const imageUpload = (newImageID) => {
        dispatch(UpdateChecklistItem(item,
            produce(item, d => { d.imageID = newImageID })
        ));
    }
    if (prevNotes !== item.notes) {
        setPrevNotes(item.notes);
        setNoteVal(item.notes);
    }
    return (
        <Grid container>
            <Grid item xs={2}>
                <Checkbox
                    checked={item.isChecked}
                    onChange={handleChecked}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
            </Grid>
            <Grid item xs={10}>
                <span className={classes.itemText}>{item.name}</span>
            </Grid>
            <Grid item xs={12}>
                <TextField value={noteVal ? noteVal:''} onChange={(e) => setNoteVal(e.target.value)} style={{ width: '80%' }} onBlur={handleTextChange} multiline />
            </Grid>
            <Grid item xs={4}>
                <ImageAttachmentButton attachmentID={item.imageID} onChange={ imageUpload } />
            </Grid>
          
            <Grid item xs={8}>
                <KeyItemCheckBox entity={item} visitID={props.checklist.visitID}
                    setReference={(entity) => {
                        return entity.name;
                    }}
                    entityType={constants.Surveys.Checklist} edit={true} tabIndex="-1"
                    defaultNote={item.notes}
                />
            </Grid>    
        </Grid>);
}

export default ChecklistItemsList;