import Constants from '../constants';
import produce from "immer";
import { UpdateArray,UpdateArrayRange } from '../misc/ArrayFuncs';
const initDataState = [];

const Lines = (state = initDataState, action) => {
    switch (action.type) {
        case Constants.Actions.Lines_ReceiveByPlant:
            return UpdateArrayRange(action.data, state);
        case Constants.Actions.Lines_GetByID:
            return UpdateArray(action.data, state);
        case Constants.Actions.Lines_Update:
            return UpdateArray(action.payload.update, state);
        case Constants.Actions.Lines_Update_Commit:
            return UpdateArray(action.payload?.data, state);
        case Constants.Actions.Lines_Update_Rollback:
            return UpdateArray(action.meta.data.current, state);
        case Constants.Actions.Lines_Add:
            return [...state, action.payload];
        case Constants.Actions.Lines_Add_Rollback:
            const lineInd = state.findIndex(line => line.id === action.meta.newLine.id);
            if (lineInd !== -1) {
                return produce(state, pd => { pd.splice(lineInd, 1) });
            } else {
                return state;
            }
        case Constants.Actions.Lines_Add_Commit:
            return UpdateArray(action.payload?.data, state);
        case Constants.Actions.Lines_Delete:
            return UpdateArray(produce(action.payload, d => {
                d.deleted = true;
            }), state);
        case Constants.Actions.Lines_Delete_Commit:
            return state;
        case Constants.Actions.Lines_Delete_Rollback:
            return UpdateArray(action.meta.delLine, state);
        case Constants.Actions.Lines_ClearData:
            return [];
        default:
            return state;
    };
}
export default Lines;