import Constants from '../../constants';
//Library Imports
import React, { useEffect, useContext, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useNavigate,useParams } from 'react-router-dom';
//MUI Imports
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import PrintIcon from '@mui/icons-material/Print';
import Grid from '@mui/material/Grid';
//Action Imports
import { LoadChecklist, DeleteChecklist } from '../../actions/ChecklistActions';
import { ConfirmAction } from '../../actions/ConfirmActionActions';
import { enqueueSnackbar, closeSnackbar } from '../../actions/NotificationActions';
//Compontent Imports
import GetEntity from '../../misc/GetEntity';
import ChecklistEdit from './ChecklistEdit';
import ChecklistPrint from './ChecklistPrint';
import '../Print/Print.css';
const constants = Constants;
const useStyles = makeStyles((theme) => ({
    header: {
        borderBottom: 'solid'
    },
}));
const ChecklistView = props => {
    const classes = useStyles();
    const navigate = useNavigate();
    const params=useParams();
    const clID = params.SurveyID;
    const dispatch = useDispatch();
    const { checklist, online, authToken, offlineMode } = useSelector(state => {
        return {
            checklist: state.Checklist.find(cl => cl.id == clID),
            online: state.offline.online,
            authToken: state.Auth.token,
            offlineMode: state.Auth.offlineMode
        };
    }, shallowEqual);
    //Get Latest Data and Check if still valid.
    useEffect(() => {
        if (clID) {
            //Pull HeatVessel Data
            if (authToken && !offlineMode && online) {
                GetEntity(constants.URLs.Checklist + clID, online, authToken)
                    .then(ret => {
                        if (ret.complete && ret.valid) {
                            dispatch(LoadChecklist(ret.data));
                        }
                    })
                    .catch(ret => {                   
                        const newNotification = {
                            message: 'Could not load Checklist Data Error:' + ret.error,
                            options: {
                                key: new Date().getTime() + Math.random(),
                                variant: 'error',
                                action: key => (
                                    <Button onClick={() => closeSnackbar(key)}>dismiss</Button>
                                ),
                            },
                        };
                        dispatch(enqueueSnackbar(newNotification));
                        navigate('/');
                    });

            }
        } else {
            //Something went wrong go home
            navigate("/", { replace: true });
        }
    }, [clID, online, authToken]);
    //Internal Funcs
    const handleDel = () => {
        const visitID = checklist.visitID;
        dispatch(ConfirmAction("Do you want to delete this Checklist?", DeleteChecklist(checklist), null,
            (confirmed) => {
                if (confirmed) {
                    navigate('/VisitOverview/' + visitID, { replace: true });
                }
            }));
    }
    const handleClose = () => {
        navigate(-1);
    }
    if (!checklist) {
        return <div>Loading Checklist From Database</div>;
    }
    return <Grid container spacing={3}>
        <Grid item xs={4} sm={4} align="left">
            <Button
                variant="contained"
                startIcon={<PrintIcon />}
                onClick={() => navigate('/ChecklistPrint/' + clID)}>
                Print
            </Button>
        </Grid>
        <Grid item xs={4} sm={4} >
            <Button
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
                onClick={handleDel}
            >Delete
            </Button>
        </Grid>
        <Grid item xs={4} sm={4} align="right">
            <Button variant="contained" startIcon={<CloseIcon />} onClick={handleClose}>
                Close
            </Button>
        </Grid>
        <Grid item xs={12}>
            <ChecklistEdit checklist={checklist} />;
        </Grid>
    </Grid>;
}
export default ChecklistView;