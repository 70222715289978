import React, { useEffect, useContext, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { CheckReSync } from '../actions/LastSyncActions';
export const useOnlineState=()=>{
    return useSelector(state=>{
        return {online:state.offline.online, authToken: state.Auth.token, loggedIn:state.Auth.authenticated};
        },
        (oldVal,newVal)=>oldVal.online===newVal.online && oldVal.authToken===newVal.authToken
    );
};
export const useCheckLastSync=(id,threashold = 14400000)=>{
    const LastSync=useSelector(s=>s.LastSync,shallowEqual);
    return CheckReSync(id,LastSync,threashold);
}