import Constants from '../../constants';
//Modules
import React, { useEffect, useContext, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import produce from "immer";
import { Formik, Form, Field } from 'formik';
import { TextField as FormText} from 'formik-mui'
import { v4 as uuidv4 } from 'uuid';
//MUI
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import { FormControlLabel, Grid } from '@mui/material';
//Components
import { KeyItemCheckBox } from '../KeyItems/KeyItemCheckBox';
//Actions
import { GetInventoryByVisitID, AddInventory, DeleteInventory } from '../../actions/InventoryActions';
import { UpdateStarchKitchenItem,AddNewStarchKitchenItem, DeleteStarchKitchenItem, GetSKItemsByVisitID } from '../../actions/StarchKitchenActions';
import { UpdateAction } from '../../actions/DataActions';
import { AddDefaultStarchKitchenItems } from '../../actions/StarchKitchenActions';
//Selectors
import { SelSKItemsByVisitID } from '../../selectors/VisitSelectors';
const constants=Constants;

const StarchKitchenItemsView = ({isHideAddRow,visit}) =>{
    const [addOpen,setAddOpen]=useState(false);
    const items=useSelector(s=>SelSKItemsByVisitID(s,visit.id));
    const online=useSelector(s=>s.offline.online,shallowEqual);
    const dispatch=useDispatch();
    let errorMsg=null;
    useEffect(()=>{
        console.log('check ---', items);
        if (visit?.id && items ){
            if(items.length<7 && !visit.starchKitchenCreated){
                console.log('call api for add-----------');
                dispatch(AddDefaultStarchKitchenItems(visit.id,items));
            }
        }
    }, [visit?.id, items?.length, visit?.starchKitchenCreated])
    useEffect(()=>{
        if (visit?.id && items ){
            if(items.length<7 && !visit.starchKitchenCreated){
                dispatch(UpdateAction('UpdateVisit','starchKitchenCreated',visit,true));
                // dispatch(AddDefaultStarchKitchenItems(visit.id,items));
            } else {
                if(online){
                    errorMsg="Pulling Starch Kitchen Items from Online Database";
                    dispatch(GetSKItemsByVisitID(visit.id));

                } else {
                    errorMsg="Could not load Starch Kitchen Items. To load please go online.";
                }
            }
        }

    }, [visit?.id, items?.length, visit?.starchKitchenCreated])
    const handleAdd=(formVals)=>{
        const newOrder=items.reduce((acc,item)=>{
            return Math.max(acc,item.order);
        },0)+1;
        const newItem = {id:uuidv4(),name:formVals.skName,visitID:visit.id,checked:false,order:newOrder};
        dispatch(AddNewStarchKitchenItem(newItem));
        setAddOpen(false);
    }
    const validate=(formVals)=>{
        let errors={};
        if(!formVals.skName){
            errors.skName="Required";
        }
        return errors;
    }
    return <Grid container spacing={3}>
        {items.map(i=><ItemView item={i} key={i.id} visitID={visit.id} />)}
        <Grid item xs={12}>
            {
                !isHideAddRow ? 
                <Button onClick={()=>setAddOpen(true)}>Add Row</Button>
                : <></>
            }
            <Dialog open={addOpen} onClose={()=>setAddOpen(false)}>
            <Formik initialValues={{skName:''}} onSubmit={handleAdd} validate={validate}>
                <Form>
                    <DialogTitle >Starch Kitchen Item</DialogTitle >
                <DialogContent>
                <Field
                                component={FormText}
                                name="skName"
                                type="text"
                                label="Name"
                            />
                </DialogContent>
                <DialogActions>
                    <Button type="submit">Save</Button>
                    <Button onClick={()=>setAddOpen(false)}>Close</Button>
                </DialogActions>
                </Form>
                </Formik>
            </Dialog>
        </Grid>
    </Grid>;
}
export default StarchKitchenItemsView;

const ItemView = ({item,visitID})=>{
    const dispatch=useDispatch();
    const [textVal,setText]=useState('');
    useEffect(()=>setText(item.attention??''),[item]);
    const setChecked=(event)=>{
        const newItem=produce(item,d=>{
            d.checked=event.target.checked;
        });
        onChange(newItem,item);
    }
    const attentionChanged=()=>{
        if(textVal!==item.attention){
            const newItem=produce(item,d=>{
                d.attention=textVal;
            });
            onChange(newItem,item);
        }
    }
    const handleDelete=()=>{
        dispatch(DeleteStarchKitchenItem(item));
    }
    const onChange=(newItem,curItem)=>{
        dispatch(UpdateStarchKitchenItem(newItem,curItem));
    };
    if(!item){return null;}
    let deleteBtn=null;
    if(!constants.DefaultStarchKitchen.includes(item.name)){
        deleteBtn=<Tooltip title={"Delete"}>
        <IconButton
            onClick={handleDelete}
            size="large">
            <DeleteIcon  />
        </IconButton>
    </Tooltip>;
    }
    return <React.Fragment key={"SKI_"+item.id}>
    <Grid item xs={2} justifyContent="start">
        <FormControlLabel 
            control={<Checkbox name={'tvc'} checked={item.checked} onChange={setChecked} />}
            label={ item.name } />
        
    </Grid>
    <Grid item xs={6}>
    <TextField
            label={item.name+" Attention"}
            value={textVal}
            onChange={(e)=>setText(e.target.value??'')}
            onBlur={attentionChanged}
                fullWidth
                multiline
        />
    </Grid>
    <Grid item xs={3} >
        <KeyItemCheckBox entity={item} visitID={visitID}
            setReference={(entity) => entity.name } field={item.name}
        entityType={'Starch Kitchen'} edit={true} tabIndex="-1" defaultNote={item.attention}
        />
    </Grid>
    <Grid item xs={1}>
        {deleteBtn}
    </Grid>
</React.Fragment>;
}