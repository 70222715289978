import Constants from '../constants';
import produce from "immer";
import { UpdateArray, UpdateArrayRange } from '../misc/ArrayFuncs';
const constants = Constants;
const initDataState = [];
const ActionItemLinks = (state = initDataState, action) => {
    switch (action.type) {
        case Constants.Actions.ActionItemLinks.Recive_Visit:
            return UpdateArrayRange(action.data, state);
        case Constants.Actions.ActionItemLinks.Recieve_Entity:
            const recEntLinks = state.filter(ai => ai.entityID !== action.entityID && !ai.deleted);
            return recEntLinks.concat(action.data);
        case Constants.Actions.ActionItemLinks.Add:
            return UpdateArray(action.payload, state);
        case Constants.Actions.ActionItemLinks.Add_Rollback:
            const ailId = state.findIndex(ail => ail.id === action.meta.newActionItemLink.id);
            if (ailId !== -1) {
                return produce(state, pd => { pd.splice(ailId, 1) });
            } else {
                return state;
            }
        case Constants.Actions.ActionItemLinks.Add_Commit:
            return UpdateArray(action.payload?.data, state);
        case Constants.Actions.ActionItemLinks.Update:
            return UpdateArray(action.payload.update, state);
        case Constants.Actions.ActionItemLinks.Update_Commit:
            return UpdateArray(action.payload?.data, state);
        case Constants.Actions.ActionItemLinks.Update_Rollback:
            return UpdateArray(action.meta.data.current, state);
        case Constants.Actions.ActionItemLinks.Delete:
            return UpdateArray(produce(action.payload, d => {
                d.deleted = true;
            }), state);
        case Constants.Actions.ActionItemLinks.Delete_Commit:
            return state;
        case Constants.Actions.ActionItemLinks.Delete_Rollback:
            return UpdateArray(action.meta.delActionItemLink, state);
        case Constants.Actions.ActionItemLinks.ClearData:
            return initDataState;
        case Constants.Actions.ActionItemLinks.DeleteByActionItemID:
            return state.filter(i => i.actionItemID !== action.actionItemID);
        case Constants.Actions.ActionItemLinks.RestoreLinks:
            return UpdateArrayRange(action.links, state);
        default:
            return state;
    }
}
export default ActionItemLinks;