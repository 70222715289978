import Constants from '../constants';
import { v4 as uuidv4 } from 'uuid';
import produce from "immer";
import { FetchErrorLogger, FetchCatchHandler } from '../middlewares';
export const GetPlantFormulaSteps = (plantID) => {
    return (dispatch, getState) => {
        //offline.online
        const state = getState();
        if (state.offline.online) {
            let fetchTask = fetch(Constants.URLs.FormulaStep + '?plantID=' + plantID, { headers: { 'Authorization': 'Bearer ' + state.Auth.token } })
                .then(FetchErrorLogger("GetPlantFormulaSteps", plantID, dispatch))
                .then(response => response.json())
                .then(mvs => {
                    const formulaIDs = mvs.map(fs => fs.formulaID).filter((v, i, a) => a.indexOf(v) === i);
                    dispatch({ type: Constants.Actions.FormulaSteps_ReceiveByPlant, data: mvs, plantID: plantID, formulaIDs: formulaIDs });
                })
                .catch(FetchCatchHandler("GetPlantFormulaSteps", plantID, dispatch))
        }
    }
}
export const AddFormulaStep = (newFormulaStep) => {
    newFormulaStep.id = uuidv4();
    return {
        type: Constants.Actions.FormulaStep_Add,
        payload: newFormulaStep,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.FormulaStep, method: 'POST', body: JSON.stringify(newFormulaStep), headers: { 'content-type': 'application/json' }
                },
                commit: {
                    type: Constants.Actions.FormulaStep_Add_Commit, meta: { newFormulaStep }
                },
                rollback: {
                    type: Constants.Actions.FormulaStep_Add_Rollback, meta: { newFormulaStep }
                }
            }
        }
    }
};
export const DeleteFormulaStep = (delFormulaStep) => {
    return {
        type: Constants.Actions.FormulaStep_Delete,
        payload: delFormulaStep,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.FormulaStep + delFormulaStep.id, method: 'DELETE'
                },
                commit: {
                    type: Constants.Actions.FormulaStep_Delete_Commit, meta: { delFormulaStep }
                },
                rollback: {
                    type: Constants.Actions.FormulaStep_Delete_Rollback, meta: { delFormulaStep }
                }
            }
        }
    }
};
export const ChangeFormulaStepNumber = (FormulaStep, isUp) => {
    const changeData = { id: FormulaStep.id, isUp: isUp }
    return {
        type: Constants.Actions.FormulaStep_ChangeOrder,
        payload: changeData,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.FormulaStep + changeData.id + "/ChangeOrder/" + isUp, method: 'GET'
                },
                commit: {
                    type: Constants.Actions.FormulaStep_ChangeOrder_Commit, meta: { changeData }
                },
                rollback: {
                    type: Constants.Actions.FormulaStep_ChangeOrder_Rollback, meta: { changeData }
                }
            }
        }
    };
};
export const ReOrderFormulaSteps = (formulaID) => {
    return {
        type: Constants.Actions.FormulaStep_ReOrder,
        payload: formulaID,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.FormulaStep + formulaID + "/ReOrder/", method: 'GET'
                },
                commit: {
                    type: Constants.Actions.FormulaStep_ReOrder_Commit, meta: { formulaID: formulaID }
                },
                rollback: {
                    type: Constants.Actions.FormulaStep_ReOrder_Rollback, meta: { formulaID: formulaID }
                }
            }
        }
    };
};
export const UpdateFormulaStep = (updFormulaStep, curFormulaStep) => {
    console.log('put check __________________________________________________________________________  77');
    updFormulaStep = produce(updFormulaStep, ret => {
        ret.changeID = uuidv4();
        if(isNaN(ret.cost) || ret.cost==''){ret.cost=0;}
        if(isNaN(ret.mixTime) || ret.mixTime==''){ret.mixTime=0;}
    });
    const data = { update: updFormulaStep, current: curFormulaStep }
    return {
        type: Constants.Actions.FormulaStep_Update,
        payload: data,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.FormulaStep + updFormulaStep.id, method: 'PUT', body: JSON.stringify(updFormulaStep), headers: { 'content-type': 'application/json' }
                },
                commit: {
                    type: Constants.Actions.FormulaStep_Update_Commit, meta: { data }
                },
                rollback: {
                    type: Constants.Actions.FormulaStep_Update_Rollback, meta: { data }
                }
            }
        }
    }
};
export const ClearData = () => { return { type: Constants.Actions.FormulaStep_ClearData }; };