//Libraries 
import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
//MUI
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';
import Zoom from 'react-medium-image-zoom'
//Compontents
import { GetAttachment, DeleteAttachment } from '../../actions/AttachmentActions'
//Actions
import { AddImageView } from './AddImageView';
//Misc
import 'react-medium-image-zoom/dist/styles.css'
import { doResync } from '../../misc/HelperFuncs';
export const ImageAttachmentView = props => {
    const dispatch = useDispatch();
    const attachment = useSelector(state => state.Attachments.find(a => a.id === props.attachmentID && !a.deleted), shallowEqual);
    const pattern = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$', 'i');
    if ((!attachment || doResync(attachment.lastSyncDate)) && pattern.test(props.attachmentID)) {
        console.log("ImageAttachmentView Get Attachment Data");
        dispatch(GetAttachment(props.attachmentID));
    }
    let retVal = null;
    if (attachment) {
        retVal = <ImageView attachment={attachment} handleChange={props.handleChange} header={ props.header } />;
    } else {
        retVal = <AddImageView handleChange={props.handleChange} header={props.header} id={props.id} />;
    }
    return retVal;
};
export const ImageView = props => {
    const dispatch = useDispatch();
    const handleEdit = () => {
        alert("Not Implemented");
    }
    const handleDel = () => {
        dispatch(DeleteAttachment(props.attachment));
        props.handleChange('00000000-0000-0000-0000-000000000000');
    }
    const imgStyle = {
        maxWidth :'100%',
            height: 'auto'
    };
   
    return <Card >
        {props.header}
        <CardContent>
            <img src={"data:" + props.attachment.mime + ";base64," + props.attachment.data} style={imgStyle} /> 
        </CardContent>
        <CardActions>      
            <Button size="small" onClick={handleDel }>Delete</Button>
        </CardActions>
    </Card>;
}
