import Constants from '../constants';
import produce from "immer";
import { UpdateArray, UpdateArrayRange } from '../misc/ArrayFuncs';
const initDataState = [];
const Thermals = (state = initDataState, action) => {
    switch (action.type) {
        //ADD
        case Constants.Actions.Thermal.Add:
            return UpdateArray(action.payload, state);
        case Constants.Actions.Thermal.Add_Rollback:
            const ivId = state.findIndex(iv => iv.id === action.meta.newT.id);
            if (ivId !== -1) {
                return produce(state, pd => { pd.splice(ivId, 1) });
            } else {
                return state;
            }
        case Constants.Actions.Thermal.Add_Commit:
            return UpdateArray(action.payload?.data, state);
        //Update
        case Constants.Actions.Thermal.Update:
            return UpdateArray(action.payload.update, state);

        case Constants.Actions.Thermal.Update_Commit:
            return UpdateArray(action.payload?.data, state);

        case Constants.Actions.Thermal.Update_Rollback:
            return UpdateArray(action.meta.data.current, state);

        //Delete
        case Constants.Actions.Thermal.Delete:
            return UpdateArray(produce(action.payload, d => {
                d.deleted = true;
            }), state);

        case Constants.Actions.Thermal.Delete_Commit:
            return state;

        case Constants.Actions.Thermal.Delete_Rollback:
            return UpdateArray(action.meta.delIngVer, state);

        case Constants.Actions.Thermal.ClearData:
            return initDataState;
        //Get
        case Constants.Actions.Thermal.GetByVisitID:
            return UpdateArrayRange(action.data, state);
        case Constants.Actions.Thermal.GetByID:
            return UpdateArray(action.data, state);
        default:
            return state;
    }
}
export default Thermals;