import React, { useEffect, useContext, useState } from 'react';
import Constants from '../../constants';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Formik, Form, Field, useFormikContext } from 'formik';
import { CheckboxWithLabel } from 'formik-mui';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { PercentSolidFormik } from './PercentSolidFormik';
import { StepProductDropdown } from './StepProductAC';
import { StepSelectFormik, StepTypeView } from './StepTypeViews';
import { UnitSelectFormik, UnitView } from './UnitViews';
import { DensityWeightFormik } from './DensityWeightFormik';
import TextFieldFormik from '../FormikUI/TextFieldFormik';
export const StepAddDialog = props => {
    const [keyItemcheck, setkeyItemcheck] = useState(false);
    
    useEffect(() => {
        setkeyItemcheck(props.keyItemcheck)
    }, [props]);
    const validate = (values) => {
        //{ stepNumber: 0, stepType: 999, stepDescription: '', lbsPerGallon: 0, dryPercentage: 0, product: '', value: 0, unit: 0, mixTime: 0, cost:0};
        // StepType: { Water: 0, Starch: 1, Caustic_Percentage: 2, Borax: 4, Starch_Specialty: 6, Liquid_Add: 7, Liquid_Specialty: 8, Heat: 10, Error: -1, Mix: 11 },
        //FormulaUnit: { lbs: 0, oz: 1, kgs: 2, F: 3, C: 4, gal: 5, sec: 6 },
        const errors = {};
        if (!props.addStep) {
            if (values.comment.trim() === '') { errors.comment = "Required"; }
        }
        if (values.stepType < 0 || values.stepType > 11) { errors.stepType = "Invalid Step Selected"; }
        if (values.dryPercentage < 0 || values.dryPercentage > 100) { errors.dryPercentage = "Percent Solids must be between 0-100"; }
        if (values.value < 0) { errors.value = "Amount must be positive"; }
        if (values.mixTime < 0) { errors.mixTime = "Mix must be positive"; }
        if (values.cost < 0) { errors.cost = "Cost must be positive"; }
        if (values['stepType'] >= 0 && values['stepType'] !== 999
            && values['stepType'] !== 11 && values['stepType'] !== 1
            && values['stepType'] !== 10 && !values['product']) {
            errors.product = "Required";
        }
        if (!errors.value) {
            switch (values.stepType) {
                case 0: //Water
                    if (values.unit == 0 && values.product != 'Finish Water' && (values.value < 250 || values.value > 2510)) {
                        errors.value = "Water Amount must be between 250 - 2510 lbs";
                    } else if (values.unit == 5 && values.product != 'Finish Water' && (values.value < 30 || values.value > 300)) {
                        errors.value = "Water Amount must be between 30 - 300 gals";
                    } else if (values.unit == 0 && values.product == 'Finish Water' && (values.value < 30 || values.value > 200)) {
                        errors.value = "Finish Water Amount must be between 30 - 200 lbs";
                    } else if (values.unit == 5 && values.product == 'Finish Water' && (values.value < 3 || values.value > 24)) {
                        errors.value = "Finish Water Amount must be between 3 - 24 gals";
                    }
                    break;
                case 1://Starch
                    if (values.value < 30 || values.value > 2000) {
                        errors.value = "Starch Amount must be between 30 - 2000 lbs";
                    }
                    break;
                case 2: //caustic
                    if (values.unit == 0 && values.value < 3) {
                        errors.value = " Amount must be greater than 3lbs";
                    } else if (values.unit == 5 && (values.value < .2)) {
                        errors.value = " Amount must be greater than .2 gals ";
                    }
                    break;
                case 4: //borax
                    if (values.unit == 0 && (values.value < 1)) {
                        errors.value = " Amount must be greater than 1  lbs";
                    } else if (values.unit == 5 && (values.value < 0.1)) {
                        errors.value = " Amount must be greater than .1 gals";
                    }
                    break;
                case 7://Liquid Additives
                    if (values.unit == 0 && (values.value < 5 || values.value > 200)) {
                        errors.value = " Amount must be between 5 - 200 lbs";
                    } else if (values.unit == 5 && (values.value < 0.5 || values.value > 24)) {
                        errors.value = " Amount must be between .5 - 24 gals";
                    }
                    break;
                case 8://Speciality Liquid(oz)
                    if (values.value < 2 || values.value > 100) {
                        errors.value = " Amount must be between 2 - 100 oz";
                    }
                    break;
                case 10:
                    if (values.unit == 3 && (values.value < 80 || values.value > 130)) {
                        errors.value = " Amount must be between 80 - 130 F";
                    } else if (values.unit == 4 && (values.value < 26 || values.value > 55)) {
                        errors.value = " Amount must be between 26 - 55 C";
                    }
                    break;
                default:
                    //Do Nothing
                    break;
            }
        }
        return errors;
    }
    let comment = null;

    if (!props.addStep) {
        comment = <div>
            <TextFieldFormik

                name="comment"
                type="text"
                label="Comment"
            />
        </div>;
    }
    const handleChange = (e) => {
        props.handleKeyItem(true)
    }
    return (
        <Dialog open={props.modalOpen} onClose={props.closeModal} >
            <Formik initialValues={props.initialValues} onSubmit={props.onSumbit} validate={validate}>
                <Form>
                    <DialogTitle >Add/Edit Step </DialogTitle >
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <div><StepSelectFormik steps={props.steps} /></div>

                                <div><StepProductDropdown allSteps={props.allSteps} /></div>
                                <div><FSOtherDesc /></div>
                                <div>
                                    <FSAmount />
                                </div>
                                <div><UnitSelectFormik /></div>
                                {comment}
                                {
                                    !props.addStep ?
                                        <div style={{ paddingLeft: '4px' }} >
                                        <FormControlLabel checked={keyItemcheck} onChange={handleChange} control={<Checkbox />} label="Key Item" />
                                        </div>
                                        : <></>

                                }

                            </Grid>
                            <Grid item xs={12} md={6}>
                                <DensityWeightFormik />
                                <PercentSolidFormik />
                                <div>
                                    <FSCost />
                                </div>
                                <div>
                                    <MixTime />
                                </div>
                                <div>
                                    <OtherTxt />
                                </div>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit" >Save</Button>
                        <Button onClick={props.closeModal}>Cancel</Button>
                    </DialogActions>
                </Form>
            </Formik>
        </Dialog >
    );
}
const FSAmount = props => {
    const { values, handleChange, touched, errors } = useFormikContext();
    let retVal = null;
    if (values['stepType'] !== 11) {
        retVal = <TextField
            variant="outlined"
            name="value"
            type="number"
            InputProps={{
                inputProps: { step: "any" }
            }}
            value={values.value}
            onChange={handleChange}
            error={touched.value && Boolean(errors.value)}
            helperText={touched.value && errors.value}
            label="Amount"
        />;
    }
    return retVal;
}
const MixTime = props => {
    const { values, handleChange, touched, errors } = useFormikContext();
    return <TextField
        variant="outlined"
        name="mixTime"
        type="number"
        InputProps={{
            inputProps: { step: "any" }
        }}
        value={values.mixTime}
        onChange={handleChange}
        error={touched.mixTime && Boolean(errors.mixTime)}
        helperText={touched.mixTime && errors.mixTime}
        label="Mix(Seconds)"
    />;
}
const OtherTxt = props => {
    const { values, handleChange, touched, errors } = useFormikContext();
    return <TextField
        variant="outlined"
        name="other"
        type="text"
        value={values.other}
        onChange={handleChange}
        error={touched.other && Boolean(errors.other)}
        helperText={touched.other && errors.other}
        label="Other"
    />;
}
const FSCost = props => {
    const { values, handleChange, touched, errors } = useFormikContext();
    let retVal = null;
    if (values['stepType'] !== 11) {
        retVal = <TextField
            variant="outlined"
            name="cost"
            type="number"
            InputProps={{
                inputProps: { step: "any" }
            }}
            value={values.cost}
            onChange={handleChange}
            error={touched.cost && Boolean(errors.cost)}
            helperText={touched.cost && errors.cost}
            label="Cost"
        />
    }
    return retVal;
}
const FSOtherDesc = props => {
    const { values, handleChange, touched, errors } = useFormikContext();
    let retVal = null;
    if (values['product'] == "Other" && values['stepType'] >= 0 && values['stepType'] !== 999
        && values['stepType'] !== 11 && values['stepType'] !== 1
        && values['stepType'] !== 10) {
        retVal = <TextField
            variant="outlined"
            name="stepDescription"
            type="text"
            label="Product Description"
            value={values.stepDescription}
            onChange={handleChange}
            error={touched.stepDescription && Boolean(errors.stepDescription)}
            helperText={touched.stepDescription && errors.stepDescription}
        />;
    }
    return retVal;
}
