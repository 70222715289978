import Constants from '../constants';
import produce from "immer";
import { UpdateArray, UpdateArrayRange } from '../misc/ArrayFuncs';
const initDataState = [];

const Visits = (state = initDataState, action) => {
    switch (action.type) {
        case Constants.Actions.RequestVisits:
            return state;
        case Constants.Actions.ReceiveVisits:
            return UpdateArrayRange(action.data, state);
        case Constants.Actions.Visits_GetByPlantID:
            const nonPlantVisits = state.filter(a => a.plantID !== action.plantID);
            return nonPlantVisits.concat(action.data);
        case Constants.Actions.AddVisit:
            return UpdateArray(action.payload, state);
        case Constants.Actions.AddVisit_Commit:
            return UpdateArray(action.payload?.data, state);
        case Constants.Actions.AddVisit_Rollback:
            const visitInd = state.findIndex(v => v.id === action.meta.newVisit.id);
            if (visitInd !== -1) {
                return produce(state, pd => { pd.splice(visitInd, 1) });
            } else {
                return state;
            }
        case Constants.Actions.UpdateVisit:
            //Action will have the full plant data
            return state.map((p, ind) => {
                if (p.id === action.payload.id) {
                    return produce(p, pd => { pd[action.payload.name] = action.payload.newValue });
                } else { return p; }
            });

        case Constants.Actions.UpdateVisit_Commit:
            return UpdateArray(action.payload?.data, state);
        case Constants.Actions.UpdateVisit_Rollback:
            //Action will have the full plant data
            return state.map((p, ind) => {
                if (p.id === action.meta.updatePayload.id) {
                    return produce(p, pd => { pd[action.meta.updatePayload.name] = action.meta.updatePayload.oldValue });
                } else { return p; }
            });
        case Constants.Actions.Visits_ClearData:
            return initDataState;
        default:
            return state;
    };
}
export default Visits;