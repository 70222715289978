import Constants from '../constants';
import { FetchErrorLogger, FetchCatchHandler } from '../middlewares';
const constants=Constants;
export const GetReports = () => {
    return (dispatch, getState) => {
        const state = getState();
        if (state.offline.online) {
            //No Data Get Data
            let fetchTask = fetch(constants.URLs.Reports, { headers: { 'Authorization': 'Bearer ' + state.Auth.token } })
                .then(FetchErrorLogger("GetReports", null, dispatch))
                .then(response => response.json())
                .then(reports => {   
                    dispatch({ type: constants.Actions.Report.Load, payload: reports })
                })
                .catch(FetchCatchHandler("GetReports", null, dispatch))
                ;
        }
    };
};
export const AddUpdateReport=(report)=>{
    return {type:constants.Actions.Report.AddUpdate,payload:report}
};
export const ClearReports=()=>{
    return {type:constants.Actions.Report.ClearData};
}