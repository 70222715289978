import Constants from '../constants';
import { v4 as uuidv4 } from 'uuid';
import { FetchErrorLogger, FetchCatchHandler } from '../middlewares';
import { GetPlantFormulas } from "./FormulaActions";
import { AddIngredientVerification, GetIngredientVerificationByVisitID } from "./IngredientVerificationActions";
import { AddNewHeatVessel, GetHeatVesselsByVisitID } from './HeatVesselActions';
import { AddNewGlueRoll, GetGlueRollsByVisitID } from './GlueRollActions';
import { AddNewGlueLine, GetGlueLinesByVisitID } from './GlueLineActions';
import { AddNewStarch, GetSKItemsByVisitID, GetStarchsByVisitID } from './StarchKitchenActions';
import { CreateBHSChecklist, GetChecklistsByVisitID } from './ChecklistActions';
import { GetKeyItemByVisitID } from "./KeyItemActions";
import { AddSyncTimestamp, CheckReSync } from './LastSyncActions';
import { GetPlantData } from './PlantActions';
import { GetThermalsByVisitID } from './ThermalImageActions';
const constants=Constants;
export const GetBaselines = () => {
    console.log('DataActions.js GetBaselines Called');
    return (dispatch, getState) => {
        console.log('DataActions.js GetBaselines Thunk Called');
        const state = getState();
        if (state.offline.online) {
            //No Data Get Data
            let fetchTask = fetch(Constants.URLs.Baseline, { headers: { 'Authorization': 'Bearer ' + state.Auth.token } })
                .then(FetchErrorLogger("GetBaselines", null, dispatch))
                .then(response => response.json())
                .then(Baselines => {
                    dispatch({ type: Constants.Actions.ReceiveBaselines, data: Baselines })
                })
                .catch(FetchCatchHandler("GetBaselines", null, dispatch));
        }
    };
};
export const GetBaselinesByPlantID = (PlantID) => {
    return (dispatch, getState) => {
        const state = getState();
        if (state.offline.online) {
            //No Data Get Data
            let fetchTask = fetch(Constants.URLs.Baseline + '?PlantID=' + PlantID, { headers: { 'Authorization': 'Bearer ' + state.Auth.token } })
                .then(FetchErrorLogger("GetBaselinesByPlantID", PlantID, dispatch))
                .then(response => {
                   return response.json()
                })
                .then(baselines => {
                    dispatch({ type: Constants.Actions.Baselines_GetByPlantID, data: baselines, plantID:PlantID })
                })
                .catch(FetchCatchHandler("GetBaselinesByPlantID", PlantID, dispatch));
        }
    };
};
export const AddBaseline = (newBaselineItem) => {
    const constants = Constants;
    newBaselineItem.id = uuidv4();
    return {
        type: constants.Actions.Baselines.Add,
        payload: newBaselineItem,
        meta: {
            offline: {
                effect: {
                    url: constants.URLs.Baseline, method: 'POST', body: JSON.stringify(newBaselineItem), headers: { 'content-type': 'application/json' }
                },
                commit: {
                    type: constants.Actions.Baselines.Add_Commit, meta: { newBaselineItem }
                },
                rollback: {
                    type: constants.Actions.Baselines.Add_Rollback, meta: { newBaselineItem }
                }
            }
        }
    }
};
export const DeleteBaseline = (delBaseline) => {
    return {
                type: constants.Actions.Baselines.Delete,
                payload: delBaseline,
                meta: {
                    offline: {
                        effect: {
                            url: Constants.URLs.Baseline + delBaseline.id, method: 'DELETE'
                        },
                        commit: {
                            type: constants.Actions.Baselines.Delete_Commit, meta: { delBaseline }
                        },
                        rollback: {
                            type: constants.Actions.Baselines.Delete_Rollback, meta: { delBaseline }
                        }
                    }
                }
            };
}
export const UpdateBaseline= (updBaseline, curBaseline) => {
    console.log('put check __________________________________________________________________________  21');
    const data = { update: updBaseline, current: curBaseline }
    return {
        type: constants.Actions.Baselines.Update,
        payload: data,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.Baseline + updBaseline.id, method: 'PUT', body: JSON.stringify(updBaseline), headers: { 'content-type': 'application/json' }
                },
                commit: {
                    type: constants.Actions.Baselines.Update_Commit, meta: { data }
                },
                rollback: {
                    type: constants.Actions.Baselines.Update_Rollback, meta: { data }
                }
            }
        }
    }
};
export const ClearData = () => {
    return { type: constants.Actions.Baselines.ClearData };
}