import React, { useEffect, useContext, useState } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from '@mui/icons-material/Delete';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { AddMeasurement, DeleteMeasurement } from '../../actions/MeasurementActions';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { MeasurementTypeName } from '../MetaData/EnumViews'
import PrintIcon from '@mui/icons-material/Print';
import AddIcon from "@mui/icons-material/Add";
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Padding } from '@mui/icons-material';
import Grid from '@mui/material/Grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MeasurementGridNew from './MeasurementGridNew';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Constants from '../../constants';
//Modules
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
//MUI
import MUIDataTable from "mui-datatables";
//Components
import MeasurementsGrid from './MeasurementsGrid';
//Actions
import { GetMeasurementsByVisitID } from '../../actions/MeasurementActions';
//Selectors
import { SelMeasuresByVisitID } from '../../selectors/VisitSelectors';

const MeasurementsList = props => {
  const location = useLocation();
  let checks = ''
  let showId = null
  if (location.search.length > 0) {
    checks = location.search.split('-')
  }
  if (checks[1]) {
    showId = Number(checks[1])
  }
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [meList, setmeList] = useState([]);
  const measeList = [{ name: "Mixer", value: 0, listData: [] }, { name: "Storage", value: 1, listData: [] }, { name: "Doser - SF #1", value: 2, listData: [] },
  { name: "Doser - SF #2", value: 3, listData: [] }, { name: "Doser - DB", value: 4, listData: [] }];
  const [selMachine, setSelMachine] = useState(-1);
  const measurements = useSelector(s => SelMeasuresByVisitID(s, props.visitID));
  useEffect(() => {
    dispatch(GetMeasurementsByVisitID(props.visitID));
  }, [props.visitID]);

  const machineSelect = value => {
    setSelMachine(value);
  }
  const handlePrint = () => {
    navigate('/StartchChecksPrint/' + props.visitID);
}
  const handleAddNew = () => {
    navigate('/StartchChecks/' + props.visitID + '/' + 'add');
}
  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
          styleOverrides: {
            root: {
              paddingBlock: '2px !important',
            },
            head: {
              paddingBlock: '12px !important',
            }
          },
        },
      }
    });
  measeList.forEach(e => {
    e.listData = measurements.filter(m => m.location === e.value && !m.deleted);
  });
  return (
    <TableContainer component={Paper}>
      <ThemeProvider theme={getMuiTheme()}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow >
              <TableCell sx={{ ...commonStyles }}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                <Typography sx={{marginRight: 2}} variant="h6" component="div">
                  Starch Checks
                </Typography>
                <Tooltip title={"Print"}>
                    <IconButton
                        onClick={handlePrint}
                        size="large">
                        <PrintIcon  />
                    </IconButton>
                </Tooltip>
                <Tooltip title={"Add"}>
                    <IconButton
                        onClick={handleAddNew}
                        size="large">
                        <AddIcon  />
                    </IconButton>
                </Tooltip>
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {measeList.map((row, index) => (
              <Row key={index} row={row} showId={row.value === showId} />
            ))}
          </TableBody>
        </Table>
      </ThemeProvider>
    </TableContainer>
  );
};

function createData(name, calories, fat, carbs, protein, price) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      {
        date: '2020-01-05',
        customerId: '11091700',
        amount: 3,
      },
      {
        date: '2020-01-02',
        customerId: 'Anonymous',
        amount: 1,
      },
    ],
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = useState(props.showId);
  const dispatch = useDispatch();
  const handleDelete = (data) => {
    dispatch(DeleteMeasurement());
  }
  return (
    <>
      <TableRow onClick={() => setOpen(!open)} style={{ cursor: 'pointer' }} sx={{ '& > *': { borderBottom: 'unset', borderRight: 'unset' } }}>

        <TableCell sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} component="th" scope="row">
          <span>{row.name}</span>
          <IconButton
            aria-label="expand row"
            size="small"
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {/* <Grid container xl={12} style={{ padding: '16px', marginTop: '20px' }}> */}
            <Grid container xl={12}>
              <Grid item xl={6}>
                <Box sx={{ margin: 1, border: 1, borderColor: 'lightgray' }}>
                  {/* <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Type</TableCell>
                    <TableCell>Value</TableCell>
                    <TableCell>Time</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.listData.map((historyRow, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {<MeasurementTypeName value={historyRow.type} />}
                      </TableCell>
                      <TableCell>{historyRow.value}</TableCell>
                      <TableCell >{historyRow.measurementDateTime}</TableCell>
                      <TableCell align="right">
                      <Tooltip title={"Delete"}>
                          <IconButton
                              onClick={handleDelete}
                              size="large">
                              <DeleteIcon  />
                          </IconButton>
                      </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table> */}
                  <MeasurementGridNew Measurements={row.listData} VisitID={props.visitID} />
                </Box>
              </Grid>
              { row.listData.length > 0 ?
                <Grid item xl={6}>
                  <Box sx={{ margin: 1, padding: 2, border: 1, borderColor: 'lightgray', display: 'flex', width: 'fit-content' }}>
                    <MeasurementsSummary Measurements={row.listData} VisitID={props.visitID} />
                  </Box>
                </Grid> : <></>
              }
            </Grid>

          </Collapse>
        </TableCell>
      </TableRow>

    </>
  );
}
const commonStyles = {
  m: 1,
  p: 2,
};
const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0, 3.99),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3, 4.99),
  createData('Eclair', 262, 16.0, 24, 6.0, 3.79),
  createData('Cupcake', 305, 3.7, 67, 4.3, 2.5),
  createData('Gingerbread', 356, 16.0, 49, 3.9, 1.5),
];
const MeasurementsSummary = props => {
  const data = props.Measurements
    .reduce((acc, val, ind, array) => {
      switch (val.type) {
        case 0:
          if (acc.gelMin === null || val.value < acc.gelMin)
            acc.gelMin = val.value;
          if (acc.gelMax === null || val.value > acc.gelMax)
            acc.gelMax = val.value;
          break;
        case 1:
          if (acc.visMin === null || val.value < acc.visMin)
            acc.visMin = val.value;
          if (acc.visMax === null || val.value > acc.visMax)
            acc.visMax = val.value;
          break;
        case 2:
          if (acc.tempMin === null || val.value < acc.tempMin)
            acc.tempMin = val.value;
          if (acc.tempMax === null || val.value > acc.tempMax)
            acc.tempMax = val.value;
          break;
      }
      return acc;
    },
      { gelMin: null, gelMax: null, tempMin: null, tempMax: null, visMin: null, visMax: null })

  let gelSum = null;
  let visSum = null;
  let tempSum = null;
  if (data.gelMin !== null) {
    gelSum = data.gelMin;
    if (data.gelMin != data.gelMax)
      gelSum = data.gelMin + ' - ' + data.gelMax
  }
  if (data.visMin !== null) {
    visSum = data.visMin;
    if (data.visMin != data.visMax)
      visSum = data.visMin + ' - ' + data.visMax
  }
  if (data.tempMin !== null) {
    tempSum = data.tempMin;
    if (data.tempMin != data.tempMax)
      tempSum = data.tempMin + ' - ' + data.tempMax
  }
  return <Grid container spacing={2}>
    <Grid item xs={6}>Gel Temp:</Grid>
    <Grid item xs={3}>{gelSum}</Grid>
    <Grid item xs={6}>Viscosity:</Grid>
    <Grid item xs={3}>{visSum}</Grid>
    <Grid item xs={6}>Temperatures:</Grid>
    <Grid item xs={3}>{tempSum}</Grid>
  </Grid>;
}

export default MeasurementsList;