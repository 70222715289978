import Constants from '../../constants';
//Libraries
import React, { useEffect, useContext, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import  {useNavigate, useParams } from 'react-router-dom';
import ReportViewer from '../Report/ReportViewer';
import { GetIngredientVerificationByID, GetIVStepsByParentID } from '../../selectors/IngredientVerificationSelectors';
import { KeyItemsFilteredByArray } from '../../selectors/KeyItemSelectors';
import { PopulateFormulaStepsStrings, SelFormulaByID } from '../../selectors/FormulaSelectors';
import { SelVisitByID,SelInventoryByVisitID,SelMeasuresByVisitID,FormatLocation,SelSKItemsByVisitID } from '../../selectors/VisitSelectors';
import { useReportUser,usePlantDataFromVisitID, useVisitData, useReportName } from '../../misc/UseFuncs';
const IVPrint = props => {
    // const steps = useSelector(state => state.IngredientVerificationSteps
    //     , shallowEqual);
    const navigate = useNavigate();
    const params=useParams();
    const ivID = params.VisitID;
    const printData=useIVPrintData(ivID);
    console.log('print data************************************************************ ', printData);
    const handleClose = () => {
        navigate(-1);
    }
    console.log("IVPrint Render");
    return <ReportViewer type="StarchKitchen" name="StarchKitchen" printData={printData} 
       onClose={handleClose}  />;
    
}
export default IVPrint;
export const useIVPrintData=(visitID)=>{
    const UserData = useReportUser();
    const plant = usePlantDataFromVisitID(visitID);
    
    const visit = useSelector(s=>SelVisitByID(s,visitID));
    const reportName = useReportName("Starch Kitchen",plant?.name,visit);
    const skItems = useSelector(s=>SelSKItemsByVisitID(s,visitID));
    skItems.forEach(element => {
        element.isChecked = element.checked
    });
    const dJSON={User:UserData, Plant:plant, Visit:visit,SKItems:skItems};
    const Data ={starchKitchenData: JSON.stringify(dJSON)};
    let visitDate=null;
    if(visit){
        const d=new Date(visit.startDate);
        visitDate=+(d.getMonth()+1).toString().padStart(2,"0")+"/"+d.getDate().toString().padStart(2,"0")+"/"+d.getFullYear();
    }
    return {Loaded:true,Data:Data,ReportName:reportName,EmailSubject:'Starch Kitchen '+plant?.name+' '+visitDate};
};