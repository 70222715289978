import Constants from '../../constants';
import React from 'react';
import { NativeSelectFormik } from '../FormikUI/NativeSelectFormik'
export const VisitTypeSelFormik = props => {
    

    return <NativeSelectFormik
        name="type"
        label="Visit Type"
        options={VisitTypeOptions}
    />
};

export const VisitTypeOptions = [
    <option key={'visit_type_1'} value={'Corrugator Audit'}>Corrugator Audit</option>,
    <option key={'visit_type_2'} value={'Pre-Trial Survey'}>Pre-Trial Survey</option>,
    <option key={'visit_type_3'} value={'Service Annual Survey'}>Service Annual Survey</option>,
    <option key={'visit_type_4'} value={'Service Emergency'}>Service Emergency</option>,
    <option key={'visit_type_5'} value={'Service JTE'}>Service JTE</option>,
    <option key={'visit_type_6'} value={'Service Kaizen'}>Service Kaizen</option>,
    <option key={'visit_type_7'} value={'Service Routine'}>Service Routine</option>,
    <option key={'visit_type_8'} value={'Service Routine With Glue roll Pics'}>Service Routine With Glue roll Pics</option>,
    <option key={'visit_type_9'} value={'Service Speed Trials'}>Service Speed Trials</option>,
    <option key={'visit_type_10'} value={'Service Training'}>Service Training</option>,
    <option key={'visit_type_11'} value={'Survey Trial Combo'}>Survey Trial Combo</option>,
    <option key={'visit_type_12'} value={'Trial'}>Trial</option>,
    <option key={'visit_type_13'} value={'Glue roll survey and pics'}>Glue roll survey and pics</option>
];
