import Constants from '../constants';
import { ReOrderMachineVessels, RemoveMachineVesselByNumber, AddMachineVesselByNumber } from '../actions/MachineVesselsActions';
import { Observable, of, concat} from 'rxjs';
import { mapTo, filter, mergeMap } from 'rxjs/operators';

const SS = () => {
    return {
        type: Constants.Actions.AddSyncSnack
        , severity: 'error'
        , message: 'Change num hotplates called'
    }
};
const ChangeHP = () => { return { type: Constants.Actions.Machines_Change_Num_HotPlates } };

export const hotPlateVesselEpic = (action$, state$) => action$.pipe(
    filter(action => {
        let ret = false;
        if (action.type === Constants.Actions.Machines_Update
            && action.payload.name === "numberHotPlates"
            && (action.payload.source.type == 5 || action.payload.source.type == 3)
        ) { ret = true; }
        return ret;

    }),
    mergeMap(action => {
        const newActions = [];
        const oldValue = action.payload.oldValue ? action.payload.oldValue:0;
        let numChange = action.payload.newValue - oldValue;
        if (numChange < 0) {
            //Delete MachineVessels
            while (numChange < 0) {
                newActions.push(RemoveMachineVesselByNumber(oldValue + numChange + 1, action.payload.id));
                numChange += 1;
            }
            //Call Reorder
            newActions.push(ReOrderMachineVessels(action.payload.id));
        } else if (numChange > 0) {
            //Add MachineVessels

            for (var i = oldValue + 1; i <= action.payload.newValue; i++) {

                newActions.push(AddMachineVesselByNumber(i,action.payload.source,))
            }
        }      
        return of(...newActions);
    })
);