import React, { useState } from 'react'
import { AddCAD, UpdateCAD, DeleteCAD } from '../../actions/CorrugatorAdhesiveDataActions';
import { useSelector, useDispatch } from 'react-redux'
import AddCustomToolbar from "../AddCustomToolbar";
import CustomSelectToolbar from '../CustomSelectToolbar';
import MUIDataTable from "mui-datatables";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Checkbox from '@mui/material/Checkbox';
import { Formik, Form, Field } from 'formik';
import { TextField, CheckboxWithLabel } from 'formik-mui';
import { AutoCompleteFormik } from '../FormikUI/AutoCompleteFormik';
import TextFieldFormik  from '../FormikUI/TextFieldFormik';
export const CorrugatorAdhesiveGridView = props => {
    //const classes = useStyles();
    const dispatch = useDispatch();
    const cadData = useSelector(state => state.CorrugatorAdhesiveData);
    const plantCAD = cadData.filter(p => p.plantID === props.plantID);
    const [modalOpen, setModal] = useState(false);
    const initCAD = { item: '', product: '', vendor: '', price: 0 };
    const [selectedRows, setSelectedRows] = useState([]);
    const [addCAD, setAddCAD] = useState(false);
    const columns = [{ name: "item", label: "Item" }, { name: "product", label: "Product" }, { name: "vendor", label: "Vendor" }, { name: "price", label: "Price" }];
    const ClickOpen = () => {
        setAddCAD(false);
        setModal(true);
    };
    const ClickDelete = () => {
        dispatch(DeleteCAD(cadData[selectedRows[0]]));
        setSelectedRows([]);
    };
    const ClickAdd = (e) => {     
        setModal(true);
        setAddCAD(true);
    };
    const rowSelect = (rowsSelectedData, allRows, rowsSelected) => {
        setSelectedRows(rowsSelected);
    };
    const options = {
        filterType: 'checkbox', selectableRows: 'single', selectableRowsOnClick: true, print: false,
        fixedHeader: true,
        responsive: 'standard',
        download: false,
        customToolbar: () => {
            return (
                <AddCustomToolbar onClick={ClickAdd} />
            );
        },
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
            return <CustomSelectToolbar selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows} clickEdit={ClickOpen} clickDelete={ClickDelete} />;
        },
        onRowSelectionChange: rowSelect,
        rowsSelected: selectedRows
    };
    const handleSubmit = (e) => {
        console.log("Handle Submit", e);
        setModal(false);
        if (addCAD) {
            e.plantID = props.plantID;
            dispatch(AddCAD(e));
        } else {
            //Update
            dispatch(UpdateCAD(e, cadData[selectedRows[0]]));
        }
    }
    const getInitValues = () => {
        if (modalOpen) {
            if (addCAD) {
                return initCAD;
            } else {
                return plantCAD[selectedRows[0]];
            }
        } else
            return null;
    }
    return (
        <Card>
            <CardContent>
                <MUIDataTable
                    title={"Corrugator Adhesive"}
                    data={plantCAD}
                    columns={columns}
                    options={options}
                />
                <CADFormDialog modalOpen={modalOpen} closeModal={() => setModal(false)} onSubmit={handleSubmit} initialValues={getInitValues()} cadData={cadData} />
            </CardContent>
        </Card>
    );
}
export const CADFormDialog = props => {
    return (
        <Dialog open={props.modalOpen} onClose={props.closeModal} >
            <Formik initialValues={props.initialValues} onSubmit={props.onSubmit}>
                <Form>
                    <DialogTitle >Add Corrugator Adhesive</DialogTitle >
                    <DialogContent>
                        <div>
                            <AutoCompleteFormik
                                name="item"
                                options={props.cadData}
                                label="Item"
                                freeSolo
                            />
                        </div>
                        <div>
                            <AutoCompleteFormik
                                name="product"
                                options={props.cadData}
                                label="Product"
                                freeSolo
                            />
                            
                        </div>
                        <div>
                            <AutoCompleteFormik
                                name="vendor"
                                options={props.cadData}
                                label="Vendor"
                                freeSolo
                            />
                          
                        </div>
                        <div>
                            <TextFieldFormik
                                
                                name="price"
                                type="number"
                                label="Price"
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit">Save</Button>
                        <Button onClick={props.closeModal}>Cancel</Button>
                    </DialogActions>
                </Form>
            </Formik>
        </Dialog >
    );
}