import React, { useCallback,useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader';
import IconButton from "@mui/material/IconButton";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import Tooltip from "@mui/material/Tooltip";
import { AddAttachment } from '../../actions/AttachmentActions';
import { enqueueSnackbar } from '../../actions/NotificationActions';
import  CameraDialog  from './CameraDialog';
export const AddImageView = props => {
    const dispatch = useDispatch();
    const [modelOpen, setModelOpen] = useState(false);
    const onDrop = useCallback(acceptedFiles => {
        if (acceptedFiles && acceptedFiles.length >= 1) {
            const file = acceptedFiles[0];
            const reader = new FileReader();
            reader.onabort = () => {
                dispatch(enqueueSnackbar({
                    message: 'Image file reading was aborted.',
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error'
                    }
                }));
            };
            reader.onerror = () => {
                dispatch(enqueueSnackbar({
                    message: 'Image file read has failed.',
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error'
                    }
                }));
            };
            reader.onload = () => {
                // Do whatever you want with the file contents
                const binaryStr = reader.result
                const base64 = window.btoa(binaryStr);
                const newImage = { data: base64, mime: file.type, id: uuid() };
                dispatch(AddAttachment(newImage));
                setTimeout(function () {
                    props.handleChange(newImage.id);
                }, 30 );
                          
            }
            reader.readAsBinaryString(file)
        }
    }, [props.handleChange])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'image/jpeg, image/png' });
    const PhotoClicked = () => {
        setModelOpen(true);
    };
    const handleTakePhoto = dataUri => {
        const base64 = dataUri.substring(dataUri.indexOf(',')+1);
        const filetype = dataUri.substring(0, dataUri.indexOf(';'));
        const newImage = { data: base64, mime: filetype.substring(5), id: uuid() };
        dispatch(AddAttachment(newImage));
        setTimeout(function () {
            props.handleChange(newImage.id);
        }, 100);
    };
    return <>
        <Card >
            { props.header }
            <CardContent {...getRootProps()}>
             
            <input {...getInputProps()} id={props.id}/>
            {
                isDragActive ?
                    <p>Drop Image Here</p> :
                    <p>Click to Select Image or Drop Image Here</p>
            }
        </CardContent>
        <CardActions>
            <Tooltip title={"Take Photo"}>
                <IconButton onClick={PhotoClicked} size="large">
                    <AddAPhotoIcon />
                </IconButton>
            </Tooltip>
        </CardActions>
        </Card>
        <CameraDialog modalOpen={modelOpen} closeModal={() => setModelOpen(false)} handleTakePhoto={handleTakePhoto } />
        </>;
};