import Constants from '../constants';
import { createSelector } from 'reselect';
export const FilterPlantsByContactID = contactId => {
    return (p) => {
        {
            if (!contactId || !p)
                return false;
            return p.usrareamanagerId === contactId || p.usrprimarytechId === contactId
                || p.usrsecondarytechId === contactId || p.usrtertiarytechId === contactId 
                || p.ownerId ===contactId
                || Constants.AllPlantContactIDs.includes(contactId);
        };
    }
}
export const PlantsSel = state=>state.Plants;
export const PlantsFilteredByContact = createSelector(PlantsSel,(state,contactID)=>contactID,
    (Plants,contactID)=>Plants.filter(FilterPlantsByContactID(contactID))
);
export const PersonnelSel = state=>state.Personnel;
export const PersonnelByPlantID = createSelector(PersonnelSel,(_,plantID)=>plantID,
    (personnel,plantID)=>{
        return personnel.filter(p=>p.plantID===plantID);
    }
);
//Machines
export const MachinesSel = state=>state.Machines.filter(i=>!i.deleted);
export const SelMachineByID = createSelector(MachinesSel,(_,machineID)=>machineID,
    (machines,machineID)=>machines.find(m=>m.id===machineID)
);
export const SelMachinesByLineID =createSelector(MachinesSel,(_,lineID)=>lineID,(_,lineID,addlFilter)=>addlFilter,
    (machines,lineID,addlFilter)=>{
        return machines.filter(m=>{
            let f = m.lineID === lineID;
            if (!f)
                return f;
            if (addlFilter)
                return addlFilter(m);
            return f;
        }).sort((a, b) => a.order - b.order);
    }
);
export const MachinesFilteredByArray = createSelector(MachinesSel,(_,entites)=>entites,
    (machines,entites)=>{
        return machines
        .filter(m=>entites
            .some(i=>{
                let retVal = false;
                Object.keys(i).forEach(key=>{         
                    if(key.toLowerCase().endsWith("machineid") && i[key]===m.id){
                        retVal=true;
                    }
                });
                return retVal;
            }));
    }
);
//Machine Flutes
export const MachineFlutesSel=state=>state.MachineFlutes.filter(i=>!i.deleted);
export const SelMachineFlutesByMachineID = createSelector(MachineFlutesSel,(_,machineID)=>machineID,
    (machineFlutes,machineID)=>{
        return machineFlutes.filter(mf => mf.machineID === machineID).sort((a, b) => a.order - b.order);
    }
);
export const MachinesAddFlutesString = createSelector(MachineFlutesSel,(_,machines)=>machines,
    (MachinesFlutes,machines)=>{
        return machines.map(m=>{
            let fluteStr='';
            MachinesFlutes
            .filter(mf=>mf.machineID===m.id)
            .forEach(mf=>{
                const FluteName = Object.keys(Constants.Enums.Flutes).find(key => Constants.Enums.Flutes[key] === mf.flute);
                if (fluteStr !== '') {
                    fluteStr += ', ' + FluteName;
                } else {
                    fluteStr = FluteName;
                }
            });
            return {...m, flutes:fluteStr}
        })
    }
);
//Machine Vessels
export const SelMachineVessels =state=>state.MachineVessels.filter(mv=>!mv.deleted);
export const SelMachineVesselsByMachineID = createSelector(SelMachineVessels,(_,machineID)=>machineID,
    (machineVessels,machineID)=>{
        return machineVessels.filter(mv => mv.machineID === machineID)
        .sort((a, b) => a.order - b.order);
    }
)

   