import Constants from '../constants';
import produce from "immer";
import { UpdateArray } from '../misc/ArrayFuncs';
const initDataState = [];
const RequestStatus = (state = initDataState, action) => {
    switch (action.type) {
        case Constants.Actions.RequestStatus.StartRequest:
            return UpdateArray(action.data, state);
        case Constants.Actions.RequestStatus.RequestResult:
            return UpdateArray(action.data, state);
        //case Constants.Actions.IngredientVerification_Update:
        //    return UpdateArray(action.payload.update, state);
        //case Constants.Actions.IngredientVerification_Update_Commit:
        //    return UpdateArray(action.payload, state);
        //case Constants.Actions.IngredientVerification_Update_Rollback:
        //    return UpdateArray(action.meta.data.current, state);
        //case Constants.Actions.IngredientVerification_Delete:
        //    return state.filter(p => p.id !== action.payload.id);
        //case Constants.Actions.IngredientVerification_Delete_Commit:
        //    return state;
        //case Constants.Actions.IngredientVerification_Delete_Rollback:
        //    return UpdateArray(action.meta.delIngVer, state);
        //case Constants.Actions.IngredientVerification_ClearData:
        //    return initDataState;
        //case Constants.Actions.IngredientVerification_GetByVisit:
        //    return state.filter(iv => iv.visitID != action.visitID).concat(action.data);
        default:
            return state;
    }
}
export default RequestStatus;