import React, { useEffect,useState } from 'react';
import Constants from '../constants';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import PlantOverviewView from './PlantOverviewView';
import PlantEditView from '../components/PlantEditView';
import HeatVesselView from './HeatVessel/HeatVesselView';
import GlueRollView from './GlueRoll/GlueRollView';
import GlueLineView from './GlueLine/GlueLineView';
import GlueRollPrint from './GlueRoll/GlueRollPrint';
import StarchView from './Starch/StarchView';
import ChecklistView from './Checklist/ChecklistView';
import VisitOverviewView from '../components/Visit/VisitOverviewView';
import VisitPrint from './Visit/VisitPrint';
// import VisitGlobalPrint from './Visit/VisitGlobalPrint';
import VisitPrintTest from './Visit/VisitPrintTest';
import { FormulaHistoryView } from './FormulaHistory/FormulaHistoryView';
import IngredientVerification from './Ingredient_Verification/IngredientVerificationView';
import ActionItemEditView from './ActionItem/ActionItemEditView';
import AddReport from './Report/AddReport';
import LoginDialog from './Login/LoginDialog';
import Notifier from "./Notifier";
import ConfirmAction from "./ConfirmAction";
import AlertAction from "./AlertAction";
import AppPrompt from "./AppPromptView";
import Layout from './Layout';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Routes, Route, Link } from "react-router-dom";
import { GetAllData } from '../actions/DataActions';
import { CheckSiteAccess } from '../actions/NetworkStateActions';
import HeatVesselPrint from './HeatVessel/HeatVesselPrint';
import GlueLinePrint from './GlueLine/GlueLinePrint';
import IVPrint from './Ingredient_Verification/IVPrint';
import ChecklistPrint from './Checklist/ChecklistPrint';
import VisitPDFGeneration from './Visit/VisitPDFGeneration';
import VisitEmailPDFs from './Visit/VisitEmailPDFs';
import ThermalImagesView from './Thermal/ThermalImagesView';
import ContinuousImprovementView from './ContinuosImprove/ContinuousImprovementView';
import StarchKitchenAddEdit from './StarchKitchen/StarchKitchenAddEdit';
import StarchCheckAdd from './StarchChecks/StarchCheckAdd';
import StarchChecksPrint from './StarchChecks/StarchChecksPrint';
import StartchKitchenPrint from './StarchKitchen/StarchKitchenPrint';
import ContinuousImprovementInfo from './ContinuosImprove/ContinuousImprovementInfo';
import ConImpPrintView from './ContinuosImprove/ConImpPrintView';
import ThermalImagesPrint from './Thermal/ThermalImagesPrint';
const theme = createTheme();

const AppView = props => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(GetAllData());
    }, []); 
      //console.log('AuthPreFix', ApplicationPaths.ApiAuthorizationPrefix);
      return (
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <SnackbarProvider>
                    <CssBaseline />
                    <Layout>
                    <Routes>
                        <Route path='/' element={<PlantOverviewView />} />
                        <Route path='/PlantEdit/:PlantID' element={<PlantEditView />} />
                        <Route path='VisitOverview' >
                            <Route path=':VisitID' element={<VisitOverviewView />} />
                        </Route>
                        <Route path ='/VisitPDFs/Email/:VisitID' element={<VisitEmailPDFs />} />
                        
                        
                        <Route path='/VisitPrint/:VisitID' element={<VisitPrint />} />
                        {/* <Route path='/VisitGlobalPrint/:VisitID' element={<VisitGlobalPrint />} /> */}
                        <Route path='/VisitAllReport/:VisitID' element={<VisitPrintTest />} />
                        <Route path='/FormulaHistory/:FormulaID?' element={<FormulaHistoryView />} />
                        <Route path='/IngredientVerification/:VerificationID' element={<IngredientVerification />} />
                        <Route path='/IngredientVerificationPrint/:VerificationID' element={<IVPrint />} />
                        <Route path='/ActionItem/:ActionItemID' element={<ActionItemEditView />} />
                        <Route path='/HeatVessel/:SurveyID' element={<HeatVesselView />} />
                        <Route path='/HeatVesselPrint/:SurveyID' element={<HeatVesselPrint />} />
                        <Route path='/GlueRoll/:SurveyID' element={<GlueRollView />} />
                        <Route path='/GlueRollPrint/:SurveyID' element={<GlueRollPrint />} />
                        <Route path='/GlueLine/:SurveyID' element={<GlueLineView />} />
                        <Route path='/GlueLinePrint/:SurveyID' element={<GlueLinePrint />} />
                        <Route path='/Starch/:SurveyID' element={<StarchView />} />
                        <Route path='/ThermalImages/:SurveyID' element={<ThermalImagesView />} />
                        <Route path='/ContinuousImprovement/:VisitID/:ContImpID' element={<ContinuousImprovementView />} />
                        <Route path='/StartchKitchen/:VisitID/:StarchKiID' element={<StarchKitchenAddEdit />} />
                        <Route path='/StartchKitchenPrint/:VisitID' element={<StartchKitchenPrint />} />
                        <Route path='/StartchChecks/:VisitID/:StarchChID' element={<StarchCheckAdd />} />
                        <Route path='/StartchChecksPrint/:VisitID' element={<StarchChecksPrint />} />
                        <Route path='/ContinuousImprovementInfo/:ContImpID' element={<ContinuousImprovementInfo />} />
                        <Route path='/ThermalImagesPrint/:SurveyID' element={<ThermalImagesPrint />} />
                        <Route path='/ContinuousImprovementPrint/:ContImpID' element={<ConImpPrintView />} />
                        <Route path='/Checklist/:SurveyID' element={<ChecklistView />} />
                        <Route path='/ChecklistPrint/:SurveyID' element={<ChecklistPrint />} />
                        <Route path='/AddReport' element={<AddReport />} />
                        
                       </Routes>
                    </Layout>
                    <Notifier />
                    <AppPrompt />
                    <ConfirmAction />
                    <AlertAction />
                      <LoginDialog />
                      <SiteStatusChecker />
                </SnackbarProvider>
            </ ThemeProvider>
          </StyledEngineProvider>
      );
}

export default AppView;

const ReportViewer = props => {
    console.log("Reload Screen")
    window.location='/ListReports';
    return <div>Redirecting to server</div>;
}
/*add checkbox for tabletmode, state in redux , call toggletabletmode action update redux state
*/
export const SiteStatusChecker = props => {
    const networkState = useSelector(s => s.NetworkState, shallowEqual);
    const [intID, setIntID] = useState(null);
    const dispatch = useDispatch();
    if (!networkState.online || !networkState.siteAccess) {
        dispatch(CheckSiteAccess())
        if (!intID) {
            setIntID(setInterval(() => dispatch(CheckSiteAccess()), 5000));
        }
    }
    if (networkState.online && networkState.siteAccess && intID) {
        clearInterval(intID);
        setIntID(null);
    }
    
    return <></>;
}