import React from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from '@mui/icons-material/Edit';
import PrintIcon from '@mui/icons-material/Print';
import withStyles from '@mui/styles/withStyles';
import FilterListIcon from '@mui/icons-material/FilterList';

const defaultToolbarStyles = {
  iconButton: {
  },
};

class AddCustomToolbar extends React.Component {
  
  render() {
      const { classes } = this.props;
      let addIcon = null;
      if (this.props.onClick) {
          addIcon = <Tooltip title={"Add Item"}>
              <IconButton onClick={this.props.onClick} size="large">
                  <AddIcon />
              </IconButton>
          </Tooltip>;
      }
      let editIcon = null;
      if (this.props.clickEdit) {
        editIcon = (
        <Tooltip title={"Edit"}>
            <IconButton
                className={classes.iconButton}
                onClick={this.props.clickEdit}
                size="large">
                <EditIcon className={classes.icon} />
            </IconButton>
        </Tooltip>
        );
    }
      let printIcon = null;
      if (this.props.clickPrint) {
          printIcon = (
              <Tooltip title={"Print"}>
                  <IconButton
                      onClick={this.props.clickPrint}
                      size="large">
                      <PrintIcon  />
                  </IconButton>
              </Tooltip>
          );
      }
      let filterIcon = null;
      if (this.props.customFilter) {
          filterIcon = (
              <Tooltip title={"Filter"}>
                  <IconButton
                      onClick={this.props.customFilter}
                      size="large">
                      <FilterListIcon />
                  </IconButton>
              </Tooltip>
          );
      }
    return (
        <React.Fragment>
            { editIcon }
            { filterIcon }
            { printIcon }
            { addIcon }
      </React.Fragment>
    );
  }

}

export default withStyles(defaultToolbarStyles, { name: "AddCustomToolbar" })(AddCustomToolbar);
