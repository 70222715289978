import Constants from '../../constants';
//Libraries
import React, { useEffect, useContext, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import {useNavigate,useParams} from 'react-router-dom';
//MUI
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import PrintIcon from '@mui/icons-material/Print';
import makeStyles from '@mui/styles/makeStyles';
//Components
import { DataForm, DataTextBox, DataDate, DataSelect, DataNumber, DataFormContext } from '../FormManager';
import { PlantLink } from '../MetaData/Links';
import { usePlantIDFromVisitID } from '../MetaData/PlantOptions';
import GetEntity from '../../misc/GetEntity';

import GlueLineMachinesView from './GlueLineMachinesView';

import PrintDialog from '../Print/PrintDialog';
import GlueLinePrint from './GlueLinePrint';
//Actions
import { DeleteGlueLine, LoadGlueLine, AddGlueLineMachinesByLineID, SetGlueLineName } from '../../actions/GlueLineActions';
import { GetPlantMachines } from '../../actions/MachineActions'
import { GetPlantMachineVessels } from '../../actions/MachineVesselsActions';
import { GetPlantMachineFlutes } from '../../actions/MachineFlutesActions';
import { enqueueSnackbar, closeSnackbar } from '../../actions/NotificationActions';
import { ConfirmAction } from '../../actions/ConfirmActionActions';
import { UpdateAction } from '../../actions/DataActions';
const constants = Constants;
const useStyles = makeStyles((theme) => ({
    header: {
        borderBottom: 'solid'
    },
}));
const GlueLineView = props => {
    const params=useParams();
    const glID = params.SurveyID;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { glueLine, online, authToken, offlineMode } = useSelector(state => {
        return {
            glueLine: state.GlueLines.find(gr => gr.id == glID),
            online: state.offline.online,
            authToken: state.Auth.token,
            offlineMode: state.Auth.offlineMode
        };
    }, shallowEqual);
    console.log('glueLineglueLineglueLineglueLineglueLineglueLineglueLineglueLineglueLineglueLineglueLineglueLineglueLineglueLineglueLineglueLineglueLineglueLine', glueLine);
    //Get Latest Data and Check if still valid.
    useEffect(() => {
        if (glID) {
            //Pull HeatVessel Data
            if (authToken && !offlineMode && online) {
                GetEntity(constants.URLs.GlueLine + glID, online, authToken)
                    .then(ret => {
                        if (ret.complete && ret.valid) {
                            dispatch(LoadGlueLine(ret.data));
                            //dispatch(GetGlueRollMachinesByParentID(ret.data.id));
                        }
                    })
                    .catch(ret => {
                        if (!glueLine) {
                            const newNotification = {
                                message: 'Could not load Glue Line Data Error:' + ret.error,
                                options: {
                                    key: new Date().getTime() + Math.random(),
                                    variant: 'error',
                                    action: key => (
                                        <Button onClick={() => closeSnackbar(key)}>dismiss</Button>
                                    ),
                                },
                            };
                            dispatch(enqueueSnackbar(newNotification));
                            navigate('/');
                        }
                    });
            } 
        } else {
            //Something went wrong go home
            navigate('/');
        }
    }, [glID, online, authToken]);
    const plantID = usePlantIDFromVisitID(glueLine ? glueLine.visitID : null);
    useEffect(() => {
        if (plantID) {
            dispatch(GetPlantMachines(plantID));
            dispatch(GetPlantMachineVessels(plantID));
            dispatch(GetPlantMachineFlutes(plantID));
        }
    }, [plantID])
    //Get Request Status
    //If Request Status complete==true and heatVessel==null then we have a bad id or cannot pull offline data. 
    const handleDel = () => {
        const visitID = glueLine.visitID;
        dispatch(ConfirmAction("Do you want to delete this GlueLine Survey?", DeleteGlueLine(glueLine), null,
            (confirmed) => {
                if (confirmed) {
                    navigate('/VisitOverview/' + visitID);
                }
            }));
    }
    const handleClose = () => {
        navigate(-1);
    }
    const handleLineChange = newLineID => {
        dispatch(UpdateAction(constants.Actions.GlueLine, 'lineID', glueLine, newLineID));
        
    }
    const handleWallChange = wallType => {
        dispatch(UpdateAction(constants.Actions.GlueLine, 'wall', glueLine, wallType));
        dispatch(AddGlueLineMachinesByLineID(glueLine.id, glueLine.lineID, wallType));
        dispatch(SetGlueLineName(glueLine, glueLine.lineID, wallType));
    }
    if (!glueLine) {
        return <div>Loading Glue Roll Data from Database.</div>;
    }
    let deckEntry=null;
    
    return (
        <>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <DataForm actionType={constants.Actions.GlueLine} data={glueLine}>
                    <Grid container spacing={3}>
                    <Grid item xs={4} sm={4} align="left">
                <Button
                    variant="contained"
                    startIcon={<PrintIcon />}
                    onClick={() => navigate('/GlueLinePrint/'+glID)}>
                    Print
                </Button>

            </Grid>
            <Grid item xs={4} sm={4} >
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<DeleteIcon />}
                    onClick={handleDel}
                >Delete
                </Button>
            </Grid>
            <Grid item xs={4} sm={4} align="right">
                <Button variant="contained" startIcon={<CloseIcon />} onClick={handleClose}>
                    Close
                </Button>
            </Grid>
            <Grid item md={4}>Glue Line Survey</Grid>
            <Grid item md={4}><PlantLink plantID={plantID} /></Grid>
            <Grid item md={4}><DataDate name={'date'} label={'Date'} TextFieldProps={{ size: 'small' }} /></Grid>
                        
                        <Grid item xs={12} sm={4}>
                            <DataTextBox name={'boardCombo'} label={'Board Combinations'} fullWidth TextFieldProps={{ size: 'small' }} />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <DataTextBox name={'fluteName'} label={'Flute'} TextFieldProps={{ size: 'small' }} />
                        </Grid>
                       
                        <Grid item xs={12} >                  
                            {deckEntry}
                        </Grid>
                    </Grid>
                </DataForm>
            </Grid>
            <Grid item lg={12}>
                <GlueLineMachinesView glueLine={glueLine} plantID={plantID} onLineChange={handleLineChange} onWallChange={handleWallChange} />
            </Grid>
        </Grid>
        </>
    );
}
export default GlueLineView;