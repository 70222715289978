import Constants from '../constants';
import produce from "immer";
const constants = Constants;
const initDataState = { drawerOpen:false };
const Menu = (state = initDataState, action) => {
    switch (action.type) {
        case constants.Actions.Menu.OpenDrawer:
            return produce(state, d => {
                d.drawerOpen = true;
            });
        case constants.Actions.Menu.CloseDrawer:
            return produce(state, d => {
                d.drawerOpen = false;
            });
        default:
            return state;
    }
}
export default Menu;