//Modules
import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import produce from "immer";
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
//MUI
import MUIDataTable from "mui-datatables";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
//Actions
import { GetVisitsByPlantID } from '../../actions/VisitActions';
//Components
import { GridDateFilter, DateFilterLogic, UpdateFilterList } from '../MetaData/GridDateFilter';
import { FilterPlantsByContactID } from '../../selectors/PlantSelectors';
import AddCustomToolbar from "../AddCustomToolbar";
import CustomSelectToolbar from '../CustomSelectToolbar';

const DateView = props => {
    if (props.value === "0001-01-01T00:00:00") {
        return null;
    }
    const d = new Date(props.value);
    return <div>{d.toLocaleDateString()}</div>;
};

const VisitsGridView = props => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const Plants = useSelector(state => state.Plants, shallowEqual);
    const Visits = useSelector(state => state.Visits, shallowEqual);
    const contactId = useSelector(state => state.Auth.userData ? state.Auth.userData.contactID : null, shallowEqual);
    const [selectedRows, setSelectedRows] = useState([]);
    const [asyncClickOpen, setClickOpen] = useState(false);
    useEffect(() => {
        if (props.plantID) {
            dispatch(GetVisitsByPlantID(props.plantID))
        }
    }, [props.plantID]);
    useEffect(() => {
        if (asyncClickOpen && selectedRows.length > 0) {
            ClickOpen();
            setClickOpen(false);
        }
    }, [selectedRows, asyncClickOpen])
    const filteredVisits = Visits.filter((v) => {
        if (props.plantID) { //Check if SelectedPlant is not {}
            if (v.plantID === props.plantID) {
                return true;
            }
            else { return false; }
        } else {
            const matchPlant = Plants.find(p => p.id == v.plantID);
            return FilterPlantsByContactID(contactId)(matchPlant);
        }
    }).map((val, ind, array) => {
        //Load Plant Data
        const matchPlant = Plants.find(p => p.id == val.plantID);
        if (!matchPlant)
            return val;
        return produce(val,d => {
            d.plantName = matchPlant.name;
            d.plantLocation = matchPlant.location;
            d.plantCompany = matchPlant.company;
        });
    });
   
    const rowSelect = (rowsSelectedData, allRows, rowsSelected) => {
        setSelectedRows(rowsSelected);
    };
    const ClickOpen = () => {
        if (selectedRows.length > 0) {
            navigate('/VisitOverview/' + filteredVisits[selectedRows[0]].id);
        } else {
            alert("Select Visit From List");
        }
    }
    const columns = [
        {
            name: "startDate", label: "Start Date", options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <DateView value={value} />
                    );
                },
                sort: true,
                filter: true,
                filterType:'custom',
                customFilterListOptions: {
                    update: UpdateFilterList
                },
                filterOptions: {
                    logic: DateFilterLogic,
                    display: (filterList, onChange, index, column) => <GridDateFilter filterList={filterList} onChange={onChange} index={index} column={column} />,
                    fullWidth: true
                }
            }
        },
        {name:"createdBy", label:"Created By"},
        {
            name: "endDate", label: "End Date", options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <DateView value={value} />
                    );
                },
                filter: true,
                filterType: 'custom',
                customFilterListOptions: {
                    update: UpdateFilterList
                },
                filterOptions: {
                    logic: DateFilterLogic,
                    display: (filterList, onChange, index, column) => <GridDateFilter filterList={filterList} onChange={onChange} index={index} column={column} />,
                    fullWidth: true
                }, display: false
            }
        },
        { name: "type", label: "Type", options: { display: true } },
        { name: "plantName", label: "Plant", options: { display: props.plantID ? false:true } },
        { name: "plantLocation", label: "Location", options: { display: false } },
        { name: "plantCompany", label: "Company", options: {display:false}}
    ];
    const options = {
        filterType: 'checkbox', selectableRows: 'single', selectableRowsOnClick: true, print: false,
        download: false,
        fixedHeader: true,
        responsive: 'standard',
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
            return <CustomSelectToolbar selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows} clickEdit={ClickOpen} />;
        },
        onRowSelectionChange: rowSelect,
        rowsSelected: selectedRows,
        sortOrder: {name:'startDate',direction:'desc'},
        setRowProps:()=>({
            onDoubleClick: (row) => {
                if (selectedRows.length == 0) {
                    const targetID = row.currentTarget.id;
                    const dataRow = parseInt(targetID.substring(targetID.lastIndexOf('-') + 1));
                    if (!isNaN(dataRow)) {
                        setSelectedRows([dataRow]);
                        setClickOpen(true);//dislike this but requred
                        //;
                    }
                } else {
                    ClickOpen();
                }      
                //;
            }
        })
    };
    return (
        <div>
            <Card>
                <CardContent>
                    <MUIDataTable
                        title={"Visits"}
                        data={filteredVisits}
                        columns={columns}
                        options={options}
                    />
                </CardContent>
            </Card>
        </div>
    );
};
export default VisitsGridView
