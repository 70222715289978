import { createSelector } from 'reselect';
export const Machines = state=>state.Machines.filter(m=>!m.deleted);
export const GetBoilers = createSelector(Machines,(_,lineID)=>lineID,
(machines,lineID)=>{
    return machines.filter(m=>m.lineID===lineID && m.type===12).sort((a, b) => a.order - b.order);
});
export const HeatVesselsSel = state =>state.HeatVessels.filter(i=>!i.deleted);
export const GetHeatVesselByID= createSelector(HeatVesselsSel,(_,hvID)=>hvID,
    (heatVessels,hvID)=>{
        return heatVessels.find(hv=>hv.id===hvID);
    }
);
export const HeatVesselTempsSel = state=>state.HeatVesselTemps.filter(i=>!i.deleted);
export const GetHVTempsByHVID = createSelector(HeatVesselTempsSel,(_,hvID)=>hvID,
    (HVTemps,hvID)=>{
        return HVTemps.filter(t=>t.heatVesselSurveyID===hvID).sort((a, b) => a.order - b.order);
    }
);