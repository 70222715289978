import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
//MUI Imports
import makeStyles from '@mui/styles/makeStyles';
//Compontent Imports
import ChecklistItemsList from './ChecklistItemsList';
import ChecklistItemsPrint from './ChecklistItemsPrint';
import { SelChecklistGroupsByCLID } from '../../selectors/ChecklistSelectors';
const useStyles = makeStyles((theme) => ({
    groupHeader: {
        width: '100%',
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '1.2em',
        color: '#1976d2',
    },
    thinLine: {
        border: '2px solid darkgray',
        borderRadius: '2px'
    },
    thickLine: {
        border: '4px solid darkgray',
        borderRadius: '2px'
    }
}));
const ChecklistDetailEdit = props => {
    const classes = useStyles();
    const groups = useSelector(state => SelChecklistGroupsByCLID(state,props.checklist.id));
    const printMode = props.printMode ? true : false;
    const groupMap = (group) => {
        let itemView = <ChecklistItemsList checklist={props.checklist} group={group} />
        if (printMode) { itemView = <ChecklistItemsPrint checklist={props.checklist} group={group} /> }
        return <>
            <div key={group.id} className={classes.groupHeader} >
                {group.name}
                <br />
                <hr className={classes.thinLine} />
                <hr className={classes.thickLine} />
                {itemView}
            </div>
        </>
    }
    const groupsJSX = groups.map(groupMap);
    return <div>
        {groupsJSX}
    </div>;
};
export default ChecklistDetailEdit;