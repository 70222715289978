import Constants from '../constants';
import { v4 as uuidv4 } from 'uuid';
import { FetchErrorLogger, FetchCatchHandler } from '../middlewares';
import { enqueueSnackbar } from './NotificationActions';
import { GetVessels } from './MetaDataActions';
export const GetPlantMachineVessels = (plantID) => {
    return (dispatch, getState) => {
        //offline.online
        const state = getState();
        const lineIDs = state.Lines.filter(l => l.plantID === plantID).map(l => l.id);
        const machineIDs = state.Machines.filter(m => lineIDs.filter(i => i == m.lineID && !i.deleted).length > 0).map(m => m.id);
        if (state.offline.online) {
            let fetchTask = fetch(Constants.URLs.MachineVessel + '?plantID=' + plantID, { headers: { 'Authorization': 'Bearer ' + state.Auth.token } })
                .then(FetchErrorLogger("GetPlantMachineVessels", plantID, dispatch))
                .then(response => response.json())
                .then(mvs => {
                    dispatch({ type: Constants.Actions.MachineVessels_ReceiveByPlant, data: mvs, plantID: plantID, machineIDs: machineIDs });
                })
                .catch(FetchCatchHandler("GetPlantMachineVessels", plantID, dispatch))
        }
    }
}
export const GetMachineVessels = (machineID) => {
    return (dispatch, getState) => {
        //offline.online
        const state = getState();
        if (state.offline.online) {
            let fetchTask = fetch(Constants.URLs.MachineVessel + '?machineID=' + machineID, { headers: { 'Authorization': 'Bearer ' + state.Auth.token } })
                .then(FetchErrorLogger("GetMachineVessels", machineID, dispatch))
                .then(response => response.json())
                .then(mvs => {
                    dispatch({ type: Constants.Actions.MachineVessels_ReceiveByMachine, data: mvs, machineID: machineID });
                })
                .catch(FetchCatchHandler("GetMachineVessels", machineID, dispatch))
        }
    }
}
export const AddMachineVessel = (machineId, vesselID, order) => {
    const newMachineVessel = { id: uuidv4(), machineID: machineId, vesselID: vesselID, order: order };
    return {
        type: Constants.Actions.MachineVessel_Add,
        payload: newMachineVessel,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.MachineVessel, method: 'POST', body: JSON.stringify(newMachineVessel), headers: { 'content-type': 'application/json' }
                },
                commit: {
                    type: Constants.Actions.MachineVessel_Add_Commit, meta: { newMachineVessel }
                },
                rollback: {
                    type: Constants.Actions.MachineVessel_Add_Rollback, meta: { newMachineVessel }
                }
            }
        }
    }
};
export const DeleteMachineVessel = (delMachineVessel) => {
    return {
        type: Constants.Actions.MachineVessel_Delete,
        payload: delMachineVessel,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.MachineVessel + delMachineVessel.id, method: 'DELETE'
                },
                commit: {
                    type: Constants.Actions.MachineVessel_Delete_Commit, meta: { delMachineVessel }
                },
                rollback: {
                    type: Constants.Actions.MachineVessel_Delete_Rollback, meta: { delMachineVessel }
                }
            }
        }
    }
};
export const ChangeMachineVesselOrder = (machineVessel, isUp) => {
    const changeData = { id: machineVessel.id, isUp: isUp }
    return {
        type: Constants.Actions.MachineVessel_ChangeOrder,
        payload: changeData,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.MachineVessel + changeData.id + "/ChangeOrder/" + isUp, method: 'GET'
                },
                commit: {
                    type: Constants.Actions.MachineVessel_ChangeOrder_Commit, meta: { changeData }
                },
                rollback: {
                    type: Constants.Actions.MachineVessel_ChangeOrder_Rollback, meta: { changeData }
                }
            }
        }
    };
};
export const ReOrderMachineVessels = (machineID) => {
    return {
        type: Constants.Actions.MachineVessel_ReOrder,
        payload: machineID,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.MachineVessel + machineID + "/ReOrder/" , method: 'GET'
                },
                commit: {
                    type: Constants.Actions.MachineVessel_ReOrder_Commit, meta: { machineID: machineID }
                },
                rollback: {
                    type: Constants.Actions.MachineVessel_ReOrder_Rollback, meta: { machineID: machineID}
                }
            }
        }
    };
};
export const RemoveMachineVesselByNumber = (remNum,machineID) => {
    return (dispatch, getState) => {
        const state = getState();
        const machinesVessels = state.MachineVessels.filter(mv => mv.machineID == machineID && !mv.deleted);
        const remMV = machinesVessels.find(mv => {
            let retVal = false;
            let vName = '';
            if (mv.vessel) {
                vName = mv.vessel.name
            } else {
                const findV = state.MetaData.Vessels.find(v => v.id == mv.vesselID);
                if (findV) {
                    vName = findV.name;
                }
            }
            const nameNum = Number(vName)
            if (!isNaN(nameNum) && nameNum === remNum) {
                //We found it!
                retVal = true;
            }
            return retVal;
        });
        if (remMV) {
            dispatch(DeleteMachineVessel(remMV));
        }
       
    };
};
export const AddMachineVesselByNumber = (addNum, machine) => {
    return (dispatch, getState) => {
        const state = getState();
        const vessels = state.MetaData.Vessels.filter(v => v.type == machine.type && !v.deleted);
        const machinesVessels = state.MachineVessels.filter(mv => mv.machineID == machine.id && !mv.deleted);
        let count = machinesVessels.length;
        if (isNaN(count)) { count = 0;}
        const addVessel = vessels.find(v => {
            let retVal = false;
            const nameNum = Number(v.name)
            if (!isNaN(nameNum) && nameNum === addNum) {
                //We found it!
                retVal = true;
            }
            return retVal;
        });
        if (addVessel) {
            dispatch(AddMachineVessel(machine.id, addVessel.id, count));
        }

    };
};
export const AddMachineVesselByName = (addName, machineID,order) => {
    return (dispatch, getState) => {
        const state = getState();
        const vessel = state.MetaData.Vessels.find(v => v.name.toUpperCase() == addName.toUpperCase());
        if (vessel) {
            dispatch(AddMachineVessel(machineID, vessel.id, order));
        } else {
            //Show Error Notification
            dispatch(enqueueSnackbar({
                message: 'Auto Add Vessels Failed! Please Try Again.',
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'warning'
                }
            }));
            //Pull Vessels
            dispatch(GetVessels(1000));

        }
    }
}
export const ClearData = () => {
    return { type: Constants.Actions.MachineVessel_ClearData };
};
