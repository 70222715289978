import Constants from '../constants';
import produce from "immer";
import { UpdateArray, UpdateArrayRange } from '../misc/ArrayFuncs';
const initDataState = [];
//TODO: Normalize Data Maybe?
const Plants = (state = initDataState, action) => {
    switch (action.type) {
        case Constants.Actions.RequestPlants:
            return state;
        case Constants.Actions.ReceivePlants:
            return action.data;
        case Constants.Actions.UpdatePlant:
            //Action will have the full plant data
            return state.map((p, ind) => {
                if (p.id === action.payload.id) {
                    return produce(p, pd => { pd[action.payload.name] = action.payload.newValue });
                } else { return p; }
            });
            
        case Constants.Actions.UpdatePlant_Commit:
            return UpdateArray(action.payload?.data, state);
        case Constants.Actions.UpdatePlant_Rollback:
            //Action will have the full plant data
            return state.map((p, ind) => {
                if (p.id === action.meta.updatePayload.id) {
                    return produce(p, pd => { pd[action.meta.updatePayload.name] = action.meta.updatePayload.oldValue });
                } else { return p; }
            });
            
        case Constants.Actions.AddPlant:
            let newPlants = [...state, action.payload];
            return newPlants;
        case Constants.Actions.AddPlant_Rollback:
            const plantsInd = state.findIndex(plant => plant.id === action.meta.newPlant.id);
            if (plantsInd !== -1) {
                return produce(state, pd => { pd.splice(plantsInd, 1) });
            } else {
                return state;
            }             
        case Constants.Actions.AddPlant_Commit:
            return UpdateArray(action.payload?.data, state);
        case Constants.Actions.AddPlantCache:
            return UpdateArray(action.data, state);
        case Constants.Actions.Plant_ClearData:
            return [];
        default:
            return state;
    };
}
export default Plants;