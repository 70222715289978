import React from 'react'
import Constants from '../../constants';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import { NativeSelectFormik } from '../FormikUI/NativeSelectFormik';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
export const FluteName = props => {
    const FluteStr = Object.keys(Constants.Enums.Flutes).find(key => Constants.Enums.Flutes[key] === props.value);
    return <>{FluteStr}</>;
};

export const FluteSelectFormik = props => {
    const options = Object.keys(Constants.Enums.Flutes).map(key =>
        <option
            key={key}
            value={Constants.Enums.Flutes[key]}
        >
            { key }
        </option>
    );
    //options.unshift(<option key={'-1'} value={-1}>Select Flute</option>);
    return (
        <NativeSelectFormik name={props.name} options={options} label={props.label} number />
    );
};
export const useMachineFlutesList = machineID => {
    const { flutes,machine } = useSelector(s => {
        const ret = { flutes: null, machine: null };
        ret.flutes = s.MachineFlutes.filter(m => m.machineID === machineID && !m.deleted)
        ret.machine = s.Machines.find(m => m.id == machineID);
        return ret;
    }
        , shallowEqual);
    if (machine.type !== 1)
        return null;
    let fluteStr = '';
    flutes.forEach(f => {
        const FluteName = Object.keys(Constants.Enums.Flutes).find(key => Constants.Enums.Flutes[key] === f.flute);
        if (fluteStr !== '') {
            fluteStr += ', ' + FluteName;
        } else {
            fluteStr = FluteName;
        }
    });
    return fluteStr;
}