import Constants from '../constants';
import { v4 as uuidv4 } from 'uuid';
import { FetchErrorLogger, FetchCatchHandler } from '../middlewares';
export const GetPlantCAD = (plantID) => {
    console.log('CorrugatorAdhesiveDataActions.js GET CorrugatorAdhesive Called', plantID);
    return (dispatch, getState) => {
        //offline.online
        const state = getState();
        if (state.offline.online) {
            let fetchTask = fetch(Constants.URLs.CorrugatorAdhesive + '?plantID=' + plantID, { headers: { 'Authorization': 'Bearer ' + state.Auth.token } })
                .then(FetchErrorLogger("GetPlantCAD", plantID, dispatch))
                .then(response => response.json())
                .then(cad => {
                    console.log("Got Plant CAD", cad);
                    dispatch({ type: Constants.Actions.CorrugatorAdhesive_ReceiveByPlant, data: cad, plantID: plantID });
                })
                .catch(FetchCatchHandler("GetPlantCAD", plantID, dispatch))
        }
    }
}
export const AddCAD = (newCAD) => {
    newCAD.id = uuidv4();
    return {
        type: Constants.Actions.CorrugatorAdhesive_Add,
        payload: newCAD,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.CorrugatorAdhesive, method: 'POST', body: JSON.stringify(newCAD), headers: { 'content-type': 'application/json' }
                },
                commit: {
                    type: Constants.Actions.CorrugatorAdhesive_Add_Commit, meta: { newCAD }
                },
                rollback: {
                    type: Constants.Actions.CorrugatorAdhesive_Add_Rollback, meta: { newCAD }
                }
            }
        }
    }
};
export const UpdateCAD = (updCAD, curCAD) => {
    const data = { update: updCAD, current: curCAD }
    console.log('put check __________________________________________________________________________  33');
    return {
        type: Constants.Actions.CorrugatorAdhesive_Update,
        payload: data,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.CorrugatorAdhesive + updCAD.id, method: 'PUT', body: JSON.stringify(updCAD), headers: { 'content-type': 'application/json' }
                },
                commit: {
                    type: Constants.Actions.CorrugatorAdhesive_Update_Commit, meta: { data }
                },
                rollback: {
                    type: Constants.Actions.CorrugatorAdhesive_Update_Rollback, meta: { data }
                }
            }
        }
    }
};
export const DeleteCAD = (delCAD) => {
    return {
        type: Constants.Actions.CorrugatorAdhesive_Delete,
        payload: delCAD,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.CorrugatorAdhesive + delCAD.id, method: 'DELETE'
                },
                commit: {
                    type: Constants.Actions.CorrugatorAdhesive_Delete_Commit, meta: { delCAD }
                },
                rollback: {
                    type: Constants.Actions.CorrugatorAdhesive_Delete_Rollback, meta: { delCAD }
                }
            }
        }
    }
};
export const ClearData = () => {
    return { type: Constants.Actions.CorrugatorAdhesive_ClearData };
};