import Constants from '../constants';
import produce from "immer";
import { UpdateArray, UpdateArrayRange } from '../misc/ArrayFuncs';
const roundToTwo = (num) => {
    return +(Math.round(num + "e+2") + "e-2");
}
const initDataState = [];
const ThermalImages = (state = initDataState, action) => {
    switch (action.type) {
        case Constants.Actions.ThermalImages.Add:
            return UpdateArray(action.payload, state);
        case Constants.Actions.ThermalImages.Add_Rollback:
            const tiID = state.findIndex(iv => iv.id === action.meta.newTImg.id);
            if (tiID !== -1) {
                return produce(state, pd => { pd.splice(tiID, 1) });
            } else {
                return state;
            }
        case Constants.Actions.ThermalImages.Add_Commit:
            return UpdateArray(action.payload?.data, state);
        case Constants.Actions.ThermalImages.ClearData:
            return initDataState;
        case Constants.Actions.ThermalImages.Update:
            return UpdateArray(action.payload.update, state);
        case Constants.Actions.ThermalImages.Update_Commit:
            return UpdateArray(action.payload?.data, state);
        case Constants.Actions.ThermalImages.Update_Rollback:
            return UpdateArray(action.meta.data.current, state);
        case Constants.Actions.ThermalImages.GetByParentID:
            return UpdateArrayRange(action.data, state);
        default:
            return state;
    }
}
export default ThermalImages;