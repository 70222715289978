import Constants from '../../constants';
//Modules
import React, { useState, useEffect } from 'react';
import {useNavigate} from 'react-router-dom';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { createSelector } from 'reselect';
//MUI
import MUIDataTable from "mui-datatables";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
//Compontents
import CustomSelectToolbar from '../CustomSelectToolbar';
import AddCustomToolbar from "../AddCustomToolbar";
import PrintDialog from '../Print/PrintDialog';
import { GridDateFilter, DateFilterLogic, UpdateFilterList } from '../MetaData/GridDateFilter';
import { GridEnumFilter_CheckBox, EnumFilterLogic, EnumUpdateFilterList, EnumRenderValue } from '../MetaData/GridEnumFilter_CheckBox';
import { ActionItemStatus } from './ActionItem_MiscViews';
import ActionItemsPrint from './ActionItemsPrint';
import { DateView } from '../MetaData/MiscViews';
//Actions
import { GetActionsByPlantID, AddActionItem } from '../../actions/ActionItemActions';
import { AddActionItemLink } from '../../actions/ActionItemLinkActions';
import { enqueueSnackbar } from '../../actions/NotificationActions';
//Misc 
import { usePlantIDFromVisitID } from '../MetaData/PlantOptions';

const constants = Constants;
const ActionItemsSel = state => state.ActionItems;
const GetActionsItemsBy_EntityType_Reference = createSelector(
    [ActionItemsSel,
        (s, entityType) => entityType,
        (s,entityType,reference)=>reference,
        (s,entityType,reference,plantID)=>plantID
    ],
    (actionItems, entityType, reference,plantID) => {
        return actionItems.filter(ai => ai.entityType == entityType && ai.reference == reference && ai.plantID==plantID && !ai.deleted);
    });
const KeyItem_ActionItems_SelectGrid = props => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const plantID = usePlantIDFromVisitID(props.keyItem?.visitID);
    const actionItems = useSelector(s => GetActionsItemsBy_EntityType_Reference(s, props.keyItem?.entityType, props.keyItem?.reference, plantID));
    const [selectedRows, setSelectedRows] = useState([]);
    const handleAdd = () => {
        if (!plantID || !props.keyItem) {
            dispatch(enqueueSnackbar({
                message: 'Could not Add Action Item Invalid KeyItem',
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'error'
                }
            }));
            return;
        }
        const newID = uuidv4();
        const newActionItem = {
            id: newID,
            description: props.keyItem.notes,
            plantID: plantID,
            percentCompleted: 0,
            startDate: (new Date()).toJSON(),
            targetDate: (new Date()).toJSON(),
            status: 0,
            entityType: props.keyItem.entityType,
            reference: props.keyItem.reference,
            createdFromKeyItemID:props.keyItem.id,
            imageID:props.keyItem.imageID
        };
        dispatch(AddActionItem(newActionItem));
        const newAILink = {
            actionItemID: newID,
            keyItemID: props.keyItem.id,
            visitID: props.keyItem.visitID
        }
        dispatch(AddActionItemLink(newAILink));
        navigate('/ActionItem/' + newID);
    }
    const ClickLink = () => {
        const ai = actionItems[selectedRows[0]];
        const newAILink = {
            actionItemID: ai.id,
            keyItemID: props.keyItem.id,
            visitID: props.keyItem.visitID
        }
        dispatch(AddActionItemLink(newAILink));
        navigate('/ActionItem/' + ai.id);
    }
   
    const rowSelect = (rowsSelectedData, allRows, rowsSelected) => {
            setSelectedRows(rowsSelected);
        };
    const columns = [
        {
            name: "description", label: "Description", options: { filterType: 'textField' }
        },
        {
            name: "actionNeeded", label: "ActionNeeded", options: { filterType: 'textField' }
        },
        { name: "benefit", label: "Benefit", options: { display: false, filterType: 'textField' } },
        { name: "percentCompleted", label: "% Completed", options: { display: false } },
        {
            name: "status", label: 'Status', options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <ActionItemStatus value={value} />
                    );
                },
                filter: true,
                filterType: 'custom',
                customFilterListOptions: {
                    render: EnumRenderValue(constants.Enums.ActionStatus),
                    update: EnumUpdateFilterList
                },
                filterOptions: {
                    logic: EnumFilterLogic,
                    display: (filterList, onChange, index, column) => <GridEnumFilter_CheckBox filterList={filterList} onChange={onChange} index={index} column={column} enum={constants.Enums.ActionStatus} />
                }
            }
        },

        {
            name: "startDate", label: "Start", options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <DateView value={value} />
                    );
                },
                filter: true,
                filterType: 'custom',
                customFilterListOptions: {
                    update: UpdateFilterList
                },
                filterOptions: {
                    logic: DateFilterLogic,
                    display: (filterList, onChange, index, column) => <GridDateFilter filterList={filterList} onChange={onChange} index={index} column={column} />
                }
            }
        }];
    const options = {
        filterType: 'checkbox', selectableRows: 'single', selectableRowsOnClick: true, print: false,
        download: false, filter:false,viewColumns:false,
        fixedHeader: true,
        responsive: 'standard',
        customToolbar: () => {
            return (
                <AddCustomToolbar onClick={handleAdd} />
            );
        },
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
            return <CustomSelectToolbar selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows}
                clickLink={ClickLink}  />;
        },
        onRowSelectionChange: rowSelect,
        rowsSelected: selectedRows
    };
    return <MUIDataTable
        title={"Action Items"}
        data={actionItems}
        columns={columns}
        options={options}
    />;
}
export default KeyItem_ActionItems_SelectGrid;