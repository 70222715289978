//Libraries
import React, { useState, useEffect } from 'react';
// import speedTest from 'speed-test';
import { ReactInternetSpeedMeter } from 'react-internet-meter'
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { OFFLINE_STATUS_CHANGED } from "@redux-offline/redux-offline/lib/constants";
//MUI
import Grid from '@mui/material/Grid';
//Compontents
import VisitsGridView from './Visit/VisitsGridView';
import ActionItemsGrid from './ActionItem/ActionItemsGrid';
import PlantTable from './PlantTable';

//Actions
import { GetAllData } from '../actions/DataActions';
const PlantOverviewView = props => {
    const [checkSpeed, SetCheckSpeed] = React.useState(
        ""
      );
    const dispatch = useDispatch();
    const [selectedPlant, changeSelectedPlant] = useState(null);
    const [downloadSpeed, setdownloadSpeed] = useState(null);
    const [uploadSpeed, setuploadSpeed] = useState(null);
    const online = useSelector(s => s.offline.online);
    // useEffect(() => {
    //     const test = speedTest({maxTime: 5000})
    //     test.on('data', data=> {
    //         setdownloadSpeed(data.speeds.download)
    //         setuploadSpeed(data.speeds.upload)
    //     })
    //     return () => {
    //         test.cancel();
    //     }
    // }, []);
    useEffect(() => {
        if (online) {
            dispatch(GetAllData());
        }
    }, [online]);
    return (
        <div>    
            {/* <ReactInternetSpeedMeter
            txtSubHeading="Internet connection is slow"
            outputType="" // "alert"/"modal"/"empty"
            customClassName={null}
            pingInterval={2000} // milliseconds
            txtMainHeading="Opps..."
            thresholdUnit="megabyte" // "byte" , "kilobyte", "megabyte"
            threshold={50}
            imageUrl="https://source.unsplash.com/random?topics=nature"
            downloadSize="1561257" //bytes
            callbackFunctionOnNetworkDown={(data) =>
            console.log(`Internet speed : ${data}`)
            }
                callbackFunctionOnNetworkTest={(data) => SetCheckSpeed(data)}
            />
             <span className="display-1">{checkSpeed} MB/s</span> */}
            <Grid container>                
                <Grid item sm={6}>
                    <PlantTable SelectedPlant={selectedPlant} OnChange={(p) => changeSelectedPlant(p) } />
                </Grid>
                <Grid item sm={6}>                                    
                    <VisitsGridView plantID={selectedPlant ? selectedPlant.id:null} />
                    <ActionItemsGrid plantID={selectedPlant ? selectedPlant.id : null} />
                </Grid>                    
            </Grid>
        </div>
    );

};


export default PlantOverviewView;