import Constants from '../constants';
import produce from "immer";
import { v4 as uuidv4 } from 'uuid';
import { FetchErrorLogger, FetchCatchHandler } from '../middlewares';
export const GetPlantFormulaChanges = (plantID) => {
    console.log('FormulaActions.js GetPlantFormulas Called');
    return (dispatch, getState) => {
        const state = getState();
        const formulaIDs = state.Formulas.filter(l => l.plantID === plantID && !l.deleted).map(l => l.id);
        if (state.offline.online) {
            let fetchTask = fetch(Constants.URLs.FormulaChange + '?PlantID=' + plantID, { headers: { 'Authorization': 'Bearer ' + state.Auth.token } })
                .then(FetchErrorLogger("GetPlantFormulaChanges", plantID, dispatch))
                .then(response => response.json())
                .then(fChanges => {
                    dispatch({ type: Constants.Actions.FormulaChange_ReceiveByPlant, data: fChanges, plantID: plantID, formulaIDs: formulaIDs })
                })
                .catch(FetchCatchHandler("GetPlantFormulaChanges", plantID, dispatch));
        }
    };
};
export const ClearData = () => {
    return { type: Constants.Actions.FormulaChanges_ClearData };
};