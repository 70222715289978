import Constants from '../../constants';
//Library Imports
import React, { useEffect, useState } from 'react';
import { useNavigate,useParams } from 'react-router-dom';
import { useSelector,useDispatch,shallowEqual } from 'react-redux';
import { useReportUser,usePlantDataFromVisitID, useVisitData, useReportName } from '../../misc/UseFuncs';
import { SelChecklistByID, SelChecklistGroupsByCLID, SelChecklistItemsByCLID } from '../../selectors/ChecklistSelectors';
import { KeyItemsFilteredByArray } from '../../selectors/KeyItemSelectors';
import { AttachmentsByFilterArray } from '../../selectors/AttachmentSelectors';
import { GetAttachmentsByArray } from '../../actions/AttachmentActions';
import { ScaleImages } from '../../misc/ImageFuncs';
import ReportViewer from '../Report/ReportViewer';
import { ErrorNotification } from '../../actions/NotificationActions';
const ChecklistPrint = props => {
    const navigate = useNavigate();
    const params=useParams();
    const clID = params.SurveyID;
    const printData=useChecklistPrintData(clID);
    if(printData.Loaded){
        return <ReportViewer type="Checklist" name="BHSChecklist"  printData={printData}
            onClose={()=>navigate(-1)} />;
    }
    return <div>Loading Data</div>;
}
export default ChecklistPrint;
export const useChecklistPrintData=(clID)=>{
    const dispatch=useDispatch();
    const [scaled,setScaled]=useState(false);
    const [scaledAttachements,setScaledAttachments]=useState([]);
    const [loaded,setLoaded]=useState(false);
    const online =useSelector(s=>s.offline.online,shallowEqual);
    const LastSync=useSelector(s=>s.LastSync,shallowEqual);
    const Token=useSelector(s=>s.Auth.token,shallowEqual);
    const checklist = useSelector(s=>SelChecklistByID(s,clID));
    const groups=useSelector(s=>SelChecklistGroupsByCLID(s,clID));
    const items=useSelector(s=>SelChecklistItemsByCLID(s,clID));
    const imageIDs=items.filter(i=>i.imageID!=Constants.EmptyGuid).map(i=>i.imageID);
    useEffect(()=>{
        if ( online){
            GetAttachmentsByArray(items,dispatch,LastSync,Token)
            .then(
                ()=>setTimeout(()=>setLoaded(true),200),
                ()=>{
                    dispatch(ErrorNotification("Error Loading Attachments for Report",{File:"CheclistPrint.js",Function:"useChecklistPrintData"}));
                    setTimeout(() => setLoaded(true), 200);
                }
            );
        } else {
            setTimeout(() => setLoaded(true), 200)
        }
    },[JSON.stringify(imageIDs)]);
    const UserData = useReportUser();
    const plant = usePlantDataFromVisitID(checklist?.visitID);
    const visit = useVisitData(checklist?.visitID);
    const keyItems = useSelector(s=>KeyItemsFilteredByArray(s,items));
    const attachments=useSelector(state=>AttachmentsByFilterArray(state,items));
    const reportName=useReportName("BHSChecklist",plant?.name,visit);
    let visitDate=null;
    if(visit){
        const d=new Date(visit.startDate);
        visitDate=+(d.getMonth()+1).toString().padStart(2,"0")+"/"+d.getDate().toString().padStart(2,"0")+"/"+d.getFullYear();
    }
    const retVal ={Loaded:false,Data:{},ReportName:reportName,EmailSubject:'BHSChecklist '+plant?.name+' '+visitDate}
    if(loaded){
        if(scaled){
            const dJSON ={Checklist:checklist, User:UserData, Plant:plant, Visit:visit, Groups:groups , Items:items, KeyItems:keyItems, Attachments:scaledAttachements };
            const Data ={ChecklistData:JSON.stringify(dJSON)};
            retVal.Loaded=true;
            retVal.Data=Data;
            
        } else {
            Promise.all(ScaleImages(attachments,200,200))
            .then((values)=>{
                setScaledAttachments(values);
                setScaled(true);
            });
        }
    } 
    return retVal;
}