import Constants from '../constants';
const constants = Constants;
export const AddSyncTimestamp = (id,entity) => {
    return { type: constants.Actions.LastSync.AddSync, id: id, entity:entity  };
}
export const CheckReSync = (id, LastSync, threashold = 14400000) => {
    let retVal = true;
    const lastSync = LastSync.find(ls => ls.id === id);
    if (lastSync) {
        const cur = new Date();
        const syncD = new Date(lastSync.timeStamp);
        retVal = cur - syncD > threashold;
        return retVal;
    }
    return retVal;
}
export const ClearLastSyncs =()=>{
    return {type: constants.Actions.LastSync.ClearData};
}