import React from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
//MUI Imports
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import './Print.css';
const useStyles = makeStyles((theme) => ({
  
    thinLine: {
        border: '2px solid darkgray',
        borderRadius: '2px',
        width: '100%'
        
    }
}));
const PrintFooter = props => {
    const classes = useStyles();
    const userData = useSelector(s => s.Auth.userData, shallowEqual);
    const d = new Date();
    return <div id="printFooter">
        <hr className={classes.thinLine} />
        <Grid container style={{width:'100%'} }>
            <Grid item xs={3} style={{textAlign:"left"}}>{ userData.fullName}</Grid>
            <Grid item xs={3} style={{ textAlign: "center" }}>{userData.email}</Grid>
            <Grid item xs={3} style={{ textAlign: "center" }}>{userData.phone}</Grid>
            <Grid item xs={3} style={{ textAlign: "right" }}>Printed: {d.toLocaleDateString()}</Grid>
        </Grid>
    </div>;
}
export default PrintFooter;