import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import ActionItemsGrid from '../ActionItem/ActionItemsGrid';
class ActionItemsTab extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        let plantID = null;
        if (this.props.PlantData) {
            plantID = this.props.PlantData.id;
        }
        return <ActionItemsGrid plantID={plantID} />;
    }
}
export default ActionItemsTab;