import React, { useState } from 'react'
import { AddDSI, UpdateDSI, DeleteDSI } from '../../actions/DesiredSpeedImprovementsActions';
import { FluteName, FluteSelectFormik } from '../MetaData/FluteViews'
import { useSelector, useDispatch } from 'react-redux'
import AddCustomToolbar from "../AddCustomToolbar";
import CustomSelectToolbar from '../CustomSelectToolbar';
import MUIDataTable from "mui-datatables";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Checkbox from '@mui/material/Checkbox';
import { Formik, Form, Field } from 'formik';
import { TextField, CheckboxWithLabel } from 'formik-mui';
import { AutoCompleteFormik } from '../FormikUI/AutoCompleteFormik';
import TextFieldFormik from '../FormikUI/TextFieldFormik';
const isInt = val => {
    if (/^[-+]?(\d+|Infinity)$/.test(val)) {
        return true;
    } else {
        return false;
    }
}
export const DesiredSpeedImprovementsGridView = props => {
    //const classes = useStyles();
    const dispatch = useDispatch();
    const dsiData = useSelector(state => state.DesiredSpeedImprovements);
    const plantDSI = dsiData.filter(p => p.plantID === props.plantID);
    const [modalOpen, setModal] = useState(false);
    const initDSI = { grade: '', currentFPM: '', goalFPM: '', flute: 0 };
    const [selectedRows, setSelectedRows] = useState([]);
    const [addDSI, setAddDSI] = useState(false);
    const columns = [{ name: "grade", label: "Grade" }, { name: "currentFPM", label: "Current FPM" }, { name: "goalFPM", label: "Goal FPM" }
        , {
            name: "flute"
            , label: "Flute"
            , options: {
                filter: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <FluteName value={value} />
                    );
                },
            }
        }];
    const ClickOpen = () => {
        setAddDSI(false);
        setModal(true);
    };
    const ClickDelete = () => {
        dispatch(DeleteDSI(dsiData[selectedRows[0]]));
        setSelectedRows([]);
    };
    const ClickAdd = (e) => {
        setModal(true);
        setAddDSI(true);
    };
    const rowSelect = (rowsSelectedData, allRows, rowsSelected) => {
        setSelectedRows(rowsSelected);
    };
    const options = {
        filterType: 'checkbox', selectableRows: 'single', selectableRowsOnClick: true, print: false,
        fixedHeader: true,
        responsive: 'standard',
        download: false,
        customToolbar: () => {
            return (
                <AddCustomToolbar onClick={ClickAdd} />
            );
        },
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
            return <CustomSelectToolbar selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows} clickEdit={ClickOpen} clickDelete={ClickDelete} />;
        },
        onRowSelectionChange: rowSelect,
        rowsSelected: selectedRows
    };
    const handleSubmit = (e) => {
        console.log("Handle Submit", e);
        setModal(false);
        if (addDSI) {
            e.plantID = props.plantID;
            dispatch(AddDSI(e));
        } else {
            //Update
            dispatch(UpdateDSI(e, dsiData[selectedRows[0]]));
        }
    }
    const getInitValues = () => {
        if (modalOpen) {
            if (addDSI) {
                return initDSI;
            } else {
                return plantDSI[selectedRows[0]];
            }
        } else
            return null;
    }
    return (
        <Card>
            <CardContent>
                <MUIDataTable
                    title={"Speed Improvements"}
                    data={plantDSI}
                    columns={columns}
                    options={options}
                />
                <DSIFormDialog modalOpen={modalOpen} closeModal={() => setModal(false)} onSubmit={handleSubmit} initialValues={getInitValues()} dsiData={dsiData} />
            </CardContent>
        </Card>
    );
}
export const DSIFormDialog = props => {
    const validate = (values, props /* only available when using withFormik */) => {
        const errors = {};
        
        if (!Number.isInteger(values.currentFPM)) {
            errors.currentFPM = 'Whole numbers only';
        }
        if (!Number.isInteger(values.goalFPM)) {
            errors.goalFPM = 'Whole numbers only';
        }

        return errors;
    };
    return (
        <Dialog open={props.modalOpen} onClose={props.closeModal} >
            <Formik initialValues={props.initialValues} onSubmit={props.onSubmit} validate={ validate}>
                <Form>
                    <DialogTitle >Add Speed Improvement</DialogTitle >
                    <DialogContent>
                        <div>
                            <AutoCompleteFormik
                                name="grade"
                                options={props.dsiData}
                                label="Grade"
                                freeSolo
                            />
                        </div>
                        <div>
                            <TextFieldFormik
                                
                                name="currentFPM"
                                type="number"
                                label="Current FPM"
                            />

                        </div>
                        <div>
                            <TextFieldFormik
                                
                                name="goalFPM"
                                type="number"
                                label="Goal FPM"
                            />

                        </div>
                        <div>
                            <FluteSelectFormik name="flute" label="Flute" />
                            
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit">Save</Button>
                        <Button onClick={props.closeModal}>Cancel</Button>
                    </DialogActions>
                </Form>
            </Formik>
        </Dialog >
    );
}