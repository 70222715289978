import { createSelector } from 'reselect';
export const AttachmentsSel = state=>state.Attachments.filter(a=>!a.deleted);
export const AttachmentsByFilterArray=createSelector(AttachmentsSel,(state,filterArray)=>filterArray,
    (Attachments,filterArray)=> {
        return Attachments
        .filter(mf=>filterArray
            .some(i=>{
                let retVal = false;
                Object.keys(i).forEach(key=>{         
                    if(key.toLowerCase().endsWith("imageid") && i[key]===mf.id){
                        retVal=true;
                    }
                });
                return retVal;
            }));
    }
    );
export const  GetAttachmentSel = createSelector(AttachmentsSel,(_,aID)=>aID,
    (attachments,aID)=>{
        return attachments.find(a=>a.id===aID);
    }
);