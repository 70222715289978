import React, { useState } from 'react'
import { FormulaSelection } from './FormulaSelectionView';
import FormulaEdit from './FormulaEditView';
export const FormulasEditView = props => {
    //const classes = useStyles();
    const [selectedFormula, setSelFormula] = useState('0');
    const initDSI = { grade: '', currentFPM: '', goalFPM: '', flute: 0 };
    let fe = null;
    if (selectedFormula != '0') {
        fe = <FormulaEdit formulaID={selectedFormula} visitID={ props.visitID } />;
    }
    return (
        <div>
            <FormulaSelection plantID={props.plantID} addLine selected={selectedFormula} handleChange={newVal => setSelFormula(newVal)} />
            {fe}
        </div>
    );
};