import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import CloudOffOutlinedIcon from '@mui/icons-material/CloudOffOutlined';
import NightsStayOutlinedIcon from '@mui/icons-material/NightsStayOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import { CheckIsOnline, SyncManualy, SyncOff } from '../../actions/NetworkStateActions'
import { AlertAction } from '../../actions/AlertAction';
import Constants from '../../../src/constants';
import { Preview } from '@mui/icons-material';
const OnlineIcon = props => {

    
    const dispatch = useDispatch();
    const [selectedLine, setSelLine] = useState('0');
    const [speed,setSpeed]=useState(0);
    const speedRef = useRef(0)
    // const [payloadSize,setPayloadSize]=useState(0);
    // const [showAlert,setshowAlert]=useState(false);

    // const abc = useSelector(state => {
    //     return state
    // }, shallowEqual);
    
    // var pdata = abc.offline.outbox
    // var biteSize = 0
    
    // useEffect(()=> {
    //     biteSize = 0
    //     pdata.forEach(element => {
    //         biteSize += Object.keys(JSON.stringify(element.payload)).length; 
    //     });

    //     var kbpsSize = (biteSize / 1024).toFixed(2); 
    //     var mbpSize = (kbpsSize / 1024).toFixed(2); 
    //     setPayloadSize(Number(mbpSize))
    // }, [pdata])

    useEffect(() => {
        // console.log('speed-------------------', speed);
        if (speed > 5120) {
            dispatch({ type: Constants.Actions.AlertAction.ClearData });
            setTimeout(() => {
                dispatch( AlertAction("Seems you have good internet connectivity. Please stay where you are and don't move from this location after you start the sync process", AlertAction(), 'sync',
                (confirmed) => {})); 
            }, 100);
        } else if (speed !== 0) {
            dispatch(
                AlertAction("Seems you have POOR Internet connectivity. Please move to a different area and click on 'Go Online' once again to check if you have stable connection, please make sure you have full wifi or internet signal on your device before you resume", AlertAction(), 'cancel',
                (confirmed) => {
                })
            );
        } else {
            dispatch({ type: Constants.Actions.AlertAction.ClearData });
        }
    }, [speed])
    const manualSync = (check) => {
        speedRef.current = 0
        setSpeed(Pre => Pre-Pre) 
        var isGoodSpeed = true
        if (window.navigator.onLine) {
            dispatch({ type: Constants.Actions.AlertAction.ClearData });
            setTimeout(() => {
                dispatch(
                    AlertAction('Please wait, checking internet connection quality...', AlertAction(), 'loader',
                    (confirmed) => {}
                )
                );
            }, 100);
        } else {
            dispatch(
                AlertAction('Could not connect the internet. Please check your internet connection and try again.', AlertAction(), 'cancel',
                    (confirmed) => {}
                )
            );
        }

        // let count = 1;
        // const intervalId = setInterval(() => {
        // console.log("count --", count,  "------", speed);
        // if (count >= 15) {
        //     clearInterval(intervalId);
        //     console.log("Count reached 15. Stopping...");
        // }
        // count++;
        // }, 1000);
        
        setTimeout(() => {
            // console.log('aaaa', speed); 
            // console.log('speedRef', speedRef.current); 
            if (speedRef.current === 0 && window.navigator.onLine) {
                dispatch({ type: Constants.Actions.AlertAction.ClearData });
                isGoodSpeed = false
                setTimeout(() => {
                    setSpeed(1) 
                }, 100);
            }
        }, 15000);

        var userImageLink =   "/api/assets/myimage.jpg"; 
        var time_start, end_time; 
        var downloadSize = 5616998; 
        var downloadImgSrc = new Image(); 
        downloadImgSrc.onload =  function () { 
            end_time = new Date().getTime(); 
            if (isGoodSpeed) {     
                displaySpeed(); 
            }
        }; 
        time_start = new Date().getTime(); 
        downloadImgSrc.src = userImageLink; 
        function displaySpeed() { 
            var timeDuration = (end_time - time_start) / 1000; 
            var loadedBits = downloadSize * 8; 
            
            var bps = (loadedBits / timeDuration).toFixed(2); 
            var speedInKbps = (bps / 1024).toFixed(2); 
            dispatch({ type: Constants.Actions.AlertAction.ClearData });
            setSpeed(pre => pre + Number(speedInKbps))
            speedRef.current = Number(speedInKbps)
        }




    }
    const mSyncOff = () => {
        dispatch(SyncOff());
    }
    const status = useSelector(s => {
        return {
            online: s.offline.online,
            outboxCount: s.offline.outbox.length,
            offlineMode: s.Auth.offlineMode,
            windowOnline: s.NetworkState.online,
            siteAccess: s.NetworkState.siteAccess,
            authenticated:s.Auth.authenticated
        }
    }, shallowEqual);
    useEffect(() => {
        dispatch(CheckIsOnline());
    }, [status.windowOnline, status.siteAccess, status.authenticated])
    if (!status.online) {
        return <>
        <Button style={{ marginRight: '10px' }} variant="contained" color="secondary" disabled>Go Offline</Button>
        <Button style={{ marginRight: '10px' }} variant="contained" color="secondary" onClick={() => manualSync('check')}>Go Online</Button>
        <Tooltip title="Offline" aria-label="Offline">
            
            <CloudOffOutlinedIcon fontSize="large" />
            
        </Tooltip>
        
        </> 
    }
    if (status.offlineMode) {
        return <>
        <Tooltip title="OfflineMode" aria-label="OfflineMode">
            <NightsStayOutlinedIcon fontSize="large" />
        </Tooltip>
        </> 
    }
    if (status.outboxCount > 0) {
        return <>
        <Tooltip title="Uploading" aria-label="Uploading">
            <CloudUploadOutlinedIcon fontSize="large" />
        </Tooltip>
        </>
    }
    return <> 
       <Button style={{ marginRight: '10px' }} variant="contained" color="secondary" onClick={() => mSyncOff()}>Go Offline</Button>
       <Button style={{ marginRight: '10px' }} variant="contained" disabled color="secondary">Go Online</Button>
    <Tooltip title="Synced" aria-label="Synced">
        <CloudDoneOutlinedIcon fontSize="large" />
        
    </Tooltip>
    </> 
}
export default OnlineIcon;