import { number } from 'prop-types';
import React, { useEffect, useContext, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { createSelector } from 'reselect';
export const useGetPlantNameFromID = (plantID) => {
    const plant = useSelector(s => s.Plants.find(p => p.id === plantID), shallowEqual);
    return plant ? plant.name : '';
};
export const useReportUser =()=>{
    const userData = useSelector(s => s.Auth.userData, shallowEqual);
    return userData
};
export const usePlantDataFromVisitID=(visitID)=>{
    const visit = useSelector(s=>s.Visits.find(v=>v.id===visitID),shallowEqual);  
    return usePlantData(visit?.plantID);
}
export const usePlantData=(plantID)=>{
    const plant = useSelector(s => s.Plants.find(p => p.id === plantID), shallowEqual);
    return plant??{};
}
export const useReportName=(reportName,plantName,visit=null)=>{
    let d = new Date();
    if(visit?.startDate){
        d=new Date(visit.startDate);
    }
    return plantName+"_"+reportName+"_"+d.getFullYear()+"_"+(d.getMonth()+1).toString().padStart(2,"0")+"_"+d.getDate().toString().padStart(2,"0");
}
// export const conImpReportName=(plantName,DetailsObj,compName,location)=>{
//     let d=new Date(DetailsObj.startDate);
//     let e=new Date(DetailsObj.enddate);
//     if (compName === '' && location === '') {
//         return plantName+"-"+d.getFullYear()+"_"+(d.getMonth()+1).toString().padStart(2,"0")+"_"+d.getDate().toString().padStart(2,"0");
//     } else {
//         return compName+", "+location+"-"+d.getFullYear()+"_"+(d.getMonth()+1).toString().padStart(2,"0")+"_"+d.getDate().toString().padStart(2,"0");
//     }
// }

export const conImpReportName=(userName, plantName,DetailsObj,compName,location,baseline)=>{
    let d=new Date(DetailsObj.startDate);
    let e=new Date(DetailsObj.enddate);
    let DD=new Date(baseline.lastSyncDate);
    // let EE=new Date(baseline.baselineEndDate);
    if (compName === '' && location === '') {
        return userName+"-"+plantName+"-"+"CI-("+d.getFullYear()+"_"+(d.getMonth()+1).toString().padStart(2,"0")+"_"+d.getDate().toString().padStart(2,"0")+"--"+e.getFullYear()+"_"+(e.getMonth()+1).toString().padStart(2,"0")+"_"+e.getDate().toString().padStart(2,"0")+")-Baseline-"+""+DD.getFullYear()+"_"+(DD.getMonth()+1).toString().padStart(2,"0")+"_"+DD.getDate().toString().padStart(2,"0")
    } else {
        return compName+", "+location+"-"+d.getFullYear()+"_"+(d.getMonth()+1).toString().padStart(2,"0")+"_"+d.getDate().toString().padStart(2,"0");
    }
}
export const conImpRepName=(userName, plantName,DetailsObj,compName,location,baseline)=>{
    const monthNames = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'June',
        'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
    ];
    let d= DetailsObj.startDate !== '' ? new Date(DetailsObj.startDate) : ''
    let e= DetailsObj.enddate !== '' ? new Date(DetailsObj.enddate) : ''
    let selectedDate= DetailsObj.selectedDate !== '' ? new Date(DetailsObj.selectedDate) : ''
    let baselineStartDate= baseline.baselineStartDate !== '' ? new Date(baseline.baselineStartDate +' 10:00 AM') : ''
    let baselineEndDate= baseline.baselineEndDate !== '' ? new Date(baseline.baselineEndDate +' 10:00 AM') : ''

    let createdDate = selectedDate !== '' ? selectedDate.getDate().toString().padStart(2,"0") + "-" + monthNames[Number((selectedDate.getMonth()).toString().padStart(2,"0"))] + "-" + Number(selectedDate.getFullYear())%100 : ''

    let blineStrDt = baselineStartDate !== '' ? baselineStartDate.getDate().toString().padStart(2,"0")+ "-" + monthNames[Number((baselineStartDate.getMonth()).toString().padStart(2,"0"))] + "-" + Number(baselineStartDate.getFullYear())%100 + '-' : ''

    let blineEndDt = baselineEndDate !== '' ? '-' + baselineEndDate.getDate().toString().padStart(2,"0") + "-" + monthNames[Number((baselineEndDate.getMonth()).toString().padStart(2,"0"))] + "-"+ Number(baselineEndDate.getFullYear())%100  + '-' : ''

    let ciStr = d !=='' ? '-' + d.getDate().toString().padStart(2,"0")+ "-"+ monthNames[Number((d.getMonth()).toString().padStart(2,"0"))] + "-" + Number(d.getFullYear())%100 + '-' : ''

    let ciEnd = e !=='' ? '-' + e.getDate().toString().padStart(2,"0")+ "-" + monthNames[Number((e.getMonth()).toString().padStart(2,"0"))] + "-" + Number(e.getFullYear())%100 : ''
    
    return plantName+"-"+"on-"+ createdDate +"-with-Baseline-"+ blineStrDt + blineEndDt +"-and-CI"+ ciStr + ciEnd
    
}

export const useReportNameFromPlantID=(reportName,plantID,visit=null) =>{
    const plantName = useGetPlantNameFromID(plantID); 
    return useReportName(reportName,plantName,visit);
}
export const useReportNameFromVisitID = (reportName,visitID)=>{
    const visit = useSelector(s=>s.Visits.find(v=>v.id===visitID),shallowEqual);
    return useReportNameFromPlantID(reportName,visit?.plantID,visit);
}
export const usePlantNameFromVisitID = (visitID)=>{
    const visit = useSelector(s=>s.Visits.find(v=>v.id===visitID),shallowEqual);
    return useGetPlantNameFromID(visit?.plantID);
}
export const useVisitData=(visitID)=>{
    const visit = useSelector(s=>s.Visits.find(v=>v.id===visitID),shallowEqual);
    return visit ?? {};
}