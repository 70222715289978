import Constants from '../constants';
import { v4 as uuidv4 } from 'uuid';
import produce from "immer";
const constants = Constants;
export const GetRequest = (url, type, id, callback) => {
    return (dispatch, getState) => {
        //offline.online
        const state = getState();
        if (state.offline.online && state.Auth.token && !state.Auth.offlineMode) {
            dispatch({
                type: constants.Actions.RequestStatus.StartRequest,
                data: {
                    url: url,
                    reqType: type,
                    id: id,
                    complete: false,
                    result: 'Sent'
                }
            });
            let fetchTask = fetch(url, {
                headers: { 'Authorization': 'Bearer ' + state.Auth.token}
            })
                .then(response => {
                    if (response.status === 404) {
                        dispatch({
                            type: constants.Actions.RequestStatus.StartRequest,
                            data: {
                                url: url,
                                reqType: type,
                                id: id,
                                complete: true,
                                result: 'Not Found'
                            }
                        });
                        return null;
                    }
                    return response.json();
                })
                .then(data => {
                    if (data) {
                        dispatch({
                            type: constants.Actions.RequestStatus.StartRequest,
                            data: {
                                url: url,
                                reqType: type,
                                id: id,
                                complete: true,
                                result: 'Success'
                            }
                        });
                        callback(data);
                    }
                })
                .catch(error => {
                    dispatch({
                        type: constants.Actions.RequestStatus.StartRequest,
                        data: {
                            url: url,
                            reqType: type,
                            id: id,
                            complete: true,
                            result: error
                        }
                    });
                })
        } else {
            dispatch({
                type: constants.Actions.RequestStatus.StartRequest,
                data: {
                url: url,
                reqType: type,
                id: id,
                complete: true,
                result: 'Offline'
                }
            });
        }
    }
}