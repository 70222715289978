import Constants from '../constants';
import produce from "immer";
const initDataState = {
     ActionDataCache: []
    , SurveyDataCache: []
    
};
const updateArray = (updObj, updArray) => {
    let updated = false;
    const newArray = updArray.map((curObj, ind) => {
        if (curObj.id === updObj.id) {
            updated = true;
            return updObj;
        } else { return curObj; }
    });
    if (!updated) {
        newArray.push(updObj);
    }
    return newArray
};

//TODO: Normalize Data Maybe?
const Data = (state = initDataState, action) => {
    switch (action.type) {
         default:
            return state;
    };
}
export default Data;