import Constants from '../constants';
import { v4 as uuidv4 } from 'uuid';
import { FetchErrorLogger, FetchCatchHandler } from '../middlewares';
const constants=Constants;
//Start Measurements
export const AddMeasurement = (newSM) => {
    newSM.id = uuidv4();
    return {
        type: Constants.Actions.StarchMeasurement.Add,
        payload: newSM,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.StarchMeasurement, method: 'POST', body: JSON.stringify(newSM), headers: { 'content-type': 'application/json' }
                },
                commit: {
                    type: Constants.Actions.StarchMeasurement.Add_Commit, meta: { newSM }
                },
                rollback: {
                    type: Constants.Actions.StarchMeasurement.Add_Rollback, meta: { newSM }
                }
            }
        }
    }
};
export const DeleteMeasurement = (delSM) => {
    return {
        type: Constants.Actions.StarchMeasurement.Delete,
        payload: delSM,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.StarchMeasurement + delSM.id, method: 'DELETE'
                },
                commit: {
                    type: Constants.Actions.StarchMeasurement.Delete_Commit, meta: { delSM }
                },
                rollback: {
                    type: Constants.Actions.StarchMeasurement.Delete_Rollback, meta: { delSM }
                }
            }
        }
    }
};
export const GetMeasurementsByVisitID = (visitID) => {
    return (dispatch, getState) => {
        //offline.online
        const state = getState();
        if (state.offline.online) {
            let fetchTask = fetch(Constants.URLs.StarchMeasurement + "?VisitID=" + visitID, { headers: { 'Authorization': 'Bearer ' + state.Auth.token } })
                .then(FetchErrorLogger("GetMeasurementsByVisitID", visitID, dispatch))
                .then(response => response.json())
                .then(ivs => {
                    //Ignore 404 errors
                    if (Array.isArray(ivs)) {
                        dispatch({ type: constants.Actions.StarchMeasurement.GetByVisitID, data: ivs, visitID: visitID });

                    }
                })
                .catch(FetchCatchHandler("GetMeasurementsByVisitID", visitID, dispatch));
        }
    }
};
export const ClearMeasurements =()=>{
    return {type: constants.Actions.StarchMeasurement.ClearData};
}