import Constants from '../../constants';
//Libraries
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';
//MUI
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import MUIDataTable from "mui-datatables";
//Compontents
import { usePlantIDFromVisitID } from '../MetaData/PlantOptions';
import { KeyItemsGrid } from "./KeyItemsGrid";
import CustomSelectToolbar from '../CustomSelectToolbar';
import { DateView } from '../MetaData/MiscViews';

const KeyItemsHistory = props => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let plantID = props.plantID;
    
    plantID = usePlantIDFromVisitID(props.keyItem?.visitID);
    const visits = useSelector(s => s.Visits.filter(v => v.plantID == plantID && !v.deleted), shallowEqual);
    const visitIDs = visits.map(v => v.id);
    const keyItemsFilter=ki=>{
        if(ki.deleted){return false;}
        if(!visitIDs.includes(ki.visitID))
            return false;
        if (!props.keyItem)
            return true;
        if (ki.entityType !== props.keyItem.entityType)
            return false;
        if (ki.reference !== props.keyItem.reference)
            return false;
        return true;
    };   
    const keyItems = useSelector(state => state.KeyItems.filter(keyItemsFilter), shallowEqual);
    const keyHistItems = keyItems
        .map(ki => {
        const v = visits.find(v => v.id == ki.visitID);
        return { ...ki, ...v };
        })
        .sort((a, b) => {
        const da = new Date(a.startDate);
        const db = new Date(b.startDate);
        return db - da;
    });
    const [selectedRows, setSelectedRows] = useState([]);
    let keyItem = null;
    if (selectedRows.length > 0) {
        keyItem = keyHistItems[selectedRows[0]];
    }
    const columns = [
        { name: "id", label: "ID", options: { display: false } },
        {
            name: "startDate", label: "Visit Date", options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <DateView value={value} />;
                }
            }
        },
        { name:'type', label:'Visit Type'},
        { name: "notes", label: "Notes" }
    ];
    const ClickOpen = () => {
        if (keyItem) {
            navigate(keyItem.url);
        } else {
            alert("Select Key Item From List");
        }
    };
    const rowSelect = (rowsSelectedData, allRows, rowsSelected) => {
        setSelectedRows(rowsSelected);
    };
    const options = {
        filterType: 'checkbox', selectableRows: 'single', selectableRowsOnClick: true, print: false,
        download: false,
        fixedHeader: true,
        responsive: 'standard',
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
            return <CustomSelectToolbar selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                 clickLink={ClickOpen}  />;
        },
        onRowSelectionChange: rowSelect,
        rowsSelected: selectedRows, filter: false, viewColumns:false
    };
    return (
 
                <MUIDataTable
                    title={"Key Items"}
            data={keyHistItems}
                    columns={columns}
                    options={options}
                />
 
    );
}
export default KeyItemsHistory;