import React, { useState,useEffect } from 'react';
import Constants from '../../constants';
import MUIDataTable from "mui-datatables";
import MachineAddDialog from './MachineAddDialog';
import { useSelector, useDispatch } from 'react-redux';
import AddCustomToolbar from "../AddCustomToolbar";
import CustomSelectToolbar from '../CustomSelectToolbar';
import CustomToolbar, { DeleteButton, UpButton, DownButton, EditButton,AddButton } from '../Common/CustomToolbar';
import NoSelect_TableToolbarSelect from '../../misc/NoSelect_TableToolbarSelect';
import KeyItemGridButton from '../KeyItems/KeyItemGridButton';
import { v4 as uuidv4 } from 'uuid';
import {
    AddMachine, UpdateMachine, DeleteMachine, ChangeMachineOrder,
    ReOrderMachines, AddTripleStackVessels
} from '../../actions/MachineActions';
import { MachinesSel,SelMachinesByLineID } from '../../selectors/PlantSelectors';
const MachineSelectGrid = props => {
    const dispatch = useDispatch();
    const [modalOpen, setModal] = useState(false); 
    const lineMachines = useSelector(s=>SelMachinesByLineID(s,props.lineID,props.Filter));
    const machine = lineMachines.find(m => m.id === props.selectedID);
    useEffect(() => {
      dispatch(ReOrderMachines(props.lineID));  
    }, [props.lineID]);
    const columns = [
        { name: "name", label: "Name" }
    ];
    if (!props.NameOnly)
        columns.push({ name: "model", label: "Model" });
    const ClickAdd = () => {
        setModal(true);
    };
    const ClickDelete = () => {
        dispatch(DeleteMachine(machine));
        props.selectOnChange(null);
    };
    const ChangeOrder = (isUp) => {
        dispatch(ChangeMachineOrder(machine, !isUp));
    };
    const rowSelect = (rowsSelectedData, allRows, rowsSelected) => {
        if (rowsSelected.length>0)
            return props.selectOnChange(lineMachines[rowsSelected[0]].id);
       return props.selectOnChange(null);
    };
    const handleMachineAdd = (formVal) => {
        setModal(false);
        formVal.lineID = props.lineID;
        formVal.order = lineMachines.reduce((acc, val) => { return acc + 1 }, 0);
        formVal.id = uuidv4();
        const macType = formVal.type;
        dispatch(AddMachine(formVal));
        

    }
    let selectedRows = [];
    const selInd = lineMachines.reduce((acc, curVal, index, array) => {
        if (acc !== -1)
            return acc;
        if (curVal.id === props.selectedID)
            return index;
        return -1;
    }, -1);
    if (selInd >= 0)
        selectedRows = [selInd];
    let addToolBar = null;
    let selToolBar = (selectedRows, displayData, setSelectedRows)=><></>;
    if (!props.NoEdit) {
        let keyButton = null;
        if (props.visitID) {
            const tM = lineMachines[selInd];
            if (tM) {
                keyButton = <KeyItemGridButton entityType={tM.name}
                    entity={tM} reference={''}
                    visitID={props.visitID} url={'VisitOverview/' + props.visitID} />;
            }
        }
        addToolBar = () => {
            return (
                <AddCustomToolbar onClick={ClickAdd} />
            );
        };
        selToolBar = (selectedRows, displayData, setSelectedRows) => {
            return <CustomToolbar>
                <AddButton onClick={ ClickAdd } />
                <UpButton onClick={() => ChangeOrder(true)} />
                <DownButton onClick={() => ChangeOrder(false)} />
                {keyButton}
                <DeleteButton onClick={ClickDelete} />
            </CustomToolbar>;
        };
    }
    const options = {
        filterType: 'checkbox',
        selectableRows: 'single', responsive:"standard",
        selectableRowsOnClick: true,
        print: false, pagination: false, download: false, searchOpen: false, sort: false,
        sortFilterList: false, viewColumns: false, filter: false, search: false,
        customToolbar: addToolBar,
        customToolbarSelect: selToolBar ,
        onRowSelectionChange: rowSelect,
        rowsSelected: selectedRows,
        textLabels: {
            selectedRows: { text: '' }
        }
    };
    let components = { TableToolbarSelect: NoSelect_TableToolbarSelect }
    if (props.NoToolbars) {
        const customTableHead = props => null;
        components ={
            TableHead: customTableHead, TableToolbar: customTableHead
                , TableToolbarSelect: customTableHead
        }
    }
    return <>
        <MUIDataTable
            title={"Machines"}
            data={lineMachines}
            columns={columns}
            options={options}
            components={components}
        />
        <MachineAddDialog modalOpen={modalOpen} closeModal={() => setModal(false)}
            onSumbit={handleMachineAdd} machines={lineMachines}
        />
    </>;
}
export default MachineSelectGrid;