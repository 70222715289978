import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { DateView } from './MiscViews'
import { UnitView } from '../Formula/UnitViews';
import { StepTypeView } from '../Formula/StepTypeViews';
const EntityInfoDialog = props => {
    let data = [];
    if (props.entityData) {
        const jObj = JSON.parse(props.entityData);
        let row = 0;
        for (const prop in jObj) {
            if (prop.slice(-2).toLowerCase() == 'id') { continue }
            if (prop.slice(-4).toLocaleLowerCase() == 'date') {
                data.push(<tr key={'InfoRow_' + row++}><td>{prop}</td><td><DateView value={jObj[prop]} /></td></tr>);
            } else {
                switch (prop) {
                    case 'stepType':
                        data.push(<tr key={'InfoRow_' + row++}><td>{prop}</td><td><StepTypeView value={jObj[prop]} /></td></tr>);
                        break;
                    case 'unit':
                        data.push(<tr key={'InfoRow_' + row++}><td>{prop}</td><td><UnitView value={jObj[prop]} /></td></tr>);
                        break;
                    default:
                        data.push(<tr key={'InfoRow_' + row++}><td>{prop}</td><td>{jObj[prop]}</td></tr>);
                        break;
                }
            }
            

        }
    } else {
        data.push(<tr key={'InfoRow_0'}><td>No</td><td>Data</td></tr>);
    }
    return (
        <Dialog open={props.modalOpen} onClose={props.modalClose} >

            <DialogTitle >Link Info</DialogTitle >
            <DialogContent>
                <table>
                    <tbody>
                        {data}
                        </tbody>
                </table>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.modalClose}>Close</Button>
            </DialogActions>

        </Dialog>
    );
}
export default EntityInfoDialog;