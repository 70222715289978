import Constants from '../constants';
import React, { useEffect, useContext, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { createSelector } from 'reselect';
import { useOnlineState,useCheckLastSync } from './CommonDataAccess';
import { LoadThermal,GetThermalImagesByParentID } from '../actions/ThermalImageActions';
import { AddSyncTimestamp } from '../actions/LastSyncActions';
import { SelMachinesByLineID,SelMachineVesselsByMachineID } from '../selectors/PlantSelectors';
export const SelThermals=state=>state.Thermals.filter(t=>!t.deleted);
export const SelThermalByID=createSelector(SelThermals,(_,tID)=>tID,
    (thermals,tID)=>{
        return thermals.find(t=>t.id===tID);
    }
    )
export const useThermalData=(tID)=>{
    //Try to Pull Data
    const dispatch = useDispatch();
    const [status,setStatus]=useState({status:'Loading',errorMsg:'Downloading Survey From Database'});
    const netStatus=useOnlineState();
    const reSync=useCheckLastSync(tID);
    useEffect(()=>{
        if(reSync){
            if(netStatus.online && netStatus.loggedIn ){
                //TODO: Check Last Sync
                //We are online time to get the data. 
                const url = Constants.URLs.Thermals + tID;
                fetch(url, { headers: { 'Authorization': 'Bearer ' + netStatus.authToken }})
                .then(resp=>{
                    if(!resp.ok){
                        throw new Error("Could not download data from database Http Status:"+resp.status+" Url:"+url);
                    } 
                    return resp.json()
                })
                .then(result=>{
                    //Update Store
                    dispatch(LoadThermal(result.data));
                    //Pull Child Data
                    dispatch(GetThermalImagesByParentID(tID));
                    dispatch(AddSyncTimestamp(tID,"Thermal"));
                    setStatus({status:"Loaded", errorMsg:"Local DB Updated"});
                })
                .catch(error=>{
                    setStatus({status:'Error', errorMsg:error.message});
                })
                
            } else {
                setStatus({status:'Offline',errorMsg:'Please go online to download Survey Data'});
            }
        } else {
            if(status.status!=="Loaded")
                setStatus({status:"Loaded", errorMsg:"Cached Data"});
        }
    },[tID,JSON.stringify(netStatus)]);
    const data = useSelector(state=>SelThermalByID(state,tID));
    return {data:data,status:status.status,message:status.errorMsg};
}
export const SelThermalImages = state=>state.ThermalImages.filter(ti=>!ti.deleted);
export const SelThermalImagesBySurveyID = createSelector(SelThermalImages,(_,SurveyID)=>SurveyID,
    (ThermalImages,SurveyID)=>{
        return ThermalImages.filter(ti=>ti.thermalImageSurveyID===SurveyID);
    }
);
export const useThermalImages=(surveyID)=>{
    //Assume All Data has been pulled by the parent. 
    return useSelector(s=>SelThermalImagesBySurveyID(s,surveyID));
}
export const useThermalMachines=(lineID)=>{
   return useSelector(s=>SelMachinesByLineID(s,lineID,(m)=>m.type === 1 || m.type === 5 || m.type === 11));
}
export const useVesselsByMachineID=(machineID)=>{
    
    const mvs=useSelector(s=>SelMachineVesselsByMachineID(s,machineID));
    const vessels=useSelector(s=>s.MetaData.Vessels,shallowEqual);
    return mvs.map(mv=>{
        return vessels.find(v=>v.id===mv.vesselID);
    });
}
