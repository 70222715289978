
class PSLogger {
    constructor() {
        this.Actions = [];
        this.Errors =[]
    }

    LogAction(action) {
        const d = new Date();
        this.Actions.push({
            DateTime: d.toJSON(),
            Action:action
        })
        if (this.Actions.length > 200)
            this.Actions.shift();
    }

    LogError(source, error,data) {
        const d = new Date();
        this.Errors.push({
            DateTime: d.toJSON(),
            Source: source,
            Error: error,
            Data: data
        });
        if (this.Errors.length > 200)
            this.Errors.shift();
    }
    ExportLog() {
        return { Actions: this.Actions, Errors: this.Errors };
    }
}
const logger = new PSLogger();
export default logger;

