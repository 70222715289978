import Constants from '../constants';
import produce from "immer";
import { v4 as uuidv4 } from 'uuid';
import { AddFormulaStep, GetPlantFormulaSteps } from './FormulaStepActions';
import { DeleteAttachment } from './AttachmentActions';
import { FetchErrorLogger, FetchCatchHandler } from '../middlewares';
export const GetPlantFormulas = (plantID) => {
    console.log('FormulaActions.js GetPlantFormulas Called');
    return (dispatch, getState) => {
        const state = getState();
        if (state.offline.online) {
            dispatch(GetPlantFormulaSteps(plantID));
            let fetchTask = fetch(Constants.URLs.Formulas + '?PlantID=' + plantID, { headers: { 'Authorization': 'Bearer ' + state.Auth.token } })
                .then(FetchErrorLogger("GetPlantFormulas", plantID, dispatch))
                .then(response => response.json())
                .then(formulae => {
                    console.log("Got Formulas", formulae);
                    dispatch({ type: Constants.Actions.Formula_ReceiveByPlant, data: formulae })
                })
                .catch(FetchCatchHandler("GetPlantFormulas", plantID, dispatch));
        }
    };
};
export const UpdateFormula = (updFormula, curFormula) => {
    const data = { update: updFormula, current: curFormula }
    console.log('put check __________________________________________________________________________  66');
    return {
        type: Constants.Actions.Formula_Update,
        payload: data,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.Formulas + updFormula.id, method: 'PUT', body: JSON.stringify(updFormula),
                    headers: { 'content-type': 'application/json' }
                },
                commit: {
                    type: Constants.Actions.Formula_Update_Commit, meta: { data }
                },
                rollback: {
                    type: Constants.Actions.Formula_Update_Rollback, meta: { data }
                }
            }
        }
    }
};
export const CaptureFormulaSignature = (imageId,formula) => {
    return (dispatch, getState) => {
        const state = getState();
        const setDate = new Date();
        const updFormula = produce(formula, d => {
            d.signatureImageID = imageId;
            d.signatureCaptureDate = setDate.toJSON();
            d.signatureCapturedByName = state.Auth.userData.fullName;
            d.signatureCaputredByID = state.Auth.id;
        });
        dispatch(UpdateFormula(updFormula, formula));
    }
}
export const DeleteFormulaSignature = (formula) => {
    return (dispatch, getState) => {
        const s = getState();
        const attachment = s.Attachments.find(a => a.id === formula.signatureImageID);
        const updFormula = produce(formula, d => {
            d.signatureImageID = Constants.EmptyGuid;
            d.signatureCapturedByName = '';
            d.signatureCaputredByID = Constants.EmptyGuid;
        });
        dispatch(UpdateFormula(updFormula, formula));
        dispatch(DeleteAttachment(attachment));
    }
}
export const AddFormula = (newFormula) => {
    return (dispatch, getState) => {
        newFormula.id = uuidv4();
        const setDate = new Date();
        newFormula.costUpdatedOn = setDate.toJSON();
        dispatch({
            type: Constants.Actions.Formula_Add,
            payload: newFormula,
            meta: {
                offline: {
                    effect: {
                        url: Constants.URLs.Formulas, method: 'POST', body: JSON.stringify(newFormula), headers: { 'content-type': 'application/json' }
                    },
                    commit: {
                        type: Constants.Actions.Formula_Add_Commit, meta: { newFormula }
                    },
                    rollback: {
                        type: Constants.Actions.Formula_Add_Rollback, meta: { newFormula }
                    }
                }
            }
        });
        //StepType: { Water: 0, Starch: 1, Caustic_Percentage: 2, Caustic_Dry: 3, Borax: 4, Borax_Liquid: 5, Starch_Specialty: 6, Liquid_Specialty: 7, Penetrant: 8, Resin: 9, Heat: 10, Error: -1 },
        //FormulaUnit: { lbs: 0, oz: 1, kgs: 2, F: 3, C: 4, gal: 5 },
        let stepNum = 1;
        //Water
        dispatch(AddFormulaStep({
            formulaID: newFormula.id, stepNumber: stepNum++, stepType: 0, lbsPerGallon: 8.34
            , dryPercentage: 0, product: '', value: 0, unit: 0, mixTime: 0, cost: 0
        }));
        //Heat
        dispatch(AddFormulaStep({
            formulaID: newFormula.id, stepNumber: stepNum++, stepType: 10, lbsPerGallon: 0
            , dryPercentage: 0, product: '', value: 0, unit: 3, mixTime: 0, cost: 0
        })); 
        //Finish Water
        dispatch(AddFormulaStep({
            formulaID: newFormula.id, stepNumber: stepNum++, stepType: 0, lbsPerGallon: 8.34
            , dryPercentage: 0, product: 'Finish Water', value: 0, unit: 0, mixTime: 0, cost: 0
        }));
        //Starch
        dispatch(AddFormulaStep({
            formulaID: newFormula.id, stepNumber: stepNum++,
            stepType: 1, lbsPerGallon: 0, dryPercentage: 100, product: '', value: 0, unit: 0, mixTime: 0, cost: 0
        }));
        //Caustic        
        dispatch(AddFormulaStep({
            formulaID: newFormula.id, stepNumber: stepNum++,
            stepType: 2, stepDescription: '', lbsPerGallon: 12.76, dryPercentage: 50, product: '',
            value: 0, unit: 0, mixTime: 0, cost: 0
        }));
        //Borax    
        dispatch(AddFormulaStep({
            formulaID: newFormula.id, stepNumber: stepNum++,
            stepType: 4, stepDescription: '', lbsPerGallon: 0, dryPercentage: 100, product: '', value: 0,
            unit: 0, mixTime: 0, cost: 0
        }));
        //Water
        dispatch(AddFormulaStep({
            formulaID: newFormula.id, stepNumber: stepNum++, stepType: 0, lbsPerGallon: 8.34
            , dryPercentage: 0, product: '', value: 0, unit: 0, mixTime: 0, cost: 0
        }));
        //Heat
        dispatch(AddFormulaStep({
            formulaID: newFormula.id, stepNumber: stepNum++, stepType: 10, lbsPerGallon: 0
            , dryPercentage: 0, product: '', value: 0, unit: 3, mixTime: 0, cost: 0
        }));
        //Finish Water
        dispatch(AddFormulaStep({
            formulaID: newFormula.id, stepNumber: stepNum++, stepType: 0, lbsPerGallon: 8.34
            , dryPercentage: 0, product: 'Finish Water', value: 0, unit: 0, mixTime: 0, cost: 0
        }));
        //Starch
        dispatch(AddFormulaStep({
            formulaID: newFormula.id, stepNumber: stepNum++,
            stepType: 1, lbsPerGallon: 0, dryPercentage: 100, product: '', value: 0, unit: 0, mixTime: 0, cost: 0
        }));
        //Borax    
        dispatch(AddFormulaStep({
            formulaID: newFormula.id, stepNumber: stepNum++,
            stepType: 4, stepDescription: '', lbsPerGallon: 0, dryPercentage: 100, product: '', value: 0,
            unit: 0, mixTime: 0, cost: 0
        }));
        //Liquid Addtivate
        dispatch(AddFormulaStep({
            formulaID: newFormula.id, stepNumber: stepNum++,
            stepType: 7, stepDescription: '', lbsPerGallon: 8.9238, dryPercentage: 0, product: 'TMRT50',
            value: 0, unit: 0, mixTime: 0, cost: 0
        }));    
    }
};
export const CopyFormula = (newFormula,copyFromID) => {
    return (dispatch, getState) => {
        const state = getState();
        newFormula.id = uuidv4();
        const oldFormula = state.Formulas.find(f => f.id == copyFromID);
        newFormula.temperatureTarget = oldFormula.temperatureTarget;
        newFormula.temperatureVariance = oldFormula.temperatureVariance;
        newFormula.viscosityTarget = oldFormula.viscosityTarget;
        newFormula.viscosityVariance = oldFormula.viscosityVariance;
        newFormula.gelPointTarget = oldFormula.gelPointTarget;
        newFormula.gelPointVariance = oldFormula.gelPointVariance;
        const setDate = new Date();
        newFormula.costUpdatedOn = setDate.toJSON();
        dispatch({
            type: Constants.Actions.Formula_Add,
            payload: newFormula,
            meta: {
                offline: {
                    effect: {
                        url: Constants.URLs.Formulas, method: 'POST', body: JSON.stringify(newFormula), headers: { 'content-type': 'application/json' }
                    },
                    commit: {
                        type: Constants.Actions.Formula_Add_Commit, meta: { newFormula }
                    },
                    rollback: {
                        type: Constants.Actions.Formula_Add_Rollback, meta: { newFormula }
                    }
                }
            }
        });
        const steps = state.FormulaSteps.filter(fs => fs.formulaID == copyFromID && !fs.deleted).sort((a, b) => a.stepNumber - b.stepNumber);
        steps.forEach(s => {
            const copyStep = {
                formulaID: newFormula.id, stepNumber: s.stepNumber, stepType: s.stepType, stepDescription: s.stepDescription, lbsPerGallon: s.lbsPerGallon,
                dryPercentage: s.dryPercentage, product: s.product, value: s.value, unit: s.unit, mixTime: s.mixTime, cost: s.cost, other: s.other,
                comment: s.comment, changeID: s.changeID, keyItem: s.keyItem, createdDate: s.createdDate, lastSyncDate: s.lastSyncDate,
                createdByID: s.createdByID, createdBy: s.createdBy, updatedByID: s.updatedByID, updatedBy: s.updatedBy
            }
            dispatch(
                AddFormulaStep(copyStep)
                );
        });
        dispatch(FormulaCalcSummary(newFormula.id));
    }
};
export const FormulaCalcSummary = (formulaID) => {
    return (dispatch, getState) => {
        const state = getState();
        const formula = state.Formulas.find(f => f.id == formulaID);
        if (formula) {
            const updFormula =  FormulaSummary(state, formula);;
            if (updFormula) {
                dispatch(UpdateFormula(updFormula, formula));
            }
        }
    };
};
//fs={cost: 0, createdDate: "DT", dryPercentage: 0, formulaID: "guid" ,id: "guid", lastSyncDate: "DT", lbsPerGallon: 8.33, mixTime: 0, product: ""
//, stepDescription: "Primary Water", stepNumber: 1, stepType: 0, unit: 5, value: 168}
//StepType: { Water: 0, Starch: 1, Caustic_Percentage: 2, Caustic_Dry: 3, Borax: 4, Borax_Liquid: 5, Starch_Specialty: 6, Liquid_Specialty: 7, Penetrant: 8, Resin: 9, Heat: 10, Error: -1 },
//FormulaUnit: { lbs: 0, oz: 1, kgs: 2, F: 3, C: 4, gal: 5 }
const FormulaSummary = (state, formula) => {
    
    const steps = state.FormulaSteps.filter(fs => fs.formulaID == formula.id && !fs.deleted);
    let batchWeight = 0;
    let totalGallons = 0;
    let starchWeight = 0;
    let boraxLbs = 0;
    let causticSolidWeight = 0;
    let totalCost = 0;
    let solidWeight = 0;
    steps.forEach(fs => {
        batchWeight += GetWeight(fs);
        totalCost += GetExtCost(fs);
        solidWeight += GetSolidWeight(fs);
        if (fs.stepType === 0) {
            totalGallons += GetGals(fs);
        }
        else if (fs.stepType == 1 || fs.stepType == 6) {
            starchWeight += GetWeight(fs);
        }
        else if (fs.stepType == 2) {
            totalGallons += GetGals(fs);
            causticSolidWeight += GetSolidWeight(fs);
        }
        else if (fs.stepType == 4 || fs.stepType == 5) {
            boraxLbs += GetWeight(fs);
        }
        else if (fs.stepType == 7) {
            totalGallons += GetGals(fs);
        }
        else if (fs.stepType == 8 || fs.stepType == 9) {
            totalGallons += GetGals(fs);
        }
    });
    let pearlOnly = 0;
    if (batchWeight != 0) {
        pearlOnly = starchWeight / batchWeight * 100;
    }
    const batchVol = batchWeight / 9.1805175;
    totalCost = parseFloat(totalCost.toFixed(3));
    return produce(formula, nf => {
        nf.batchWeight = Math.round(batchWeight);
        nf.dryStarch = parseFloat(starchWeight.toFixed(4));
        nf.pearlOnly = parseFloat(pearlOnly.toFixed(1));
        nf.batchVolume = Math.round(batchVol);
        if (causticSolidWeight != 0) {
            nf.boraxToCaustic = parseFloat((boraxLbs / causticSolidWeight*100).toFixed(2));
        }
        if (batchVol != 0) {
            nf.total_Two = parseFloat((starchWeight / batchVol).toFixed(2));
        }
        nf.totalCost = totalCost;
        if (solidWeight != 0) {
            nf.costDry = parseFloat((totalCost / solidWeight).toFixed(3));
        }
        if (nf.batchVolume != 0) {
            nf.costGal = parseFloat((totalCost / nf.batchVolume).toFixed(3));
        }
        if (batchWeight != 0) {
            nf.percentSolids = parseFloat((solidWeight / batchWeight * 100).toFixed(2));
        }
    });    
};
export const DeleteFormula = (delFormula) => {
    return {
        type: Constants.Actions.Formula_Delete,
        payload: delFormula,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.Formulas + delFormula.id, method: 'DELETE'
                },
                commit: {
                    type: Constants.Actions.Formula_Delete_Commit, meta: { delFormula }
                },
                rollback: {
                    type: Constants.Actions.Formula_Delete_Rollback, meta: { delFormula }
                }
            }
        }
    }
};
const GetWeight = (fs) => {
    let retWeight = 0;
    switch (fs.unit) {
        case 0://lbs
            retWeight= fs.value;
            break;
        case 1://oz
            retWeight = (fs.value / 16);
            break;
        case 2://kgs - Not Supported
            break;
        case 5:
            retWeight = (fs.value * fs.lbsPerGallon);
            break
        default: //case 3,4 -Do nothing
            break;
    }
    return retWeight;
}
const GetGals = (fs) => {
    let retGals = 0;
    switch (fs.unit) {
        case 0://lbs
            if (fs.lbsPerGallon !== 0) {
                retGals += fs.value / fs.lbsPerGallon;
            }
            break;
        case 1://oz -Not Supportedxc
            
            break;
        case 2://kgs - Not Supported
            break;
        case 5:
            retGals = fs.value;
            break
        default: //case 3,4 -Do nothing
            break;
    }
    return retGals;
}
const GetSolidWeight = (fs) => {
    let retWeight = 0;
    switch (fs.unit) {
        case 0://lbs
            retWeight = fs.value;
            break;
        case 1://oz
            retWeight = (fs.value / 16);
            break;
        case 2://kgs - Not Supported
            break;
        case 5:
            retWeight = (fs.value * fs.lbsPerGallon);
            break
        default: //case 3,4 -Do nothing
            break;
    }
    return retWeight * (fs.dryPercentage/100);
}
const GetExtCost = (fs) => {
    return parseFloat((fs.cost * GetWeight(fs)).toFixed(2));
};
export const ClearData = () => {
    return { type: Constants.Actions.Formula_ClearData }
};