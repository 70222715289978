import Constants from '../constants';
import produce from "immer";
import { UpdateArray,UpdateArrayRange } from '../misc/ArrayFuncs';
const constants = Constants;
const initDataState = [];
const ActionItems = (state = initDataState, action) => {
    switch (action.type) {
        case constants.Actions.ActionItems.GetByPlantID:
            return UpdateArrayRange(action.data, state);
        case Constants.Actions.ActionItems.Add:
            return UpdateArray(action.payload, state);
        case Constants.Actions.ActionItems.Add_Rollback:
            const ivId = state.findIndex(iv => iv.id === action.meta.newActionItem.id);
            if (ivId !== -1) {
                return produce(state, pd => { pd.splice(ivId, 1) });
            } else {
                return state;
            }
        case Constants.Actions.ActionItems.Add_Commit:
            return UpdateArray(action.payload?.data, state);
        case Constants.Actions.ActionItem_Update:
            //Action will have the full plant data
            return state.map((p, ind) => {
                if (p.id === action.payload.id) {
                    return produce(p, pd => { pd[action.payload.name] = action.payload.newValue });
                } else { return p; }
            });

        case Constants.Actions.ActionItem_Update_Commit:
            return UpdateArray(action.payload?.data, state);
        case Constants.Actions.ActionItem_Update_Rollback:
            //Action will have the full plant data
            return state.map((p, ind) => {
                if (p.id === action.meta.updatePayload.id) {
                    return produce(p, pd => { pd[action.meta.updatePayload.name] = action.meta.updatePayload.oldValue });
                } else { return p; }
            });
        case Constants.Actions.ActionItems.Delete:
            return UpdateArray(produce(action.payload, d => {
                d.deleted = true;
            }), state);
        case Constants.Actions.ActionItems.Delete_Commit:
            return state;
        case Constants.Actions.ActionItems.Delete_Rollback:
            return UpdateArray(action.meta.delActionItem, state);
        case Constants.Actions.ActionItems.ClearData:
            return initDataState;
        default:
            return state;
    }
}
export default ActionItems;