import { createSelector } from 'reselect';
export const GlueLinesSel = state=>state.GlueLines.filter(gl=>!gl.deleted);
export const GlueLineMachinesSel=state=>state.GlueLineMachines.filter(glm=>!glm.deleted);
export const GetGlueLineByID = createSelector(GlueLinesSel,(_,glID)=>glID,
    (glueLines,glID)=>{
        return glueLines.find(gl=>gl.id===glID);
    }
);
export const GetGLMachinesByGLID = createSelector(GlueLineMachinesSel,(_,glID)=>glID,
    (glMachines,glID)=>{
        return glMachines.filter(glm=>glm.glueLineSurveyID===glID).sort((a, b) => a.order - b.order);
    }
);