import Constants from '../../constants';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
const constants = Constants;
export const useVisitSurveys = (visitID) => {
    const ivs = useSelector(state => state.IngredientVerifications.filter(iv => iv.visitID == visitID && !iv.deleted), shallowEqual);
    const hvs = useSelector(state => state.HeatVessels.filter(hv => hv.visitID == visitID && !hv.deleted), shallowEqual);
    const grs = useSelector(state => state.GlueRolls.filter(gr => gr.visitID == visitID && !gr.deleted), shallowEqual);
    const gls = useSelector(state => state.GlueLines.filter(gl => gl.visitID == visitID && !gl.deleted), shallowEqual);
    const tis = useSelector(state => state.Thermals.filter(s => s.visitID == visitID && !s.deleted), shallowEqual);
    const cls = useSelector(state => state.Checklist.filter(c => c.visitID == visitID && !c.deleted), shallowEqual);
    let surveys = [];
    //Map IngredientVerifications
    surveys = surveys.concat(ivs.map(iv => {
        return {
            surveyType: constants.Surveys.IngredientVerification, id: iv.id,
            createdDate: iv.createdDate, name: iv.name,
            url: '/IngredientVerification/' + iv.id
        };
    }));
    //Map HeatVessels
    surveys = surveys.concat(hvs.map(hv => {
        return {
            surveyType: constants.Surveys.HeatVessel, id: hv.id,
            createdDate: hv.createdDate, name: hv.name,
            url: '/HeatVessel/' + hv.id
        };

    }));
    surveys = surveys.concat(grs.map(gr => {
        let grType=constants.Surveys.GlueRoll_Measurements;
        if(gr.glueRollType===1){
            grType=constants.Surveys.GlueRoll_Pictures;
        }
        return {
            surveyType: grType, id: gr.id,
            createdDate: gr.createdDate, name: gr.name,
            url: '/GlueRoll/' + gr.id
        };

    }));
    surveys = surveys.concat(gls.map(gl => {
        return {
            surveyType: constants.Surveys.GlueLine, id: gl.id,
            createdDate: gl.createdDate, name: gl.name,
            url: '/GlueLine/' + gl.id
        };

    }));
    surveys = surveys.concat(cls.map(cl => {
        return {
            surveyType: constants.Surveys.Checklist,
            id: cl.id,
            createdDate: cl.createdDate, name: cl.name,
            url:'/Checklist/'+cl.id
        }
    }));
    //tis - Thermal Image Surveys
    surveys = surveys.concat(tis.map(ti => {
        return {
            surveyType: constants.Surveys.Thermal,
            id: ti.id,
            createdDate: ti.createdDate, name: ti.name,
            url:'/ThermalImages/'+ti.id
        }
    }));
    return surveys;
};