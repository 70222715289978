import { dispatch } from 'rxjs/internal/observable/pairs';
import Constants from '../constants';
export const enqueueSnackbar = (notification) => {
    const key = notification.options && notification.options.key;
    return {
        type: Constants.Actions.ENQUEUE_SNACKBAR,
        notification: {
            ...notification,
            key: key || new Date().getTime() + Math.random(),
        },
    };
};

export const closeSnackbar = key => ({
    type: Constants.Actions.CLOSE_SNACKBAR,
    dismissAll: !key, // dismiss all if no key has been defined
    key,
});

export const removeSnackbar = key => ({
    type: Constants.Actions.REMOVE_SNACKBAR,
    key,
});

export const clearAllNotifications = () => {
    return { type: Constants.Actions.CLEAR_DATA_SNACKBAR };
};

export const ErrorNotification =(ErrorMsg,Source)=>{
    return (dispatch, getState) => {

        dispatch(enqueueSnackbar({
            message: ErrorMsg,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error'
            }
        }));
    }
};