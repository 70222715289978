import React, { useState } from 'react';
import Constants from '../../constants';
import MachineConfigurationView  from './MachineConfigurationView';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MachineSelectGrid from './MachineSelectGrid';
import Grid from '@mui/material/Grid';

const MachineSetup = props => {
    const [machineID, setMachineID] = useState(null);
    return (
        <Grid container spacing={1}>
            <Grid item sm={12} md={3} >
                <Card>
                    <CardContent>
                        <MachineSelectGrid lineID={props.lineID} selectedID={machineID}
                            selectOnChange={selID => setMachineID(selID)} visitID={ props.visitID } />
                    </CardContent>
                </Card>
            </Grid>
            <Grid item sm={12} md={9}>
                <MachineConfigurationView MachineID={machineID}  />
            </Grid>
        </Grid>
    );
};


export default MachineSetup;