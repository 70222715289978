//Modules
import React, { useRef,useEffect,useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
//MUI
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import PrintIcon from '@mui/icons-material/Print';
//Components
import PrintFooter from './PrintFooter';
import CCI_Logo from "../../images/CCI_Logo.svg"
import './Print.css';
const PrintDialog = props => {
    const componentRef = useRef();
    const oldTitle =useRef(document.title);
    const handlePrint = useReactToPrint({
       content: () => componentRef.current,
       documentTitle:props.title
    });
    
    useEffect(()=>{
        if(props.printOpen && props.title){
            document.title=props.title
        } else {
            document.title=oldTitle.current;
        }
        return ()=>{ document.title=oldTitle.current; }
    }
    ,[props.title,props.printOpen])
    return (
        <Dialog fullScreen open={props.printOpen} onClose={props.onClose} >
            <DialogTitle>    
                <div className={'hide-print'}>
                    <Button
                        variant="contained"
                        startIcon={<CloseIcon />}
                        onClick={() => props.onClose()}>
                        Close
                            </Button>

                    <Button
                        variant="contained"
                        startIcon={<PrintIcon />}
                        onClick={handlePrint}>
                        Print
                    </Button>
                </div>
            </DialogTitle>
            <DialogContent align="center">
                <div  id="printDiv" className='printDiv' ref={componentRef}>
                   <PrintHeader /> 
                    {props.children}
                </div>
            </DialogContent>
        </Dialog>
    );
}
export default PrintDialog;

export const PrintHeader = props=>{
    
    const userData = useSelector(s => s.Auth.userData, shallowEqual);
    const d = new Date();
    return <Grid container style={{width:'100%'}} className={'printHeader'} >
    <Grid item xs={3} style={{textAlign:"left"}}>{ userData.fullName}</Grid>
    <Grid item xs={3} style={{ textAlign: "center" }}>{userData.email}</Grid>
    <Grid item xs={3} style={{ textAlign: "center" }}>{userData.phone}</Grid>
    <Grid item xs={3} style={{ textAlign: "right" }}>Printed: {d.toLocaleDateString()}</Grid>
</Grid>;
}