import Constants from '../constants';
//Modules
import React, { useState,useEffect } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { useParams,useNavigate } from 'react-router-dom';
//MUI
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
//Components
import TabPanel from './TabPanel';
import PersonnelTab from './PlantEdit/PlantEdit_PersonnelTab';
import ProductionTab from './PlantEdit/PlantEdit_ProductionTab';
import CorrugatorLayoutTab from './PlantEdit/PlantEdit_CorrugatorLayoutTab';
import StarchPrepTab from './PlantEdit/PlantEdit_StarchPrepTab';
import PlantItemsTab from './PlantEdit/PlantItemsTab';
import FormulasTab from './PlantEdit/PlantEdit_FormulasTab';
import BaselineTab from './PlantEdit/PlantEdit_BaselineTab';
import { DataForm, DataTextBox } from './FormManager';
//New added
import { GetInternateSpeed } from '../actions/internetSpeed'
//Actions
import { GetPlantData } from '../actions/PlantActions';
//Selectors
const PlantsSel = state=>state.Plants;
const PlantByID =createSelector(PlantsSel,(state,pID)=>pID,
(plants,pID)=>{
    return plants.find(p=>p.id===pID);
}
)
const PlantEditView = (props)=> {

    // const speed = GetInternateSpeed();

    // console.log("check 111111111111111111111", speed);

    const dispatch = useDispatch();
    // dispatch(SetOffine()); //new added
    const navigate = useNavigate();
    const [active,setActive]=useState(0);
    const params = useParams();
    const PlantData = useSelector(s=>PlantByID(s,params.PlantID));
    useEffect(()=>{
        if(params.PlantID){
            dispatch(GetPlantData(params.PlantID, 'plantEdit')); 
        }
    },[params.PlantID]);
  
    const switchTab=(event, newValue)=> {
        if (active !== newValue) {
            setActive( newValue );
        }
    }
    const backClick=()=> {
        navigate(-1);
    }
    return (
        <DataForm actionType={'UpdatePlant'} data={PlantData}>
            <Grid container>
                <Grid item xs={3}>
                    <DataTextBox name={'name'} label={'Plant Name'} />
                </Grid>
                <Grid item  xs={3}>
                    <DataTextBox name={'company'} label={'Company'} />
                </Grid>
                <Grid item  xs={3}>
                    <DataTextBox name={'location'} label={'Region'} />
                </Grid>
                <Grid item  xs={3} align="right" style={{ paddingTop:"5px" }}>
                    <Button variant="contained" startIcon={<CloseIcon />} onClick={backClick}>
                        Close
                    </Button>
                </Grid>
            </Grid>
            <div style={{ flexGrow: 1, width: '100%', backgroundColor:'white' }}>
            <AppBar position="static">
                <Tabs
                    value={active}
                    onChange={switchTab}
                    indicatorColor="primary"
                    textColor="primary"
                    style={{backgroundColor:'white'} }
                    variant="scrollable"
                    scrollButtons
                    aria-label="Plant Edit Tabs"
                    allowScrollButtonsMobile>
                    <Tab label="Visits/Actions/KeyItems" />
                    
                    <Tab label="Personnel" />
                    <Tab label="Production"  />
                    <Tab label="Corrugator Layout"  />
                    <Tab label="Starch Preparation System"  />
                    <Tab label="Formulas" />
                    <Tab label="Baseline" />
                </Tabs>
            </AppBar>
            <TabPanel value={active} index={0}>
                    <PlantItemsTab PlantData={PlantData} />
            </TabPanel>
            <TabPanel value={active} index={1}>
                <PersonnelTab PlantData={PlantData} />
            </TabPanel>
            <TabPanel value={active} index={2}>
                <ProductionTab  plantID={params.PlantID} />
            </TabPanel>
            <TabPanel value={active} index={3}>
                <CorrugatorLayoutTab  plantID={params.PlantID} />
            </TabPanel>
            <TabPanel value={active} index={4}>
                <StarchPrepTab  plantID={params.PlantID} />
            </TabPanel>
            <TabPanel value={active} index={5}>
                <FormulasTab  plantID={params.PlantID} />
            </TabPanel>
            <TabPanel value={active} index={6}>
                <BaselineTab  plantID={params.PlantID} />
            </TabPanel>
            </div>
        </DataForm>
    );
    
}

export default PlantEditView;