import Constants from '../../constants';
import React, { useState } from 'react';
import  {useNavigate, useParams } from 'react-router-dom';
//MUI
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import { GetHeatVesselByID,GetBoilers, GetHVTempsByHVID } from '../../selectors/HeatVesselSelectors';
import { KeyItemsFilteredByArray } from '../../selectors/KeyItemSelectors';
import { useReportUser,usePlantDataFromVisitID, useVisitData, useReportName } from '../../misc/UseFuncs';
import { MachinesFilteredByArray,MachinesAddFlutesString } from '../../selectors/PlantSelectors';
import ReportViewer from '../Report/ReportViewer';
const constants=Constants;

const HeatVesselPrint = props => {
    const params=useParams();
    const navigate=useNavigate();
    const hvID = params.SurveyID;
    
    const handleClose = () => {
        navigate(-1);
    }
    const printData=useHeatVeseelPrintData(hvID);
   return <ReportViewer type="HeatVessel" name="HeatVessel" printData={printData} 
   onClose={handleClose}  />;
}
export default HeatVesselPrint;
export const useHeatVeseelPrintData=(hvID)=>{
    const heatVessel=useSelector(s=>GetHeatVesselByID(s,hvID));
    const boilers=useSelector(s=>GetBoilers(s,heatVessel?.lineID));
    const HVTemps=useSelector(s=>GetHVTempsByHVID(s,hvID));
    let machines=useSelector(s=>MachinesFilteredByArray(s,HVTemps));
    machines=useSelector(s=>MachinesAddFlutesString(s,machines));
    const UserData = useReportUser();
    const plant = usePlantDataFromVisitID(heatVessel?.visitID);
    const visit = useVisitData(heatVessel?.visitID);
    const kiFilter=[...HVTemps,heatVessel];
    const keyItems = useSelector(s=>KeyItemsFilteredByArray(s,kiFilter));
    const dJSON ={HeatVessel:heatVessel, User:UserData, Plant:plant, Visit:visit, HVTemps:HVTemps,Boilers:boilers,KeyItems:keyItems,Machines:machines};
    const Data ={ HeatVesselData:JSON.stringify(dJSON)};
    const reportName=useReportName("HeatVessel",plant?.name,visit);
    let visitDate=null;
    if(visit){
        const d=new Date(visit.startDate);
        visitDate=+(d.getMonth()+1).toString().padStart(2,"0")+"/"+d.getDate().toString().padStart(2,"0")+"/"+d.getFullYear();
    }
    return {Loaded:true,Data:Data,ReportName:reportName,EmailSubject:'Heat Vessel '+plant?.name+' '+visitDate};
};
