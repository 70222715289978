import React from 'react'
import Constants from '../../constants';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import { NativeSelectFormik } from '../FormikUI/NativeSelectFormik';
export const GetMachineTypeName = value => {
    let retVal = Object.keys(Constants.Enums.MachineType).find(key => Constants.Enums.MachineType[key] === value);
    if (retVal == 'Cut_Off_Knife') { retVal = "Cut Off Knife"; }
    if (retVal == 'Slitter_Scorer') { retVal = 'Slitter Scorer'; }
    if (retVal == "Triple_Stack") { retVal = "Triple Stack";}
    return retVal;
}
export const MachineTypeName = props => {
    const MachineStr = GetMachineTypeName(props.value);
    return <>{MachineStr}</>;
};
export const GetMeasurementTypeName = value => {
    let retVal = Object.keys(Constants.Enums.MeasurementType).find(key => Constants.Enums.MeasurementType[key] === value);
    if (retVal == 'GelTemp') { retVal = "Gel Temp"; }
    return retVal;
}
export const MeasurementTypeName = props => {
    const typeStr = GetMeasurementTypeName(props.value);
    return <>{typeStr}</>;
};
export const MachineTypeSelectFormik = props => {
    const options = Object.keys(Constants.Enums.MachineType).filter(k => k != 'Error').map(key =>
        <option
            key={key}
            value={Constants.Enums.MachineType[key]}
        >
            {key}
        </option>
    );
    //options.unshift(<option key={'-1'} value={-1}>Select Flute</option>);
    return (
        <NativeSelectFormik name={props.name} options={options} label={props.label} number />
    );
};
export const MachineTypeSelect = props => {
    const options = Object.keys(Constants.Enums.MachineType).filter(k=>k!='Error').map(key =>
        <option
            key={key}
            value={Constants.Enums.MachineType[key]}
        >
            {key}
        </option>
    );
    options.unshift(<option key={'-1'} value={-1}>Select Type</option>);
    return (
        <FormControl error={props.error}>
            <InputLabel htmlFor="name-native-error">{props.label}</InputLabel>
            <NativeSelect
                name={props.name}
                value={props.value}
                onChange={props.onChange}
            >
                {options}
            </NativeSelect>
            <FormHelperText>{props.error}</FormHelperText>
        </FormControl>
    );
};
