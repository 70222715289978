import React, { useState, useEffect } from 'react'
import Constants from '../../constants';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormulaEdit from './FormulaEditView';
export const EditFormulaButton = props => {
    const [modalOpen, setOpen] = useState(false);
    return <>
        <Button onClick={() => setOpen(true)}>Edit Formula</Button>
        <Dialog open={modalOpen} onClose={() => setOpen(false)} fullScreen={true} >
            <DialogContent>
                <FormulaEdit formulaID={props.formulaID} />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>Close</Button>
            </DialogActions>
        </Dialog>
    </>;
}