import Constants from '../constants';
import { v4 as uuidv4 } from 'uuid';
import { FetchErrorLogger, FetchCatchHandler } from '../middlewares';
export const GetPlantMachineFlutes = (plantID) => {
    return (dispatch, getState) => {
        //offline.online
        const state = getState();
        const lineIDs = state.Lines.filter(l => l.plantID === plantID  && !l.deleted).map(l => l.id);
        const machineIDs = state.Machines.filter(m => lineIDs.filter(i => i == m.lineID && !m.deleted).length > 0).map(m=>m.id);
        if (state.offline.online) {
            let fetchTask = fetch(Constants.URLs.MachineFlute + '?plantID=' + plantID, { headers: { 'Authorization': 'Bearer ' + state.Auth.token } })
                .then(FetchErrorLogger("GetPlantMachineFlutes", plantID, dispatch))
                .then(response => response.json())
                .then(mfs => {
                    dispatch({ type: Constants.Actions.MachineFlutes_ReceiveByPlant, data: mfs, plantID: plantID, machineIDs: machineIDs });
                })
                .catch(FetchCatchHandler("GetPlantMachineFlutes", plantID, dispatch))
        }
    }
}
export const AddMachineFlute = (machineId, flute,order) => {
    const newMachineFlute = { id: uuidv4(), machineID:machineId, flute: flute,order:order };
    return {
        type: Constants.Actions.MachineFlute_Add,
        payload: newMachineFlute,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.MachineFlute, method: 'POST', body: JSON.stringify(newMachineFlute), headers: { 'content-type': 'application/json' }
                },
                commit: {
                    type: Constants.Actions.MachineFlute_Add_Commit, meta: { newMachineFlute }
                },
                rollback: {
                    type: Constants.Actions.MachineFlute_Add_Rollback, meta: { newMachineFlute }
                }
            }
        }
    }
};

export const DeleteMachineFlute = (delMachineFlute) => {
    return {
        type: Constants.Actions.MachineFlute_Delete,
        payload: delMachineFlute,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.MachineFlute + delMachineFlute.id, method: 'DELETE'
                },
                commit: {
                    type: Constants.Actions.MachineFlute_Delete_Commit, meta: { delMachineFlute }
                },
                rollback: {
                    type: Constants.Actions.MachineFlute_Delete_Rollback, meta: { delMachineFlute }
                }
            }
        }
    }
};
export const ChangeMachineFluteOrder = (machineFlute, isUp) => {
    const changeData = { id: machineFlute.id, isUp: isUp }
    return {
        type: Constants.Actions.MachineFlute_ChangeOrder,
        payload: changeData,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.MachineFlute + changeData.id + "/ChangeOrder/" + isUp, method: 'GET'
                },
                commit: {
                    type: Constants.Actions.MachineFlute_ChangeOrder_Commit, meta: { changeData }
                },
                rollback: {
                    type: Constants.Actions.MachineFlute_ChangeOrder_Rollback, meta: { changeData }
                }
            }
        }
    };
};
export const ClearData = () => { return { type: Constants.Actions.MachineFlute_ClearData }; };