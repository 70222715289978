import React, { useState } from 'react';
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import Webcam from 'react-webcam'
import 'react-html5-camera-photo/build/css/index.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import AutorenewIcon from '@mui/icons-material/Autorenew';
const CameraDialog = props => {
    const [facingMode, setFacingMode] = useState("environment" )
    const webcamRef = React.useRef(null);
    const videoConstraints = {
        facingMode: facingMode
    };
    const capture = React.useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();
            props.handleTakePhoto(imageSrc);
        },
        [webcamRef]
    );
    return (
        <Dialog open={props.modalOpen} onClose={props.closeModal}  >
            <DialogContent>
                <Webcam
                    audio={false}
                    
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                   
                    videoConstraints={videoConstraints}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"

                    startIcon={<AutorenewIcon />}
                    onClick={() => {
                        if (facingMode == "environment")
                            setFacingMode("user");
                        else
                            setFacingMode("environment");
                    }}
                > Switch
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<PhotoCameraIcon />}
                    onClick={capture}
                >Capture
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<CloseIcon />}
                    onClick={props.closeModal}
                >Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CameraDialog;