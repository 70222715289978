import Constants from '../constants';
import { v4 as uuidv4 } from 'uuid';
import { FetchErrorLogger, FetchCatchHandler } from '../middlewares';
import { GetPlantFormulas } from "./FormulaActions";
import { AddIngredientVerification, GetIngredientVerificationByVisitID } from "./IngredientVerificationActions";
import { AddNewHeatVessel, GetHeatVesselsByVisitID } from './HeatVesselActions';
import { AddNewGlueRoll, GetGlueRollsByVisitID } from './GlueRollActions';
import { AddNewGlueLine, GetGlueLinesByVisitID } from './GlueLineActions';
import { AddNewStarch, GetSKItemsByVisitID, GetStarchsByVisitID } from './StarchKitchenActions';
import { CreateBHSChecklist, GetChecklistsByVisitID } from './ChecklistActions';
import { GetKeyItemByVisitID } from "./KeyItemActions";
import { AddSyncTimestamp, CheckReSync } from './LastSyncActions';
import { GetPlantData } from './PlantActions';
import { GetThermalsByVisitID } from './ThermalImageActions';
import { GetBaselinesByPlantID } from './BaselineActions';
const constants=Constants;
export const GetVisits = () => {
    console.log('DataActions.js GetVisits Called');
    return (dispatch, getState) => {
        console.log('DataActions.js GetVisits Thunk Called');
        const state = getState();
        if (state.offline.online) {
            //No Data Get Data
            let fetchTask = fetch(Constants.URLs.Visits, { headers: { 'Authorization': 'Bearer ' + state.Auth.token } })
                .then(FetchErrorLogger("GetVisits", null, dispatch))
                .then(response => response.json())
                .then(visits => {
                    dispatch({ type: Constants.Actions.ReceiveVisits, data: visits })
                })
                .catch(FetchCatchHandler("GetVisits", null, dispatch));
        }
    };
};
export const GetVisitsByPlantID = (PlantID) => {
    return (dispatch, getState) => {
        const state = getState();
        if (state.offline.online) {
            //No Data Get Data
            let fetchTask = fetch(Constants.URLs.Visits + '?PlantID=' + PlantID, { headers: { 'Authorization': 'Bearer ' + state.Auth.token } })
                .then(FetchErrorLogger("GetVisitsByPlantID", PlantID, dispatch))
                .then(response => {
                   return response.json()
                })
                .then(visits => {
                    dispatch({ type: Constants.Actions.Visits_GetByPlantID, data: visits, plantID:PlantID })
                })
                .catch(FetchCatchHandler("GetVisitsByPlantID", PlantID, dispatch));
        }
    };
};
export const AddVisit = (newVisit) => {
    newVisit.id = uuidv4();
    return {
        type: Constants.Actions.AddVisit
        , payload: newVisit
        , meta: {
            offline: {// the network action to execute:
                effect: {
                    url: Constants.URLs.Visits, method: 'POST', body: JSON.stringify(newVisit), headers: { 'content-type': 'application/json' }
                },
                // action to dispatch when effect succeeds:
                commit: {
                    type: Constants.Actions.AddVisit_Commit, meta: { newVisit }
                },
                // action to dispatch if network action fails permanently:
                rollback: {
                    type: Constants.Actions.AddVisit_Rollback, meta: { newVisit }
                }
            }
        }
    };
};
export const ClearData = () => { return { type: Constants.Actions.Visits_ClearData }; };
//Get All Visit Data 
export const GetAllVisitData =(visit)=>{
    return (dispatch, getState) => {
        if(!visit || !visit.id || visit.id==constants.EmptyGuid) {return;}
        const state = getState();
        if (state.offline.online && CheckReSync(visit.id, state.LastSync, 3600000)) {
            ForceGetAllVisitData(dispatch,visit);
            dispatch(AddSyncTimestamp(visit.id,'Visit'));
        }    
    }
};
export const ForceGetAllVisitData =(dispatch,visit)=>{
    dispatch(GetPlantFormulas(visit.plantID));
    dispatch(GetKeyItemByVisitID(visit.id));
    dispatch(GetIngredientVerificationByVisitID(visit.id));
    dispatch(GetHeatVesselsByVisitID(visit.id));
    dispatch(GetGlueRollsByVisitID(visit.id));
    dispatch(GetGlueLinesByVisitID(visit.id));
    dispatch(GetChecklistsByVisitID(visit.id));
    dispatch(GetPlantData(visit.plantID));
    dispatch(GetBaselinesByPlantID(visit.plantID));
    dispatch(GetSKItemsByVisitID(visit.id));
    dispatch(GetThermalsByVisitID(visit.id));
}