import Constants from '../constants';
import produce from "immer";
import { UpdateArray, UpdateArrayRange } from '../misc/ArrayFuncs';

const initDataState = [];
const ChecklistGroup = (state = initDataState, action) => {
    
    switch (action.type) {
        case Constants.Actions.ChecklistGroup.Add:
            return UpdateArray(action.payload, state);
        case Constants.Actions.ChecklistGroup.Add_Rollback:
            const clgID = state.findIndex(iv => iv.id === action.meta.newCLG.id);
            if (clgID !== -1) {
                return produce(state, pd => { pd.splice(clgID, 1) });
            } else {
                return state;
            }
        case Constants.Actions.ChecklistGroup.Add_Commit:
            return UpdateArray(action.payload?.data, state);
        case Constants.Actions.ChecklistGroup.ClearData:
            return initDataState;
        case Constants.Actions.ChecklistGroup.Update:
            return UpdateArray(action.payload.update, state);
        case Constants.Actions.ChecklistGroup.Update_Commit:
            return UpdateArray(action.payload?.data, state);
        case Constants.Actions.ChecklistGroup.Update_Rollback:
            return UpdateArray(action.meta.data.current, state);
        case Constants.Actions.ChecklistGroup.GetByParentID:
            return UpdateArrayRange(action.data,state);
        default:
            return state;
    }
}
export default ChecklistGroup;