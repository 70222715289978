import Constants from '../constants';
import produce from "immer";
import { UpdateArray, UpdateArrayRange } from '../misc/ArrayFuncs';
import { IVStepToLbs } from '../misc/FormulaFuncs';
const roundToTwo=(num)=> {
    return +(Math.round(num + "e+2") + "e-2");
}
const initDataState = [];
const IngredientVerificationSteps = (state = initDataState, action) => {
    switch (action.type) {
        case Constants.Actions.IngredientVerificationStep_Add:
            return UpdateArray(action.payload, state);
        case Constants.Actions.IngredientVerificationStep_Add_Rollback:
            const ivId = state.findIndex(iv => iv.id === action.meta.newIVStep.id);
            if (ivId !== -1) {
                return produce(state, pd => { pd.splice(ivId, 1) });
            } else {
                return state;
            }
        case Constants.Actions.IngredientVerificationStep_Add_Commit:
            return UpdateArray(action.payload?.data, state);
        case Constants.Actions.IngredientVerificationStep_Clear_LocalSteps:
            return state.filter(s => s.starchIngredientVerificationID !== action.ivID);
        case Constants.Actions.IngredientVerificationStep_ClearData:
            return initDataState;
        case Constants.Actions.IngredientVerificationStep_Update:
            return UpdateArray(action.payload.update, state);
        case Constants.Actions.IngredientVerificationStep_Update_Commit:
            //return UpdateArray(action.payload?.data, state);
            return state;
        case Constants.Actions.IngredientVerificationStep_Update_Rollback:
            return UpdateArray(action.meta.data.current, state);
        case Constants.Actions.IngredientVerificationStep_ReCalc_AccumulatedWeight:
            const steps = state
                .filter((v, ind, arr) => v.starchIngredientVerificationID === action.ingredientVerificationID && !v.deleted)
                .sort((a, b) => a.stepLocation - b.stepLocation);
            console.log("Start Weight", action.startWeight);
            let accumWeight = action.startWeight;
            steps.forEach((step,ind,array) => {
                accumWeight += IVStepToLbs(step);
                console.log("Step Acum Weight", accumWeight, step.acutalWeight);
                array[ind] = produce(array[ind], d => { d.accumulatedWeight = roundToTwo(accumWeight)});
            });
            return UpdateArrayRange(steps, state);
        case Constants.Actions.IngredientVerificationStep_GetByParentID:
            return UpdateArrayRange(action.data, state);
        default:
            return state;
    }
}
export default IngredientVerificationSteps;