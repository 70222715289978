import React, { useState } from 'react';
import Constants from '../../constants';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useNavigate,useParams } from 'react-router-dom';
import { DeleteActionItem } from '../../actions/ActionItemActions';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { DataForm, DataTextBox, DataNumber, DataDate, DataImageAttachment, DataSelect } from '../FormManager';
import ActionItemLinksGrid from './ActionItemLinksGrid';
import { useLineOptions, useMachineOptions, usePersonnalOptions } from '../MetaData/PlantOptions';
import { PlantLink } from '../MetaData/Links';
import { ConfirmAction } from '../../actions/ConfirmActionActions';
const ActionStatusOptions = [
    <option key={'as_type_1'} value={0}>Open</option>,
    <option key={'as_type_2'} value={2}>WIP</option>,
    <option key={'as_type_3'} value={3}>Cancelled</option>,
    <option key={'as_type_4'} value={1}>Closed</option>
];
const ActionItemEditView = props => {
    const params=useParams();
    const aiID = params.ActionItemID;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const ActionItem = useSelector(state => state.ActionItems.find(a => a.id === aiID), shallowEqual);
    const lineOptions = useLineOptions(ActionItem ? ActionItem.plantID : null, false
        , !(ActionItem && ActionItem.lineID && ActionItem.lineID !== Constants.EmptyGuid)
        ,true
    );
    const machineOptions = useMachineOptions(ActionItem ? ActionItem.lineID : null,
        !(ActionItem && ActionItem.machineID && ActionItem.machineID !== Constants.EmptyGuid)
    );
    const personnelOptions = usePersonnalOptions(ActionItem ? ActionItem.plantID : null,
        !(ActionItem && ActionItem.championID && ActionItem.championID !== Constants.EmptyGuid),
        true
    );
    const handleDel = () => {
        dispatch(ConfirmAction("Do you want to delete this Action Item?", DeleteActionItem(ActionItem), null,
            (confirmed) => {
                if (confirmed) {
                    navigate(-1);
                }
        }));
        
    }
    const handleClose = () => {
        navigate(-1);
    }
    
    if (ActionItem) {
        let machineOpts = null;
        if (ActionItem.lineID && ActionItem.lineID !== Constants.EmptyGuid && ActionItem.lineID !== '11111111-1111-1111-1111-111111111111') {
            machineOpts = <DataSelect name={'machineID'} label={'Machine'} options={machineOptions} />;
        }
        console.log("ActionItem Edit Render");
        return (
            <DataForm actionType={'ActionItem_Update'} data={ActionItem}>
                <Grid container spacing={2} justifyContent="space-between"  >
                    <Grid item xs={12} sm={4} ><PlantLink plantID={ ActionItem.plantID } /></Grid>
                    <Grid item xs={6} sm={4} align="right">
                    <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<DeleteIcon />}
                            onClick={handleDel}
                        >Delete
                        </Button>
                    </Grid>
                    <Grid item xs={6} sm={4} align="right">
                        <Button variant="contained" startIcon={<CloseIcon />} onClick={handleClose}>
                            Close
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <DataTextBox name={'entityType'} label={'Type'} fullWidth TextFieldProps={{ InputProps:{ readOnly: true }}} />
                    </Grid>
                    <Grid item xs={6}>
                        <DataTextBox name={'reference'} label={'Refenence'} fullWidth TextFieldProps={{ InputProps:{ readOnly: true }}} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <div><DataTextBox name={'description'} label={'Description'} fullWidth multiline /></div>
                        <div><DataTextBox name={'actionNeeded'} label={'ActionNeeded'} fullWidth multiline /></div>
                        <div><DataTextBox name={'benefit'} label={'Benefit'} fullWidth multiline /></div>
                    </Grid>
                    
                    <Grid item xs={12} md={4}>
                        <div><DataSelect name={'status'} label={'Status'} options={ActionStatusOptions} /></div>
                        <div><DataDate name={'startDate'} label={'Start Date'} /></div>
                        <div><DataDate name={'targetDate'} label={'Target Date'} /></div>
                    </Grid>               
                    <Grid item xs={12} md={4}>
                        <div><DataNumber name={'percentCompleted'} label={'Percent Completed'} type={'int'} /></div>
                        <div><DataSelect name={'championID'} label={'Champion'} options={personnelOptions} /></div >
                        <div><DataSelect name={'lineID'} label={'Line'} options={lineOptions} />
                            {machineOpts}</div >
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <DataImageAttachment name={'imageID'} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <ActionItemLinksGrid actionItemID={ActionItem.id} />
                    </Grid >
                    
                </Grid>
            </DataForm>
        );
    } else {
        return <div>Unable to Load Action Item</div>;
    }
};
export default ActionItemEditView;