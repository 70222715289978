//Modules
import React from "react";
//MUI
import AddIcon from '@mui/icons-material/Add';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from '@mui/icons-material/Delete';
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import EditIcon from '@mui/icons-material/Edit';
import PreviewTwoTone from '@mui/icons-material/PreviewTwoTone';

import SearchIcon from '@mui/icons-material/Search';
import LinkIcon from '@mui/icons-material/Link';
import BlockIcon from "@mui/icons-material/Block";
import withStyles from '@mui/styles/withStyles';

const defaultToolbarSelectStyles = {
    iconButton: {
    },
    iconContainer: {
        marginRight: "24px",
        width:'100%'
    }
};

class CustomSelectToolbar extends React.Component {
    //TODO Redo this as a hooks instead of big if string have it just render the children
    render() {
        const { classes } = this.props;
        let addIcon = null;
        let deleteIcon = null;
        let upIcon = null;
        let downIcon = null;
        let editIcon = null;
        let infoIcon = null;
        let linkIcon = null;
        let keyIcon = null;
        let previewTwoTone = null;
        if (this.props.clickAdd) {
            addIcon = <Tooltip title={"Add Item"}>
                <IconButton onClick={this.props.clickAdd}>
                    <AddIcon />
                </IconButton>
            </Tooltip>;
        }
        if (this.props.clickDelete) {

            deleteIcon = (<Tooltip title={"Delete"}>
                <IconButton
                    className={classes.iconButton}
                    onClick={this.props.clickDelete}
                    size="large">
                    <DeleteIcon className={classes.icon} />
                </IconButton>
            </Tooltip>);
        }
        if (this.props.changeOrder) {
            upIcon = (<Tooltip title={"Up"}>
                <IconButton
                    className={classes.iconButton}
                    onClick={() => this.props.changeOrder(true) }
                    size="large">
                    <ArrowUpwardIcon className={classes.icon} />
                </IconButton>
            </Tooltip>);
            downIcon = (<Tooltip title={"Down"}>
                <IconButton
                    className={classes.iconButton}
                    onClick={() => this.props.changeOrder(false)}
                    size="large">
                    <ArrowDownwardIcon className={classes.icon} />
                </IconButton>
            </Tooltip>);
        }
        if (this.props.clickEdit) {
            editIcon = (
            <Tooltip title={"Edit"}>
                <IconButton
                    className={classes.iconButton}
                    onClick={this.props.clickEdit}
                    size="large">
                    <EditIcon className={classes.icon} />
                </IconButton>
            </Tooltip>
            );
        }
        if (this.props.clickInfo) {
            infoIcon = (
                <Tooltip title={"Info"}>
                    <IconButton
                        className={classes.iconButton}
                        onClick={this.props.clickInfo}
                        size="large">
                        <SearchIcon className={classes.icon} />
                    </IconButton>
                </Tooltip>
            );
        }
        if (this.props.clickLink) {
            linkIcon = (
                <Tooltip title={"Link"}>
                    <IconButton
                        className={classes.iconButton}
                        onClick={this.props.clickLink}
                        size="large">
                        <LinkIcon className={classes.icon} />
                    </IconButton>
                </Tooltip>
            );
        }
        if (this.props.view) {
            previewTwoTone = (
                <Tooltip title={"View"}>
                    <IconButton
                        className={classes.iconButton}
                        onClick={this.props.view}
                        size="large">
                        <PreviewTwoTone className={classes.icon} />
                    </IconButton>
                </Tooltip>
            );
        }
        return (
            <div className={classes.iconContainer}>
                {infoIcon}
                {linkIcon}
                {addIcon}
                {upIcon}
                {downIcon}
                {editIcon}      
                {deleteIcon}
                {previewTwoTone}
            </div>
        );
    }
}

export default withStyles(defaultToolbarSelectStyles, { name: "CustomSelectToolbar" })(CustomSelectToolbar);