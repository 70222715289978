import React, { Component } from 'react';
import VisitsGridView from '../Visit/VisitsGridView';
class VisitsTab extends Component {
    constructor(props) {
        super(props);
    }
    render() {       
        let plantID = null;
        if (this.props.PlantData) {
            plantID = this.props.PlantData.id;
        }
        return <VisitsGridView plantID={plantID} />;
    }
}
export default VisitsTab;