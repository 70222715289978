
//Modules
import React from "react";
//MUI
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from '@mui/icons-material/Add';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from '@mui/icons-material/Delete';
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import LinkIcon from '@mui/icons-material/Link';

const useStyles = makeStyles((theme) => ({
    iconButton: {
    },
    iconContainer: {
        marginRight: "24px",
        width: '100%'
    }
}));
const CustomToolbar = props => {
    const classes = useStyles();
    return <div className={classes.iconContainer}>{props.children}</div>;
};
export default CustomToolbar;

export const AddButton = props => {
    const classes = useStyles();
    return <Tooltip title={"Add Item"}>
        <IconButton onClick={props.onClick}>
            <AddIcon />
        </IconButton>
    </Tooltip>;
};
export const DeleteButton = props => {
    const classes = useStyles();
    return <Tooltip title={"Delete"}>
        <IconButton
            className={classes.iconButton}
            onClick={props.onClick}
            size="large">
            <DeleteIcon className={classes.icon} />
        </IconButton>
    </Tooltip>;
};
export const UpButton = props => {
    const classes = useStyles();
    return <Tooltip title={"Up"}>
        <IconButton
            className={classes.iconButton}
            onClick={props.onClick}
            size="large">
            <ArrowUpwardIcon className={classes.icon} />
        </IconButton>
    </Tooltip>;
}
export const DownButton = props => {
    const classes = useStyles();
    return <Tooltip title={"Down"}>
        <IconButton
            className={classes.iconButton}
            onClick={props.onClick}
            size="large">
            <ArrowDownwardIcon className={classes.icon} />
        </IconButton>
    </Tooltip>;
};
export const EditButton = props => {
    const classes = useStyles();
    return <Tooltip title={"Edit"}>
        <IconButton
            className={classes.iconButton}
            onClick={props.onClick}
            size="large">
            <EditIcon className={classes.icon} />
        </IconButton>
    </Tooltip>;
};
export const InfoButton = props => {
    const classes = useStyles();
    return <Tooltip title={"Info"}>
        <IconButton
            className={classes.iconButton}
            onClick={props.onClick}
            size="large">
            <SearchIcon className={classes.icon} />
        </IconButton>
    </Tooltip>;
};
export const LinkButton = props => {
    const classes = useStyles();
    return <Tooltip title={"Link"}>
        <IconButton
            className={classes.iconButton}
            onClick={props.onClick}
            size="large">
            <LinkIcon className={classes.icon} />
        </IconButton>
    </Tooltip>;
};
