import Constants from '../constants';
import produce from "immer";
import { UpdateArray, ChangeOrder, UpdateArrayRange } from '../misc/ArrayFuncs';
const initDataState = [];
const MachineVessels = (state = initDataState, action) => {
    switch (action.type) {
        case Constants.Actions.MachineVessels_ReceiveByPlant:
            return UpdateArrayRange(action.data, state);
        case Constants.Actions.MachineVessels_ReceiveByMachine:
            return UpdateArrayRange(action.data, state);
        //Add
        case Constants.Actions.MachineVessel_Add:
            return [...state, action.payload];
        case Constants.Actions.MachineVessel_Add_Rollback:
            const machineInd = state.findIndex(machine => machine.id === action.meta.newMachineVessel.id);
            if (machineInd !== -1) {
                return produce(state, pd => { pd.splice(machineInd, 1) });
            } else {
                return state;
            }
        case Constants.Actions.MachineVessel_Add_Commit:
            return UpdateArray(action.payload?.data, state);
        //Delete
        case Constants.Actions.MachineVessel_Delete:
            return UpdateArray(produce(action.payload, d => {
                d.deleted = true;
            }), state);
        case Constants.Actions.MachineVessel_Delete_Commit:
            return state;
        case Constants.Actions.MachineVessel_Delete_Rollback:
            return UpdateArray(action.meta.delMachineVessel, state);
        case Constants.Actions.MachineVessel_ChangeOrder:
            return ChangeOrder(action.payload.id, state, action.payload.isUp, (a, b) => a.machineID == b.machineID);
        case Constants.Actions.MachineVessel_ChangeOrder_Commit:
            return state;
        case Constants.Actions.MachineVessel_ChangeOrder_Rollback:
            let isUp = false;
            if (action.meta.changeData.isUp)
                isUp = true;
            return ChangeOrder(action.meta.changeData.id, state, isUp, (a, b) => a.machineID == b.machineID);
        case Constants.Actions.MachineVessel_ReOrder:
            let newOrder = 0
            const changed = state
                .filter(mv => mv.machineID == action.payload && !mv.deleted)
                .sort((a, b) => a.order - b.order)
                .reduce((acc,mv) => {
                    if (mv.order != newOrder) {
                        acc.push(produce(mv, d => { d.order = newOrder }));
                    }
                    newOrder++;
                    return acc;
                },[]);

            return UpdateArrayRange(changed, state);
        //MachineVessel_ReOrder Commit no change and Rollback impossible to reverse
        case Constants.Actions.MachineVessel_ClearData:
            return [];
        default:
            return state;
    };
}
export default MachineVessels;