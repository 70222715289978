import React, { useEffect, useContext,useState } from 'react';
import { TextField, CheckboxWithLabel } from 'formik-mui';
import { Form, Formik,Field } from 'formik';
import Button from '@mui/material/Button';
const EmailForm=({initVals,onSubmit,onCancel})=>{
    const validate=(vals)=>{
        const errors={};
        return errors;
    }
    return  <Formik initialValues={initVals} onSubmit={onSubmit} validate={validate} >
    <Form>
     <div>
                    <Field  fullWidth
                        component={TextField}
                        name="To"
                        type="text"
                        label="To"
                    />
                </div>
                <div>
                    <Field  fullWidth
                        component={TextField}
                        name="Cc"
                        type="text"
                        label="Cc"
                    />
                </div>
                <div>
                    <Field  fullWidth
                        component={TextField}
                        name="Subject"
                        type="text"
                        label="Subject"
                    />
                </div>
                <div> 
                    <Field  fullWidth
                        component={TextField}
                        name="Body"
                        type="text"
                        label="Body"
                        InputProps={{
                            multiline: true,
                            rows: 3,

                        }}
                    />
                </div>
      
    
        <Button onClick={onCancel}>Cancel</Button>
        <Button type="submit">Send Email</Button>
    
</Form>
</Formik>;
}
export default EmailForm