import Constants from '../../constants';
import React, { useEffect, useContext, useState } from 'react';
import LineSelection from './LineSelection';
import { LineIDLink } from '../MetaData/MachineMiscViews';
const constants = Constants;
const LineSingleSelect = props => {
 
    if (props.lineID && props.lineID !== Constants.EmptyGuid && props.lineID !== '11111111-1111-1111-1111-111111111111') {
        return <LineIDLink lineID={props.lineID} TextFieldProps={props.TextFieldProps} />;
    }
    return <LineSelection onLineChange={props.onLineChange} plantID={props.plantID} TextFieldProps={props.TextFieldProps} />;
    
};
export default LineSingleSelect;