import Constants from '../constants';
import { v4 as uuidv4 } from 'uuid';
import { FetchErrorLogger, FetchCatchHandler } from '../middlewares';

const constants = Constants;
export const CreateChecklistGroup = (newCLG) => {
    return {
        type: Constants.Actions.ChecklistGroup.Add,
        payload: newCLG,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.ChecklistGroup, method: 'POST', body: JSON.stringify(newCLG), headers: { 'content-type': 'application/json' }
                },
                commit: {
                    type: Constants.Actions.ChecklistGroup.Add_Commit, meta: { newCLG }
                },
                rollback: {
                    type: Constants.Actions.ChecklistGroup.Add_Rollback, meta: { newCLG }
                }
            }
        }
    }
};
export const CreateChecklistItem = (checkListID,groupID,name,order) => {
    const newCLI = {
        id: uuidv4(),
        checklistID: checkListID,
        groupID: groupID,
        name: name,
        order:order
    }
    return {
        type: Constants.Actions.ChecklistItem.Add,
        payload: newCLI,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.ChecklistItem, method: 'POST', body: JSON.stringify(newCLI), headers: { 'content-type': 'application/json' }
                },
                commit: {
                    type: Constants.Actions.ChecklistItem.Add_Commit, meta: { newCLI }
                },
                rollback: {
                    type: Constants.Actions.ChecklistItem.Add_Rollback, meta: { newCLI }
                }
            }
        }
    }
}
export const UpdateChecklistItem = (current,updItem) => {
    console.log('put check __________________________________________________________________________  22');
    return {
        type: Constants.Actions.ChecklistItem.Update,
        payload: updItem,
        meta: {
            offline: {
                effect: {
                    url:constants.URLs.ChecklistItem + updItem.id, method: 'PUT', body: JSON.stringify(updItem), headers: { 'content-type': 'application/json' }
                },
                commit: {
                    type: constants.Actions.ChecklistItem.Update_Commit, meta: { updItem }
                },
                rollback: {
                    type: constants.Actions.ChecklistItem.Update_Rollback, meta: { current,updItem }
                }
            }
        }
    }
};
export const CreateBHSChecklist = (id, visitID) => {
    return (dispatch, getState) => {
        //Create Header
        const d = new Date();
        const newCL = {};
        newCL.id = id;
        newCL.visitID = visitID;
        newCL.date = new Date().toJSON();
        newCL.listType = 'BHS';
        newCL.name = 'BHS WCS Checklist - ' + d.toLocaleDateString();
        dispatch({
            type: Constants.Actions.Checklist.Add,
            payload: newCL,
            meta: {
                offline: {
                    effect: {
                        url: Constants.URLs.Checklist, method: 'POST', body: JSON.stringify(newCL), headers: { 'content-type': 'application/json' }
                    },
                    commit: {
                        type: Constants.Actions.Checklist.Add_Commit, meta: { newCL }
                    },
                    rollback: {
                        type: Constants.Actions.Checklist.Add_Rollback, meta: { newCL }
                    }
                }
            }
        });
        //Create Groups

        const g1 = { checklistID: newCL.id, id: uuidv4(), name: '1. DOWNLOAD WSC DATA – FILTER AND VERIFY THE FOLLOWING SETTINGS FOR ACCURACY', order: 1 }
        dispatch(CreateChecklistGroup(g1));
        const g2 = { checklistID: newCL.id, id: uuidv4(), name: '2. WET END', order: 2 }
        dispatch(CreateChecklistGroup(g2));
        const g3 = { checklistID: newCL.id, id: uuidv4(), name: '3. WCS CONTROL SYSTEM', order: 3 }
        dispatch(CreateChecklistGroup(g3));
        const g4 = { checklistID: newCL.id, id: uuidv4(), name: '4. MANAGEMENT OF WCS', order: 4 }
        dispatch(CreateChecklistGroup(g4));
        //Create Items
        let tc = 0;
        dispatch(CreateChecklistItem(newCL.id, g1.id, 'Double facer glue roll by lagging- By Flute board grade (what are they?)', ++tc))
        dispatch(CreateChecklistItem(newCL.id, g1.id, 'Double facer and module facer glue gaps – Light, Minimum and Heavy weight grades.', ++tc))
        dispatch(CreateChecklistItem(newCL.id, g1.id, 'Paper temperature targets- Hot Plate Exit, Bottom Liner, Medium, Liner at SF Which board grades are too high and out of the WCS Set Point Limits', ++tc))
        dispatch(CreateChecklistItem(newCL.id, g1.id, 'Ensure all paper grades have the proper Glue Program ID – Light, Medium, Heavy and Double wall.', ++tc))
        dispatch(CreateChecklistItem(newCL.id, g1.id, 'Settings of Shoetrol should be in Temperature - 1 light, 2 medium, 3 heavy or 1,2,3,4,5 – identify Process used of shoetrol to board grades.', ++tc))
        dispatch(CreateChecklistItem(newCL.id, g1.id, 'Speed Control table is set up by 1 light, 2 medium, 3 heavy or 1,2,3,4,5 – identify Process used of shoetrol to board grades.', ++tc))
        dispatch(CreateChecklistItem(newCL.id, g1.id, 'Steam Shower % by board grades – check to see if enough steam is used on heavier weight papers. >20% Medium 15% Light 5-10%', ++tc))
        dispatch(CreateChecklistItem(newCL.id, g1.id, 'Tension Roll settings Light weight 1-5% Medium 5-15% Heavy 15-25%', ++tc))

        dispatch(CreateChecklistItem(newCL.id, g2.id, 'Are the temperature sensors Clean?', ++tc))
        dispatch(CreateChecklistItem(newCL.id, g2.id, 'Verify accuracy of all temperature sensors compared to actual paper temperature', ++tc))
        dispatch(CreateChecklistItem(newCL.id, g2.id, 'Verify wrap arm positions for position accuracy. 0%, 50%, 100% to all stations', ++tc))
        dispatch(CreateChecklistItem(newCL.id, g2.id, 'Review glue gap curves for module facers and double facer glue unit for accuracy; NOTE - glue gap curves should end approximately 200 feet per minute before the top machine speed of that particular board grade.', ++tc))
        dispatch(CreateChecklistItem(newCL.id, g2.id, 'Audit Steam Zone targets to hot plate sections to ensure that they are within targets.  (Check pressure gauges and computer screens- compare the two- are they slow to react?', ++tc));
        dispatch(CreateChecklistItem(newCL.id, g2.id, 'Verify that steam shower at module facers are opening and closing with adjustment settings as well as with speed changes - Steam is increased as speed of machine is increased.', ++tc));
        dispatch(CreateChecklistItem(newCL.id, g2.id, 'Review splicer tension and check splicer alarm history for critical alarms – report critical alarms to maintenance ', ++tc));
        dispatch(CreateChecklistItem(newCL.id, g2.id, 'Monitor Module facer and double backer glue gaps are following Glue Gap Curves properly when machine speed - verify with soaks and tear test at different speeds.', ++tc));
       
        dispatch(CreateChecklistItem(newCL.id, g3.id, 'Are all stations run in Auto and in Temperature mode?', ++tc));
        dispatch(CreateChecklistItem(newCL.id, g3.id, 'In Plants with POP and COPAR audit speed settings and compare to actual running', ++tc));
        dispatch(CreateChecklistItem(newCL.id, g3.id, 'Audit Operator process in adjusting settings - Do they use the slider bar, are they adjusting temperatures, tensions, steam etc.? - (If settings are within the WCS upper and lower limits there should be minimal changes and the slider bar is preferred method of adjusting as the system learns the adjusted settings.)', ++tc));
        dispatch(CreateChecklistItem(newCL.id, g3.id, 'Audit paper wrap positions at Triple Stack.  Having 100% wrap on both bottom liner and Web may indicate the targets to both are too high.  Lower temperature targets to both in Saved settings.  By doing this you have room to adjust for both Up and Down warp with a desired temperature and < 50% wrap to web and Liner.', ++tc));
        dispatch(CreateChecklistItem(newCL.id, g3.id, 'Verify that running paper grade has the proper Glue Program ID at Glue Unit– (Glue Gap Curve)- Are Glue gap curves set by grade of Light, Medium or Heavy?- Compare to history.', ++tc));
        dispatch(CreateChecklistItem(newCL.id, g3.id, 'Review running order history and compare with saved data – question discrepancies by viewing previously ran orders and saved settings.', ++tc));
        
        dispatch(CreateChecklistItem(newCL.id, g4.id, 'Review audit of system with Champion - if there is none or not there- review personally with Supervisor and Plant Manager. -  No Champion?... then recommend a champion.', ++tc));
        dispatch(CreateChecklistItem(newCL.id, g4.id, 'Identify and coordinate corrective actions with team.', ++tc));
    }
};
export const GetChecklistsByVisitID = (visitID) => {
    return (dispatch, getState) => {
        //offline.online
        const state = getState();
        if (state.offline.online) {
            let fetchTask = fetch(Constants.URLs.Checklist + "?visitID=" + visitID
                , { headers: { 'Authorization': 'Bearer ' + state.Auth.token } })
                .then(FetchErrorLogger("GetChecklistsByVisitID", visitID, dispatch))
                .then(response => response.json())
                .then(hvs => {
                    //Ignore 404 errors
                    if (Array.isArray(hvs)) {
                        dispatch({ type: Constants.Actions.Checklist.GetByVisitID, data: hvs, visitID: visitID });
                        hvs.forEach(cl=>{
                            dispatch(GetChecklistGroupsByParentID(cl.id));
                            dispatch(GetChecklistItemsByParentID(cl.id));
                        })
                    }
                })
                .catch(FetchCatchHandler("GetChecklistsByVisitID", visitID, dispatch))
        }
    }
}
export const GetChecklistGroupsByParentID = (parentID) => {
    return (dispatch, getState) => {
        const state = getState();
        if (state.offline.online) {
            let fetchTask = fetch(constants.URLs.ChecklistGroup + "?ChecklistID=" + parentID
                , { headers: { 'Authorization': 'Bearer ' + state.Auth.token } }            )
                .then(FetchErrorLogger("GetChecklistGroupsByParentID", parentID, dispatch))
                .then(response => response.json())
                .then(clgs => {
                    //Ignore 404 errors
                    if (Array.isArray(clgs)) {
                        dispatch({ type: Constants.Actions.ChecklistGroup.GetByParentID, data: clgs, parentID: parentID });
                    }
                })
                .catch(FetchCatchHandler("GetChecklistGroupsByParentID", parentID, dispatch))
        }
    }
};
export const GetChecklistItemsByParentID = (parentID) => {
    return (dispatch, getState) => {
        const state = getState();
        if (state.offline.online) {
            let fetchTask = fetch(constants.URLs.ChecklistItem + "?ChecklistID=" + parentID
                , { headers: { 'Authorization': 'Bearer ' + state.Auth.token } }            )
                .then(FetchErrorLogger("GetChecklistItemsByParentID", parentID, dispatch))
                .then(response => response.json())
                .then(clis => {
                    //Ignore 404 errors
                    if (Array.isArray(clis)) {
                        dispatch({ type: Constants.Actions.ChecklistItem.GetByParentID, data: clis, parentID: parentID });
                    }
                })
                .catch(FetchCatchHandler("GetChecklistItemsByParentID", parentID, dispatch))
        }
    }
}
export const DeleteChecklist = (delCL) => {
    return {
        type: Constants.Actions.Checklist.Delete,
        payload: delCL,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.Checklist + delCL.id, method: 'DELETE'
                },
                commit: {
                    type: Constants.Actions.Checklist.Delete_Commit, meta: { delCL }
                },
                rollback: {
                    type: Constants.Actions.Checklist.Delete_Rollback, meta: { delCL }
                }
            }
        }
    }
};
export const DeleteChecklistByID=(delCLID)=>{
    return (dispatch,getState)=>{
        const delCL=getState().Checklist.find(cl=>cl.id===delCLID);
        dispatch(DeleteChecklist(delCL));
    }
}
export const LoadChecklist = (data) => {
    return (dispatch, getState) => {
        dispatch({ type: constants.Actions.Checklist.GetByID, data: data });
        dispatch(GetChecklistGroupsByParentID(data.id));
        dispatch(GetChecklistItemsByParentID(data.id));
    }
};
export const UpdateItem = (item) => {

}

export const ClearAllCheckListData=()=>{
    return (dispatch,getState)=>{
        //Checklist,ChecklistGroup,ChecklistItem
        dispatch({type:constants.Actions.Checklist.ClearData});
        dispatch({type:constants.Actions.ChecklistGroup.ClearData});
        dispatch({type:constants.Actions.ChecklistItem.ClearData});
    }
}