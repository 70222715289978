import Constants from '../../constants';
import React, { useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { DataForm, DataTextBox, DataDate, DataSelect, DataNumber, DataFormContext,DataImageAttachmentButton, DataCheckbox } from '../FormManager';
import LineSingleSelect from '../Common/LineSingleSelect';
import WallTypeSelect from './WallTypeSelect';
import MachineSelect from './MachineSelect';
import { KeyItemCheckBox } from '../KeyItems/KeyItemCheckBox';
// import { SelKeyItemByEntity } from '../../selectors/KeyItemSelectors';
import { GetGlueLineMachinesByParentID } from '../../actions/GlueLineActions';
import { UpdateAction } from '../../actions/DataActions';
import { SelMachinesByLineID } from '../../selectors/PlantSelectors';
const constants = Constants;
const GlueLineMachinesView = props => {
    const dispatch = useDispatch();
    const handleMachineChange = (glMachine,type,newMachineID,oldMachineID) => {
        let updName = "sfMachineID";
        if (type === 4)
            updName = "guMachineID";
        //Check for Existing Use
        const existing = glMachines.find(glm => glm[updName] === newMachineID);
        if (existing)
            dispatch(UpdateAction(constants.Actions.GlueLineMachine, updName, existing, oldMachineID));
        dispatch(UpdateAction(constants.Actions.GlueLineMachine, updName, glMachine, newMachineID));
    };
    const glMacViews = [];
    const glMachines = useSelector(s => s.GlueLineMachines.filter(glm => glm.glueLineSurveyID == props.glueLine.id && !glm.deleted), shallowEqual);
    const machines = useSelector(s=>SelMachinesByLineID(s,props.glueLine.lineID,(m)=>m.type === 1 || m.type === 4));
    
    glMachines.forEach(glm =>
        glMacViews.push(<GLMachineView glMachine={glm} onMachineChange={handleMachineChange}
            machines={machines} visitID={props.glueLine.visitID} glueLine={props.glueLine}  printMode={props.printMode} />)
    );
    useEffect(() => {
        dispatch(GetGlueLineMachinesByParentID(props.glueLine.id));
    }
        , [props.glueLine.id])
    let wallSelect = null;    
    if (props.glueLine.lineID && props.glueLine.lineID != constants.EmptyGuid) {
        wallSelect = <WallTypeSelect wall={props.glueLine.wall} onWallChange={props.onWallChange} lineID={props.glueLine.lineID} TextFieldProps={{ size: 'small' }} />;
    }
    
    return <Grid container>
        <Grid item xs={6} >
            <LineSingleSelect plantID={props.plantID} onLineChange={props.onLineChange} lineID={props.glueLine.lineID} TextFieldProps={{ size: 'small' }} />
        </Grid>
        <Grid item xs={6} >{wallSelect}</Grid>
        <Grid item xs={12}>
            { glMacViews }
        </Grid>
        </Grid>;
};
const GLMachineView = props => {
    const defaultProps = {
        bgcolor: 'background.paper',
        m: 1,
        borderColor: 'text.primary',
    };
    // const keyItem = useSelector(state=>SelKeyItemByEntity(state,props.glMachine.id,props.glMachine.sfMachineName));
    //  const handleMachineChange = (glMachine,type,newMachineID,oldMachineID) => {
    //     let updName = "sfMachineID";
    //     if (type === 4)
    //         updName = "guMachineID";
    //     //Check for Existing Use
    //     const existing = props.machines.find(glm => glm.id === newMachineID);
    //    console.log('=++++++++++  keyItem  +++++++++++++', keyItem);
    //    console.log('=++++++++++  existing  +++++++++++++', existing);
    //    console.log('=+++++++++++++++++++++++', glMachine);
    //    console.log('=++++++++++++++++++++++ props.machines+', props.machines);
    //    console.log('=++++++++++++++++++++++newMachineID+', newMachineID);
    //    console.log('=+++++++++++++++++++++++', oldMachineID);
    //     props.onMachineChange(glMachine,type,newMachineID,oldMachineID)

    //     // const keyItem = useSelector(state=>SelKeyItemByEntity(state,props.entity.id,props.field));
    //     // dispatch(UpdateKeyItem(formVal, keyItem));
    // };
    return (
        <DataForm actionType={constants.Actions.GlueLineMachine} data={props.glMachine}>
            <Box border={1} borderRadius="16px" {...defaultProps} style={{pageBreakInside:'avoid'}}>
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <Grid container >
                            <Grid item xs={12} >
                                <Typography variant="h6">Glue Line Measurement</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <MachineSelect machines={props.machines} type={1}
                                    onMachineChange={props.onMachineChange} glMachine={props.glMachine} />
                                    {/* onMachineChange={handleMachineChange} glMachine={props.glMachine} /> */}
                            </Grid>
                            
                            <Grid item xs={4}><DataNumber name={'corrGlue'} label={'Glue Gap'}  TextFieldProps={{ size: 'small' }} /></Grid>
                            <Grid item xs={4}><DataNumber name={'glueMet'} label={'Glue Lag'} TextFieldProps={{ size: 'small' }} /></Grid>
                            <Grid item xs={4}><DataCheckbox name={'softTouch'} label={"Soft Touch"} /> </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={11}>
                                <KeyItemCheckBox entity={props.glMachine} visitID={props.visitID}
                                    setReference={(entity) => entity.sfMachineName } field={props.glMachine.sfMachineName}
                                    entityType={constants.Surveys.GlueLine} edit={true} tabIndex="-1"/> 
                                    <DataImageAttachmentButton name='sfImageID' /><DataImageAttachmentButton name='sfSecondImageID' />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Grid container alignItems="center" justifyItems="center" >
                            <Grid item xs={3}></Grid>
                            <Grid item xs={3} >
                                <Typography variant="h6" align="center">Op</Typography>
                            </Grid>
                            <Grid item xs={3}><Typography variant="h6" align="center">Center</Typography></Grid>
                            <Grid item xs={3}><Typography variant="h6" align="center">Drive</Typography></Grid>

                            <Grid item xs={3}  >Liner</Grid>
                            <Grid item xs={3}><DataNumber name={'sfLinerOperator'} label={''} TextFieldProps={{ size: 'small' }} /></Grid>
                            <Grid item xs={3}><DataNumber name={'sfLinerCenter'} label={''} TextFieldProps={{ size: 'small' }} /></Grid>
                            <Grid item xs={3}><DataNumber name={'sfLinerDrive'} label={''} TextFieldProps={{ size: 'small' }} /></Grid>

                            <Grid item xs={3}>Medium</Grid>
                            <Grid item xs={3}><DataNumber name={'sfMediumOperator'} label={''} TextFieldProps={{ size: 'small' }} /></Grid>
                            <Grid item xs={3}><DataNumber name={'sfMediumCenter'} label={''} TextFieldProps={{ size: 'small' }} /></Grid>
                            <Grid item xs={3}><DataNumber name={'sfMediumDrive'} label={''} TextFieldProps={{ size: 'small' }} /></Grid>
                        </Grid>
                    </Grid>
                                  
                <Grid item xs={12}>
                    <hr />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid container>
                        <Grid item xs={12}>
                            <MachineSelect machines={props.machines} type={4}
                                onMachineChange={props.onMachineChange} glMachine={props.glMachine} />
                        </Grid>
                        <Grid item xs={4}>
                            <DataNumber name={'glueGap'} label={'Glue Gap'} TextFieldProps={{ size: 'small' }} />
                            
                        </Grid>
                        <Grid item xs={4}>
                            <DataNumber name={'glueLag'} label={'Glue Lag'} TextFieldProps={{ size: 'small' }} />
                        </Grid>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={11}>
                            <KeyItemCheckBox entity={props.glMachine} visitID={props.visitID}
                                    setReference={(entity) => entity.guMachineName} field={props.glMachine.guMachineName}
                                    entityType={constants.Surveys.GlueLine} edit={true} tabIndex="-1"/> 
                            <DataImageAttachmentButton name='imageID' /><DataImageAttachmentButton name='secondImageID' />
                        </Grid>
                        
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                        <Grid container alignItems="center">
                        <Grid item xs={3}></Grid>
                            <Grid item xs={3}><Typography variant="h6" align="center">Op</Typography></Grid>
                            <Grid item xs={3}><Typography variant="h6" align="center">Center</Typography></Grid>
                            <Grid item xs={3}><Typography variant="h6" align="center">Drive</Typography></Grid>
                            <Grid item xs={3}>Medium</Grid>
                            <Grid item xs={3}><DataNumber name={'guMediumOperator'} label={''} TextFieldProps={{ size: 'small' }} /></Grid>
                            <Grid item xs={3}><DataNumber name={'guMediumCenter'} label={''} TextFieldProps={{ size: 'small' }} /></Grid>
                            <Grid item xs={3}><DataNumber name={'guMediumDrive'} label={''} TextFieldProps={{ size: 'small' }} /></Grid>
                    <Grid item xs={3}>Liner</Grid>
                    <Grid item xs={3}><DataNumber name={'guLinerOperator'} label={''} TextFieldProps={{ size: 'small' }} /></Grid>
                    <Grid item xs={3}><DataNumber name={'guLinerCenter'} label={''} TextFieldProps={{ size: 'small' }} /></Grid>
                    <Grid item xs={3}><DataNumber name={'guLinerDrive'} label={''} TextFieldProps={{ size: 'small' }} /></Grid>        
                    </Grid>
                    </Grid>
                </Grid>
            </Box>
        </DataForm>
    );
};

export default GlueLineMachinesView;