import { useFormikContext } from 'formik';
import { WaterProducts, BoraxProducts, LiquidAdditiveProducts, SpecialtyStarchProducts, SpecialtyLiquidsProducts, CausticProducts } from './FormulaProducts'
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import Select from '@mui/material/Select';
import produce from "immer";
import React from 'react'
//StepType: { Water: 0, Starch: 1, Caustic_Percentage: 2, Caustic_Dry: 3, Borax: 4, Borax_Liquid: 5, Starch_Specialty: 6, Liquid_Specialty: 7, Penetrant: 8, Resin: 9, Heat: 10, Error: -1 },
export const StepProductDropdown = props => {
    const { values, errors, setValues } = useFormikContext();
    const onChange = (e) => {
        const selID = Number(e.target.value);
        const sel = GetProductSelection(values['stepType'], selID);
        if (sel) {
            setValues(produce(values, nv => {
                nv.dryPercentage = sel.percentSolid;
                nv.lbsPerGallon = sel.lbsPerGal;
                nv.product = sel.product;
            }));
        } else {
            setValues(produce(values, nv => {
                nv.dryPercentage = 100;
                nv.lbsPerGallon = 0;
                nv.product = '';
            }));
        }
    };
    let retVal = null;
    if (values['stepType'] >= 0 && values['stepType'] !== 999 && values['stepType'] !== 11 && values['stepType'] !== 1
        && values['stepType'] !== 10) {
        const options = [];
        options.push(<option key={-1} value={-1}>Select Product</option>);
        const parray = GetProductArray(values['stepType']);
        parray.forEach(p => options.push(<option key={p.id} value={p.id}>{p.product}</option>));
        options.push(<option key={999} value={999}>Other</option>);
        retVal = <FormControl error={errors['product']} variant="outlined">
            <InputLabel htmlFor="fs_Product_dropdown">Type</InputLabel>
            <Select
                native
                label="Type"
                id={ 'fs_Product_dropdown'}
                name={'product'}
                value={GetProductIdFromStr(values['stepType'],values['product'])}
                onChange={onChange}
            >
                {options}
            </Select>
            <FormHelperText>{errors['product']}</FormHelperText>
        </FormControl>
    }
    return retVal;
};
const GetProductSelection = (stepType, selValue) => {
    
    const pArray = GetProductArray(stepType);
    let oPercSolid = 0;
    let oLbsGal = 8.34; 
    switch (stepType){
        case 0:
            oPercSolid = 0;
            oLbsGal = 8.34;
            break;
        case 2:
            oPercSolid = 50;
            oLbsGal = 12.76;
            break;
        case 4:
            oPercSolid = 100;
            oLbsGal = 8.34;
            break;
        case 6:
            oPercSolid = 100;
            oLbsGal = 0;
            break;
        case 7: case 8:
            oPercSolid = 0;
            oLbsGal = 8.9238;
            break;
    }
    if (selValue === 999) {
        return { id: 999, product: 'Other', percentSolid: oPercSolid, lbsPerGal: oLbsGal }
    } else {
        return pArray.find(p => p.id === selValue);
    }
};
const GetProductIdFromStr = (stepType, productStr) => {
    const parray = GetProductArray(stepType);
    const pVal = parray.find(p => p.product == productStr);
    let retVal = -1
    if (pVal) { retVal = pVal.id; }
    if (productStr == 'Other') { retVal = 999;}
    return retVal;
}
const GetProductArray = stepType => {
    switch (stepType) {
        case 0:
            return WaterProducts;
        case 2:
            return CausticProducts;
        case 4:
            return BoraxProducts;
        case 6:
            return SpecialtyStarchProducts;
        case 7:
            return LiquidAdditiveProducts;
        case 8:
            return SpecialtyLiquidsProducts;
        default:
            return [];
    }
};