import Constants from '../../constants';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { DataForm, DataTextBox, DataDate, DataSelect, DataNumber, DataFormContext } from '../FormManager';
const useStyles = makeStyles((theme) => ({
    gapTable: {
        border: "1px solid black",
        width: "425px",
        "& td": { border: "1px solid black"}
    },
}));
const GlueRoll_GapView = props => {
    const classes = useStyles();
    return <DataFormContext.Consumer>
        {({ actionType, data }) => (
            <table className={classes.gapTable}>
                <thead>
                    <tr>
                        <td colspan={4} align="center">
                            GAP
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td></td>
                        <td>O.S</td>
                        <td>C</td>
                        <td>D.S.</td>
                    </tr>
                    <tr>
                        <td>1st Glue Roll Gap</td>
                        <td>{data.gapOperator}</td>
                        <td>{data.gapCenter}</td>
                        <td>{data.gapDrive}</td>
                    </tr>
                    <tr>
                        <td>2st Glue Roll Gap</td>
                        <td>{data.gap2Operator}</td>
                        <td>{data.gap2Center}</td>
                        <td>{data.gap2Drive}</td>
                    </tr>
                    <tr>
                        <td>1st ReadOut</td>
                        <td><DataNumber name={'readoutOperator'} label={''}
                            InputProps={{ shrink: true, style: { width: '75px' } }} TextFieldProps={{ size: 'small' }} />
                        </td>
                        <td><DataNumber name={'readoutCenter'} label={''}
                            InputProps={{ shrink: true, style: { width: '75px' } }} TextFieldProps={{ size: 'small' }} /></td>
                        <td><DataNumber name={'readoutDrive'} label={''}
                            InputProps={{ shrink: true, style: { width: '75px' } }} TextFieldProps={{ size: 'small' }} /></td>
                    </tr>
                    <tr>
                        <td>2nd ReadOut</td>
                        <td><DataNumber name={'readout2Operator'} label={''}
                            InputProps={{ shrink: true, style: { width: '75px' } }} TextFieldProps={{ size: 'small' }} /></td>
                        <td><DataNumber name={'readout2Center'} label={''}
                            InputProps={{ shrink: true, style: { width: '75px' } }} TextFieldProps={{ size: 'small' }} /></td>
                        <td><DataNumber name={'readout2Drive'} label={''}
                            InputProps={{ shrink: true, style: { width: '75px' } }} TextFieldProps={{ size: 'small' }} /></td>
                    </tr>
                </tbody>
            </table>)}
    </DataFormContext.Consumer>;
}
export default GlueRoll_GapView;