import Constants from '../constants';
import { v4 as uuidv4 } from 'uuid';
import { FetchErrorLogger } from '../middlewares';
export const GetPlantPersonnel = (plantID) => {
    console.log('PersonnelActions.js GetPlantPersonnel Called', plantID);
    return (dispatch, getState) => {
        //offline.online
        if (getState().offline.online) {
            let fetchTask = fetch('api/Personnel?plantID=' + plantID)
                .then(FetchErrorLogger("GetPlantPersonnel", plantID, dispatch))
                .then(response => response.json())
                .then(plantPersonnel => {
                    console.log("Got Plant Personnel", plantPersonnel);
                    dispatch({ type: Constants.Actions.Personnel_ReceiveByPlant, data: plantPersonnel, plantID:plantID });
                })
                .catch(error => { console.error('GetPlantPersonnel API Error', error); })
        }
    }
}
export const AddPersonnel = (newPerson) => {
    newPerson.id = uuidv4();
    return {
        type: Constants.Actions.AddPersonnel,
        payload: newPerson,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.AddPersonnel, method: 'POST', body: JSON.stringify(newPerson), headers: { 'content-type': 'application/json' }
                },
                commit: {
                    type: Constants.Actions.AddPersonnel_Commit, meta: { newPerson }
                },
                rollback: {
                    type: Constants.Actions.AddPersonnel_Rollback, meta: { newPerson }
                }
            }
        }
    }
};
export const UpdatePersonnel = (updPerson, curPerson) => {
    const data = { update: updPerson, current: curPerson }
    console.log('put check __________________________________________________________________________  25');
    return {
        type: Constants.Actions.UpdatePersonnel,
        payload: data,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.UpdatePersonnel + updPerson.id, method: 'PUT', body: JSON.stringify(updPerson), headers: { 'content-type': 'application/json' }
                },
                commit: {
                    type: Constants.Actions.UpdatePersonnel_Commit, meta: { data }
                },
                rollback: {
                    type: Constants.Actions.UpdatePersonnel_Rollback, meta: { data }
                }
            }
        }
    }
};
export const DeletePerson = (delPerson) => {
    return {
        type: Constants.Actions.Personnel_Delete,
        payload: delPerson,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.Personnel_Delete + delPerson.id, method: 'DELETE'
                },
                commit: {
                    type: Constants.Actions.Personnel_Delete_Commit, meta: { delPerson }
                },
                rollback: {
                    type: Constants.Actions.Personnel_Delete_Rollback, meta: { delPerson }
                }
            }
        }
    }
};
export const ClearData = () => { return { type: Constants.Actions.Personnel_ClearData }; };