import { KeyOutlined } from "@mui/icons-material";
import { createSelector } from "reselect";

export const BaselineSel = state=>state.Baselines.filter(ki=>!ki.deleted);
export const SelKeyItemByEntity =createSelector(BaselineSel,(_,entityID)=>entityID,(_,entityID,field)=>field,
    (Baselines,entityID,field)=>{
        return Baselines.find(k => {
            let match = k.entityID === entityID;
            if (match && field) {
                match = field === k.entityField;
            }
            return match;
        });
    });
export const BaselinesFilteredByArray=createSelector(BaselineSel,(_,entityArray)=>entityArray,
    (Baselines,entityArray)=>{
        return Baselines.filter(ki=>entityArray.some(e=>e.id==ki.entityID))
    }
)
export const selectBaselinesByPlantId = createSelector(
    s => s.Baselines.filter(ki=>!ki.deleted),
    (_, plantID) => plantID,
    (Baselines,plantID)=>Baselines.filter(ki=>ki.plantID==plantID)
);
export const selectMultiBaselines= createSelector(
    selectBaselinesByPlantId,
    (_,plantID,entityID)=>entityID,
    (visitKIs,entityID)=>visitKIs.filter(ki=>ki.entityID==entityID)
);