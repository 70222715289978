import Constants from '../constants';
import produce from "immer";
import { UpdateArray, ChangeOrder,UpdateArrayRange } from '../misc/ArrayFuncs';
const initDataState = [];
const MachineFlutes = (state = initDataState, action) => {
    switch (action.type) {
        case Constants.Actions.MachineFlutes_ReceiveByPlant:
            return UpdateArrayRange(action.data, state);
        case Constants.Actions.MachineFlute_Add:
            return [...state, action.payload];
        case Constants.Actions.MachineFlute_Add_Rollback:
            const machineInd = state.findIndex(machine => machine.id === action.meta.newMachineFlute.id);
            if (machineInd !== -1) {
                return produce(state, pd => { pd.splice(machineInd, 1) });
            } else {
                return state;
            }
        case Constants.Actions.MachineFlute_Add_Commit:
            return UpdateArray(action.payload?.data, state);
        case Constants.Actions.MachineFlute_Delete:
            return UpdateArray(produce(action.payload, d => {
                d.deleted = true;
            }), state);
        case Constants.Actions.MachineFlute_Delete_Commit:
            return state;
        case Constants.Actions.MachineFlute_Delete_Rollback:
            return UpdateArray(action.meta.delMachineFlute, state);
        case Constants.Actions.MachineFlute_ChangeOrder:
            return ChangeOrder(action.payload.id, state, action.payload.isUp, (a, b) => a.machineID == b.machineID);
        case Constants.Actions.MachineFlute_ChangeOrder_Commit:
            return state;
        case Constants.Actions.MachineFlute_ChangeOrder_Rollback:
            let isUp = false;
            if (action.meta.changeData.isUp)
                isUp = true;
            return ChangeOrder(action.meta.changeData.id, state, isUp, (a, b) => a.machineID == b.machineID);
        case Constants.Actions.MachineFlute_ClearData:
            return initDataState;
        default:
            return state;
    };
}
export default MachineFlutes;