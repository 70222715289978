import Constants from '../constants';
import { mapTo, filter, mergeMap } from 'rxjs/operators';
import { Observable, of, concat } from 'rxjs';
import { enqueueSnackbar, closeSnackbar as closeSnackbarAction } from '../actions/NotificationActions';
import { AddSyncError } from '../actions/SyncErrorActions';
import { useDispatch } from 'react-redux';
import React from 'react';
import Button from '@mui/material/Button';
import { v4 as uuidv4 } from 'uuid';
//import { error } from 'console';
const NotificationFromAction = (action,errorID) => {
    return (dispatch, getState) => {
        const closeSnackbar = (key) => dispatch(closeSnackbarAction(key));
        const newNotification = {
            message: 'Sync Error Rolled Back Change Type:' + action.type,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
                action: key => (
                    <Button onClick={() => closeSnackbar(key)}>dismiss</Button>
                ),
            },
        };
        dispatch(enqueueSnackbar(newNotification));
    }
};
const SyncErrorAdd = (action, errorID) => {
    return AddSyncError(action, errorID);
};
export const rollbackEpic = (action$, state) => action$.pipe(
    filter(action => {
        return action && typeof action.type == 'string'
            && action.type.substring(action.type.length - 8).toUpperCase() == "ROLLBACK"
            && action.type !== Constants.Actions.SyncError_Add_Rollback //Prevent Infinate Loops
    }),
    mergeMap(action => {
        const errorID = uuidv4();
        return of(NotificationFromAction(action,errorID), SyncErrorAdd(action,errorID));
    })
    
);