import Constants from '../../constants';
//Modules
import React, { useEffect, useContext, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

//MUI
import { TextField as FormText } from 'formik-mui'
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import PrintIcon from '@mui/icons-material/Print';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import makeStyles from '@mui/styles/makeStyles';
import EditIcon from '@mui/icons-material/Edit';
import { v4 as uuidv4 } from 'uuid';
//Components
import { PlantLink } from '../MetaData/Links';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { usePlantIDFromVisitID } from '../MetaData/PlantOptions';
import { DataForm, DataTextBox, DataDate, DataSelect, DataNumber, DataFormContext } from '../FormManager';
import { ConfirmAction } from '../../actions/ConfirmActionActions';
import { NativeSelectFormik } from '../FormikUI/NativeSelectFormik';
import { FormControl, IconButton, InputLabel } from '@mui/material';

import { ForkLeft, Height, SavedSearch, WidthFull } from '@mui/icons-material';
import { selectBaselinesByPlantId } from '../../selectors/BaselineSelectors';
import { AddContImprove, DeleteContImprove, UpdateContImprove } from '../../actions/ContinuousImprovementAction';
import { Formik, Form, Field } from 'formik';
import { usePlantDataFromVisitID } from '../../misc/UseFuncs';
import { SelSKItemsByVisitID } from '../../selectors/VisitSelectors';
import { AddNewStarchKitchenItem } from '../../actions/StarchKitchenActions';
import StarchKitchenItemsView from '../../components/Visit/StarchKitchenItemsView';
const StarchKitchenAddEdit = props => {
    const params = useParams();
    const glID = params.VisitID;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { visit, userObj } = useSelector(state => {
        return {
            visit: state.Visits.find(gr => gr.id == glID),
            userObj: state.Auth.userData
        };
    }, shallowEqual);
    
    console.log('____11________________________________________________________________________', visit);

    const plant = usePlantDataFromVisitID(params.VisitID);
    const plantID = usePlantIDFromVisitID(visit ? params.VisitID : null);
    const user = userObj ? userObj.fullName : ''
    const tID = params.VisitID;
    const UpdateId = params.StarchKiID;
    const Satedata = useSelector(s => {
        return s
    });
    const [skName, setskName] = useState('');
    const [skNameErr, setskNameErr] = useState('');
    const [isloading, setisloading] = useState(false);
    const KeyItems = useSelector(state => state.KeyItems.filter(ki => !ki.deleted), shallowEqual);
    const items = useSelector(s => SelSKItemsByVisitID(s, params.VisitID));
    console.log('sssssssssssssssssssss   UpdateId  sssssssssssssssssssssss', UpdateId);
    console.log('sssssssssssssssssssss   items  sssssssssssssssssssssss', items);
    useEffect(() => {
        console.log('____________________________________________________________________________', visit);
    }, [visit]);
    const handleDel = () => {
        const visitID = params.VisitID;
    }

    const handleClose = () => {
        navigate(-1);
    }
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent default form submission
            handleSave();
        }
    };
    const handleSave = (e) => {
        console.log(' onClick={handleSave}')
        e.preventDefault(); // Prevent default form submission
        let index = -1
        if (skName !== '') {
            index = items.findIndex(e => e.name.toLowerCase() === skName.toLowerCase().trim());
        }
        if (index !== -1) {
            console.log(`Exist error show`);
            setskNameErr('This item is already exist')
        } else {
            if (skName === '') {
                setskNameErr('Name is Required')
            } else {
                setisloading(true)
                console.log(`Ready to save`);
                const newOrder = items.reduce((acc, item) => {
                    return Math.max(acc, item.order);
                }, 0) + 1;
                const newItem = { id: uuidv4(), name: skName.trim(), visitID: visit.id, checked: false, order: newOrder };
                dispatch(AddNewStarchKitchenItem(newItem));
                setTimeout(() => {
                    // navigate(-1);
                    navigate('/VisitOverview/' + visit.id + '?' + 'starchKitchen');
                }, 1000);
            }
        }
    }

    const handleAdd = (formVals) => {
        const newOrder = items.reduce((acc, item) => {
            return Math.max(acc, item.order);
        }, 0) + 1;
        const newItem = { id: uuidv4(), name: formVals.skName, visitID: visit.id, checked: false, order: newOrder };
        dispatch(AddNewStarchKitchenItem(newItem));
    }
    const validate = (formVals) => {
        setskNameErr('')
        console.log('check ddd #######', formVals.skName);
        let errors = {};
        if (!formVals.skName) {
            setskName('')
            errors.skName = "Required";
        } else {
            setskName(formVals.skName)
        }
        return errors;
    }

    return (
        <DataForm>
            <Grid container sx={{ marginBottom: 12 }}>
                <Grid item xs={12}>
                {UpdateId === 'edit' ? <><Grid container spacing={3}>
                        <Grid item xs={4} sm={4} align="left">
                        </Grid>
                        <Grid item xs={4} sm={4} >
                        </Grid>
                        <Grid item xs={4} sm={4} align="right" style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                            <Button disabled={isloading} variant="contained" startIcon={<CloseIcon />} onClick={handleClose}>
                                Close
                            </Button>
                        </Grid>
                    </Grid></> :<></>}
                </Grid>
                {UpdateId !== 'edit' ? <>
                    <Grid item sm={12} sx={{ marginBlock: 3,paddingLeft: 1 }}>
                        <div>
                            Starch Kitchen
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Formik initialValues={{ skName: '' }} onSubmit={() => {}} validate={validate} >
                            <Form>
                                <Field style={{ width: '100%' }}
                                    component={FormText}
                                    name="skName"
                                    type="text"
                                    label="Name"
                                    onKeyDown={handleKeyPress}
                                    InputProps={{
                                        inputProps: { autoFocus: true }
                                    }}
                                />
                                {
                                    // skNameErr !== '' ? <Typography style={{ marginLeft: '10px' }} color="error">{skNameErr}</Typography> : <></>
                                }
                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px',paddingLeft: 2}}>
                                    <Button disabled={isloading} variant="contained" color="secondary"  startIcon={<CloseIcon />} onClick={handleClose}>
                                        Close
                                    </Button>
                                    {
                                        isloading && UpdateId !== 'edit' ? <div style={{ marginLeft: '12px'}}><CircularProgress /></div> :

                                            UpdateId !== 'edit' ?

                                                <Button sx={{ marginLeft: 2 }} variant="contained" type="submit" onClick={handleSave}>
                                                    Save
                                                </Button> : <></>
                                    }
                                </div>
                            </Form>
                        </Formik>
                        {/* <Grid align="right" style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', marginTop: '8px' }}>

                            <Button disabled={isloading} variant="contained" color="secondary"  startIcon={<CloseIcon />} onClick={handleClose}>
                                Close
                            </Button>
                            {
                                isloading && UpdateId !== 'edit' ? <div style={{ marginLeft: '12px', height: '36px' }}><CircularProgress /></div> :

                                    UpdateId !== 'edit' ?

                                        <Button sx={{ marginLeft: 2 }} variant="contained" onClick={handleSave}>
                                            Save
                                        </Button> : <></>
                            }


                        </Grid> */}
                    </Grid>
                </> :
                    <>
                    <Grid item sm={12} sx={{ marginBottom: 4, marginLeft: 4 }}>
                        <div>
                            Starch Kitchen
                        </div>
                    </Grid>
                        <Grid item xs={12} sx={{marginLeft: 4}}>
                            {
                                visit?.id ?
                                <StarchKitchenItemsView isHideAddRow={true} visit={visit} /> 
                                 : <>Lading data please waite...</>
                            }
                        </Grid>
                    </>
                }
            </Grid>
        </DataForm>
    );
};
export default StarchKitchenAddEdit;

