import Constants from '../constants';
import produce from "immer";
import { UpdateArray, UpdateArrayRange } from '../misc/ArrayFuncs';

const initDataState = [];
const ChecklistItem = (state = initDataState, action) => {
    switch (action.type) {
        case Constants.Actions.ChecklistItem.Add:
            return UpdateArray(action.payload, state);
        case Constants.Actions.ChecklistItem.Add_Rollback:
            const cliID = state.findIndex(iv => iv.id === action.meta.newCLI.id);
            if (cliID !== -1) {
                return produce(state, pd => { pd.splice(cliID, 1) });
            } else {
                return state;
            }
        case Constants.Actions.ChecklistItem.Add_Commit:
            return UpdateArray(action.payload?.data, state);
        case Constants.Actions.ChecklistItem.ClearData:
            return initDataState;
        case Constants.Actions.ChecklistItem.Update:
            return UpdateArray(action.payload, state);
        case Constants.Actions.ChecklistItem.Update_Commit:
            return UpdateArray(action.payload?.data, state);
        case Constants.Actions.ChecklistItem.Update_Rollback:
            return UpdateArray(action.meta.current, state);
        case Constants.Actions.ChecklistItem.GetByParentID:
            return UpdateArrayRange(action.data,state);
        default:
            return state;
    }
}
export default ChecklistItem;