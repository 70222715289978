import Constants from '../constants';
import produce from "immer";
import { UpdateArray,UpdateArrayRange } from '../misc/ArrayFuncs';
const initDataState = [];
const HeatVessels = (state = initDataState, action) => {
    switch (action.type) {
        case Constants.Actions.HeatVessel.Add:
            return UpdateArray(action.payload, state);
        case Constants.Actions.HeatVessel.Add_Rollback:
            const hvId = state.findIndex(hv => hv.id === action.meta.newHV.id);
            if (hvId !== -1) {
                return produce(state, pd => { pd.splice(hvId, 1) });
            } else {
                return state;
            }
        case Constants.Actions.HeatVessel.Add_Commit:
            return UpdateArray(action.payload?.data, state);
        case Constants.Actions.HeatVessel.GetByID:
            return UpdateArray(action.data, state);
        case Constants.Actions.HeatVessel.Update:
            //Action will have the full plant data
            return state.map((p, ind) => {
                if (p.id === action.payload.id) {
                    return produce(p, pd => { pd[action.payload.name] = action.payload.newValue });
                } else { return p; }
            });

        case Constants.Actions.HeatVessel.Update_Commit:
            return UpdateArray(action.payload?.data, state);
        case Constants.Actions.HeatVessel.Update_Rollback:
            //Action will have the full plant data
            return state.map((p, ind) => {
                if (p.id === action.meta.updatePayload.id) {
                    return produce(p, pd => { pd[action.meta.updatePayload.name] = action.meta.updatePayload.oldValue });
                } else { return p; }
            });
        case Constants.Actions.HeatVessel.Delete:
            return UpdateArray(produce(action.payload, d => {
                d.deleted = true;
            }), state);
        case Constants.Actions.HeatVessel.Delete_Rollback:
            return UpdateArray(action.meta.delHeatVessel, state);
        case Constants.Actions.HeatVessel.ClearData:
            return initDataState;
        case Constants.Actions.HeatVessel.GetByVisitID:
            return UpdateArrayRange(action.data, state);
        default:
            return state;
    }
}
export default HeatVessels;