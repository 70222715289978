import Constants from '../constants';
import produce from "immer";
import { UpdateArray,UpdateArrayRange } from '../misc/ArrayFuncs';
const initDataState = [];
const KeyItems = (state = initDataState, action) => {
    switch (action.type) {
        case Constants.Actions.KeyItems.Recive_Visit:
            return UpdateArrayRange(action.data, state);
        case Constants.Actions.KeyItems.Receive_Plant:
            return UpdateArrayRange(action.data, state);
        case Constants.Actions.KeyItems.Recieve_Entity:
            const recEntity = state.filter(v => v.entityID !== action.data.entityID);
            recEntity.push(action.data);
            return recEntity;
        case Constants.Actions.KeyItems.Add:
            return UpdateArray(action.payload, state);
        case Constants.Actions.KeyItems.Add_Rollback:
            const ivId = state.findIndex(iv => iv.id === action.meta.newKeyItem.id);
            if (ivId !== -1) {
                return produce(state, pd => { pd.splice(ivId, 1) });
            } else {
                return state;
            }
        case Constants.Actions.KeyItems.Add_Commit:
            return UpdateArray(action.payload?.data, state);
        case Constants.Actions.KeyItems.Update:
            return UpdateArray(action.payload.update, state);
        case Constants.Actions.KeyItems.Update_Commit:
            return UpdateArray(action.payload?.data, state);
        case Constants.Actions.KeyItems.Update_Rollback:
            return UpdateArray(action.meta.data.current, state);
        case Constants.Actions.KeyItems.Delete:
            return UpdateArray(produce(action.payload, d => {
                d.deleted = true;
            }), state);
        case Constants.Actions.KeyItems.Delete_Commit:
            return state;
        case Constants.Actions.KeyItems.Delete_Rollback:
            return UpdateArray(action.meta.delKeyItem, state);
        case Constants.Actions.KeyItems.ClearData:
            return initDataState;
        default:
            return state;
    }
}
export default KeyItems;