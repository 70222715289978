import Constants from '../../constants';
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import SignatureCanvas from 'react-signature-canvas'
import { v4 as uuid } from 'uuid';
//MUI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
//Components 
import ImagePrintView from '../ImageAttachment/ImagePrintView';
import { DateView } from '../MetaData/MiscViews';
//Actions
import { AddAttachment } from '../../actions/AttachmentActions';
import { CaptureFormulaSignature, DeleteFormulaSignature } from '../../actions/FormulaActions';
import { ConfirmAction } from '../../actions/ConfirmActionActions';

const FormulaSignatureDialog = props => {
    const sigRef = useRef();
    const dispatch = useDispatch();
    const capture = () => {
        const dataURL = sigRef.current.getTrimmedCanvas().toDataURL('image/png');
        const base64 = dataURL.substring(dataURL.indexOf(',') + 1);
        const filetype = dataURL.substring(0, dataURL.indexOf(';'));
        const newImage = { data: base64, mime: filetype.substring(5), id: uuid() };
        dispatch(AddAttachment(newImage));
        dispatch(CaptureFormulaSignature(newImage.id, props.formula));
        props.onClose();
    }
    const clear = () => {
        sigRef.current.clear();
    }
    const clearSignuature = () => {
        dispatch(ConfirmAction("Do you want to delete this Signature?", DeleteFormulaSignature(props.formula), null, null));
    };
    let dialogContent = <div style={{ border: "1px black solid" }}><SignatureCanvas penColor='black'
        canvasProps={{ width: 450, height: 200, className: 'sigCanvas' }} ref={sigRef} /></div>;
    let dialogButtons = <><Button onClick={clear}>Clear</Button><Button onClick={capture}>Capture</Button></>;
    if (props.formula.signatureImageID && props.formula.signatureImageID !== Constants.EmptyGuid && props.formula.signatureImageID !== '11111111-1111-1111-1111-111111111111') {
        dialogContent = <Grid container>
            <Grid item xs={12} >
                <ImagePrintView attachmentID={props.formula.signatureImageID} />
            </Grid>
            <Grid item xs={6}>Captured By:{props.formula.signatureCapturedByName}</Grid>
            <Grid item xs={6}>Captured On: <DateView value={props.formula.signatureCaptureDate} /></Grid>
        </Grid>;
        dialogButtons = <Button onClick={clearSignuature}>Clear Signature</Button>;
    }
    return <Dialog open={props.open} onClose={props.onClose} >
        <DialogTitle>
            <Button onClick={() => props.onClose()}>Close</Button>
        </DialogTitle>
        <DialogContent>
            {dialogContent}
        </DialogContent>
        <DialogActions>
            {dialogButtons}
        </DialogActions>
    </Dialog>;
}
export default FormulaSignatureDialog;