import Constants from '../../constants';
import React, { useEffect, useContext, useState } from 'react';
import { DataForm, DataTextBox, DataDate, DataSelect, DataNumber, DataFormContext } from '../FormManager';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import GlueRoller from "../../images/GlueRoller.png";
import MeterRoller from "../../images/MeterRoller.png";
import RollerBar from "../../images/RollerBar.png"
const GlueRoll_MeasurementsEntry = props => {
    const machine = useSelector(s => s.Machines.find(m => m.id === props.machineID), shallowEqual);
    let RiderRoll = null;
    if (machine.type === 4 && machine.ballastSystem === "RiderRoll") {
        RiderRoll = <>
            <Grid item xs={12}>
                <DataTextBox name={'rollerComment'} label={'Roller Comments'} multiline fullWidth />
            </Grid>
        </>;
    }
    if (machine.type === 4 && machine.ballastSystem === "Holddown Bar") {
        RiderRoll = <>
            <Grid item xs={12}>
                <DataTextBox name={'rollerComment'} label={'Holddown Comments'} multiline fullWidth />
            </Grid>
        </>;
    }
    return <Grid container>
        <Grid item xs={2}>
            <DataNumber name={'meterRollOperator'} label={'O.S. TIR'} 
                        InputProps={{ shrink: true, style: { width: '125px' } }} TextFieldProps={{ size: 'small' }} />
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={2}>
            <DataNumber name={'meterRollCenter'} label={'CENTER TIR'}
                InputProps={{ shrink: true, style: { width: '125px' } }} TextFieldProps={{ size: 'small' }} />
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={2}>
            <DataNumber name={'meterRollDrive'} label={'D.S TIR'}
                InputProps={{ shrink: true, style: { width: '125px' } }} TextFieldProps={{ size: 'small' }} />
        </Grid>
        {/*End of Top Row*/}
        <Grid item xs={2}></Grid>
        <Grid item xs={8}>
            <img src={MeterRoller} style={{ width: '100%' }} />
        </Grid>
        <Grid item xs={2}></Grid>
        {/*End of Metering Row*/ }
        <Grid item xs={1} justify="center" alignItems="center">
            <Typography variant="h6" color="primary" align="center">OPER. SIDE</Typography>
        </Grid>
        <Grid item xs={10}>
            <Grid container>
                <Grid item xs={3} justify="center" alignItems="center">
                1st Gap Check
                </Grid>
            <Grid item xs={3}>
                
            <DataNumber name={'gapOperator'} label={''}
                        InputProps={{ shrink: true, style: { maxWidth: '125px' } }} TextFieldProps={{ size: 'small' }} />
                </Grid>
                <Grid item xs={3}>
            <DataNumber name={'gapCenter'} label={''}
                        InputProps={{ shrink: true, style: { maxWidth: '125px' } }} TextFieldProps={{ size: 'small' }} />
                </Grid>
                <Grid item xs={3}>
            <DataNumber name={'gapDrive'} label={''}
                        InputProps={{ shrink: true, style: { maxWidth: '125px' } }} TextFieldProps={{ size: 'small' }} />
                    </Grid>
        
                <Grid item xs={3} justify="center" alignItems="center">
                    2nd Gap Check
                </Grid>
                <Grid item xs={3}>
                <DataNumber name={'gap2Operator'} label={''}
                        InputProps={{ shrink: true, style: { maxWidth: '125px' } }} TextFieldProps={{ size: 'small' }} />
                </Grid>
                <Grid item xs={3}>
                <DataNumber name={'gap2Center'} label={''}
                        InputProps={{ shrink: true, style: { maxWidth: '125px' } }} TextFieldProps={{ size: 'small' }} />
                </Grid>
                <Grid item xs={3}>
                <DataNumber name={'gap2Drive'} label={''}
                        InputProps={{ shrink: true, style: { maxWidth: '125px' } }} TextFieldProps={{ size: 'small' }} />
                    </Grid>
            </Grid>
        </Grid>
        <Grid item xs={1} justify="center" alignItems="center">
            <Typography variant="h6" color="primary" align="center">DRIVE SIDE</Typography>
        </Grid>
        {/* End of Gap Check Rows */}
        <Grid item xs={2}></Grid>
        <Grid item xs={8}>
            <img src={GlueRoller} style={{ width: '100%' }} />
        </Grid>
        <Grid item xs={2}></Grid>

            <Grid item xs={2}>
                <DataNumber name={'glueRollOperator'} label={'O.S TIR'}
                    InputProps={{ shrink: true, style: { width: '125px' } }} TextFieldProps={{ size: 'small' }} />
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={2}>
                <DataNumber name={'glueRollCenter'} label={'CENTER TIR'}
                    InputProps={{ shrink: true, style: { width: '125px' } }} TextFieldProps={{ size: 'small' }} />
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={2}>
                <DataNumber name={'glueRollDrive'} label={'D.S. TIR'}
                    InputProps={{ shrink: true, style: { width: '125px' } }} TextFieldProps={{ size: 'small' }} />
            </Grid>
            {RiderRoll}
            </Grid>;
};
export default GlueRoll_MeasurementsEntry;