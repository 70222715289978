import Constants from '../../constants';
import React, {  useRef } from 'react';
import {  useDispatch } from 'react-redux';
import SignatureCanvas from 'react-signature-canvas'
import moment from 'moment';
import { v4 as uuid } from 'uuid';
//MUI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
//Components 
import ImagePrintView from '../ImageAttachment/ImagePrintView';
import { DateView } from '../MetaData/MiscViews';
//Actions
import { AddAttachment } from '../../actions/AttachmentActions';
import { ConfirmAction } from '../../actions/ConfirmActionActions';

const CiSignatureDialog = props => {
    const sigRef = useRef();
    const dispatch = useDispatch();
    const capture = () => {
        const dataURL = sigRef.current.getTrimmedCanvas().toDataURL('image/png');
        const base64 = dataURL.substring(dataURL.indexOf(',') + 1);
        props.handelSignature(base64)
        props.onClose();
    }
    const clear = () => {
        sigRef.current.clear();
    }
    const clearSignuature=()=>{
        dispatch(ConfirmAction("Do you want to delete this Signature?", null, null,
            (confirmed) => {
                if (confirmed) {
                    props.handelSignature("")
                }
            }
        ));
    };
    let dialogContent = <div style={{ border:"1px black solid" }}><SignatureCanvas penColor='black'
        canvasProps={{ width: 450, height: 200, className: 'sigCanvas' }} ref={sigRef} /></div>; 
    let dialogButtons = <><Button onClick={clear }>Clear</Button><Button onClick={ capture }>Capture</Button></>;
    let imgStyle = {
        maxWidth: '100%',
        height: 'auto'
    };
    if (props.signatureImage !== '') {
        dialogContent = <Grid container>
            <Grid item xs={12} >
                <img src={"data:" + 'image/png' + ";base64," + props.signatureImage} style={imgStyle} />
            </Grid>
            <Grid item xs={6}>Captured By: {props.signatureCaptureBy}</Grid>
            <Grid item xs={6}>Captured On: {moment(props.signatureCaptureDate).format('MM/DD/YY hh:mm a')}</Grid>
        </Grid>;
        dialogButtons=<Button onClick={clearSignuature}>Clear Signature</Button>;
    }
    return <Dialog open={props.open} onClose={props.onClose} >
        <DialogTitle>
            <Button onClick={()=>props.onClose()}>Close</Button>
        </DialogTitle>
        <DialogContent>
            { dialogContent }
        </DialogContent>
        <DialogActions>
            { dialogButtons }
        </DialogActions>
    </Dialog>;
}
export default CiSignatureDialog;