import Constants from '../constants';
import { v4 as uuidv4 } from 'uuid';
import { FetchErrorLogger, FetchCatchHandler } from '../middlewares';
const constants = Constants;
export const GetActionItemLinkByEntityID = (entityID) => {
    return (dispatch, getState) => {
        //offline.online
        const state = getState();
        if (state.offline.online) {
            let fetchTask = fetch(constants.URLs.ActionItemLinks + entityID, { headers: { 'Authorization': 'Bearer ' + state.Auth.token } })
                .then(FetchErrorLogger("GetActionItemLinkByEntityID", entityID, dispatch))
                .then(response => response.json())
                .then(aiLink => {
                    //Ignore 404 errors
                    if (Array.isArray(aiLink) ) {
                        dispatch({ type: constants.Actions.ActionItemLinks.Recieve_Entity, data: aiLink, entityID:entityID });
                    }
                })
                .catch(FetchCatchHandler("GetActionItemLinkByEntityID", entityID,dispatch))
        }
    }
}
export const cccc = (visitID) => {
    return (dispatch, getState) => {
        //offline.online
        const state = getState();
        if (state.offline.online) {
            let fetchTask = fetch(constants.URLs.ActionItemLinks + "?VisitID=" + visitID, { headers: { 'Authorization': 'Bearer ' + state.Auth.token } })
                .then(FetchErrorLogger("GetActionItemLinkByVisitID", visitID, dispatch))
                .then(response => response.json())
                .then(aiLinks => {
                    //Ignore 404 errors
                    if (Array.isArray(aiLinks)) {
                        dispatch({ type: constants.Actions.ActionItemLinks.Recive_Visit, data: aiLinks, visitID: visitID });
                    }
                })
                .catch(FetchCatchHandler("GetActionItemLinkByVisitID", visitID, dispatch))
        }
    }
}
export const AddActionItemLink = (newActionItemLink) => {    
    if (!newActionItemLink.id) {
        newActionItemLink.id = uuidv4();
    }
    return {
        type: constants.Actions.ActionItemLinks.Add,
        payload: newActionItemLink,
        meta: {
            offline: {
                effect: {
                    url: constants.URLs.ActionItemLinks, method: 'POST', body: JSON.stringify(newActionItemLink), headers: { 'content-type': 'application/json' }
                },
                commit: {
                    type: constants.Actions.ActionItemLinks.Add_Commit, meta: { newActionItemLink }
                },
                rollback: {
                    type: constants.Actions.ActionItemLinks.Add_Rollback, meta: { newActionItemLink }
                }
            }
        }
    }
};
export const DeleteActionItemLink = delAILinkID => {
    return (dispatch, getState) => {
        const state = getState();
        const delActionItemLink = state.ActionItemLinks.find(ail => ail.id == delAILinkID);
        dispatch({
            type: constants.Actions.ActionItemLinks.Delete,
            payload: delActionItemLink,
            meta: {
                offline: {
                    effect: {
                        url: Constants.URLs.ActionItemLinks + delActionItemLink.id, method: 'DELETE'
                    },
                    commit: {
                        type: constants.Actions.ActionItemLinks.Delete_Commit, meta: { delActionItemLink }
                    },
                    rollback: {
                        type: constants.Actions.ActionItemLinks.Delete_Rollback, meta: { delActionItemLink }
                    }
                }
            }
        });
    }
};

export const ClearData = () => { return { type: Constants.Actions.ActionItemLinks.ClearData }; };
