import Constants from '../constants';
import { AddMachineVesselByName } from "../actions/MachineVesselsActions";
import { Observable, of, concat } from 'rxjs';
import { mapTo, filter, mergeMap } from 'rxjs/operators';
const models = ["UNITED VANGUARD", "UNITED ADVANTAGE", "BHS-MFP", "BHS-MFB", "BHS MFA", "MHI-50C", "MHI-PASER","LANGSTON 380","FOSBER"];
const VesselsAddAction = (action) => {
    return (dispatch, getState) => {
        const state = getState();
        const mvs = state.MachineVessels.filter(mv => mv.machineID == action.payload.id && !mv.deleted);
        let order = mvs.length;
        switch (action.payload.newValue.toUpperCase()) {
            case "UNITED VANGUARD":
                dispatch(AddMachineVesselByName("Medium Perconditioner Curved Plate", action.payload.id, order++));
                dispatch(AddMachineVesselByName("Top Liner Preheater 36\"", action.payload.id, order++));
                dispatch(AddMachineVesselByName("Top Liner Peanut roll", action.payload.id, order++));
                dispatch(AddMachineVesselByName("Pressure Roll", action.payload.id, order++));
                dispatch(AddMachineVesselByName("Lower Corrugating roll", action.payload.id, order++));
                dispatch(AddMachineVesselByName("upper corrugating roll", action.payload.id, order++));
                dispatch(AddMachineVesselByName("Medium infusion drum", action.payload.id, order++));
                break;
            case "UNITED ADVANTAGE":
                dispatch(AddMachineVesselByName("Top Liner Preheater 36\"", action.payload.id, order++));
                dispatch(AddMachineVesselByName("15\" Top Liner Peanut Roll", action.payload.id, order++));
                dispatch(AddMachineVesselByName("Pressure Roll", action.payload.id, order++));
                dispatch(AddMachineVesselByName("upper corrugating roll", action.payload.id, order++));
                dispatch(AddMachineVesselByName("Lower Corrugating roll", action.payload.id, order++));
                dispatch(AddMachineVesselByName("Medium Preconditioner", action.payload.id, order++));
                break;
            case "BHS-MFP":
                dispatch(AddMachineVesselByName("Integrated Preconditioner 1", action.payload.id, order++));
                dispatch(AddMachineVesselByName("Integrated Preconditioner 2", action.payload.id, order++));
                dispatch(AddMachineVesselByName("upper corrugating roll", action.payload.id, order++));
                dispatch(AddMachineVesselByName("Lower Corrugating roll", action.payload.id, order++));
                dispatch(AddMachineVesselByName("Pressure Roll", action.payload.id, order++));
                dispatch(AddMachineVesselByName("PH Internal", action.payload.id, order++));
                dispatch(AddMachineVesselByName("PH External 1", action.payload.id, order++));
                dispatch(AddMachineVesselByName("PH External 2", action.payload.id, order++));
                break;
            case "BHS-MFB":
                dispatch(AddMachineVesselByName("Integrated Preconditioner 1", action.payload.id, order++));
                dispatch(AddMachineVesselByName("Integrated Preconditioner 2", action.payload.id, order++));
                dispatch(AddMachineVesselByName("upper corrugating roll", action.payload.id, order++));
                dispatch(AddMachineVesselByName("Lower Corrugating roll", action.payload.id, order++));
                dispatch(AddMachineVesselByName("Band Leader Heated Roll Back", action.payload.id, order++));
                dispatch(AddMachineVesselByName("Band leader Tension Roll Front", action.payload.id, order++));
                dispatch(AddMachineVesselByName("PH Internal", action.payload.id, order++));
                dispatch(AddMachineVesselByName("Pre-Heater External Top", action.payload.id, order++));
                dispatch(AddMachineVesselByName("Pre-Heater External Lower", action.payload.id, order++));
                break;
            case "BHS MFA":
                dispatch(AddMachineVesselByName("Integrated Preconditioner 1", action.payload.id, order++));
                dispatch(AddMachineVesselByName("Integrated Preconditioner 2", action.payload.id, order++));
                dispatch(AddMachineVesselByName("upper corrugating roll", action.payload.id, order++));
                dispatch(AddMachineVesselByName("Lower Corrugating roll", action.payload.id, order++));
                dispatch(AddMachineVesselByName("Band Leader Heated Roll Back", action.payload.id, order++));
                dispatch(AddMachineVesselByName("PH Internal", action.payload.id, order++));
                dispatch(AddMachineVesselByName("PH External 1", action.payload.id, order++));
                dispatch(AddMachineVesselByName("PH External 2", action.payload.id, order++));
                break;
            case "MHI-50C":
                dispatch(AddMachineVesselByName("Pre-Conditioner External Med", action.payload.id, order++));
                dispatch(AddMachineVesselByName("Medium Preconditioner", action.payload.id, order++));
                dispatch(AddMachineVesselByName("upper corrugating roll", action.payload.id, order++));
                dispatch(AddMachineVesselByName("Lower Corrugating roll", action.payload.id, order++));
                dispatch(AddMachineVesselByName("Pressure Roll", action.payload.id, order++));
                dispatch(AddMachineVesselByName("Liner Preconditioner Upper Peanut Roll", action.payload.id, order++));
                dispatch(AddMachineVesselByName("Liner Preconditioner Lower Peanut Roll", action.payload.id, order++));
                dispatch(AddMachineVesselByName("Pre-Heater External Top", action.payload.id, order++));
                dispatch(AddMachineVesselByName("Pre-Heater External Lower", action.payload.id, order++));
                break;
            case "MHI-PASER":
                dispatch(AddMachineVesselByName("Pre-Conditioner External Med", action.payload.id, order++));
                dispatch(AddMachineVesselByName("Medium Preconditioner Upper", action.payload.id, order++));
                dispatch(AddMachineVesselByName("Medium Preconditioner Lower", action.payload.id, order++));
                dispatch(AddMachineVesselByName("upper corrugating roll", action.payload.id, order++));
                dispatch(AddMachineVesselByName("Lower Corrugating roll", action.payload.id, order++));
                dispatch(AddMachineVesselByName("Liner Preconditioner Upper Peanut Roll", action.payload.id, order++));
                dispatch(AddMachineVesselByName("Liner Preconditioner Lower Peanut Roll", action.payload.id, order++));
                dispatch(AddMachineVesselByName("Pre-Heater External Top 36\"", action.payload.id, order++));
                dispatch(AddMachineVesselByName("Pre-Heater External Lower 36\"", action.payload.id, order++));
                dispatch(AddMachineVesselByName("Heated Kappa Belt Roll", action.payload.id, order++));
                break;
            case "LANGSTON 380":
                dispatch(AddMachineVesselByName("Pre-Conditioner External Med", action.payload.id, order++));
                dispatch(AddMachineVesselByName("Medium Preconditioner", action.payload.id, order++));
                dispatch(AddMachineVesselByName("upper corrugating roll", action.payload.id, order++));
                dispatch(AddMachineVesselByName("Lower Corrugating roll", action.payload.id, order++));
                dispatch(AddMachineVesselByName("Pressure Roll", action.payload.id, order++));
                dispatch(AddMachineVesselByName("Top Liner 36\"", action.payload.id, order++));
                dispatch(AddMachineVesselByName("Pre-Heater External", action.payload.id, order++));
                dispatch(AddMachineVesselByName("Pre-Heater Internal #1", action.payload.id, order++));
                dispatch(AddMachineVesselByName("Pre-Heater Internal #2", action.payload.id, order++));
                break;
            case "FOSBER":
                dispatch(AddMachineVesselByName("40\" Preheater", action.payload.id, order++));
                dispatch(AddMachineVesselByName("24\" Internal Preheater", action.payload.id, order++));
                dispatch(AddMachineVesselByName("Pressure Roll", action.payload.id, order++));
                dispatch(AddMachineVesselByName("Btm Corr Roll", action.payload.id, order++));
                dispatch(AddMachineVesselByName("Top Corr Roll", action.payload.id, order++));
                dispatch(AddMachineVesselByName("24 Btm Preconditioner", action.payload.id, order++));
                break;
            default:
                break;
        }
    }
};
export const MachineModelEpic = (action$, state$) => action$.pipe(
    filter(action => {
        let ret = false;
        if (action.type === Constants.Actions.Machines_Update
            && action.payload.name === "model"
            && action.payload.source.type == 1
            && models.indexOf(action.payload.newValue.toUpperCase()) >= 0
        ) { ret = true; }
        return ret;

    }),
    mergeMap(action => {
        return of({
            type: Constants.Actions.SHOW_PROMPT,
            title: "Auto Add Vessels?",
            message: "Do you want to add preselected vessels for this model?",
            confirmAction: VesselsAddAction(action)
        });
    })
);