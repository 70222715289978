import Constants from './constants';
import { enqueueSnackbar } from './actions/NotificationActions';
import { Logout } from './actions/AuthActions'
import logger from './logger';


export const PSLoggerMiddleware = store => next => action => {
    logger.LogAction(action);
    let result = next(action);
    //console.log('next state', store.getState())
    return result
}
export const FetchErrorLogger = (source, data, dispatch,isError=null) => {
    return response => {
        if (!response.ok && (!isError || isError(response))) {
            console.log('response.status')
            console.log(response.status)
            let messageVal = source
            let color = 'error'
            //Log Error and Dispatch Notification
            if (response.status === 401) {
                dispatch(Logout());
                throw Error("Auth failed.")
            }
            if (response.status === 404) {
                messageVal = ''
                messageVal = source + ' No Record Found'
                color = 'info'
            } else {
                messageVal = ''
                messageVal = 'API Error - ' + source + ' Failed'
                color = 'error'
            }
            logger.LogError(source, ResponseToError(response), data);
            
            dispatch(enqueueSnackbar({
                message: messageVal,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: color
                }
            }));
            throw Error(response.statusText);
        }
        return response;
    }
}
export const FetchCatchHandler = (source, data, dispatch) => {
    return error => {
        if (error.toString() === 'TypeError: Failed to fetch') {
            //Site Down Trigger Offline.
            dispatch({ type: Constants.Actions.NetworkState.SetSiteDown })
        }
        console.error(source + ' Fetch Error', error);
    }
}
export const ResponseToError = response => {
    return { ok: response.ok, redirected: response.redirected, status: response.status, statusText: response.statusText, url: response.url };
}