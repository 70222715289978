import Constants from '../constants';
import { v4 as uuidv4 } from 'uuid';
export const SendEmail = (to,cc,subject,body,attachments,from,sender,phone)=>{
    const email ={id:uuidv4(),to:to,cc:cc,subject,body,attachments,from,sender,phone};
    return {  
        type: Constants.Actions.OutboundEmail.Send,
        payload: email,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.OutboundEmail, method: 'POST', body: JSON.stringify(email), headers: { 'content-type': 'application/json' }
                },
                commit: {
                    type: Constants.Actions.OutboundEmail.Send_Commit, meta: { email }
                },
                rollback: {
                    type: Constants.Actions.OutboundEmail.Send_Rollback, meta: { email }
                }
            }
        }
    };
}