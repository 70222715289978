import Constants from '../../constants';
import React, { useEffect, useContext, useState } from 'react';
import Grid from '@mui/material/Grid';
import LineSingleSelect from '../Common/LineSingleSelect';
import MachineSelectGrid from '../PlantEdit/MachineSelectGrid';
import GlueRoll_Measurements from './GlueRoll_Measurements';
import GlueRoll_Pictures from './GlueRoll_Pictures';
const constants = Constants;
const GlueRollMachineView = props => {
    const [machineID, setMachineID] = useState(null);
    let entry = null;

    if (props.glueRoll.lineID && props.glueRoll.lineID != constants.EmptyGuid) {
        let machineEntry = <GlueRoll_Measurements machineID={machineID} glueRoll={props.glueRoll} />;
        if(props.glueRoll.glueRollType===1){
            machineEntry = <GlueRoll_Pictures machineID={machineID} glueRoll={props.glueRoll} />;
        }
        entry = <>
            <Grid item xs={3} md={2}>
                <MachineSelectGrid lineID={props.glueRoll.lineID} selectedID={machineID} selectOnChange={selID => setMachineID(selID)}
                    NoEdit NameOnly Filter={(m) => { return m.type === 1 || m.type === 4 } } NoToolbars />
            </Grid>
            <Grid item xs={9} md={10}>
                {machineEntry}
            </Grid>
        </>;
    }
    
    return <Grid container>
        <Grid item xs={12}>
            <LineSingleSelect plantID={props.plantID} onLineChange={props.onLineChange} lineID={props.glueRoll.lineID} />
        </Grid>
        {entry}
    </Grid>;
}
export default GlueRollMachineView;
