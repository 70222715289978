import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { rollbackEpic } from './rollbackEpic'
import { hotPlateVesselEpic } from './hotPlateVesselEpic'
import { MachineModelEpic } from './MachineModelEpic';
import { ivDelCommit, actionItem_Del_Rollback, SetOfflineOnline } from './MiscEpics';
export const rootEpic = combineEpics(
    rollbackEpic,
    hotPlateVesselEpic,
    MachineModelEpic,
    ivDelCommit,
    actionItem_Del_Rollback
);