import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { TextField } from 'formik-mui';
import { AuthenticationSuccess, GoOffline } from '../../actions/AuthActions'
import CCI_Logo from "../../images/CCI_Logo.svg"
import { OFFLINE_STATUS_CHANGED } from "@redux-offline/redux-offline/lib/constants";

const LoginView = props => {
    const dispatch = useDispatch();
    const [loginError, setLoginError] = useState(null);
    const handleSubmit = (formVals, { resetForm, setErrors, setSubmitting,set }) => {
        let errors = {};
        if (!formVals.UserName)
            errors.UserName = "UserName is Required";
        if (!formVals.UserPassword)
            errors.UserPassword = "Password is Required";
        if (Object.entries(errors).length === 0) {
            if (loginError)
                setLoginError(null); 
            loginRequest(formVals, setSubmitting, setErrors);
        } else {
            setErrors(errors);
            setSubmitting(false);
        }
        
    };
    const loginRequest = (formVals, setSubmitting) => {
        fetch('api/Login/',
            {
                method: 'POST', headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formVals)
            }
        )
            .then(response => {
                console.log('LOGIN GOOD 11111');
                return response.json();
            })
            .then(data => {
                if (data && data.authorized) {
                    //Good Login
                    dispatch(AuthenticationSuccess(data));
                    console.log('LOGIN GOOD 2222');
                } else {
                    // Bad Login
                    if (data.errorMsg) {
                        setLoginError(data.errorMsg);
                    } else {
                        setLoginError("Creatio Authenication Failed: No Message");
                    }
                }
                setSubmitting(false);
            })
            .catch(error => {
                //Bad Login
                setLoginError("Error connectiong to Login Server: " + error);
                setSubmitting(false);
            })
    };
    return <Paper style={{ maxWidth: '400px' }}>
        <Formik initialValues={{ UserName: '', UserPassword: '' }}
            onSubmit={handleSubmit}>
            <Form>
                <div><img src={CCI_Logo} style={{ width: '300px' }} /></div>
                <div><Typography variant="h4">Enter your Creatio Login</Typography></div>
                <div><Typography  style={{ color:'red'}}>{loginError}</Typography></div>
                <div>
                    <Field
                        component={TextField}
                        name="UserName"
                        type="text"
                        label="User Name"
                        InputProps={{
                            inputProps: { autoFocus: true }
                        }}
                    />
                    <Field
                        component={TextField}
                        name="UserPassword"
                        type="password"
                        label="Password"
                    />
                </div>
                <div>
                    <Button type="submit" variant="contained">Submit</Button>
                </div>
            </Form>
        </Formik>
    </Paper>;
}
export default LoginView;