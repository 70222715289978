import Constants from '../constants';
import { mapTo, filter, mergeMap } from 'rxjs/operators';
import { Observable, of, concat } from 'rxjs';
export const ivDelCommit = action$ => action$.pipe(
    filter(action => action.type === Constants.Actions.IngredientVerification_Delete_Commit),
    mergeMap(action => {
        return of({
            type: Constants.Actions.IngredientVerificationStep_Clear_LocalSteps, ivID: action.meta.delIngVer.id
        });
    })
);
export const actionItem_Del_Rollback = action$ => action$.pipe(
    filter(action => action.type === Constants.Actions.ActionItems.Delete_Rollback),
    mergeMap(action => {
        return of({
            type: Constants.Actions.ActionItemLinks.RestoreLinks, links: action.meta.delActionItemLinks
        });
    })
);