import Constants from '../constants';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import axios from "axios";
import { createOffline } from '@redux-offline/redux-offline';
import defaultConfig  from "@redux-offline/redux-offline/lib/defaults";
import { History } from 'history';
import { CreateRootReducer } from './RootStore';
import * as localforage from "localforage";
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { rootEpic } from '../epic/RootEpic';
import logger from 'redux-logger';
import LogRocket from 'logrocket';
import { getStore } from '../core';
import { PSLoggerMiddleware } from '../middlewares';
import { Logout } from '../actions/AuthActions';
const OfflineEffect = (effect, _action) => {
    const store = getStore();
    const state = store.getState();
    const auth = { Authorization: 'Bearer ' + state.Auth.token };
    const req = {
        method: effect.method,
        url: effect.url,
        data: effect.body,
        headers: { ...effect.headers, ...auth }
    };
    
    return axios(req);
};
const OfflineDiscard = (error, _action, _retries) => {
    const { request, response } = error;
    if (!request) throw error; // There was an error creating the request
    if (!response || error.code==="ERR_NETWORK") {
        const s = getStore();
        s.dispatch({ type: Constants.Actions.NetworkState.SetSiteDown });
        return false;
    } // There was no response
    if (response.status === 401) {
        const s = getStore();
        s.dispatch(Logout());
        return false;
    }
    return 400 <= response.status && response.status < 500;
}
const SetupDetectNetwork = dispatch => {

    console.log('check network online ofline +++++++++++++++++++++++++++++++++++++++++++++++', window.navigator.onLine);
    
    if (typeof window !== 'undefined' && window.addEventListener) {
        window.addEventListener('online', () => dispatch({ type: Constants.Actions.NetworkState.SetOnline }));
        window.addEventListener('offline', () => dispatch({ type: Constants.Actions.NetworkState.SetOffline }));
        if ( window.navigator.onLine) {
            dispatch({ type: Constants.Actions.NetworkState.SetOnline })
        } else {
           dispatch({ type: Constants.Actions.NetworkState.SetOffline })
        }
    }
};
const offlineConfig = {
    ...defaultConfig,
    persistOptions: { storage: localforage },
    retry: (action, retry) => {
        if (typeof retry !=="number" || retry === 0) {
            return 1;
        }
        else if (retry === 1) {
            return 10;
        }
        else if (retry === 2) {
            return 100;
        }
        else if(retry===3){
            return 1000;
        }
        else {
            return null;
        }
    },
    effect: OfflineEffect,
    discard: OfflineDiscard,
    detectNetwork: callback => { }
    
    

};

// console.log('check 111++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++');

const cOff = createOffline(offlineConfig);

const configureStore=(history)=> {
    const epicMiddleware = createEpicMiddleware();
    const middlewareList = [
        thunk
        , epicMiddleware
        , PSLoggerMiddleware
    ];
    
    const rootReducer = CreateRootReducer(history);


    const appliedMiddleware = applyMiddleware(...middlewareList, cOff.middleware, 
        LogRocket.reduxMiddleware({
            actionSanitizer:(action)=>{
                if(action.type==='persist/REHYDRATE'){
                    return null;
                }
                return action;
            }
        })
    );
    const store = createStore(cOff.enhanceReducer(rootReducer), compose(cOff.enhanceStore, appliedMiddleware));
    epicMiddleware.run(rootEpic);
    SetupDetectNetwork(store.dispatch);
    return store; 
}
export default configureStore;
