import produce from 'immer';
export const ScaleImages=(attachments,maxHeight,maxWidth)=>{
    return attachments.map(a=>{
        return new Promise((resolve,reject)=>{
            const img = new window.Image();
                img.addEventListener("load",()=>{
                    let retAttachment=a;
                    let rHeight=0,rWidth=0;
                    if(img.height>maxHeight) {rHeight=maxHeight/img.height}
                    if(img.width>maxWidth) {rWidth=maxWidth/img.width}
                    const resize =Math.min(rHeight,rWidth);
                    if(resize>0){    
                        let newData=compressImage(img,resize,1,a.mime);
                        const base64 = newData.substring(newData.indexOf(',')+1);
                        retAttachment=produce(a,d=>{
                            d.data=base64;
                        })
                    }
                    resolve(retAttachment);
                });
                img.src="data:" + a.mime + ";base64," + a.data;
        })
    })
}

export const compressImage=(imgToCompress,resizingFactor,mime) =>{
    // showing the compressed image
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    
    const originalWidth = imgToCompress.width;
    const originalHeight = imgToCompress.height;


    const canvasWidth = originalWidth * resizingFactor;
    const canvasHeight = originalHeight * resizingFactor;
    
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;
    
    context.drawImage(
      imgToCompress,
      0,
      0,
      originalWidth * resizingFactor,
      originalHeight * resizingFactor
    );
   
    return canvas.toDataURL(mime,1);
};