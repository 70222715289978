import { KeyOutlined } from "@mui/icons-material";
import { createSelector } from "reselect";

export const KeyItemsSel = state=>state.KeyItems.filter(ki=>!ki.deleted);
export const SelKeyItemByEntity =createSelector(KeyItemsSel,(_,entityID)=>entityID,(_,entityID,field)=>field,
    (keyItems,entityID,field)=>{
        return keyItems.find(k => {
            let match = k.entityID === entityID;
            if (match && field) {
                match = field === k.entityField;
            }
            return match;
        });
    });
export const KeyItemsFilteredByArray=createSelector(KeyItemsSel,(_,entityArray)=>entityArray,
    (keyItems,entityArray)=>{
        return keyItems.filter(ki=>entityArray.some(e=>e.id==ki.entityID))
    }
)
export const selectKeyItemsByVisitID = createSelector(
    s => s.KeyItems.filter(ki=>!ki.deleted),
    (_, visitID) => visitID,
    (keyItems,visitID)=>keyItems.filter(ki=>ki.visitID==visitID)
);
export const selectMultiKeyItems= createSelector(
    selectKeyItemsByVisitID,
    (_,visitID,entityID)=>entityID,
    (visitKIs,entityID)=>visitKIs.filter(ki=>ki.entityID==entityID)
);