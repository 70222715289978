import React, { Component } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Paper from '@mui/material/Paper';
import { DataForm, DataTextBox, DataNumber } from '../FormManager';
import { DesiredSpeedImprovementsGridView } from './DesiredSpeedImprovementsGridView';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';

class ProductionTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Error: false,
            ErrorMsg: '',
            active: '1',
            isDirty: false
        }
        
        this.renderProduction = this.renderProduction.bind(this);
        this.renderSpecialtyProd = this.renderSpecialtyProd.bind(this);
        this.renderPlantChanges = this.renderPlantChanges.bind(this);
    }
    renderProduction() {
        return <Card >
            <CardHeader title="Production" />
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs>
                        <DataNumber name={'volume'} label={'Volume (MMSF/MO)'} type="int" />
                        <DataNumber name={'averageBasisWeight'} label={'Average Basis Weight'} type="int" />
                        <DataTextBox name={'shiftArrangement'} label={'Shift Arrangement'} />
                    </Grid>
                    <Grid item xs>   
                        <DataNumber name={'averageTotalWastePerc'} label={'Average Total Waste'} InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }} />
                        <DataNumber name={'doubleWallPerc'} label={'Double Wall'} InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }} />
                        <DataNumber name={'tripleWallPerc'} label={'Triple Wall'} InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }} />
                    </Grid>

                </Grid>
                <DataTextBox name={'needs'} label={'Major Customers and Special Needs'} multiline fullWidth />
            </CardContent>
        </Card>;
    }
    renderSpecialtyProd() {
        return (
        <Card >
            <CardHeader title="Specialty Production" />
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs>
                            <DataNumber name={'preprintPerc'} label={'Preprint'}
                                InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }} />
                            <DataNumber name={'recycledPaperPerc'} label={'Recycled Paper'}
                                InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }} />
                            <DataNumber name={'hpLinerPerc'} label={'High Performance Liner'}
                                InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }} />
                            <DataNumber name={'dualArchPerc'} label={'Dual Arch'}
                                InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }} />
                        </Grid>
                        <Grid item xs>
                            <DataNumber name={'clayWhitePerc'} label={'Clay White'}
                                InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }} />
                            <DataNumber name={'bannerBoardPerc'} label={'Banner Board'}
                                InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }} />
                            <DataNumber name={'waxPerc'} label={'Wax Treatments'}
                                 InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }} />
                            <DataTextBox name={'other'} label={'Other'} />
                        </Grid>
                    </Grid>
            </CardContent>
        </Card>);
    }
    renderGoalsandObj() {
        return (
            <Card >
                <CardHeader title="Goals and Objectives" />
                <CardContent>
                    <Paper>
                    <Grid container spacing={3}>
                        <Grid item xs>
                            <DataTextBox name={'targetVolume'} label={'Target Volume'} />
                        </Grid>
                        <Grid item xs>
                            <DataNumber name={'linealFeetPerHour'} label={'Lineal Feet / Hour'} type="int" />
                        </Grid>
                        <Grid item xs>
                            <DataTextBox name={'otherGoals'} label={'Other'} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs>
                            <DataTextBox name={'curStarchConsumption'} label={'Current Starch Consumption(lbs/msf)'} fullWidth
                            />
                        </Grid>
                        <Grid item xs>
                                <DataTextBox name={'goalStarchConsumption'} label={'Goal Starch Consumption(lbs/msf)'} fullWidth />
                        </Grid>
                    </Grid>
                    <DesiredSpeedImprovementsGridView plantID={this.props.plantID} />

                    <Grid container spacing={3}>
                            <Grid item xs>
                                <DataTextBox name={'qualityGoals'} multiline label={ "Quality Goals"} fullWidth />
                                    </Grid>
                            
                            <Grid item xs>
                                <DataTextBox name={'expectedBusinessChanges'} multiline label={ "New Business Expected or Mix Changes Anticipated" } fullWidth />
                                    </Grid>
                                </Grid>
                        <DataTextBox name={'expectedUpgrades'} multiline label={"New Equipment or Upgrades Expected (including dates)"} fullWidth />
                    </Paper>
                </CardContent>  
            </Card>

            );
       
    }
    renderPlantChanges() {
        return null;
       
    }
    render() {
        const ProductionSpecialtyRow = <Grid container>
            <Grid item sm={12} md={6} >
                {this.renderProduction()}
            </Grid>
            <Grid sm={12} md={6}>
                {this.renderSpecialtyProd()}               
            </Grid>
        </Grid>;
        return (
            <>
                {ProductionSpecialtyRow}
                {this.renderGoalsandObj()}
                {this.renderPlantChanges()}
          </>
            );
    }
}
export default ProductionTab;
