import Constants from '../constants';
import { FetchErrorLogger, FetchCatchHandler } from '../middlewares';
import { AddSyncTimestamp, CheckReSync } from './LastSyncActions';
export const GetAutoCompleteData = (AutoCompleteName) => {
    console.log('MetaDataActions.js GetAutoCompleteData Called');
    return (dispatch, getState) => {
        //Check if Data is Already Loaded
        const state = getState();
        if (state.offline.online && !state.MetaData.AutoCompleteData.hasOwnProperty(AutoCompleteName)) {
            console.log('MetaDataActions.js GetAutoCompleteData Thunk Called');
            let fetchTask = fetch('api/MetaData/' + AutoCompleteName, { headers: { 'Authorization': 'Bearer ' + state.Auth.token } })
                .then(FetchErrorLogger("GetAutoCompleteData", AutoCompleteName, dispatch))
                .then(response => response.json())
                .then(ac => {
                    console.log("Got AutoComplete", ac);
                    dispatch({ type: Constants.Actions.ReceiveAutoComplete, data: ac, name: AutoCompleteName })
                })
                .catch(FetchCatchHandler("GetAutoCompleteData", AutoCompleteName, dispatch));
        }
    };
};


export const GetVessels = (cacheMissMS) => {
    console.log('DataActions.js Get Vessels Called');
    if (isNaN(cacheMissMS)) { cacheMissMS = 14400000; }
    return (dispatch, getState) => {
        const state = getState();
        console.log('DataActions.js Get Vessels Thunk Called');
        if (state.offline.online && CheckReSync("Vessels", state.LastSync, cacheMissMS)) {
            //No Data Get Data
            let fetchTask = fetch('api/MetaData/GetVessels', { headers: { 'Authorization': 'Bearer ' + state.Auth.token } })
                .then(FetchErrorLogger("GetVessels", null, dispatch))
                .then(response => response.json())
                .then(vessels => {
                    console.log("Got Vessels", vessels);
                    dispatch({ type: Constants.Actions.ReceiveVessels, data: vessels })
                    dispatch(AddSyncTimestamp("Vessels"));
                })
                .catch(FetchCatchHandler("GetVessels", null, dispatch));
        }
    };
};
export const AddVessel = (newVessel) => {
    return {
        type: Constants.Actions.AddVessel,
        payload: newVessel,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.AddVessel, method: 'POST', body: JSON.stringify(newVessel), headers: { 'content-type': 'application/json' }
                },
                commit: {
                    type: Constants.Actions.AddVessel_Commit, meta: { newVessel }
                },
                rollback: {
                    type: Constants.Actions.AddVessel_Rollback, meta: { newVessel }
                }
            }
        }
    }
};
export const ClearData = () => { return { type: Constants.Actions.MetaData_ClearData }; };
