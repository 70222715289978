import Constants from '../../constants';
//Modules
import React, { useEffect, useContext, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import  {useNavigate, useParams } from 'react-router-dom';

//MUI
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import PrintIcon from '@mui/icons-material/Print';
import makeStyles from '@mui/styles/makeStyles';
//Components
import { PlantLink } from '../MetaData/Links';
import { usePlantIDFromVisitID } from '../MetaData/PlantOptions';
import VesselTempsGrid from './VesselTempsGrid';
import { UpdateAction } from '../../actions/DataActions';
import GetEntity from '../../misc/GetEntity';
import HeatVesselPrint from './HeatVesselPrint';
import KeyItemsPrint from '../KeyItems/KeyItemsPrint';
import ActionItemsPrint from '../ActionItem/ActionItemsPrint';
import PrintDialog from '../Print/PrintDialog';
import { DataForm, DataTextBox, DataDate, DataSelect, DataNumber, DataFormContext } from '../FormManager';
//Actions
import { GetHeatVessel } from '../../actions/HeatVesselActions';
import { DeleteHeatVessel, CreateHV_Temps, LoadHeatVessel, SetHeatVesselName } from '../../actions/HeatVesselActions';
import { GetPlantMachines } from '../../actions/MachineActions'
import { GetPlantMachineVessels } from '../../actions/MachineVesselsActions';
import { GetPlantMachineFlutes } from '../../actions/MachineFlutesActions';
import { enqueueSnackbar, closeSnackbar } from '../../actions/NotificationActions';
import { ConfirmAction } from '../../actions/ConfirmActionActions';
//Selectors
import { GetBoilers } from '../../selectors/HeatVesselSelectors';
const constants = Constants;
const useStyles = makeStyles((theme) => ({
    header: {
        borderBottom: 'solid'
    },
}));
const HeatVesselView = props => {
    const classes = useStyles();
    const params=useParams();
    const hvID = params.SurveyID;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { heatVessel, online, authToken, offlineMode } = useSelector(state => {
        return {
            heatVessel: state.HeatVessels.find(hv => hv.id == hvID),
            online: state.offline.online,
            authToken: state.Auth.token,
            offlineMode: state.Auth.offlineMode
        };
    }, shallowEqual);
    const boilers=useSelector(s=>GetBoilers(s,heatVessel?.lineID));
    //Get Latest Data and Check if still valid.
    useEffect(() => {
        if (hvID) {
            //Pull HeatVessel Data
            if (authToken && !offlineMode && online) {
                GetEntity(constants.URLs.HeatVessel + hvID, online, authToken)
                    .then(ret => {
                        if (ret.complete && ret.valid) {
                            dispatch(LoadHeatVessel(ret.data));
                        }
                    })
                    .catch(ret => {
                        if (!heatVessel) {
                            const newNotification = {
                                message: 'Could not load Heat Vessel Data Error:' + ret.error,
                                options: {
                                    key: new Date().getTime() + Math.random(),
                                    variant: 'error',
                                    action: key => (
                                        <Button onClick={() => closeSnackbar(key)}>dismiss</Button>
                                    ),
                                },
                            };
                            dispatch(enqueueSnackbar(newNotification));
                            navigate("/", { replace: true });
                        }
                    });

            } 
        } else {
            //Something went wrong go home
            navigate("/", { replace: true });
        }
    }, [hvID,online,authToken]);
    const plantID = usePlantIDFromVisitID(heatVessel ? heatVessel.visitID : null);
    useEffect(() => {
        if (plantID) {
            dispatch(GetPlantMachines(plantID));
            dispatch(GetPlantMachineVessels(plantID));
            dispatch(GetPlantMachineFlutes(plantID));
        }
    }, [plantID])
    //Get Request Status
    //If Request Status complete==true and heatVessel==null then we have a bad id or cannot pull offline data. 
    const handleDel = () => {
        const visitID = heatVessel.visitID;
        dispatch(ConfirmAction("Do you want to delete this HeatVessel Survey?", DeleteHeatVessel(heatVessel), null,
            (confirmed) => {
                if (confirmed) {
                    navigate("/VisitOverview/" + visitID, { replace: true });
                }
            }));
    }
    const handleClose = () => {
        navigate(-1);
    }
    const handleLineChange = newLineID => {
        //actionType, name, data, newValue
        dispatch(UpdateAction(constants.Actions.HeatVessel, 'lineID', heatVessel, newLineID));
        dispatch(CreateHV_Temps(heatVessel.id, newLineID));
        dispatch(SetHeatVesselName(heatVessel, newLineID));
    }
    const handlePrint =()=>{
        navigate('/HeatVesselPrint/'+heatVessel.id);
    }
    if (!heatVessel) {
        return <div>Loading Heat Vessel Data from Database.</div>;  
    }
    const getHVChildIDs = (state) => {
        return state.HeatVesselTemps
        .filter(hvt => hvt.heatVesselSurveyID === hvID && !hvt.deleted)
        .map((v, ind, array) => v.id);
    };
    const toolOptions = [<option value="Temperature Crayon" key={"Temperature Crayon"}>Temperature Crayon</option>,
        <option value="Probe" key={"Probe" }>Probe</option>];
    return (
        <>
        <DataForm actionType={constants.Actions.HeatVessel} data={heatVessel}>
            <Grid container spacing={3}>
            <Grid item xs={4} sm={4} align="left">
            <Button
                variant="contained"
                startIcon={<PrintIcon />}
                onClick={handlePrint}>
            Print
                        </Button>
                    
                    </Grid>
                <Grid item xs={4} sm={4} >
                    <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<DeleteIcon />}
                        onClick={handleDel}
                    >Delete
                        </Button>
                </Grid>
                <Grid item xs={4} sm={4} align="right">
                    <Button variant="contained" startIcon={<CloseIcon />} onClick={handleClose}>
                    Close
                        </Button>
                </Grid>
                <Grid item md={4}>Heat Vessel Survey</Grid>
                <Grid item md={4}><PlantLink plantID={plantID} /></Grid>
                <Grid item md={4}><DataDate name={'date'} label={'Date'} TextFieldProps={{ size: 'small' }}   /></Grid>
                { boilers.map(b=>(<React.Fragment key={'boiler_'+b.id}>
                <Grid item xs={3}>
                    <TextField label="Boiler Model" defaultValue={b.model} InputLabelProps={{ shrink: true }} InputProps={{ readOnly: true }}  variant="outlined" size='small' />
                </Grid>
                <Grid item xs={3}>
                    <TextField label="Boiler Description" defaultValue={b.ballastSystem} InputLabelProps={{ shrink: true }} InputProps={{ readOnly: true }}  
                    variant="outlined" size='small' fullWidth />
                </Grid>
                <Grid item xs={2}>
                    <TextField label="HP" defaultValue={b.width} InputLabelProps={{ shrink: true }} InputProps={{ readOnly: true }}  variant="outlined" size='small' />
                </Grid>
                <Grid item xs={2}>
                    <TextField label="Target Pressure" defaultValue={b.zone1Pressure} InputLabelProps={{ shrink: true }} InputProps={{ readOnly: true }}  variant="outlined" size='small' />
                </Grid>
                <Grid item xs={2}>
                    <TextField label="High Pressure Receiver " defaultValue={b.zone2Pressure} InputLabelProps={{ shrink: true }} InputProps={{ readOnly: true }}  variant="outlined" size='small' />
                </Grid>
                </React.Fragment>))
                }
                <Grid item xs={6}><DataNumber name={'actualPressure'} label={'ActualPressure'} type="int"
                        InputProps={{ shrink: true, style: { width: '200px' } }} TextFieldProps={{ size: 'small' }} />
                </Grid>
                <Grid item xs={6}><DataSelect name={'tool'} label={'Tool'}
                            size="small" options={toolOptions} />
                </Grid>
                <Grid item lg={12}>
                    <VesselTempsGrid plantID={plantID} heatVessel={heatVessel}
                        onLineChange={handleLineChange} printMode={props.printMode} />
                </Grid>
                
            </Grid>
        </DataForm>
        </>
        );
}
export default HeatVesselView;