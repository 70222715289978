import Constants from '../constants';
import produce from "immer";
import { UpdateArray,UpdateArrayRange } from '../misc/ArrayFuncs';
const initDataState = [];

const DesiredSpeedImprovements = (state = initDataState, action) => {
    switch (action.type) {
        case Constants.Actions.DesiredSpeedImprovement_ReceiveByPlant:
            return UpdateArrayRange(action.data, state);
        case Constants.Actions.DesiredSpeedImprovement_Update:
            return UpdateArray(action.payload.update, state);
        case Constants.Actions.DesiredSpeedImprovement_Update_Commit:
            return UpdateArray(action.payload?.data, state);
        case Constants.Actions.DesiredSpeedImprovement_Update_Rollback:
            return UpdateArray(action.meta.data.current, state);
        case Constants.Actions.DesiredSpeedImprovement_Add:
            return [...state, action.payload];
        case Constants.Actions.DesiredSpeedImprovement_Add_Rollback:
            const dsiInd = state.findIndex(dsi => dsi.id === action.meta.newDSI.id);
            if (dsiInd !== -1) {
                return produce(state, pd => { pd.splice(dsiInd, 1) });
            } else {
                return state;
            }
        case Constants.Actions.DesiredSpeedImprovement_Add_Commit:
            return UpdateArray(action.payload?.data, state);
        case Constants.Actions.DesiredSpeedImprovement_Delete:
            return UpdateArray(produce(action.payload, d => {
                d.deleted = true;
            }), state);
        case Constants.Actions.DesiredSpeedImprovement_Delete_Commit:
            return state;
        case Constants.Actions.DesiredSpeedImprovement_Delete_Rollback:
            return UpdateArray(action.meta.delDSI, state);
        case Constants.Actions.DesiredSpeedImprovement_ClearData:
            return [];
        default:
            return state;
    };
}
export default DesiredSpeedImprovements;