import Constants from '../constants';
const constants = Constants;

export const AuthenticationSuccess = (authResp) => {
    return { type: constants.Actions.Auth.Success, data: authResp };
}
export const Logout = () => {
    return { type: constants.Actions.Auth.Logout };
}
export const GoOffline = () => {
    return { type: constants.Actions.Auth.GoOffline };
}
