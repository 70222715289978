import Constants from '../../constants';
//Libraries
import React, { useEffect, useState, useCallback } from 'react';
import produce from 'immer';
import ImageAttachmentButton from '../ImageAttachment/ImageAttachmentButton';

const constants=Constants;
export const GridImageIcon = ({value,keyItem,onChange})=>{
    const handleChange=(imageID)=>{
        
        onChange(produce(keyItem,d=>{
            d.imageID=imageID;
        }))
    }

    return <ImageAttachmentButton attachmentID={value} onChange={handleChange} />;
}