import Constants from '../../constants';
//Modules
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
//MUI
import { GridDateFilter, DateFilterLogic, UpdateFilterList } from '../MetaData/GridDateFilter';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MUIDataTable from "mui-datatables";
//Compontents
import CustomSelectToolbar from '../CustomSelectToolbar';
//Actions
import { ConfirmAction } from '../../actions/ConfirmActionActions';
import { DeleteIngredientVerificationByID } from '../../actions/IngredientVerificationActions';
import { DeleteHeatVesselByID } from '../../actions/HeatVesselActions';
import { DeleteGlueRollByID } from '../../actions/GlueRollActions';
import { DeleteGlueLineByID } from '../../actions/GlueLineActions';
import { selectBaselinesByPlantId } from '../../selectors/BaselineSelectors';
import { DeleteChecklistByID } from '../../actions/ChecklistActions';
//Misc
import { useVisitSurveys } from './VisitSurveys';
import { DeleteThermalByID } from '../../actions/ThermalImageActions';
import { selectContImproveByVisitID } from '../../selectors/ContinuouseImprovement';
import { DeleteContImprove, GetContImproveByVisitID } from '../../actions/ContinuousImprovementAction';
import { getStore } from '../../core';
const constants = Constants;
const DateView = props => {
    if (props.value === "0001-01-01T00:00:00") {
        return null;
    }
    const d = new Date(props.value);
    return <div>{d.toLocaleDateString()}</div>;
};
const formatnum = (num) => {
    let value = num.toFixed(2)
    return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
export const ContinuosImpGrid = props => {
    const baselines = useSelector(s => selectBaselinesByPlantId(s, props.plantID));
    const ContImprove = useSelector(s => selectContImproveByVisitID(s, props.visitID));
    const store = getStore();
    const state = store.getState();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectedRows, setSelectedRows] = useState([]);
    const [ciList, setciList] = useState([]);
    const surveys = useVisitSurveys(props.visitID);
    const [asyncClickOpen, setClickOpen] = useState(false);

    
    useEffect(() => {
        if (props.plantID) {
            dispatch(GetContImproveByVisitID(props.visitID))
        }
    }, [props.plantID]);
    useEffect(() => {

        if (ContImprove && baselines) {
            ContImprove.forEach(element => {                
                const data = baselines.find((line) => line.id == element.baselineID)

                let totalSAVE = 0

                if (element.newPerWaste > 0 && element.wasteTotalCount > 0) {
                    totalSAVE += element.totalWasteSavings
                }
                if (element.proNewLinealPerHr > 0 && element.proTotalCount > 0) {
                    totalSAVE += element.totalProductionSaving
                }
                if ((element.consLbPerMsf > 0 && element.consCostLb > 0)) {
                    totalSAVE += element.totalConsumptionSavings
                }
                if (element.hoursOfTraining > 0) {
                    totalSAVE += element.totalTrainingCostSaved
                }
                if (element.hoursOfDownTime > 0) {
                    totalSAVE += element.totalDownTimeSaved
                }

                // let value = element.totalWasteSavings + element.totalProductionSaving + element.totalConsumptionSavings + element.totalTrainingCostSaved + element.totalDownTimeSaved
                element.totalSaving = formatnum(totalSAVE)
                if (data) {
                    element.baselineName = data.baselineName + ` ${data.baselineStartDate !== '' ? ': (From ' + formatDate(data.baselineStartDate) + ' to ' + formatDate(data.baselineEndDate) + ')' : ''}`    
                }
                
            });
            setciList(ContImprove) 
        }
        
        
    }, [ContImprove, baselines]);
    
    
    function formatDate(date) {
        const month = new Date(date).getMonth() + 1; // Month is zero-based, so we add 1
        const day = new Date(date).getDate();
        const year = new Date(date).getFullYear() % 100; // Get last two digits of the year
        const formattedDate = `${month}/${day}/${year}`;

        return formattedDate;
    }

   

    const ClickOpen = () => {
        if (selectedRows.length > 0) {
            navigate('/ContinuousImprovement/' + props.visitID + '/' + ciList[selectedRows[0]].id);
        } else {
            alert("Select Cointinuous improvement From List");
        }
    };

    const ClickDelete = () => {
        dispatch(ConfirmAction("Do you want to delete this Continuous Improvement?", DeleteContImprove(ciList[selectedRows[0]]), null));
        setSelectedRows([]);
    };
    const columns = [
        {
            name: "createdDate", label: "Date",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <DateView value={value} />
                    );
                },
                filter: true,
                filterType: 'custom',
                customFilterListOptions: {
                    update: UpdateFilterList
                },
                filterOptions: {
                    logic: DateFilterLogic,
                    display: (filterList, onChange, index, column) => <GridDateFilter filterList={filterList} onChange={onChange} index={index} column={column} />
                }
            }
        },
        { name: "baselineName", label: "Baseline Name" },
        { name: "totalSaving", label: "Total Saving" },
        
    ];
    const rowSelect = (rowsSelectedData, allRows, rowsSelected) => {
        setSelectedRows(rowsSelected);
    };
    const ClickInfo = () => {
        navigate('/ContinuousImprovementInfo/' + ciList[selectedRows[0]].id);
    };
    const options = {
        filterType: 'checkbox', selectableRows: 'single', selectableRowsOnClick: true, print: false,
        download: false,
        fixedHeader: true,
        responsive: 'standard',
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
            return <CustomSelectToolbar selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows} clickEdit={ClickOpen} clickInfo={ClickInfo} clickDelete={ClickDelete} />;
        },
        onRowSelectionChange: rowSelect,
        rowsSelected: selectedRows,
        sortOrder: { name: 'createdDate', direction: 'desc' },
        setRowProps: () => ({
            onDoubleClick: (row) => {
                if (selectedRows.length == 0) {
                    const targetID = row.currentTarget.id;
                    const dataRow = parseInt(targetID.substring(targetID.lastIndexOf('-') + 1));
                    if (!isNaN(dataRow)) {
                        setSelectedRows([dataRow]);
                        setClickOpen(true);//dislike this but requred
                        //;
                    }
                } else {
                    ClickOpen();
                }
            }
        })
    };
    return (
        <Card>
            <CardContent>
                <MUIDataTable
                    title={"Continuous Improvement"}
                    data={ciList}
                    columns={columns}
                    options={options}
                />
            </CardContent>
        </Card>
    );
};