import Constants from '../constants';
import produce from "immer";
import { UpdateArray } from '../misc/ArrayFuncs';
const initDataState = [];
const LastSync = (state = initDataState, action) => {
    switch (action.type) {
        case Constants.Actions.LastSync.AddSync:
            const d = new Date();
            const newSync = {id:action.id, timeStamp:d.toJSON(),entity:action.entity}
            return UpdateArray(newSync, state);
        case Constants.Actions.LastSync.ClearData:
            return initDataState;
        default:
            return state;
    }
}
export default LastSync;