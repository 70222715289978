
const GetEntity = (url, online, authToken) => new Promise((resolve, reject) => {
    if (online) {
        if (!authToken) {
            return resolve({ complete: false, valid: false, data: null, error: null });
        }
        let fetchTask = fetch(url, {
            headers: { 'Authorization': 'Bearer ' + authToken }
        })
            .then(response => {
                if (response.status === 404) {
                    reject({ complete: true, valid: false, data: null, error: 'Not Found' });
                    return null;
                }
                return response.json();
            })
            .then(data => {
                if (data) {
                    resolve({ complete: true, valid: true, data: data, error: null });
                }
            })
            .catch(error => {
                return reject({ complete: true, valid: false, data: null, error: error });
            })
    } else {
        return reject({ complete: true, valid: false, data: null, error: 'Offline' });
    }
});
export default GetEntity;