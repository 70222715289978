import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { GetAttachment, DeleteAttachment } from '../../actions/AttachmentActions'
import { doResync } from '../../misc/HelperFuncs';
const ImagePrintView = props => {
    const dispatch = useDispatch();
    const attachment = useSelector(state => state.Attachments.find(a => a.id === props.attachmentID && !a.deleted), shallowEqual);
    const pattern = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$', 'i');
    if ((!attachment || doResync(attachment.lastSyncDate)) && pattern.test(props.attachmentID)) {
        dispatch(GetAttachment(props.attachmentID));
    }
    let imgStyle = {
        maxWidth: '100%',
        height: 'auto'
    };
    if (props.imageStyle) {
        imgStyle = props.imageStyle
    }
    let retVal = null;
    if (attachment) {
        retVal = <img src={"data:" + attachment.mime + ";base64," + attachment.data} style={imgStyle} />;
    }
    return retVal
};
export default ImagePrintView;
