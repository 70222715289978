import Constants from '../constants';
import produce from "immer";
import { UpdateArray,UpdateArrayRange } from '../misc/ArrayFuncs';
const initDataState = [];
const Checklist = (state = initDataState, action) => {
    switch (action.type) {
        case Constants.Actions.Checklist.Add:
            return UpdateArray(action.payload, state);
        case Constants.Actions.Checklist.Add_Rollback:
            const clID = state.findIndex(hv => hv.id === action.meta.newCL.id);
            if (clID !== -1) {
                return produce(state, pd => { pd.splice(clID, 1) });
            } else {
                return state;
            }
        case Constants.Actions.Checklist.Add_Commit:
            return UpdateArray(action.payload?.data, state);
        case Constants.Actions.Checklist.GetByID:
            return UpdateArray(action.data, state);
        //case Constants.Actions.Checklist.Update:
        //    //Action will have the full plant data
        //    return state.map((p, ind) => {
        //        if (p.id === action.payload.id) {
        //            return produce(p, pd => { pd[action.payload.name] = action.payload.newValue });
        //        } else { return p; }
        //    });

        //case Constants.Actions.Checklist.Update_Commit:
        //    return UpdateArray(action.payload?.data, state);
        //case Constants.Actions.Checklist.Update_Rollback:
        //    //Action will have the full plant data
        //    return state.map((p, ind) => {
        //        if (p.id === action.meta.updatePayload.id) {
        //            return produce(p, pd => { pd[action.meta.updatePayload.name] = action.meta.updatePayload.oldValue });
        //        } else { return p; }
        //    });
        case Constants.Actions.Checklist.Delete:
            return UpdateArray(produce(action.payload, d => {
                d.deleted = true;
            }), state);
        case Constants.Actions.Checklist.Delete_Rollback:
            return UpdateArray(action.meta.delCL, state);
        case Constants.Actions.Checklist.ClearData:
            return initDataState;
        case Constants.Actions.Checklist.GetByVisitID:
            return UpdateArrayRange(action.data, state);
        default:
            return state;
    }
}
export default Checklist;