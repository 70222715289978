import Constants from '../../constants';
import React, { useEffect, useContext, useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
const MachineSelect = props => {
    //props machines, type, onMachineChange(glMachine,type,newMachineID,oldMachineID), glMachine
    const machineSelID = 'ms_MachineSel_' + props.type + '_' + props.glMachine.order;
    let machineID = props.glMachine.sfMachineID;
    if (props.type == 4) {
        machineID = props.glMachine.guMachineID;
    }
    const onChange = e => {
        if (e.target.value === machineID)
            return;
        props.onMachineChange(props.glMachine, props.type, e.target.value, machineID);
    };
    const options = props.machines
        .filter(m => m.type == props.type && !m.deleted)
        .map(m =>
        <option
            key={m.id}
            value={m.id}
        >
            {m.name}
        </option>
    );
    return <FormControl variant="outlined" size="small">
        <InputLabel htmlFor={machineSelID} shrink={true}>Machine</InputLabel>
        <Select
            native
            name={'Machine'}
            value={machineID}
            onChange={onChange}
            id={machineSelID}
            label="Machine"
        >
            {options}
        </Select>
    </FormControl>;
};
export default MachineSelect;
