import Constants from '../../constants';
//Libraries 
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
//MUI
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Checkbox from '@mui/material/Checkbox';
import MUIDataTable from "mui-datatables";
//components
import { VesselAutocomplete, VesselName } from '../MetaData/VesselViews'
import AddCustomToolbar from "../AddCustomToolbar";
import CustomSelectToolbar from '../CustomSelectToolbar';
//Actions
import { ReOrderMachineVessels, ChangeMachineVesselOrder, DeleteMachineVessel, AddMachineVessel } from '../../actions/MachineVesselsActions'
import { SelMachineVesselsByMachineID,SelMachineByID} from '../../selectors/PlantSelectors';
export const MachineVesselsGridView = props => {
    //const classes = useStyles();
    const dispatch = useDispatch();
    const vessels=useSelector(s=>SelMachineVesselsByMachineID(s,props.machineID));
    const machine = useSelector(state => SelMachineByID(state,props.machineID))
    const [modalOpen, setModal] = useState(false);
    const initMachineFlute = { flute: 0 };
    const [selectedRows, setSelectedRows] = useState([]);
    useEffect(() => {
        if (vessels) {
            //Check If they Need ReOrder
            let testOrder = 0
            const reOrder = vessels.reduce((acc, mv) => {
                if (acc)
                    return acc;
                if (mv.order != testOrder) {
                    return true;
                }
                testOrder++;
                return false;
            }, false);
            if (reOrder) {
                dispatch(ReOrderMachineVessels(props.machineID));
            }
        }
    },[vessels])
    const columns = [{ name: "vesselID", label: "Vessel", options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
            return (
                <VesselName id={value} />
            );
        },
    }}];
    const ClickDelete = () => {
        dispatch(DeleteMachineVessel(vessels[selectedRows[0]]));
        setSelectedRows([]);
    };
    const ClickAdd = (val) => {
        const newOrder = vessels.reduce((acc, val) => acc + 1, 0);
        dispatch(AddMachineVessel(props.machineID, val.id, newOrder));
    };
    const rowSelect = (rowsSelectedData, allRows, rowsSelected) => {
        setSelectedRows(rowsSelected);
    };
    const ChangeOrder = (isUp) => {
        dispatch(ChangeMachineVesselOrder(vessels[selectedRows[0]], !isUp));
        let newRow = selectedRows[0];
        if (!isUp) {
            newRow += 1;
        } else {
            newRow -= 1;
        }
        if (newRow >= 0 && newRow < vessels.length) {
            setSelectedRows([newRow]);
        }
    };
    const options = {
        filterType: 'checkbox', selectableRows: 'single', selectableRowsOnClick: true,
        print: false, pagination: false, download: false, searchOpen: false, sort: false,
        sortFilterList: false, viewColumns: false, filter: false, search: false,
        fixedHeader: true,
        responsive: 'standard',
        customToolbar: () => {
            return (
                <AddCustomToolbar onClick={() => { setModal(true); }} />
            );
        },
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
            return <CustomSelectToolbar selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows}
                clickDelete={ClickDelete} changeOrder={ChangeOrder} />;
        },
        onRowSelectionChange: rowSelect,
        rowsSelected: selectedRows,
        textLabels: {
            selectedRows: { text: '' }
        }
    };


    return (
        
        <Card >
            <CardContent>
                <MUIDataTable
                    title={"Vessels"}
                    data={vessels}
                    columns={columns}
                    options={options}
                />
                <MachineVesselAddDialog modalOpen={modalOpen} closeModal={() => { setModal(false); }}
                    onSubmit={ClickAdd} type={machine.type} />
            </CardContent>
            </Card>
           
    );
}
export const MachineVesselAddDialog = props => {
    const [vessel, setVessel] = useState({});
    const [errorMsg, setErrorMsg] = useState(null);
    const handleSubmit = () => {
        if (vessel?.id) {
            props.closeModal();
            props.onSubmit(vessel);
        } else {
            setErrorMsg("Invalid Vessel Selected");
        }
    };
    useEffect(() => setVessel({}), [props.modalOpen]);
    return (
        <Dialog open={props.modalOpen} onClose={props.closeModal} >
            <DialogTitle >Add Machine Vessel</DialogTitle >
            <DialogContent>
                <div>
                    <VesselAutocomplete onChange={(val) => { setVessel(val); setErrorMsg(null); }} type={props.type} />
                    <p style={{ color: 'red', fontWeight: 'bold' }}>{errorMsg}</p>
                </div>
            </DialogContent>
            <DialogActions>
                <Button type="submit" onClick={handleSubmit}>Save</Button>
                <Button onClick={props.closeModal}>Cancel</Button>
            </DialogActions>
        </Dialog >
    );
}