import Constants from '../../constants';
//Libraries
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import produce from "immer";
//MUI 
import makeStyles from '@mui/styles/makeStyles';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
//Compontents
import { KeyItemCheckBox } from '../KeyItems/KeyItemCheckBox';
import ImagePrintView from '../ImageAttachment/ImagePrintView';

//Actions
import { UpdateChecklistItem } from '../../actions/ChecklistActions';
const constants = Constants;
const useStyles = makeStyles((theme) => ({
    itemText: {
        fontWeight: 'normal',
        color: 'black',
        verticalAlign: 'bottom'
    },
    thinLine: {
        width:'80%',
        
        marginLeft: 5
    },
    noBreak: {
        pageBreakInside:'avoid'
    }
}));
const ChecklistItemsPrint = props => {
    const itemIDs = useSelector(state => state.ChecklistItem
        .filter(i => i.checklistID === props.checklist.id && i.groupID == props.group.id && !i.deleted)
        .sort((a, b) => a.order - b.order)
        .map(i => i.id)
        , shallowEqual);
    const itemMap = (id) => {
        return <ItemPrint itemID={id} checklist={props.checklist} />
    }
    const itemsJSX = itemIDs.map(itemMap);
    return <>{itemsJSX}</>;
};

export const ItemPrint = props => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const item = useSelector(state => state.ChecklistItem.find(i => i.id == props.itemID), shallowEqual);
    let imageDisplay = null;
    if (item.imageID && item.imageID != constants.EmptyGuid) {
        imageDisplay = <Grid item xs={12}><ImagePrintView attachmentID={item.imageID} imageStyle={{maxHeight:'150px'}} /></Grid>
    }
    let notesDisplay = <hr className={classes.thinLine} />
    if (item.notes) {
        notesDisplay = <Grid item xs={12}>
            <TextField value={item.notes ? item.notes : ''}
                style={{ width: '80%' }} multiline readOnly size="small" variant="standard"
                sx={{ '& textarea': {fontSize:'.7em'}}}
            />
        </Grid>;
    }
    return (
        <Grid container className={classes.noBreak}>
            <Grid item xs={1}>
                <Checkbox
                    checked={item.noBreak}
                    inputProps={{ 'aria-label': 'controlled' }}
                    readOnly
                    size="small"
                    sx={{ paddingTop:'0',paddingBottom:'0'  }}
                />
            </Grid>
            <Grid item xs={11}>
                <span className={classes.itemText}>{item.name}</span>
            </Grid>
            {notesDisplay}
            { imageDisplay }
        </Grid>);
}

export default ChecklistItemsPrint;