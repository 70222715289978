import React, { useState} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { AddVessel } from '../../actions/MetaDataActions';
import { v4 as uuidv4 } from 'uuid';
export const VesselAutocomplete = props => {
    const dispatch = useDispatch();
    const vessels = useSelector(state => state.MetaData.Vessels
        .filter(v => v && v.type == props.type && isNaN(Number(v.name))));
    const [value, setValue] = useState(null);
    const filter = createFilterOptions();
    const newVessel=(name) => {
        return { name: name, type: props.type, id: uuidv4() };
    }
    return (
        <Autocomplete
            value={value}
            onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                    const firstV = vessels.find(v => v.name.startsWith(newValue));
                    if (firstV) {
                        setValue(firstV);
                        props.onChange(firstV);
                    }
                    else
                        setValue(null);
                } else if (newValue && newValue.inputValue) {
                    
                    // Create a new value from the user input
                    const newV = newVessel(newValue.inputValue)
                    setValue(newV);
                    dispatch(AddVessel(newV));
                    props.onChange(newV);
                } else {
                    
                    setValue(newValue);
                    props.onChange(newValue);
                }
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);

                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                    filtered.push({
                        inputValue: params.inputValue,
                        name: `Add "${params.inputValue}"`,
                    });
                }
                console.log("VesselAC Filtered", filtered)
                return filtered;
            }}
            //onBlur={handleOnBlur}
            selectOnFocus
            clearOnBlur={ true }
            handleHomeEndKeys
            id="vessel-autocomplete"
            options={vessels}
            getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                    return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                    return option.inputValue;
                }
                // Regular option
                return option.name;
            }}
            renderOption={(props,option) => <li {...props}>{option.name}</li>}
            freeSolo
            renderInput={(params) => (
                <TextField {...params} label="Vessel"  />
            )}
        />
        );
}
export const VesselName = props => {
    const vessel = useSelector(state => state.MetaData.Vessels.find(v => v && v.id == props.id));
    let vName = '';
    if (vessel) {
        vName = vessel.name;
    }
    return (<div>{vName}</div>);
}