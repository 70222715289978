import Constants from '../constants';
import { v4 as uuidv4 } from 'uuid';
import { FetchErrorLogger, FetchCatchHandler } from '../middlewares';
export const GetPlantDSI = (plantID) => {
    console.log('DesiredSpeedImprovementDataActions.js GET DesiredSpeedImprovement Called', plantID);
    return (dispatch, getState) => {
        //offline.online
        const state = getState();
        if (state.offline.online) {
            let fetchTask = fetch(Constants.URLs.DesiredSpeedImprovement + '?plantID=' + plantID, { headers: { 'Authorization': 'Bearer ' + state.Auth.token } })
                .then(FetchErrorLogger("GetPlantDSI", plantID, dispatch))
                .then(response => response.json())
                .then(DSI => {
                    console.log("Got Plant DSI", DSI);
                    dispatch({ type: Constants.Actions.DesiredSpeedImprovement_ReceiveByPlant, data: DSI, plantID: plantID });
                })
                .catch(FetchCatchHandler("GetPlantDSI", plantID, dispatch))
        }
    }
}
export const AddDSI = (newDSI) => {
    newDSI.id = uuidv4();
    return {
        type: Constants.Actions.DesiredSpeedImprovement_Add,
        payload: newDSI,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.DesiredSpeedImprovement, method: 'POST', body: JSON.stringify(newDSI), headers: { 'content-type': 'application/json' }
                },
                commit: {
                    type: Constants.Actions.DesiredSpeedImprovement_Add_Commit, meta: { newDSI }
                },
                rollback: {
                    type: Constants.Actions.DesiredSpeedImprovement_Add_Rollback, meta: { newDSI }
                }
            }
        }
    }
};
export const UpdateDSI = (updDSI, curDSI) => {
    const data = { update: updDSI, current: curDSI }
    console.log('put check __________________________________________________________________________  55');
    return {
        type: Constants.Actions.DesiredSpeedImprovement_Update,
        payload: data,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.DesiredSpeedImprovement + updDSI.id, method: 'PUT', body: JSON.stringify(updDSI), headers: { 'content-type': 'application/json' }
                },
                commit: {
                    type: Constants.Actions.DesiredSpeedImprovement_Update_Commit, meta: { data }
                },
                rollback: {
                    type: Constants.Actions.DesiredSpeedImprovement_Update_Rollback, meta: { data }
                }
            }
        }
    }
};
export const DeleteDSI = (delDSI) => {
    return {
        type: Constants.Actions.DesiredSpeedImprovement_Delete,
        payload: delDSI,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.DesiredSpeedImprovement + delDSI.id, method: 'DELETE'
                },
                commit: {
                    type: Constants.Actions.DesiredSpeedImprovement_Delete_Commit, meta: { delDSI }
                },
                rollback: {
                    type: Constants.Actions.DesiredSpeedImprovement_Delete_Rollback, meta: { delDSI }
                }
            }
        }
    };
};
export const ClearData = () => {
    return {
        type: Constants.Actions.DesiredSpeedImprovement_ClearData
    };
};