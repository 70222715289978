import Constants from '../../constants';
import React from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { enqueueSnackbar, closeSnackbar } from '../../actions/NotificationActions';
const constants=Constants;
const WallTypeSelect = props => {
    const dispatch = useDispatch();
    const { sfCount, guCount } = useSelector(s => {
        let sfCount = 0;
        let guCount = 0;
        s.Machines
            .filter(m => m.lineID === props.lineID && (m.type === 4 || m.type === 1) && !m.deleted)
            .forEach(m => {
                if (m.type === 1) {
                    sfCount++;
                } else {
                    guCount++;
                }
            });
        return { sfCount: sfCount, guCount: guCount };
    });
    if (props.wall && props.wall !== -1) {
        const WallTypeName = Object.keys(constants.Enums.WallType).find(key => constants.Enums.WallType[key] === parseInt(props.wall));
        return <TextField
            id="wallType_Read_Only"
            label="Wall Type"
            defaultValue={WallTypeName}
            InputProps={{
                readOnly: true,
            }}
            variant="outlined"
            {...props.TextFieldProps}
        />;
    }
    const options = Object.keys(Constants.Enums.WallType).filter(k => k != 'Error').map(key =>
        <option
            key={key}
            value={Constants.Enums.WallType[key]}
        >
            {key}
        </option>
    );
    options.unshift(<option key={'-1'} value={-1}>Select Type</option>);
    //return <LineSelection onLineChange={props.onLineChange} plantID={props.plantID} />
    const wallSelectID = 'ms_Wall_Sel'
    const onWallChange = e => {
        if (!e.target.value > 0)
            return;
        if (e.target.value == props.wall) {
            return;
        }
        if (e.target.value > sfCount || e.target.value > guCount) {
            //Notification
            const newNotification = {
                message: 'Invalid Wall Type Selected. Not enough SF and GU setup on Line',
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'error',
                    action: key => (
                        <Button onClick={() => closeSnackbar(key)}>dismiss</Button>
                    ),
                },
            };
            dispatch(enqueueSnackbar(newNotification));
            return
        }
        props.onWallChange(e.target.value);
    };
    return <FormControl variant="outlined" {...props.TextFieldProps}>
        <InputLabel htmlFor={wallSelectID} shrink={true}>Line</InputLabel>
        <Select
            native
            name={'Wall'}
            value={props.wall}
            onChange={onWallChange}
            id={wallSelectID}
            label="Wall Type"
        >
            {options}
        </Select>
    </FormControl>;
};
export default WallTypeSelect;