import React from 'react'
import Constants from '../../constants';
import makeStyles from '@mui/styles/makeStyles';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { useFormikContext } from 'formik';
export const UnitSelectFormik = props => {
    //const classes = useStyles();
    const { values,
        errors,
        handleChange,
        handleBlur,
        setFieldValue
    } = useFormikContext();
   
    const onChange = (e) => {
        const newNum = Number(e.target.value);
        if (!isNaN(newNum)) {
            setFieldValue('unit', newNum);
        }
    };
    let retVal = null;
    //StepType: { Water: 0, Starch: 1, Caustic_Percentage: 2, Caustic_Dry: 3, Borax: 4, Borax_Liquid: 5, Starch_Specialty: 6, Liquid_Specialty: 7, Penetrant: 8, Resin: 9, Heat: 10, Error: -1 },
    if (values['stepType'] >= 0 && values['stepType'] !== 999 && values['stepType'] !== 11) {
        const options = [];
        let value = '';
        switch (values['stepType']) {
            case 1:  case 3: case 4: case 6:
                value = 'lbs';
                break;
            case 0: case 2: case 5: case 9: //Water,Borax Liquid,Speciality Liquid,Resin
                options.push(<option key={0} value={0}>lbs</option>);
                options.push(<option key={5} value={5}>gal</option>);
                break;
            case 7:
                options.push(<option key={0} value={0}>lbs</option>);
                options.push(<option key={1} value={1}>oz</option>);
                options.push(<option key={5} value={5}>gal</option>);
                break;
            case 8://Penetrant
                
                options.push(<option key={1} value={1}>oz</option>);
                break;
            case 10://Heat
                //F or C
                options.push(<option key={3} value={3}>F</option>);
                options.push(<option key={4} value={4}>C</option>);
                break;
            default:
        }
        if (options.length > 0) {
            retVal = (<FormControl error={errors['unit']} variant="outlined">
                <InputLabel htmlFor="name-native-error">Unit</InputLabel>
                <Select
                    native
                    name={props.name}
                    value={values['unit']}
                    onChange={onChange}
                    label="Unit"
                >
                    {options}
                </Select>
                <FormHelperText>{errors['unit']}</FormHelperText>
            </FormControl>);
        } else {
            retVal = <TextField
                variant="outlined"
                id="unit-read-only"
                label="Unit"
                defaultValue={ value}
                InputProps={{
                    readOnly: true,
                }}
            />;
        }
        
    }
    return retVal;
}
export const UnitView = props => {
    const key = Object.keys(Constants.Enums.FormulaUnit).find(key => Constants.Enums.FormulaUnit[key] == props.value);

    return <>{key}</>;
};