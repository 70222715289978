import Constants from '../constants';
import { v4 as uuidv4 } from 'uuid';
import { FetchErrorLogger, FetchCatchHandler } from '../middlewares';
import { AddMachine } from './MachineActions';
const constants=Constants;
export const GetPlantLines = (plantID) => {
    console.log('LinesDataActions.js GET Lines Called', plantID);
    return (dispatch, getState) => {
        //offline.online
        const state = getState();
        if (state.offline.online) {
            let fetchTask = fetch(Constants.URLs.Lines + '?plantID=' + plantID, { headers: { 'Authorization': 'Bearer ' + state.Auth.token } })
                .then(FetchErrorLogger("GetPlantLines", plantID, dispatch))
                .then(response => response.json())
                .then(cad => {
                    console.log("Got Plant Line", cad);
                    dispatch({ type: Constants.Actions.Lines_ReceiveByPlant, data: cad, plantID: plantID });
                })
                .catch(FetchCatchHandler("GetPlantLines", plantID, dispatch))
        }
    }
}
export const GetLine = (lineID) => {
    console.log('LinesDataActions.js GET Line Called', lineID);
    return (dispatch, getState) => {
        //offline.online
        const state = getState();
        if (state.offline.online) {
            let fetchTask = fetch(Constants.URLs.Lines + lineID, { headers: { 'Authorization': 'Bearer ' + state.Auth.token } })
                .then(FetchErrorLogger("GetLine", lineID, dispatch))
                .then(response => response.json())
                .then(cad => {
                    dispatch({ type: Constants.Actions.Lines_GetByID, data: cad });
                })
                .catch(FetchCatchHandler("GetLine", lineID, dispatch))
        }
    }
}
export const AddLine = (newLine) => {
    return (dispatch,getState)=>{
        newLine.id = uuidv4();
        dispatch({
            type: Constants.Actions.Lines_Add,
            payload: newLine,
            meta: {
                offline: {
                    effect: {
                        url: Constants.URLs.Lines, method: 'POST', body: JSON.stringify(newLine), headers: { 'content-type': 'application/json' }
                    },
                    commit: {
                        type: Constants.Actions.Lines_Add_Commit, meta: { newLine }
                    },
                    rollback: {
                        type: Constants.Actions.Lines_Add_Rollback, meta: { newLine }
                    }
                }
            }
        });
        //Create Default Setup
        dispatch(AddMachine({id:uuidv4(),order:0,lineID:newLine.id,type:constants.Enums.MachineType.SF,name:"SF2"}));
        dispatch(AddMachine({id:uuidv4(),order:1,lineID:newLine.id,type:constants.Enums.MachineType.SF,name:"SF1"}));
        dispatch(AddMachine({id:uuidv4(),order:2,lineID:newLine.id,type:constants.Enums.MachineType.Web,name:"Web1"}));
        dispatch(AddMachine({id:uuidv4(),order:3,lineID:newLine.id,type:constants.Enums.MachineType.Web,name:"Web2"}));
        dispatch(AddMachine({id:uuidv4(), order:4, lineID:newLine.id, type:constants.Enums.MachineType.Triple_Stack, name:"Triple Stack"}));
        dispatch(AddMachine({id:uuidv4(), order:5, lineID:newLine.id, type:constants.Enums.MachineType.GU, name:"GUT"}));
        dispatch(AddMachine({id:uuidv4(), order:6, lineID:newLine.id, type:constants.Enums.MachineType.GU, name:"GUB"}));
        dispatch(AddMachine({id:uuidv4(), order:7, lineID:newLine.id, type:constants.Enums.MachineType.HotPlates, name:"HotPlates"}));
        dispatch(AddMachine({id:uuidv4(), order:8, lineID:newLine.id, type:constants.Enums.MachineType.Shear_Knife, name:"Shear Knife"}));
        dispatch(AddMachine({id:uuidv4(), order:9, lineID:newLine.id, type:constants.Enums.MachineType.Slitter_Scorer, name:"Slitter Scorer"}));
        dispatch(AddMachine({id:uuidv4(), order:10, lineID:newLine.id, type:constants.Enums.MachineType.Quality_System, name:"Quality System"}));
        dispatch(AddMachine({id:uuidv4(), order:11, lineID:newLine.id, type:constants.Enums.MachineType.Starch_Kitchen, name:"Starch Kitchen"}));
        dispatch(AddMachine({id:uuidv4(), order:12, lineID:newLine.id, type:constants.Enums.MachineType.Training, name:"Training"}));
        dispatch(AddMachine({id:uuidv4(), order:13, lineID:newLine.id, type:constants.Enums.MachineType.Splicers, name:"Splicers"}));
        dispatch(AddMachine({id:uuidv4(), order:14, lineID:newLine.id, type:constants.Enums.MachineType.Rollstands, name:"Rollstands"}));
    }
};
export const UpdateLine = (updLine, curLine) => {
    const data = { update: updLine, current: curLine }
    console.log('put check __________________________________________________________________________  23');
    return {
        type: Constants.Actions.Lines_Update,
        payload: data,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.Lines + updLine.id, method: 'PUT', body: JSON.stringify(updLine), headers: { 'content-type': 'application/json' }
                },
                commit: {
                    type: Constants.Actions.Lines_Update_Commit, meta: { data }
                },
                rollback: {
                    type: Constants.Actions.Lines_Update_Rollback, meta: { data }
                }
            }
        }
    }
};
export const DeleteLine = (delLine) => {
    return {
        type: Constants.Actions.Lines_Delete,
        payload: delLine,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.Lines + delLine.id, method: 'DELETE'
                },
                commit: {
                    type: Constants.Actions.Lines_Delete_Commit, meta: { delLine }
                },
                rollback: {
                    type: Constants.Actions.Lines_Delete_Rollback, meta: { delLine }
                }
            }
        }
    }
};
export const DeleteLineByID = (lineID) => {
    return (dispatch, getState) => {
        const delLine=getState().Lines.find(l=>l.id===lineID);
        if(delLine){
            dispatch({
                type: Constants.Actions.Lines_Delete,
                payload: delLine,
                meta: {
                    offline: {
                        effect: {
                            url: Constants.URLs.Lines + delLine.id, method: 'DELETE'
                        },
                        commit: {
                            type: Constants.Actions.Lines_Delete_Commit, meta: { delLine }
                        },
                        rollback: {
                            type: Constants.Actions.Lines_Delete_Rollback, meta: { delLine }
                        }
                    }
                }
            });
        }
    }
};
export const ClearData = () => { return { type: Constants.Actions.Lines_ClearData }; };