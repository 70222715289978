//Moduyles
import React, { useState } from 'react'
import produce from "immer";
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Form, Field } from 'formik';
//MUI
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { TextField, CheckboxWithLabel } from 'formik-mui';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
//Compontents
import MachineSetup from './MachineSetup';
import { useLineOptions } from '../MetaData/PlantOptions';
import { DeleteButton } from '../Common/CustomToolbar';
//Actions
import { UpdateLine, AddLine,DeleteLineByID } from "../../actions/LineActions";
import { ConfirmAction } from '../../actions/ConfirmActionActions';
export const LineEditView = props => {
    //const classes = useStyles();  
    const [selectedLine, setSelLine] = useState('0');
    const dispatch = useDispatch();
    const delLine = () => {
        dispatch(
            ConfirmAction('Are you sure you want to delete this line?', DeleteLineByID(selectedLine), null,
                (confirmed) => {
                    if (confirmed) {
                        setSelLine('0');
                    }
                }
            )
        );
    }
    let delBtn = null;
    let mc = null;
    if (selectedLine != '0') {
        mc = <MachineSetup lineID={selectedLine} visitID={props.visitID} />;
        delBtn= <DeleteButton onClick={delLine} />;
    }
    
    return (
        <div>
            <LineSelection plantID={props.plantID} addLine selected={selectedLine} handleChange={newVal => setSelLine(newVal)} />
            <DirDataSel lineID={selectedLine} clearSel={() => setSelLine('0')} />
            {delBtn}
            {mc}         
        </div>
        );
};
export const LineSelection = props => {
    const [isOpen, setOpen] = useState(false);
    const dispatch = useDispatch();
    const options = useLineOptions(props.plantID, props.addLine);
    const onChange = (e) => {
        if (e.target.value !== '9999') {
            props.handleChange(e.target.value);
        } else {
            setOpen(true);
        }
    }
    const newSubmit = (vals) => {
        vals.plantID = props.plantID;
        dispatch(AddLine(vals));
        setOpen(false);
    }
    return (
        <>
        <FormControl >
            <InputLabel htmlFor="lines_selector">Linse:</InputLabel>
            <NativeSelect
                id="lines_selector"
                name={props.name}
                value={props.selected}
                onChange={onChange}
            >
                {options}
            </NativeSelect>
            <FormHelperText></FormHelperText>
            </FormControl>
            <AddNewLineModal isOpen={isOpen} closeModal={() => { setOpen(false); }} onSubmit={newSubmit} />
        </>
            
    );
}
const AddNewLineModal = props => {
    const validate = (values) => {
        const errors = {};
        if (!values.name) {
            errors.name = "Required";
        }
        return errors;
    }
    return <Dialog open={props.isOpen} onClose={props.closeModal} >
        <Formik initialValues={{name:'', direction:0}} onSubmit={props.onSubmit} validate={validate}>
            <Form>
                <DialogTitle >Add Line</DialogTitle >
                <DialogContent>
                    <div>
                        <Field
                            component={TextField}
                            name="name"
                            label="Name"
                        />
                    </div>    
                    <div>
                        <Field
                            component={LeftRightSelFormik}
                            name="direction"
                            label="Direction"
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button type="submit">Add</Button>
                    <Button onClick={props.closeModal}>Cancel</Button>
                </DialogActions>
            </Form>
        </Formik>
    </Dialog >;
}
const DirDataSel = props => {
    const line = useSelector(state => state.Lines.find(l => l.id == props.lineID));
    const dispatch = useDispatch();
    const onChange = (e) => {
        const newDir = Number(e.target.value);
        if (!isNaN(newDir) && newDir != line.direction) {
            dispatch(UpdateLine(produce(line, d => { d.direction = newDir }), line));
        }  
    }
    const lineDelete = ()=>{
        dispatch(ConfirmAction("Do you want to delete this Line?", DeleteLineByID(props.lineID), null,
            (confirmed) => {
                if (confirmed) {
                    props.clearSel();
                }
            }));
    }
    let retVal = null
    if (line) {
        retVal = <><LeftRightSel value={line.direction} onChange={onChange} /></>
    }
    return retVal;
}
const LeftRightSel = props => {
    const options = (<>
        <option key={0} value={0}> Left to Right</option>
        <option key={1} value={1}> Right to Left</option>
    );
    </>);
    return (
        <FormControl >
            <InputLabel htmlFor="Left_Right_Sel">Direction</InputLabel>
            <NativeSelect
                id="Left_Right_Sel"
                name={'direction'}
                value={props.value}
            onChange={props.onChange}
        >
            {options}
        </NativeSelect>
    </FormControl>
    );
};
const LeftRightSelFormik = props => {
    const handleChange = (e) => {
        const newNum = Number(e.target.value);
        if (!isNaN(newNum)) {
            props.form.setFieldValue('direction', newNum);
        }
    }
    return <LeftRightSel value={props.field.value} onChange={handleChange} />;
}