import Constants from '../constants';
import { v4 as uuidv4 } from 'uuid';
//export const GetPlantPersonnel = (plantID) => {
//    console.log('PersonnelActions.js GetPlantPersonnel Called', plantID);
//    return (dispatch, getState) => {
//        //offline.online
//        if (getState().offline.online) {
//            let fetchTask = fetch('api/Personnel?plantID=' + plantID)
//                .then(response => response.json())
//                .then(plantPersonnel => {
//                    console.log("Got Plant Personnel", plantPersonnel);
//                    dispatch({ type: Constants.Actions.Personnel_ReceiveByPlant, data: plantPersonnel, plantID:plantID });
//                })
//                .catch(error => { console.error('GetPlantPersonnel API Error', error); alert('GetPlantPersonnel API call error'); })
//        }
//    }
//}
export const AddSyncError = (errorAction, errorID = uuidv4()) => {
    const newError = {
        id: errorID , actionType:errorAction.type, dataJSON:JSON.stringify(errorAction)
    };
    return {
        type: Constants.Actions.SyncError_Add,
        payload: newError,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.SyncErrors, method: 'POST', body: JSON.stringify(newError), headers: { 'content-type': 'application/json' }
                },
                commit: {
                    type: Constants.Actions.SyncError_Add_Commit, meta: { newError }
                },
                rollback: {
                    type: Constants.Actions.SyncError_Add_Rollback, meta: { newError }
                }
            }
        }
    }
};
export const ClearData = () => { return { type: Constants.Actions.SyncError_ClearData }; };