import React from 'react';
import produce from "immer";
import makeStyles from '@mui/styles/makeStyles';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import Select from '@mui/material/Select';
import { useFormikContext } from 'formik';
import Constants from '../../constants';

const constants = Constants;
export const StepToLabel = (v) => {
    let lbl = v;
    if (v === "Caustic_Percentage") { lbl = "Caustic"; }
    if (v === "Caustic_Dry") { lbl = "Caustic Dry"; }
    if (v === "Borax_Liquid") { lbl = "Borax Liquid"; }
    if (v === "Starch_Specialty") { lbl = "Specialty Starch"; }
    if (v === "Liquid_Specialty") { lbl = "Specialty Liquid"; }
    if (v === "Liquid_Add") { lbl = "Liquid Additive"; }
    return lbl;
};
const options = Object.keys(constants.Enums.StepType).filter(key => constants.Enums.StepType[key]!==-1).map(key =>
    <option
        key={key}
        value={constants.Enums.StepType[key]}
    >
        {StepToLabel(key)}
    </option>
);

options.unshift(<option key={999} value={999}>Select Type</option>);
export const StepSelectFormik = props => {
    //const classes = useStyles();
    const { values,
        errors,
        handleChange,
        handleBlur,
        setFieldValue,
        setValues
    } = useFormikContext();
    const onChange = (e) => {      
        const newNum = Number(e.target.value);
        if (!isNaN(newNum)) {
            //setFieldValue('stepType', newNum);
            //setFieldValue('stepDescription', prefix + ' ' + postFix);
            //StepType: { Water: 0, Starch: 1, Caustic_Percentage: 2, Caustic_Dry: 3, Borax: 4, Borax_Liquid: 5, Starch_Specialty: 6, Liquid_Specialty: 7, Penetrant: 8, Resin: 9, Heat: 10, Error: -1 },
            //FormulaUnit: { lbs: 0, oz: 1, kgs: 2, F: 3, C: 4, gal: 5 }
            switch (newNum) {
                case 0:    //Water,Speciality_Liquid
                    setValues(produce(values, nv => {
                        nv.unit = 5; nv.dryPercentage = 0; nv.lbsPerGallon = 8.34;
                        nv.stepType = newNum; nv.product = ''; nv.value = 0; nv.cost = 0; nv.other = ''; nv.mixTime = 0;
                    }));
                    break;
                case 1: case 6: //Starch,,Starch Speciality
                    setValues(produce(values, nv => {
                        nv.unit = 0; nv.dryPercentage = 100; nv.stepType = newNum; nv.product = ''; nv.value = 0;
                        nv.other = ''; nv.mixTime = 0; nv.cost = 0;
                    }));
                    break;       
                case 2: //Caustic_Percentage
                    setValues(produce(values, nv => {
                        nv.unit = 0; nv.dryPercentage = 50; 
                        nv.stepType = newNum; nv.product = '';
                        nv.lbsPerGallon = 12.76; nv.value = 0;
                        nv.other = ''; nv.mixTime = 0; nv.cost = 0;
                    }));         
                    break;
                case 3: //Caustic_Dry
                    setValues(produce(values, nv => {
                        nv.unit = 0; nv.dryPercentage = 100; 
                        nv.stepType = newNum; nv.product = ''; nv.lbsPerGallon = 17; nv.value = 0;
                        nv.other = ''; nv.mixTime = 0; nv.cost = 0;
                    }));
                    break;
                case 4: //Borax
                case 5: //Borax Liquid
                    setValues(produce(values, nv => {
                        nv.unit = 0; nv.dryPercentage = 100;  nv.stepType = newNum;
                        nv.product = ''; nv.lbsPerGallon = 14.44; nv.value = 0;
                        nv.other = ''; nv.mixTime = 0; nv.cost = 0;
                    }));
                    break;
                case 7:
                    setValues(produce(values, nv => {
                        nv.unit = 0; nv.dryPercentage = 0; nv.lbsPerGallon = 8.34;
                        nv.stepType = newNum; nv.product = ''; nv.value = 0;
                        nv.other = ''; nv.mixTime = 0; nv.cost = 0;
                    }));
                    break;
                case 8: case 9: //Penetrant,Resin
                    setValues(produce(values, nv => {
                        nv.unit = 1; nv.dryPercentage = 0; nv.lbsPerGallon = 9;
                        nv.stepType = newNum; nv.product = ''; nv.value = 0;
                        nv.other = ''; nv.mixTime = 0; nv.cost = 0;
                    }));
                    break;
                case 10://Heat
                    //F or C
                    setValues(produce(values, nv => {
                        nv.unit = 3; nv.stepType = newNum; nv.product = ''; nv.value = 0;
                        nv.other = ''; nv.mixTime = 0; nv.cost = 0;
                    }));
                    break;
                case 11:
                    setValues(produce(values, nv => {
                        nv.unit = 6; nv.stepType = newNum; nv.product = ''; nv.value = 0;
                        nv.other = ''; nv.mixTime = 0; nv.cost = 0;
                    }));
                    break;
                default:
                //TODO: Log Error
            }
        }
    };
    return (
        <FormControl error={errors['stepType']} variant="outlined" >
            <InputLabel htmlFor="name-native-error">Ingredient</InputLabel>
            <Select
                native
                label="Ingredient"
                name={props.name}
                value={values['stepType']}
                onChange={onChange}
            >
                {options}
            </Select>
            <FormHelperText>{errors['stepType']}</FormHelperText>
        </FormControl>
    );
}
export const StepTypeView = props => {
    const key = Object.keys(Constants.Enums.StepType).find(key => Constants.Enums.StepType[key] == props.value);

    return <>{StepToLabel(key)}</>;
};