import Constants from '../constants';
import { v4 as uuidv4 } from 'uuid';
import { FetchErrorLogger, FetchCatchHandler } from '../middlewares';
export const GetContImproveList = () => {
    console.log('DataActions.js ContImprove Called');
    return (dispatch, getState) => {
        console.log('DataActions.js ContImprove Thunk Called');
        const state = getState();
        if (state.offline.online) {
            //No Data Get Data
            let fetchTask = fetch(Constants.URLs.ContImprove, { headers: { 'Authorization': 'Bearer ' + state.Auth.token } })
                .then(FetchErrorLogger("GetContImprove", null, dispatch))
                .then(response => response.json())
                .then(ContImprove => {
                    dispatch({ type: Constants.Actions.ReceiveContImprove, data: ContImprove })
                })
                .catch(FetchCatchHandler("GetContImprove", null, dispatch));
        }
    };
};
export const GetContImproveByVisitID = (VisitID) => {
    return (dispatch, getState) => {
        const state = getState();
        if (state.offline.online) {
            //No Data Get Data
            let fetchTask = fetch(Constants.URLs.ContImprove + '?VisitID=' + VisitID, { headers: { 'Authorization': 'Bearer ' + state.Auth.token } })
                .then(FetchErrorLogger("ContImproveByVisitID", VisitID, dispatch))
                .then(response => {
                    return response.json()
                })
                .then(ContImprove => {
                    dispatch({ type: Constants.Actions.ContImprove_GetByVisitID, data: ContImprove, VisitID: VisitID })
                })
                .catch(FetchCatchHandler("ContImproveByVisitID", VisitID, dispatch));
        }
    };
};

export const AddContImprove = (newContImproveItem) => {
    const constants = Constants;
    newContImproveItem.id = uuidv4();
    return {
        type: constants.Actions.ContImprove.Add,
        payload: newContImproveItem,
        meta: {
            offline: {
                effect: {
                    url: constants.URLs.ContImprove, method: 'POST', body: JSON.stringify(newContImproveItem), headers: { 'content-type': 'application/json' }
                },
                commit: {
                    type: constants.Actions.ContImprove.Add_Commit, meta: { newContImproveItem }
                },
                rollback: {
                    type: constants.Actions.ContImprove.Add_Rollback, meta: { newContImproveItem }
                }
            }
        }
    }
};
export const UpdateContImprove = (updContImprove, curContImprove) => {
    const constants = Constants;
    console.log('put check __________________________________________________________________________  21');
    const data = { update: updContImprove, current: curContImprove }
    return {
        type: constants.Actions.ContImprove.Update,
        payload: data,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.ContImprove + updContImprove.id, method: 'PUT', body: JSON.stringify(updContImprove), headers: { 'content-type': 'application/json' }
                },
                commit: {
                    type: constants.Actions.ContImprove.Update_Commit, meta: { data }
                },
                rollback: {
                    type: constants.Actions.ContImprove.Update_Rollback, meta: { data }
                }
            }
        }
    }
};
export const DeleteContImprove = (delContImprove) => {
    const constants = Constants;
    return {
        type: constants.Actions.ContImprove.Delete,
        payload: delContImprove,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.ContImprove + delContImprove.id, method: 'DELETE'
                },
                commit: {
                    type: constants.Actions.ContImprove.Delete_Commit, meta: { delContImprove }
                },
                rollback: {
                    type: constants.Actions.ContImprove.Delete_Rollback, meta: { delContImprove }
                }
            }
        }
    };
}
export const ClearContImproveData = () => {
    const constants = Constants;
    return { type: constants.Actions.ContImprove.ClearData };
}