import React from 'react'
import Constants from '../../constants';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import { useFormikContext } from 'formik';
export const PercentSolidFormik = props => {
    //const classes = useStyles();
    const { values } = useFormikContext();   
    let retVal = null;
    //StepType: { Water: 0, Starch: 1, Caustic_Percentage: 2, Caustic_Dry: 3, Borax: 4, Borax_Liquid: 5, Starch_Specialty: 6, Liquid_Specialty: 7, Penetrant: 8, Resin: 9, Heat: 10, Error: -1 },
    if (values['product'] === 'Other' && (values['stepType'] == 2 || values['stepType'] == 4 || values['stepType'] == 8)) {
        retVal = <div>
            <Field
                component={TextField}
                name="dryPercentage"
                type="number"
                label="Percent Solid"
            />
        </div>;
    }
    return retVal;
}