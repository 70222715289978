import Constants from '../constants';
import { OFFLINE_STATUS_CHANGED } from "@redux-offline/redux-offline/lib/constants";
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
const constants = Constants;
export const AuthenticationSuccess = (authResp) => {
    const dispatch = useDispatch();
    dispatch({ type: OFFLINE_STATUS_CHANGED, payload: { online:true } });
    return { type: constants.Actions.Auth.Success, data: authResp };
}
export const Logout = () => {
    return { type: constants.Actions.Auth.Logout };
}
export const GoOffline = () => {
    return { type: constants.Actions.Auth.GoOffline };
}
export const CheckSiteAccess = () => {
    return (dispatch, getState) => {
        const s = getState();

        if (!s.NetworkState.online) {
            if ( window.navigator.onLine) {
                dispatch({ type: Constants.Actions.NetworkState.SetOnline })
            }
        }
        if (s.NetworkState.online && !s.NetworkState.siteAccess) {
            fetch(constants.URLs.Login)
                .then(resp => {
                    if (resp && resp.status === 200) {
                        dispatch({ type: constants.Actions.NetworkState.SetSiteUp })
                    }
                });
        }
    }
}

export const CheckIsOnline = () => {
    
    return (dispatch, getState) => {
        const s = getState();
        if (s.Auth.authenticated && s.NetworkState.online && s.NetworkState.siteAccess && !s.offline.online) {
            // dispatch({ type: OFFLINE_STATUS_CHANGED, payload: { online:true } });

        }
        if ((!s.Auth.authenticated || !s.NetworkState.online || !s.NetworkState.siteAccess) && s.offline.online) {
            dispatch({ type: OFFLINE_STATUS_CHANGED, payload: { online: false } });
        }
    }
};

export const SyncManualy = () => {
    
    return (dispatch, getState) => {
        const s = getState();
        // console.log("window.navigator", window.navigator);

        if (window.navigator.connection) {
            // console.log("window.navigator", window.navigator.connection);
        }


        

        // console.log('check call manuallly----------------------------------------------------', s.Auth.authenticated);
        //  console.log('check call manuallly----------------------------------------------------', s.NetworkState.online);
        //     console.log('check call manuallly----------------------------------------------------', s.NetworkState.siteAccess);
        //  console.log('check call manuallly----------------------------------------------------', !s.offline.online);
        if (s.Auth.authenticated && s.NetworkState.online && s.NetworkState.siteAccess && !s.offline.online) {
            // console.log('check on lineeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee   m1   eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',  window.navigator.onLine);
            dispatch({ type: OFFLINE_STATUS_CHANGED, payload: { online:true } });

        }
        if ((!s.Auth.authenticated || !s.NetworkState.online || !s.NetworkState.siteAccess) && s.offline.online) {
            // console.log('check on lineeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee   m2   eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',  window.navigator.onLine);
            dispatch({ type: OFFLINE_STATUS_CHANGED, payload: { online: false } });
        }
    }
};
export const SyncOff = () => {
    return (dispatch, getState) => {
    dispatch({ type: OFFLINE_STATUS_CHANGED, payload: { online: false } });  // new added
    }
};

