import Constants from '../constants';
import produce from "immer";
import { enqueueSnackbar } from './NotificationActions';
import { FetchErrorLogger, FetchCatchHandler } from '../middlewares';
import { AddSyncTimestamp, CheckReSync } from './LastSyncActions';
import { toIsoString } from '../misc/HelperFuncs';
import { dispatch } from 'rxjs/internal/observable/pairs';
const constants=Constants;
const maxImageSize=1850000
const quality=0.9;
const imageTypes=["image/png","image/jpeg"];
export const AddAttachment = (attachObj) => {
    attachObj.lastSyncDate = toIsoString(new Date());
    return (dispatch, getState) => {
        //If Image Check for Need to Resize
        
        if(attachObj && imageTypes.includes(attachObj.mime)){
            //Check Data Size
            if(attachObj.data.length>maxImageSize){
                const resize=maxImageSize/attachObj.data.length;
                const img = new window.Image();
                img.addEventListener("load",()=>{
                    let newData=compressImage(img,resize,quality,attachObj.mime);
                    const base64 = newData.substring(newData.indexOf(',')+1);
                    attachObj.data=base64;
                    dispatch(getAddAttachmentAction(attachObj));    
                });
                img.src="data:" + attachObj.mime + ";base64," + attachObj.data;
                
            } else {
                dispatch(getAddAttachmentAction(attachObj));
            }
        } else { 
            dispatch(getAddAttachmentAction(attachObj));
        }
    }
}
const getAddAttachmentAction = (attachObj)=>{
    return {  
        type: Constants.Actions.Attachment.Add,
        payload: attachObj,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.Attachments, method: 'POST', body: JSON.stringify(attachObj), headers: { 'content-type': 'application/json' }
                },
                commit: {
                    type: Constants.Actions.Attachment.Add_Commit, meta: { attachObj }
                },
                rollback: {
                    type: Constants.Actions.Attachment.Add_Rollback, meta: { attachObj }
                }
            }
        }
    };
}
function compressImage(imgToCompress, resizingFactor, quality,mime) {
    // showing the compressed image
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    
    const originalWidth = imgToCompress.width;
    const originalHeight = imgToCompress.height;
    
    const canvasWidth = originalWidth * resizingFactor;
    const canvasHeight = originalHeight * resizingFactor;
    
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;
    
    context.drawImage(
      imgToCompress,
      0,
      0,
      originalWidth * resizingFactor,
      originalHeight * resizingFactor
    );
   
    return canvas.toDataURL(mime,quality);
};
export const GetAttachment = (attachmentId) => {
    console.log('AttachmentActions.js GetAttachment Called', attachmentId);
    return (dispatch, getState) => {
        if(!attachmentId || attachmentId===constants.EmptyGuid){ return; }
        //First Check if Image is in Cache
        const state = getState();
        if (state.offline.online && CheckReSync(attachmentId, state.LastSync, 900000)) {
            GetAttachmentPromise(attachmentId,dispatch,state.Auth.token).then();
        }
    }
};

export const DeleteAttachment = (delAttachment) => {
    return {
        type: Constants.Actions.Attachment.Delete,
        payload: delAttachment,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.Attachments + delAttachment.id, method: 'DELETE'
                },
                commit: {
                    type: Constants.Actions.Attachment.Delete_Commit, meta: { delAttachment }
                },
                rollback: {
                    type: Constants.Actions.Attachment.Delete_Rollback, meta: { delAttachment }
                }
            }
        }
    };
};
export const ClearData = () => {
    return { type: Constants.Actions.Attachment.ClearData };
};
export const UpdateAttachment=(UpdateAttachment,CurrentAttachment) => {
    const data = { update: UpdateAttachment, current: CurrentAttachment }
    console.log('put check __________________________________________________________________________  11');
    return {
        type: Constants.Actions.Attachment.Update,
        payload: data,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.Attachments + UpdateAttachment.id, method: 'PUT', body: JSON.stringify(UpdateAttachment), headers: { 'content-type': 'application/json' }
                },
                commit: {
                    type: Constants.Actions.Attachment.Update_Commit, meta: { data }
                },
                rollback: {
                    type: Constants.Actions.Attachment.Update_Rollback, meta: { data }
                }
            }
        }
    }
}
export const GetAttachmentsByArray = (entityArray, dispatch,LastSync,Token) =>{
    const gets=[];
    entityArray.forEach(i=>{
            Object.keys(i).forEach(key=>{         
                if(key.toLowerCase().endsWith("imageid") && i[key] && i[key]!==Constants.EmptyGuid && CheckReSync(i[key],LastSync, 900000)){
                    gets.push(GetAttachmentPromise(i[key],dispatch,Token));
                }
            });
        });
    return Promise.all(gets);
};
export const GetAttachmentPromise=(attachmentId,dispatch,token)=>{
    return new Promise((resolve, reject) => {
        fetch('api/Attachments/' + attachmentId, { headers: { 'Authorization': 'Bearer ' +token  } })
            .then(FetchErrorLogger("GetAttachment", attachmentId, dispatch))
            .then(response => response.json())
            .then(attachment => {
                dispatch({ type: Constants.Actions.Attachment.Receive, data: attachment });
                if(imageTypes.includes(attachment.mime) && attachment.data.length>maxImageSize){
                    const resize=maxImageSize/attachment.data.length;
                    const img = new window.Image();
                    img.addEventListener("load",()=>{
                        let newData=compressImage(img,resize,quality,attachment.mime);
                        const base64 = newData.substring(newData.indexOf(',')+1);
                        const updAtt=produce(attachment,d=>{
                            d.data=base64;
                        })
                        dispatch(UpdateAttachment(updAtt,attachment));    
                    });
                    img.src="data:" + attachment.mime + ";base64," + attachment.data;                 
                }
                dispatch(AddSyncTimestamp(attachmentId,'Attachment'));
                resolve();
            })
                .catch(err=>{
                const fh=FetchCatchHandler("GetAttachment", attachmentId, dispatch);
                fh(err);
                reject(err);
                })
        
    });
};

