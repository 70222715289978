import Constants from '../../constants';
//Modules
import React, { useEffect, useContext, useState,useRef } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import produce from "immer";
//MUI
import Button from '@mui/material/Button';
//Components
import TextFieldFormik from '../FormikUI/TextFieldFormik';
import { NativeSelectFormik } from '../FormikUI/NativeSelectFormik';
//Actions
import { AddUpdateReport } from '../../actions/ReportActions';
const constants=Constants;
const AddReport = props =>{
    const dispatch = useDispatch();
    const online = useSelector(s=>s.offline.online,shallowEqual);
    const authToken = useSelector(s=>s.Auth.token,shallowEqual );
    const initialValues={ reportType: '', reportName: '',reportDescription:'',reportData:'',dataSourcesCSV:'' };
    const validate = (values)=>{
        const errors={};
        if(!values.reportType || values.reportType==='select'){ errors.reportType="Select Report Type"; }
        if(!values.reportName) {
            errors.reportName="Name is required";
        }
        if(!values.reportData){errors.reportData="Choose Report File for Upload";}
        return errors;
    }
    
    const onSubmit = (values, { setSubmitting }) => {
        //Check if existing report
        const saveNewReport =()=>{
            let postTask = fetch(constants.URLs.Reports
                ,{ 
                    method:'POST', 
                    headers: { 'Authorization': 'Bearer ' + authToken,'Content-Type': 'application/json' },
                    body:JSON.stringify(values)
             })
             .then(response=>{   
                if(!response.ok){
                    alert("Error Uploading Report");
                    return null;
                } 
                return response.json();
             })
             .then(data=>{
                if(!data){ return; }
                //We have saved a new report add it to the local storage. 
                
                dispatch(AddUpdateReport(data));
             })
             .catch(error=>{
                alert("Upload Report Fetch Exception");
             })
            .finally(()=>{
                setSubmitting(false);
            })
        }
        const updateReport=(existing)=>{
            const upd=produce(values,d=>{
                d.id=existing.id;
            });
            console.log('put check __________________________________________________________________________  28');
            let updFetch = fetch(constants.URLs.Reports+upd.id
                ,{ 
                    method:'PUT', 
                    headers: { 'Authorization': 'Bearer ' + authToken,'Content-Type': 'application/json' },
                    body:JSON.stringify(upd)
             })
             .then(response=>{   
                if(!response.ok){
                    alert("Error Uploading Report");
                    return null;
                } 
                return response.json();
             })
             .then(data=>{
                if(!data){ return; }
                //We have saved a new report add it to the local storage. 
                dispatch(AddUpdateReport(data));
             })
             .catch(error=>{
                alert("Upload Report Fetch Exception");
             })
            .finally(()=>{
                setSubmitting(false);
            })
            
        }
        const getURL=constants.URLs.Reports +values.reportType+'/'+values.reportName;
        let fetchTask = fetch(getURL , { headers: { 'Authorization': 'Bearer ' + authToken } })
                .then(response => {
                    if(response.ok){
                        //existing report pull data and save
                        return response.json();
                    } else {
                        return null;
                    }
                })
                .then(data=>{
                    if(data){
                        updateReport(data);
                    } else {
                        saveNewReport();
                    }
                })
                .catch(error=>{
                    alert("Get Fetch Error",error);
                })
        
      };
      const typeOptions = [
        <option key={'select'} value={'select'}>Select Type</option>,
        <option key={'Checklist'} value={'Checklist'}>Checklist</option>,
        <option key={'GlueLine'} value={'GlueLine'}>GlueLine</option>,
        <option key={'GlueRoll_Measurements'} value={'GlueRoll_Measurements'}>GlueRoll Measurements</option>,
        <option key={'GlueRoll_Pictures'} value={'GlueRoll_Pictures'}>GlueRoll Pictures</option>,
        <option key={'Formula'} value={'Formula'}>Formula</option>,
        <option key={'HeatVessel'} value={'HeatVessel'}>Heat Vessel</option>,
        <option key={'IngredientVerification'} value={'IngredientVerification'}>Ingredient Verification</option>,
        <option key={'Visit'} value={'Visit'}>Visit</option>,
        <option key={'KeyItem'} value={'KeyItem'}>KeyItem</option>,
        <option key={'ActionItem'} value={'ActionItem'}>Action Item</option>,
        <option key={'Baseline'} value={'Baseline'}>Baseline</option>,
        <option key={'ThermalImages'} value={'ThermalImages'}>Thermal Image</option>,
        <option key={'StarchKitchen'} value={'StarchKitchen'}>Starch Kitchen</option>,
        <option key={'StarchChecks'} value={'StarchChecks'}>Starch Checks</option>,
        <option key={'AllReportsData'} value={'AllReportsData'}>All Reports</option>,
    ];
    if(!online){
        return <div>Must be online to Upload Reports.</div> 
    }
    return <div>

        
        <Formik
       initialValues={initialValues}
       validate={validate}
       onSubmit={onSubmit}
     >
       {({ isSubmitting,setFieldValue }) => (
         <Form>
            <NativeSelectFormik name="reportType" label="Type" options={typeOptions} />
            <TextFieldFormik name="reportName" type="text" label="Name" />
            <TextFieldFormik name="reportDescription" type="text" label="Description" />
            <TextFieldFormik name="dataSourcesCSV" type="text" label="Data Sources(CSV)" />
           <input type="file" onChange={(e)=>{
                 const fileReader = new FileReader();
                 fileReader.readAsText(e.target.files[0], "UTF-8");
                 fileReader.onload = e => {
                   setFieldValue("reportData",e.target.result);
                 };  
            }} />
           <ErrorMessage name="reportData" component="div" style={{color:'red'}} />
           <Button type="submit" disabled={isSubmitting}>
             Submit
           </Button>
         </Form>
       )}
     </Formik>
    </div>;
};
export default AddReport;