import Constants from '../constants';
import produce from "immer";
const constants = Constants;
const initDataState = { id: null, user: null, authenticated: false, token: null, offlineMode:false,userData:null };
const Auth = (state = initDataState, action) => {
    switch (action.type) {
        case constants.Actions.Auth.Success:
            return produce(state, d => {
                d.id = action.data.user.id;
                d.user = action.data.user.name;
                d.userData = action.data.user;
                d.authenticated = true;
                d.token = action.data.token;
                d.offlineMode = false;
            });
        case constants.Actions.Auth.Logout:
            return initDataState;
        case constants.Actions.Auth.GoOffline:
            return produce(state, d => {
                d.authenticated = false;
                d.offlineMode = true;
            });
        default:
            return state;
    }
}
export default Auth;