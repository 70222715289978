import Constants from '../constants';
import { GetPlantData } from './PlantActions';
import { FetchErrorLogger, FetchCatchHandler } from '../middlewares';
const constants = Constants;
export const GetActionsByPlantID = (plantID) => {
    return (dispatch, getState) => {
        //offline.online
        const state = getState();
        if (state.offline.online) {
            let fetchTask = fetch(constants.URLs.ActionItems + "?PlantID=" + plantID, { headers: { 'Authorization': 'Bearer ' + state.Auth.token } })
                .then(FetchErrorLogger("GetActionsByPlantID", plantID, dispatch))
                .then(response => response.json())
                .then(actions => {
                    //Ignore 404 errors
                    if (Array.isArray(actions)) {
                        dispatch({ type: constants.Actions.ActionItems.GetByPlantID, data: actions, plantID: plantID });
                    }
                })
                .catch(FetchCatchHandler("GetActionsByPlantID", plantID, dispatch))
        }
    }
};
export const GetAllActionItems= () => {
    return (dispatch, getState) => {
        //offline.online
        const state = getState();
        if (state.offline.online) {
            let fetchTask = fetch(constants.URLs.ActionItems, { headers: { 'Authorization': 'Bearer ' + state.Auth.token } })
                .then(FetchErrorLogger("GetAllActionItems", null, dispatch))
                .then(response => response.json())
                .then(actions => {
                    //Ignore 404 errors
                    if (Array.isArray(actions)) {
                        dispatch({ type: constants.Actions.ActionItems.GetAll, data: actions });
                    }
                })
                .catch(FetchCatchHandler("GetAllActionItems", null, dispatch))
        }
    }
};
export const AddActionItem = (newActionItem) => {
    return {
        type: constants.Actions.ActionItems.Add,
        payload: newActionItem,
        meta: {
            offline: {
                effect: {
                    url: constants.URLs.ActionItems, method: 'POST', body: JSON.stringify(newActionItem), headers: { 'content-type': 'application/json' }
                },
                commit: {
                    type: constants.Actions.ActionItems.Add_Commit, meta: { newActionItem }
                },
                rollback: {
                    type: constants.Actions.ActionItems.Add_Rollback, meta: { newActionItem }
                }
            }
        }
    }
}
export const DeleteActionItem = (delActionItem) => {
    return (dispatch, getState) => {
        const delActionItemLinks = getState().ActionItemLinks.filter(ail => ail.actionItemID === delActionItem.id);
        dispatch({ type: Constants.Actions.ActionItemLinks.DeleteByActionItemID, actionItemID: delActionItem.id })
        dispatch({
            type: Constants.Actions.ActionItems.Delete,
            payload: delActionItem,
            meta: {
                offline: {
                    effect: {
                        url: Constants.URLs.ActionItems + delActionItem.id, method: 'DELETE'
                    },
                    commit: {
                        type: Constants.Actions.ActionItems.Delete_Commit, meta: { delActionItem }
                    },
                    rollback: {
                        type: Constants.Actions.ActionItems.Delete_Rollback, meta: { delActionItem, delActionItemLinks }
                    }
                }
            }
        });
    }
}
export const ClearData = () => { return { type: Constants.Actions.ActionItems.ClearData }; };