import { createSelector } from 'reselect';
import Constants from '../../constants';
export const GlueRollSel = state => state.GlueRolls;
export const GlueRollByIDSel = createSelector(GlueRollSel, (state, grID) => grID,
(GlueRolls, grID) => {
    return GlueRolls.find(gr => gr.id == grID)
}
);
export const GRMeasurementsSel = state => state.GlueRollMeasurements;
export const GRMeasurementsByID = createSelector(GRMeasurementsSel, (state, grID) => grID,
    (Measurements, grID) => {
        return Measurements.filter(l => l.glueRollSurveyID == grID)
    }
);
export const MachineFlutesSel = state => state.MachineFlutes;
export const FilteredMachineFlutesSel=createSelector(MachineFlutesSel,(state,filterArray)=>filterArray,
    (MachineFlutes,filterArray)=> {
        return MachineFlutes.filter(mf=>filterArray.some(i=>i.machineID===mf.machineID));
    }
    );
export const GlueRollPicturesSel = state=>state.GlueRollPictures;
const MachineHasImages=(pics=>{
    return pics.roll1ImageID!==Constants.EmptyGuid || pics.roll2ImageID!==Constants.EmptyGuid  
    || pics.roll3ImageID!==Constants.EmptyGuid  || pics.roll4ImageID!==Constants.EmptyGuid  || pics.roll5ImageID!==Constants.EmptyGuid 
    || pics.roll6ImageID!==Constants.EmptyGuid  || pics.roll7ImageID!==Constants.EmptyGuid  || pics.roll8ImageID!==Constants.EmptyGuid 
    || pics.roll9ImageID!==Constants.EmptyGuid  || pics.roll10ImageID!==Constants.EmptyGuid  || pics.roll11ImageID!==Constants.EmptyGuid ;
})
export const GRPicturesBySurveyID = createSelector(GlueRollPicturesSel, (state, grID) => grID,
(Pictures, grID) => {
    return Pictures.filter(l => l.glueRollSurveyID == grID && MachineHasImages(l));
}
);
