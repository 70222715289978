import Constants from '../../constants';
import React, { useEffect, useContext, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Formik, Form, Field } from 'formik';

//MUI
import MUIDataTable from "mui-datatables";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField, CheckboxWithLabel } from 'formik-mui'
import { DataNumber } from '../FormManager';
import { Card, CardContent, CardHeader, InputAdornment, Typography } from '@mui/material';
const constants = Constants;
const ConsumptionDetail = props => {
    const { selectedBaseline, selectedDays, DetailObj, ErrObj } = props
    // console.log('---------------------------------------------------', props);

    const [StarchIbUsage, setStarchIbUsage] = React.useState(0);
    const [NewStarchIbUsage, setNewStarchIbUsage] = React.useState(0);
    const [StarchIbSaving, setStarchIbSaving] = React.useState(0);
    const [TotalSaving, setTotalSaving] = React.useState(0);
    const [TotalConsumptionSaving, setTotalConsumptionSaving] = React.useState(0);
    const [HandelKey, setHandelKey] = React.useState('');

    const [reqObj, setreqObj] = React.useState(
        {
            consLbPerMsf: 0,
            consCostLb: 0,
            totalCount: 0,
            totalConsumptionSavings: 0
        }
    );
    const [ErObj, setErObj] = React.useState(
        {
            consLbPerMsfErr: '',
            consCostLbErr: '',
            totalCountErr: ''
        }
    );
    useEffect(() => {
        let obj = ErObj
        obj.consLbPerMsfErr = DetailObj.consLbPerMsf > 0 ? '' : ErrObj.consLbPerMsfErr
        obj.consCostLbErr = DetailObj.consCostLb > 0 ? '' : ErrObj.consCostLbErr
        obj.totalCountErr = DetailObj.consTotalCount > 0 ? '' : ErrObj.consTotalCountErr
        setErObj({ ...obj })
    }, [ErrObj]);
    useEffect(() => {
        let data = reqObj
        data.consLbPerMsf = DetailObj.consLbPerMsf,
            data.consCostLb = DetailObj.consCostLb,
            data.totalCount = DetailObj.consTotalCount
        setreqObj({ ...data })
        let obj = ErObj
        obj.consLbPerMsfErr = DetailObj.consLbPerMsf > 0 ? '' : 'Required'
        obj.consCostLbErr = DetailObj.consCostLb > 0 ? '' : 'Required'
        setErObj({ ...obj })
    }, [DetailObj]);
    useEffect(() => {
        if (DetailObj.consCostLb > 0 && DetailObj.consLbPerMsf > 0 && DetailObj.consTotalCount > 0) {
            calculation(props.selectedDays)
        }
    }, [props.selectedDays]);


    const validate = (values) => {
        const errors = {};
        if (values.consLbPerMsf === 0)
            errors.consLbPerMsf = "Must have value";
        if (values.consLbPerMsf < 0)
            errors.consLbPerMsf = "invalid input";
        if (values.consCostLb === 0)
            errors.consCostLb = "Must have value";
        if (values.consCostLb < 0)
            errors.consCostLb = "invalid input";
        if (values.totalCount <= 0)
            errors.totalCount = "Must have value";
        if (values.totalCount < 0)
            errors.totalCount = "invalid input";

        if (!errors.consLbPerMsf && values.consLbPerMsf > 0) {

            const data = reqObj
            data.consLbPerMsf = values.consLbPerMsf
            setreqObj({ ...data })
            hendelconsLbPerMsf()
        }
        if (!errors.consCostLb && values.consCostLb > 0) {
            const data = reqObj
            data.consCostLb = values.consCostLb
            setreqObj({ ...data })
            hendelconsCostLb()
        }
        if (!errors.totalCount && values.totalCount > 0) {
            const data = reqObj
            data.totalCount = values.totalCount
            setreqObj({ ...data })
            hendeltotalCount(values.totalCount)
        }

        if (errors.consLbPerMsf !== "" && HandelKey === 'consLbPerMsf') {
            setError('consLbPerMsfErr', '')
        }
        if (errors.consCostLb !== "" && HandelKey === 'consCostLb') {
            setError('consCostLbErr', '')
        }
        if (errors.totalCount !== "" && HandelKey === 'totalCount') {
            setError('totalCountErr', '')
        }
        props.updateRequest(values.consLbPerMsf, 'consLbPerMsf')
        props.updateRequest(values.consCostLb, 'consCostLb')
        props.updateRequest(values.totalCount, 'consTotalCount')
        return errors;
    }
    const hendelconsLbPerMsf = (value) => {
        calculation(props.selectedDays, 'consLbPerMsf')
    }
    const hendelconsCostLb = (e) => {
        calculation(props.selectedDays, 'consCostLb')
    }
    const hendeltotalCount = (e) => {
        calculation(props.selectedDays, 'consTotalCount')
    }
    const setError = (key, value) => {
        let obj = ErObj
        obj[key] = value
        setErObj({ ...obj })
        // setErObj(ErObj => ({  ...ErObj,  [key]: value }));
    }
    const calculation = (selectedRadio, key) => {
        if (reqObj.consLbPerMsf > 0 && reqObj.consCostLb > 0 && reqObj.totalCount > 0) {
            let StarchIbUsage = selectedBaseline.consumptionBaseline * (selectedBaseline.avgSpeed * selectedBaseline.avgWidth / 12 / 1000) * (selectedBaseline.shiftPerDay * selectedBaseline.hoursPerShift)
            let NewStarchIbUsage = (reqObj.consLbPerMsf * (selectedBaseline.avgSpeed * selectedBaseline.avgWidth / 12 / 1000)) * (selectedBaseline.shiftPerDay * selectedBaseline.hoursPerShift)

            let msfProducedMonth = selectedBaseline.daysPerWeek * 4.25
            let msfProducedWeek = selectedBaseline.daysPerWeek

            let TotalConsumptionSaving = 0
            if (selectedRadio === 'day') {
                let StarchIbSaving = StarchIbUsage - NewStarchIbUsage
                let TotalSaving = reqObj.consCostLb * StarchIbSaving
                TotalConsumptionSaving = TotalSaving * reqObj.totalCount

                setStarchIbUsage(StarchIbUsage)
                setNewStarchIbUsage(NewStarchIbUsage)
                setStarchIbSaving(StarchIbSaving)
                setTotalSaving(TotalSaving)
                setTotalConsumptionSaving(TotalConsumptionSaving)
            } else if (selectedRadio === 'week') {
                let StarchIbUsageM = StarchIbUsage * msfProducedWeek
                let NewStarchIbUsageM = NewStarchIbUsage * msfProducedWeek
                let StarchIbSaving = StarchIbUsageM - NewStarchIbUsageM
                let TotalSaving = reqObj.consCostLb * StarchIbSaving
                TotalConsumptionSaving = TotalSaving * reqObj.totalCount

                setStarchIbUsage(StarchIbUsageM)
                setNewStarchIbUsage(NewStarchIbUsageM)
                setStarchIbSaving(StarchIbSaving)
                setTotalSaving(TotalSaving)

                setTotalConsumptionSaving(TotalConsumptionSaving)
            } else if (selectedRadio === 'month') {
                let StarchIbUsageM = StarchIbUsage * msfProducedMonth
                let NewStarchIbUsageM = NewStarchIbUsage * msfProducedMonth
                let StarchIbSaving = StarchIbUsageM - NewStarchIbUsageM
                let TotalSaving = reqObj.consCostLb * StarchIbSaving
                TotalConsumptionSaving = TotalSaving * reqObj.totalCount

                setStarchIbUsage(StarchIbUsageM)
                setNewStarchIbUsage(NewStarchIbUsageM)
                setStarchIbSaving(StarchIbSaving)
                setTotalSaving(TotalSaving)

                setTotalConsumptionSaving(TotalConsumptionSaving)
            }

            props.ConsumptionHandle({ ...reqObj, totalConsumptionSavings: TotalConsumptionSaving }, key)
        }
    }

    const formatnum = (num) => {
        let value = parseFloat(num)
        let formatted = Math.round(value);
        return parseFloat(formatted).toLocaleString();
    }
    const Tformatnum = (number) => {
      if (isNaN(number) || number === null) {        
            return "Invalid Number";
        }
        if (Number.isInteger(number)) {
            let formattedNumber = number.toString()
            formattedNumber = formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return formattedNumber;
            // return number.toString();
        } else {
            let formattedNumber = Number(number).toFixed(2);
            formattedNumber = formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return formattedNumber;
        }
    }
    const handleFocus = (e, keyName) => {
        setHandelKey(keyName)
        e.target.select(); // Selects the input value
    };

    if (selectedBaseline !== null) {
        return <>
            <Grid container xl={6} style={{ padding: '16px', marginTop: '20px' }}>
                <Grid item xl={12}>
                    <Formik initialValues={reqObj} validate={validate} validateOnBlur={true}>
                        <Form >
                            <Card variant="outlined" >
                                <CardHeader title="Consumption" />
                                <CardContent>
                                    <Grid style={{ paddingLeft: '20px' }}>
                                        <Grid spacing={3} container item xs={12} sx={{ borderTop: 1, borderLeft: 1 }}>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1 }} item xs={6} alignItems='center' justifyContent='center' display='flex'>
                                                <div>Lb./MSF</div>
                                            </Grid>

                                            <Grid sx={{ borderBottom: 1, borderRight: 1, paddingRight: 1 }} item xs={6} align="right">
                                                <p style={{ fontSize: '12px', paddingRight: '5px', margin: '0px' }}>Baseline Value: {selectedBaseline.consumptionBaseline.toFixed(2)}</p>
                                                <Field id='cusomption1' onWheel={(e) => e.target.blur()} style={{}} label="Lb./MSF" component={TextField} name="consLbPerMsf" type="number"
                                                    onFocus={(e) => handleFocus(e, "consLbPerMsf")} inputProps={{ min: 0 }} onKeyDown={(e) => {
                                                        if (e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 189) {
                                                            e.preventDefault();
                                                        }
                                                    }} />
                                                {
                                                    ErObj.consLbPerMsfErr !== "" ? <Typography style={{ fontSize: '0.75rem' }} color="error">{ErObj.consLbPerMsfErr}</Typography> : <></>
                                                }
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1, paddingRight: 1 }} item xs={6} alignItems='center' justifyContent='center' display='flex'>
                                                <div>
                                                    Cost per Lb. per Starch
                                                </div>
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1, paddingRight: 1 }} item xs={6} align="right">
                                                <p style={{ fontSize: '12px', paddingRight: '5px', margin: '0px' }}>Baseline Value: ${selectedBaseline.costRate}</p>
                                                <Field id='cusomption2' onWheel={(e) => e.target.blur()} style={{}} label='Cost Lb.' component={TextField} name="consCostLb" type="number"
                                                    onFocus={(e) => handleFocus(e, "consCostLb")} inputProps={{ min: 0 }} onKeyDown={(e) => {
                                                        if (e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 189) {
                                                            e.preventDefault();
                                                        }
                                                    }} />
                                                {
                                                    ErObj.consCostLbErr !== "" ? <Typography style={{ fontSize: '0.75rem' }} color="error">{ErObj.consCostLbErr}</Typography> : <></>
                                                }
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1, paddingRight: 1 }} item xs={6} align="center">
                                                <div>
                                                    Starch Ib. Usage
                                                </div>
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1, paddingRight: 1 }} item xs={6} align="right">
                                                {formatnum(StarchIbUsage)}
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1, paddingRight: 1 }} item xs={6} align="center">
                                                <div>
                                                    New Starch Ib. Usage
                                                </div>
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1, paddingRight: 1 }} item xs={6} align="right">
                                                {formatnum(NewStarchIbUsage)}
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1, paddingRight: 1 }} item xs={6} align="center">
                                                <div>
                                                    Starch Ib. Savings
                                                </div>
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1, paddingRight: 1 }} item xs={6} align="right">
                                                {formatnum(StarchIbSaving)}
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1, paddingRight: 1 }} item xs={6} align="center">
                                                <div>
                                                    Total Savings
                                                </div>
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1, paddingRight: 1 }} item xs={6} align="right">
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <p style={{ margin: '0px' }}>$</p>
                                                    <p style={{ margin: '0px' }}>{Tformatnum(TotalSaving)}</p>
                                                </div>
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1, paddingRight: 1 }} item xs={6} alignItems='center' justifyContent='center' display='flex'>
                                                <div>Total {props.selectedDays.charAt(0).toUpperCase() + props.selectedDays.slice(1)}</div>
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1, paddingRight: 1 }} item xs={6} align="right">
                                                {Tformatnum(reqObj.totalCount)}
                                                {/* <Field inputProps={{ readOnly: true }} onWheel={(e) => e.target.blur()} style={{}} label={'Total ' + props.selectedDays.charAt(0).toUpperCase() + props.selectedDays.slice(1)} component={TextField} name="totalCount" type="number"
                                             />
                                                {
                                                    ErObj.totalCountErr !== "" ? <Typography style={{ fontSize: '0.75rem' }} color="error">{ErObj.totalCountErr}</Typography> : <></>
                                                } */}
                                            </Grid>
                                        </Grid>
                                        <Grid xs={12}>
                                            <Grid container xs={12} sx={{ marginTop: 2, fontWeight: 'bold', alignItems: 'center' }}>
                                                <Grid item xs={6}>
                                                    Total Consumption Savings
                                                </Grid>
                                                <Grid container item xs={6} align="right" sx={{ paddingRight: 1 }}>

                                                    <Grid xs={12}>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <p>$</p>
                                                            <p>{Tformatnum(TotalConsumptionSaving)}</p>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Form>
                    </Formik>
                </Grid>
            </Grid>
        </>
    }

};
export default ConsumptionDetail;