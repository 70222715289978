import * as React from 'react';
import NavMenu from './Menu/NavMenu';
import MenuDrawer from './Menu/MenuDrawer'
export default (props) => (
    <React.Fragment>       
        <NavMenu />

        <div style={{ paddingLeft: '5px', paddingRight: '5px', paddingTop:'5px' }}>
            <MenuDrawer />
            {props.children}
        </div>
    </React.Fragment>
);
