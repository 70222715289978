import Constants from '../../constants';
import React, { useEffect, useContext, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { UnitView } from '../Formula/UnitViews';
import MUIDataTable from "mui-datatables";
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CreateHV_Temps, UpdateHeatVesselTemp } from '../../actions/HeatVesselActions';
import { GetLineMachines } from '../../actions/MachineActions'
import { useLineOptions, useMachineOptions, usePersonnalOptions } from '../MetaData/PlantOptions';
import produce from "immer";
import { KeyItemCheckBox } from '../KeyItems/KeyItemCheckBox';
import { DataForm, DataTextBox, DataDate, DataSelect, DataNumber, DataFormContext } from '../FormManager';

import useWindowSize from '../MetaData/useWindowSize';
import LineSingleSelect from '../Common/LineSingleSelect';
import Hidden from '@mui/material/Hidden';
import { useMachineFlutesList } from '../MetaData/FluteViews';
const constants = Constants;
const useStyles = makeStyles((theme) => ({
    header: {
        borderBottom: 'solid'
    },
}));
const VesselTempsGrid = props => {
    const dispatch = useDispatch();
    const machines = useSelector(s => s.Machines
        .filter(m => m.lineID === props.heatVessel.lineID && !m.deleted)
        .sort((a, b) => a.order - b.order), shallowEqual);
    const machineVesselTemps = useSelector(s => s.HeatVesselTemps
        .filter(hvt => hvt.heatVesselSurveyID === props.heatVessel.id && !hvt.deleted), shallowEqual);
    const machineRows = [];
    useEffect(() => {
        if (props.heatVessel.lineID) {
            dispatch(GetLineMachines(props.heatVessel.lineID));
        }
    }, [props.heatVessel.lineID])
    machines.forEach(m => {
        if (machineVesselTemps.some(mvt => mvt.machineID === m.id)){
            machineRows.push(<MachineTempsView machine={m}
                visitID={props.heatVessel.visitID}
                heatVessel={props.heatVessel}
                printMode={props.printMode}
            />);
        }
    })
    return <>
        <LineSingleSelect plantID={props.plantID} onLineChange={props.onLineChange} lineID={props.heatVessel.lineID} />
        {machineRows}
    </>;
    
    
};
const MachineTempsView = props => {
    const classes = useStyles();
    //<!--<FluteDisplay machine={props.machine} />-->
    return (
        <Grid container key={'MachineTemps_'+props.machine?.id}>
            <Grid item xs={12} sm={3} md={3} className={classes.header}>
                <Typography>{ props.machine.name }</Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8} className={classes.header}>
                <TempGridHeader machine={props.machine} printMode={props.printMode}  />
            </Grid>
            <Hidden smDown>
            <Grid item xs={12} sm={3} md={3}>
                    <LeftDisplay machine={props.machine} visitID={props.visitID} heatVessel={props.heatVessel} printMode={props.printMode}  />
            </Grid>
            </Hidden>
            <Grid item xs={12} sm={8} md={8} >
                <TempsGrid machine={props.machine} visitID={props.visitID} heatVesselID={props.heatVessel.id} printMode={props.printMode} />
            </Grid>
            <Hidden smUp>
                <Grid item xs={12} sm={3}>
                    <LeftDisplay machine={props.machine} visitID={props.visitID} heatVessel={props.heatVessel} printMode={props.printMode} />
            </Grid>
            </Hidden>
        </Grid>
    );
}
const InLineEditStr = props => {
    const dispatch = useDispatch();
    const inputID = props.rowIndex + '_' + props.name;
    const [value, setValue] = useState(props.value);
    useEffect(() => {
        setValue(props.value);
    },[props.value])
    //const getNextID = (curID) => {
    //    let row = Number(curID.substring(0, curID.indexOf('_')));
    //    return (++row) + '_acutalWeight';

    //};
    //const isFocus = inputID === nextInput;
    const isFocus = false;
    return <FormControlLabel
        value={props.value}
        control={
            <TextField value={value && value != null ? value : ''} id={inputID} autoFocus={isFocus}
                onFocus={event => event.target.select()} size="small" variant="outlined" 
                 />
        }
        onChange={event => {
            console.log("InLineStr OnChange " + event.target.value);
            setValue(event.target.value)
        }}
        onBlur={event => {
            if (props.curObj[props.name] != event.target.value) {
                const updObj = produce(props.curObj, draft => {
                    draft[props.name] = event.target.value;
                });
                dispatch(UpdateHeatVesselTemp(updObj, props.curObj));
            }
        }
        }
        label={''}
        size="small"
    />;
}
const TempsGrid = props => {
    //const windowSize = useWindowSize();

    const temps = useSelector(s => s.HeatVesselTemps
        .filter(hvt => hvt.heatVesselSurveyID === props.heatVesselID && hvt.machineID === props.machine.id && !hvt.deleted)
        .sort((a, b) => a.order - b.order)
        , shallowEqual);
    const rows = [];
    if (props.machine.type == 5) {
        temps.forEach(t => {
            rows.push(<React.Fragment key={t.id}>
                <Grid item md={2} xs={3} sm={2} align="center">
                    {t.vesselName}
                </Grid>
                <Grid item md={2} xs={3} sm={2}>
                    <InLineEditStr value={t.top} rowIndex={rows.length + 1} name={'top'} curObj={t} />
                </Grid>
                
            </React.Fragment>);
        });
    } else {
        temps.forEach(t => {
            let linkButtons = null;
            let colSize=5
            if (!props.printMode) {
                linkButtons = <Grid item xs={3} sm={3}>
                    <KeyItemCheckBox entity={t} visitID={props.visitID}
                        setReference={(entity) => {
                            return entity.machineName + '-' + entity.vesselName;
                        }}
                        entityType={constants.Surveys.HeatVessel} edit={true} tabIndex="-1"
                    />
                  
                </Grid>;
                colSize = 3;
            }
            rows.push(<React.Fragment key={t.id}>
                <Grid item xs={colSize} sm={colSize}>
                    <Typography noWrap>
                        {t.vesselName}
                    </Typography>
                </Grid>
                <Grid item xs={3} sm={3}>
                    <InLineEditStr value={t.top} rowIndex={rows.length + 1} name={'top'} curObj={t} />
                </Grid>
                <Grid item xs={3} sm={3}>
                    <InLineEditStr value={t.bottom} rowIndex={rows.length + 1} name={'bottom'} curObj={t} />
                </Grid>
                {linkButtons}
            </React.Fragment>);
        })
    }
    return <Grid container justifyContent="center"
        alignItems="center">
        {rows}
    </Grid>;
}
const TempGridHeader = props => {
    if (props.machine.type == 5) {
        return (
            <Grid container>
                <Grid item xs={3} sm={2} md={2}>Vessel</Grid>
                <Grid item xs={3} sm={2} md={2}>Temp</Grid>
                <Grid item xs={3} sm={2} md={2}>Vessel</Grid>
                <Grid item xs={3} sm={2} md={2}>Temp</Grid>
                <Hidden mdDown>
                    <Grid item sm={2} md={2}>Vessel</Grid>
                    <Grid item sm={2} md={2}>Temp</Grid>
                </Hidden>
            </Grid>
        );
    }
    let colSize = 5
    let linkHeader = null;
    if (!props.printMode) {
        linkHeader = <Grid item xs={3} sm={3}></Grid>;
        colSize = 3;
    }
    return <Grid container>
        <Grid item xs={colSize} sm={colSize}>Vessel</Grid>
        <Grid item xs={3} sm={3}>Top</Grid>
        <Grid item xs={3} sm={3}>Bottom</Grid>
        {linkHeader}
    </Grid>;
}
const LeftDisplay = props => {
    if (props.machine.type === 1)
        return <FluteDisplay machine={props.machine} />;
    if (props.machine.type === 5)
        return <HotPlatesLeft machine={props.machine} visitID={props.visitID} heatVessel={props.heatVessel} printMode={props.printMode} />;
    return null;
}
const FluteDisplay = props => {
    const fluteStr = useMachineFlutesList(props.machine.id);
    return <div>{fluteStr }<br/>-Flutes</div>;
}
const HotPlatesLeft = props => {
    let linkButtons = null;
    if (!props.printMode) {
        linkButtons = <><KeyItemCheckBox entity={props.heatVessel} visitID={props.visitID}
            setReference={(entity) => { return 'HotPlates' }}
            entityType={constants.Surveys.HeatVessel} edit={true} tabIndex="-1"
        />
            </>;
    }
    return <div>
        {linkButtons}
        <div>
            Steam PSI
            </div>
        <div><DataTextBox name={'zoneTemp1'} label={'Zone Temp #1'}
            InputProps={{ shrink: true, style: { width: '130px' } }} TextFieldProps={{ size: 'small' }}/></div>
        <div><DataTextBox name={'zoneTemp2'} label={'Zone Temp #2'}
            InputProps={{ shrink: true, style: { width: '130px' } }} TextFieldProps={{ size: 'small' }} /></div>
        <div><DataTextBox name={'zoneTemp3'} label={'Zone Temp #3'}
            InputProps={{ shrink: true, style: { width: '130px' } }} TextFieldProps={{ size: 'small' }} /></div>
        <div><DataTextBox name={'zoneTemp4'} label={'Zone Temp #4'}
            InputProps={{ shrink: true, style: { width: '130px' } }} TextFieldProps={{ size: 'small' }}/></div>
        <div><DataTextBox name={'zoneTemp5'} label={'Zone Temp #5'}
            InputProps={{ shrink: true, style:{width:'130px'} }} TextFieldProps={{ size:'small'}} /></div>
    </div>;
}
export default VesselTempsGrid;