import Constants from '../../constants';
import React, { useEffect, useContext, useState } from 'react';
import {useDispatch} from 'react-redux';
import MUIDataTable from "mui-datatables";
import Card from '@mui/material/Card';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { Formik, Form, Field } from 'formik';
import { TextField, CheckboxWithLabel } from 'formik-mui'
import AddCustomToolbar from "../AddCustomToolbar";
import CustomSelectToolbar from '../CustomSelectToolbar';
import { AddMeasurement, DeleteMeasurement } from '../../actions/MeasurementActions';
import { NativeSelectFormik } from '../FormikUI/NativeSelectFormik';
import { MeasurementTypeName } from '../MetaData/EnumViews'
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
const MeasurementGridNew = props => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [page,setPage]=useState(0);
    const [modalOpen, setModal] = useState(false);
    const dispatch = useDispatch();
    const rowSelect = (rowsSelectedData, allRows, rowsSelected) => {
        setSelectedRows(rowsSelected);
    };
    const data = props.Measurements
    const ClickDelete = () => {
        dispatch(DeleteMeasurement(data[selectedRows[0]]));
        setSelectedRows([]);
    };
    const ClickAdd = (e) => {
        console.log("personnel add clicked");
        setModal(true);
    };
    const handleDelete = (rowIndex) => {
        console.log('data[rowIndex]', data[rowIndex]);
        dispatch(DeleteMeasurement(data[rowIndex]));
    };
    const columns = [{
        name: "type", label: "Type", options: {
            filter: true,
            customBodyRender: (value, tableMeta, updateValue) => {
                return (
                    <MeasurementTypeName value={value} />
                );
            },
        }
    }
        ,
        { name: "value", label: "Value" },
        {
            name: "measurementDateTime", label: "Time",
            options: {
                filter: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const tDate = new Date(value);
                    return (<div>{tDate.toLocaleString()}</div>);
                }
            }
        },
        { 
            name: "", 
            label: "", 
            textAlign: 'end',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    // const rowData = tableMeta.rowData;
                    return (
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <IconButton 
                                onClick={() => handleDelete(tableMeta.rowIndex)} 
                                color=""
                            >
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    );
                },
            }
        }
    ];
    const options = {
        filterType: '', selectableRows: false, selectableRowsOnClick: false, print: false,
        download: false,
        fixedHeader: true,
        fixedSelectColumn: false,
        responsive: 'standard',
        onRowSelectionChange: rowSelect,
        rowsSelected: selectedRows,
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
            return <CustomSelectToolbar selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows} />;
        },
        sort: false,
        search: false,
        filter: false,
        viewColumns: false,
        // pagination: false,
    };
    // if (props.SelectedMachine == -1) {
    //     return <div>Select Location</div>;
    // }
    const getMuiTheme = () =>
        createTheme({
          components: {
            MuiTableCell: {
              styleOverrides:{ root: {
                paddingBlock: '3px !important',
              }}
            },
            MuiButtonBase: {
              styleOverrides:{ root: {
                // display: 'none !important',
              }}
            }
          }
        });
    return <>
     <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
        title={""}
        data={data}
        columns={columns}
        options={options}
        />
        </ThemeProvider>
        {/* <MeasurementFormDialog modalOpen={modalOpen} closeModal={() => setModal(false)}
            SelectedMachine={ props.SelectedMachine} VisitID={props.VisitID}
            /> */}
        </>;
};
export const MeasurementFormDialog = props => {
    const dispatch = useDispatch();
    const handleSubmit = (e) => {    
        if(e.gelTempVal>0){
            dispatch(AddMeasurement({
                type:0,location: props.SelectedMachine,
                visitID: props.VisitID, value: e.gelTempVal, measurementDateTime:new Date().toJSON()
            }));
        }
        if(e.viscosityVal>0){
            dispatch(AddMeasurement({
                type:1,location: props.SelectedMachine,
                visitID: props.VisitID, value: e.viscosityVal, measurementDateTime:new Date().toJSON()
            }));
        }
        if(e.tempVal>0){
            dispatch(AddMeasurement({
                type:2,location: props.SelectedMachine,
                visitID: props.VisitID, value: e.tempVal, measurementDateTime:new Date().toJSON()
            }));
        }
        props.closeModal();
    }
    const initVals={
        gelTempVal:0,
        viscosityVal:0,
        tempVal:0
    }
    const validate =(values) => {
        const errors={};
        if (!Number.isInteger(values.gelTempVal)) { errors.gelTempVal = "Whole numbers only"; }
        if (!Number.isInteger(values.viscosityVal)) { errors.viscosityVal = "Whole numbers only"; }
        if (!Number.isInteger(values.tempVal)) { errors.tempVal = "Whole numbers only"; }
        return errors;
    }
  
    return (
        <Dialog open={props.modalOpen} onClose={props.closeModal} >
            <Formik initialValues={initVals} onSubmit={handleSubmit} validate={validate}  >
                <Form>
                    <DialogTitle >Add Measurement</DialogTitle >
                    <DialogContent>
                        <Grid container  align = "center" justify = "center" alignItems = "center">
                            <Grid item xs={6} >Gel Temp</Grid>
                            <Grid item xs={6}><Field component={TextField} name="gelTempVal" type="number"  /></Grid>
                            <Grid item xs={6} >Viscosity</Grid>
                            <Grid item xs={6}><Field component={TextField} name="viscosityVal" type="number"  /></Grid>
                            <Grid item xs={6} >Temperature</Grid>
                            <Grid item xs={6}><Field component={TextField} name="tempVal" type="number"  /></Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit">Add</Button>
                        <Button onClick={props.closeModal}>Cancel</Button>
                    </DialogActions>
                </Form>
            </Formik>
        </Dialog >
    );
};
export default MeasurementGridNew;