import Constants from '../constants';
import produce from "immer";
import { UpdateArray,UpdateArrayRange } from '../misc/ArrayFuncs';
const initDataState = [];
const GlueLines= (state = initDataState, action) => {
    switch (action.type) {
        case Constants.Actions.GlueLine.Add:
            return UpdateArray(action.payload, state);
        case Constants.Actions.GlueLine.Add_Rollback:
            const glID = state.findIndex(gl => gl.id === action.meta.newGL.id);
            if (glID !== -1) {
                return produce(state, pd => { pd.splice(glID, 1) });
            } else {
                return state;
            }
        case Constants.Actions.GlueLine.Add_Commit:
            return UpdateArray(action.payload?.data, state);
        case Constants.Actions.GlueLine.GetByID:
            return UpdateArray(action.data, state);
        case Constants.Actions.GlueLine.Update:
            //Action will have the full plant data
            return state.map((p, ind) => {
                if (p.id === action.payload.id) {
                    return produce(p, pd => { pd[action.payload.name] = action.payload.newValue });
                } else { return p; }
            });
        case Constants.Actions.GlueLine.Update_Commit:
            return UpdateArray(action.payload?.data, state);
        case Constants.Actions.GlueLine.Update_Rollback:
            //Action will have the full plant data
            return state.map((p, ind) => {
                if (p.id === action.meta.updatePayload.id) {
                    return produce(p, pd => { pd[action.meta.updatePayload.name] = action.meta.updatePayload.oldValue });
                } else { return p; }
            });
        case Constants.Actions.GlueLine.Delete:
            return UpdateArray(produce(action.payload, d => {
                d.deleted = true;
            }), state);
        case Constants.Actions.IngredientVerification_Delete_Commit:
            return state;
        case Constants.Actions.GlueLine.Delete_Rollback:
            return UpdateArray(action.meta.delGR, state);
        case Constants.Actions.GlueLine.ClearData:
            return initDataState;
        case Constants.Actions.GlueLine.GetByVisitID:
            return UpdateArrayRange(action.data, state);
        default:
            return state;
    }
}
export default GlueLines;