//StepType: { Water: 0, Starch: 1, Caustic_Percentage: 2, Caustic_Dry: 3, Borax: 4, Borax_Liquid: 5, Starch_Specialty: 6, Liquid_Specialty: 7, Penetrant: 8, Resin: 9, Heat: 10, Error: -1 },
export const WaterProducts = [
    { product: "City Water", lbsPerGal:8.34, percentSolid:0,id:0 },
    { product: "Finish Water", lbsPerGal:8.34, percentSolid:0, id:1  },
    { product: "Process Water", lbsPerGal:8.34, percentSolid:0, id:2  },
    { product: "Reclaimed Water", lbsPerGal:8.34, percentSolid:0, id:3 },
    { product: "Softened Water", lbsPerGal:8.34, percentSolid:0, id:4  },
    { product: "Well Water", lbsPerGal:8.34, percentSolid:0, id:5 }
];
export const BoraxProducts = [
    { product: "5 Mol Dry Borax", lbsPerGal: 0, percentSolid: 100, id: 0 },
    { product: "10 Mol Dry Borax", lbsPerGal: 0, percentSolid: 100, id: 1 },
    { product: "Boric Acid", lbsPerGal: 0, percentSolid: 100, id: 2 },
    { product: "5 Mol Liquid Borax", lbsPerGal: 14.4, percentSolid: 100, id: 3 }
];

export const LiquidAdditiveProducts = [
    { product: "Turbo Max", lbsPerGal: 8.9238, percentSolid: 0, id: 0 },
    { product: "TurboMax 50", lbsPerGal: 8.9238, percentSolid: 0, id: 1 },
    { product: "Turbo Max 66", lbsPerGal: 8.9238, percentSolid: 0, id: 2 },
    { product: "Turbo Max IPS", lbsPerGal: 8.9238, percentSolid: 0, id: 3 },
    { product: "Turbo Max IPS 50", lbsPerGal: 8.9238, percentSolid: 0, id: 4 },
    { product: "Turbo Max IPS 66", lbsPerGal: 8.9238, percentSolid: 0, id: 5 },
    { product: "Super Tac", lbsPerGal: 8.34, percentSolid: 0, id: 6 },
    { product: "Super Tac SS", lbsPerGal: 8.9238, percentSolid: 0, id: 7 },
    { product: "Turbo Max RT", lbsPerGal: 8.9238, percentSolid: 0, id: 8 },
    { product: "Turbo Max RT 50", lbsPerGal: 8.9238, percentSolid: 0, id: 9 },
    { product: "Turbo Max RT 66", lbsPerGal: 8.9238, percentSolid: 0, id: 10 },
    { product: "MLS RT", lbsPerGal: 8.9238, percentSolid: 0, id: 11 },
    { product: "MLS RT 50", lbsPerGal: 8.9238, percentSolid: 0, id: 12 },
    { product: "MLS RT 66", lbsPerGal: 8.9238, percentSolid: 0, id: 13 },
    { product: "Enviro-Rez IPS", lbsPerGal: 9.174, percentSolid: 0, id: 14 },
    { product: "Enviro-Rez RT", lbsPerGal: 8.757, percentSolid: 0, id: 15 },
    { product: "Enviro-Rez 210", lbsPerGal: 9.174, percentSolid: 0, id: 16 },
    { product: "E-Z-Rez IPS", lbsPerGal: 9.174, percentSolid: 0, id: 17 },
    { product: "E-Z-Rez100", lbsPerGal: 9.174, percentSolid: 0, id: 18 },
    { product: "E-Z Rez 710", lbsPerGal: 9.174, percentSolid: 0, id: 19 }
];

export const SpecialtyStarchProducts = [
    { product: "Fiber LoK", lbsPerGal: 0, percentSolid: 100, id: 0 },
    { product: "Fiber Tac Plus", lbsPerGal: 0, percentSolid: 100, id: 1}
];

export const SpecialtyLiquidsProducts = [
    { product: "Bond Aid", lbsPerGal: 8.35668, percentSolid: 0, id: 0 },
    { product: "Bond Aid Extra", lbsPerGal: 9.04056, percentSolid: 0, id: 1},
    { product: "Bond  Aid Plus", lbsPerGal: 8.4234, percentSolid: 0, id: 2 },
    { product: "No Foam 211P", lbsPerGal: 8.45676, percentSolid: 0, id: 3},
    { product: "No Foam 211 Concentrate", lbsPerGal: 8.55684, percentSolid: 0, id: 4 },
    { product: "Proxel GXL", lbsPerGal: 0, percentSolid: 100, id: 5},
    { product: "B-141", lbsPerGal: 0, percentSolid: 100, id: 6}
];

export const CausticProducts = [
    { product: "Liquid 25%", lbsPerGal: 10.66, percentSolid: 25, id: 0},
    { product: "Liquid 30%", lbsPerGal: 11.11, percentSolid: 30, id: 1},
    { product: "Liquid 40%", lbsPerGal: 11.96, percentSolid: 40, id: 2},
    { product: "Liquid 50%", lbsPerGal: 12.76, percentSolid: 50, id: 3},
    { product: "Beads", lbsPerGal: 0, percentSolid: 100, id: 4 },
];
// export const CausticProducts = [
//     { product: "Liquid 25%", lbsPerGal: 10.66, percentSolid: 75, id: 0},
//     { product: "Liquid 30%", lbsPerGal: 11.11, percentSolid: 70, id: 1},
//     { product: "Liquid 40%", lbsPerGal: 11.96, percentSolid: 60, id: 2},
//     { product: "Liquid 50%", lbsPerGal: 12.76, percentSolid: 50, id: 3},
//     { product: "Beads", lbsPerGal: 0, percentSolid: 100, id: 4 },
// ];