import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import Constants from '../constants';
import CorrugatorAdhesiveData from '../store/CorrugatorAdhesiveDataStore';
import { CheckIsOnline, SyncManualy, SyncOff } from '../actions/NetworkStateActions'
const AlertAction = props => {
    const constants = Constants;
    const dispatch = useDispatch();
    const confirm = useSelector(state => state.AlertAction, shallowEqual);
 
    const handleNo = () => {
        if (confirm.cancelAction !== 'loader') {
            if (confirm.callback) {
                confirm.callback(false);
            }
            dispatch({ type: constants.Actions.AlertAction.ClearData });
        }
        
    }
    const handleSync = () => {
        if (confirm.callback) {
            confirm.callback(false);
        }
        dispatch({ type: constants.Actions.AlertAction.ClearData });
        dispatch(SyncManualy());
    }
    if (!confirm) {
        return null;
    }
    return (
        <Dialog
            open={true}
            onClose={handleNo}
            
            aria-describedby="alert-dialog-description"
        >
            
            <DialogContent>
            {
                confirm.cancelAction === 'loader' ?  <>
                <div >
                <DialogContentText id="alert-dialog-description">  {confirm.label}</DialogContentText>
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
                </div>
                </>
                :  confirm.cancelAction !== 'ok' ?  <DialogContentText id="alert-dialog-description">  {confirm.label}</DialogContentText> : <DialogContentText style={{  whiteSpace: 'break-spaces'}} id="alert-dialog-description">  {confirm.label}</DialogContentText>
            }
                
            </DialogContent>
            <DialogActions>

            {
                confirm.cancelAction === 'sync' ?  <Button onClick={handleSync} color="primary" autoFocus>Start Sync Process Now</Button>
                :  confirm.cancelAction === 'cancel' || confirm.cancelAction === 'ok' ? <Button onClick={handleNo} color="primary" autoFocus>Ok</Button> : <></>
            }
            </DialogActions>
        </Dialog>
    );
}
export default AlertAction;