import Constants from '../../constants';
//Modules
import React, { useEffect, useContext, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import  { useNavigate, useParams } from 'react-router-dom';

//MUI
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import PrintIcon from '@mui/icons-material/Print';
import makeStyles from '@mui/styles/makeStyles';
//Components
import { PlantLink } from '../MetaData/Links';
import { usePlantIDFromVisitID } from '../MetaData/PlantOptions';
import { DataForm, DataTextBox, DataDate, DataSelect, DataNumber, DataFormContext } from '../FormManager';
import GetEntity from '../../misc/GetEntity';
import GlueRollMachineView from './GlueRollMachineView';
import PrintDialog from '../Print/PrintDialog';
import GlueRoll_MachinesPrint from './GlueRoll_MachinePrint';
//Actions
import { DeleteGlueRoll, LoadGlueRoll, GetGlueRollMeasurementsByParentID, GetGlueRollPicturesByParentID,
     AddGlueRollMeasurementsByLineID,AddGlueRollPicturesByLineID, SetGlueRollName} from '../../actions/GlueRollActions';
import { GetPlantMachines } from '../../actions/MachineActions'
import { GetPlantMachineVessels } from '../../actions/MachineVesselsActions';
import { GetPlantMachineFlutes } from '../../actions/MachineFlutesActions';
import { enqueueSnackbar, closeSnackbar } from '../../actions/NotificationActions';
import { ConfirmAction } from '../../actions/ConfirmActionActions';
import { UpdateAction } from '../../actions/DataActions';
import { StartLogin } from '../../actions/AuthActions';

const constants = Constants;
const useStyles = makeStyles((theme) => ({
    header: {
        borderBottom: 'solid'
    },
}));
const GlueRollView = props => {
    const classes = useStyles();
    const params=useParams();
    const grID = params.SurveyID;
    const [printOpen, setPrint] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { glueRoll, online, authToken, offlineMode } = useSelector(state => {
        return {
            glueRoll: state.GlueRolls.find(gr => gr.id == grID),
            online: state.offline.online,
            authToken: state.Auth.token,
            offlineMode: state.Auth.offlineMode
        };
    }, shallowEqual);
    //Get Latest Data and Check if still valid.
    useEffect(() => {
        if (grID) {
            //Pull HeatVessel Data
            if (authToken && !offlineMode && online) {
                GetEntity(constants.URLs.GlueRoll + grID, online, authToken)
                    .then(ret => {
                        if (ret.complete && ret.valid) {
                            dispatch(LoadGlueRoll(ret.data));
                            if(ret.data.glueRollType===0){
                                dispatch(GetGlueRollMeasurementsByParentID(ret.data.id));
                            } else {
                                dispatch(GetGlueRollPicturesByParentID(ret.data.id));
                            }
                        }
                    })
                    .catch(ret => {
                        if (!glueRoll) {
                            const newNotification = {
                                message: 'Could not load Glue Roll Data Error:' + ret.error,
                                options: {
                                    key: new Date().getTime() + Math.random(),
                                    variant: 'error',
                                    action: key => (
                                        <Button onClick={() => closeSnackbar(key)}>dismiss</Button>
                                    ),
                                },
                            };
                            dispatch(enqueueSnackbar(newNotification));
                            navigate("/", { replace: true }); //replace
                        }
                    });
            }
        } else {
            //Something went wrong go home
            navigate("/", { replace: true }); //replace
        }
    }, [grID, online, authToken]);
    const plantID = usePlantIDFromVisitID(glueRoll ? glueRoll.visitID : null);
    useEffect(() => {
        if (plantID) {
            dispatch(GetPlantMachines(plantID));
            dispatch(GetPlantMachineVessels(plantID));
            dispatch(GetPlantMachineFlutes(plantID));
        }
    }, [plantID])
    //Get Request Status
    //If Request Status complete==true and heatVessel==null then we have a bad id or cannot pull offline data. 
    const handleDel = () => {
        const visitID = glueRoll.visitID;
        dispatch(ConfirmAction("Do you want to delete this GlueRoll Survey?", DeleteGlueRoll(glueRoll), null,
            (confirmed) => {
                if (confirmed) {
                    navigate('/VisitOverview/' + visitID, { replace: true });
                }
            }));
    }
    const handleClose = () => {
        navigate(-1);
    }
    const handleLineChange = newLineID => {
        dispatch(UpdateAction(constants.Actions.GlueRoll, 'lineID', glueRoll, newLineID));
        if(glueRoll.glueRollType===0){
            dispatch(AddGlueRollMeasurementsByLineID(newLineID, grID));
        } else {
            dispatch(AddGlueRollPicturesByLineID(newLineID, grID));
        }
        dispatch(SetGlueRollName(glueRoll, newLineID));
    }
    const handlePrint =()=>{
        navigate('/GlueRollPrint/'+glueRoll.id);
    }
    if (!glueRoll) {
        return <div>Loading Glue Roll Data from Database.</div>;
    }
    let grType='Measurements';
    if(glueRoll.glueRollType===1){
        grType='Pictures';
    }
    return (
        <>
        <Grid container>
            <Grid item xs={12}>
                <DataForm actionType={constants.Actions.GlueRoll} data={glueRoll}>
                    <Grid container spacing={3}>
                    <Grid item xs={4} sm={4} align="left">
                <Button
                    variant="contained"
                    startIcon={<PrintIcon />}
                    onClick={handlePrint}>
                    Print
                </Button>
            </Grid>
            <Grid item xs={4} sm={4} >
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<DeleteIcon />}
                    onClick={handleDel}
                >Delete
                </Button>
            </Grid>
            <Grid item xs={4} sm={4} align="right">
                <Button variant="contained" startIcon={<CloseIcon />} onClick={handleClose}>
                    Close
                </Button>
            </Grid>
            <Grid item md={4}>Glue Roll {grType} Survey</Grid>
            <Grid item md={4}><PlantLink plantID={plantID} /></Grid>
            <Grid item md={4}><DataDate name={'date'} label={'Date'} TextFieldProps={{ size: 'small' }} /></Grid>
                    </Grid>
                </DataForm>
                </Grid>
                <Grid item lg={12}>
                    <GlueRollMachineView glueRoll={glueRoll} onLineChange={handleLineChange} plantID={plantID} />
                </Grid>
            </Grid>
            <PrintDialog printOpen={printOpen} onClose={() => setPrint(false)} title={"GlueRoll_Report"}>
                <GlueRoll_MachinesPrint glueRoll={glueRoll} plantID={plantID} />
            </PrintDialog>
        </>
    );
}
export default GlueRollView;