import Constants from '../constants';
import produce from "immer";
import { UpdateArray, UpdateArrayRange } from '../misc/ArrayFuncs';
const initDataState = [];
const IngredientVerifications = (state = initDataState, action) => {
    switch (action.type) {
        //ADD
        case Constants.Actions.IngredientVerification_Add:
            return UpdateArray(action.payload, state);
        case Constants.Actions.IngredientVerification_Add_Rollback:
            const ivId = state.findIndex(iv => iv.id === action.meta.newIngVer.id);
            if (ivId !== -1) {
                return produce(state, pd => { pd.splice(ivId, 1) });
            } else {
                return state;
            }
        case Constants.Actions.IngredientVerification_Add_Commit:
            return UpdateArray(action.payload?.data, state);
        //Update
        case Constants.Actions.IngredientVerification_Update:
            return UpdateArray(action.payload.update, state);
        case Constants.Actions.IngredientVerification_Update_Commit:
            return UpdateArray(action.payload?.data, state);
        case Constants.Actions.IngredientVerification_Update_Rollback:
            return UpdateArray(action.meta.data.current, state);
        //Delete
        case Constants.Actions.IngredientVerification_Delete:
            return UpdateArray(produce(action.payload, d => {
                d.deleted = true;
            }), state);
        case Constants.Actions.IngredientVerification_Delete_Commit:
            return state;
        case Constants.Actions.IngredientVerification_Delete_Rollback:
            return UpdateArray(action.meta.delIngVer, state);
        case Constants.Actions.IngredientVerification_ClearData:
            return initDataState;
        //Get
        case Constants.Actions.IngredientVerification_GetByVisit:
            return UpdateArrayRange(action.data, state);
        default:
            return state;
    }
}
export default IngredientVerifications;