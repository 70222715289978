import Constants from '../constants';
import produce from "immer";
import { UpdateArray,UpdateArrayRange } from '../misc/ArrayFuncs';
const initDataState = [];
//Not Normalized
const Formulas = (state = initDataState, action) => {
    switch (action.type) {
        case Constants.Actions.Formula_ReceiveByPlant:
            return UpdateArrayRange(action.data, state);
        //Add
        case Constants.Actions.Formula_Add:
            return [...state, action.payload];
        case Constants.Actions.Formula_Add_Rollback:
            const lineInd = state.findIndex(line => line.id === action.meta.newFormula.id);
            if (lineInd !== -1) {
                return produce(state, pd => { pd.splice(lineInd, 1) });
            } else {
                return state;
            }
        case Constants.Actions.Formula_Add_Commit:
            return UpdateArray(action.payload?.data, state);
        //End Add
        //Update
        case Constants.Actions.Formula_Update:
            return UpdateArray(action.payload.update, state);
        case Constants.Actions.Formula_Update_Commit:
            return UpdateArray(action.payload?.data, state);
        case Constants.Actions.Formula_Update_Rollback:
            return UpdateArray(action.meta.data.current, state);
        //End Update
        case Constants.Actions.Formula_ClearData:
            return [];
        case Constants.Actions.Formula_Delete:
            return UpdateArray(produce(action.payload, d => {
                d.deleted = true;
            }), state);
        case Constants.Actions.Formula_Delete_Commit:
            return state;
        case Constants.Actions.Formula_Delete_Rollback:
            return UpdateArray(action.meta.delFormula, state);
        default:
            return state;
    };
}
export default Formulas;