import Constants from '../../constants';
//Libraries
import React, { useEffect, useContext, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import  {useNavigate, useParams } from 'react-router-dom';
import { useReportUser,usePlantDataFromVisitID, useVisitData, useReportName } from '../../misc/UseFuncs';
import { GetGLMachinesByGLID, GetGlueLineByID } from '../../selectors/GlueLineSelectors';
import { KeyItemsFilteredByArray } from '../../selectors/KeyItemSelectors';
import { AttachmentsByFilterArray } from '../../selectors/AttachmentSelectors';
import { GetAttachmentsByArray } from '../../actions/AttachmentActions';
import { ScaleImages } from '../../misc/ImageFuncs';
import ReportViewer from '../Report/ReportViewer';
const constants=Constants;
const GlueLinePrint = props => {
    
    const params=useParams();
    const navigate=useNavigate();
    const glID = params.SurveyID; 
    const printData =useGlueLinePrintData(glID);
    const handleClose=()=>{
        navigate(-1);
    }
   if(printData.Loaded){
    return <ReportViewer type="GlueLine" name="GlueLine" printData={printData}
            onClose={handleClose}  />;
   } 
   return <div>Loading Data</div>;
};
export default GlueLinePrint;
export const useGlueLinePrintData=(glID)=>{
    const dispatch=useDispatch();
    const [scaled,setScaled]=useState(false);
    const [scaledAttachements,setScaledAttachments]=useState([]);
    const [loaded,setLoaded]=useState(false);
    const glueLine = useSelector(s=>GetGlueLineByID(s,glID));
    const UserData = useReportUser();
    const plant = usePlantDataFromVisitID(glueLine?.visitID);
    const visit = useVisitData(glueLine?.visitID);
    const glMachines=useSelector(s=>GetGLMachinesByGLID(s,glID));
    const keyItems = useSelector(s=>KeyItemsFilteredByArray(s,glMachines));
    const reportName = useReportName("GlueLine",plant?.name,visit);
    const online =useSelector(s=>s.offline.online,shallowEqual);
    const LastSync=useSelector(s=>s.LastSync,shallowEqual);
    const Token=useSelector(s=>s.Auth.token,shallowEqual);
    useEffect(()=>{
        if (online){
            GetAttachmentsByArray(glMachines,dispatch,LastSync,Token)
            .then(()=>setLoaded(true),
                ()=>{
                alert("Get Attachments Failed");
                setLoaded(true);
                }
            );
        } else {
            setLoaded(true)
        }
    },[JSON.stringify(glMachines)]);
    let visitDate=null;
    if(visit){
        const d=new Date(visit.startDate);
        visitDate=+(d.getMonth()+1).toString().padStart(2,"0")+"/"+d.getDate().toString().padStart(2,"0")+"/"+d.getFullYear();
    }
    const attachments=useSelector(state=>AttachmentsByFilterArray(state,glMachines));
    const dJSON={GlueLine:glueLine, User:UserData, Plant:plant, Visit:visit,
        GLMachines:glMachines, KeyItems:keyItems, Attachments:scaledAttachements};
    
    const retVal ={Loaded:false,Data:{},ReportName:reportName,EmailSubject:'Glue Line '+plant?.name+' '+visitDate};
    if(loaded){
        if(scaled){
            const Data={GlueLineData:JSON.stringify(dJSON)};
            retVal.Loaded=true;
            retVal.Data=Data;
        } else {
            Promise.all(ScaleImages(attachments,180,180))
            .then((values)=>{
                setScaledAttachments(values);
                setScaled(true);
            });
        }
    } 
    return retVal;
};