import Constants from '../../constants';
//Modules
import React, { useEffect, useContext,useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { TextField, CheckboxWithLabel } from 'formik-mui';
import { Form, Formik,Field } from 'formik';

import { useReportUser } from '../../misc/UseFuncs';
import { useParams,useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import EmailForm from '../Common/EmailForm';
import VisitPDFGeneration from './VisitPDFGeneration';
import { SendEmail } from '../../actions/OutboundEmailActions';
const VisitEmailPDFs=(props)=>{
    const dispatch=useDispatch();
    const [send,setSend]=useState(false);
    const [emailTo, setEmailTo] = useState(null);
    const [sentEmail, setSentEmail] = useState(false);
    const [attachemnts, setAttachments] = useState([]);
    const params = useParams();

    const userData = useReportUser();
    const navigate=useNavigate();
    useEffect(()=>{
        if(attachemnts.length>0 && !sentEmail){
            dispatch(SendEmail(emailTo.To, emailTo.Cc, emailTo.Subject, emailTo.Body, attachemnts,emailTo.From,emailTo.Sender,emailTo.Phone));
            setSentEmail(true);
            navigate(-1);
        }
    },[attachemnts])
    const initVals={To:userData?.email,Cc:'',Subject:'Visit Reports',Body:'',From:userData?.email,Sender:userData?.fullName, Phone:userData?.phone};
    const onSubmit = (vals) => {
        setEmailTo(vals);
        setSend(true);
	};
    const onCancel=()=>navigate(-1);
    const OnPDFComplete = (atts) => {
       setAttachments(atts);
    }
    if(!send){
        return <EmailForm initVals={initVals} onCancel={onCancel} onSubmit={onSubmit} />;
    }
    if(send && attachemnts.length==0){
        return <div>
        <VisitPDFGeneration VisitID={params.VisitID} OnComplete={OnPDFComplete} />
        <Button onClick={()=>navigate(-1)}>Close</Button>
    </div>;
    }
    return <div> Done</div>;
    
};

export default VisitEmailPDFs;