import Constants from '../../constants';
//Modules
import React, { useState, useEffect } from 'react';
import {useNavigate} from 'react-router-dom';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
//MUI
import MUIDataTable from "mui-datatables";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
//Compontents
import CustomSelectToolbar from '../CustomSelectToolbar';
import AddCustomToolbar from "../AddCustomToolbar";
import PrintDialog from '../Print/PrintDialog';
import { GridDateFilter, DateFilterLogic, UpdateFilterList } from '../MetaData/GridDateFilter';
import { GridEnumFilter_CheckBox, EnumFilterLogic, EnumUpdateFilterList, EnumRenderValue } from '../MetaData/GridEnumFilter_CheckBox';
import { ActionItemStatus } from './ActionItem_MiscViews';
import ActionItemsPrint from './ActionItemsPrint';
//Actions
import { GetActionsByPlantID, AddActionItem } from '../../actions/ActionItemActions';
const constants = Constants;
const DateView = props => {
    if (props.value === "0001-01-01T00:00:00") {
        return null;
    }
    const d = new Date(props.value);
    return <div>{d.toLocaleDateString()}</div>;
};

const ActionItemsGrid = props => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const ActionItems = useSelector(state => state.ActionItems.filter(ai=>!ai.deleted));
    const [printOpen, setPrint] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const filteredActions = ActionItems
    .filter((a) => {
        if(a.deleted){return false;}
        if (props.plantID) { //Check if SelectedPlant is not {}
            if (a.plantID === props.plantID) {
                return true;
            } else { return false; }
        } else {
            return true;
        }
    });
    useEffect(() => {
        if (props.plantID) {
            dispatch(GetActionsByPlantID(props.plantID))
        }
    }, [props.plantID]);

    const openActionAddDialog = () => {
        const newID = uuidv4();
        dispatch(AddActionItem(newID, props.plantID));
        navigate('/ActionItem/' + newID);
    };
    const rowSelect = (rowsSelectedData, allRows, rowsSelected) => {
        setSelectedRows(rowsSelected);
    };
    const ClickOpen = () => {
        if (selectedRows.length > 0) {
            navigate('/ActionItem/' + filteredActions[selectedRows[0]].id);
        } else {
            alert("Select Visit From List");
        }
    };
    const ClickLink = () => {
        if (selectedRows.length > 0) {
            props.handleLink(filteredActions[selectedRows[0]]);
        } else {
            alert("Select Visit From List");
        }
    };
    const columns = [
        {
            name: "description", label: "Description", options: { filterType: 'textField' }
        },
        {
            name: "actionNeeded", label: "ActionNeeded", options: { filterType: 'textField' }
        },
        { name: "benefit", label: "Benefit", options: { display: false, filterType: 'textField' } },
        { name: "percentCompleted", label: "% Completed", options: { display: false } },
        {
            name: "status", label: 'Status', options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <ActionItemStatus value={value} />
                    );
                },
                filter: true,
                filterType: 'custom',
                customFilterListOptions: {
                    render: EnumRenderValue(constants.Enums.ActionStatus),
                    update: EnumUpdateFilterList
                },
                filterOptions: {
                    logic: EnumFilterLogic,
                    display: (filterList, onChange, index, column) => <GridEnumFilter_CheckBox filterList={filterList} onChange={onChange} index={index} column={column} enum={constants.Enums.ActionStatus} />
                }
            }
        },

        {
            name: "startDate", label: "Start", options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <DateView value={value} />
                    );
                },
                filter: true,
                filterType: 'custom',
                customFilterListOptions: {
                    update: UpdateFilterList
                },
                filterOptions: {
                    logic: DateFilterLogic,
                    display: (filterList, onChange, index, column) => <GridDateFilter filterList={filterList} onChange={onChange} index={index} column={column} />
                }
            }
        }];
    const options = {
        filterType: 'checkbox', selectableRows: 'single', selectableRowsOnClick: true, print: false,
        download: true,
        fixedHeader: true,
        responsive: 'standard',
        customToolbar: () => {
            return (
                <AddCustomToolbar  clickPrint={()=>setPrint(true)} />
            );
        },
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
            if (props.mode === "Link") {
                return <CustomSelectToolbar selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows} clickLink={ ClickLink }  />;
            } else {
                return <CustomSelectToolbar selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows} clickEdit={ ClickOpen } />;
            }
        },
        onRowSelectionChange: rowSelect,
        rowsSelected: selectedRows
    };
    let dataTable = <p>Select Plant</p>;
    if (props.plantID) {
        dataTable = <MUIDataTable
            title={"Action Items"}
            data={filteredActions}
            columns={columns}
            options={options}
        />;
    }
    return (
        <div>
            <Card>
                <CardContent>
                    {dataTable}
                </CardContent>
            </Card>
            <Dialog open={printOpen} onClose={() => setPrint(false)} fullScreen >
                <ActionItemsPrint actionItems={filteredActions} onClose={() => setPrint(false)} visit={props.visit}  />
            </Dialog>
        </div>
    );
};
export default ActionItemsGrid
