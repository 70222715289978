import React, { Component } from 'react';
import Constants from '../../constants';
import { LineEditView } from './LineEditView';
class CorrugatorLayoutTab extends Component {
    render() {
        return (   
            <LineEditView plantID={this.props.plantID} />
        );
    }
}
export default CorrugatorLayoutTab;