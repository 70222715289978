import React, { useState, useContext } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { DataFormContext, DataForm, DataTextBox, UpdateAction, DataAutoComplete, DataNumber, DataImageAttachment, DataSelect } from '../FormManager';
import Constants from '../../constants';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import { MachineFlutesGridView } from './MachineFlutesGridView';
import { MachineVesselsGridView } from './MachineVesselsGridView';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { v4 as uuid } from 'uuid';
const MachineConfigurationView = props => {
    const machines = useSelector(state => state.Machines, shallowEqual);
    const machine = machines.find(m => m.id == props.MachineID);
    let mView = <div>Select a Machine to Configure</div>;
    if (machine!= null) {
        mView = <MachineEditView Machine={machine} Machines={ machines} />;
    }
    return (
        <Card>
            <CardContent>
                <DataForm actionType={'Machines_Update'} data={machine}>
                    {mView}
                </DataForm>
            </CardContent>
        </Card>
        );
};
const MachineModelAC = props => {

    const contextDF = useContext(DataFormContext);
    let seedArray = [];
    if (contextDF.data.type == 1) {
        seedArray = SFModelSeed();
    }
    const options = [...new Set(props.Machines.filter(m => m.type === contextDF.data.type && m.model && !m.deleted).map(m=>m.model).concat(seedArray))];
    return (
        <>
        <div>
            <DataAutoComplete           
                name={'model'}
                options={options}
                label={ 'Model'}
            />  
        </div>
        <div>
            <DataTextBox name={'configuration'} label={'Notes' } multiline />
            </div>
            </>
        );
}
const HotPlateData = props => {
    const contextDF = useContext(DataFormContext);
    const options = [...new Set(props.Machines.filter(m => m.type === contextDF.data.type && m.ballastSystem && !m.deleted).map(m => m.ballastSystem))];
    return (
        <>
            
                <DataAutoComplete
                    name={'ballastSystem'}
                    options={options}
                    label={'Pressure Section'}
                />
            <DataNumber name={'width'} label={'Width'} int InputLabelProps={{
                shrink: true,
            }} />
            <DataNumber name={'numberHotPlates'} label={'Number Hot Plates'} int />
            <DataNumber name={'numberOfSteamZones'} label={'Number Of Steam Zones'} int />
            <DataNumber name={'hotPlatesPerZone'} label={'HotPlates Per Zone'} int />
            <DataNumber name={'zone1Pressure'} label={'Zone1 Pressure'} int />
            <DataNumber name={'zone2Pressure'} label={'Zone2 Pressure'} int />
            <DataNumber name={'zone3Pressure'} label={'Zone3 Pressure'} int />
            <DataNumber name={'zone4Pressure'} label={'Zone4 Pressure'} int />
        </>
    );
}
const GUData = props => {
    const options = [
        <option value={''} key={ 0 }>Select Type</option>,
        <option value={'Interfic'} key={1}>Interfic</option>,
        <option value={'Holddown Bar'} key={2}>Holddown Bar</option>,
        <option value={'RiderRoll'} key={3}>RiderRoll</option>];
    return (
        <>
            <div>
                <DataNumber name={'width'} label={'Width'} int InputLabelProps={{
                    shrink: true,
                }} />
            </div>
            <div>
                <DataSelect
                    name={'ballastSystem'}
                    options={options}
                    label={'Type'}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </div>
            
        </>
    );
}
const SFModelSeed = () => {
    return ["United Vanguard", "United Advantage", "BHS-MFP", "BHS-MFB", "BHS MFA", "MHI-50C", "MHI-Paser", "Langston 380", "Fosber"];
}
export const MachineEditView = props => {
   
    const valNumPreH = (num) => {
        if (num > 6) {
            return "Maximum 6 Allowed";
        }
        if (num < 0) {
            return "Invalid Number";
        }
        return null;
    };
    switch (props.Machine.type) {
        case 1: //SF
            return (
            <>
                <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                            <MachineModelAC Machine={props.Machine} Machines={props.Machines} />
                            <DataNumber name={'width'} label={'Width'} int InputLabelProps={{
                                shrink: true,
                            }} />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <DataImageAttachment name={'machineImage'} />
                        </Grid>
                </Grid>
                <Grid container spacing={3}>
                        <Grid item md={4} xs={12}>
                            <MachineFlutesGridView machineID={props.Machine.id} />
                        </Grid>
                        <Grid item md={8} xs={12}>
                            <MachineVesselsGridView machineID={props.Machine.id} />
                        </Grid>
                </Grid>
                </>
            );
        case 2: case 6: //Web Guide,Misc
            return (
                <>
                    <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                            <MachineModelAC Machine={props.Machine} Machines={props.Machines} />
                            
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <DataImageAttachment name={'machineImage'} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        
                        <Grid item xs={12}>
                            <MachineVesselsGridView machineID={props.Machine.id} />
                        </Grid>
                    </Grid>
                </>
            );
        case 3: //PreHeater
            return (
                <>
                    <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                            <MachineModelAC Machine={props.Machine} Machines={props.Machines} />
                            <DataNumber name={'numberHotPlates'}
                                label={'Number PreHeaters'} int
                                validate={valNumPreH }
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <DataImageAttachment name={'machineImage'} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <MachineVesselsGridView machineID={props.Machine.id} />
                        </Grid>
                    </Grid>
                </>
            );
        case 4://Glue Unit
            return (
                <>
                    <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                            <MachineModelAC Machine={props.Machine} Machines={props.Machines} />
                            <GUData />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <DataImageAttachment name={'machineImage'} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>

                        <Grid item xs={12}>
                            <MachineVesselsGridView machineID={props.Machine.id} />
                        </Grid>
                    </Grid>
                </>
            );
        case 5: //HotPlates
            return (
                <>
                    <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                            <MachineModelAC Machine={props.Machine} Machines={props.Machines} />
                            <HotPlateData Machine={props.Machine} Machines={props.Machines} />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <DataImageAttachment name={'machineImage'} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>

                        <Grid item xs={12}>
                            <MachineVesselsGridView machineID={props.Machine.id} />
                        </Grid>
                    </Grid>
                </>
            );
        case 7:
        case 8:
        case 9:
        case 10: 
            return (
                <>
                    <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                            <MachineModelAC Machine={props.Machine} Machines={props.Machines} />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <DataImageAttachment name={'machineImage'} />
                        </Grid>
                    </Grid>
                </>
            );
        case 11:
            return (<>
                <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                        <MachineModelAC Machine={props.Machine} Machines={props.Machines} />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <DataImageAttachment name={'machineImage'} />
                    </Grid>
                    <Grid item xs={12}>
                        <MachineVesselsGridView machineID={props.Machine.id} />
                    </Grid>
                </Grid>
            </>);
        case 12: 
        return (<>
            <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                    <MachineModelAC Machine={props.Machine} Machines={props.Machines} />
                </Grid>
                <Grid item md={6} xs={12}>
                    <DataImageAttachment name={'machineImage'} />
                </Grid>
                <Grid item md={12} xs={12}>
                <DataTextBox name={'ballastSystem'} label={'Description' } multiline />
                <DataNumber name={'width'} label={'HP'} int InputLabelProps={{
                    shrink: true,
                }} />
                  <DataNumber name={'zone1Pressure'} label={'Target'} int InputLabelProps={{
                    shrink: true,
                }} />
                 <DataNumber name={'zone2Pressure'} label={'High Pressure Receiver'} int InputLabelProps={{
                    shrink: true,
                }} />
                </Grid>
            </Grid>
        </>);
        case 13:
        case 14:
        case 15:
        case 16:
        case 17:
            return (<>
                <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                        <MachineModelAC Machine={props.Machine} Machines={props.Machines} />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <DataImageAttachment name={'machineImage'} />
                    </Grid>
                    
                </Grid>
            </>);
        default: 
            return null;
    }
    
}
export default MachineConfigurationView;