import Constants from '../constants';
import produce from "immer";
import { UpdateArray, ChangeOrder, UpdateArrayRange } from '../misc/ArrayFuncs';
const initDataState = null;
//Not Normalized
const ConfirmAction = (state = initDataState, action) => {
    switch (action.type) {
        case Constants.Actions.ConfirmAction.Add:
            return action.confirmObj;
        case Constants.Actions.ConfirmAction.ClearData:
            return initDataState;
        default:
            return state;
    };
}
export default ConfirmAction;