import Constants from '../../constants';
//Modules
import React, { useEffect, useContext, useState,useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Button from '@mui/material/Button';
import { createSelector } from 'reselect';
import { Stimulsoft } from 'stimulsoft-reports-js/Scripts/stimulsoft.viewer';
import 'stimulsoft-reports-js/Css/stimulsoft.viewer.office2013.whiteblue.css';

import { GetReports } from '../../actions/ReportActions';
import { ReportsByTypeName,LoadReport,encode } from './ReportCommon';
const useReportToPDF = (reportType,reportName,printData,key,addAttachment,generate) =>{
	const reportData = useSelector(state => ReportsByTypeName(state, reportType, reportName));
	if (reportData && printData.Loaded && generate) {
    const report = LoadReport(reportData, printData.ReportName, printData.Data);
		report.renderAsync(function () {
			report.exportDocumentAsync((pdfData) => {
				
				// Converting Array into buffer
				const pdf64Str = encode(pdfData);
				//const reverse = decode(pdf64Str);
				// File System module
				//var fs = require('fs');
				// Saving string with rendered report in PDF into a file
				//fs.writeFileSync('./SimpleList.pdf', buffer);
				const attachment = { data: pdf64Str, fileName: printData.ReportName + ".pdf", mimeType: "application/pdf" };
				addAttachment(key,attachment);
				//Stimulsoft.System.StiObject.saveAs(pdfData, reportName + ".pdf", "application/pdf");
			}, Stimulsoft.Report.StiExportFormat.Pdf);
		}); 
	}
}
export default useReportToPDF;