import Constants from '../../constants';
import React from 'react';
import Grid from '@mui/material/Grid';
import { DataForm, DataTextBox, DataDate, DataSelect, DataNumber, DataFormContext, DataCheckbox } from '../FormManager';
import { KeyItemCheckBox } from '../KeyItems/KeyItemCheckBox';
const constants = Constants;
const SystemAudit = props => {
    return (
        <div style={{width:'100%'}}>
        <DataForm actionType={constants.Actions.Starch} data={props.starch}>
                <Grid container spacing={2}  >
                    <Grid item xs={3} container justifyContent="start"><DataCheckbox name={'tvc'} label={'TVC' } /></Grid>
                    <Grid item xs={6}><DataTextBox name={'tvc_Attention'} label={'TVC Attention'} fullWidth /></Grid>
                    <Grid item xs={3} >
                        <KeyItemCheckBox entity={props.starch} visitID={props.starch.visitID}
                            setReference={(entity) => { return "System Audit TVC " }} field={'tvc'}
                        entityType={constants.Surveys.Starch} edit={true} tabIndex="-1" defaultNote={props.starch.tvc_Attention}
                        />
                    </Grid>
                   

                    <Grid item xs={3} container justifyContent="start"><DataCheckbox name={'mixer'} label={'Mixer'} /></Grid>
                    <Grid item xs={6}><DataTextBox name={'mixer_Attention'} label={'Mixer Attention'} fullWidth /></Grid>
                    <Grid item xs={3}>
                        <KeyItemCheckBox entity={props.starch} visitID={props.starch.visitID}
                            setReference={(entity) => { return "System Audit Mixer" }} field={'mixer'}
                            entityType={constants.Surveys.Starch} edit={true} tabIndex="-1" defaultNote={props.starch.mixer_Attention}
                        />
                    </Grid>
                   

                    <Grid item xs={3} container justifyContent="start"><DataCheckbox name={'caustic'} label={'Caustic'} /></Grid>
                    <Grid item xs={6}><DataTextBox name={'caustic_Attention'} label={'Caustic Attention'} fullWidth /></Grid>
                    <Grid item xs={3}>
                        <KeyItemCheckBox entity={props.starch} visitID={props.starch.visitID}
                            setReference={(entity) => { return "System Audit Caustic" }} field={'caustic'}
                            entityType={constants.Surveys.Starch} edit={true} tabIndex="-1" defaultNote={props.starch.caustic_Attention}
                        />
                    </Grid>
                    

                    <Grid item xs={3} container justifyContent="start"><DataCheckbox name={'steamWaterLeak'} label={'Steam/Water Leak'} /></Grid>
                    <Grid item xs={6}><DataTextBox name={'steamWaterLeak_Attention'} label={'Steam/Water Leak Attention'} fullWidth /></Grid>
                    <Grid item xs={3}>
                        <KeyItemCheckBox entity={props.starch} visitID={props.starch.visitID}
                                setReference={(entity) => { return "System Audit Steam/Water Leak" }} field={'steamWaterLeak'}
                            entityType={constants.Surveys.Starch} edit={true} tabIndex="-1" defaultNote={props.starch.steamWaterLeak_Attention}
                        />
                    </Grid>
                    

                    <Grid item xs={3} container justifyContent="start"><DataCheckbox name={'borax'} label={'Borax'} /></Grid>
                    <Grid item xs={6}><DataTextBox name={'borax_Attention'} label={'Borax Attention'} fullWidth /></Grid>
                    <Grid item xs={3}>
                        <KeyItemCheckBox entity={props.starch} visitID={props.starch.visitID}
                            setReference={(entity) => { return "System Audit Borax" }} field={'borax'}
                            entityType={constants.Surveys.Starch} edit={true} tabIndex="-1" defaultNote={props.starch.borax_Attention}
                        />
                    </Grid>
                    

                    <Grid item xs={3} container justifyContent="start"><DataCheckbox name={'loadCell'} label={'Load Cell'} /></Grid>
                    <Grid item xs={6}><DataTextBox name={'loadCell_Attention'} label={'Load Cell Attention'} fullWidth /></Grid>
                    <Grid item xs={3}>
                        <KeyItemCheckBox entity={props.starch} visitID={props.starch.visitID}
                            setReference={(entity) => { return "System Audit Load Cell" }} field={'loadCell'}
                            entityType={constants.Surveys.Starch} edit={true} tabIndex="-1" defaultNote={props.starch.loadCell_Attention}
                        />
                    </Grid>
                   

                    <Grid item xs={3} container justifyContent="start"><DataCheckbox name={'storageTank'} label={'Storage Tank'} /></Grid>
                    <Grid item xs={6}>
                        <DataTextBox name={'storageTank_Attention'} label={'Storage Tank Attention'} fullWidth />
                    </Grid>
                    <Grid item xs={3}>
                        <KeyItemCheckBox entity={props.starch} visitID={props.starch.visitID}
                            setReference={(entity) => { return "System Audit Storage Tank" }} field={'storageTank'}
                            entityType={constants.Surveys.Starch} edit={true} tabIndex="-1" defaultNote={props.starch.storageTank_Attention}
                        />
                    </Grid>
                    
                </Grid> 
            </DataForm>
        </div>
    );
}
export default SystemAudit;
