import Constants from '../../constants';
//Libraries
import React, { useEffect, useContext, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { useThermalData } from '../../data/ThermalImagesData';
import { useNavigate, useParams } from 'react-router-dom';
import { useThermalImages, useThermalMachines, useVesselsByMachineID } from '../../data/ThermalImagesData';
import ReportViewer from '../Report/ReportViewer';
import { useReportUser, usePlantDataFromVisitID, useVisitData, useReportName } from '../../misc/UseFuncs';
import { usePlantIDFromVisitID } from '../MetaData/PlantOptions';
import { DataFormContext } from '../FormManager';
const ThermalImagesPrint = props => {
    const params = useParams();
    const navigate=useNavigate();
    const tID = params.SurveyID;
    const result = useThermalData(tID);
    const Thermal = result.data;
    const plantID = usePlantIDFromVisitID(Thermal?.visitID);
    const { Plant, KeyItems } = useSelector(state => {
        return {
            Plant: state.Plants.find(pl => pl.id == plantID),
            KeyItems: state.KeyItems
        };
    }, shallowEqual);
    const dispatch = useDispatch();
    const [printData, setprintData] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const UserData = useReportUser();
    const images = useThermalImages(Thermal?.id);
    const Attachments = useSelector(state => state.Attachments)

    
    useEffect(() => {
        if (Thermal) {
            let imageList = []
            
            if (images.length > 0) {
                images.forEach(img => {
                    const imgLeft = Attachments.find(a => a.id === img.leftImageID && !a.deleted)
                    const imgRight = Attachments.find(a => a.id === img.rightImageID && !a.deleted)
                    let leftImageUrl = ''
                    let rightImageUrl = ''
                    if (imgLeft !== undefined && imgLeft !== null) {
                        leftImageUrl = "data:" + imgLeft.mime + ";base64," + imgLeft.data
                    }
                    if (imgRight !== undefined && imgRight !== null) {
                        rightImageUrl = "data:" + imgRight.mime + ";base64," + imgRight.data
                    }
                    let isImage1Null = true
                    let isImage2Null = true
                    if (leftImageUrl === '') isImage1Null = false
                    if (rightImageUrl === '') isImage2Null = false
                    const keyData = KeyItems.find(k => k.entityID === img.id && !k.deleted)                   
                    console.log('img.boardCombo', img.boardCombo)
                    imageList.push(
                        {
                            Vessel: img.vesselName === null || img.vesselName === '' ? '--' : img.vesselName,
                            BoradCombination: img.boardCombo === null || img.boardCombo === '' ? '--' : img.boardCombo,
                            Comment: img.comments === null || img.comments === '' ? '--' : img.comments,
                            Machine: img.machineName === null || img.machineName === '' ? '--' : img.machineName,
                            isKyeItem: keyData ? true : false,
                            isImage1: isImage1Null,
                            isImage2: isImage2Null,
                            ImageURL1: leftImageUrl,
                            ImageURL2: rightImageUrl,
                        }
                    )
                });
            }

            let printObj = {
                HeaderObj: {
                    addedDate: Thermal.createdDate,
                    plantName: Plant.name,
                    LineName: Thermal.name
                },
                ThermalDataObj: imageList
            }
            setprintData({ ...printObj })

        }
    }, [Thermal]);


    const handleClose = () => {
        navigate(-1);
    }
    const visit = useVisitData(Thermal?.visitID);
const reportName=useReportName("Theraml Images", Plant?.name, visit);
    const Data ={ ThermalImages:JSON.stringify(printData)};
    let reportData = {Loaded:true,Data:Data,ReportName:reportName,EmailSubject:'Theraml Images '+Plant?.name};

    if (reportData === null) {
        return <div>Loading...</div>
    }

    

    // return <div>Print Coming Soon</div>
    return <ReportViewer type="ThermalImages" name="ThermalImages" printData={reportData} onClose={handleClose}  />;
}
export default ThermalImagesPrint
export const useThermalImagesPrintData = (thermalID) => {
    const dispatch = useDispatch();
    const [scaled, setScaled] = useState(false);
    const [scaledAttachements, setScaledAttachments] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const Thermal = useThermalData(thermalID);
    const UserData = useReportUser();
    //const plant = usePlantDataFromVisitID(glueLine?.visitID);
    //const visit = useVisitData(glueLine?.visitID);
    return {};

}