import React, { useState } from 'react';
import Constants from '../../constants';
import { AddMachineFlute, DeleteMachineFlute, ChangeMachineFluteOrder } from '../../actions/MachineFlutesActions';
import { useSelector, useDispatch } from 'react-redux';
import AddCustomToolbar from "../AddCustomToolbar";
import CustomSelectToolbar from '../CustomSelectToolbar';
import MUIDataTable from "mui-datatables";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Checkbox from '@mui/material/Checkbox';
import { Formik, Form, Field } from 'formik';
import { TextField, CheckboxWithLabel } from 'formik-mui';
import { AutoCompleteFormik } from '../FormikUI/AutoCompleteFormik';
import { FluteName } from '../MetaData/FluteViews';
import { SelMachineFlutesByMachineID } from '../../selectors/PlantSelectors';
export const MachineFlutesGridView = props => {
    //const classes = useStyles();
    const dispatch = useDispatch();
    const mfData = useSelector(state => SelMachineFlutesByMachineID(state,props.machineID));
    const [selectedRows, setSelectedRows] = useState([]);
    const columns = [{
        name: "flute"
        , label: "Flute"
        , options: {
            filter: true,
            customBodyRender: (value, tableMeta, updateValue) => {
                return (
                    <FluteName value={value} />
                );
            },
        }
    }];
    const ClickDelete = () => {
        dispatch(DeleteMachineFlute(mfData[selectedRows[0]]));
        setSelectedRows([]);
    };
    const ClickAdd = (val) => {
        //Check to See if Flute already exists
        const ef = mfData.find(mf => mf.flute == val);
        if (ef) {
            alert("Flute Already Added"); //TODO: Fix Alert
        } else {
            const newOrder = mfData.reduce((acc, val) => acc + 1, 0)+1;
            dispatch(AddMachineFlute(props.machineID, val, newOrder ))
        }
    };
    const rowSelect = (rowsSelectedData, allRows, rowsSelected) => {
        setSelectedRows(rowsSelected);
    };
    const ChangeOrder = (isUp) => {
        dispatch(ChangeMachineFluteOrder(mfData[selectedRows[0]], !isUp));
        let newRow = selectedRows[0];
        if (!isUp) {
            newRow += 1;
        } else {
            newRow -= 1;
        }
        if (newRow >= 0 && newRow < mfData.length) {
            setSelectedRows([newRow]);
        }
    };
    const options = {
        filterType: 'checkbox', selectableRows: 'single', selectableRowsOnClick: true,
        print: false, pagination: false, download: false, searchOpen: false, sort: false,
        sortFilterList: false, viewColumns: false, filter: false, search: false,
        fixedHeader: true,
        responsive: 'standard',
        customToolbar: () => {
            return (
                <FluteAddButton handleAdd={ClickAdd} />
            );
        },
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
            return <CustomSelectToolbar selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows}
                clickDelete={ClickDelete} changeOrder={ChangeOrder} />;
        },
        onRowSelectionChange: rowSelect,
        rowsSelected: selectedRows,
        textLabels: {
            selectedRows: { text: '' }
        }
    };
   
   
    return (
        <Card>
            <CardContent>
                <MUIDataTable
                    title={"Flutes"}
                    data={mfData}
                    columns={columns}
                    options={options}
                />
                
            </CardContent>
        </Card>
    );
}
export const FluteAddButton = props => {
    //const { classes } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const mItems = Object.keys(Constants.Enums.Flutes).map(key =>
        <MenuItem
            key={key}
            onClick={() => {
                const val = Constants.Enums.Flutes[key];
                props.handleAdd(val);
                handleClose();
            }}
        >
            {key}
        </MenuItem>
    );
    return (
        <React.Fragment>
            <Tooltip title={"custom icon"}>
                <IconButton onClick={handleClick} size="large">
                    <AddIcon  />
                </IconButton>
            </Tooltip>
            <Menu
                id="flute_add_menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                { mItems}
            </Menu>
        </React.Fragment>
    );
};
//export const MachineFluteFormDialog = props => {
//    return (
//        <Dialog open={props.modalOpen} onClose={props.closeModal} >
//            <Formik initialValues={props.initialValues} onSubmit={props.onSubmit}>
//                <Form>
//                    <DialogTitle >Add Corrugator Adhesive</DialogTitle >
//                    <DialogContent>
//                        <div>
//                            <AutoCompleteFormik
//                                name="item"
//                                options={props.mfData}
//                                label="Item"
//                                freeSolo
//                            />
//                        </div>
//                        <div>
//                            <AutoCompleteFormik
//                                name="product"
//                                options={props.mfData}
//                                label="Product"
//                                freeSolo
//                            />

//                        </div>
//                        <div>
//                            <AutoCompleteFormik
//                                name="vendor"
//                                options={props.mfData}
//                                label="Vendor"
//                                freeSolo
//                            />

//                        </div>
//                        <div>
//                            <Field
//                                component={TextField}
//                                name="price"
//                                type="number"
//                                label="Price"
//                            />
//                        </div>
//                    </DialogContent>
//                    <DialogActions>
//                        <Button type="submit">Save</Button>
//                        <Button onClick={props.closeModal}>Cancel</Button>
//                    </DialogActions>
//                </Form>
//            </Formik>
//        </Dialog >
//    );
//}