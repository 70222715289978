import Constants from '../constants';
const constants = Constants;
export const AlertAction = (label,confirm, cancel,callback=null) => { 
    return {
        type: constants.Actions.AlertAction.Alert,
        confirmObj: {
            label: label,
            AlertAction: confirm,
            cancelAction: cancel,
            callback: callback
        }
    }
}