import Constants from '../../constants';
import React, { useEffect, useContext, useState } from 'react';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import CCI_Logo from "../../images/CCI_Logo.svg"
import SystemAudit from './SystemAudit';
import PrintFooter from '../Print/PrintFooter';
const useStyles = makeStyles((theme) => ({
    logoImg: {
        width: "80%"
    }, thinLine: {
        border: '2px solid darkgray',
        borderRadius: '2px',
        width: '100%'

    }
}));
const StarchAuditPrint = props=>{
    const classes = useStyles();
    const printHeader =  <Grid container spacing={3}>
            <Grid item xs={4}>
                <img src={CCI_Logo} style={{ width: '300px' }} />
            </Grid>
            <Grid item xs={5} style={{ paddingTop: '30px' }} >
                Starch Kitchen
            </Grid>
            <Grid item xs={3} style={{ paddingTop: '30px' }} >
                Printed:{new Date().toLocaleDateString()}
            </Grid>
            </Grid>;
    return <div>
        {printHeader}
        <hr className={classes.thinLine}/>
        <SystemAudit starch={props.starch} plantID={props.plantID} />    
        <PrintFooter />
        </div>;
};
export default StarchAuditPrint;