import React, { useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
const LoginIcon = props => {
    const status = useSelector(s => {
        return {
            authenticated: s.Auth.authenticated,
            user:s.Auth.user
        }
    });
    if (!status.authenticated) {
        return <div>Login</div>;
    }
    return <Tooltip title="LoggedIn" aria-label="LoggedIn">
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <AccountCircle fontSize="large" />
            {status.user}
            </div>
    </Tooltip>;
    
}

export default LoginIcon;