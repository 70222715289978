import Constants from '../../constants';
//Modules
import React, { useEffect, useContext, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';
//MUI
import { makeStyles } from '@mui/styles';
import { GridDateFilter, DateFilterLogic, UpdateFilterList } from '../MetaData/GridDateFilter';
import Card from '@mui/material/Card';
import MUIDataTable from "mui-datatables";
import AddCustomToolbar from "../AddCustomToolbar";
import { createTheme, ThemeProvider } from '@mui/material/styles';
//Actions
import { UpdateStarchKitchenItem,AddNewStarchKitchenItem, DeleteStarchKitchenItem, GetSKItemsByVisitID } from '../../actions/StarchKitchenActions';
import { UpdateAction } from '../../actions/DataActions';
import { AddDefaultStarchKitchenItems } from '../../actions/StarchKitchenActions';
//Selectors
import { SelVisitByID,SelInventoryByVisitID,SelMeasuresByVisitID,FormatLocation,SelSKItemsByVisitID } from '../../selectors/VisitSelectors';
const constants=Constants;
const StarchKitchenItemsView = ({visit}) =>{
    const KeyItems = useSelector(state => state.KeyItems.filter(ki=>!ki.deleted), shallowEqual);
    const [addOpen,setAddOpen]=useState(false);
    const items=useSelector(s=>SelSKItemsByVisitID(s,visit.id));
    const navigate = useNavigate();
    const online=useSelector(s=>s.offline.online,shallowEqual);
    const dispatch=useDispatch();
    let errorMsg=null;
    useEffect(()=>{
        console.log('check ---', items);
        if (visit?.id && items ){
            
            if(items.length<7 && !visit.starchKitchenCreated){
                console.log('call api for add-----------');
                dispatch(AddDefaultStarchKitchenItems(visit.id,items));
            }
        }
    }, [visit?.id, items?.length, visit?.starchKitchenCreated])
    useEffect(()=>{
        if (visit?.id && items ){
            if(items.length<7 && !visit.starchKitchenCreated){
                dispatch(UpdateAction('UpdateVisit','starchKitchenCreated',visit,true));
                // dispatch(AddDefaultStarchKitchenItems(visit.id,items));
            } else {
                if(online){
                    errorMsg="Pulling Starch Kitchen Items from Online Database";
                    dispatch(GetSKItemsByVisitID(visit.id));

                } else {
                    errorMsg="Could not load Starch Kitchen Items. To load please go online.";
                }
            }
        }

    }, [visit?.id, items?.length, visit?.starchKitchenCreated])

    useEffect(()=>{
        if ( items && KeyItems ){
            items.forEach(e => {
                let value =  KeyItems.find(k => {
                    let match = k.entityID === e.id;
                    if (match && e.name) {
                        match = e.name === k.entityField;
                    }
                    return match;
                });
              e.keyItem = value ? 'Yes' : '-';
              e.checkedVal = e.checked ? 'Yes' : '-';
            });
        }

    }, [items?.length, KeyItems])

    const columns = [
        {
            name: "name", label: "Name",
            options: {
                filter: true,
                filterType: 'custom',
                customFilterListOptions: {
                    update: UpdateFilterList
                },
                filterOptions: {
                    logic: DateFilterLogic,
                    display: (filterList, onChange, index, column) => <GridDateFilter filterList={filterList} onChange={onChange} index={index} column={column} />
                }
            }
        },
        { name: "checkedVal", label: "Checked" },
        { name: "attention", label: "Attention" },
        { name: "keyItem", label: "KeyItem" },
        
    ];

    const ClickAdd = () => {
        console.log('click add');
        navigate('/StartchKitchen/' + visit.id + '/' + 'add');
    }
    const clickEdit = () => {
        navigate('/StartchKitchen/' + visit.id + '/' + 'edit');
    }
    const clickPrint = () => {
        console.log('click clickPrint');
        navigate('/StartchKitchenPrint/' + visit.id);  
    }
    const options = {
        filterType: '', selectableRows: false, selectableRowsOnClick: false, print: false,
        download: false,
        fixedHeader: true,
        responsive: 'standard',
        sortOrder: { name: 'createdDate', direction: 'desc' },
        customToolbar: () => {
            return (
                <AddCustomToolbar onClick={ClickAdd} clickPrint={clickPrint} clickEdit={clickEdit} />
            );
        },
    };
const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
          styleOverrides:{ root: {
            paddingBlock: '10px !important',
          }}
        }
      }
    });

    return  <Card id="StarchKitchen">
         <ThemeProvider theme={getMuiTheme()}>
    <MUIDataTable 
            title={"Starch Kitchen"}
            data={items}
            columns={columns}
            options={options}
        />
        </ThemeProvider>
</Card>
    
}
export default StarchKitchenItemsView;
