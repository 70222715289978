import Constants from '../constants';
import { v4 as uuidv4 } from 'uuid';
import { FetchErrorLogger, FetchCatchHandler } from '../middlewares';
import produce from "immer";
import { GetRequest } from './RequestActions';
import { GetMachineVessels } from './MachineVesselsActions';
import { GetLineMachines } from './MachineActions';
import { UpdateAction } from './DataActions';
const constants = Constants;
export const AddNewHeatVessel = (newHV) => {
    if(!newHV.tool){
        newHV.tool='Temperature Crayon';
    }
    return {
        type: Constants.Actions.HeatVessel.Add,
        payload: newHV,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.HeatVessel, method: 'POST', body: JSON.stringify(newHV), headers: { 'content-type': 'application/json' }
                },
                commit: {
                    type: Constants.Actions.HeatVessel.Add_Commit, meta: { newHV }
                },
                rollback: {
                    type: Constants.Actions.HeatVessel.Add_Rollback, meta: { newHV }
                }
            }
        }
    }
};
export const LoadHeatVessel = (data) => {
    return { type: constants.Actions.HeatVessel.GetByID, data: data }
};
export const GetHeatVesselsByVisitID = (visitID) => {
    return (dispatch, getState) => {
        //offline.online
        const state = getState();
        if (state.offline.online) {
            let fetchTask = fetch(Constants.URLs.HeatVessel + "?visitID=" + visitID, { headers: { 'Authorization': 'Bearer ' + state.Auth.token } })
                .then(FetchErrorLogger("GetHeatVesselsByVisitID", visitID, dispatch))
                .then(response => response.json())
                .then(hvs => {
                    //Ignore 404 errors
                    if (Array.isArray(hvs)) {
                        dispatch({ type: Constants.Actions.HeatVessel.GetByVisitID, data: hvs, visitID: visitID });
                        hvs.forEach(iv => {
                            dispatch(GetHeatVesselTempsByParentID(iv.id));
                        });
                    }
                })
                .catch(FetchCatchHandler("GetHeatVesselsByVisitID", visitID, dispatch))
        }
    }
}
export const CreateHV_Temps = (hvID, lineID) => {
    return (dispatch, getState) => {
        
        
        //Compare Machine Vessels to HV Temps
        const state = getState();
        const machines = state.Machines.filter(m => m.lineID == lineID && !m.deleted);
        machines.forEach(machine => {
            //Load Machine Vessels
            
            const curHVT = state.HeatVesselTemps.filter(hvt => hvt.heatVesselSurveyID == hvID && hvt.machineID == machine.id && !hvt.deleted);
            const macName = machine ? machine.name : '';
            const macVessels = state.MachineVessels.filter(mv => mv.machineID == machine.id && !mv.deleted);
            macVessels.forEach(mv => {
                if (!curHVT.some(e => e.machineVesselID == mv.id)) {
                    //Need to Add New Heat Vessel Temp
                    //Create Ones that do not exist
                    dispatch(AddHeatVesselTemp({
                        heatVesselSurveyID:hvID,
                        machineID:machine.id,
                        machineVesselID:mv.id,
                        vesselName: mv.vessel.name,
                        top:'',
                        bottom:'',
                        order: mv.order,
                        machineName: macName
                    }));
                }
            });
        });
    }
}
export const AddHeatVesselTemp = (newHVTemp) => {
    newHVTemp.id = uuidv4();
    return {
        type: Constants.Actions.HeatVesselTemp.Add,
        payload: newHVTemp,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.HeatVesselTemp, method: 'POST', body: JSON.stringify(newHVTemp), headers: { 'content-type': 'application/json' }
                },
                commit: {
                    type: Constants.Actions.HeatVesselTemp.Add_Commit, meta: { newHVTemp }
                },
                rollback: {
                    type: Constants.Actions.HeatVesselTemp.Add_Rollback, meta: { newHVTemp }
                }
            }
        }
    }
};
export const UpdateHeatVesselTemp = (updHVTemp, curHVTemp) => {
    console.log('put check __________________________________________________________________________  88');
    const data = { update: updHVTemp, current: curHVTemp }
    return {
        type: Constants.Actions.HeatVesselTemp.Update,
        payload: data,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.HeatVesselTemp + updHVTemp.id, method: 'PUT', body: JSON.stringify(updHVTemp), headers: { 'content-type': 'application/json' }
                },
                commit: {
                    type: Constants.Actions.HeatVesselTemp.Update_Commit, meta: { data }
                },
                rollback: {
                    type: Constants.Actions.HeatVesselTemp.Update_Rollback, meta: { data }
                }
            }
        }
    }
};
export const ClearData = () => {
    return (dispatch, getState) => {
        dispatch({ type: Constants.Actions.HeatVessel.ClearData });
        dispatch({ type: Constants.Actions.HeatVesselTemp.ClearData });
    }
    
};
export const GetHeatVesselTempsByParentID = (parentID) => {
    return (dispatch, getState) => {
        //offline.online
        const state = getState();
        if (state.offline.online) {
            let fetchTask = fetch(Constants.URLs.HeatVesselTemp + "?SurveyID=" + parentID, { headers: { 'Authorization': 'Bearer ' + state.Auth.token } })
                .then(FetchErrorLogger("GetHeatVesselTempsByParentID", parentID, dispatch))
                .then(response => response.json())
                .then(ivs => {
                    //Ignore 404 errors
                    if (Array.isArray(ivs)) {
                        dispatch({ type: Constants.Actions.HeatVesselTemp.GetByParentID, data: ivs, parentID: parentID });

                    }
                })
                .catch(FetchCatchHandler("GetHeatVesselTempsByParentID", parentID, dispatch))
        }
    }
};
export const DeleteHeatVesselByID=(delHVID)=>{
    return (dispatch,getState)=>{
        const delHV=getState().HeatVessels.find(hv=>hv.id===delHVID);
        dispatch(DeleteHeatVessel(delHV));
    }
};
export const DeleteHeatVessel = (delHeatVessel) => {
    return {
        type: Constants.Actions.HeatVessel.Delete,
        payload: delHeatVessel,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.HeatVessel + delHeatVessel.id, method: 'DELETE'
                },
                commit: {
                    type: Constants.Actions.HeatVessel.Delete_Commit, meta: { delHeatVessel }
                },
                rollback: {
                    type: Constants.Actions.HeatVessel.Delete_Rollback, meta: { delHeatVessel }
                }
            }
        }
    }
};
export const SetHeatVesselName = (heatVessel, lineID) => {
    return (dispatch, getState) => {
        const state = getState();
        //Set GlueRoll Name After Line is selected. 
        const line = state.Lines.find(l => l.id == lineID);
        let lineName = '';
        if (line) {
            lineName = line.name;
        }
        const d = new Date();
        const newName = lineName + ' - ' + d.toLocaleDateString()
        dispatch(UpdateAction(constants.Actions.HeatVessel, 'name', heatVessel, newName));
    }
};