import React, { useState, useEffect } from 'react';
import { FormulaChangeGrid } from './FormulaChangeGrid';
import { useParams } from 'react-router-dom';
export const FormulaHistoryView = props => {
    console.log("Formula History View Render");
    const params=useParams();
    return <div>
        History Grid
        <FormulaChangeGrid formulaID={params.FormulaID} />
    </div>
};