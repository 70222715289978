import Constants from '../constants';
import { v4 as uuidv4 } from 'uuid';
import { FetchErrorLogger, FetchCatchHandler } from '../middlewares';
import produce from 'immer';
export const AddNewThermal = (id, visitID) => {
    return (dispatch, getState) => {
        //Create Header
        const d = new Date();
        const newT = {};
        newT.id = id;
        newT.visitID = visitID;
        newT.date = new Date().toJSON();
        dispatch({
            type: Constants.Actions.Thermal.Add,
            payload: newT,
            meta: {
                offline: {
                    effect: {
                        url: Constants.URLs.Thermals, method: 'POST', body: JSON.stringify(newT), headers: { 'content-type': 'application/json' }
                    },
                    commit: {
                        type: Constants.Actions.Thermal.Add_Commit, meta: { newT }
                    },
                    rollback: {
                        type: Constants.Actions.Thermal.Add_Rollback, meta: { newT }
                    }
                }
            }
        });
        //Init First Thermal Image
    }
}
export const GetThermalsByVisitID = (visitID) => {
    return (dispatch, getState) => {
        //offline.online
        const state = getState();
        if (state.offline.online) {
            let fetchTask = fetch(Constants.URLs.Thermals + "?visitID=" + visitID, { headers: { 'Authorization': 'Bearer ' + state.Auth.token } })
                .then(FetchErrorLogger("GetThermalsByVisitID", visitID, dispatch))
                .then(response => response.json())
                .then(hvs => {
                    //Ignore 404 errors
                    if (Array.isArray(hvs)) {
                        dispatch({ type: Constants.Actions.Thermal.GetByVisitID, data: hvs, visitID: visitID });
                        hvs.forEach(iv => {
                            dispatch(GetThermalImagesByParentID(iv.id));
                        });
                    }
                })
                .catch(FetchCatchHandler("GetThermalsByVisitID", visitID, dispatch))
        }
    }
}
export const LoadThermal=(Thermal)=>{
    return {type:Constants.Actions.Thermal.GetByID, data:Thermal};
}
export const GetThermalImagesByParentID = (parentID) => {
    return (dispatch, getState) => {
        //offline.online
        const state = getState();
        if (state.offline.online) {
            let fetchTask = fetch(Constants.URLs.ThermalImages + "?SurveyID=" + parentID, { headers: { 'Authorization': 'Bearer ' + state.Auth.token } })
                .then(FetchErrorLogger("GetThermalImagesByParentID", parentID, dispatch))
                .then(response => response.json())
                .then(ivs => {
                    //Ignore 404 errors
                    if (Array.isArray(ivs)) {
                        dispatch({ type: Constants.Actions.ThermalImages.GetByParentID, data: ivs, parentID: parentID });

                    }
                })
                .catch(FetchCatchHandler("GetThermalImagesByParentID", parentID, dispatch))
        }
    }
};
export const DeleteThermalByID=(delThemID)=>{
    return (dispatch,getState)=>{
        const delT=getState().Thermals.find(t=>t.id===delThemID);
        dispatch(DeleteThermal(delT));
    }
};
export const DeleteThermal = (delThermal) => {
    return {
        type: Constants.Actions.Thermal.Delete,
        payload: delThermal,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.Thermals + delThermal.id, method: 'DELETE'
                },
                commit: {
                    type: Constants.Actions.Thermal.Delete_Commit, meta: { delThermal }
                },
                rollback: {
                    type: Constants.Actions.Thermal.Delete_Rollback, meta: { delThermal }
                }
            }
        }
    }
};
export const ClearThermalsData = () => {
    return (dispatch, getState) => {
        dispatch({ type: Constants.Actions.Thermal.ClearData });
        dispatch({ type: Constants.Actions.ThermalImages.ClearData });
    } 
};
export const UpdateThermal = (updThermal,curThermal) => {
    const data = { update: updThermal, current: curThermal }
    console.log('put check __________________________________________________________________________  28');
    return {
        type: Constants.Actions.Thermal.Update,
        payload: data,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.Thermals + updThermal.id, method: 'PUT', body: JSON.stringify(updThermal), headers: { 'content-type': 'application/json' }
                },
                commit: {
                    type: Constants.Actions.Thermal.Update_Commit, meta: { data }
                },
                rollback: {
                    type: Constants.Actions.Thermal.Update_Rollback, meta: { data }
                }
            }
        }
    }
};
export const SetThermalLineID=(lineID,Thermal)=>{
    return (dispatch, getState) => {
        const state = getState();
        //Set GlueRoll Name After Line is selected. 
        const line = state.Lines.find(l => l.id == lineID);
        let lineName = '';
        if (line) {
            lineName = line.name;
        }
        const d = new Date();
        const newName = lineName + ' - ' + d.toLocaleDateString();
        const updThermal=produce(Thermal,d=>{
            d.lineID=lineID;
            d.name=newName;
        });
        dispatch(UpdateThermal(updThermal,Thermal));
    }
}
export const AddNewThermalImage=(surveyID)=>{
    return (dispatch, getState) => {
        const state=getState();
        let newOrder=state.ThermalImages.filter(ti=>ti.thermalImageSurveyID===surveyID).reduce((order,ti)=>{
            if(ti.order>order)
                return ti.order;
            return order;
        },-1)+1;
        const newTImg={
            id:uuidv4(),
            thermalImageSurveyID:surveyID,
            order:newOrder
        };
        dispatch( {
            type: Constants.Actions.ThermalImages.Add,
            payload: newTImg,
            meta: {
                offline: {
                    effect: {
                        url: Constants.URLs.ThermalImages, method: 'POST', body: JSON.stringify(newTImg), headers: { 'content-type': 'application/json' }
                    },
                    commit: {
                        type: Constants.Actions.ThermalImages.Add_Commit, meta: { newTImg }
                    },
                    rollback: {
                        type: Constants.Actions.ThermalImages.Add_Rollback, meta: { newTImg }
                    }
                }
            }
        });
    }
}