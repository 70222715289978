import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';


import { BrowserRouter } from "react-router-dom";
import configureStore from './store/configureStore';
import AppView from './components/AppView';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { buildStore } from './core.js';


import { enqueueSnackbar } from './actions/NotificationActions';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
//Load Data
const { store, history } = buildStore();
LogRocket.init('fvjrcx/plantsurvey');
setupLogRocketReact(LogRocket);
ReactDOM.render(
    <Provider store={store}> 
    <BrowserRouter>
        <AppView />
        </BrowserRouter>
    </Provider>
    ,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
const swConfig ={
    onUpdate:(reg)=>{
        const registrationWaiting = reg.waiting;
        if (registrationWaiting) {
            registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
            registrationWaiting.addEventListener('statechange', e => {
            if (e.target.state === 'activated') {
                window.location.reload();
            }
            });
            store.dispatch(enqueueSnackbar({
                message: 'Updating to New Version',
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'info'
                }
            }));
        }
    },
    onSuccess:(reg)=>{
        console.log("svg on Success")
    }
}
serviceWorkerRegistration.register(swConfig);
reportWebVitals();