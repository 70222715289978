import Constants from '../../constants';
//Libraries
import React, { useEffect, useContext, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import {useNavigate,useParams} from 'react-router-dom';
//MUI
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import PrintIcon from '@mui/icons-material/Print';
import Add from '@mui/icons-material/Add';
import makeStyles from '@mui/styles/makeStyles';
//Components
import { DataForm, DataTextBox, DataDate, DataSelect, DataNumber, DataFormContext } from '../FormManager';
import { PlantLink } from '../MetaData/Links';
import { usePlantIDFromVisitID } from '../MetaData/PlantOptions';
import LineSingleSelect from '../Common/LineSingleSelect';
import PrintDialog from '../Print/PrintDialog';
//Actions
import { GetPlantMachines } from '../../actions/MachineActions'
import { GetPlantMachineVessels } from '../../actions/MachineVesselsActions';
import { GetPlantMachineFlutes } from '../../actions/MachineFlutesActions';
import { enqueueSnackbar, closeSnackbar } from '../../actions/NotificationActions';
import { ConfirmAction } from '../../actions/ConfirmActionActions';
import { UpdateAction } from '../../actions/DataActions';
import { useThermalData } from '../../data/ThermalImagesData';

import { AddNewThermalImage, DeleteThermal, SetThermalLineID } from '../../actions/ThermalImageActions';
import ThermalImagesList from './ThermalImagesList';
import { DateView } from '../MetaData/MiscViews';
const constants = Constants;
const useStyles = makeStyles((theme) => ({
    header: {
        borderBottom: 'solid'
    },
}));
const ThermalImagesView = props => {
    //use
    const params=useParams();
    const tID = params.SurveyID;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const result = useThermalData(tID);
    const Thermal = result.data;
    const plantID=usePlantIDFromVisitID(Thermal?.visitID);
    //Functions
    const lineChange=(val)=>{  
        dispatch(SetThermalLineID(val,Thermal));
        dispatch(AddNewThermalImage(tID));
    };
    const handleDel=()=>{
        const visitID = Thermal.visitID;
        dispatch(ConfirmAction("Do you want to delete this Thermal Images Survey?", DeleteThermal(Thermal), null,
            (confirmed) => {
                if (confirmed) {
                    navigate("/VisitOverview/" + visitID, { replace: true });
                }
            }));
    }; 
    //JSX
    const closeBtn=<Button variant="contained" startIcon={<CloseIcon />} onClick={()=>navigate(-1)}>Close</Button>;
    let addBtn=null;
    if(Thermal.lineID && Thermal.lineID!==Constants.EmptyGuid){
        addBtn=<Button variant="contained" startIcon={<Add />} onClick={()=>dispatch(AddNewThermalImage(tID))}>New Image</Button>
    }
    if (!Thermal) {
        return <div>{result.status} - {result.message} {closeBtn}</div>
    }
    
    return( 
    <Grid container spacing={3}>   
        <Grid item xs={4} sm={4} align="left">
            <Button
                variant="contained"
                startIcon={<PrintIcon />}
                onClick={() => navigate('/ThermalImagesPrint/'+tID)}>
                Print
            </Button>
        </Grid>
        <Grid item xs={4} sm={4} >
            <Button
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
                onClick={handleDel}
            >Delete
            </Button>
        </Grid>
        <Grid item xs={4} sm={4} align="right">
            {closeBtn}
        </Grid>
        <Grid item md={4}>Thermal Images</Grid>
        <Grid item md={4}><PlantLink plantID={plantID} /></Grid>
        <Grid item md={4}><DateView value={Thermal.date} /></Grid>
        <Grid item xs={12}>
            <LineSingleSelect plantID={plantID} onLineChange={lineChange} lineID={Thermal?.lineID} />
        </Grid>
        <Grid item xs={12}>
            <ThermalImagesList thermal={Thermal} />
        </Grid>
        <Grid item xs={12}>
            {addBtn}
        </Grid>
    </Grid>
    );
};
export default ThermalImagesView;

//