import Constants from '../constants';
import { UpdateAction } from './DataActions';
import { AddMachineVessel } from './MachineVesselsActions';
import { v4 as uuidv4 } from 'uuid';
import { FetchErrorLogger, FetchCatchHandler } from '../middlewares';
import { dispatch } from 'rxjs/internal/observable/pairs';
export const GetPlantMachines = (plantID) => {
    console.log('check caall 222222222222222222222222222222222222222222222222222222');
    return (dispatch, getState) => {
        //offline.online
        const state = getState();
        const lineIDs = state.Lines.filter(l => l.plantID === plantID && !l.deleted).map(l => l.id);
        if (state.offline.online) {
            let fetchTask = fetch(Constants.URLs.Machines + '?plantID=' + plantID, { headers: { 'Authorization': 'Bearer ' + state.Auth.token } })
                .then(FetchErrorLogger("GetPlantMachines", plantID, dispatch))
                .then(response => response.json())
                .then(machine => {
                    dispatch({ type: Constants.Actions.Machines_ReceiveByPlant, data: machine, plantID: plantID, lineIDs:lineIDs });
                })
                .catch(FetchCatchHandler("GetPlantMachines", plantID, dispatch))
        }
    }
}
export const GetLineMachines = (lineID) => {
    console.log('check 1 caall 1111111111111111111111111111111111111111111111111111');
    return (dispatch, getState) => {
        //offline.online
        const state = getState();
        const lineIDs = [lineID];
        if (state.offline.online) {
            let fetchTask = fetch(Constants.URLs.Machines + '?lineID=' + lineID, { headers: { 'Authorization': 'Bearer ' + state.Auth.token } })
                .then(FetchErrorLogger("GetLineMachines", lineID, dispatch))
                .then(response => response.json())
                .then(machine => {
                    dispatch({ type: Constants.Actions.Machines_ReceiveByPlant, data: machine, lineIDs: lineIDs });
                })
                .catch(FetchCatchHandler("GetLineMachines", lineID, dispatch))
        }
    }
}
export const AddMachine = (newMachine) => { 
    console.log('check 2 caall 1111111111111111111111111111111111111111111111111111');
    return (dispatch,gs)=>{
        dispatch({
            type: Constants.Actions.Machines_Add,
            payload: newMachine,
            meta: {
                offline: {
                    effect: {
                        url: Constants.URLs.Machines, method: 'POST', body: JSON.stringify(newMachine), headers: { 'content-type': 'application/json' }
                    },
                    commit: {
                        type: Constants.Actions.Machines_Add_Commit, meta: { newMachine }
                    },
                    rollback: {
                        type: Constants.Actions.Machines_Add_Rollback, meta: { newMachine }
                    }
                }
            }
        });
        if (newMachine.type === 11) { //Triple_Stack
            dispatch(AddTripleStackVessels(newMachine.id));
        }
    }
};
export const UpdateMachine = (updMachine, curMachine) => {
    console.log('put check __________________________________________________________________________  24');
    const data = { update: updMachine, current: curMachine }
    return {
        type: Constants.Actions.Machines_Update,
        payload: data,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.Machines + updMachine.id, method: 'PUT', body: JSON.stringify(updMachine), headers: { 'content-type': 'application/json' }
                },
                commit: {
                    type: Constants.Actions.Machines_Update_Commit, meta: { data }
                },
                rollback: {
                    type: Constants.Actions.Machines_Update_Rollback, meta: { data }
                }
            }
        }
    }
};
export const DeleteMachine = (delMachine) => {
    console.log('chec k 4 caall 1111111111111111111111111111111111111111111111111111');
    return {
        type: Constants.Actions.Machines_Delete,
        payload: delMachine,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.Machines + delMachine.id, method: 'DELETE'
                },
                commit: {
                    type: Constants.Actions.Machines_Delete_Commit, meta: { delMachine }
                },
                rollback: {
                    type: Constants.Actions.Machines_Delete_Rollback, meta: { delMachine }
                }
            }
        }
    }
};
export const ChangeMachineOrder = (machine, isUp) => {
    console.log('check 5 caall 1111111111111111111111111111111111111111111111111111');
    const changeData = { id: machine.id, isUp: isUp }
    return {
        type: Constants.Actions.Machines_ChangeOrder,
        payload: changeData,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.Machines + changeData.id +"/ChangeOrder/"+isUp, method: 'GET'
                },
                commit: {
                    type: Constants.Actions.Machines_ChangeOrder_Commit, meta: { changeData }
                },
                rollback: {
                    type: Constants.Actions.Machines_ChangeOrder_Rollback, meta: { changeData }
                }
            }
        }
    };
};
export const ReOrderMachines = (lineID) => {
    console.log('check 6 caall 1111111111111111111111111111111111111111111111111111');
    return (dispatch, getState) => {
        const state = getState();
        const machines = state.Machines.filter(m => m.lineID === lineID && !m.deleted).sort((a, b) => a.order - b.order);
        let order = 0;
        machines.forEach(m => {
            if (m.order != order) {
                dispatch(UpdateAction(Constants.Actions.Machines_Update, 'order', m, order));
            }
            order++;
        });
    }
    
};
export const ClearData = () => { return { type: Constants.Actions.Machines_ClearData }; };
export const AddTripleStackVessels = (machineID) => {
    return (dispatch, getState) => {
        const s = getState();
        const vessels = s.MetaData.Vessels;
        //PreH Top
        const pT = vessels.find(v => v.type == 11 && v.name == "PreH Top")
        if (pT)
            dispatch(AddMachineVessel(machineID, pT.id, 0));
        //PreH Middle
        const pM = vessels.find(v => v.type == 11 && v.name == "PreH Middle")
        if (pM)
            dispatch(AddMachineVessel(machineID, pM.id, 1));
        //PreH Bottom
        const pB = vessels.find(v => v.type == 11 && v.name == "PreH Bottom")
        if (pB)
            dispatch(AddMachineVessel(machineID, pB.id, 2 ));
    }
}
