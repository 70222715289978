import Constants from '../../constants';
//Libraries
import React, { useEffect, useContext, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import  {useNavigate, useParams } from 'react-router-dom';
import ReportViewer from '../Report/ReportViewer';
import { SelVisitByID,SelInventoryByVisitID,SelMeasuresByVisitID,FormatLocation,SelSKItemsByVisitID } from '../../selectors/VisitSelectors';
import { useReportUser,usePlantDataFromVisitID, useVisitData, useReportName } from '../../misc/UseFuncs';
import { getStore } from '../../core';
const StarchChecksPrint = props => {
    const Temp = useReportUser();
    const navigate = useNavigate();
    const params=useParams();
    const vID = params.VisitID;
    const printData=useIVPrintData(vID);
    const handleClose = () => {
        navigate('/VisitOverview/' + vID );
    }
    if (Temp === null) {
       return <div>Loading...</div>;
    } 
    return <ReportViewer type="StarchChecks" name="StarchChecks" printData={printData} 
       onClose={handleClose}  />;
    
}
export default StarchChecksPrint;
export const useIVPrintData=(vID)=>{
    const UserData = useReportUser();
    const visitID=vID
    const plant = usePlantDataFromVisitID(visitID);
    
    const visit = useSelector(s=>SelVisitByID(s,visitID));
    const reportName = useReportName("StarchChecks",plant?.name,visit);
    const measurements = useSelector(s=>SelMeasuresByVisitID(s,visitID)).map(m=>{
        return {...m, locationName:FormatLocation(m.location)};
    });
    const dJSON={User:UserData, 
        Plant:plant, Visit:visit,Measurements:measurements};
    const Data ={starchChecksData:JSON.stringify(dJSON)};
    let visitDate=null;
    if(visit){
        const d=new Date(visit.startDate);
        visitDate=+(d.getMonth()+1).toString().padStart(2,"0")+"/"+d.getDate().toString().padStart(2,"0")+"/"+d.getFullYear();
    }
    return {Loaded:true,Data:Data,ReportName:reportName,EmailSubject:'StarchChecks'+' '+plant?.name+' '+visitDate};
};