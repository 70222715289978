import React, { useContext, useState, useEffect } from 'react';
import Constants from '../constants';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete from '@mui/material/Autocomplete';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import Select from '@mui/material/Select';
import { ImageAttachmentView } from './ImageAttachment/ImageAttachmentView';
import ImageAttachmentButton from './ImageAttachment/ImageAttachmentButton';
import { UpdateAction } from '../actions/DataActions';
import produce from "immer";
import { useDispatch } from 'react-redux';
import '../css/FormStyle.css';
const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    }
}));

export const DataFormContext = React.createContext({ actionType: null, data: null });
export const DataForm = (props) => {

    const classes = useStyles();
    return (
        <DataFormContext.Provider value={{ actionType: props.actionType, data: props.data }}>
            <form>
                {props.children}
            </form>
        </DataFormContext.Provider>
    );
};
export const DataTextBox = (props) => {
    // console.log('DataFormContext -------------------------', DataFormContext);
    const contextDF = useContext(DataFormContext);
    const dispatch = useDispatch();
    let value = '';
    const size = props.size ? props.size : "small";
    if (contextDF.data && contextDF.data?.hasOwnProperty(props.name) && contextDF.data[props.name]) {
        value = contextDF.data[props.name];
    }
    const [textVal, setTextVal] = useState(value);
    const [prevId, setPrevId] = useState(contextDF.data?.id)
    if (prevId != contextDF.data?.id) {
        setPrevId(contextDF.data?.id)
        setTextVal(value);
    }
    const handleChange = (event) => {
        setTextVal(event.target.value);
    };

    const handleBlur = (e) => {
        if (e.target.value != value) {
            console.log('check 11111', contextDF);
            dispatch(UpdateAction(contextDF.actionType, props.name, contextDF.data, e.target.value))
        }
    }
    //if (textVal != value) {
    //    setTextVal(value);
    //}
    return (
        <TextField
            id={props.name}
            label={props.label}
            value={textVal}
            onChange={handleChange}
            onBlur={handleBlur}
            multiline
            fullWidth={props.fullWidth ? true : false}
            variant="outlined"
            size={size}
            {...props.TextFieldProps}
        />
    );
};
export const DataNumber = (props) => {
    const contextDF = useContext(DataFormContext);
    const dispatch = useDispatch();
    const size = props.size ? props.size : "small";
    const [isError, setError] = useState(null);
    let contextVal = '';
    let noBlurAction = false;
    if (props.noBlurAction) {
        noBlurAction = true
    }
    if (contextDF.data && contextDF.data.hasOwnProperty(props.name)) {
        contextVal = contextDF.data[props.name] ?? '';
    }
    const [preCVal, setPrevVal] = useState(contextVal);
    const [value, setValue] = useState(contextVal);
    if (contextVal != preCVal) {
        setPrevVal(contextVal);
        setValue(contextVal);
    }
    const handleChange = (event) => {
        
        if (props.isNotAllowDecimal) {
            const filteredValue = event.target.value.replace(/\D/g, '');
            // Update the input field with the filtered value
            event.target.value = filteredValue;
        }
        setValue(event.target.value);
        if (props.handelChange) {
            props.handelChange(event.target.value)
        }
    };
    const isNumber = (event) => {
        if (props.isNotAllowDecimal) {
            // const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
            const keysAllowed = ['.']
            const keyPressed = event.key
            // if (!keysAllowed.includes(keyPressed)) {
            if (keysAllowed.includes(keyPressed)) {
                event.preventDefault()
            }
        }
    };
    const handleBlur = (e) => {
        if (contextVal != value && !noBlurAction) {
            //Convert Value to Number
            let error = null;
            let outNum = 0;
            if (props.type === "int") {
                if (/^[-+]?(\d+|Infinity)$/.test(value)) {
                    outNum = parseInt(value);
                } else {
                    error = "Only Whole Numbers.";
                }
            } else {
                outNum = parseFloat(value);
                if (isNaN(outNum)) {
                    error = "Invalid Number";
                }
            }
            if (props.validate) {
                error = props.validate(outNum);
            }
            if (error == null) {
                dispatch(UpdateAction(contextDF.actionType, props.name, contextDF.data, outNum));
                if (isError) {
                    setError(null);
                }
            } else {
                setError(error);
            }
        }
    }
    return (
        <TextField
            error={isError}
            id={props.name}
            label={props.label}
            value={value}
            onBlur={handleBlur}
            onChange={handleChange}
            onKeyDown={isNumber}
            type="number"
            inputProps={props.inputProps}
            helperText={isError}
            fullWidth={props.fullWidth ? true : false}
            InputProps={props.InputProps}
            InputLabelProps={props.InputLabelProps}
            disabled={props.disabled}
            size={size}
            variant="outlined"
            {...props.TextFieldProps}
        />
    );
};
export const DataDate = (props) => {
    const contextDF = useContext(DataFormContext);
    const dispatch = useDispatch();
    let noBlurAction = false;
    if (props.noBlurAction) {
        noBlurAction = true
    }
    let contextVal = '';
    if (contextDF.data && contextDF.data.hasOwnProperty(props.name)) {
        contextVal = contextDF.data[props.name];
        //Remove Time from DateTime String
        if (contextVal && contextVal.indexOf('T') > 0) {
            contextVal = contextVal.substring(0, contextVal.indexOf('T'));
        }
    }
    //Using Props to State
    const [preConVal, setPreVal] = useState(contextVal);
    const [value, setValue] = useState(contextVal);
    if (preConVal != contextVal) {
        console.log("DataDate ContextVal Changed");
        setPreVal(contextVal);
        setValue(contextVal);
    }
    const handleBlur = (e) => {
        if (e.target.value != preConVal && !noBlurAction) {
            dispatch(UpdateAction(contextDF.actionType, props.name, contextDF.data, e.target.value))
        }
    }
    const onChangeHandel = (e) => {
        setValue(e.target.value)
        if (props.dateHandler) {
            props.dateHandler(e.target.value)
        }
    }


    return (
        <TextField
            id={props.name}
            label={props.label}
            value={value}
            onBlur={handleBlur}
            onChange={onChangeHandel}
            type="date"
            InputLabelProps={{
                shrink: true,
            }}
            variant="outlined"
            {...props.TextFieldProps}
        />
    );
};
export const DataCheckbox = (props) => {
    const contextDF = useContext(DataFormContext);
    const dispatch = useDispatch();
    let value = false;
    if (contextDF.data && contextDF.data.hasOwnProperty(props.name)) {
        value = contextDF.data[props.name];
    }
    const handleChange = (e) => {
        if (e.target.checked != value) {
            dispatch(UpdateAction(contextDF.actionType, props.name, contextDF.data, e.target.checked))
        }
    }
    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={value}
                    onChange={handleChange}
                    name={props.name}
                    color="primary"
                />
            }
            label={props.label}
        />
    );
};
export const DataSelect = (props) => {
    const classes = useStyles();
    const contextDF = useContext(DataFormContext);
    const dispatch = useDispatch();
    let value = '';
    if (contextDF.data && contextDF.data.hasOwnProperty(props.name)) {
        if (contextDF.data[props.name]) {
            value = contextDF.data[props.name];
        }
    }
    const handleChange = (e) => {
        if (e.target.value != value) {
            dispatch(UpdateAction(contextDF.actionType, props.name, contextDF.data, e.target.value))
        }
    }
    const selStyle = {};
    if (props.size === "small") {
        selStyle.height = '42px'
    }
    const fieldId = "DataSelect_" + props.name;
    return (
        <FormControl variant="outlined" className={classes.formControl} >
            <InputLabel htmlFor={fieldId} {...props.InputLabelProps} >{props.label}</InputLabel>
            <Select
                native
                name={props.name}
                value={value}
                onChange={handleChange}
                id={fieldId}
                label={props.label}
                style={selStyle}
            >
                {props.options}
            </Select>
        </FormControl>
    );
};
export const DataAutoComplete = props => {
    const contextDF = useContext(DataFormContext);
    let curVal = ''
    if (contextDF.data && contextDF.data[props.name]) {
        curVal = contextDF.data[props.name];
    }
    const dispatch = useDispatch();
    const [value, setValue] = useState(curVal);
    const [inputValue, setInputValue] = useState('');
    const [prevId, setPrevId] = useState(contextDF.data.id)
    if (prevId != contextDF.data.id) {
        setPrevId(contextDF.data.id)
        setValue(curVal);
        setInputValue(curVal);
    }
    const saveValue = (val) => {
        if (val != contextDF.data[props.name]) {
            dispatch(UpdateAction(contextDF.actionType, props.name, contextDF.data, val))
        }
    }

    return (
        <div>
            <Autocomplete
                value={value}
                name={props.name}
                freeSolo
                options={props.options}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                onBlur={(e) => {
                    if (value && value != contextDF.data[props.name]) {
                        saveValue(value);

                    } else {
                        if (inputValue != contextDF.data[props.name]) {
                            saveValue(inputValue)
                        }
                    }
                }}
                renderInput={(params) => (
                    <TextField {...params} label={props.label} variant="outlined" {...props.TextFieldProps} />
                )}
            />
        </div>
    );
};
export const DataImageAttachment = props => {
    const contextDF = useContext(DataFormContext);
    const data = contextDF.data;
    const dispatch = useDispatch();
    let value = null;
    if (data && data.hasOwnProperty(props.name)) {
        value = data[props.name];
    }
    const handleChange = (newValue) => {
        if (newValue != value) {
            dispatch(UpdateAction(contextDF.actionType, props.name, data, newValue))
        }
    }
    return <ImageAttachmentView attachmentID={value} handleChange={handleChange} header={props.header} />;
};

export const DataImageAttachmentButton = props => {
    const contextDF = useContext(DataFormContext);
    const data = contextDF.data;
    const dispatch = useDispatch();
    let value = null;
    if (data && data.hasOwnProperty(props.name)) {
        value = data[props.name];
    }
    const handleChange = (newValue) => {
        if (newValue != value) {
            dispatch(UpdateAction(contextDF.actionType, props.name, data, newValue))
        }
    }
    return <ImageAttachmentButton attachmentID={value} onChange={handleChange} />
}