import Constants from '../constants';
import { v4 as uuidv4 } from 'uuid';
import { FetchErrorLogger, FetchCatchHandler } from '../middlewares';
import { AddSyncTimestamp, CheckReSync } from './LastSyncActions';
const constants = Constants;
export const GetSKItemsByVisitID = (visitID) => {
    return (dispatch, getState) => {
        //offline.online
        const state = getState();
        if (state.offline.online && CheckReSync("SKItems_"+visitID, state.LastSync, 3600000)) {
            const getURL = constants.URLs.StarchKitchenItem + "?visitID=" + visitID
            let fetchTask = fetch(getURL, { headers: { 'Authorization': 'Bearer ' + state.Auth.token } })
                .then(FetchErrorLogger("GetSKItemsByVisitID", visitID, dispatch))
                .then(response => response.json())
                .then(grs => {
                    //Ignore 404 errors
                    if (Array.isArray(grs)) {
                        dispatch({ type: Constants.Actions.StarchKitchenItem.GetByVisitID, data: grs, visitID: visitID });       
                        dispatch(AddSyncTimestamp("SKItems_"+visitID,'Starch Kitchen Items')); 
                    }
                })
                .catch(FetchCatchHandler("GetSKItemsByVisitID", visitID, dispatch))
        }
        return;
    }
}
export const AddDefaultStarchKitchenItems =(visitID,existing)=>{
    return (dispatch,getState)=>{
        
        let order=0;
        constants.DefaultStarchKitchen.forEach(name=>{
            if(existing.some(i=>i.name==name)) { order++; return; }
            const newItem = {id:uuidv4(),name:name,visitID:visitID,checked:false,order:order++};
            dispatch(AddNewStarchKitchenItem(newItem));
        });
    }
}
export const ClearStarchKitchen = () => {
    return {type:constants.Actions.StarchKitchenItem.ClearData};
};
export const UpdateStarchKitchenItem=(newItem,curItem)=>{
    const data = { update: newItem, current: curItem }
    console.log('put check __________________________________________________________________________  27');
    return {
        type: Constants.Actions.StarchKitchenItem.Update,
        payload: data,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.StarchKitchenItem + newItem.id, method: 'PUT', body: JSON.stringify(newItem), headers: { 'content-type': 'application/json' }
                },
                commit: {
                    type: Constants.Actions.StarchKitchenItem.Update_Commit, meta: { data }
                },
                rollback: {
                    type: Constants.Actions.StarchKitchenItem.Update_Rollback, meta: { data }
                }
            }
        }
    }
}
//Old Stuff
export const DeleteStarchKitchenItem = (delSKItem) => {
    return {
        type: Constants.Actions.StarchKitchenItem.Delete,
        payload: delSKItem,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.StarchKitchenItem + delSKItem.id, method: 'DELETE'
                },
                commit: {
                    type: Constants.Actions.StarchKitchenItem.Delete_Commit, meta: {  delSKItem }
                },
                rollback: {
                    type: Constants.Actions.StarchKitchenItem.Delete_Rollback, meta: {  delSKItem }
                }
            }
        }
    }
};
export const AddNewStarchKitchenItem = (newSKItem) => {
    return {
        type: constants.Actions.StarchKitchenItem.Add,
        payload: newSKItem,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.StarchKitchenItem, method: 'POST',
                    body: JSON.stringify(newSKItem), headers: { 'content-type': 'application/json' }
                },
                commit: {
                    type: constants.Actions.StarchKitchenItem.Add_Commit, meta: { newSKItem }
                },
                rollback: {
                    type: constants.Actions.StarchKitchenItem.Add_Rollback, meta: { newSKItem }
                }
            }
        }
    }
};