import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import Constants from '../constants';
import CorrugatorAdhesiveData from '../store/CorrugatorAdhesiveDataStore';
const ConfirmAction = props => {
    const constants = Constants;
    const dispatch = useDispatch();
    const confirm = useSelector(state => state.ConfirmAction, shallowEqual);
    const handleYes = () => {
        if (confirm.confirmAction) {
            dispatch(confirm.confirmAction);
        }
        if (confirm.callback) {
            confirm.callback(true);
        }
        dispatch({ type: constants.Actions.ConfirmAction.ClearData });
    };
    const handleNo = () => {
        if (confirm.cancel) {
            dispatch(confirm.cancelAction);
        }
        if (confirm.callback) {
            confirm.callback(false);
        }
        dispatch({ type: constants.Actions.ConfirmAction.ClearData });
    }
    if (!confirm) {
        return null;
    }
    console.log('check qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq', confirm);
    return (
        <Dialog
            open={true}
            onClose={handleNo}
            
            aria-describedby="alert-dialog-description"
        >
            
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {confirm.label}
          </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleYes} color="primary">
                    Yes
          </Button>
                <Button onClick={handleNo} color="primary" autoFocus>
                    No
          </Button>
            </DialogActions>
        </Dialog>
    );
}
export default ConfirmAction;