import React, { Component } from 'react';
import { CorrugatorAdhesiveGridView } from './CorrugatorAdhesiveGridView';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { DataForm, DataTextBox, DataNumber, DataDate, DataCheckbox } from '../FormManager';
class StarchPrepTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Error: false,
            ErrorMsg: '',
            active: '1'
        }
        //this.saveClick = this.saveClick.bind(this);
        //this.backClick = this.backClick.bind(this);
        //this.switch = this.switch.bind(this);
    }
    render() {
        return (
            
                <Paper>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={ 3 }>
                            <DataTextBox label={'Type'} name={'type'} />
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <DataTextBox label={'Carrier'} name={'carrier'} />
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <DataTextBox label={'Pearl'} name={'pearl'} />
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <DataTextBox label={'Caustic'} name={'caustic'} />
                        </Grid>           
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={3}>
                            <DataTextBox label={'Borax'} name={'borax'} />
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <DataTextBox label={'Additive'} name={'additive'} />
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <DataTextBox label={'Condition'} name={'condition'} />
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <DataTextBox label={'Primary Mixer Size'} name={'primaryMixerSize'} />
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={3}>
                            <DataTextBox label={'Secondary Mixer Size'} name={'secondaryMixerSize'} />
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <DataTextBox label={'Resin'} name={'resin'} />
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <DataTextBox label={'Storage Tank Capacity'} name={'storageTankCapacity'} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={3}>
                            <DataNumber label={'Distance of Corrugator Loop(feet)'} name={'corrugatorLoopDistance'} type={'int'} fullWidth />
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <DataTextBox label={'Gauge Placement and Accuracy'} name={'gaugePlacementAccuracy'} fullWidth />
                        </Grid>
                    </Grid>
                    <Card>
                        <CardHeader title={ 'Pump Description'}></CardHeader>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item xs={12} lg={3}>
                                    <DataTextBox label={'Transfer'} name={'pumpTransfer'} />
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <DataTextBox label={'Return'} name={'pumpReturn'} />
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <DataTextBox label={'Circulating'} name={'pumpCirculating'} />
                                </Grid>
                            </Grid>

                        </CardContent>
                    </Card>
                    <Card>
                        <CardHeader title={ 'TVC'}> </CardHeader>
                       
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item xs={12} lg={6}>
                                    <DataTextBox label={'Description'} name={'tvcDesc'} />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <DataNumber label={'Water Temp'} name={'tvcWaterTemp'} type={'int'} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={12} lg={6}>
                                    <DataNumber label={'Gauge Temp'} name={'tvcGaugeTemp'} type={'int'} />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <DataTextBox label={'Other Components'} name={'otherComponents'} />
                                </Grid>
                            </Grid>               
                        </CardContent>  

                    </Card>

              
                
                
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <CorrugatorAdhesiveGridView plantID={this.props.plantID} />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Card>
                            <CardHeader title={'Caustic'}></CardHeader>
                            <CardContent>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} lg={6}>
                                        <DataCheckbox label={'Liquid 50%'} name={'causticLiquid50'} />
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <DataCheckbox label={'Beads'} name={'causticBeads'} />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            </Card>
                        <Card>
                            <CardHeader title={'Borax / Borix Acid'}></CardHeader>
                            <CardContent>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} lg={3}>
                                        <DataCheckbox label={'5 Mol'} name={'borax5Mol'} />
                                    </Grid>
                                    <Grid item xs={12} lg={3}>
                                        <DataCheckbox label={'10 Mol'} name={'borax10Mol'} />
                                    </Grid>
                                    <Grid item xs={12} lg={3}>
                                        <DataCheckbox label={'Liquid'} name={'boraxLiquid'} />
                                    </Grid>
                                    <Grid item xs={12} lg={3}>
                                        <DataCheckbox label={'Boric Acid'} name={'boricAcid'} />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={4}>
                        <DataDate label={'Date of Last Revision'} name={'lastRevisionDate'} />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <DataTextBox label={'On Letterhead'} name={'onLetterhead'} />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <DataTextBox label={'Formulated by'} name={'formulatedBy'} />
                    </Grid>
                </Grid>
                </Paper>
            );
    }
}
export default StarchPrepTab;