import Constants from '../../constants';
//Modules
import React from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
//MUI
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
//Components
import { DataForm, DataImageAttachment, DataTextBox } from '../FormManager';
import { useMachineFlutesList } from '../MetaData/FluteViews';
import ImagePrintView from '../ImageAttachment/ImagePrintView';
import GlueRoll_MeasurementsEntry from './GlueRoll_MeasurementsEntry';
import GlueRoll_GapView from './GlueRoll_GapView';
import GlueRoll_TIRView from './GlueRoll_TIRView';
import glueroll from "../../images/glueroll.png"
import CCI_Logo from "../../images/CCI_Logo.svg"
const constants = Constants;
const useStyles = makeStyles((theme) => ({
    printDiv: {
        width: '100%',
        fontSize: '.7em'
    },
    headerDiv: {
        paddingLeft: '5%',
        paddingRight: '5%',
    },
    imgDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    thinLine: {
        border: '2px solid darkgray',
        borderRadius: '2px',
        width: '100%'
    },
    thickLine: {
        border: '4px solid darkgray',
        borderRadius: '2px',
        width: '100%'
    },
    printInfo: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'right',
        fontWeight: 'bold'
    },
    clHeader: {
        width: '100%',
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '1.5em',
        color: '#1976d2',
        paddingLeft: '5%',
        paddingRight: '5%',
        paddingTop:'5%'
    },
    alignPrint: {
        verticalAlign: 'bottom'
    },
    flexRow:{
        display:'flex',
        flexDirection:'row',
        pageBreakInside:'avoid',
        breakInside:'avoid',
        borderBottom:'2px solid darkgray',
        borderRadius:"2px"
    },
    printPage:{
        pageBreakAfter:'always'
    }
}));
const GlueRoll_MachinesPrint = props => {
    const grMachines = useSelector(s => s.GlueRollMachines
        .filter(m => m.glueRollSurveyID === props.glueRoll.id && !m.deleted)
        .sort((a,b)=>a.order-b.order)
        , shallowEqual);
    const plant = useSelector(s => s.Plants.find(p => p.id == props.plantID),shallowEqual);
    const pages = [];
    const ids = [];
    grMachines.forEach(grm => {
        pages.push(<MachinePrint grMachine={grm} plant={plant} />);
        ids.push(grm.id);
    });
    const getGRMachineIDs = () => ids;
    const classes = useStyles();
    return <div>
        {pages}
    </div>;
}
const MachinePrint = props => {
    const classes = useStyles();
    return (
        <DataForm actionType={constants.Actions.GlueRollMeasurement} data={props.grMachine}>
            <Paper className={classes.printPage}>
                <div className={'pageDiv'}>
                <div className={classes.headerDiv}>
            <Grid container>
                <Grid item xs={3} className={classes.imgDiv}>
                    <img src={CCI_Logo} style={{ width: '200px' }} />
                </Grid>
                <Grid item xs={9}>
                    <div className={classes.clHeader}>GlueRoll Measurements {props.grMachine.machineName}</div>
                </Grid>
            </Grid>
            <hr className={classes.thinLine} />
            <hr className={classes.thickLine} />
        </div>
                    <div className={'printBody'}>
                        <MachineMeasurementPrint grMachine={props.grMachine} plant={ props.plant } />
                    </div>
                </div>
                
            </Paper>
            <Paper className={classes.printPage}>
                <div className={'pageDiv'}>
                <div className={classes.headerDiv}>
            <Grid container>
                <Grid item xs={3} className={classes.imgDiv}>
                    <img src={CCI_Logo} style={{ width: '200px' }} />
                </Grid>
                <Grid item xs={9}>
                    <div className={classes.clHeader}>GlueRoll Pictures {props.grMachine.machineName}</div>
                </Grid>
            </Grid>
            <hr className={classes.thinLine} />
            <hr className={classes.thickLine} />
        </div>
                    <div className={'printBody'}>
                        <MachinePicturesPrint plant={props.plant} grMachine={props.grMachine} />
                    </div>
                </div>
            </Paper>
        </DataForm>
    );
}
const MachineMeasurementPrint = props => {
    const fluteStr = useMachineFlutesList(props.grMachine.machineID);
    let flutesPrint=null
    if (fluteStr) {
        flutesPrint = <ReadOnyTextBox value={fluteStr} label={"Flutes"} />
    }
    return <Grid container>
        <Grid item xs={4}>
            < DataTextBox name="machineName" label="Machine" InputProps={{readOnly: true}} />
        </Grid>
        <Grid item xs={4}>
            < DataTextBox name="machineModel" label="Model" InputProps={{ readOnly: true }} />
        </Grid>
        <Grid item xs={4}>
            {flutesPrint }
        </Grid>
        <Grid item xs={12}>
            <GlueRoll_MeasurementsEntry machineID={props.grMachine.machineID} />
        </Grid>
        <Grid item xs={6}>
            <GlueRoll_GapView />
        </Grid>
        <Grid item xs={6}>
            <GlueRoll_TIRView />
        </Grid>
    </Grid>;
}
const MachinePicturesPrint = props => {
    const cardHeaderFactory = (picNo) => {
        return <div style={{ backgroundColor: "Blue", color: "White", textAlign: "Center" }}>
            {picNo}
        </div>;
    };
    const imageStyle={height:'125px'}
    return <Grid container spacing={3}>
        <Grid item xs={4}>
            < DataTextBox name="machineName" label="Machine" InputProps={{ readOnly: true }} />
        </Grid>
        <Grid item xs={4}>
            < DataTextBox name="machineModel" label="Model" InputProps={{ readOnly: true }} />
        </Grid>
        <Grid item xs={4}>
            < DataTextBox name="width" label="Width" InputProps={{ readOnly: true }} />
        </Grid>
        <Grid item xs={12} align="Center">
            <img src={glueroll} />
        </Grid>
        <Grid item xs={3}>
            <Card>
                {cardHeaderFactory(1)}
                <CardContent>
                    <ImagePrintView attachmentID={props.grMachine.roll1ImageID} imageStyle={imageStyle}/>
                </CardContent>
            </Card>
        </Grid>
        <Grid item xs={3}>
        <Card>
                {cardHeaderFactory(2)}
                <CardContent>
                    <ImagePrintView attachmentID={props.grMachine.roll2ImageID} imageStyle={imageStyle}/>
                </CardContent>
            </Card>
            
        </Grid>
        <Grid item xs={3}>
        <Card>
                {cardHeaderFactory(3)}
                <CardContent>
                    <ImagePrintView attachmentID={props.grMachine.roll3ImageID} imageStyle={imageStyle}/>
                </CardContent>
            </Card>
            
        </Grid>
        <Grid item xs={3}>
        <Card>
                {cardHeaderFactory(4)}
                <CardContent>
                    <ImagePrintView attachmentID={props.grMachine.roll4ImageID} imageStyle={imageStyle}/>
                </CardContent>
            </Card>
            
        </Grid>
        <Grid item xs={3}>
        <Card>
                {cardHeaderFactory(5)}
                <CardContent>
                    <ImagePrintView attachmentID={props.grMachine.roll5ImageID} imageStyle={imageStyle}/>
                </CardContent>
            </Card>
            
        </Grid>
        <Grid item xs={3}>
        <Card>
                {cardHeaderFactory(6)}
                <CardContent>
                    <ImagePrintView attachmentID={props.grMachine.roll6ImageID} imageStyle={imageStyle}/>
                </CardContent>
            </Card>
            
        </Grid>
        <Grid item xs={3}>
        <Card>
                {cardHeaderFactory(7)}
                <CardContent>
                    <ImagePrintView attachmentID={props.grMachine.roll7ImageID} imageStyle={imageStyle}/>
                </CardContent>
            </Card>
            
        </Grid>
        <Grid item xs={3}>
        <Card>
                {cardHeaderFactory(8)}
                <CardContent>
                    <ImagePrintView attachmentID={props.grMachine.roll8ImageID} imageStyle={imageStyle}/>
                </CardContent>
            </Card>
            
        </Grid>
        <Grid item xs={3}>
        <Card>
                {cardHeaderFactory(9)}
                <CardContent>
                    <ImagePrintView attachmentID={props.grMachine.roll9ImageID} imageStyle={imageStyle}/>
                </CardContent>
            </Card>
            
        </Grid>
        <Grid item xs={3}>
        <Card>
                {cardHeaderFactory(10)}
                <CardContent>
                    <ImagePrintView attachmentID={props.grMachine.roll10ImageID} imageStyle={imageStyle}/>
                </CardContent>
            </Card>
            
        </Grid>
        <Grid item xs={3}>
        <Card>
                {cardHeaderFactory(11)}
                <CardContent>
                    <ImagePrintView attachmentID={props.grMachine.roll11ImageID} imageStyle={imageStyle}/>
                </CardContent>
            </Card>
            
        </Grid>
    </Grid>;
}
const ReadOnyTextBox = props => {
    return <TextField
        label={props.label}
        value={props.value}
        variant="outlined"
        InputProps={{ readOnly: true }}
    />
}
export default GlueRoll_MachinesPrint;