import Constants from '../constants';
import { v4 as uuidv4 } from 'uuid';
import { FetchErrorLogger, FetchCatchHandler } from '../middlewares';
import produce from "immer";
import { GetRequest } from './RequestActions';
import { GetMachineVessels } from './MachineVesselsActions';
import { GetLineMachines } from './MachineActions'
import { UpdateAction } from './DataActions';
const constants = Constants;
export const AddNewGlueRoll = (newGR) => {
    return {
        type: Constants.Actions.GlueRoll.Add,
        payload: newGR,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.GlueRoll, method: 'POST', body: JSON.stringify(newGR), headers: { 'content-type': 'application/json' }
                },
                commit: {
                    type: Constants.Actions.GlueRoll.Add_Commit, meta: { newGR }
                },
                rollback: {
                    type: Constants.Actions.GlueRoll.Add_Rollback, meta: { newGR }
                }
            }
        }
    }
};
export const LoadGlueRoll = (data) => {
    return { type: constants.Actions.GlueRoll.GetByID, data: data }
};
export const GetGlueRollsByVisitID = (visitID) => {
    return (dispatch, getState) => {
        //offline.online
        const state = getState();
        if (state.offline.online) {
            let fetchTask = fetch(Constants.URLs.GlueRoll + "?visitID=" + visitID, { headers: { 'Authorization': 'Bearer ' + state.Auth.token } })
                .then(FetchErrorLogger("GetGlueRollsByVisitID", visitID, dispatch))
                .then(response => response.json())
                .then(grs => {
                    //Ignore 404 errors
                    if (Array.isArray(grs)) {
                        dispatch({ type: Constants.Actions.GlueRoll.GetByVisitID, data: grs, visitID: visitID });
                        grs.forEach(gr => {
                            if(gr.glueRollType==0){
                                dispatch(GetGlueRollMeasurementsByParentID(gr.id));
                            } else {
                                dispatch(GetGlueRollPicturesByParentID(gr.id));
                            }
                        });
                    }
                })
                .catch(FetchCatchHandler("GetGlueRollsByVisitID", visitID, dispatch))
        }
    }
}
//#region Measurements
export const AddGlueRollMeasurement = (surveyID,machineID) => {
    return (dispatch, getState) => {
        const state = getState();
        const machine = state.Machines.find(m => m.id == machineID);
        const newGRM = {
            id: uuidv4(),
            glueRollSurveyID: surveyID,
            machineID: machine.id,
            machineName: machine.name,
            machineModel: machine.model,
            width: machine.width,
            order:machine.order
        };
        dispatch( {
            type: Constants.Actions.GlueRollMeasurement.Add,
            payload: newGRM,
            meta: {
                offline: {
                    effect: {
                        url: Constants.URLs.GlueRollMeasurement, method: 'POST', body: JSON.stringify(newGRM), headers: { 'content-type': 'application/json' }
                    },
                    commit: {
                        type: Constants.Actions.GlueRollMeasurement.Add_Commit, meta: { newGRM }
                    },
                    rollback: {
                        type: Constants.Actions.GlueRollMeasurement.Add_Rollback, meta: { newGRM }
                    }
                }
            }
        })
    }
};

export const AddGlueRollMeasurementsByLineID = (lineID,surveyID) => {
    return (dispatch, getState) => {
        const state = getState();
        const machines = state.Machines.filter(m => m.lineID === lineID && (m.type === 4 || m.type === 1) && !m.deleted);
        machines.forEach(m => {
            dispatch(AddGlueRollMeasurement(surveyID, m.id));
        })
    }
}
export const GetGlueRollMeasurementsByParentID = (parentID) => {
    return (dispatch, getState) => {
        //offline.online
        const state = getState();
        if (state.offline.online) {
            let fetchTask = fetch(Constants.URLs.GlueRollMeasurement + "?SurveyID=" + parentID, { headers: { 'Authorization': 'Bearer ' + state.Auth.token } })
                .then(FetchErrorLogger("GetGlueRollMeasurementsByParentID", parentID, dispatch))
                .then(response => response.json())
                .then(ivs => {
                    //Ignore 404 errors
                    if (Array.isArray(ivs)) {
                        dispatch({ type: constants.Actions.GlueRollMeasurement.GetByParentID, data: ivs, parentID: parentID });

                    }
                })
                .catch(FetchCatchHandler("GetGlueRollMeasurementsByParentID", parentID, dispatch))
        }
    }
};
//#endregion
//#region Pictures
export const AddGlueRollPicture = (surveyID,machineID) => {
    return (dispatch, getState) => {
        const state = getState();
        const machine = state.Machines.find(m => m.id == machineID);
        const newGRM = {
            id: uuidv4(),
            glueRollSurveyID: surveyID,
            machineID: machine.id,
            machineName: machine.name,
            machineModel: machine.model,
            width: machine.width,
            order:machine.order
        };
        dispatch( {
            type: Constants.Actions.GlueRollPicture.Add,
            payload: newGRM,
            meta: {
                offline: {
                    effect: {
                        url: Constants.URLs.GlueRollPicture, method: 'POST', body: JSON.stringify(newGRM), headers: { 'content-type': 'application/json' }
                    },
                    commit: {
                        type: Constants.Actions.GlueRollPicture.Add_Commit, meta: { newGRM }
                    },
                    rollback: {
                        type: Constants.Actions.GlueRollPicture.Add_Rollback, meta: { newGRM }
                    }
                }
            }
        })
    }
};

export const AddGlueRollPicturesByLineID = (lineID,surveyID) => {
    return (dispatch, getState) => {
        const state = getState();
        const machines = state.Machines.filter(m => m.lineID === lineID && (m.type === 4 || m.type === 1) && !m.deleted);
        machines.forEach(m => {
            dispatch(AddGlueRollPicture(surveyID, m.id));
        })
    }
}
export const GetGlueRollPicturesByParentID = (parentID) => {
    return (dispatch, getState) => {
        //offline.online
        const state = getState();
        if (state.offline.online) {
            let fetchTask = fetch(Constants.URLs.GlueRollPicture + "?SurveyID=" + parentID, { headers: { 'Authorization': 'Bearer ' + state.Auth.token } })
                .then(FetchErrorLogger("GetGlueRollPicturesByParentID", parentID, dispatch))
                .then(response => response.json())
                .then(ivs => {
                    //Ignore 404 errors
                    if (Array.isArray(ivs)) {
                        dispatch({ type: constants.Actions.GlueRollPicture.GetByParentID, data: ivs, parentID: parentID });

                    }
                })
                .catch(FetchCatchHandler("GetGlueRollPicturesByParentID", parentID, dispatch))
        }
    }
};
//#endregion
export const SetGlueRollName = (glueRoll, lineID) => {
    return (dispatch, getState) => {
        const state = getState();
        //Set GlueRoll Name After Line is selected. 
        const line = state.Lines.find(l => l.id == lineID);
        let lineName = '';
        if (line) {
            lineName = line.name;
        }
        const d = new Date();
        const newName = lineName + ' - ' + d.toLocaleDateString()
        dispatch(UpdateAction(constants.Actions.GlueRoll, 'name', glueRoll, newName));
    }
}
export const ClearGlueRollData = () => {
    return (dispatch, getState) => {
        dispatch({ type: Constants.Actions.GlueRoll.ClearData });
        dispatch({ type: Constants.Actions.GlueRollMeasurement.ClearData });
        dispatch({type:Constants.Actions.GlueRollPicture.ClearData});
    }   

};
export const DeleteGlueRollByID=(delGRID)=>{
    return (dispatch,getState)=>{
        const delGR=getState().GlueRolls.find(gr=>gr.id===delGRID);
        dispatch(DeleteGlueRoll(delGR));
    }
};
export const DeleteGlueRoll = (delGR) => {
    return {
        type: Constants.Actions.GlueRoll.Delete,
        payload: delGR,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.GlueRoll + delGR.id, method: 'DELETE'
                },
                commit: {
                    type: Constants.Actions.GlueRoll.Delete_Commit, meta: { delGR }
                },
                rollback: {
                    type: Constants.Actions.GlueRoll.Delete_Rollback, meta: { delGR }
                }
            }
        }
    }
};
