import Constants from '../constants';
import produce from "immer";
import { UpdateArray } from '../misc/ArrayFuncs';
const initDataState = [];
//Not Normalized
const FormulaChanges = (state = initDataState, action) => {
    switch (action.type) {
        case Constants.Actions.FormulaChanges_ReceiveByPlant:
            alert("FormulaChanges by Plant Store not implemented");
            return state;
            //merge 
            let newF = action.data;
            //Add Existing not updated formulae
            newF.concat(
                state.filter((f) => {
                    return newF.reduce((acc, value) => {
                        if (!acc) { return acc; }
                        return value.id == f.id ? false : true;
                    }, true)
                })
            );
            return newF;
        case Constants.Actions.FormulaChanges_ReceiveByFormula:
            alert("FormulaChanges by Formula Store not implemented");
            return state;
        //Add
        case Constants.Actions.FormulaChanges_Add:
            return [...state, action.payload];
        case Constants.Actions.FormulaChanges_Add_Rollback:
            const lineInd = state.findIndex(line => line.id === action.meta.newChange.id);
            if (lineInd !== -1) {
                return produce(state, pd => { pd.splice(lineInd, 1) });
            } else {
                return state;
            }
        case Constants.Actions.FormulaChanges_Add_Commit:
            return state;
        //End Add
        case Constants.Actions.FormulaChanges_ClearData:
            return [];
        default:
            return state;
    };
}
export default FormulaChanges;