import Constants from '../constants';
import { v4 as uuidv4 } from 'uuid';
import { FetchErrorLogger, FetchCatchHandler } from '../middlewares';
const constants=Constants;
//Inventory
export const GetInventoryByVisitID = (visitID) => {
    return (dispatch, getState) => {
        //offline.online
        const state = getState();
        if (state.offline.online) {
            let fetchTask = fetch(Constants.URLs.Inventory + "?VisitID=" + visitID, { headers: { 'Authorization': 'Bearer ' + state.Auth.token } })
                .then(FetchErrorLogger("GetInventoryByVisitID", visitID, dispatch))
                .then(response => response.json())
                .then(ivs => {
                    //Ignore 404 errors
                    if (Array.isArray(ivs)) {
                        dispatch({ type: constants.Actions.StarchInventory.GetByVisitID, data: ivs, visitID: visitID });

                    }
                })
                .catch(FetchCatchHandler("GetInventoryByVisitID", visitID, dispatch))
        }
    }
};
export const AddInventory = (newInv) => {
    newInv.id = uuidv4();
    return {
        type: constants.Actions.StarchInventory.Add,
        payload: newInv,
        meta: {
            offline: {
                effect: {
                    url: constants.URLs.Inventory, method: 'POST', body: JSON.stringify(newInv), headers: { 'content-type': 'application/json' }
                },
                commit: {
                    type: constants.Actions.StarchInventory.Add_Commit, meta: { newInv }
                },
                rollback: {
                    type: constants.Actions.StarchInventory.Add_Rollback, meta: { newInv }
                }
            }
        }
    }
};
export const DeleteInventory = (delInv) => {
    return {
        type: constants.Actions.StarchInventory.Delete,
        payload: delInv,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.Inventory + delInv.id, method: 'DELETE'
                },
                commit: {
                    type: Constants.Actions.StarchInventory.Delete_Commit, meta: { delInv }
                },
                rollback: {
                    type: Constants.Actions.StarchInventory.Delete_Rollback, meta: { delInv }
                }
            }
        }
    }
};
export const ClearInventoryData =()=>{
    return {type:Constants.Actions.StarchInventory.ClearData};
}
//End Inventory