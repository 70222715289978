import Constants from '../../constants';
//Modules
import React from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
//MUI
import Grid from '@mui/material/Grid';
//Compontents
import { DataImageAttachment,DataForm } from '../FormManager';
import { KeyItemCheckBox } from '../KeyItems/KeyItemCheckBox';
import glueroll from "../../images/glueroll.png"
import { ImageAttachmentView } from '../ImageAttachment/ImageAttachmentView';
//Actions
import { AddGlueRollPicture } from '../../actions/GlueRollActions';
import { UpdateAction } from '../../actions/DataActions';
const constants = Constants;
const cardHeaderFactory = (picNo) => {
    return <div style={{ backgroundColor: "Blue", color: "White", textAlign:"Center" }}>
        {picNo}
    </div>;
};
const GlueRoll_Pictures = props => {
    const dispatch=useDispatch();
    const grMachine = useSelector(s => s.GlueRollPictures.find(m => m.machineID === props.machineID && m.glueRollSurveyID === props.glueRoll.id), shallowEqual);
    if (!props || !props.machineID || props.machineID === constants.EmptyGuid) {
          return <div>Select a Machine</div>;
    }
    if (!grMachine) {
        dispatch(AddGlueRollPicture(props.glueRoll.id, props.machineID));
        return <div>Please Wait Creating Glue Roll Picture Record</div>;
    } 
    const handleChange = (newValue) => {
        alert("Handle Change Abovr"+grMachine.machineName+' ,')
        //dispatch(UpdateAction(constants.Actions.GlueRollPicture, props.name, props.glueRollPicture, newValue));
    };
    return <Grid container spacing={3}>
        <Grid item xs={12} align="Center">
            <img src={glueroll}  />
        </Grid>
        <Grid item xs={3}>
            <GlueRollPic name={'roll1ImageID'} header={ cardHeaderFactory(1) }  id={'pics_1_'+grMachine.id} glueRollPicture={grMachine} />
        </Grid>
        <Grid item xs={3}>
            <GlueRollPic name={'roll2ImageID'} header={cardHeaderFactory(2)}  id={'pics_2_'+grMachine.id} glueRollPicture={grMachine} />
        </Grid>
        <Grid item xs={3}>
            <GlueRollPic name={'roll3ImageID'} header={cardHeaderFactory(3)}  id={'pics_3_'+grMachine.id} glueRollPicture={grMachine} />
        </Grid>
        <Grid item xs={3}>
            <GlueRollPic name={'roll4ImageID'} header={cardHeaderFactory(4)}  id={'pics_4_'+grMachine.id} glueRollPicture={grMachine} />
        </Grid>
        <Grid item xs={3}>
            <GlueRollPic name={'roll5ImageID'} header={cardHeaderFactory(5)}  id={'pics_5_'+grMachine.id} glueRollPicture={grMachine} />
        </Grid>
        <Grid item xs={3}>
            <GlueRollPic name={'roll6ImageID'} header={cardHeaderFactory(6)}  id={'pics_6_'+grMachine.id} glueRollPicture={grMachine} />
        </Grid>
        <Grid item xs={3}>
            <GlueRollPic name={'roll7ImageID'} header={cardHeaderFactory(7)}  id={'pics_7_'+grMachine.id} glueRollPicture={grMachine} />
        </Grid>
        <Grid item xs={3}>
            <GlueRollPic name={'roll8ImageID'} header={cardHeaderFactory(8)}  id={'pics_8_'+grMachine.id} glueRollPicture={grMachine} />
        </Grid>
        <Grid item xs={3}>
            <GlueRollPic name={'roll9ImageID'} header={cardHeaderFactory(9)}  id={'pics_9_'+grMachine.id} glueRollPicture={grMachine}/>
        </Grid>
        <Grid item xs={3}>
            <GlueRollPic name={'roll10ImageID'} header={cardHeaderFactory(10)}  id={'pics_10_'+grMachine.id} glueRollPicture={grMachine} />
        </Grid>
        <Grid item xs={3}>
            <GlueRollPic name={'roll11ImageID'} header={cardHeaderFactory(11)} id={'pics_11_'+grMachine.id} glueRollPicture={grMachine} onChange={handleChange} />
        </Grid>
        <Grid item sm={12}>
            <KeyItemCheckBox entity={grMachine} visitID={props.glueRoll.visitID}
                setReference={(entity) => {
                    return entity.machineName;
                }}
                entityType={constants.Surveys.GlueRoll_Pictures} edit={true} tabIndex="-1"
            />
           
        </Grid>
    </Grid>;
};
const GlueRollPic=props=>{  
    const dispatch = useDispatch();
    const grPic= props.glueRollPicture;
    const handleChange = (newValue) => {
        //alert("Handle Change 2"+props.glueRollPicture.machineName+' ,')
        dispatch(UpdateAction(constants.Actions.GlueRollPicture, props.name, props.glueRollPicture, newValue));
    };
    return <ImageAttachmentView attachmentID={props.glueRollPicture[props.name]} 
    handleChange={handleChange} header={ props.header } machineName={props.glueRollPicture.machineName}
    id={props.id}
     />;
};
export default GlueRoll_Pictures;