import { createSelector } from 'reselect';
export const IngredientVerificationsSel = state=>state.IngredientVerifications.filter(i=>!i.deleted);
export const IVStepsSel= state=>state.IngredientVerificationSteps.filter(i=>!i.deleted);
export const GetIngredientVerificationByID = createSelector(IngredientVerificationsSel,(_,ivID)=>ivID,
    (ivs,ivID)=>{
        return ivs.find(iv=>iv.id===ivID);
    }
);
export const GetIVStepsByParentID = createSelector(IVStepsSel,(_,ivID)=>ivID,
    (steps,ivID)=>{
        return steps
        .filter(s => s.starchIngredientVerificationID == ivID)
        .sort((a, b) => a.stepLocation - b.stepLocation);
    }
);