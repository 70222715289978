import Constants from '../constants';
import produce from "immer";
import { UpdateArray,UpdateArrayRange } from '../misc/ArrayFuncs';
const initDataState = [];
const CorrugatorAdhesiveData = (state = initDataState, action) => {
    switch (action.type) {
        case Constants.Actions.CorrugatorAdhesive_ReceiveByPlant:
            return UpdateArrayRange(action.data, state);
        case Constants.Actions.CorrugatorAdhesive_Update:
            return UpdateArray(action.payload.update, state);
        case Constants.Actions.CorrugatorAdhesive_Update_Commit:
            return UpdateArray(action.payload?.data, state);
        case Constants.Actions.CorrugatorAdhesive_Update_Rollback:
            return UpdateArray(action.meta.data.current, state);
        case Constants.Actions.CorrugatorAdhesive_Add:
            let newCADs = [...state, action.payload];
            return newCADs;
        case Constants.Actions.CorrugatorAdhesive_Add_Rollback:
            const cadInd = state.findIndex(cad => cad.id === action.meta.newCAD.id);
            if (cadInd !== -1) {
                return produce(state, pd => { pd.splice(cadInd, 1) });
            } else {
                return state;
            }
        case Constants.Actions.CorrugatorAdhesive_Add_Commit:
            return UpdateArray(action.payload?.data, state);
        case Constants.Actions.CorrugatorAdhesive_Delete:
            return UpdateArray(produce(action.payload, d => {
                d.deleted = true;
            }), state);
        case Constants.Actions.CorrugatorAdhesive_Delete_Commit:
            return state;
        case Constants.Actions.CorrugatorAdhesive_Delete_Rollback:
            return UpdateArray(action.meta.delCAD, state);
        case Constants.Actions.CorrugatorAdhesive_ClearData:
            return [];
        default:
            return state;
    };
}
export default CorrugatorAdhesiveData;