import Constants from '../../constants';
//Modules
import React, { useEffect, useContext, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useVisitPrintData } from './VisitPrint';
import useReportToPDF from '../Report/ReportToPDF';
import { useKeyItemsPrintData } from '../KeyItems/KeyItemsPrint';
import { selectKeyItemsByVisitID } from '../../selectors/KeyItemSelectors';
import { usePlantData, usePlantDataFromVisitID, useVisitData } from '../../misc/UseFuncs';
import { useVisitSurveys } from './VisitSurveys';
import { useHeatVeseelPrintData } from '../HeatVessel/HeatVesselPrint';
import { useChecklistPrintData } from '../Checklist/ChecklistPrint';
import { useGlueLinePrintData } from '../GlueLine/GlueLinePrint';
import { useGRMeasurementsPrintData, useGRPicturesPrintData } from '../GlueRoll/GlueRollPrint';
import { useIVPrintData } from '../Ingredient_Verification/IVPrint';
import { selActionItemsByPlantID } from '../../selectors/ActionItemSelectors';
import { useActionItemPrintData } from '../ActionItem/ActionItemsPrint';
import { SelActivePlantFormulas } from '../../selectors/FormulaSelectors';
import { useFormulaPrintData } from '../Formula/FormulaPrintView';
const VisitPDFGeneration=({VisitID,OnComplete})=>{
    const surveys=useVisitSurveys(VisitID);
    const visit=useVisitData(VisitID);
    const formulas=useSelector(s=>SelActivePlantFormulas(s,visit?.plantID));
    const [attachments, setAttachments] = useState([]);
    const updateAttachment = (key, updAttach) => {
        setAttachments(s => {
            if (!s.some(i => i.key == key)) {
                return [...s, { key: key, attachment: updAttach }];
            }
            return s;
        });
    }
    const pdfLIs=[];  
    //Read Visit Data
    pdfLIs.push(<VisitReportPDF visitID={VisitID} updateAttachment={updateAttachment} attachemnts={attachments} />);
    pdfLIs.push(<KeyItemsPDF visit={visit} updateAttachment={updateAttachment} attachemnts={attachments} /> );
    pdfLIs.push(<ActionItemsPDF visit={visit} updateAttachment={updateAttachment} attachemnts={attachments} /> );
    surveys.forEach(e => {
        switch (e.surveyType) {
            case Constants.Surveys.HeatVessel:
                pdfLIs.push(<HeatVesselPDF heatVesselID={e.id} updateAttachment={updateAttachment} attachemnts={attachments} /> );
                break;
            case Constants.Surveys.Checklist:
                pdfLIs.push(<ChecklistPDF surveyID={e.id} updateAttachment={updateAttachment} attachemnts={attachments} />);
                break;
            case Constants.Surveys.GlueLine:
                pdfLIs.push(<GlueLinePDF surveyID={e.id} updateAttachment={updateAttachment} attachemnts={attachments} />);
                break;
            case Constants.Surveys.GlueRoll_Measurements:
                pdfLIs.push(<GlueRollMeasPDF surveyID={e.id} visitID={VisitID} updateAttachment={updateAttachment} attachemnts={attachments} />);
                break;
            case Constants.Surveys.GlueRoll_Pictures:
                pdfLIs.push(<GlueRollPicsPDF surveyID={e.id} visitID={VisitID} updateAttachment={updateAttachment} attachemnts={attachments} />);
                break;
            case Constants.Surveys.IngredientVerification:
                pdfLIs.push(<IngVerPDF surveyID={e.id} updateAttachment={updateAttachment} attachemnts={attachments} />);
                break;
        }
    });
    formulas.forEach(e=>{
        pdfLIs.push(<FormulaPDF formula={e} updateAttachment={updateAttachment} attachemnts={attachments} /> );
    });
    //Get Surveys
    //Get Formulas
    //Build List of PDFs
    console.log("VisitPDFGeneration Render");
    let pdfMessage='Generating PDFs';
    if(pdfLIs.length===attachments.length){ 
        pdfMessage="PDF Generation Complete";
        OnComplete(attachments.map(a=>a.attachment));
    }
    return <div>
        PDFs
        <ul>
            {pdfLIs}
        </ul>
        {pdfMessage}
    </div>
};
export default VisitPDFGeneration;

const VisitReportPDF = ({ visitID, updateAttachment, attachemnts })=>{
    const data = useVisitPrintData(visitID);
    const key = 'visitPDF_' + visitID;
    const pdfGenerated=attachemnts.some(i => i.key == key);
    const pdf = useReportToPDF("Visit", "Visit", data, key, updateAttachment, !pdfGenerated)
    console.log("VisitReportPDF Render",data);

    return <li key={key}>Visit Report PDF {pdfGenerated?'Created':'Loading' }</li>
}
const KeyItemsPDF = ({ visit, updateAttachment, attachemnts }) => {
    
    const keyItems = useSelector(s => selectKeyItemsByVisitID(s, visit?.id));
    const plant = usePlantData(visit?.plantID);
    const data= useKeyItemsPrintData(keyItems, plant, visit);
    const key = 'keyItemsPDF_' + visit?.id;
    const pdfGenerated=attachemnts.some(i => i.key == key);
    const pdf = useReportToPDF("KeyItem", "KeyItem", data, key, updateAttachment, !pdfGenerated)
    console.log("keyItemsPDF Render", data);
    return <li key={key}>Key ItemsPDF {pdfGenerated?'Created':'Loading' }</li>
};
const ActionItemsPDF = ({ visit, updateAttachment, attachemnts }) => {
    const actionItems = useSelector(s=>selActionItemsByPlantID(s,visit?.plantID));
    const key = 'actionItemsPDF_' + visit?.plantID;
    const data = useActionItemPrintData(actionItems,visit)
    const pdfGenerated=attachemnts.some(i => i.key == key);
    const pdf = useReportToPDF("ActionItem", "ActionItem", data, key, updateAttachment, !pdfGenerated)
    console.log("Action Items PDF Render", data);
    return <li key={key}>Action Items PDF {pdfGenerated?'Created':'Loading' }</li>
};
const HeatVesselPDF = ({ heatVesselID, updateAttachment, attachemnts }) => {
    const data = useHeatVeseelPrintData(heatVesselID);
    const key='heatVesselPDF_'+heatVesselID;
    const pdfGenerated=attachemnts.some(i => i.key == key);
    const pdf = useReportToPDF("HeatVessel", "HeatVessel", data, key, updateAttachment, !pdfGenerated)
    console.log("heatVesselPDF Render",data);
    return <li key={key}>HeatVessel PDF{pdfGenerated?'Created':'Loading' }</li>;
};
const ChecklistPDF = ({ surveyID, updateAttachment, attachemnts }) => {
    const data = useChecklistPrintData(surveyID);
    const key = 'checklistPDF_' + surveyID;
    const pdfGenerated=attachemnts.some(i => i.key == key);
    const pdf = useReportToPDF("Checklist", "BHSChecklist", data, key, updateAttachment, !pdfGenerated)
    console.log("heatVesselPDF Render", data);
    return <li key={key}>Checklist PDF {pdfGenerated?'Created':'Loading' }</li>;
};
const GlueLinePDF =  ({ surveyID, updateAttachment, attachemnts }) => {
    const data = useGlueLinePrintData(surveyID);
    const key = 'glueLinePDF_' + surveyID;
    const pdfGenerated=attachemnts.some(i => i.key == key);
    const pdf = useReportToPDF("GlueLine", "GlueLine", data, key, updateAttachment, !pdfGenerated)
    console.log("GlueLinePDF Render", data);
    return <li key={key}>GlueLine PDF {pdfGenerated?'Created':'Loading' }</li>;
};
const GlueRollPicsPDF=({ surveyID,visitID, updateAttachment, attachemnts }) => {
    const data = useGRPicturesPrintData(surveyID,visitID);
    const key = 'GlueRoll_PicsPDF_' + surveyID;
    const pdfGenerated=attachemnts.some(i => i.key == key);
    const pdf = useReportToPDF("GlueRoll_Pictures", "GlueRoll_Pictures", data, key, updateAttachment, !pdfGenerated)
    console.log("GlueLinePDF Render", data);
    return <li key={key}>GlueRoll_Pictures PDF {pdfGenerated?'Created':'Loading' }</li>;
};
const GlueRollMeasPDF=({ surveyID,visitID, updateAttachment, attachemnts }) => {
    const data = useGRMeasurementsPrintData(surveyID,visitID);
    const key = 'GlueRoll_MeasPDF_' + surveyID;
    const pdfGenerated=attachemnts.some(i => i.key == key);
    const pdf = useReportToPDF("GlueRoll_Measurements", "GlueRoll Measurements", data, key, updateAttachment, !pdfGenerated)
    console.log("GlueLinePDF Render", data);
    return <li key={key}>GlueRoll Measurements PDF {pdfGenerated?'Created':'Loading' }</li>;
};
const IngVerPDF =  ({ surveyID, updateAttachment, attachemnts }) => {
    const data = useIVPrintData(surveyID);
    const key = 'IVPDF_' + surveyID;
    const pdfGenerated=attachemnts.some(i => i.key == key);
    const pdf = useReportToPDF("IngredientVerification", "IngredientVerification", data, key, updateAttachment, !pdfGenerated)
    console.log("GlueLinePDF Render", data);
    return <li key={key}>Ingredient Verification PDF {pdfGenerated?'Created':'Loading' }</li>;
};
const FormulaPDF =  ({ formula, updateAttachment, attachemnts }) => {
    const data = useFormulaPrintData(formula);
    const key = 'Formula_' + formula?.id;
    const pdfGenerated=attachemnts.some(i => i.key == key);
    const pdf = useReportToPDF("Formula", "Formula", data, key, updateAttachment, !pdfGenerated)
    console.log("GlueLinePDF Render", data);
    return <li key={key}>Formula PDF {pdfGenerated?'Created':'Loading' }</li>;
};
