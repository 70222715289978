import Constants from '../../constants';
import React, { useEffect, useContext, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Formik, Form, Field } from 'formik';

//MUI
import MUIDataTable from "mui-datatables";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField, CheckboxWithLabel } from 'formik-mui'
import { DataNumber } from '../FormManager';
import { Card, CardContent, CardHeader, InputAdornment, Typography } from '@mui/material';
import { Savings } from '@mui/icons-material';
const constants = Constants;
const WasteBaselineDetail = props => {
    const { selectedBaseline, selectedDays, DetailObj, ErrObj } = props
    const [totalLineal, settotalLineal] = React.useState(0);
    const [totalLinealNewProNum, settotalLinealNewProNum] = React.useState(0);
    const [totalSqFt, settotalSqFt] = React.useState(0);
    const [totalSqFtNewProNum, settotalSqFtNewProNum] = React.useState(0);
    const [msfPerHour, setmsfPerHour] = React.useState(0);
    const [msfPerHourNewProNum, setmsfPerHourNewProNum] = React.useState(0);
    const [LbsPerHour, setLbsPerHour] = React.useState(0);
    const [LbsPerNewProNum, setLbsPerNewProNum] = React.useState(0);
    const [DolarPerHour, setDolarPerHour] = React.useState(0);
    const [DolarPerHourNewProNum, setDolarPerHourNewProNum] = React.useState(0);

    const [MsfImproved, setMsfImproved] = React.useState(0);
    const [TotalSalesInc, setTotalSalesInc] = React.useState(0);
    const [CostReductProfit, setCostReductProfit] = React.useState(0);
    const [TotalProctSaving, setTotalProctSaving] = React.useState(0);
    const [HandelKey, setHandelKey] = React.useState('');

    // console.log('-----------------DetailObj comp----------------------------------', props.DetailObj);
    const [reqObj, setreqObj] = React.useState(
        {
            proNewLinealPerHr: DetailObj.proNewLinealPerHr,
            proTotalCount: DetailObj.proTotalCount,
            totalProductionSaving: DetailObj.totalProductionSaving
        }
    );
    const [ErObj, setErObj] = React.useState(
        {
            proNewLinealPerHrErr:  DetailObj.proNewLinealPerHr > 0 ? '' : 'Required',
            proTotalCountErr: ''
        }
    );

    useEffect(() => {
        let obj = ErObj
        obj.proNewLinealPerHrErr = DetailObj.proNewLinealPerHr > 0 ? '' : 'Required'
        obj.proTotalCountErr = DetailObj.proTotalCount > 0 ? '' : ErrObj.proTotalCountErr
        setErObj({ ...obj })
    }, [ErrObj]);
    useEffect(() => {
        if (DetailObj.proNewLinealPerHr > 0 && DetailObj.proTotalCount > 0) {
            calculation(props.selectedDays)
        }
    }, [props.selectedBaseline]);
    useEffect(() => {
        if (DetailObj.proNewLinealPerHr > 0 && DetailObj.proTotalCount > 0) {
            calculation(props.selectedDays)
        }
    }, [props.selectedDays]);

    const validate = (values) => {
        const errors = {};
        if (values.proNewLinealPerHr === 0)
            errors.proNewLinealPerHr = "Must have value";
        if (values.proNewLinealPerHr < 0)
            errors.proNewLinealPerHr = "invalid input";
        if (values.proTotalCount === 0)
            errors.proTotalCount = "Must have value";
        if (values.proTotalCount < 0)
            errors.proTotalCount = "invalid input";

        if (!errors.proNewLinealPerHr && values.proNewLinealPerHr > 0) {
            handelLinPerHour(values.proNewLinealPerHr)
        }

        if (!errors.proTotalCount && values.proTotalCount > 0) {
            handelTotalCount(values.proTotalCount)
        }

        if (errors.proNewLinealPerHr !== "" && HandelKey === 'proNewLinealPerHr') {
            setError('proNewLinealPerHrErr', '')
        }
        if (errors.proTotalCount !== "" && HandelKey === 'proTotalCount') {
            setError('proTotalCountErr', '')
        }
        props.updateRequest(values.proNewLinealPerHr, 'proNewLinealPerHr')
        props.updateRequest(values.proTotalCount, 'proTotalCount')
        return errors;
    }
    const setError = (key, value) => {
        let obj = ErObj
        obj[key] = value
        setErObj({ ...obj })
    }
    const handelTotalCount = (e) => {
        let data = reqObj
        data.proTotalCount = e
        setreqObj({ ...data })

        calculation(props.selectedDays, 'proTotalCount')
    }
    const handelLinPerHour = (e) => {
        let data = reqObj
        data.proNewLinealPerHr = e
        setreqObj({ ...data })

        calculation(props.selectedDays, 'proNewLinealPerHr')
    }
    const calculation = (selectedRadio, key) => {
        // console.log('cjecl 21111');
        if (reqObj.proNewLinealPerHr > 0 && reqObj.proTotalCount > 0) {
            let avgBgCost = constants.BLine.BasisWeight[selectedBaseline.baseGrade]
            let costMSFWperPP = ((selectedBaseline.preprint / 100 * (avgBgCost * 2.5)) + (((1 - selectedBaseline.preprint / 100) * avgBgCost) * selectedBaseline.costRate))
            let totalLineal = selectedBaseline.avgSpeed * 24
            let totalLinealNewProNum = reqObj.proNewLinealPerHr * 24
            let totalSqFt = totalLineal * (selectedBaseline.avgWidth / 12)
            let totalSqFtNewProNum = totalLinealNewProNum * (selectedBaseline.avgWidth / 12)
            let msfPerHour = totalSqFt / 24 / 1000
            let msfPerHourNewProNum = totalSqFtNewProNum / 24 / 1000
            let LbsPerHour = msfPerHour * selectedBaseline.baseGrade
            let LbsPerNewProNum = msfPerHourNewProNum * selectedBaseline.baseGrade
            let DolarPerHour = msfPerHour * costMSFWperPP
            let DolarPerHourNewProNum = msfPerHourNewProNum * costMSFWperPP
            settotalLineal(totalLineal)
            settotalLinealNewProNum(totalLinealNewProNum)
            settotalSqFt(totalSqFt)
            settotalSqFtNewProNum(totalSqFtNewProNum)
            setmsfPerHour(msfPerHour)
            setmsfPerHourNewProNum(msfPerHourNewProNum)
            setLbsPerHour(LbsPerHour)
            setLbsPerNewProNum(LbsPerNewProNum)
            setDolarPerHour(DolarPerHour)
            setDolarPerHourNewProNum(DolarPerHourNewProNum)
            let Savings = 0

            let MsfImproved = (msfPerHourNewProNum - msfPerHour) * (selectedBaseline.hoursPerShift * selectedBaseline.shiftPerDay)
            if (selectedRadio === 'day') {
                // console.log('day');

                let TotalSalesInc = MsfImproved * costMSFWperPP
                let CostReductProfit = TotalSalesInc * 0.3
                let TotalProctSaving = reqObj.proTotalCount * CostReductProfit

                setMsfImproved(MsfImproved)
                setTotalSalesInc(TotalSalesInc)
                setCostReductProfit(CostReductProfit)
                setTotalProctSaving(TotalProctSaving)
                Savings = TotalProctSaving

            } else if (selectedRadio === 'week') {
                // console.log('weeek');

                let MsfImprovedWeek = MsfImproved * selectedBaseline.daysPerWeek
                let TotalSalesInc = MsfImprovedWeek * costMSFWperPP
                let CostReductProfit = TotalSalesInc * 0.3
                let TotalProctSaving = reqObj.proTotalCount * CostReductProfit

                setMsfImproved(MsfImprovedWeek)
                setTotalSalesInc(TotalSalesInc)
                setCostReductProfit(CostReductProfit)
                setTotalProctSaving(TotalProctSaving)
                Savings = TotalProctSaving

            } else if (selectedRadio === 'month') {
                // console.log('month');

                let MsfImprovedWeek = MsfImproved * selectedBaseline.daysPerWeek * 4.25
                let TotalSalesInc = MsfImprovedWeek * costMSFWperPP
                let CostReductProfit = TotalSalesInc * 0.3
                let TotalProctSaving = reqObj.proTotalCount * CostReductProfit

                setMsfImproved(MsfImprovedWeek)
                setTotalSalesInc(TotalSalesInc)
                setCostReductProfit(CostReductProfit)
                setTotalProctSaving(TotalProctSaving)
                Savings = TotalProctSaving
            }
            props.ProductionHandle({...reqObj, totalProductionSaving: Savings}, key)
        }
    }
    const formatnum = (num) => {
        let value = parseFloat(num)
        let formatted = Math.round(value);
        return parseFloat(formatted).toLocaleString();
    }
    const Tformatnum = (number) => {
      if (isNaN(number) || number === null) {
        return "Invalid Number";
    }
    if (Number.isInteger(number)) {
        return number.toString();
    } else {
        let formattedNumber = Number(number).toFixed(2);
        formattedNumber = formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return formattedNumber;
    }
    }
    const handleFocus = (e, keyName) => {
        // console.log(keyName);
        setHandelKey(keyName)
        e.target.select(); // Selects the input value
    };


    if (selectedBaseline !== null) {
        return <>
            <Grid container xl={6} style={{ padding: '16px', marginTop: '20px' }}>
                <Grid item xl={12}>
                    <Formik initialValues={reqObj} validate={validate}>
                        <Form>
                            <Card variant="outlined" >
                                <CardHeader title="Production" />
                                <CardContent>
                                    <Grid style={{ paddingLeft: '20px' }}>
                                        <Grid spacing={3} container item xs={12} sx={{ borderTop: 1, borderLeft: 1 }}>
                                            {/* <Grid sx={{ borderBottom: 1, borderRight: 1 }} item xs={6} align="center">
                                                <div>Baseline LFPH</div>
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1 }} item xs={6} align="center">
                                                Total Lineal
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1, paddingRight: 1 }} item xs={6} align="right">
                                                <div>{formatnum(selectedBaseline.avgSpeed)}</div>
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1, paddingRight: 1 }} item xs={6} >
                                                <div align="right">
                                                    {formatnum(totalLineal)}
                                                </div>
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1 }} item xs={6} align="center">
                                                <div>New Production Numbers</div>
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1, paddingRight: 1 }} item xs={6} align="right">
                                                {
                                                    <div>{formatnum(totalLinealNewProNum)}</div>
                                                }
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1 }} item xs={6} align="center">
                                                <div>New Lineal per Hour</div>
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1, paddingRight: 1 }} item xs={6} align="right">

                                            </Grid> */}
                                            <Grid sx={{ borderBottom: 1, borderRight: 1, paddingRight: 1 }} item xs={6} align="right">
                                            <p style={{fontSize: '12px', paddingRight: '5px', margin: '0px', marginBottom: '2px'}}>Baseline Value: {selectedBaseline.avgSpeed}</p>
                                                <Field id='proNewLinealPerHr' onWheel={(e) => e.target.blur()} style={{}} label="New Lineal per Hour" component={TextField} name="proNewLinealPerHr" type="number"
                                                    onFocus={(e) => handleFocus(e, "proNewLinealPerHr")} inputProps={{min: 0}} onKeyDown={(e) => {
                                                        if (e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 189) {
                                                            e.preventDefault();
                                                        }
                                                    }} />
                                                {
                                                    ErObj.proNewLinealPerHrErr !== "" ? <Typography align='right' style={{ fontSize: '0.75rem' }} color="error">{ErObj.proNewLinealPerHrErr}</Typography> : <></>
                                                }
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1 }} item xs={6} align="center">
                                                <div>{selectedDays.charAt(0).toUpperCase() + selectedDays.slice(1)}</div>
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1 }} item xs={6} align="center">
                                                <div>MSF Improved</div>
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1, paddingRight: 1 }} item xs={6} align="right" >
                                                <div>{formatnum(Math.round(MsfImproved))}</div>
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1 }} item xs={6} align="center">
                                                <div>Total Sales Increase</div>
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1, paddingRight: 1 }} item xs={6} align="right">
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <p style={{margin: '0px'}}>$</p>
                                                    <p style={{margin: '0px'}}>{formatnum(Math.round(TotalSalesInc))}</p>
                                                </div>
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1 }} item xs={6} align="center">
                                                <div>Cost Reduction Profit</div>
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1, paddingRight: 1 }} item xs={6} align="right">
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <p style={{margin: '0px'}}>$</p>
                                                    <p style={{margin: '0px'}}>{formatnum(Math.round(CostReductProfit))}</p>
                                                </div>
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1, paddingRight: 1 }} item xs={6} align="center">
                                                <div>Total {selectedDays.charAt(0).toUpperCase() + selectedDays.slice(1)}</div>
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1, paddingRight: 1 }} item xs={6} align="right">
                                            {Tformatnum(reqObj.proTotalCount)}
                                                {/* <Field inputProps={{ readOnly: true }} onWheel={(e) => e.target.blur()} style={{}} label={'Total ' + selectedDays.charAt(0).toUpperCase() + selectedDays.slice(1)} component={TextField} name="proTotalCount" type="number"
                                                 />
                                                {
                                                    ErObj.proTotalCountErr !== "" ? <Typography align='' style={{ fontSize: '0.75rem' }} color="error">{ErObj.proTotalCountErr}</Typography> : <></>
                                                } */}
                                            </Grid>
                                        </Grid>
                                        <Grid xs={12}>
                                            <Grid container xs={12} sx={{ marginTop: 2, fontWeight: 'bold', alignItems: 'center' }}>
                                                <Grid item xs={6}>
                                                    Total Production Savings
                                                </Grid>
                                                <Grid container item xs={6} align="right" sx={{ paddingRight: 1 }}>

                                                    <Grid xs={12}>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <p>$</p>
                                                            <p>{Tformatnum(TotalProctSaving)}</p>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Form>
                    </Formik>
                </Grid>
            </Grid>
        </>
    }

};
export default WasteBaselineDetail;