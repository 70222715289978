import { KeyOutlined } from "@mui/icons-material";
import { createSelector } from "reselect";

export const ContImproveSel = state=>state.ContImprove.filter(ki=>!ki.deleted);
export const SelKeyItemByEntity =createSelector(ContImproveSel,(_,entityID)=>entityID,(_,entityID,field)=>field,
    (ContImprove,entityID,field)=>{
        return ContImprove.find(k => {
            let match = k.entityID === entityID;
            if (match && field) {
                match = field === k.entityField;
            }
            return match;
        });
    });
export const ContImproveFilteredByArray=createSelector(ContImproveSel,(_,entityArray)=>entityArray,
    (ContImprove,entityArray)=>{
        return ContImprove.filter(ki=>entityArray.some(e=>e.id==ki.entityID))
    }
)
export const selectContImproveByVisitID = createSelector(
    s => s.ContImprove.filter(ki=>!ki.deleted),
    (_, visitID) => visitID,
    (ContImprove,visitID)=>ContImprove.filter(ki=>ki.visitID==visitID)
);
export const selectMultiContImprove= createSelector(
    selectContImproveByVisitID,
    (_,visitID,entityID)=>entityID,
    (visitKIs,entityID)=>visitKIs.filter(ki=>ki.entityID==entityID)
);
export const selectContImproveByPlantId = createSelector(
    s => s.ContImprove.filter(ki=>!ki.deleted),
    (_, plantID) => plantID,
    (ContImprove,plantID)=>ContImprove.filter(ki=>ki.plantID==plantID)
);
export const selectMultipleContImprove= createSelector(
    selectContImproveByPlantId,
    (_,plantID,entityID)=>entityID,
    (visitKIs,entityID)=>visitKIs.filter(ki=>ki.entityID==entityID)
);