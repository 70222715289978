import { usePlantData, useReportName, useReportUser } from '../../misc/UseFuncs';
import { useDispatch, useSelector } from 'react-redux';
import { SelFormulaStepsByFormulaID,PopulateFormulaStepsStrings } from '../../selectors/FormulaSelectors';
import React, { useEffect, useContext, useState } from 'react';
import { GetAttachmentSel } from '../../selectors/AttachmentSelectors';
import ReportViewer from '../Report/ReportViewer';
import { GetAttachment } from '../../actions/AttachmentActions';
const FormulaPrintView = props => {
    const printData = useFormulaPrintData(props.formula);
    if(printData.Loaded){
	return <ReportViewer type="Formula" name="Formula" printData={printData}
            onClose={props.onClose}  />;
    }
    return <div>Loading Data</div>;
}
export default FormulaPrintView;
export const useFormulaPrintData=(formula)=>{
    const [loaded,setLoaded]=useState(false);
    const UserData = useReportUser();
    const dispatch = useDispatch();
    useEffect(()=>{
        if(formula){
           dispatch(GetAttachment(formula.signatureImageID))
           setTimeout(()=>setLoaded(true),100);
        }
        },[formula]);
    const plant = usePlantData(formula?.plantID);
    const steps = PopulateFormulaStepsStrings(useSelector(s=>SelFormulaStepsByFormulaID(s,formula?.id)));
    const signature = useSelector(s=>GetAttachmentSel(s,formula?.signatureImageID));
    
    const reportName = useReportName(formula.name,plant?.name);
    const retVal ={Loaded:false,Data:{},ReportName:reportName,EmailSubject:'Formula '+formula?.name};
    if(loaded){
        const dJSON ={Formula:formula, FormulaSteps:steps, User:UserData, Plant:plant, Signature:signature };
        const Data ={FormulaData:JSON.stringify(dJSON)};
        retVal.Loaded=true;
        retVal.Data=Data;
    }
    return retVal
}