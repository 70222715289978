import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { ImageAttachmentView } from '../ImageAttachment/ImageAttachmentView';
import { v4 as uuid } from 'uuid';
import { PersonnelGridView} from './PersonnelGridView';
import { DataImageAttachment } from '../FormManager';

class PersonnelTab extends Component {
    constructor(props) {
        super(props);
       
    }
  

    render() {
        return (
            <Grid container spacing={3}>
                <Grid item sm={7}>
                    <PersonnelGridView plantID={this.props.PlantData.id} />
                </Grid>
                <Grid item sm={3}>
                    <Card>
                        <CardHeader title="Picture of Plant" />
                        <CardContent>
                            <DataImageAttachment name={'plantImageID'} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        );
    }
}
export default PersonnelTab;