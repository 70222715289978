import Constants from '../constants';
import { v4 as uuidv4 } from 'uuid';

import { GetRequest } from './RequestActions';
import { GetMachineVessels } from './MachineVesselsActions';
import { GetLineMachines } from './MachineActions'
import { FetchErrorLogger, FetchCatchHandler } from '../middlewares';
import { dispatch } from 'rxjs/internal/observable/pairs';
import { UpdateAction } from './DataActions';
const constants = Constants;
export const AddNewGlueLine = (newGL) => {
    return {
        type: Constants.Actions.GlueLine.Add,
        payload: newGL,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.GlueLine, method: 'POST',
                    body: JSON.stringify(newGL), headers: { 'content-type': 'application/json' }
                },
                commit: {
                    type: Constants.Actions.GlueLine.Add_Commit, meta: { newGL }
                },
                rollback: {
                    type: Constants.Actions.GlueLine.Add_Rollback, meta: { newGL }
                }
            }
        }
    }
};
export const LoadGlueLine = (data) => {
    return { type: constants.Actions.GlueLine.GetByID, data: data }
};
export const GetGlueLinesByVisitID = (visitID) => {
    return (dispatch, getState) => {
        //offline.online
        const state = getState();
        if (state.offline.online) {
            const getURL = constants.URLs.GlueLine + "?visitID=" + visitID
            let fetchTask = fetch(getURL, { headers: { 'Authorization': 'Bearer ' + state.Auth.token } })
                .then(FetchErrorLogger("GetGlueLinesByVisitID", visitID, dispatch))
                .then(response => response.json())
                .then(grs => {
                    //Ignore 404 errors
                    if (Array.isArray(grs)) {
                        dispatch({ type: Constants.Actions.GlueLine.GetByVisitID, data: grs, visitID: visitID });
                        grs.forEach(iv => {
                           dispatch(GetGlueLineMachinesByParentID(iv.id));
                        });
                    }
                })
                .catch(FetchCatchHandler("GetGlueLinesByVisitID", visitID, dispatch))
        }
    }
};
export const ClearGlueLineData = () => {
    return (dispatch, getState) => {
        dispatch({ type: Constants.Actions.GlueLine.ClearData });
        dispatch({ type: Constants.Actions.GlueLineMachine.ClearData });
    }

};
export const DeleteGlueLineByID=(delGLID)=>{
    return (dispatch,getState)=>{
        const delGL=getState().GlueLines.find(gl=>gl.id===delGLID);
        dispatch(DeleteGlueLine(delGL));
    }
};
export const DeleteGlueLine = (delGR) => {
    return {
        type: Constants.Actions.GlueLine.Delete,
        payload: delGR,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.GlueLine + delGR.id, method: 'DELETE'
                },
                commit: {
                    type: Constants.Actions.GlueLine.Delete_Commit, meta: { delGR }
                },
                rollback: {
                    type: Constants.Actions.GlueLine.Delete_Rollback, meta: { delGR }
                }
            }
        }
    }
};
//GlueLineMachine Actions
export const AddGlueLineMachinesByLineID = (surveyID,lineID, wall) => {
    return (dispatch, getState) => {
        const state = getState();
        const singleFacers = state.Machines.filter(m => m.lineID === lineID && m.type === 1 && !m.deleted);
        const glueUnits = state.Machines.filter(m => m.lineID === lineID && m.type === 4 && !m.deleted);
        for (let i = 1; i <= wall; i++) {
            const sf = singleFacers[i - 1];
            const gu = glueUnits[i - 1];
            dispatch(AddGlueLineMachine(surveyID, i, sf, gu));
        }
    }
};
export const AddGlueLineMachine = (surveyID, order,sf,gu) => {
    return (dispatch, getState) => {
        const state = getState();
        const newGLM = {
            id: uuidv4(),
            glueLineSurveyID: surveyID,
            order: order,
            sfMachineID: sf.id,
            sfMachineName: sf.name,
            guMachineID: gu.id,
            guMachineName:gu.name        
        };
        dispatch({
            type: Constants.Actions.GlueLineMachine.Add,
            payload: newGLM,
            meta: {
                offline: {
                    effect: {
                        url: Constants.URLs.GlueLineMachine, method: 'POST', body: JSON.stringify(newGLM), headers: { 'content-type': 'application/json' }
                    },
                    commit: {
                        type: Constants.Actions.GlueLineMachine.Add_Commit, meta: { newGLM }
                    },
                    rollback: {
                        type: Constants.Actions.GlueLineMachine.Add_Rollback, meta: { newGLM }
                    }
                }
            }
        })
    }
};
export const GetGlueLineMachinesByParentID = (parentID) => {
    return (dispatch, getState) => {
        //offline.online
        const state = getState();
        if (state.offline.online) {
            let fetchTask = fetch(Constants.URLs.GlueLineMachine + "?SurveyID=" + parentID, { headers: { 'Authorization': 'Bearer ' + state.Auth.token } })
                .then(FetchErrorLogger("GetGlueLineMachinesByParentID", parentID, dispatch))
                .then(response => response.json())
                .then(ivs => {
                    //Ignore 404 errors
                    if (Array.isArray(ivs)) {
                        dispatch({ type: constants.Actions.GlueLineMachine.GetByParentID, data: ivs, parentID: parentID });
                    }
                })
                .catch(FetchCatchHandler("GetGlueLineMachinesByParentID", parentID, dispatch))
        }
    }
};
export const SetGlueLineName = (glueLine, lineID, wallType) => {
    return (dispatch, getState) => {
        const state = getState();
        //Get Line Name
        const line = state.Lines.find(l => l.id == lineID);
        let lineName = '';
        if (line) {
            lineName = line.name;
        }
        let WallTypeName=''
        if (!isNaN(wallType)) {
            const wallNum = Number(wallType);
            WallTypeName = Object.keys(constants.Enums.WallType).find(key => constants.Enums.WallType[key] === wallNum);
        }
        const d = new Date();
        const newName = lineName + ' - ' + WallTypeName + ' Wall - ' + d.toLocaleDateString()
        dispatch(UpdateAction(constants.Actions.GlueLine, 'name', glueLine, newName));
    }
}