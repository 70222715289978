import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';

const EnumCB = props => {
    const handleChange = (e) => {
        if (e.target.checked != props.checked) {
           props.onChange(e.target.checked, props.value); 
        }
    };
    return <FormControlLabel
        control={
            <Checkbox
                checked={props.checked}
                onChange={handleChange}
                name={props.label}
                color="primary"
            />
        }
        label={props.label}
    />;
}
export const GridEnumFilter_CheckBox = props => {
    const checkboxes = [];
    const filters = props.filterList[props.index];
    const handleChange = (isAdd, val) => {
        if (isAdd) {
            filters.push(val);
            props.onChange(filters, props.index, props.column)
        } else {
            props.onChange(filters.filter(v => v != val), props.index, props.column);
        }
        
    };
    const keys = Object.keys(props.enum)
        .forEach(key => {
            const val = props.enum[key];
            checkboxes.push(<EnumCB label={key} value={val} checked={filters.includes(val)}
                onChange={handleChange} key={'enum_cb_'+key+'_'+val }
            />);
        });
    
    return <FormControl component="fieldset">
        <FormLabel component="legend">{props.column.label}</FormLabel>
        { checkboxes }
        
    </FormControl>;
};
export const EnumFilterLogic = (value, filters, row) => {
    if (Array.isArray(filters) && filters.length > 0) {
        if (!filters.includes(value)) {
            return true;
        }
    }
    return false;
};
export const EnumUpdateFilterList = (filterList, filterPos, index) => {
    if (filterPos < 0)
        filterPos = 0;

    filterList[index].splice(filterPos,1);
    return filterList;
}
export const EnumRenderValue = inEnumObj => {
    const enumObj = inEnumObj;
    return (value) => {
        return value.map((val, ind, arr)=> { return Object.keys(enumObj).find(key => enumObj[key] == val) });
    };
}