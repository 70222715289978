import Constants from '../../constants';
import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '../TabPanel';
import VisitsTab from './PlantEdit_VisitsTab';
import ActionItemsTab from './PlantEdit_ActionItemsTab';
import PlantKeyItemsGrid from '../KeyItems/PlantKeyItemsGrid';
const PlantItemsTab = props => {
    const [active, setActive] = useState(0);
    const switchTab = (event, newValue) =>{
        if (active !== newValue) {
            setActive( newValue );
        }
    }
    return <div style={{ flexGrow: 1, width: '100%', backgroundColor: 'white' }}>
        <AppBar position="static">
            <Tabs
                value={active}
                onChange={switchTab}
                indicatorColor="primary"

                textColor="primary"
                style={{ backgroundColor: 'white' }}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="Plant Edit Tabs"
            >
                <Tab label="Visits" />
                <Tab label="Actions" />
                <Tab label="KeyItems" />
              
            </Tabs>
        </AppBar>
        <TabPanel value={active} index={0}>
            <VisitsTab PlantData={props.PlantData} />
        </TabPanel>
        <TabPanel value={active} index={1}>
            <ActionItemsTab PlantData={props.PlantData} />
        </TabPanel>
        <TabPanel value={active} index={2}>
            <PlantKeyItemsGrid plantID={props.PlantData?.id } />
        </TabPanel>
    </div>
}
export default PlantItemsTab;