import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import Constants from '../../constants';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';

import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { ThemeProvider, StyledEngineProvider, createTheme, adaptV4Theme } from "@mui/material/styles";
import MUIDataTable, { ExpandButton } from "mui-datatables";
import {  StepTypeView } from '../Formula/StepTypeViews';
import { UnitView } from '../Formula/UnitViews';
export const FormulaChangeGrid = props => {
    const online = useSelector(state => state.offline.online, shallowEqual);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);

    // Note: the empty deps array [] means
    // this useEffect will run once
    // similar to componentDidMount()
    useEffect(() => {
        fetch(Constants.URLs.FormulaChange +'?formulaID='+props.formulaID)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setItems(result);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, []);
    if (!online) {
        return <div>Formula History not availible offline.</div>
    }
    const sortedChanges = items.sort((a, b) => b.changedOn - a.changedOn);
    if (!isLoaded) {
        return <div>Loading ...</div>
    }
    const ExpandDisplay = (rowData, rowMeta) => {
        const colSpan = rowData.length + 1;
        const sourceData = sortedChanges[rowMeta.dataIndex];
        let newData = {};
        if (sourceData.newData) {
            newData = JSON.parse(sourceData.newData);
        }
        let oldData = {};
        if (sourceData.oldData) {
            oldData = JSON.parse(sourceData.oldData);
        }
        let changeDisp = null;
        switch (sourceData.changeType) {
            case 0: //Formula Added
                try {
                    changeDisp = (<Table>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell >Type</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>Added</TableCell>
                                <TableCell>{newData.Name}</TableCell>
                                <TableCell ><FormulaTypeDisp value={newData.CalculationType} /></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    );
                } catch (err) {
                    changeDisp = "Error Loading Changes";
                }
                break;
            case 1: //Formula Deleted
                changeDisp = (<Table>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell >Type</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>Deleted</TableCell>
                            <TableCell>{newData.Name}</TableCell>
                            <TableCell ><FormulaTypeDisp value={newData.CalculationType} /></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                );
                break;
            case 2: //Formula Change
                changeDisp = (<Table>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell >Type</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>New</TableCell>
                            <TableCell>{newData.Name}</TableCell>
                            <TableCell ><FormulaTypeDisp value={newData.CalculationType} /></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Old</TableCell>
                            <TableCell>{oldData.Name}</TableCell>
                            <TableCell ><FormulaTypeDisp value={oldData.CalculationType} /></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>

                );
                break;
            case 3: //Step Added
                const addFields = [];
                if (newData.StepNumber) {
                    addFields.push(<TableRow>
                        <TableCell>Step Number</TableCell>
                        <TableCell >{newData.StepNumber}</TableCell>
                    </TableRow>);
                }
                if (newData.StepType) {
                    addFields.push(<TableRow>
                        <TableCell>Ingredient</TableCell>
                        <TableCell ><StepTypeView value={newData.StepType} /></TableCell>
                    </TableRow>);
                }
                if (newData.StepDescription) {
                    addFields.push(<TableRow>
                        <TableCell>Other Product</TableCell>
                        <TableCell >{newData.StepDescription}</TableCell>
                    </TableRow>);
                }
                if (newData.LbsPerGallon) {
                    addFields.push(<TableRow>
                        <TableCell>LbsPerGallon</TableCell>
                        <TableCell >{newData.LbsPerGallon}</TableCell>
                    </TableRow>);
                }
                if (newData.DryPercentage) {
                    addFields.push(<TableRow>
                        <TableCell>DryPercentage</TableCell>
                        <TableCell >{newData.DryPercentage}</TableCell>
                    </TableRow>);
                }
                if (newData.Product) {
                    addFields.push(<TableRow>
                        <TableCell>Product</TableCell>
                        <TableCell >{newData.Product}</TableCell>
                    </TableRow>);
                }
                if (newData.Value) {
                    addFields.push(<TableRow>
                        <TableCell>Amount</TableCell>
                        <TableCell >{newData.Value}</TableCell>
                    </TableRow>);
                }
                if (newData.Unit) {
                    addFields.push(<TableRow>
                        <TableCell>Unit</TableCell>
                        <TableCell ><UnitView value={newData.Unit} /></TableCell>
                    </TableRow>);
                }
                if (newData.MixTime) {
                    addFields.push(<TableRow>
                        <TableCell>MixTime</TableCell>
                        <TableCell >{newData.MixTime}</TableCell>
                    </TableRow>);
                }
                if (newData.Cost) {
                    addFields.push(<TableRow>
                        <TableCell>Cost</TableCell>
                        <TableCell >{newData.Cost}</TableCell>
                    </TableRow>);
                }
                if (newData.Other) {
                    addFields.push(<TableRow>
                        <TableCell>Other</TableCell>
                        <TableCell >{newData.Other}</TableCell>
                    </TableRow>);
                }
                changeDisp = (<Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Field</TableCell>
                            <TableCell >New Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {addFields}
                    </TableBody>
                </Table>
                );
                break;
            case 4: //Step Deleted
                const delFields = [];
                if (newData.StepNumber) {
                    delFields.push(<TableRow>
                        <TableCell>Step Number</TableCell>
                        <TableCell >{newData.StepNumber}</TableCell>
                    </TableRow>);
                }
                if (newData.StepType ) {
                    delFields.push(<TableRow>
                        <TableCell>Ingredient</TableCell>
                        <TableCell ><StepTypeView value={newData.StepType} /></TableCell>
                    </TableRow>);
                }
                if (newData.StepDescription) {
                    delFields.push(<TableRow>
                        <TableCell>Other Product</TableCell>
                        <TableCell >{newData.StepDescription}</TableCell>
                    </TableRow>);
                }
                if (newData.LbsPerGallon) {
                    delFields.push(<TableRow>
                        <TableCell>LbsPerGallon</TableCell>
                        <TableCell >{newData.LbsPerGallon}</TableCell>
                    </TableRow>);
                }
                if (newData.DryPercentage) {
                    delFields.push(<TableRow>
                        <TableCell>DryPercentage</TableCell>
                        <TableCell >{newData.DryPercentage}</TableCell>
                    </TableRow>);
                }
                if (newData.Product) {
                    delFields.push(<TableRow>
                        <TableCell>Product</TableCell>
                        <TableCell >{newData.Product}</TableCell>
                    </TableRow>);
                }
                if (newData.Value) {
                    delFields.push(<TableRow>
                        <TableCell>Amount</TableCell>
                        <TableCell >{newData.Value}</TableCell>
                    </TableRow>);
                }
                if (newData.Unit ) {
                    delFields.push(<TableRow>
                        <TableCell>Unit</TableCell>
                        <TableCell ><UnitView value={newData.Unit} /></TableCell>
                    </TableRow>);
                }
                if (newData.MixTime) {
                    delFields.push(<TableRow>
                        <TableCell>MixTime</TableCell>
                        <TableCell >{newData.MixTime}</TableCell>
                    </TableRow>);
                }
                if (newData.Cost) {
                    delFields.push(<TableRow>
                        <TableCell>Cost</TableCell>
                        <TableCell >{newData.Cost}</TableCell>
                    </TableRow>);
                }
                if (newData.Other) {
                    delFields.push(<TableRow>
                        <TableCell>Other</TableCell>
                        <TableCell >{newData.Other}</TableCell>
                    </TableRow>);
                }
                changeDisp = (<Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Field</TableCell>
                            <TableCell >New Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {delFields}
                    </TableBody>
                </Table>
                );
                break;
            case 5: //Step Edited
                const changes = [];
                if (newData.StepNumber != oldData.StepNumber) {
                    changes.push(<TableRow>
                        <TableCell>Step Number</TableCell>
                        <TableCell>{oldData.StepNumber}</TableCell>
                        <TableCell >{newData.StepNumber}</TableCell>
                    </TableRow>);
                }
                if (newData.StepType != oldData.StepType) {
                    changes.push(<TableRow>
                        <TableCell>Ingredient</TableCell>
                        <TableCell><StepTypeView value={oldData.StepType} /></TableCell>
                        <TableCell ><StepTypeView value={newData.StepType} /></TableCell>
                    </TableRow>);
                }
                if (newData.StepDescription != oldData.StepDescription) {
                    changes.push(<TableRow>
                        <TableCell>Other Product</TableCell>
                        <TableCell>{oldData.StepDescription}</TableCell>
                        <TableCell >{newData.StepDescription}</TableCell>
                    </TableRow>);
                }
                if (newData.LbsPerGallon != oldData.LbsPerGallon) {
                    changes.push(<TableRow>
                        <TableCell>LbsPerGallon</TableCell>
                        <TableCell>{oldData.LbsPerGallon}</TableCell>
                        <TableCell >{newData.LbsPerGallon}</TableCell>
                    </TableRow>);
                }
                if (newData.DryPercentage != oldData.DryPercentage) {
                    changes.push(<TableRow>
                        <TableCell>DryPercentage</TableCell>
                        <TableCell>{oldData.DryPercentage}</TableCell>
                        <TableCell >{newData.DryPercentage}</TableCell>
                    </TableRow>);
                }
                if (newData.Product != oldData.Product) {
                    changes.push(<TableRow>
                        <TableCell>Product</TableCell>
                        <TableCell>{oldData.Product}</TableCell>
                        <TableCell >{newData.Product}</TableCell>
                    </TableRow>);
                }
                if (newData.Value != oldData.Value) {
                    changes.push(<TableRow>
                        <TableCell>Amount</TableCell>
                        <TableCell>{oldData.Value}</TableCell>
                        <TableCell >{newData.Value}</TableCell>
                    </TableRow>);
                }
                if (newData.Unit != oldData.Unit) {
                    changes.push(<TableRow>
                        <TableCell>Unit</TableCell>
                        <TableCell><UnitView value={oldData.Unit} /></TableCell>
                        <TableCell ><UnitView value={newData.Unit} /></TableCell>
                    </TableRow>);
                }
                if (newData.MixTime != oldData.MixTime) {
                    changes.push(<TableRow>
                        <TableCell>MixTime</TableCell>
                        <TableCell>{oldData.MixTime}</TableCell>
                        <TableCell >{newData.MixTime}</TableCell>
                    </TableRow>);
                }
                if (newData.Cost != oldData.Cost) {
                    changes.push(<TableRow>
                        <TableCell>Cost</TableCell>
                        <TableCell>{oldData.Cost}</TableCell>
                        <TableCell >{newData.Cost}</TableCell>
                    </TableRow>);
                }
                if (newData.Other != oldData.Other) {
                    changes.push(<TableRow>
                        <TableCell>Other</TableCell>
                        <TableCell>{oldData.Other}</TableCell>
                        <TableCell >{newData.Other}</TableCell>
                    </TableRow>);
                }
                changeDisp = (<Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Field</TableCell>
                            <TableCell>Old Value</TableCell>
                            <TableCell >New Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { changes }
                    </TableBody>
                </Table>
                );
                break;
            case 6: //Step Number Change
                changeDisp = (<Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Field</TableCell>
                            <TableCell>Old Value</TableCell>
                            <TableCell >New Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>Step Number</TableCell>
                            <TableCell>{oldData.StepNumber}</TableCell>
                            <TableCell >{newData.StepNumber}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                );
                break;
            default:
                changeDisp = "Type Error";
                break;
        }
        return (
            <TableRow>
                <TableCell colSpan={colSpan}>
                    {changeDisp}
                </TableCell>
            </TableRow>
        );
    };
    const columns = [
        {
            name: "changeType",
            label:"Change Type",
            options: {
                filter: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <ChangeType value={value} />
                    );
                }

            }
        },
        {
            label:"Changed On",
            name: "changedOn",
            options: {
                filter: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <DateTimeDisp value={value} />
                    );
                }
            }
        },
        {
            label:"ChangeComment",
            name: "changeComment",
            options: {
                filter: false,
            }
        }
    ];
    const options = {
        filter: true,
        filterType: 'dropdown',
        responsive: 'standard',
        expandableRows: true,
        expandableRowsHeader: false,
        expandableRowsOnClick: true,
        isRowExpandable: (dataIndex, expandedRows) => {
            return true;
        },
        
        renderExpandableRow: ExpandDisplay,
        onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) => console.log(curExpanded, allExpanded, rowsExpanded),
        selectableRows: 'none',
        sortOrder: {
            name: 'changedOn',direction:'desc'
        }
    };
    const theme = createTheme(adaptV4Theme({
        overrides: {
            MUIDataTableSelectCell: {
                expandDisabled: {
                    // Soft hide the button.
                    visibility: 'hidden',
                },
            },
        },
    }));
    
    const components = {
        ExpandButton: function (props) {
            if (props.dataIndex === 3 || props.dataIndex === 4) return <div style={{ width: '24px' }} />;
            return <ExpandButton {...props} />;
        }
    };
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <MUIDataTable  data={sortedChanges} columns={columns} options={options} components={components} />
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
const ChangeType = props => {
    let retVal = 'Error';
    switch (props.value) {
        case 0:
            retVal = "Added";
            break;
        case 1:
            retVal = "Deleted";
            break;
        case 2:
            retVal = "Changed";
            break;
        case 3:
            retVal = "Step Added";
            break;
        case 4:
            retVal = "Step Deleted";
            break;
        case 5:
            retVal = "Step Edited";
            break;
        case 6:
            retVal = "Step Order Changed";
            break;
        default:
            retVal = "Type Error";
            break;
    }
    return <div>{retVal}</div>;
};

const DateTimeDisp = props => {
    const d = new Date(props.value);
    return <div>{d.toLocaleDateString() + ' - ' + d.toLocaleTimeString()}</div>;
};
const FormulaTypeDisp = props => {
    if (props.value == 0) {
        return <>Standard</>;
    } else if (props.value == 1) {
        return <>Westrock</>;
    } else {
        return <>Error</>;
    }
};