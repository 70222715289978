import Constants from '../constants';
import produce from "immer";
import { UpdateArray, ChangeOrder, UpdateArrayRange } from '../misc/ArrayFuncs';
const initDataState = [];

const Machines = (state = initDataState, action) => {
    switch (action.type) {
        case Constants.Actions.Machines_ReceiveByPlant:
            return UpdateArrayRange(action.data, state);
        //Update
        case Constants.Actions.Machines_Update:
            return state.map((p, ind) => {
                if (p.id === action.payload.id) {
                    return produce(p, pd => { pd[action.payload.name] = action.payload.newValue });
                } else { return p; }
            });
            
        case Constants.Actions.Machines_Update_Commit:
            return UpdateArray(action.payload?.data, state);
        case Constants.Actions.Machines_Update_Rollback:
            //Action will have the full plant data
            return state.map((p, ind) => {
                if (p.id === action.meta.updatePayload.id) {
                    return produce(p, pd => { pd[action.meta.updatePayload.name] = action.meta.updatePayload.oldValue });
                } else { return p; }
            });
        //Add    
        case Constants.Actions.Machines_Add:
            return [...state, action.payload];
        case Constants.Actions.Machines_Add_Rollback:
            const machineInd = state.findIndex(machine => machine.id === action.meta.newMachine.id);
            if (machineInd !== -1) {
                return produce(state, pd => { pd.splice(machineInd, 1) });
            } else {
                return state;
            }
        case Constants.Actions.Machines_Add_Commit:
            return UpdateArray(action.payload?.data, state);
        //Delete
        case Constants.Actions.Machines_Delete:
            const delMac = state.find(m => m.id === action.payload.id);
            return state
                .map((val, ind, array) => {
                    if(val.id==delMac.id){return produce(val, d => { d.deleted=true; }) }
                    if (val.lineID === delMac.lineID && val.order > delMac.order && !val.deleted) {
                        return produce(val, d => { d.order--; })
                    }
                    return val;
                });
        case Constants.Actions.Machines_Delete_Commit:
            return state;
        case Constants.Actions.Machines_Delete_Rollback:
            return UpdateArray(action.meta.delMachine, state);
        case Constants.Actions.Machines_ChangeOrder:
            return ChangeOrder(action.payload.id, state, action.payload.isUp, (a, b) => a.lineID == b.lineID);       
        case Constants.Actions.Machines_ChangeOrder_Commit:
            return state;
        case Constants.Actions.Machines_ChangeOrder_Rollback:
            let isUp = false;
            if (action.meta.changeData.isUp)
                isUp = true;
            return ChangeOrder(action.meta.changeData.id, state, isUp, (a, b) => a.lineID == b.lineID);
        case Constants.Actions.Machines_ReOrder:
            return state;
        case Constants.Actions.Machines_ClearData:
            return [];
        default:
            return state;
    };
}
export default Machines;