import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { Formik, Form, Field, useFormikContext } from 'formik';
const TextFieldFormik = props => {
    const { values, handleChange, touched, errors } = useFormikContext();
    return <TextField
        multiline
        variant="outlined"
        name={props.name}
        type={ props.type}
        InputProps={{
            inputProps: { step: "any" }
        }}
        value={values[props.name]}
        onChange={handleChange}
        error={touched[props.name] && Boolean(errors[props.name])}
        helperText={touched[props.name] && errors[props.name]}
        label={ props.label}
        />
    
    
}
export default TextFieldFormik;