import Constants from '../../constants';
//Library Imports
import React, { useEffect, useState } from 'react';
import { useSelector,useDispatch,shallowEqual } from 'react-redux';
//MUI Imports
import Grid from '@mui/material/Grid';
import { Button } from '@mui/material';
//Compontent Imports
import ImagePrintView from '../ImageAttachment/ImagePrintView';
import { PersonnelName } from '../MetaData/PlantOptions';
import { usePlantData, useReportName, useReportUser } from '../../misc/UseFuncs';
import { AttachmentsByFilterArray } from '../../selectors/AttachmentSelectors';
import { PersonnelByPlantID } from '../../selectors/PlantSelectors';
import { GetAttachmentsByArray } from '../../actions/AttachmentActions';
import ReportViewer from '../Report/ReportViewer';
import { ScaleImages } from '../../misc/ImageFuncs';
import { ErrorNotification } from '../../actions/NotificationActions';
const ActionItemsPrint = props => {
    const aiPrintData=useActionItemPrintData(props.actionItems,props.visit);
    if(!aiPrintData.Loaded){
        return <div>Loading Data</div>;
    }
    return <ReportViewer type="ActionItem" name="ActionItem" printData={aiPrintData}
    onClose={props.onClose}  />;
}
export default ActionItemsPrint;
export const useActionItemPrintData = (actionItems,visit)=>{
    const [scaled, setScaled] = useState(false);
    const [scaledAttachements, setScaledAttachments] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const dispatch=useDispatch();
    const online =useSelector(s=>s.offline.online,shallowEqual);
    const LastSync=useSelector(s=>s.LastSync,shallowEqual);
    const Token=useSelector(s=>s.Auth.token,shallowEqual);
    const imageIDs=actionItems.filter(ai=>ai.imageID!=Constants.EmptyGuid).map(ai=>ai.imageID);
    useEffect(() => {
        if ( online){
            GetAttachmentsByArray(actionItems,dispatch,LastSync,Token)
            .then(
                ()=>setTimeout(()=>setLoaded(true),200),
                ()=>{
                    dispatch(ErrorNotification("Error Loading Attachments for Report",{File:"ActionItemsPrint.js",Function:"useActionItemPrintData"}));
                    setTimeout(() => setLoaded(true), 200);
                }
            );
        } else {
            setTimeout(() => setLoaded(true), 200)
        }
    }, [JSON.stringify(imageIDs)]);
    
    const UserData= useReportUser();
    const plant = usePlantData(actionItems.find(ai=>1==1)?.plantID);
    const attachments=useSelector(state=>AttachmentsByFilterArray(state,actionItems));
    const personnel = useSelector(state=>PersonnelByPlantID(state,plant?.id));
    const reportName = useReportName("ActionItem",plant.name,visit);
    const actions = actionItems.map(ai=>{
        let statusText='';
        switch (ai.status) {
            case 0:
                statusText ="Open";
                break;
            case 1:
                statusText ="Closed";
                break;
            case 2:
                statusText = "WIP";
                break;
            case 3:
                statusText = "Cancelled";
                break;
        }
        let championName='';
        const p = personnel.find(p=>p.id===ai.championID);
        if(p){
            championName=p.name;
        } else {
            if(ai.championID== '11111111-1111-1111-1111-111111111111'){ championName='CCI';}
        }
        return {...ai, statusText:statusText,championName:championName};
    });
    let visitDate=null;
    if(visit){
        const d=new Date(visit.startDate);
        visitDate=+(d.getMonth()+1).toString().padStart(2,"0")+"/"+d.getDate().toString().padStart(2,"0")+"/"+d.getFullYear();
    }
    const retVal ={Loaded:false,Data:{},ReportName:reportName,EmailSubject:'Action Items '+plant?.name+' '+visitDate}
    if (loaded) {
        if(scaled){
            const dJSON ={ ActionItems:actions, User:UserData, Plant:plant, Visit:{}, Attachments:scaledAttachements} ;
            retVal.Loaded=true;
            retVal.Data={ ActionsData:JSON.stringify(dJSON) };
        } else {
            Promise.all(ScaleImages(attachments, 200, 220))
                .then((values) => {
                    setScaledAttachments(values);
                    setScaled(true);
                });
        }
    } 
    return retVal;
}