const Constants = {
    Actions: {
        SetView: "SetView"
        , BackView: "BackView"
        , ToggleTouch: "Toggle_Touch"
        //Plants
        , RequestPlants: "Request_Plants"
        , ReceivePlants: "Receive_Plants"
        , UpdatePlant: "Update_Plant"
        , UpdatePlant_Commit: "Update_Plant_Commit"
        , UpdatePlant_Rollback: "Update_Plant_Rollback"
        , AddPlant: "Add_Plant"
        , AddPlant_Rollback: "Add_Plant_Rollback"
        , AddPlant_Commit: "Add_Plant_Commit"
        , AddPlantCache: "Add_Plant_Cache"
        , Plant_ClearData:"Plant_ClearData"
        //End Plants
        //Personnel
        , RequestPersonnel: "Request_Personnel"
        , Personnel_ReceiveByPlant: "Personnel_ReceiveByPlant"
        , UpdatePersonnel: "Update_Personnel"
        , UpdatePersonnel_Commit: "Update_Personnel_Commit"
        , UpdatePersonnel_Rollback: "Update_Personnel_Rollback"
        , AddPersonnel: "Add_Personnel"
        , AddPersonnel_Rollback: "Add_Personnel_Rollback"
        , AddPersonnel_Commit: "Add_Personnel_Commit"
        , Personnel_Delete: "Personnel_Delete"
        , Personnel_Delete_Commit: "Personnel_Delete_Commit"
        , Personnel_Delete_Rollback: "Personnel_Delete_Rollback"
        , Personnel_ClearData:"Personnel_ClearData"
        //End Personnel
        //CorrugatorAdhesive
        , CorrugatorAdhesive_Request: "CorrugatorAdhesive_Request"
        , CorrugatorAdhesive_ReceiveByPlant: "CorrugatorAdhesive_ReceiveByPlant"
        , CorrugatorAdhesive_Update: "CorrugatorAdhesive_Update"
        , CorrugatorAdhesive_Update_Commit: "CorrugatorAdhesive_Update_Commit"
        , CorrugatorAdhesive_Update_Rollback: "CorrugatorAdhesive_Update_Rollback"
        , CorrugatorAdhesive_Add: "CorrugatorAdhesive_Add"
        , CorrugatorAdhesive_Add_Rollback: "CorrugatorAdhesive_Add_Rollback"
        , CorrugatorAdhesive_Add_Commit: "CorrugatorAdhesive_Add_Commit"
        , CorrugatorAdhesive_Delete: "CorrugatorAdhesive_Delete"
        , CorrugatorAdhesive_Delete_Commit: "CorrugatorAdhesive_Delete_Commit"
        , CorrugatorAdhesive_Delete_Rollback: "CorrugatorAdhesive_Delete_Rollback"
        , CorrugatorAdhesive_ClearData: "CorrugatorAdhesive_ClearData"
        //End CorrugatorAdhesive
        //DesiredSpeedImprovement
        , DesiredSpeedImprovement_Request: "DesiredSpeedImprovement_Request"
        , DesiredSpeedImprovement_ReceiveByPlant: "DesiredSpeedImprovement_ReceiveByPlant"
        , DesiredSpeedImprovement_Update: "DesiredSpeedImprovement_Update"
        , DesiredSpeedImprovement_Update_Commit: "DesiredSpeedImprovement_Update_Commit"
        , DesiredSpeedImprovement_Update_Rollback: "DesiredSpeedImprovement_Update_Rollback"
        , DesiredSpeedImprovement_Add: "DesiredSpeedImprovement_Add"
        , DesiredSpeedImprovement_Add_Rollback: "DesiredSpeedImprovement_Add_Rollback"
        , DesiredSpeedImprovement_Add_Commit: "DesiredSpeedImprovement_Add_Commit"
        , DesiredSpeedImprovement_Delete: "DesiredSpeedImprovement_Delete"
        , DesiredSpeedImprovement_Delete_Commit: "DesiredSpeedImprovement_Delete_Commit"
        , DesiredSpeedImprovement_Delete_Rollback: "DesiredSpeedImprovement_Delete_Rollback"
        , DesiredSpeedImprovement_ClearData:"DesiredSpeedImprovement_ClearData"
        //End DesiredSpeedImprovement
        //Line
        , Lines_Request: "Lines_Request"
        , Lines_ReceiveByPlant: "Lines_ReceiveByPlant"
        , Lines_GetByID:"Lines_GetByID"
        , Lines_Update: "Lines_Update"
        , Lines_Update_Commit: "Lines_Update_Commit"
        , Lines_Update_Rollback: "Lines_Update_Rollback"
        , Lines_Add: "Lines_Add"
        , Lines_Add_Rollback: "Lines_Add_Rollback"
        , Lines_Add_Commit: "Lines_Add_Commit"
        , Lines_Delete: "Lines_Delete"
        , Lines_Delete_Commit: "Lines_Delete_Commit"
        , Lines_Delete_Rollback: "Lines_Delete_Rollback"
        , Lines_ClearData:"Lines_ClearData"
        //End Lines
        //Machines
        , Machines_Request: "Machines_Request"
        , Machines_ReceiveByPlant: "Machines_ReceiveByPlant"
        , Machines_Update: "Machines_Update"
        , Machines_Update_Commit: "Machines_Update_Commit"
        , Machines_Update_Rollback: "Machines_Update_Rollback"
        , Machines_Add: "Machines_Add"
        , Machines_Add_Rollback: "Machines_Add_Rollback"
        , Machines_Add_Commit: "Machines_Add_Commit"
        , Machines_Delete: "Machines_Delete"
        , Machines_Delete_Commit: "Machines_Delete_Commit"
        , Machines_Delete_Rollback: "Machines_Delete_Rollback"
        , Machines_ChangeOrder: "Machines_ChangeOrder"
        , Machines_ChangeOrder_Commit: "Machines_ChangeOrder_Commit"
        , Machines_ChangeOrder_Rollback: "Machines_ChangeOrder_Rollback"
        , Machines_Change_Num_HotPlates: "Machines_Change_Num_HotPlates"
        , Machines_ClearData: "Machines_ClearData"
        , Machines_ReOrder: "Machines_ReOrder"
        , Machines_ReOrder_Commit: "Machines_ReOrder_Commit"
        , Machines_ReOrder_Rollback: "Machines_ReOrder_Rollback"
        //End Machines
        //MachinesVessels
        , MachineVessels_Request: "MachineVessels_Request"
        , MachineVessels_ReceiveByPlant: "MachineVessels_ReceiveByPlant"
        , MachineVessels_ReceiveByMachine: "MachineVessels_ReceiveByMachine"
        , MachineVessel_Add: "MachineVessel_Add"
        , MachineVessel_Add_Rollback: "MachineVessel_Add_Rollback"
        , MachineVessel_Add_Commit: "MachineVessel_Add_Commit"
        , MachineVessel_Delete: "MachineVessel_Delete"
        , MachineVessel_Delete_Commit: "MachineVessel_Delete_Commit"
        , MachineVessel_Delete_Rollback: "MachineVessel_Delete_Rollback"
        , MachineVessel_ChangeOrder: "MachineVessel_ChangeOrder"
        , MachineVessel_ChangeOrder_Commit: "MachineVessel_ChangeOrder_Commit"
        , MachineVessel_ChangeOrder_Rollback: "MachineVessel_ChangeOrder_Rollback"
        , MachineVessel_ReOrder: "MachineVessel_ReOrder"
        , MachineVessel_ReOrder_Commit: "MachineVessel_ReOrder_Commit"
        , MachineVessel_ReOrder_Rollback: "MachineVessel_ReOrder_Rollback"
        , MachineVessel_ClearData:"MachineVessel_ClearData"
        //End MachinesVessels
        //MachinesFlutes
        , MachineFlutes_Request: "MachineFlutes_Request"
        , MachineFlutes_ReceiveByPlant: "MachineFlutes_ReceiveByPlant"
        , MachineFlute_Add: "MachineFlute_Add"
        , MachineFlute_Add_Rollback: "MachineFlute_Add_Rollback"
        , MachineFlute_Add_Commit: "MachineFlute_Add_Commit"
        , MachineFlute_Delete: "MachineFlute_Delete"
        , MachineFlute_Delete_Commit: "MachineFlute_Delete_Commit"
        , MachineFlute_Delete_Rollback: "MachineFlute_Delete_Rollback"
        , MachineFlute_ChangeOrder: "MachineFlute_ChangeOrder"
        , MachineFlute_ChangeOrder_Commit: "MachineFlute_ChangeOrder_Commit"
        , MachineFlute_ChangeOrder_Rollback: "MachineFlute_ChangeOrder_Rollback"
        , MachineFlute_ClearData:"MachineFlute_ClearData"
        //End MachinesFlutes
        //Visists
        , ReceiveVisits: "Receive_Visits"
        , ReceiveBaselines: "Receive_Baselines"
        , ReceiveContImprove: "Receive_ContImprove"
        , UpdateVisit: "Update_Visit"
        , UpdateVisit_Commit: "UpdateVisit_Commit"
        , UpdateVisit_Rollback: "UpdateVisit_Rollback"
        , AddVisit: "Add_Visit"
        , AddVisit_Commit: "Add_Visit_Commit"
        , AddVisit_Rollback: "Add_Visit_Rollback"
        , Visits_ClearData: "Visit_ClearData"
        , Visits_GetByPlantID:"Visits_GetByPlantID"
        , Baselines_GetByPlantID:"Baselines_GetByPlantID"
        , ContImprove_GetByVisitID:"ContImprove_GetByVisitID"
        //End Visists
        , ReceiveSurveys: "Receive_Surveys"
        , ReceiveSurveyData: "Receive_Survey_Data"
        , AddSurvey: "Add_New_Survey"
        , UpdateSurvey: "Update_Survey"
        //Attachments
        ,Attachment:{
            Add: "Attachment_Add"
            , Add_Commit: "Attachment_Add_Commit"
            , Add_Rollback:"Attachment_Add_Rollback"
            , Receive: "Receive_Attachment"
            , Delete: "Attachment_Delete"
            , Delete_Commit: "Attachment_Delete_Commit"
            , Delete_Rollback: "Attachment_Delete_Rollback"
            , ClearData:"Attachments_ClearData",
            Update:"Attachment_Update",
            Update_Commit:"Attachment_Update_Commit",
            Update_Rollback:"Attachment_Update_Rollback"
        }
        ,OutboundEmail:{
            Send:"OutboundEmail_Send",
            Send_Commit:"OutboundEmail_Send_Commit",
            Send_Rollback:"OutboundEmail_Send_Rollback"
        }
        //End Attachments
        , DataError: "Data_Error"
        //Vessels
        , ReceiveVessels: "Receive_Vessels"
        , AddVessel: "Add_Vessel"
        , AddVessel_Commit: "AddVessel_Commit"
        , AddVessel_Rollback: "AddVessel_Rollback"
        , Vessel_ClearData:"Vessel_ClearData"
        //End Vessels
        //Formulas
        , Formula_ReceiveByPlant: "Formula_ReceiveByPlant"
        , Formula_Add: "Formula_Add"
        , Formula_Add_Commit: "Formula_Add_Commit"
        , Formula_Add_Rollback: "Formula_Add_Rollback"
        , Formula_Update: "Formula_Update"
        , Formula_Update_Commit: "Formula_Update_Commit"
        , Formula_Update_Rollback: "Formula_Update_Rollback"
        , Formula_Delete: "Formula_Delete"
        , Formula_Delete_Commit: "Formula_Delete_Commit"
        , Formula_Delete_Rollback: "Formula_Delete_Rollback"
        , Formula_Calculate_Summary: "Formula_Calculate_Summary"
        , Formula_ClearData:"Formula_ClearData"
        //End Formulas
        //FormulaSteps
        , FormulaSteps_Request: "FormulaSteps_Request"
        , FormulaSteps_ReceiveByPlant: "FormulaSteps_ReceiveByPlant"
        , FormulaStep_Add: "FormulaStep_Add"
        , FormulaStep_Add_Rollback: "FormulaStep_Add_Rollback"
        , FormulaStep_Add_Commit: "FormulaStep_Add_Commit"
        , FormulaStep_Delete: "FormulaStep_Delete"
        , FormulaStep_Delete_Commit: "FormulaStep_Delete_Commit"
        , FormulaStep_Delete_Rollback: "FormulaStep_Delete_Rollback"
        , FormulaStep_ChangeOrder: "FormulaStep_ChangeOrder"
        , FormulaStep_ChangeOrder_Commit: "FormulaStep_ChangeOrder_Commit"
        , FormulaStep_ChangeOrder_Rollback: "FormulaStep_ChangeOrder_Rollback"
        , FormulaStep_Update: "FormulaStep_Update"
        , FormulaStep_Update_Commit: "FormulaStep_Update_Commit"
        , FormulaStep_Update_Rollback: "FormulaStep_Update_Rollback"
        , FormulaStep_ReOrder: "FormulaStep_ReOrder"
        , FormulaStep_ReOrder_Commit: "FormulaStep_ReOrder_Commit"
        , FormulaStep_ReOrder_Rollback: "FormulaStep_ReOrder_Rollback"
        , FormulaStep_ClearData:"FormulaStep_ClearData"
        //End FormulaSteps
        //FormulaChanges
        , FormulaChanges_ReceiveByPlant: "FormulaChanges_ReceiveByPlant"
        , FormulaChanges_ReceiveByFormula: "FormulaChanges_ReceiveByFormula"
        , FormulaChanges_Add: "FormulaChanges_Add"
        , FormulaChanges_Add_Commit: "FormulaChanges_Add_Commit"
        , FormulaChanges_Add_Rollback: "FormulaChanges_Add_Rollback"
        , FormulaChanges_ClearData:"FormulaChanges_ClearData"
        //End FormulaChanges
        //Notifications
        , ENQUEUE_SNACKBAR: "ENQUEUE_SNACKBAR"
        , CLOSE_SNACKBAR: "CLOSE_SNACKBAR"
        , REMOVE_SNACKBAR: "REMOVE_SNACKBAR"
        , CLEAR_DATA_SNACKBAR:"CLEAR_DATA_SNACKBAR"
        //End Notifications
        , SHOW_PROMPT: "SHOW_PROMPT"
        , CLOSE_PROMPT: "CLOSE_PROMPT"
        //Sync Errors
        , SyncError_Add: "SyncError_Add"
        , SyncError_Add_Rollback: "SyncError_Add_Rollback"
        , SyncError_Add_Commit: "SyncError_Add_Commit"
        , SyncError_ClearData:"SyncError_ClearData"
        //End Sync Errors
        , ReceiveAutoComplete: "Receive_AutoComplete"
        //Actions
        , ActionItems: {
            GetAll:"ActionItems_GetAll",
            GetByPlantID: "ActionItems_GetByPlantID",
            ClearData: "ActionItems_ClearData",
            Add: "ActionItems_Add",
            Add_Commit: "ActionItems_Add_Commit",
            Add_Rollback: "ActionItems_Add_Rollback",
            Delete: "ActionItems_Delete",
            Delete_Commit: "ActionItems_Delete_Commit",
            Delete_Rollback: "ActionItems_Delete_Rollback"
        }
        , ActionItem_Update: "ActionItem_Update"
        , ActionItem_Update_Commit: "ActionItem_Update_Commit"
        , ActionItem_Update_Rollback: "ActionItem_Update_Rollback"
        , ActionItemLinks: {
            Add: "ActionItemLinks_Add",
            Add_Commit: "ActionItemLinks_Add_Commit",
            Add_Rollback: "ActionItemLinks_Add_Rollback",
            Recieve_Entity: "ActionItemLinks_Recieve_Entity",
            Recive_Visit: "ActionItemLinks_Recive_Visit",
            Delete: "ActionItemLinks_Delete",
            Delete_Commit: "ActionItemLinks_Delete_Commit",
            Delete_Rollback: "ActionItemLinks_Delete_Rollback",
            ClearData: "ActionItemLinks_ClearData",
            Update: "ActionItemLinks_Update",
            Update_Commit: "ActionItemLinks_Update_Commit",
            Update_Rollback: "ActionItemLinks_Update_Rollback",
            DeleteByActionItemID: "ActionItemLinks_DeleteByActionItemID",
            RestoreLinks:"ActionItemLinks_RestoreLinks"
        }
        //End Actions
        //IngredientVerification 
        , IngredientVerification_Add: "IngredientVerification_Add"
        , IngredientVerification_Add_Commit: "IngredientVerification_Add_Commit"
        , IngredientVerification_Add_Rollback: "IngredientVerification_Add_Rollback"
        , IngredientVerification_Update: "IngredientVerification_Update"
        , IngredientVerification_Update_Commit: "IngredientVerification_Update_Commit"
        , IngredientVerification_Update_Rollback: "IngredientVerification_Update_Rollback"
        , IngredientVerification_Delete: "IngredientVerification_Delete"
        , IngredientVerification_Delete_Commit: "IngredientVerification_Delete_Commit"
        , IngredientVerification_Delete_Rollback: "IngredientVerification_Delete_Rollback"
        , IngredientVerification_ClearData: "IngredientVerification_ClearData"
        , IngredientVerification_GetByVisit: "IngredientVerification_GetByVisit"
        , IngredientVerification_Get: "IngredientVerification_Get"
        //End IngredientVerification
        //IngredientVerificationStep
        , IngredientVerificationStep_Add: "IngredientVerificationStep_Add"
        , IngredientVerificationStep_Add_Commit: "IngredientVerificationStep_Add_Commit"
        , IngredientVerificationStep_Add_Rollback: "IngredientVerificationStep_Add_Rollback"
        , IngredientVerificationStep_Update: "IngredientVerificationStep_Update"
        , IngredientVerificationStep_Update_Commit: "IngredientVerificationStep_Update_Commit"
        , IngredientVerificationStep_Update_Rollback: "IngredientVerificationStep_Update_Rollback"
        , IngredientVerificationStep_Clear_LocalSteps: "IngredientVerificationStep_Clear_LocalSteps"
        , IngredientVerificationStep_ClearData: "IngredientVerificationStep_ClearData"
        , IngredientVerificationStep_ReCalc_AccumulatedWeight: "IngredientVerificationStep_ReCalc_AccumulatedWeight"
        , IngredientVerificationStep_GetByParentID: "IngredientVerificationStep_GetByParentID"
        //End IngredientVerificationStep
        , ReceiveRegions: "Receive_Regions"
        , ReceiveVerifications: "Receive_Verifications"
        , AddVerification: "Add_Verification"
        , UpdateVerification: "Update_Verification"
        , ReceiveVerificationData: "Receive_Verification_Data"
        , MetaData_ClearData:"MetaData_ClearData"
        , KeyItems: {
            Add: "KeyItems_Add",
            Add_Commit: "KeyItems_Add_Commit",
            Add_Rollback: "KeyItems_Add_Rollback",
            Recieve_Entity: "KeyItems_Recieve_Entity",
            Recive_Visit: "KeyItems_Recive_Visit",
            Receive_Plant:"KeyItems_Receive_Plant",
            Delete: "KeyItems_Delete",
            Delete_Commit: "KeyItems_Delete_Commit",
            Delete_Rollback: "KeyItems_Delete_Rollback",
            ClearData: "KeyItems_ClearData",
            Update: "KeyItems_Update",
            Update_Commit: "KeyItems_Update_Commit",
            Update_Rollback:"KeyItems_Update_Rollback"
        }
        , Baselines: {
            Add: "Baseline_Add",
            Add_Commit: "Baseline_Add_Commit",
            Add_Rollback: "Baseline_Add_Rollback",
            Recieve_Entity: "Baseline_Recieve_Entity",
            Recive_Plant: "Baseline_Recive_plant",
            Receive_Plant:"Baseline_Receive_Plant",
            Delete: "Baseline_Delete",
            Delete_Commit: "Baseline_Delete_Commit",
            Delete_Rollback: "Baseline_Delete_Rollback",
            ClearData: "Baseline_ClearData",
            Update: "Baseline_Update",
            Update_Commit: "Baseline_Update_Commit",
            Update_Rollback:"Baseline_Update_Rollback"
        }
        , ContImprove: {
            Add: "ContImprove_Add",
            Add_Commit: "ContImprove_Add_Commit",
            Add_Rollback: "ContImprove_Add_Rollback",
            Recieve_Entity: "ContImprove_Recieve_Entity",
            Recive_Plant: "ContImprove_Recive_plant",
            Receive_Plant:"ContImprove_Receive_Plant",
            Delete: "ContImprove_Delete",
            Delete_Commit: "ContImprove_Delete_Commit",
            Delete_Rollback: "ContImprove_Delete_Rollback",
            ClearData: "ContImprove_ClearData",
            Update: "ContImprove_Update",
            Update_Commit: "ContImprove_Update_Commit",
            Update_Rollback:"ContImprove_Update_Rollback"
        }
        , ConfirmAction: {
            Add: "ConfirmAction_ADD",
            ClearData: "ConfirmAction_ClearData",
            Confirm: "ConfirmAction_Confirm",
            Cancel: "ConfirmAction_Cancel"
        }
        , AlertAction: {
            Alert: "AlertAction_ADD",
            ClearData: "AlertAction_ClearData",
            Confirm: "AlertAction_Confirm",
            Cancel: "AlertAction_Cancel"
        }
        , Report: {
            AddUpdate: "Report_AddUpdate",
            Load: "Report_Load",
            ClearData:"Report_ClearData"
        }
        , Auth: {
            Success: "Auth_Success",
            Logout: "Auth_Logout",
            GoOffline:"Auth_Offline"
        }
        , HeatVessel: {
            Add: "HeatVessel_Add",
            Add_Commit: "HeatVessel_Add_Commit",
            Add_Rollback: "HeatVessel_Add_Rollback",
            GetByID: "HeatVessel_GetByID",
            ClearData: "HeatVessel_ClearData",
            Update: "HeatVessel_Update",
            Update_Commit: "HeatVessel_Update_Commit",
            Update_Rollback: "HeatVessel_Update_Rollback",
            GetByVisitID: "HeatVessel_GetByVisitID",
            Delete: "HeatVessel_Delete",
            Delete_Commit: "HeatVessel_Delete_Commit",
            Delete_Rollback: "HeatVessel_Delete_Rollback"
        }
        , GlueRoll: {
            Add: "GlueRoll_Add",
            Add_Commit: "GlueRoll_Add_Commit",
            Add_Rollback: "GlueRoll_Add_Rollback",
            GetByID: "GlueRoll_GetByID",
            ClearData: "GlueRoll_ClearData",
            Update: "GlueRoll_Update",
            Update_Commit: "GlueRoll_Update_Commit",
            Update_Rollback: "GlueRoll_Update_Rollback",
            GetByVisitID: "GlueRoll_GetByVisitID",
            Delete: "GlueRoll_Delete",
            Delete_Commit: "GlueRoll_Delete_Commit",
            Delete_Rollback: "GlueRoll_Delete_Rollback"
        }
        , GlueRollMeasurement: {
            Add: "GlueRollMeasurement_Add",
            Add_Commit: "GlueRollMeasurement_Add_Commit",
            Add_Rollback: "GlueRollMeasurement_Add_Rollback",
            GetByID: "GlueRollMeasurement_GetByID",
            ClearData: "GlueRollMeasurement_ClearData",
            Update: "GlueRollMeasurement_Update",
            Update_Commit: "GlueRollMeasurement_Update_Commit",
            Update_Rollback: "GlueRollMeasurement_Update_Rollback",
            GetByParentID: "GlueRollMeasurement_GetByParentID",
            Delete: "GlueRollMeasurement_Delete",
            Delete_Commit: "GlueRollMeasurement_Delete_Commit",
            Delete_Rollback: "GlueRollMeasurement_Delete_Rollback"
        }
        ,GlueRollPicture: {
            Add: "GlueRollPicture_Add",
            Add_Commit: "GlueRollPicture_Add_Commit",
            Add_Rollback: "GlueRollPicture_Add_Rollback",
            GetByID: "GlueRollPicture_GetByID",
            ClearData: "GlueRollPicture_ClearData",
            Update: "GlueRollPicture_Update",
            Update_Commit: "GlueRollPicture_Update_Commit",
            Update_Rollback: "GlueRollPicture_Update_Rollback",
            GetByParentID: "GlueRollPicture_GetByParentID",
            Delete: "GlueRollPicture_Delete",
            Delete_Commit: "GlueRollPicture_Delete_Commit",
            Delete_Rollback: "GlueRollPicture_Delete_Rollback"
        }
        , GlueLine: {
            Add: "GlueLine_Add",
            Add_Commit: "GlueLine_Add_Commit",
            Add_Rollback: "GlueLine_Add_Rollback",
            GetByID: "GlueLine_GetByID",
            ClearData: "GlueLine_ClearData",
            Update: "GlueLine_Update",
            Update_Commit: "GlueLine_Update_Commit",
            Update_Rollback: "GlueLine_Update_Rollback",
            GetByVisitID: "GlueLine_GetByVisitID",
            Delete: "GlueLine_Delete",
            Delete_Commit: "GlueLine_Delete_Commit",
            Delete_Rollback: "GlueLine_Delete_Rollback"
        }
        , GlueLineMachine: {
            Add: "GlueLineMachine_Add",
            Add_Commit: "GlueLineMachine_Add_Commit",
            Add_Rollback: "GlueLineMachine_Add_Rollback",
            GetByID: "GlueLineMachine_GetByID",
            ClearData: "GlueLineMachine_ClearData",
            Update: "GlueLineMachine_Update",
            Update_Commit: "GlueLineMachine_Update_Commit",
            Update_Rollback: "GlueLineMachine_Update_Rollback",
            GetByParentID: "GlueLineMachine_GetByParentID",
            Delete: "GlueLineMachine_Delete",
            Delete_Commit: "GlueLineMachine_Delete_Commit",
            Delete_Rollback: "GlueLineMachine_Delete_Rollback"
        }
        , RequestStatus: {
            StartRequest: "RequestStatus_StartRequest",
            RequestResult:"RequestStatus_RequestResult"
        }
        , HeatVesselTemp: {
            Add: "HeatVesselTemp_Add",
            Add_Commit: "HeatVesselTemp_Add_Commit",
            Add_Rollback: "HeatVesselTemp_Add_Rollback",
            ClearData: "HeatVesselTemp_ClearData",
            Update: "HeatVesselTemp_Update",
            Update_Commit: "HeatVesselTemp_Update_Commit",
            Update_Rollback: "HeatVesselTemp_Update_Rollback",
            GetByParentID:"HeatVesselTemp_GetByParentID"
        }
        , StarchKitchenItem: {
            Add: "StarchKitchenItem_Add",
            Add_Commit: "StarchKitchenItem_Add_Commit",
            Add_Rollback: "StarchKitchenItem_Add_Rollback",
            GetByID: "StarchKitchenItem_GetByID",
            ClearData: "StarchKitchenItem_ClearData",
            Update: "StarchKitchenItem_Update",
            Update_Commit: "StarchKitchenItem_Update_Commit",
            Update_Rollback: "StarchKitchenItem_Update_Rollback",
            GetByVisitID: "StarchKitchenItem_GetByVisitID",
            Delete: "StarchKitchenItem_Delete",
            Delete_Commit: "StarchKitchenItem_Delete_Commit",
            Delete_Rollback: "StarchKitchenItem_Delete_Rollback"
        }
        , StarchInventory: {
            Add: "StarchInventory_Add",
            Add_Commit: "StarchInventory_Add_Commit",
            Add_Rollback: "StarchInventory_Add_Rollback",
            GetByID: "StarchInventory_GetByID",
            ClearData: "StarchInventory_ClearData",
            Update: "StarchInventory_Update",
            Update_Commit: "StarchInventory_Update_Commit",
            Update_Rollback: "StarchInventory_Update_Rollback",
            GetByVisitID: "StarchInventory_GetByVisitID",
            Delete: "StarchInventory_Delete",
            Delete_Commit: "StarchInventory_Delete_Commit",
            Delete_Rollback: "StarchInventory_Delete_Rollback"
        }
        , StarchMeasurement: {
            Add: "StarchMeasurement_Add",
            Add_Commit: "StarchMeasurement_Add_Commit",
            Add_Rollback: "StarchMeasurement_Add_Rollback",
            GetByID: "StarchMeasurement_GetByID",
            ClearData: "StarchMeasurement_ClearData",
            Update: "StarchMeasurement_Update",
            Update_Commit: "StarchMeasurement_Update_Commit",
            Update_Rollback: "StarchMeasurement_Update_Rollback",
            GetByVisitID: "StarchMeasurement_GetByVisitID",
            Delete: "StarchMeasurement_Delete",
            Delete_Commit: "StarchMeasurement_Delete_Commit",
            Delete_Rollback: "StarchMeasurement_Delete_Rollback"
        }
        , Menu: {
            OpenDrawer: "Menu_OpenDrawer",
            CloseDrawer:"Menu_CloseDrawer"
        }
        , Checklist: {
            Add: "Checklist_Add",
            Add_Commit: "Checklist_Add_Commit",
            Add_Rollback: "Checklist_Add_Rollback",
            GetByID: "Checklist_GetByID",
            ClearData: "Checklist_ClearData",
            GetByVisitID: "Checklist_GetByVisitID",
            Delete: "Checklist_Delete",
            Delete_Commit: "Checklist_Delete_Commit",
            Delete_Rollback: "Checklist_Delete_Rollback"
        }
        , ChecklistGroup: {
            Add: "ChecklistGroup_Add",
            Add_Commit: "ChecklistGroup_Add_Commit",
            Add_Rollback: "ChecklistGroup_Add_Rollback",
            ClearData: "ChecklistGroup_ClearData",
            Update: "ChecklistGroup_Update",
            Update_Commit: "ChecklistGroup_Update_Commit",
            Update_Rollback: "ChecklistGroup_Update_Rollback",
            GetByParentID: "ChecklistGroup_GetByParentID"
        }
        , ChecklistItem: {
            Add: "ChecklistItem_Add",
            Add_Commit: "ChecklistItem_Add_Commit",
            Add_Rollback: "ChecklistItem_Add_Rollback",
            ClearData: "ChecklistItem_ClearData",
            Update: "ChecklistItem_Update",
            Update_Commit: "ChecklistItem_Update_Commit",
            Update_Rollback: "ChecklistItem_Update_Rollback",
            GetByParentID: "ChecklistItem_GetByParentID"
        }
        , Thermal: {
            Add: "Thermal_Add",
            Add_Commit: "Thermal_Add_Commit",
            Add_Rollback: "Thermal_Add_Rollback",
            GetByID: "Thermal_GetByID",
            ClearData: "Thermal_ClearData",
            Update: "Thermal_Update",
            Update_Commit: "Thermal_Update_Commit",
            Update_Rollback: "Thermal_Update_Rollback",
            GetByVisitID:"Thermal_GetByVisitID",
            Delete: "Thermal_Delete",
            Delete_Commit: "Thermal_Delete_Commit",
            Delete_Rollback: "Thermal_Delete_Rollback"
        }
        , ThermalImages: {
            Add: "ThermalImages_Add",
            Add_Commit: "ThermalImages_Add_Commit",
            Add_Rollback: "ThermalImages_Add_Rollback",
            GetByID: "ThermalImages_GetByID",
            ClearData: "ThermalImages_ClearData",
            Update: "ThermalImages_Update",
            Update_Commit: "ThermalImages_Update_Commit",
            Update_Rollback: "ThermalImages_Update_Rollback",
            GetByParentID: "ThermalImages_GetByParentID",
            Delete: "ThermalImages_Delete",
            Delete_Commit: "ThermalImages_Delete_Commit",
            Delete_Rollback: "ThermalImages_Delete_Rollback"
        }
        ,
        NetworkState: {
            SetOnline: "NetworkStatus_SetOnline",
            SetOffline: "NetworkStatus_SetOffline",
            SetSiteUp: "NetworkStatus_SetSiteUp",
            SetSiteDown: "NetworkStatus_SetSiteDown"
        },
        LastSync: {
            AddSync:"LastSync_AddSync",
            ClearData:"LastSync_ClearData"
        }
    },
    URLs: {
        UpdatePlant: "api/Plants/",
        AddPersonnel: "api/Personnel/",
        UpdatePersonnel: "api/Personnel/",
        Personnel_Delete: "api/Personnel/",
        OutboundEmail:"api/OutboundEmail",
        CorrugatorAdhesive: "api/CorrugatorAdhesive/",
        DesiredSpeedImprovement: "api/DesiredSpeedImprovement/",
        Lines: "api/Lines/",
        Machines: "api/Machines/",
        Machines_Update: "api/Machines/",
        MachineFlute: "api/MachineFlute/",
        MachineVessel: "api/MachineVessel/",
        AddVessel: "api/MetaData/Vessels/",
        Formulas: "api/Formulas/",
        FormulaStep: "api/FormulaSteps/",
        FormulaChange: "api/FormulaChange/",
        SyncErrors: "api/SyncErrors/",
        Attachments: "api/Attachments/",
        Visits: "api/Visits/",
        UpdateVisit:"api/Visits/",
        FormulaHistory: "api/FormulaChange/",
        IngredientVerification: "api/StarchIngredientVerifications/",
        IngredientVerificationStep: "api/StarchIngredientVerificationStep/",
        KeyItems: "api/KeyItems/",
        ActionItems: "api/ActionItems/",
        ActionItem_Update: "api/ActionItems/",
        ActionItemLinks: "api/ActionItemLinks/",
        HeatVessel: "api/HeatVessels/",
        HeatVesselTemp: 'api/HeatVesselMeasurements/',
        GlueRoll: 'api/GlueRoll/',
        GlueRollMeasurement: 'api/GlueRollMeasurement/',
        GlueRollPicture:'api/GlueRollPicture/',
        GlueLine: 'api/GlueLine/',
        GlueLineMachine: 'api/GlueLineMachines/',
        StarchKitchenItem: 'api/StarchKitchenItem/',
        Inventory: 'api/Inventory/',
        StarchMeasurement: 'api/StarchCheckMeasurements/',
        Checklist: 'api/Checklist/',
        ChecklistGroup: 'api/ChecklistGroup/',
        ChecklistItem: 'api/ChecklistItem/',
        Login:'api/Login/',
        Reports:'api/Reports/',
        Thermals:'api/Thermals/',
        ThermalImages:'api/ThermalImages/',
        TempImage: 'api/images/',
        Baseline: 'api/Baseline/',
        ContImprove: 'api/CountinuesImprovement/'
    },
    Views: {
        PlantOverview: "PlantOverView", PlantEdit: "PlantEdit", VisitOverview: "VisitOverView", VisitEdit: "VisitEdit"
        , HeatVesselSurvey: "Heat_Vessel_Survey", StarchSurvey: "Starch_Survey", GlueLineSurvey: "Glue_Line_Survey", GlueRollSurvey: "Glue_Roll_Survey"
        , ActionEdit: "Action_Edit"
    },
    Surveys: {
        HeatVessel: "Heat Vessel", Starch: "Starch", GlueLine: "GlueLine", 
        GlueRoll_Measurements: "GlueRoll Measurements", GlueRoll_Pictures:"GlueRoll Pictures", 
        IngredientVerification: "Ingredient Verification", Checklist: "Checklist",
        Thermal:"Thermal", ContImprove:"ContImprove"
    },
    Enums: {
        Dir: { Left: 0, Right: 1 },
        PeriodTime: { YTD: 0, QTR: 1, MTH: 2 },
        MachineType: { Error: 0, SF: 1, Web: 2, PreH: 3, GU: 4, HotPlates: 5, Misc: 6, Cut_Off_Knife: 7, 
            Slitter_Scorer: 8, Stackers: 9, Shear_Knife: 10, Triple_Stack:11,Boiler:12,
            Quality_System:13, Starch_Kitchen:14, Training:15, Splicers:16, Rollstands:17 },
        WallType: { Single: 1, Double: 2, Triple: 3},
        Flutes: { A: 0, B: 1, C: 2, D: 3, E: 4, F: 5,L:6,SuperA:7 },
        MeasurementType: { GelTemp: 0, Viscosity: 1, Temperatures: 2 },
        MeasurementLocation: { Mixer: 0, Storage: 1, Doser_SF_1: 2, Doser_SF_2: 3, Doser_DB: 4 },
        PeriodType: { Q1: 0, Q2: 1, Q3: 2, Q4: 3 },
        ActionStatus: { Open: 0, Closed: 1, WIP: 2, Cancelled: 3 },
        StepType: { Water: 0, Starch: 1, Caustic_Percentage: 2, Borax: 4, Starch_Specialty: 6, Liquid_Add: 7, Liquid_Specialty:8, Heat: 10, Error: -1,Mix:11 },
        FormulaUnit: { lbs: 0, oz: 1, kgs: 2, F: 3, C: 4, gal: 5,sec:6 },
        VisitTypes: {Routine:'Routine',Emergency:'Emergency'}
    },
    EmptyGuid:'00000000-0000-0000-0000-000000000000',
    DefaultStarchKitchen:["TVC","Mixer","Caustic","SteamWaterLeak","Borax","LoadCell","StorageTank"],
    AllPlantContactIDs:['c0f37299-5e51-40a9-98eb-a8d17f078f27','1a7bb408-8817-427f-9924-b5209eac9212'],
    BLine: {
        BasisWeight: {'90': 70.4347826086957, '95': 74.3478260869565, '100': 78.2608695652174, '105': 82.1739130434783, '110': 86.0869565217391, '115': 90, '120': 93.9130434782609, '125': 97.8260869565218, '130': 101.739130434783, '135': 105.652173913043, '140': 109.565217391304, '145': 113.478260869565, '150': 117.391304347826, '160': 125.217391304348}
    }
};

export default Constants;