//Modules
import React, { useState } from 'react';
import produce from "immer";
import {  useDispatch } from 'react-redux';
import { Formik, Form, Field, useFormikContext } from 'formik';
//MUI
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
//Actions
import { FormulaCalcSummary, UpdateFormula, DeleteFormula } from '../../actions/FormulaActions';
const FormulaTargetsDialog = props => {
    const dispatch = useDispatch();
    if (!props.formula)
        return null;
    const validate = (values) => {
        const errors = {};
        ['viscosityTarget','temperatureTarget','gelPointTarget'].forEach(n=>{
            if(isNaN(values[n]))
                errors[n] = 'Must be a number';
        });
        ['viscosityVariance','temperatureVariance','gelPointVariance'].forEach(n=>{
            if(!Number.isInteger(Number(values[n])))
                errors[n]="Must be whole number";
        });
        return errors;
    }
   
    const initValues={
        viscosityTarget:props.formula.viscosityTarget, 
        viscosityVariance:props.formula.viscosityVariance,
        temperatureTarget:props.formula.temperatureTarget,
        temperatureVariance:props.formula.temperatureVariance, 
        gelPointTarget:props.formula.gelPointTarget,
        gelPointVariance:props.formula.gelPointVariance
    };
    const handleSubmit=(values)=>{
        const upd = produce(props.formula, u => { 
            u.viscosityTarget=values.viscosityTarget.toString();
            u.viscosityVariance=values.viscosityVariance;
            u.temperatureTarget=values.temperatureTarget.toString();
            u.temperatureVariance=values.temperatureVariance;
            u.gelPointTarget=values.gelPointTarget.toString();
            u.gelPointVariance=values.gelPointVariance;
        });
        dispatch(UpdateFormula(upd, props.formula));
        props.onClose();
    };
    return (
    <Dialog open={props.open} onClose={props.onClose} >
          <Formik initialValues={initValues} onSubmit={handleSubmit} validate={validate}>
                <Form>
        <DialogTitle>Formula Targets</DialogTitle>
        <DialogContent>
            <Stack spacing={2}>
                <div><TargetTextBox name={'viscosityTarget'} label={'Viscosity Target' } />
                    <TargetTextBox name={'viscosityVariance'} label={'+/-' } />
                </div>
                <div> <TargetTextBox name={'temperatureTarget'} label={'Temperature Target'} />
                    <TargetTextBox name={'temperatureVariance'} label={'+/-'} />
                </div>    
                <div><TargetTextBox name={'gelPointTarget'} label={'Gel Point Target'} />
                    <TargetTextBox name={'gelPointVariance'} label={'+/-'} />
                </div>        
            </Stack>
        </DialogContent>
        <DialogActions>
            <Button type="submit">Save</Button>
            <Button onClickCapture={()=>props.onClose()}>Close</Button>
        </DialogActions>
        </Form>
        </Formik>
    </Dialog>);
    
}
const TargetTextBox = props => {
    const { values, handleChange, touched, errors } = useFormikContext();
    return <TextField
    variant="outlined"
    name={props.name}
    type="number"
    InputProps={{
        inputProps: { step: "any" }
    }}
    value={values[props.name]}
    onChange={handleChange}
    error={touched[props.name] && Boolean(errors[props.name])}
    helperText={touched[props.name] && errors[props.name]}
    label={props.label}
/>;
};
export default FormulaTargetsDialog;