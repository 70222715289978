import React, { useState, useEffect }from 'react'
import Constants from '../../constants';
import TextField from '@mui/material/TextField';
import { useFormikContext } from 'formik';
export const DensityWeightFormik = props => {
    //const classes = useStyles();
    //StepType: { Water: 0, Starch: 1, Caustic_Percentage: 2, Caustic_Dry: 3, Borax: 4, Borax_Liquid: 5, Starch_Specialty: 6, Liquid_Specialty: 7, Penetrant: 8, Resin: 9, Heat: 10, Error: -1 },
    //FormulaUnit: { lbs: 0, oz: 1, kgs: 2, F: 3, C: 4, gal: 5 },
    const { values, setFieldValue } = useFormikContext();   
    const [value, setValue] = useState(parseFloat((values["lbsPerGallon"] / 8.34).toFixed(4)));
    useEffect(() => {
        setValue(parseFloat((values["lbsPerGallon"] / 8.34).toFixed(4)));
    }, [ values["lbsPerGallon"]]);
    const handleChange =(e) => {
        const dw = Number(e.target.value);
        if (!isNaN(dw)) {
            setFieldValue('lbsPerGallon', dw * 8.34);
        } else {
            //error handleing?
        }
        setValue(e.target.value);
    };
    let retVal = null;
    if (values['product'] === 'Other' && (values['stepType'] == 2 || values['stepType'] === 4 || values['stepType'] === 7 || values['stepType'] === 8)) {
        retVal = <div>
            <TextField
                name="densityWeight"
                type="number"
                label="Density Weight"
                
                value={value}
                onChange={handleChange }
            />
        </div>;
    }
    return retVal;
}