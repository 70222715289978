import Constants from '../constants';
import produce from "immer";
const constants = Constants;
const initDataState = { online:false, siteAccess:false };
const NetworkState = (state = initDataState, action) => {
    switch (action.type) {
        case constants.Actions.NetworkState.SetOffline:
            return produce(state, d => { d.online = false; });
        case constants.Actions.NetworkState.SetOnline:
            return produce(state, d => { d.online = true });
        case constants.Actions.NetworkState.SetSiteUp:
            return produce(state, d => { d.siteAccess = true; });
        case constants.Actions.NetworkState.SetSiteDown:
            return produce(state, d => { d.siteAccess = false; });
        default:
            return state;
    }
}
export default NetworkState;