import Constants from '../constants';
import produce from "immer";
import { UpdateArray, ChangeOrder, UpdateArrayRange } from '../misc/ArrayFuncs';
const initDataState = [];
const SyncErrors = (state = initDataState, action) => {
    switch (action.type) {
        case Constants.Actions.SyncError_Add:
            return [...state, action.payload];
        case Constants.Actions.SyncError_Add_Rollback:
            const errorInd = state.findIndex(error => error.id === action.meta.newError.id);
            if (errorInd !== -1) {
                return produce(state, pd => { pd.splice(errorInd, 1) });
            } else {
                return state;
            }
        case Constants.Actions.SyncError_Add_Commit:
            return UpdateArray(action.payload?.data, state);
        case Constants.Actions.SyncError_ClearData:
            return [];
        default:
            return state;
    };
}
export default SyncErrors;