import Constants from '../constants';
import produce from "immer";
import { UpdateArray } from '../misc/ArrayFuncs';
const initDataState = [];


//TODO: Normalize Data Maybe?
const OutboundEmail = (state = initDataState, action) => {
    switch (action.type) {
        case Constants.Actions.OutboundEmail.Send:
            return UpdateArray(action.payload, state);

        case Constants.Actions.OutboundEmail.Send_Commit:
            const oeId = state.findIndex(oe => oe.id === action.meta.email.id);
            if (oeId !== -1) {
                return produce(state, pd => { pd.splice(oeId, 1) });
            } else {
                return state;
            }

        case Constants.Actions.OutboundEmail.Send_Rollback:
            //TODO: Something Else than remove
            const remoeId = state.findIndex(oe => oe.id === action.meta.email.id);
            if (remoeId !== -1) {
                return produce(state, pd => { pd.splice(remoeId, 1) });
            } else {
                return state;
            }
        default:
            return state;
    };
}
export default OutboundEmail;