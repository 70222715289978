import Constants from '../../constants';
//Modules
import React from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
//MUI
import Grid from '@mui/material/Grid';
//Compontents
import GlueRoll_MeasurementsEntry from './GlueRoll_MeasurementsEntry';
import GlueRoll_GapView from './GlueRoll_GapView';
import GlueRoll_TIRView from './GlueRoll_TIRView';
import { KeyItemCheckBox } from '../KeyItems/KeyItemCheckBox';
import { DataForm, DataTextBox, DataDate, DataSelect, DataNumber, DataFormContext } from '../FormManager';
//Actions
import { AddGlueRollMeasurement } from '../../actions/GlueRollActions';
const constants = Constants;
const GlueRoll_Measurements = props => {
    const dispatch=useDispatch();
    const grMachine = useSelector(s => s.GlueRollMeasurements.find(m => m.machineID === props.machineID && m.glueRollSurveyID === props.glueRoll.id), shallowEqual);
    if (!props || !props.machineID || props.machineID === constants.EmptyGuid) {
          return <div>Select a Machine</div>;
    }
    if (!grMachine) {
        dispatch(AddGlueRollMeasurement(props.glueRoll.id, props.machineID));
        return <div>Please Wait Creating Glue Roll Measurement Record</div>;
    }
    return <DataForm actionType={constants.Actions.GlueRollMeasurement} data={grMachine}>
        <Grid container>
        <Grid item xs={12}>
            <GlueRoll_MeasurementsEntry machineID={ grMachine.machineID } />
        </Grid>
        <Grid item sm={12} md={6}>
            <GlueRoll_GapView />
        </Grid>
        <Grid item sm={12} md={6}>
            <GlueRoll_TIRView />
        </Grid>
        <Grid item sm={12}>
            <KeyItemCheckBox entity={grMachine} visitID={props.glueRoll.visitID}
                setReference={(entity) => {
                    return entity.machineName;
                }}
                entityType={constants.Surveys.GlueRoll_Measurements} edit={true} tabIndex="-1"
            />
           
        </Grid>
        </Grid>
        </DataForm>;
}
export default GlueRoll_Measurements;