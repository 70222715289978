import Constants from '../../constants';
//Modules
import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useNavigate,useParams } from 'react-router-dom';
//MUI

import ReportViewer from '../Report/ReportViewer';
//Components
import { useReportUser,useReportNameFromPlantID } from '../../misc/UseFuncs';
//Selectors
import { SelVisitByID,SelInventoryByVisitID,SelMeasuresByVisitID,FormatLocation } from '../../selectors/VisitSelectors';
const VisitPrint = props=>{
    const params=useParams();
    const vID= params.VisitID;
    const navigate = useNavigate();
    
    const handleClose = () => navigate(-1);
    const printData=useVisitPrintData(vID);
    return <div>
        <ReportViewer type="Visit" name="Visit" printData={printData} onClose={handleClose} />
       
       
    </div>;
}
export default VisitPrint;
export const useVisitPrintData=(vID)=>{
    const visit = useSelector(s=> SelVisitByID(s,vID));
    const inventory = useSelector(s=>SelInventoryByVisitID(s,vID));
    const measurements = useSelector(s=>SelMeasuresByVisitID(s,vID));
    const UserData = useReportUser();
    
    const dJSON ={ Visit:visit, User:UserData,Inventory:inventory,Measurements:measurements.map(m=>{
        return {...m, locationName:FormatLocation(m.location)};
    })};
    const Data ={ VisitData:JSON.stringify(dJSON)}
    const reportName=useReportNameFromPlantID("Visit",visit.plantID);
    return {Loaded:true,Data:Data,ReportName:reportName,EmailSubject:'Visit'};
}