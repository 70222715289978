import React, { useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { GetMachineTypeName } from './EnumViews';
import { GetPlantLines } from '../../actions/LineActions';
import { GetPlantMachines } from '../../actions/MachineActions';
import { GetPlantPersonnel } from '../../actions/PersonnelActions';
import { createSelector } from 'reselect';
export const useLineOptions = (plantID, addLine, showSelect = true,showStarchKitchen=false) => {
    console.log("useLineOptions Called");
    const lines = useSelector(state => state.Lines.filter(l => l.plantID === plantID && !l.deleted), shallowEqual);
    const dispatch = useDispatch();
    useEffect(() => {
        if (plantID) {
            dispatch(GetPlantLines(plantID));
        }
    }, [plantID]);
    const options = lines.map(l =>
        <option
            key={l.id}
            value={l.id}
        >
            {l.name}
        </option>
    );
    if (showSelect) {
        options.unshift(<option key={'0'} value={'0'}>Select Line</option>);
    }
    if (showStarchKitchen) {
        options.push(<option key={'11111111-1111-1111-1111-111111111111'} value={'11111111-1111-1111-1111-111111111111'}>Starch Kitchen</option>);
    }
    if (addLine) {
        options.push(<option key={'9999'} value={'9999'}>Add New Line</option>)
    }
    return options;
};
export const useMachineOptions = (lineID, showSelect = true) => {
    const line = useSelector(state => state.Lines.find(l => l.id == lineID), shallowEqual);
    const dispatch = useDispatch();
    const plantID = line ? line.plantID : null;
    useEffect(() => {
        if (plantID) {
            dispatch(GetPlantMachines(plantID));
        }
    }, [plantID])
    
    const machines = useSelector(state => state.Machines.filter(m => m.lineID == lineID && !m.deleted), shallowEqual);
    
    const options = machines.map(l =>
        <option
            key={l.id}
            value={l.id}
        >
            {l.name ? l.name :GetMachineTypeName(l.type)}
        </option>
    );
    if (showSelect) {
        options.unshift(<option key={'0'} value={'0'}>Select Machine</option>);
    }
    
    return options;
};
export const usePersonnalOptions = (plantID, showSelect = true, showCCI = false) => {
    const dispatch = useDispatch();
    useEffect(() => {
        if (plantID) {
            dispatch(GetPlantPersonnel(plantID));
        }
    }, [plantID])
    
    const personnel = useSelector(state => state.Personnel.filter(m => m.plantID == plantID && !m.deleted), shallowEqual);
    const options = personnel.map(l =>
        <option
            key={l.id}
            value={l.id}
        >
            {l.name}
        </option>
    );
    if (showSelect) {
        options.unshift(<option key={'0'} value={'0'}>Select Person</option>);
    }
    if (showCCI) {
        options.push(<option key={'11111111-1111-1111-1111-111111111111'} value={'11111111-1111-1111-1111-111111111111'}>CCI</option>);
    }
    return options;
};
export const usePlantIDFromVisitID = (visitID) => {
    //TODO: Dispatch Get Visit from ID?
    const visit = useSelector(state => state.Visits.find(v => v.id === visitID), shallowEqual);
   return visit ? visit.plantID : null;
    
}
const selPersonnel = state => state.Personnel;
const pIDSel = (state, personnelID) => personnelID;
const getPersonFromID = createSelector(selPersonnel, pIDSel,
    (personnel, pID) => {
        return personnel.find(p => p.id === pID);
    }
);
export const PersonnelName = ({ personnelID }) => {
    let person = useSelector(s => getPersonFromID(s, personnelID));
    if (!person && personnelID == '11111111-1111-1111-1111-111111111111')
        person = { name: 'CCI' };
    const name = person ? person.name : '';
    return name 
}