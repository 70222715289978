import Constants from '../constants';
import { v4 as uuidv4 } from 'uuid';
import { FetchErrorLogger, FetchCatchHandler } from '../middlewares';
const constants = Constants;
export const GetKeyItemByEntityID = (entityID) => {  
    return (dispatch, getState) => {
        //offline.online
        const state = getState();
        if (state.offline.online) {
            const errorFilter = response => response.status !== 404;
            let fetchTask = fetch(constants.URLs.KeyItems + entityID, { headers: { 'Authorization': 'Bearer ' + state.Auth.token } })
                .then(FetchErrorLogger("GetKeyItemByEntityID", entityID, dispatch, errorFilter))
                .then(response => response.json())
                .then(keyItem => {
                    //Ignore 404 errors
                    if (keyItem.hasOwnProperty('entityID') && keyItem['entityID'] == entityID) {
                        dispatch({ type: constants.Actions.KeyItems.Recieve_Entity, data: keyItem });
                    }
                })
                .catch(FetchCatchHandler("GetKeyItemByEntityID", entityID, dispatch))
        }
    }
}
export const GetKeyItemByVisitID = (visitID) => {   
    return (dispatch, getState) => {
        //offline.online
        const state = getState();
        if (state.offline.online) {
            let fetchTask = fetch(constants.URLs.KeyItems + "?VisitID=" + visitID, { headers: { 'Authorization': 'Bearer ' + state.Auth.token } })
                .then(FetchErrorLogger("GetKeyItemByVisitID", visitID, dispatch))
                .then(response => response.json())
                .then(keyItems => {
                    //Ignore 404 errors
                    if (Array.isArray(keyItems)) {
                        dispatch({ type: constants.Actions.KeyItems.Recive_Visit, data: keyItems, visitID: visitID });
                    }
                })
                .catch(FetchCatchHandler("GetKeyItemByVisitID", visitID, dispatch))
        }
    }
}
export const GetKeyItemsByPlantID = (plantID) => {
    return (dispatch, getState) => {
        //offline.online
        const state = getState();
        if (state.offline.online) {
            let fetchTask = fetch(constants.URLs.KeyItems + "?PlantID=" + plantID, { headers: { 'Authorization': 'Bearer ' + state.Auth.token } })
                .then(FetchErrorLogger("GetKeyItemsByPlantID", plantID, dispatch))
                .then(response => response.json())
                .then(keyItems => {
                    //Ignore 404 errors
                    if (Array.isArray(keyItems)) {
                        dispatch({ type: constants.Actions.KeyItems.Receive_Plant, data: keyItems, plantID: plantID });
                    }
                })
                .catch(FetchCatchHandler("GetKeyItemsByPlantID", plantID, dispatch))
        }
    }
}
export const AddKeyItem = (newKeyItem) => {
    const constants = Constants;
    
    if (!newKeyItem.type) {
        newKeyItem.type = 'Mechanical';
    }
    return {
        type: constants.Actions.KeyItems.Add,
        payload: newKeyItem,
        meta: {
            offline: {
                effect: {
                    url: constants.URLs.KeyItems, method: 'POST', body: JSON.stringify(newKeyItem), headers: { 'content-type': 'application/json' }
                },
                commit: {
                    type: constants.Actions.KeyItems.Add_Commit, meta: { newKeyItem }
                },
                rollback: {
                    type: constants.Actions.KeyItems.Add_Rollback, meta: { newKeyItem }
                }
            }
        }
    }
};
export const DeleteKeyItem = (delKeyItem) => {
    return {
                type: constants.Actions.KeyItems.Delete,
                payload: delKeyItem,
                meta: {
                    offline: {
                        effect: {
                            url: Constants.URLs.KeyItems + delKeyItem.id, method: 'DELETE'
                        },
                        commit: {
                            type: constants.Actions.KeyItems.Delete_Commit, meta: { delKeyItem }
                        },
                        rollback: {
                            type: constants.Actions.KeyItems.Delete_Rollback, meta: { delKeyItem }
                        }
                    }
                }
            };
}
export const UpdateKeyItem= (updKeyItem, curKeyItem) => {
    console.log('put check __________________________________________________________________________  21');
    const data = { update: updKeyItem, current: curKeyItem }
    return {
        type: constants.Actions.KeyItems.Update,
        payload: data,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.KeyItems + updKeyItem.id, method: 'PUT', body: JSON.stringify(updKeyItem), headers: { 'content-type': 'application/json' }
                },
                commit: {
                    type: constants.Actions.KeyItems.Update_Commit, meta: { data }
                },
                rollback: {
                    type: constants.Actions.KeyItems.Update_Rollback, meta: { data }
                }
            }
        }
    }
};
export const ClearData = () => {
    return { type: constants.Actions.KeyItems.ClearData };
}