import React, { useState } from 'react'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useFormikContext } from 'formik';
export const AutoCompleteFormik = props => {
    //const classes = useStyles();
    //const [inputValue, setInputValue] = React.useState('');
    const { values, 
        setFieldValue
    } = useFormikContext();
    const options = [...new Set(props.options.filter(i=>i[props.name]).map(i => i[props.name]))];
 
    let curVal = values[props.name];
    if (!curVal) {
        curVal = '';
    }
    const [value, setValue] = useState(curVal);
    const [inputValue, setInputValue] = useState('');
    //const acOnChange = (event, newValue) => {
        
    //    const newVal = getOptionsLabelFix(props.options[event.target.value]);
    //    console.log("ACOnChange", newVal);
    //    setFieldValue(props.name, newVal);
    //    setInputValue(newVal);
    //};
    //const acOnBlur = (event, newValue) => {
    //    console.log("AcOnBlur" + event.target.value);
    //    setFieldValue(props.name, event.target.value);
    //};
    console.log("Render AC CurVal:"+curVal+" value:"+value, options);
    return (
        <Autocomplete
            value={value}
            name={props.name}
            freeSolo
            options={options}
            onChange={(event, newValue) => {
                setValue(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            onBlur={(e) => {
                console.log("Formik AC OnBlur", value, "-", inputValue);
                if (value) {
                    setFieldValue(props.name, value)
                } else {
                    setFieldValue(props.name, inputValue)
                }
            }}
            renderInput={(params) => (
                <TextField {...params} label={props.label} variant="outlined" />
            )}
        />   
    );
}