import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
export const GridDateFilter = props => {
    return <FormControl component="fieldset">
        <FormLabel component="legend">{props.column.label}</FormLabel>
     
        <TextField   
            value={props.filterList[props.index][0] || ''}
            
            onChange={(e) => {
                props.filterList[props.index][0] = e.target.value;
                props.onChange(props.filterList[props.index], props.index, props.column)
            }}
            type="date"
            InputLabelProps={{
                shrink: true,
            }}
                />
          
        <TextField
            value={props.filterList[props.index][1] || ''}
            onChange={(e) => {
                props.filterList[props.index][1] = e.target.value;
                props.onChange(props.filterList[props.index], props.index, props.column)
            }}
            type="date"
            InputLabelProps={{
                shrink: true,
            }}
                />
            
    </FormControl>;
};
export const DateFilterLogic = (value, filters, row) => {
    if (Array.isArray(filters) && filters.length > 0) {
        const dateFilter1 = filters[0] || null;
        const dateFilter2 = filters[1] || null;
        const valDate = value.substring(0, 10);
        if (dateFilter1 && dateFilter2) {
            if (valDate >= dateFilter1 && valDate <= dateFilter2) {
                return false;
            } else {
                return true;
            }
        } else if (dateFilter1) {
            //Specific Day Filter
            if (valDate == dateFilter1) {
                return false;
            } else { return true; }
        }
    } else {
        //No Filters Defined
        return false;
    }

};
export const UpdateFilterList = (filterList, filterPos, index) => {
    filterList[index] = [];
    return filterList;
}