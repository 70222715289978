import React, { useState,useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { MachineTypeName, MachineTypeSelectFormik, MachineTypeSelect } from '../MetaData/EnumViews';
const MachineAddDialog = props => {
    const getNumMachine = (mArray, type) => mArray.reduce((acc, val) => {
        if (val.type === type) {
            return acc + 1;
        } else {
            return acc;
        }
    }, 0);
    const [mType, setType] = useState(-1);
    const [nameError, setNameError] = useState({ name: '', error: null });
    useEffect(() => {
        setType(-1);
        setNameError({ name: '', error: null });
    }, [props.modalOpen]);
    const typeChange = (e) => {
        const newType = Number(e.target.value);
        setType(newType);

        switch (newType) {
            case 1: //Single Facer
                const maxSF = getNumMachine(props.machines, 1);
                if (maxSF < 3) {
                    const loc = (maxSF + 1).toString();
                    setNameError({ name: "SF" + loc, error: null });

                } else {
                    //TODO: Fix Error Notication
                    setNameError({ name: "", error: "Max Number of Single Facers Added" });
                }
                break;
            case 2: //Web
                const maxWeb = getNumMachine(props.machines, 2)
                if (maxWeb < 3) {
                    const loc = (maxWeb + 1).toString();
                    setNameError({ name: "Web" + loc, error: null });
                } else {
                    //TODO: Fix Error Notication
                    setNameError({ name: "", error: "Max Number of Web Added" });
                }
                break;
            case 3: //PreHeater
                const maxPH = getNumMachine(props.machines, 3);
                if (maxPH < 4) {
                    const loc = (maxPH + 1).toString();
                    setNameError({ name: "PreH" + loc, error: null });
                } else {
                    setNameError({ name: "", error: "Max Number of Pre Heaters Added" });
                }
                break;
            case 4: //Glue Unit
                const maxGU = getNumMachine(props.machines, 4);
                let loc = '';
                if (maxGU < 3) {
                    if (maxGU === 0) { loc = 'T'; }
                    if (maxGU === 1) { loc = 'B'; }
                    if (maxGU === 2) { loc = 'M'; }
                    setNameError({ name: "GU" + loc, error: null });
                } else {
                    setNameError({ name: "", error: "Max Number of Glue Units Added" });
                }
                break;
            case 5: //HotPlates
                if (props.machines.some((val) => val.type === newType)) {
                    //Hot Plates Exist
                    setNameError({ name: "", error: "HotPlates Already Exist" });
                } else {
                    setNameError({ name: "HotPlates", error: null });
                }
                break;
            case 6: //Misc
                const maxM = getNumMachine(props.machines, 6);
                if (maxM < 9) {
                    const loc = (maxM + 1).toString();
                    setNameError({ name: "Misc" + loc, error: null });
                } else {
                    setNameError({ name: "", error: "Max Number of Misc Added" });
                }
                break;
            case 7://Cut_Off_Knife
                setNameError({ name: 'Cut Off Knife', error: null });
                break;
            case 8: //Slitter_Scorer
                setNameError({ name: 'Slitter Scorer', error: null });
                break;
            case 9: //Stackers
                setNameError({ name: 'Stackers', error: null });
                break;
            case 10://Shear_Knife
                setNameError({ name: 'Shear Knife', error: null });
                break;
            case 11://Triple_Stack
                setNameError({ name: 'Triple Stack', error: null });
                break;
            case 12: //Boiler
                const maxB = getNumMachine(props.machines, 12);
                if(maxB>1){
                    setNameError({name:null,error:'Already Two Boilers'});
                } else {
                    setNameError({ name: 'Boiler', error: null });
                }
                break;
            case 13://QS
                const  maxQS= getNumMachine(props.machines, 13);
                if(maxQS>0){
                    setNameError({name:null,error:'Only one Quality System'});
                } else {
                    setNameError({ name: 'Quality System', error: null });
                }
            break;
            case 14://SK
                const  maxSK= getNumMachine(props.machines, 14);
                if(maxSK>0){
                    setNameError({name:null,error:'Only one Starch Kitchen'});
                } else {
                    setNameError({ name: 'Starch Kitchen', error: null });
                }
            break;
            case 15://SK
                const  maxT= getNumMachine(props.machines, 15);
                if(maxT>0){
                    setNameError({name:null,error:'Only one Training'});
                } else {
                    setNameError({ name: 'Training', error: null });
                }
            break;
            case 16://SK
                const  maxSp= getNumMachine(props.machines, 16);
                if(maxSp>0){
                    setNameError({name:null,error:'Only one Splicers'});
                } else {
                    setNameError({ name: 'Splicers', error: null });
                }
            break;
            case 17://Rollstands 
                const  maxR= getNumMachine(props.machines, 17);
                if(maxR>0){
                    setNameError({name:null,error:'Only one Rollstands'});
                } else {
                    setNameError({ name: 'Rollstands', error: null });
                }
            break;
            default:
                return; //Ignore 
        }
    };
    const handleSubmit = () => {
        if (mType != 0 && !nameError.error) {
            props.onSumbit({ type: mType, name: nameError.name });
            setType(-1);
            setNameError({ name: "", error: null });
        }
    }
    return (
        <Dialog open={props.modalOpen} onClose={props.closeModal} >
            <DialogTitle >Add Machine</DialogTitle >
            <DialogContent>
                <div>
                    <MachineTypeSelect name="type" label="Type" value={mType} onChange={typeChange} error={nameError.error} />
                </div>
                <div>
                    <TextField
                        id={'machineNameTB'}
                        label={'Name'}
                        value={nameError.name}
                        onChange={(e) => setNameError({ name: e.target.value, error: nameError.error })}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button type="submit" onClick={handleSubmit}>Add</Button>
                <Button onClick={props.closeModal}>Cancel</Button>
            </DialogActions>
        </Dialog >
    );
}
export default MachineAddDialog;