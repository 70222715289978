//FormulaUnit: { lbs: 0, oz: 1, kgs: 2, F: 3, C: 4, gal: 5,sec:6 }
export const IVStepToLbs = (step) => {
    switch (step.unit) {
        case 0:
            return step.acutalWeight;
        case 1:
            return step.acutalWeight / 16;
        case 2:
            return step.acutalWeight * 2.20462;
        case 3:
        case 4:
            return step.acutalWeight;
        case 5:
            return step.acutalWeight * step.lbsPerGallon;
        case 6:
            return step.acutalWeight;
        default:
            return step.acutalWeight;
    }
}