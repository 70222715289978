import { createSelector } from 'reselect';
export const SelChecklists= state=>state.Checklist.filter(cl=>!cl.deleted);
export const SelChecklistGroups=state=>state.ChecklistGroup.filter(clg=>!clg.deleted);
export const SelChecklistItems = state=>state.ChecklistItem.filter(cli=>!cli.deleted);
export const SelChecklistByID=createSelector(SelChecklists,(_,clID)=>clID,
    (checklists,clID)=>{
        return checklists.find(cl=>cl.id===clID);
    }
);
export const SelChecklistGroupsByCLID=createSelector(SelChecklistGroups,(_,clID)=>clID,
    (groups, clID) => {
        return groups.filter((element, index, array)=>{
            return element.checklistID===clID;
        }).sort((a, b) => a.order - b.order);
    }
);
export const SelChecklistItemsByCLID=createSelector(SelChecklistItems,(_,clID)=>clID,
    (items,clID)=>{
        return items.filter(i=>i.checklistID===clID).sort((a, b) => a.order - b.order);
    }
);