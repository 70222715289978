import Constants from '../../constants';
import React, { useEffect, useState } from 'react';
//MUI
const constants=Constants;
export const getCIValue = (DetailsObj, plant, state) =>  {
    const BaselineData = GetBaseLineData(DetailsObj, state);
    const wasteData = GetWasteData(DetailsObj, state);
    const TrainingData = GetTrainingData(DetailsObj, state);
    const DowntimeData = GetDownShiftData(DetailsObj, state);
    const ConsumptionData = GetConsumption(DetailsObj, state);
    const ProductionData = GetProduction(DetailsObj, state);
    const FoorterData = GetFooterData(DetailsObj, wasteData, TrainingData, DowntimeData, ConsumptionData, ProductionData, plant, state);
    let CiData = {Baseline:BaselineData, Waste:wasteData, Training:TrainingData, Downtime:DowntimeData, Consumption:ConsumptionData, Production:ProductionData, FooterObject:FoorterData}

    return CiData

}
const formatnum = (num) => {
    let value = parseFloat(num)
    let formatted = Math.round(value);
    return parseFloat(formatted).toLocaleString();
}
const Tformatnum = (number) => {
  if (isNaN(number) || number === null) {
    return "Invalid Number";
}
if (Number.isInteger(number)) {
    let formattedNumber = number.toString()
            formattedNumber = formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return formattedNumber;
} else {
    let formattedNumber = Number(number).toFixed(2);
    formattedNumber = formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return formattedNumber;
}
}
function GetBaselineDetail(BaselineId, state) {
    const plant = state.Baselines.find(p => p.id === BaselineId)
    return plant??{};
}


function GetTrainingData(DetailsObj,state) {
    let TrainObj = {
        IsTrainingVisible: GetCheckIsSelected('hoursOfTraining', DetailsObj),
        HourOfTraning: 0,
        DollarHour: 250,
        TotalCostSaved: 0,
        TotalCostSavedValue: 0,
        TimeTitle: ''
    }
    if (DetailsObj.hoursOfTraining > 0 ) {
        TrainObj.HourOfTraning = Tformatnum(DetailsObj.hoursOfTraining)
        TrainObj.TotalCostSaved = Tformatnum(TrainObj.DollarHour * TrainObj.HourOfTraning)
        TrainObj.TotalCostSavedValue = Tformatnum(TrainObj.DollarHour * TrainObj.HourOfTraning)
        TrainObj.TimeTitle = formatDate(DetailsObj.startDate, DetailsObj.enddate, DetailsObj.periodValues, DetailsObj.selectedRadioValue)
    }
    return TrainObj
}
function GetDownShiftData(DetailsObj,state) {
    let TrainObj = {
        IsShowDowntime: GetCheckIsSelected('hoursOfDownTime', DetailsObj),
        HoursDTSaved : 0,
		TotalLineal: 0,
		TotalSqFt: 0,
		MsgDay: 0,
		LbsDay: 0,
		DollarPerSaved: 0,
        TimeTitle: ''
    }
    if (DetailsObj.hoursOfDownTime > 0 ) {
        const selectedBaseline = GetBaselineDetail(DetailsObj?.baselineID,state);
        let avgBgCost = constants.BLine.BasisWeight[selectedBaseline.baseGrade]
        let costMSFWperPP = ((selectedBaseline.preprint / 100 * (avgBgCost * 2.5)) + (((1 - selectedBaseline.preprint / 100) * avgBgCost) * selectedBaseline.costRate))
        TrainObj.HoursDTSaved = DetailsObj.hoursOfDownTime
        TrainObj.TotalLineal = DetailsObj.hoursOfDownTime * selectedBaseline.avgSpeed
        TrainObj.TotalSqFt = TrainObj.TotalLineal * (selectedBaseline.avgWidth/12) 
        TrainObj.MsgDay = TrainObj.TotalSqFt / 1000
        TrainObj.LbsDay = (TrainObj.TotalSqFt / 1000) * selectedBaseline.baseGrade
        TrainObj.DollarPerSaved = ((TrainObj.TotalSqFt / 1000) * costMSFWperPP)
        TrainObj.TimeTitle = formatDate(DetailsObj.startDate, DetailsObj.enddate, DetailsObj.periodValues, DetailsObj.selectedRadioValue)
    }
    let data = {
        IsShowDowntime: TrainObj.IsShowDowntime,
        TimeTitle: TrainObj.TimeTitle,
        HoursDTSaved: Tformatnum(TrainObj.HoursDTSaved),
        TotalLineal: formatnum(TrainObj.TotalLineal),
        TotalSqFt: formatnum(TrainObj.TotalSqFt),
        MsgDay: formatnum(TrainObj.MsgDay),
        LbsDay: formatnum(TrainObj.LbsDay),
        DollarPerSaved: Tformatnum(TrainObj.DollarPerSaved),
        DollarPerSavedValue: TrainObj.DollarPerSaved,
    }
    return data
}
function GetFooterData(DetailsObj, wasteData, TrainingData, DowntimeData, ConsumptionData, ProductionData, plantDetail, state) {
    let Object = {
        TotalSavings: 0,
        Waste: 0,
        Production: 0,  
        Consumption: 0,
        Downtime: 0,  
        Training: 0,
        GrandTotal: 0,
        SupplierName: 'Corrugated Chemicals Inc.',
        TimeTitle: '',
        FormatedDate: formatDate(DetailsObj.startDate, DetailsObj.enddate, DetailsObj.periodValues, DetailsObj.selectedRadioValue),
        SelectedRadio: DetailsObj.selectedRadioValue,
        ProjectSummary: "",
        IsShowProjectSummary: false,
        SavedBy: DetailsObj.signatureCaptureBy,
        SignatureTitle: '',
        UserSignatureTitle: DetailsObj.signatureCaptureBy,
        UserSignatureBASE64: DetailsObj.signatureCapture,
        UserSignatureTime: DetailsObj.userSignatureCaptureTime,
        GMSRepresentSignatureUserName: DetailsObj.gmRepDisplayUserName,
        GMSRepresentSignatureTitle: plantDetail.name,
        GMSRepresentSignatureBASE64: DetailsObj.gmSignatureData,
        GMSRepresentSignatureTime: DetailsObj.gmSignatureCaptureTime,
        isShowLeftSign: true,
        isShowRightSign: true,
        PositionName: '----',
        startTime: DetailsObj.startDate,
        endTime: DetailsObj.enddate
    }
    let start = new Date(DetailsObj.startDate);
    let end = new Date(DetailsObj.enddate);
    let startMonth = start.toLocaleString('default', { month: 'long' });
    let endMonth = end.toLocaleString('default', { month: 'long' });
    let formattedDate;
    if (startMonth === endMonth) {
        if (start.getFullYear() === end.getFullYear()) {
            formattedDate = `${startMonth} ${start.getDate()}-${end.getDate()}, ${start.getFullYear()}`;
        } else {
            formattedDate = `${startMonth} ${start.getDate()} ${start.getFullYear()}, ${endMonth} ${end.getDate()} ${end.getFullYear()}`;
        }
    } else {
        formattedDate = `${startMonth} ${start.getDate()} - ${endMonth} ${end.getDate()}, ${start.getFullYear()}`;
    }
    if (DetailsObj.projectSummary !== '' && DetailsObj.projectSummary !== null) {
        Object.IsShowProjectSummary = true
        let formattedData = JSON.parse(DetailsObj.projectSummary).map(item => `<span>• ${item}</span>`).join('<br>'); // <br> tag add kiya gaya hai taki har summary ek alag line par ho
        Object.ProjectSummary = formattedData;
    } else {
        Object.IsShowProjectSummary = false
    }
    Object.TimeTitle = formatDate(DetailsObj.startDate, DetailsObj.enddate, DetailsObj.periodValues, DetailsObj.selectedRadioValue)
        
    Object.Waste = wasteData.WasteSavingValue
    Object.Training = TrainingData.TotalCostSavedValue
    Object.Downtime = DowntimeData.DollarPerSavedValue
    Object.Production = ProductionData.MainCount
    Object.Consumption = ConsumptionData.TotalMainCountValue

    let total = 0
    if (wasteData.IsWasteVisible) {
        total += DetailsObj.totalWasteSavings
    }
    if (ProductionData.IsProductionVisible) {
        total += DetailsObj.totalProductionSaving
    }
    if (ConsumptionData.IsConsumptionVisible) {
        total += DetailsObj.totalConsumptionSavings
    }
    if (TrainingData.IsTrainingVisible) {
        total += DetailsObj.totalTrainingCostSaved
    }
    if (DowntimeData.IsShowDowntime) {
        total += DetailsObj.totalDownTimeSaved
    }
    Object.GrandTotal = total
    if (Object.GrandTotal >= 100000) {
        Object.SignatureTitle = plantDetail.name + ' GM / Date'
    } else {
        Object.SignatureTitle = plantDetail.name + ' / Date'
    }
    const plantDet = plantDetail
    if (DetailsObj.createdByID !== "00000000-0000-0000-0000-000000000000") {
        if (plantDet.usrareamanagerId === DetailsObj.createdByID) {
            Object.PositionName = 'Regional Manager'
        } else if (plantDet.usrprimarytechId === DetailsObj.createdByID) {
            Object.PositionName = 'Service Lead'
        } else if (plantDet.usrsecondarytechId === DetailsObj.createdByID) {
            Object.PositionName = 'Secondary Tech'
        } else if (plantDet.usrtertiarytechId === DetailsObj.createdByID) {
            Object.PositionName = 'Tertiary Tech'
        } else if (plantDet.ownerId === DetailsObj.createdByID) {
            Object.PositionName = 'Owner'
        }
    } else {
        if (plantDet.usrareamanagerId === DetailsObj.updatedByID) {
            Object.PositionName = 'Regional Manager'
        } else if (plantDet.usrprimarytechId === DetailsObj.updatedByID) {
            Object.PositionName = 'Service Lead'
        } else if (plantDet.usrsecondarytechId === DetailsObj.updatedByID) {
            Object.PositionName = 'Secondary Tech'
        } else if (plantDet.usrtertiarytechId === DetailsObj.updatedByID) {
            Object.PositionName = 'Tertiary Tech'
        } else if (plantDet.ownerId === DetailsObj.updatedByID) {
            Object.PositionName = 'Owner'
        }
    }
    if (Object.UserSignatureBASE64 === '') {
        Object.UserSignatureTime = ''
    }
    if (Object.GMSRepresentSignatureBASE64 === '') {
        Object.GMSRepresentSignatureTime = ''
    }
    if (DetailsObj.gmRepDisplayUserName === '') {
        Object.GMSRepresentSignatureUserName = '_________________________________'
    }

    return Object
}
function GetProduction(DetailsObj,state) {
    let ProObj = {
        IsProductionVisible: GetCheckIsSelected('production', DetailsObj),
        BaselineLFPH : 0,
        BaselineTotalLineal: 0,
        BaselineTotalsqFt: 0,
        BaselineMsfHour: 0,
        BaselineLbsHour: 0,
        BaselineDollarHour: 0,
        NewLinealperHour: 0,
        NlphTotalLineal: 0,
        NlphTotalsqFt: 0,
        NlphMsfHour: 0,
        NlphLbsHour: 0,
        NlphDollarHour: 0,
        MoreSalesHR: 0,
		IncreaseMsfHR: 0,
        MSFImproved: 0,
        TotalSalesIncrease: 0,
        CostReductionProfit: 0,
        TotalSaving: 0,
        valueCounting:'',
        TimeTitle: '',
        RadioValue: '',
        MainCount: '',
    }
    if (DetailsObj.proNewLinealPerHr > 0 && DetailsObj.proTotalCount > 0) {
        const selectedBaseline = GetBaselineDetail(DetailsObj?.baselineID,state);
        let avgBgCost = constants.BLine.BasisWeight[selectedBaseline.baseGrade]
        let costMSFWperPP = ((selectedBaseline.preprint / 100 * (avgBgCost * 2.5)) + (((1 - selectedBaseline.preprint / 100) * avgBgCost) * selectedBaseline.costRate))
        ProObj.BaselineLFPH = selectedBaseline.avgSpeed
        ProObj.BaselineTotalLineal = selectedBaseline.avgSpeed * 24
        ProObj.BaselineTotalsqFt =  ProObj.BaselineTotalLineal * (selectedBaseline.avgWidth / 12)
        ProObj.BaselineMsfHour =  ProObj.BaselineTotalsqFt / 24 / 1000
        ProObj.BaselineLbsHour =  (ProObj.BaselineTotalsqFt / 24 / 1000) * selectedBaseline.baseGrade
        ProObj.BaselineDollarHour =  ((ProObj.BaselineTotalsqFt / 24 / 1000) * costMSFWperPP)
        ProObj.NewLinealperHour =  DetailsObj.proNewLinealPerHr
        ProObj.NlphTotalLineal =  DetailsObj.proNewLinealPerHr * 24
        ProObj.NlphTotalsqFt =  ProObj.NlphTotalLineal * (selectedBaseline.avgWidth / 12)
        ProObj.NlphMsfHour =  ProObj.NlphTotalsqFt / 24 / 1000
        ProObj.NlphLbsHour =  (ProObj.NlphTotalsqFt / 24 / 1000) * selectedBaseline.baseGrade
        ProObj.NlphDollarHour =  ((ProObj.NlphTotalsqFt / 24 / 1000) * costMSFWperPP)
        ProObj.IncreaseMsfHR = ProObj.NlphMsfHour - ProObj.BaselineMsfHour
        ProObj.MoreSalesHR = (ProObj.NlphDollarHour - ProObj.BaselineDollarHour)
        let MsfImproved = ((ProObj.NlphTotalsqFt / 24 / 1000) - (ProObj.BaselineTotalsqFt / 24 / 1000)) * (selectedBaseline.hoursPerShift * selectedBaseline.shiftPerDay)
        let TotalSalesInc = MsfImproved * costMSFWperPP
        if (DetailsObj.selectedRadioValue === 'day') {
            ProObj.MSFImproved = MsfImproved
            ProObj.TotalSalesIncrease = MsfImproved * costMSFWperPP
            ProObj.CostReductionProfit = (MsfImproved * costMSFWperPP) * 0.3
            ProObj.TotalSaving = ((TotalSalesInc * 0.3) * DetailsObj.proTotalCount)
            ProObj.MainCount = ((TotalSalesInc * 0.3) * DetailsObj.proTotalCount)
        } else if (DetailsObj.selectedRadioValue === 'week') {
            ProObj.MSFImproved = MsfImproved * selectedBaseline.daysPerWeek
            ProObj.TotalSalesIncrease = (MsfImproved * selectedBaseline.daysPerWeek) * costMSFWperPP
            ProObj.CostReductionProfit = (MsfImproved * selectedBaseline.daysPerWeek) * costMSFWperPP * 0.3
            ProObj.TotalSaving = ((((MsfImproved * selectedBaseline.daysPerWeek) * costMSFWperPP) * 0.3) * DetailsObj.proTotalCount)
            ProObj.MainCount = ((((MsfImproved * selectedBaseline.daysPerWeek) * costMSFWperPP) * 0.3) * DetailsObj.proTotalCount)
        } else if (DetailsObj.selectedRadioValue === 'month') {
            ProObj.MSFImproved = MsfImproved * selectedBaseline.daysPerWeek * 4.25
            ProObj.TotalSalesIncrease = (MsfImproved * selectedBaseline.daysPerWeek * 4.25) * costMSFWperPP
            ProObj.CostReductionProfit = (MsfImproved * selectedBaseline.daysPerWeek * 4.25) * costMSFWperPP * 0.3
            ProObj.TotalSaving = ((((MsfImproved * selectedBaseline.daysPerWeek * 4.25) * costMSFWperPP) * 0.3) * DetailsObj.proTotalCount)
            ProObj.MainCount = ((((MsfImproved * selectedBaseline.daysPerWeek * 4.25) * costMSFWperPP) * 0.3) * DetailsObj.proTotalCount)
        }
        ProObj.TimeTitle = formatDate(DetailsObj.startDate, DetailsObj.enddate, DetailsObj.periodValues, DetailsObj.selectedRadioValue)
        ProObj.RadioValue = DetailsObj.selectedRadioValue
        ProObj.valueCounting = DetailsObj.periodValues
    }

    let data = {
        IsProductionVisible: ProObj.IsProductionVisible,
        TimeTitle: ProObj.TimeTitle,
        valueCounting: ProObj.valueCounting,
        RadioValue: ProObj.RadioValue,
        MainCount: ProObj.MainCount,
        BaselineLFPH: formatnum(ProObj.BaselineLFPH),
        BaselineTotalLineal: formatnum(ProObj.BaselineTotalLineal),
        BaselineTotalsqFt: formatnum(ProObj.BaselineTotalsqFt),
        BaselineMsfHour: formatnum(ProObj.BaselineMsfHour),
        BaselineLbsHour: formatnum(ProObj.BaselineLbsHour),
        BaselineDollarHour: Tformatnum(ProObj.BaselineDollarHour),
        NewLinealperHour: formatnum(ProObj.NewLinealperHour),
        NlphTotalLineal: formatnum(ProObj.NlphTotalLineal),
        NlphTotalsqFt: formatnum(ProObj.NlphTotalsqFt),
        NlphMsfHour: formatnum(ProObj.NlphMsfHour),
        NlphLbsHour: formatnum(ProObj.NlphLbsHour),
        NlphDollarHour: Tformatnum(ProObj.NlphDollarHour),
        MoreSalesHR: Tformatnum(ProObj.MoreSalesHR),
        IncreaseMsfHR: formatnum(ProObj.IncreaseMsfHR),
        MSFImproved: formatnum(ProObj.MSFImproved),
        TotalSalesIncrease: formatnum(ProObj.TotalSalesIncrease),
        CostReductionProfit: formatnum(ProObj.CostReductionProfit),
        TotalSaving: Tformatnum(ProObj.TotalSaving),
    }

    return data
}
function GetConsumption(DetailsObj,state) {
    let CompObj = {
        IsConsumptionVisible: GetCheckIsSelected('consumption', DetailsObj),
        BaselineLbMsf: 0,
        NewLbMsf: 0,
        DifferenceLbMsf: 0,
        CostLbLbMsf: 0,
        StarchLbUsage: 0,
        NewStarchLbUsage: 0,
        StarchLbSavings: 0,
        TotalSavings: 0,
        TotalMainCount: 0,
        TimeTitle: '',
        RadioValue: '',
        valueCounting:'',
    }
    if (DetailsObj.consLbPerMsf > 0 && DetailsObj.consCostLb > 0 && DetailsObj.consTotalCount > 0) {
        const selectedBaseline = GetBaselineDetail(DetailsObj?.baselineID,state);
        let StarchIbUsage = selectedBaseline.consumptionBaseline * (selectedBaseline.avgSpeed * selectedBaseline.avgWidth / 12 / 1000) * (selectedBaseline.shiftPerDay * selectedBaseline.hoursPerShift)
        let NewStarchIbUsage = (DetailsObj.consLbPerMsf * (selectedBaseline.avgSpeed * selectedBaseline.avgWidth / 12 / 1000)) * (selectedBaseline.shiftPerDay * selectedBaseline.hoursPerShift)
        let msfProducedMonth = selectedBaseline.daysPerWeek * 4.25
        let msfProducedWeek = selectedBaseline.daysPerWeek
        let TotalConsumptionSaving = 0
        CompObj.BaselineLbMsf = selectedBaseline.consumptionBaseline
        CompObj.NewLbMsf = DetailsObj.consLbPerMsf
        CompObj.DifferenceLbMsf = (selectedBaseline.consumptionBaseline - DetailsObj.consLbPerMsf)
        CompObj.CostLbLbMsf = DetailsObj.consCostLb
        CompObj.TotalMainCount = 0
        if (DetailsObj.selectedRadioValue === 'day') {
            CompObj.StarchLbUsage = StarchIbUsage
            CompObj.NewStarchLbUsage = NewStarchIbUsage
            CompObj.StarchLbSavings = StarchIbUsage - NewStarchIbUsage
            CompObj.TotalSavings = (DetailsObj.consCostLb *  (StarchIbUsage - NewStarchIbUsage))
            CompObj.TotalMainCount = (DetailsObj.consCostLb *  (StarchIbUsage - NewStarchIbUsage)) * DetailsObj.consTotalCount

        } else if (DetailsObj.selectedRadioValue === 'week') {
            CompObj.StarchLbUsage = StarchIbUsage * msfProducedWeek
            CompObj.NewStarchLbUsage = NewStarchIbUsage * msfProducedWeek
            CompObj.StarchLbSavings = (StarchIbUsage * msfProducedWeek) - (NewStarchIbUsage * msfProducedWeek)
            let a = (StarchIbUsage * msfProducedWeek) - (NewStarchIbUsage * msfProducedWeek)
            CompObj.TotalSavings = (DetailsObj.consCostLb * CompObj.StarchLbSavings)
            CompObj.TotalMainCount = ((DetailsObj.consCostLb * a) * DetailsObj.consTotalCount)
        } else if (DetailsObj.selectedRadioValue === 'month') {
            CompObj.StarchLbUsage = StarchIbUsage * msfProducedMonth
            CompObj.NewStarchLbUsage = NewStarchIbUsage * msfProducedMonth
            CompObj.StarchLbSavings = (StarchIbUsage * msfProducedMonth) - (NewStarchIbUsage * msfProducedMonth)
            CompObj.TotalSavings = (DetailsObj.consCostLb * CompObj.StarchLbSavings)
            CompObj.TotalMainCount = (DetailsObj.consCostLb *  ((StarchIbUsage * msfProducedMonth) - (NewStarchIbUsage * msfProducedMonth))) * DetailsObj.consTotalCount
        }
        CompObj.TimeTitle = formatDate(DetailsObj.startDate, DetailsObj.enddate, DetailsObj.periodValues, DetailsObj.selectedRadioValue)
        CompObj.RadioValue = DetailsObj.selectedRadioValue
        CompObj.valueCounting = DetailsObj.periodValues
    }
    let data = {
        IsConsumptionVisible: CompObj.IsConsumptionVisible,
        TimeTitle: CompObj.TimeTitle,
        RadioValue: CompObj.RadioValue,
        valueCounting: CompObj.valueCounting,

        BaselineLbMsf: Tformatnum(CompObj.BaselineLbMsf),
        NewLbMsf: Tformatnum(CompObj.NewLbMsf),
        DifferenceLbMsf: Tformatnum(CompObj.DifferenceLbMsf),
        CostLbLbMsf: Tformatnum(CompObj.CostLbLbMsf),
        StarchLbUsage: formatnum(CompObj.StarchLbUsage),
        NewStarchLbUsage: formatnum(CompObj.NewStarchLbUsage),
        StarchLbSavings: formatnum(CompObj.StarchLbSavings),
        TotalSavings: Tformatnum(CompObj.TotalSavings),
        TotalMainCount: Tformatnum(CompObj.TotalMainCount),
        TotalMainCountValue: CompObj.TotalMainCount,
        
    }
    return data
}
function GetBaseLineData(DetailsObj,state) {
    const selectedBaseline = GetBaselineDetail(DetailsObj?.baselineID,state);
    let baselineData = {
        BaselineTabTitle  : 'Plant Production Info Baseline' + ` ${ selectedBaseline.baselineStartDate !== '' ? ': (From ' + selectedBaseline.baselineStartDate + ' to ' + selectedBaseline.baselineEndDate + ')' : ''}`,
        ID: selectedBaseline.id,
        PlantID: selectedBaseline.plantID,
        BaselineName: selectedBaseline.baselineName,
        AvgSpeed: selectedBaseline.avgSpeed,
        DaysPerWeek: selectedBaseline.daysPerWeek,
        ShiftPerDay: selectedBaseline.shiftPerDay,
        HoursPerShift: selectedBaseline.hoursPerShift,
        NoOfMonth: selectedBaseline.noOfMonth,
        AvgWidth: selectedBaseline.avgWidth,
        ActualPrcWaste: Tformatnum(selectedBaseline.actualPrcWaste),
        Preprint: selectedBaseline.preprint,
        BaseGrade: selectedBaseline.baseGrade,
        CostRate: selectedBaseline.costRate,
        DayPerMonth: '',
        MSFPerHour: '',
        DayPerYear: '',
        AvgBgCost: '',
        CostMSFWPP: '',
        TotalHours: '',
        CountMargin: '',
        SalesPrice: 120,
        ConsumptionBaseline: selectedBaseline.consumptionBaseline,
        Deleted: selectedBaseline.deleted,
        CreatedDate: selectedBaseline.createdDate,
        LastSyncDate: selectedBaseline.lastSyncDate,
        CreatedByID: selectedBaseline.createdByID,
        CreatedBy: selectedBaseline.createdBy,
        UpdatedByID: selectedBaseline.updatedByID,
        UpdatedBy: selectedBaseline.updatedBy,
    }
    let avgBgCost = constants.BLine.BasisWeight[selectedBaseline.baseGrade]
    baselineData.DayPerMonth = selectedBaseline.daysPerWeek * 4.25
    
    baselineData.MSFPerHour = formatnum((selectedBaseline.avgSpeed * selectedBaseline.avgWidth / 12 / 1000))
    baselineData.DayPerYear = (selectedBaseline.daysPerWeek * 4.25) * 12
    baselineData.AvgBgCost = Math.floor(avgBgCost)
    baselineData.CostMSFWPP = ((selectedBaseline.preprint / 100 * (avgBgCost * 2.5)) + (((1 - selectedBaseline.preprint / 100) * avgBgCost) * selectedBaseline.costRate)).toFixed(2)
    baselineData.TotalHours = selectedBaseline.avgSpeed * selectedBaseline.daysPerWeek * selectedBaseline.shiftPerDay
    baselineData.CountMargin = 120 - baselineData.CostMSFWPP
    return baselineData;
}
function GetWasteData(DetailsObj,state) {
    let WasteObj = {
        IsWasteVisible: GetCheckIsSelected('waste', DetailsObj),
        ActualWaste: 0,
        NewWaste: 0,
        MSFProduced: 0,
        WasteReduction: 0,
        TotalCostReduction: 0,
        WasteSaving: 0,
        valueCounting: 0,
        TimeTitle: '',
        RadioValue: ''
    }
    const selectedBaseline = GetBaselineDetail(DetailsObj?.baselineID,state);
    if (DetailsObj.newPerWaste > 0 && DetailsObj.wasteTotalCount > 0) {
        WasteObj.ActualWaste = selectedBaseline.actualPrcWaste
        WasteObj.RadioValue = DetailsObj.selectedRadioValue
        WasteObj.valueCounting = DetailsObj.periodValues
        WasteObj.NewWaste = DetailsObj.newPerWaste
        let westReduction = selectedBaseline.actualPrcWaste - DetailsObj.newPerWaste
        let avgBgCost = constants.BLine.BasisWeight[selectedBaseline.baseGrade]
        let costMSFWperPP = ((selectedBaseline.preprint / 100 * (avgBgCost * 2.5)) + (((1 - selectedBaseline.preprint / 100) * avgBgCost) * selectedBaseline.costRate))
        let msf = selectedBaseline.shiftPerDay * selectedBaseline.hoursPerShift * (selectedBaseline.avgSpeed * selectedBaseline.avgWidth / 12 / 1000)
        if (DetailsObj.selectedRadioValue === 'day') {
            WasteObj.MSFProduced = msf
            WasteObj.NewWaste = DetailsObj.newPerWaste
            WasteObj.WasteReduction = westReduction
            WasteObj.TotalCostReduction = (westReduction / 100 * WasteObj.MSFProduced * costMSFWperPP)
            WasteObj.WasteSaving = (DetailsObj.totalWasteSavings)
            
        } else if (DetailsObj.selectedRadioValue === 'week') {
            WasteObj.MSFProduced = msf * selectedBaseline.daysPerWeek
            WasteObj.NewWaste = DetailsObj.newPerWaste
            WasteObj.WasteReduction = (selectedBaseline.actualPrcWaste - WasteObj.NewWaste)
            WasteObj.TotalCostReduction = (WasteObj.WasteReduction / 100 * WasteObj.MSFProduced * costMSFWperPP)
            WasteObj.WasteSaving = (DetailsObj.totalWasteSavings)
        } else if (DetailsObj.selectedRadioValue === 'month') {
            WasteObj.MSFProduced = ((selectedBaseline.daysPerWeek * 4.25) * msf)
            WasteObj.NewWaste = DetailsObj.newPerWaste
            WasteObj.WasteReduction = selectedBaseline.actualPrcWaste - WasteObj.NewWaste
            WasteObj.TotalCostReduction = (WasteObj.WasteReduction / 100 * WasteObj.MSFProduced * costMSFWperPP)
            WasteObj.WasteSaving = (DetailsObj.totalWasteSavings)
        }
    }
    WasteObj.TimeTitle = formatDate(DetailsObj.startDate, DetailsObj.enddate, DetailsObj.periodValues, DetailsObj.selectedRadioValue)

    let data = {
        IsWasteVisible : WasteObj.IsWasteVisible,
        ActualWaste : Tformatnum(WasteObj.ActualWaste),
        NewWaste : Tformatnum(WasteObj.NewWaste),
        MSFProduced : formatnum(WasteObj.MSFProduced),
        WasteReduction : Tformatnum(WasteObj.WasteReduction),
        TotalCostReduction : formatnum(WasteObj.TotalCostReduction),
        WasteSaving : Tformatnum(WasteObj.WasteSaving),
        WasteSavingValue : WasteObj.WasteSaving,
        valueCounting : formatnum(WasteObj.valueCounting),
        TimeTitle : WasteObj.TimeTitle,
        RadioValue : WasteObj .RadioValue,
    }


    return data;
}

function GetCheckIsSelected(from, DetailsObj) {
    if (from === 'waste') {
        if (DetailsObj.newPerWaste > 0 ) {
            return true
        } else {
            return false
        }
    } else if (from === 'production') {
        if (DetailsObj.proNewLinealPerHr > 0 ) {
            return true
        } else {
            return false
        }
    } else if (from === 'consumption') {
        if (DetailsObj.consLbPerMsf > 0 && DetailsObj.consCostLb > 0) {
            return true
        } else {
            return false
        }
    } else if (from === 'hoursOfTraining') {
        if (DetailsObj.hoursOfTraining > 0 ) {
            return true
        } else {
            return false
        }
    } else if (from === 'hoursOfDownTime') {
        if (DetailsObj.hoursOfDownTime > 0 ) {
            return true
        } else {
            return false
        }
    } else {
        return false
    }
    
}

function formatDate(startDate, endDate, count, isFrom) {
    // Convert string dates to Date objects
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);
  
    // Format dates as MM/DD/YY
    const formattedStartDate = `${startDateObj.getMonth() + 1}/${startDateObj.getDate()}/${startDateObj.getFullYear() % 100}`;
    const formattedEndDate = `${endDateObj.getMonth() + 1}/${endDateObj.getDate()}/${endDateObj.getFullYear() % 100}`;
  
    let formattedString = `${formattedStartDate} to ${formattedEndDate}`;
  
    if (isFrom === 'day') {
      formattedString += ` (${count} Days)`;
    } else if (isFrom === 'week') {
      formattedString += ` (${count} Weeks)`;
    } else {
        formattedString += ` (${count} Months)`;
    }
  
    return formattedString;
  }