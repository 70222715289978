import Constants from '../constants';
const initDataState = {
    VisitSurveysCached: []
    , Surveys: []
    ,SurveysData:[]
};
const Surveys = (state = initDataState, action) => {
    switch (action.type) {
        case Constants.Actions.ReceiveSurveys:
            //Add VisitId to and Survey Data
            //{}
            var newVisitSurveysCached = [...state.VisitSurveysCached, action.VisitId];
            var newSurveys = [...state.Surveys,...action.Surveys];
            return { ...state, VisitSurveysCached: newVisitSurveysCached, Surveys: newSurveys };
        case Constants.Actions.ReceiveSurveyData:
            var newData = [...state.SurveysData, action.data];
            return { ...state, SurveysData: newData };
        case Constants.Actions.AddSurvey:
            var addSurveys = [...state.Surveys, action.data];
            return { ...state, Surveys: addSurveys };
        case Constants.Actions.UpdateSurvey:
            let updSD = [...state.SurveysData.filter(sd => sd.id !== action.data.id), action.data];
            let updS = [...state.Surveys.filter(s => s.id !== action.data.id)
                , { id: action.data.id, name: action.data.name, type: action.data.type, visitID: action.data.visitID }];
            return { ...state, SurveysData: updSD, Surveys:updS };
        default:
            return state;
    }
}
export default Surveys;