import Constants from '../constants';
import produce from "immer";
import { UpdateArray } from '../misc/ArrayFuncs';
const initDataState = [];
const Attachments = (state = initDataState, action) => {
    switch (action.type) {
        case Constants.Actions.Attachment.Add:
            return [...state, action.payload];
        case Constants.Actions.Attachment.Add_Rollback:
            const atInd = state.findIndex(a => a.id === action.meta.attachObj.id);
            if (atInd !== -1) {
                return produce(state, pd => { pd.splice(atInd, 1) });
            } else {
                return state;
            }
        case Constants.Actions.Attachment.Add_Commit:
            return UpdateArray(action?.payload?.data, state);
        case Constants.Actions.Attachment.ClearData:
            return initDataState;
        case Constants.Actions.Attachment.Receive:
            return UpdateArray(action.data, state);
        case Constants.Actions.Attachment.Delete:
            return UpdateArray(produce(action.payload, d => {
                d.deleted = true;
            }), state);
        case Constants.Actions.Attachment.Delete_Commit:
            return state;
        case Constants.Actions.Attachment.Delete_Rollback:
            return UpdateArray(action.meta.delAttachment, state);
        case Constants.Actions.Attachment.Update:
            return UpdateArray(action.payload.update, state);
        case Constants.Actions.Attachment.Update_Commit:
            return UpdateArray(action.payload?.data, state);
        case Constants.Actions.Attachment.Update_Rollback:
            return UpdateArray(action.meta.data.current, state);
        default:
            return state;
    }
}
export default Attachments;