import Constants from '../../constants';
//Library Imports
import React, { useEffect, useState } from 'react';
//MUI 
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import KeyIcon from '@mui/icons-material/Key';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
//Compontent
import { MulitKeyItemGrid } from './KeyItemsGrid';
const KeyItemGridButton = props => {
    //entityType, entity:sourceObj, reference,visitID,url
    const [modalOpen, setOpen] = useState(false);
    return <>
        <Tooltip title={"Key Items"}>
        <IconButton onClick={() => setOpen(true)} >
            <KeyIcon />
        </IconButton>
        </Tooltip>
        <Dialog open={modalOpen} onClose={() => setOpen(false)} >
            <DialogTitle>
                {props.entityType + ' KeyItems'}
            </DialogTitle>
            <DialogContent align="center">
                <MulitKeyItemGrid visitID={props.visitID}
                    multi={{ entityType: props.entityType, entity: props.entity,  reference:props.reference, url:props.url }} />
            </DialogContent>
        </Dialog>
        </>; 
}
export default KeyItemGridButton;