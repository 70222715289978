import Constants from '../constants';
import produce from "immer";
import { UpdateArray, ChangeOrder, UpdateArrayRange } from '../misc/ArrayFuncs';
const initDataState = [];
//Not Normalized
const FormulaSteps = (state = initDataState, action) => {
    switch (action.type) {
        //Get
        case Constants.Actions.FormulaSteps_ReceiveByPlant:
            return UpdateArrayRange(action.data, state);
        //Add
        case Constants.Actions.FormulaStep_Add:
            return [...state, action.payload];
        case Constants.Actions.FormulaStep_Add_Rollback:
            const fsInd = state.findIndex(machine => machine.id === action.meta.newFormulaStep.id);
            if (fsInd !== -1) {
                return produce(state, pd => { pd.splice(fsInd, 1) });
            } else {
                return state;
            }
        case Constants.Actions.FormulaStep_Add_Commit:
            return UpdateArray(action.payload?.data, state);
        //Delete
        case Constants.Actions.FormulaStep_Delete:
            const delStep = state.find(p => p.id === action.payload.id);
            return state
                .map((val, ind, array) => {
                    if(val.deleted){ return val; }
                    if(val.id===action.payload.id){
                        return produce(val, d => { d.deleted=true; })
                    }
                    if (val.stepNumber > delStep.stepNumber) {
                        return produce(val, d => { d.stepNumber--; })
                    }
                    return val;
                });
        case Constants.Actions.FormulaStep_Delete_Commit:
            return state;
        case Constants.Actions.FormulaStep_Delete_Rollback:
            return UpdateArray(action.meta.delFormulaStep, state);
        case Constants.Actions.FormulaStep_ChangeOrder:
            return ChangeOrder(action.payload.id, state, action.payload.isUp, (a, b) => a.formulaID == b.formulaID,'stepNumber');
        case Constants.Actions.FormulaStep_ChangeOrder_Commit:
            return state;
        case Constants.Actions.FormulaStep_ChangeOrder_Rollback:
            let isUp = false;
            if (action.meta.changeData.isUp)
                isUp = true;
            return ChangeOrder(action.meta.changeData.id, state, isUp, (a, b) => a.formulaID == b.formulaID);
        case Constants.Actions.FormulaStep_ReOrder:
            let newOrder = 1
            const changed = state
                .filter(mv => mv.formulaID == action.payload && !mv.deleted)
                .sort((a, b) => a.stepNumber - b.stepNumber)
                .reduce((acc, mv) => {
                    if (mv.stepNumber != newOrder) {
                        acc.push(produce(mv, d => { d.stepNumber = newOrder }));
                    }
                    newOrder++;
                    return acc;
                }, []);

            return UpdateArrayRange(changed, state);
        case Constants.Actions.FormulaStep_Update:
            return UpdateArray(action.payload.update, state);
        case Constants.Actions.FormulaStep_Update_Commit:
            return UpdateArray(action.payload?.data, state);
        case Constants.Actions.FormulaStep_Update_Rollback:
            return UpdateArray(action.meta.data.current, state);
        //FormulaStep_ReOrder Commit no change and Rollback impossible to reverse
        case Constants.Actions.FormulaStep_ClearData:
            return [];
        default:
            return state
    };
}
export default FormulaSteps;