import React, { useEffect, useState } from 'react';
//Compontent Imports
import ChecklistHeader from './ChecklistHeader';
import ChecklistDetailEdit from './ChecklistDetailEdit';
const ChecklistEdit = props => {
    return <div>
        <ChecklistHeader checklist={props.checklist} />
        <ChecklistDetailEdit checklist={props.checklist} />
    </div>;
}
export default ChecklistEdit;