import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GetLine } from '../../actions/LineActions';
import TextField from '@mui/material/TextField';
export const LineIDLink = props => {
    const dispatch = useDispatch();
    useEffect(() => {
        if (props.lineID)
            dispatch(GetLine(props.lineID));
    }, [props.lineID]);
    const Line = useSelector(state => state.Lines.find(l => l.id === props.lineID));
    if (Line) {
        return <TextField
            id="lineName_Read_Only"
            label="Line"
            defaultValue={Line.name}
            InputProps={{
                readOnly: true,
            }}
            variant="outlined"
            {...props.TextFieldProps}
        />;
    }
    return <div>Error: Could not find Line</div>;
}