import Constants from '../../constants';
//Modules
import React, { useEffect, useContext, useState,useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useNavigate,useParams } from 'react-router-dom';
//MUI
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import ReportViewer from '../Report/ReportViewer';
//Components
import { useReportUser,useReportNameFromVisitID } from '../../misc/UseFuncs';
import { GlueRollByIDSel,GRMeasurementsByID,FilteredMachineFlutesSel,GRPicturesBySurveyID } from './GlueRollSelectors';
import { AttachmentsByFilterArray } from '../../selectors/AttachmentSelectors';
import { GetAttachmentsByArray } from '../../actions/AttachmentActions';
import { ScaleImages } from '../../misc/ImageFuncs';
import { ErrorNotification } from '../../actions/NotificationActions';

const GlueRollPrint = props => {     
    const params=useParams();
    const grID = params.SurveyID;
    const navigate = useNavigate();
    const glueRoll = useSelector(state => GlueRollByIDSel(state, grID)); 
    const handleClose = () => {
        navigate(-1);
    }
    if(!glueRoll){
        return <div>Invalid GlueRoll ID. </div>
    }
    if(glueRoll.glueRollType===1){
        return <GlueRollPicturesPrint grID={grID} handleClose={handleClose} visitID={glueRoll.visitID} />;
    }
    return <GlueRollMeasurementsPrint grID={grID} handleClose={handleClose} visitID={glueRoll.visitID} />;

}
export default GlueRollPrint;

const GlueRollMeasurementsPrint = props=> {
    
    const printData=useGRMeasurementsPrintData(props.grID,props.visitID);
    return <div>
       <ReportViewer type="GlueRoll_Measurements" name="GlueRoll Measurements" printData={printData} 
        onClose={props.handleClose}  />
    </div>;
}
export const useGRMeasurementsPrintData=(grID,visitID)=>{
    const Measurements = useSelector(state => GRMeasurementsByID(state, grID));  
    const MachineFlutes = useSelector(state=>FilteredMachineFlutesSel(state,Measurements));
    const MeasurementsWithFlutes = Measurements.map(m=>{
        let fluteStr = '';
        MachineFlutes.filter(mf=>mf.machineID===m.machineID && !mf.deleted)
        .forEach(f => {
            const FluteName = Object.keys(Constants.Enums.Flutes).find(key => Constants.Enums.Flutes[key] === f.flute);
            if (fluteStr !== '') {
                fluteStr += ', ' + FluteName;
            } else {
                fluteStr = FluteName;
            }
        });
        return {...m, flutes:fluteStr};
    });
    const UserData = useReportUser();
    const dJSON ={ Measurements:MeasurementsWithFlutes, User:UserData};
    const Data ={ m:JSON.stringify(dJSON)}
    const reportName = useReportNameFromVisitID("GlueRoll_Measurements",visitID);
    return {Loaded:true,Data:Data,ReportName:reportName,EmailSubject:reportName};
}
const GlueRollPicturesPrint = props =>{
   const printData=useGRPicturesPrintData(props.grID,props.visitID);
   if(printData.Loaded){
    return <ReportViewer type="GlueRoll_Pictures" name="GlueRoll_Pictures" printData={printData}
                onClose={props.handleClose}  />;
   }
   return <div>Loading Data</div>;
};
export const useGRPicturesPrintData =(grID,visitID)=>{
    const dispatch=useDispatch();
    const [scaled,setScaled]=useState(false);
    const [scaledAttachements,setScaledAttachments]=useState([]);
    const [loaded,setLoaded]=useState(false);
    const pictures = useSelector(state => GRPicturesBySurveyID(state, grID)); 
    const online =useSelector(s=>s.offline.online,shallowEqual);
    const LastSync=useSelector(s=>s.LastSync,shallowEqual);
    const Token=useSelector(s=>s.Auth.token,shallowEqual);
    useEffect(()=>{
        if (online){
            GetAttachmentsByArray(pictures,dispatch,LastSync,Token)
            .then(
                ()=>setTimeout(()=>setLoaded(true),200),
                ()=>{
                    dispatch(ErrorNotification("Error Loading Attachments for Report",{File:"GlueRollPrint.js",Function:"useGRPicturesPrintData"}));
                    setTimeout(() => setLoaded(true), 200);
                }
            );
        } else {
            setTimeout(() => setLoaded(true), 200)
        }
    },[JSON.stringify(pictures)]);
    const attachments=useSelector(state=>AttachmentsByFilterArray(state,pictures));
    const UserData = useReportUser();
    const reportName = useReportNameFromVisitID("GlueRoll_Pictures",visitID);
    
    const retVal ={Loaded:false,Data:{},ReportName:reportName,EmailSubject:reportName};
    if(loaded){
        if(scaled){
            const dJSON ={ GRPictures:pictures, User:UserData,Attachments:scaledAttachements};
            const Data ={ GRPicsData:JSON.stringify(dJSON)}
            console.log("GlueRoll Pictures Rendering Viewer");
            retVal.Loaded=true;
            retVal.Data=Data;
        } else {
            Promise.all(ScaleImages(attachments,200,220))
            .then((values)=>{
                setScaledAttachments(values);
                setScaled(true);
            });            
        }
    } 
    return retVal;
}

