import React, { useEffect, useContext, useState } from 'react';
//MUI Imports
import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
    clHeader: {
        width: '100%',
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '1.6em',
        color:'#1976d2',
        paddingLeft: '5%',
        paddingRight: '5%',
        '& hr': {
            border: '7px solid darkgray',
            borderRadius:'2px'
        }
    },
}));
const ChecklistHeader = props => {
    const classes = useStyles();
    return <div className={classes.clHeader}> {props.checklist.name}
        <br />
        <hr />
    </div>;
}
export default ChecklistHeader;