import Constants from '../constants';
import produce from "immer";
import { UpdateArray, ChangeOrder, UpdateArrayRange } from '../misc/ArrayFuncs';
const initDataState = { promptOpen:false, title:'',message:'',confirm:'Confirm',cancel:'Cancel',confirmAction:null};
//Not Normalized
const Prompt = (state = initDataState, action) => {
    switch (action.type) {
        case Constants.Actions.SHOW_PROMPT:
            return {
                promptOpen: true, title: action.title,
                message: action.message,
                confirm: action.confirm ? action.confirm : 'Yes',
                cancel: action.cancel ? action.cancel : 'No',
                confirmAction:action.confirmAction
            };
        case Constants.Actions.CLOSE_PROMPT:
            return initDataState;
        default:
            return state;
    };
}
export default Prompt;