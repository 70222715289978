import Constants from '../constants';
import produce from "immer";
import { UpdateArray,UpdateArrayRange } from '../misc/ArrayFuncs';
const constants=Constants;
const initDataState = [];
const Reports = (state = initDataState, action) => {
    switch (action.type) {
        case constants.Actions.Report.AddUpdate:
            return UpdateArray(action.payload, state);
        case constants.Actions.Report.Load:
            return UpdateArrayRange(action.payload,state);
        case constants.Actions.Report.ClearData:
            return initDataState;
        default:
            return state;
    }
}
export default Reports;