import Constants from '../../constants';
//Libraries
import React, { useEffect, useContext, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import  {useNavigate, useParams } from 'react-router-dom';
import produce from "immer";
//MUI
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import PrintIcon from '@mui/icons-material/Print';
//Components
import { FormulaSelection } from '../Formula/FormulaSelectionView';
import { EditFormulaButton } from '../Formula/EditFormulaButton';
import IVStepsGrid from "./IVStepsGrid";
import PrintDialog from "../Print/PrintDialog";
import IVPrint from "./IVPrint";
//Actions
import { SetIVFormulaID, DeleteIngredientVerification, UpdateIngredientVerification, CalculateAccumulatedWeight, GetIngredientVerification } from '../../actions/IngredientVerificationActions';
import { ConfirmAction } from '../../actions/ConfirmActionActions';

const IngredientVerification = props => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params=useParams();
    const ivID = params.VerificationID;
    const [printMode, setPrint] = useState(false);
    const iv = useSelector(state => {
        if (Array.isArray(state.IngredientVerifications)) { //Fix for stored state mismatch
            return state.IngredientVerifications.find(i => i.id === ivID)
        } else {
            return null;
        }
    }, shallowEqual);
    if (!iv) {
        dispatch(GetIngredientVerification(ivID));
    }
    const formulaID = iv ? iv.formulaID : null;
    const formula = useSelector(state => state.Formulas.find(f => f.id == formulaID), shallowEqual);
    const visitID = iv?iv.visitID:null;
    const visit = useSelector(state => state.Visits.find(v => v.id === visitID), shallowEqual);
    const reqStatus = useSelector(state => state.RequestStatus.find(rs => rs.id == ivID), shallowEqual);
    if (!iv) {
        if (reqStatus.complete) {
            //Bad Record
            navigate("/", { replace: true });
        }
        //TODO: Dipatch get IV Data. If offline or not found need to go home. 
        return <div>Loading from Ingredient Verification from cloud DB ... </div>;
    }
    //Functions
    const handleDel = () => {
        const visitID = iv.visitID;
        dispatch(ConfirmAction("Do you want to delete this Ingredien Verification?", DeleteIngredientVerification(iv), null,
            (confirmed) => {
                if (confirmed) {
                    navigate('/VisitOverview/' + visitID, { replace: true });
                }
            }));
    }
    const handleClose = () => navigate(-1);
    const plantID = visit ? visit.plantID : null;
    
    const style = { overflow: 'hidden', height: '100%' };
    //Has the Formula Been Selected?
    let body = <Grid item xs={12}>
        <IVFormulaSel formulaID={iv.formulaID} plantID={plantID} ingVer={iv} />
    </Grid>
    if (iv.formulaID && iv.formulaID !== Constants.EmptyGuid) {
        const batchWeight = formula ? formula.batchWeight : 'Deleted';
        const EditBtn = formula ? <EditFormulaButton formulaID={iv.formulaID} /> : null;
        body = <>
            <Grid item xs={3}>Formula:{iv.name}</Grid>
            <Grid item xs={3}><StartWeightInput data={iv} /></Grid>
            <Grid item xs={3}>Batch Weight: {batchWeight}</Grid>
            <Grid item xs={3}>{EditBtn}</Grid>
            <Grid item xs={12}>
                <IVStepsGrid ivID={iv.id} startWeight={iv.startWeight} visitID={iv.visitID} />
            </Grid>
        </>;
    }
    return <>
        <Grid container spacing={3}>
            <Grid item xs={4} sm={4} align="left">
                <Button
                    variant="contained"
                    startIcon={<PrintIcon />}
                    onClick={() => navigate('/IngredientVerificationPrint/'+ivID)}>
                    Print
                </Button>
            </Grid>
            <Grid item xs={4} sm={4} >
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<DeleteIcon />}
                    onClick={handleDel}
                >Delete
                </Button>
            </Grid>
            <Grid item xs={4} sm={4} align="right">
                <Button variant="contained" startIcon={<CloseIcon />} onClick={handleClose}>
                    Close
                </Button>
            </Grid>
            {body}
        </Grid>
    </>;
}
export const IVFormulaSel = props => {
    const dispatch = useDispatch();
    const handleChange = (e) => {
        dispatch(SetIVFormulaID(e, props.ingVer));
    }
    
    return <FormulaSelection plantID={props.plantID} handleChange={handleChange} selected={props.formulaID} />;
   
};

const StartWeightInput = props => {
    const dispatch = useDispatch();
    const [weight, setWeight] = useState(props.data.startWeight);
    useEffect(() => {
        setWeight(props.data.startWeight);
    }, [props]);
    const updateIV = event => {     
        let wn=Number(weight);
        if(isNaN(wn))
            wn=0;
        if (props.data.startWeight != wn) {
            const upd = produce(props.data, d => {
                d.startWeight = wn;
            });
            dispatch(UpdateIngredientVerification(upd, props.data));
            dispatch(CalculateAccumulatedWeight(wn,props.data.id));
        }
    }
    return <TextField 
        value={weight}
        onChange={event => { 
                setWeight(event.target.value);
        }
        }
        onBlur={updateIV}
        label={'Start Weight'} size="small"
        onFocus={event => event.target.select()}
        type="number"
    />;
}
export default IngredientVerification;