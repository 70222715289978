export const toIsoString = (date) => {
    var tzo = -date.getTimezoneOffset(),
        dif = tzo >= 0 ? '+' : '-',
        pad = function (num) {
            return (num < 10 ? '0' : '') + num;
        };

    return date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds()) +
        dif + pad(Math.floor(Math.abs(tzo) / 60)) +
        ':' + pad(Math.abs(tzo) % 60);
};
export const doResync = (lastSyncDate) => {
    const syncDate = new Date(lastSyncDate);
    if (isNaN(syncDate.getDate())) {
        return true; //Bad lastSyncDate
    }
    const curDate = new Date();
    const diff = curDate - syncDate;
    if (diff <= 60000)
        return false;
    return true;
};