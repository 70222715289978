import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Constants from '../constants';
export default function AppPrompt(props) {
    const dispatch = useDispatch();
    const prompt = useSelector(state => state.Prompt, shallowEqual);
    const handleClose = () => {
        dispatch({ type: Constants.Actions.CLOSE_PROMPT });
    };
    const handleConfirm = () => {
        dispatch(prompt.confirmAction);
        dispatch({ type: Constants.Actions.CLOSE_PROMPT });
    };
    return (
            <Dialog
                open={prompt.promptOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
            <DialogTitle id="alert-dialog-title">{prompt.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    {prompt.message}
          </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                    {prompt.cancel}
          </Button>
                <Button onClick={handleConfirm} color="primary" autoFocus>
                    {prompt.confirm}
          </Button>
                </DialogActions>
            </Dialog>
        
    );
}