//Modules
import React, { useState, useEffect } from 'react';
import  {useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';

//MUI
import MUIDataTable from "mui-datatables";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
//Compontents
import EntityInfoDialog from '../MetaData/EntityInfoDialog';
import AddCustomToolbar from "../AddCustomToolbar";
import CustomSelectToolbar from '../CustomSelectToolbar';
//Actions
import { DeleteActionItemLink } from '../../actions/ActionItemLinkActions';
import { ConfirmAction } from '../../actions/ConfirmActionActions';


const DateView = props => {
    if (props.value === "0001-01-01T00:00:00") {
        return null;
    }
    const d = new Date(props.value);
    return <div>{d.toLocaleDateString()}</div>;
};
const GetActionItemLinks = state => state.ActionItemLinks.filter(ail=>!ail.deleted);
const GetKeyItems = state => state.KeyItems;
const GetVisits = state=>state.Visits
const GetActionItem_KeyItems = createSelector([GetActionItemLinks, GetKeyItems,
    (s, actionItemID) => actionItemID, GetVisits
], (aiLinks, keyItems, aiID, visits) => {
    const ails = aiLinks.filter(l => l.actionItemID == aiID && !l.deleted)
    const kiIDs = ails.map(l => l.keyItemID);
    return keyItems
    .filter(ki => kiIDs.includes(ki.id) && !ki.deleted)
    .map(ki => {
        const ail = ails.find(ail => ail.keyItemID == ki.id);
        const v = visits.find(v => v.id == ki.visitID);
        return { ...ki, ...v, actionItemLinkID:ail.id };
    });
});
const ActionItemLinksGrid = props => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const keyItems = useSelector(state => GetActionItem_KeyItems(state, props.actionItemID));
    const [selectedRows, setSelectedRows] = useState([]);
    
    const rowSelect = (rowsSelectedData, allRows, rowsSelected) => {
        setSelectedRows(rowsSelected);
    };
  
  
    const ClickDelete = () => {
        const delAIL = keyItems[selectedRows[0]];
        setSelectedRows([]);
        dispatch(ConfirmAction("Do you want to delete this Action Item Link?", DeleteActionItemLink(delAIL.actionItemLinkID), null));
    }
    let keyItem = null;
    if (selectedRows.length > 0) {
        keyItem = keyItems[selectedRows[0]];
    }
    const columns = [
        { name: "id", label: "ID", options: { display: false } },
        {
            name: "startDate", label: "Visit Date", options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <DateView value={value} />;
                }
            }
        },
        { name: 'type', label: 'Visit Type' },
        { name: "notes", label: "Notes" }
    ];
    const options = {
        filterType: 'checkbox', selectableRows: 'single', selectableRowsOnClick: true, print: false,
        download: false,
        fixedHeader: true,
        responsive: 'standard',
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
            return <CustomSelectToolbar selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                clickLink={ClickOpen} clickDelete={ClickDelete} />;
        },
        onRowSelectionChange: rowSelect,
        rowsSelected: selectedRows, filter: false, viewColumns: false
    };
    const ClickOpen = () => {
        if (keyItem) {
            navigate(keyItem.url);
        } else {
            alert("Select Key Item From List");
        }
    };
    
    return <MUIDataTable
        title={"Linked Key Items"}
        data={keyItems}
        columns={columns}
        options={options}
    />;
};
export default ActionItemLinksGrid
