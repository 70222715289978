import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import Constants from '../../constants';
import LoginView from './LoginView';
const LoginDialog = props => {
    const constants = Constants;
    
    const { authenticated, online, authToken, offlineMode, siteAccess } = useSelector(state => {
        return {
            authenticated: state.Auth.authenticated,
            online: state.NetworkState.online,
            siteAccess:state.NetworkState.siteAccess,
            authToken: state.Auth.token,
            offlineMode: state.Auth.offlineMode
        };
    }, shallowEqual); 
    const handleClose = () => {
       alert("Login Dialog Close Clicked.")
    }
    if (!online || !siteAccess || offlineMode || authenticated) {
        return null;
    }
    return (
        <Dialog
            open={true}
            onClose={handleClose}
            fullScreen
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <LoginView />
            </DialogContent>
               
        </Dialog>
    );
}
export default LoginDialog;