import Constants from '../../constants';
//Modlues
import React, { useEffect, useContext, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
//MUI
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import PrintIcon from '@mui/icons-material/Print';
import makeStyles from '@mui/styles/makeStyles';
//Compontents
import { PlantLink } from '../MetaData/Links';
import { usePlantIDFromVisitID } from '../MetaData/PlantOptions';
import GetEntity from '../../misc/GetEntity';
import SystemAudit from './SystemAudit';
import StarchAuditPrint from './StarchAuditPrint';
import PrintDialog from '../Print/PrintDialog';
import { DataForm, DataTextBox, DataDate, DataSelect, DataNumber, DataFormContext } from '../FormManager';
//Actions

import { enqueueSnackbar, closeSnackbar } from '../../actions/NotificationActions';
import { ConfirmAction } from '../../actions/ConfirmActionActions';


const constants = Constants;
const useStyles = makeStyles((theme) => ({
    header: {
        borderBottom: 'solid'
    },
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));
const StarchView = props => {
    const params=useParams();
    const sID = params.SurveyID;
    const [printMode, setPrint] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { Starch, online, authToken,offlineMode } = useSelector(state => {
        return {
            Starch: state.Starch.find(s => s.id == sID),
            online: state.offline.online,
            authToken: state.Auth.token,
            offlineMode: state.Auth.offlineMode
        };
    }, shallowEqual);
    //Get Latest Data and Check if still valid.

    const plantID = usePlantIDFromVisitID(Starch ? Starch.visitID : null);
    const classes = useStyles();
    //If Request Status complete==true and heatVessel==null then we have a bad id or cannot pull offline data. 
    const handleDel = () => {
        /* dispatch(ConfirmAction("Do you want to delete this Starch Survey?", DeleteStarch(Starch), null,
            (confirmed) => {
                if (confirmed) {
                    navigate(-1);
                }
            })); */
    }
    const handleClose = () => {
        navigate(-1);
    }  
    if (!Starch) {
        return <div>Loading Starch Data from Database.</div>;
    }
    
    if(printMode){
        return <PrintDialog printOpen={printMode} onClose={() => setPrint(false)} >
                <StarchAuditPrint starch={Starch} plantID={plantID} />
        </PrintDialog>
    }

    const HeaderButtons = <>
            <Grid item xs={4} sm={4} align="left">
                <Button
                    variant="contained"
                    startIcon={<PrintIcon />}
                    onClick={() => setPrint(true)}>
                    Print
                </Button>
            </Grid>
            <Grid item xs={4} sm={4} >
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<DeleteIcon />}
                    onClick={handleDel}
                >Delete
                </Button>
            </Grid>
            <Grid item xs={4} sm={4} align="right">
                <Button variant="contained" startIcon={<CloseIcon />} onClick={handleClose}>
                    Close
                </Button>
            </Grid>
            <Grid item md={4}>Starch Kitchen</Grid>
            <Grid item md={4}><PlantLink plantID={plantID} /></Grid>
            <Grid item md={4}><DataDate name={'date'} label={'Date'} TextFieldProps={{ size: 'small' }} /></Grid>
        </>;
    
    return (
            <>
                <DataForm actionType={constants.Actions.Starch} data={Starch}>
                    <Grid container spacing={3}>
                        {HeaderButtons}              
                        </Grid>
            </DataForm>
            <SystemAudit starch={Starch} plantID={plantID} />                        
           </>

    );
}
export default StarchView;