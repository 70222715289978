import Constants from '../constants';
import produce from "immer";
import { UpdateArray, UpdateArrayRange } from '../misc/ArrayFuncs';
const initDataState = [];

const Baselines = (state = initDataState, action) => {
    switch (action.type) {
        case Constants.Actions.RequestBaselines:
            return state;
        case Constants.Actions.ReceiveBaselines:
            return UpdateArrayRange(action.data, state);
        case Constants.Actions.Baselines_GetByPlantID:
            const nonPlantBaseline = state.filter(a => a.plantID !== action.plantID);
            return nonPlantBaseline.concat(action.data);

        case Constants.Actions.Baselines.Add:
            return UpdateArray(action.payload, state);
            
        case Constants.Actions.Baselines.Add_Commit:
            return UpdateArray(action.payload?.data, state);


        case Constants.Actions.Baselines.Add_Rollback:
            const baseInd = state.findIndex(v => v.id === action.meta.newBaselineItem.id);
            if (baseInd !== -1) {
                return produce(state, pd => { pd.splice(baseInd, 1) });
            } else {
                return state;
            }



        case Constants.Actions.Baselines.Update:
            return UpdateArray(action.payload.update, state);
        case Constants.Actions.Baselines.Update_Commit:
            return UpdateArray(action.payload?.data, state);
        case Constants.Actions.Baselines.Update_Rollback:
            return UpdateArray(action.meta.data.current, state);
        case Constants.Actions.Baselines.Delete:
            return UpdateArray(produce(action.payload, d => {
                d.deleted = true;
            }), state);
        case Constants.Actions.Baselines.Delete_Commit:
            return state;
        case Constants.Actions.Baselines.Delete_Rollback:
            return UpdateArray(action.meta.delKeyItem, state);
        case Constants.Actions.Baselines.ClearData:
            return initDataState;
        default:
            return state;
    }
}
export default Baselines;