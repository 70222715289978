import Constants from '../../constants';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Link } from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import { OpenDrawer } from '../../actions/MenuActions';
import OnlineIcon from './OnlineIcon';
import LoginIcon from './LoginIcon';
import { CheckIsOnline, SyncManualy } from '../../actions/NetworkStateActions'
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));
 const NavMenu = props => {
     const dispatch = useDispatch();
     const classes = useStyles();

     return <>
        <AppBar>
            <Toolbar>
                     <IconButton
                         edge="start"
                         color="inherit"
                         aria-label="menu"
                         className={classes.menuButton}
                         onClick={() => { dispatch(OpenDrawer()) }}
                         size="large">
                    <MenuIcon />
                     </IconButton>
                     <Link to={"/"} style={{ color: 'white' }} className={classes.title}>
                                <Typography variant="h6" >
                            Plant Survey
                                </Typography>
                        </Link>

                        <OnlineStatus/>
                    
                    <OnlineIcon className={classes.menuButton} />
                    
                     
                     <LoginIcon />
             </Toolbar>
        </AppBar>
        <Toolbar />
     </>;
}
export default NavMenu;

export const OnlineStatus = props => {
    const dispatch = useDispatch();
    const classes = useStyles();
   const status = useSelector(s => {
       return {
           online: s.offline.online,
           outboxCount: s.offline.outbox.length,
           offlineMode: s.Auth.offlineMode,
           windowOnline: s.NetworkState.online,
           siteAccess: s.NetworkState.siteAccess,
           authenticated:s.Auth.authenticated
       }
   }, shallowEqual);
   useEffect(() => {
       dispatch(CheckIsOnline());
   }, [status.windowOnline, status.siteAccess, status.authenticated])
   useEffect(() => {
       dispatch(SyncManualy());
   }, [status.authenticated])

    if (!status.online) {
        return <>
                        <span style={{ color: 'white' }} className={classes.title}>
                                <Typography variant="h6" >
                                System is offline
                                </Typography>
                        </span>
        </>
    }
    return (
        <>
            <span style={{ color: 'white' }} className={classes.title}>
                                <Typography variant="h6" >
                                System is Online
                                </Typography>
                        </span>
        </>
    );
};