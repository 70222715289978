import Constants from '../constants';
import produce from "immer";
import { UpdateArray } from '../misc/ArrayFuncs';
const initDataState = [];


//TODO: Normalize Data Maybe?
const Personnel = (state = initDataState, action) => {
    switch (action.type) {
        case Constants.Actions.Personnel_ReceiveByPlant:
            const newState = state.filter(v => v.plantID !== action.plantID);       
            return newState.concat(action.data);
        case Constants.Actions.UpdatePersonnel:
            return UpdateArray(action.payload.update, state);
        case Constants.Actions.UpdatePersonnel_Commit:  
            return UpdateArray(action.payload, state);
        case Constants.Actions.UpdatePersonnel_Rollback:
            return UpdateArray(action.meta.data.current, state);
        case Constants.Actions.AddPersonnel:
            let newPersons = [...state, action.payload];
            return newPersons;
        case Constants.Actions.AddPersonnel_Rollback:
            const personInd = state.findIndex(person => person.id === action.meta.newPerson.id);
            if (personInd !== -1) {
                return produce(state, pd => { pd.splice(personInd, 1) });
            } else {
                return state;
            }
        case Constants.Actions.AddPersonnel_Commit:
            return UpdateArray(action.payload, state);
        case Constants.Actions.Personnel_Delete:
            return state.filter(p => p.id !== action.payload.id);
        case Constants.Actions.Personnel_Delete_Commit:
            return state;
        case Constants.Actions.Personnel_Delete_Rollback:
            return UpdateArray(action.meta.delPerson, state);
        case Constants.Actions.Personnel_ClearData:
            return [];
        default:
            return state;
    };
}
export default Personnel;