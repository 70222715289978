import Constants from '../../constants';
import React, { useEffect, useContext, useState } from 'react';
import { useLineOptions } from '../MetaData/PlantOptions';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
const LineSelection = props => {
    const lineOptions = useLineOptions(props.plantID, false
        , !(props.lineID && props.lineID !== Constants.EmptyGuid)
        , false
    );
    const lineSelID = 'ms_LineID_Sel'
    return <div>
        <FormControl variant="outlined" {...props.TextFieldProps} >
            <InputLabel htmlFor={lineSelID} shrink={true}>Line</InputLabel>
            <Select
                native
                name={'LineID'}
                value={props.lineID}
                onChange={(e) => {
                    if (e.target.value != props.lineID) {
                        props.onLineChange(e.target.value);
                    }
                }}
                id={lineSelID}
                label="Line"
            >
                {lineOptions}
            </Select>
        </FormControl>
    </div>;
}
export default LineSelection;