import Constants from '../../constants';
//Libraries
import React, { useState } from 'react';
//MUI

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import ImageIcon from '@mui/icons-material/Image';
import HideImageIcon from '@mui/icons-material/HideImage';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
//compontents 
import { ImageAttachmentView } from './ImageAttachmentView'
const constants = Constants;
const ImageAttachmentButton = props => {
    const [isOpen, setOpen] = useState(false);
    let imageID = props.attachmentID;
    if (typeof (imageID) === 'undefined')
        imageID = constants.EmptyGuid;
    let icon = <ImageIcon />;
    if (imageID == constants.EmptyGuid) {
        icon = <HideImageIcon />
    }
    return <>
        <Tooltip title={"Image Attachment"}>
            <IconButton onClick={() => setOpen(true)} tabIndex="-1" size="large">
                { icon }
            </IconButton>
        </Tooltip>
        <ImageAttachmentDialog modalOpen={isOpen} modalClose={() => setOpen(false)}
            onChange={props.onChange} attachmentID={imageID} header={''}  />
        </>;

};

export const ImageAttachmentDialog = props => {
    return <Dialog open={props.modalOpen} onClose={props.modalClose}  >
        <DialogContent>
            <ImageAttachmentView attachmentID={props.attachmentID} handleChange={props.onChange} header={ props.header } />
        </DialogContent>
        </Dialog>;
};

export default ImageAttachmentButton;

